export default {
  namespaced: true,
  state: {
    analytics: localStorage.getItem('view.analytics') || 'tiles',
    filterType: 'categories',
    filter: [],
  },
  mutations: {
    SET_VIEW(state, { view, value }) {
      state[view] = value;
      localStorage.setItem(`view.${view}`, value);
    },
    SET_FILTER_TYPE(state, filterType) {
      state.filterType = filterType;
    },
    SET_FILTER(state, filter) {
      state.filter = filter;
    },
  },
};
