import LIST_ACTIONS from './queries/listActionConfigs.gql';
import GET_ACTION from './queries/getActionConfigs.gql';
import DELETE_ACTION from './mutations/deleteActionConfigs.gql';
import { CREATE_ACTION, UPDATE_ACTION } from './mutations/actions.mutations';

export default {
  state: {
    action: {},
    actions: [],
    actionsNames: [],
  },
  mutations: {
    SET_ACTION(state, payload) {
      state.action = payload;
    },
    SET_ACTIONS(state, payload) {
      state.actions = payload.items;
      state.actionsNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    // mutations
    async deleteActionConfigs({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_ACTION,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ACTION', null);
        return result.data.deleteActionConfigs;
      }
      return result;
    },

    async createActionConfigs({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_ACTION,
          variables: payload,
        },
        { root: true }
      );

      if (result) {
        const action = result.data.createActionConfigs;
        commit('SET_ACTION', action);
        return action;
      }

      return result;
    },

    async updateActionConfigs({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ACTION,
          variables: payload,
        },
        { root: true }
      );

      if (result) {
        const action = result.data.updateActionConfigs;
        commit('SET_ACTION', action);
        return action;
      }

      return result;
    },
    // queries
    async listActionConfigs({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ACTIONS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ACTIONS', result.data.listActionConfigs);
        return result.data.listActionConfigs;
      }
      return result;
    },
    async getActionConfigs({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ACTION,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ACTION', result.data.getActionConfigs);
        return result.data.getActionConfigs;
      }

      return result;
    },
  },
};
