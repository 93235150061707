/**
 * @description builds a filter array from query params
 * @param {object} query query to add to the filters
 * @param {string} type name of query, defaults to 'q'
 * @returns {array}
 */
export const queryFilter = (query, type = 'q') => {
  const q = query[type] || [];
  const filter = [];
  if (q.length > 0) {
    if (typeof q === 'object') {
      q.forEach((item) => {
        filter.push(item);
      });
    } else if (typeof q === 'string') {
      filter.push(q);
    }
  }
  return filter;
};

export default queryFilter;
