import LIST_AUDIT_LOGS_DATE from './queries/listAuditLogsByDate.gql';
import LIST_AUDIT_LOGS_USER from './queries/listAuditLogsByUser.gql';
import LIST_AUDIT_LOGS_OP from './queries/listAuditLogsByOp.gql';

export default {
  actions: {
    async listAuditLogsByDate({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_AUDIT_LOGS_DATE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAuditLogsByDate;
      }
      return result;
    },
    async listAuditLogsByUser({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_AUDIT_LOGS_USER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAuditLogsByUser;
      }
      return result;
    },
    async listAuditLogsByOp({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_AUDIT_LOGS_OP,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAuditLogsByOp;
      }
      return result;
    },
  },
};
