<template>
  <Splash id="eula">
    <h1 class="h4">License Agreement</h1>
    <j-textarea
      v-model="slasa"
      ref="agreement"
      :columns="60"
      class="eula-textarea"
      is-read-only
      data-feature-id="eula-textarea"
    />
    <div class="g-cols right">
      <j-button
        v-if="hasDefer"
        :style="{ visibility: loading ? 'hidden' : 'visible' }"
        style-type="secondary"
        @click="deferSlasa()"
        data-feature-id="defer-slasa"
      >
        Defer until next Login
      </j-button>
      <j-button
        :style="{ visibility: loading ? 'hidden' : 'visible' }"
        @click="acceptSlasa()"
        :disabled="read"
        data-feature-id="accept-slasa"
      >
        Agree
      </j-button>
      <gp-loader v-if="loading" />
      <span v-if="message" class="error">{{ message }}</span>
    </div>
  </Splash>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Splash from '@/components/Splash.vue';
import Slasa from './slasa.txt';

export default {
  name: 'EULA',
  components: {
    Splash,
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState(['app']),
    hasDefer() {
      return (
        this.isFeatureEnabled('DEFER_BUTTON') &&
        this.app.info.eulaAccepted &&
        this.app.info.acceptedSlasaVersion !== 2
      );
    },
  },
  data() {
    return {
      slasa: Slasa,
      read: true,
      loading: false,
      message: null,
    };
  },
  mounted() {
    const ref = this.$refs.agreement.$el.firstElementChild.firstElementChild;
    const scrollPosition = () => {
      const pos = !(
        ref.scrollHeight - (ref.clientHeight + 10) >=
        ref.scrollTop
      );
      if (pos) {
        this.read = false;
        ref.removeEventListener('scroll', scrollPosition);
      }
    };
    ref.addEventListener('scroll', scrollPosition);
  },
  methods: {
    accessApp() {
      this.$router.push({ name: 'index' });
    },
    async acceptSlasa() {
      this.loading = true;
      this.message = null;
      try {
        await this.$store.dispatch('updateSlasaAcceptance', {
          acceptedSlasaVersion: 2,
        });
        this.accessApp();
      } catch (err) {
        this.loading = false;
        this.message = 'Something went wrong.';
      }
    },
    async deferSlasa() {
      this.loading = true;
      this.message = null;
      this.accessApp();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.eula-textarea {
  --size-input-height-base: 65vh;
  border: 1px solid var(--color-input-border-base);
  border-radius: var(--size-border-radius-input);
}
</style>
