import Store from '@/store/store';

/**
 * @param {string} flag
 * @param {boolean} prevent - set to true if the flag is meant to prevent access rather then grant it
 * @example: beforeEnter: (to, from, next) => FlagGuard(to, from, next, 'FLAG_NAME'),
 */
const FlagGuard = async (to, from, next, flag, prevent = false) => {
  if (Store.getters.isFeatureEnabled(flag) === !prevent) {
    next();
  } else {
    next({ name: 'index' });
  }
};

export default FlagGuard;
