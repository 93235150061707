<template>
  <div class="gp-content">
    <JamfTable
      v-if="!reset"
      :get-data="getData"
      :filters="filter"
      :suppress-search="true"
      @row-click="viewDetails"
      :column-defaults="columnDefaults"
      :suppress-filter-bar="true"
      :page-size="pageSize"
      :cache-size="pageSize"
      :columns="columns"
    >
      <template v-if="loaded" #left="{ gridApi }">
        <div class="query-container">
          <j-select
            class="query-select"
            is-small
            v-model="queryType"
            :options="queryTypes"
            :searchable="false"
          />
          <j-datetime-picker
            v-if="queryType === 'date'"
            class="datetime-picker"
            placeholder="Select Date Range"
            v-model="dates"
            :config="{ onReady, mode: 'range' }"
          />
          <j-input
            v-else
            class="query-input"
            v-model="query"
            @keydown.enter="setFilterAndSort(gridApi)"
            placeholder="Begins With..."
          />
          <j-button class="query-button" @click="setFilterAndSort(gridApi)">
            Filter
          </j-button>
          <j-button
            v-if="isFiltered"
            style-type="secondary"
            class="query-button clear"
            @click="clearFilter(gridApi)"
          >
            Clear Filter
          </j-button>
        </div>
      </template>
      <template #right="{ gridApi, selected }">
        <j-button
          :is-disabled="selected.length === 0"
          class="query-button"
          style-type="ghost-primary"
          @click="exportData(gridApi)"
        >
          Export Selected ({{ selected.length }})
        </j-button>
      </template>
    </JamfTable>
    <j-modal v-model="hasActiveRow" :close-on-esc="true">
      <AuditLogsDetailModal v-bind="rowDetails" />
    </j-modal>
  </div>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useGmt } from '@/composables/gmt';
import AuditLogsDetailModal from './AuditLogsDetailModal';
import {
  setISODateToStartOfDay,
  setISODateToEndOfDay,
  DATETIME_WITH_TZ_RE,
} from '@/util/time-helpers';

const defaultPageSize = 500;

export default {
  name: 'AuditLogs',
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    JamfTable,
    AuditLogsDetailModal,
  },
  data() {
    return {
      id: 'auditlogs',
      pageSize: defaultPageSize,
      cacheSize: defaultPageSize,
      initialSort: true,
      columnApi: null,
      filter: this.$route.query,
      isFiltered: this.$route.query.value,
      hasActiveRow: false,
      reset: false,
      loaded: false,
      rowDetails: { data: null },
      columnDefaults: {
        minWidth: 100,
        sortingOrder: ['desc', 'asc'],
      },
      dataAltInput: null, // used to force a reset on the date input
      dates: [],
      query: this.$route.query.column === 'date' ? '' : this.$route.query.value,
      queryTypes: [
        { label: 'Date', value: 'date' },
        { label: 'User', value: 'user' },
        { label: 'Operation', value: 'op' },
      ],
      queryType: this.$route.query.column || 'date',
      columns: [
        { type: 'selectAllColumn' },
        {
          headerName: 'Date',
          field: 'date',
          filterParams: {
            filterOptions: ['inRange'],
          },
          suppressMenu: true,
          type: 'dateFilterColumn',
        },
        {
          headerName: 'User',
          field: 'user',
          filterParams: {
            filterOptions: ['startsWith'],
          },
          type: 'textFilterColumn',
          minWidth: 250,
          suppressMenu: true,
          valueFormatter: (params) => this.getUser(params),
        },
        {
          headerName: 'Operation',
          type: 'textFilterColumn',
          field: 'op',
          filterParams: {
            filterOptions: ['startsWith'],
          },
          suppressMenu: true,
          minWidth: 160,
        },
        {
          headerName: 'Resource ID',
          field: 'resourceId',
          valueFormatter: (params) => params.value || 'N/A',
          maxWidth: 150,
        },
        {
          headerName: 'Arguments',
          field: 'args',
          type: 'codeColumn',
        },
        {
          headerName: 'Errors',
          field: 'error',
          minWidth: 100,
          type: 'codeColumn',
          cellStyle: { color: 'red' },
        },
        {
          headerName: "IP's",
          type: 'tagsColumn',
          minWidth: 160,
          valueFormatter: ({ value }) => (value ? value.split(',') : null),
          field: 'ips',
          flex: 1,
        },
      ],
    };
  },
  mounted() {
    this.dates =
      this.$route.query.column === 'date'
        ? this.$route.query.value
            ?.replaceAll(' ', '+')
            .match(DATETIME_WITH_TZ_RE) ?? []
        : [];
    this.loaded = true;
  },
  methods: {
    async getData({ filter, nextToken, direction }, { columnApi, gridApi }) {
      let condition = null;
      const store = this.isAdmin ? 'admin' : 'primary';
      let endpoint = '/listAuditLogsByDate';
      this.columnApi = columnApi;
      if (this.initialSort) {
        this.setSort(gridApi, direction, this.$route.query?.column || 'date');
        this.initialSort = false;
      }

      if (filter) {
        if (filter.date) {
          condition = {
            dateRange: {
              startDate: setISODateToStartOfDay(this.dates[0]),
              endDate:
                setISODateToEndOfDay(this.dates[1]) ??
                setISODateToEndOfDay(this.dates[0]),
            },
          };
        }
        if (filter.user) {
          condition = { beginsWith: filter.user.startsWith };
          endpoint = '/listAuditLogsByUser';
        }
        if (filter.op) {
          condition = { beginsWith: filter.op.startsWith };
          endpoint = '/listAuditLogsByOp';
        }
      }

      const response = await this.$store.dispatch(`${store}${endpoint}`, {
        next: nextToken,
        order: { direction },
        condition,
        pageSize: this.pageSize,
      });
      /**
       * checks if the response came back with the expected amount and if not
       * makes another call to get the remaining results
       */
      if (
        response.items.length !== 0 &&
        response.items.length < this.pageSize &&
        response.pageInfo.next
      ) {
        const { items, pageInfo } = await this.$store.dispatch(endpoint, {
          next: response.pageInfo.next,
          order: { direction },
          condition,
          pageSize: this.pageSize - response.items.length,
        });
        const combined = { items: [...response.items, ...items], pageInfo };
        return combined;
      }

      return response;
    },
    onReady(_dateObj, _dateStr, instance) {
      this.dataAltInput = instance.altInput;
    },
    getUser(params) {
      return params.value ? params.value.split('#')[0] : '';
    },
    processCellsForExport(params) {
      const {
        column: { colId },
        value,
      } = params;
      if (colId === 'date') {
        return this.formatEpochDate(value);
      }
      if (colId === 'user') {
        return this.getUser(params);
      }
      return value;
    },
    exportData(api) {
      api.exportDataAsCsv({
        onlySelected: true,
        fileName: 'jamf-protect-audit-logs',
        processCellCallback: this.processCellsForExport,
      });
    },
    viewDetails(row) {
      this.rowDetails.data = row.data;
      this.hasActiveRow = true;
    },
    setFilterAndSort(api) {
      if ((this.query && this.queryType !== 'date') || this.dates[0]) {
        let filterModel;
        if (this.queryType === 'date') {
          this.query = '';
          filterModel = {
            [this.queryType]: {
              type: 'greaterThanOrEqual',
              filter: setISODateToStartOfDay(this.dates[0]),
              and: [
                {
                  filter:
                    setISODateToEndOfDay(this.dates[1]) ??
                    setISODateToEndOfDay(this.dates[0]),
                  type: 'lessThanOrEqual',
                },
              ],
              operators: new Set(['and']),
            },
          };
        } else {
          this.dates = [];
          filterModel = {
            [this.queryType]: {
              type: 'startsWith',
              filter: this.query,
            },
          };
        }
        this.setSort(api, 'desc', this.queryType);
        api.setFilterModel(filterModel);
        this.isFiltered = true;
      }
    },
    clearFilter(api) {
      api.setFilterModel(null);
      api.onFilterChanged();
      this.setSort(api, 'desc', 'date');
      this.query = '';
      this.dates = [];
      if (this.dataAltInput) this.dataAltInput.value = '';
      this.isFiltered = false;
    },
    async setSort(api, sort = 'desc', colId = 'date') {
      // eslint-disable-next-line no-param-reassign
      this.columns.forEach((column) => {
        column.sortable = false;
      });
      this.columns.find(({ field }) => field === colId).sortable = true;
      api.setColumnDefs(this.columns);

      this.columnApi.applyColumnState({
        state: [
          {
            colId,
            sort,
          },
        ],
        defaultState: {
          sort: null,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.query {
  &-container {
    --color-input-border-base: transparent;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-button {
    margin-left: spacing();
  }
  &-select {
    --size-input-height-base: 2.35em; // match height of inputs
    height: var(--size-input-height-base);
    max-height: var(--size-input-height-base);
    padding-right: 4px;
  }
  &-input {
    --color-input-base: transparent !important;
    --size-input-height-base: 2.35em; // match height of inputs
    height: var(--size-input-height-base);
    align-items: center;
    border-right: 1px solid var(--color-border-secondary);
  }
}
</style>
