<template>
  <div class="j-table-wrapper use-calc" ref="table">
    <!-- Controls Section -->
    <TableControlBar
      :suppress-control-bar="suppressControlBar"
      :suppress-search="suppressSearch"
      :suppress-pagination="suppressPagination"
      :suppress-refresh-button="suppressRefreshButton"
      @refresh="refresh"
    >
      <template #search>
        <FilterInput
          v-if="columnApi && gridApi"
          v-model="filterInput"
          :column-options="searchParams.columnOptions"
          :grid-api="gridApi"
          :default-column="searchParams.defaultColumn"
          :column-api="columnApi"
        />
      </template>
      <template #left>
        <!-- @slot left Additional controls for the table on the left side, scope: { selected, gridApi, refresh, currentPage } -->
        <slot
          name="left"
          :selected="selected"
          :selected-nodes="selectedNodes"
          :grid-api="gridApi"
          :column-api="columnApi"
          :refresh="refresh"
          :current-page="currentPage"
          :row-count="rowCount"
        ></slot
      ></template>
      <template #right="{ isSmall }">
        <!-- @slot right Additional controls for the table on the right side, scope: { selected, gridApi, refresh, currentPage } -->
        <slot
          name="right"
          :is-small="isSmall"
          :selected="selected"
          :selected-nodes="selectedNodes"
          :grid-api="gridApi"
          :column-api="columnApi"
          :refresh="refresh"
          :current-page="currentPage"
          :row-count="rowCount"
        ></slot>
      </template>
      <template #pagination>
        <Pagination
          @next="next"
          @previous="previous"
          :current-page="currentPage"
          :last-page="lastPage"
          :page-size="internalPageSize"
          :loading="loading"
          :row-count="rowCount"
          :suppress-page-buttons="suppressPageButtons"
          :total="total"
        />
      </template>
    </TableControlBar>
    <!-- Filter Section -->
    <FilterTagBar
      v-if="hasFilters"
      :filters="internalFilters"
      @clear="clearFilter"
      @clear:all="clearAllFilters"
    />
    <!-- Table -->
    <transition name="fade" mode="out-in">
      <ag-grid-vue
        v-show="view === 'table'"
        @row-clicked="rowClick($event)"
        @grid-ready="onGridReady"
        :class="classes"
        :grid-options="gridOptions"
        :column-defs="internalColumns"
        :row-model-type="rowModelType"
        :tooltip-show-delay="1"
        server-side-infinite-scroll="true"
        :no-rows-overlay-component-params="noRowsOverlayComponentParams"
      />
    </transition>
    <!-- @slot default Use to display table data differently, scope: { data, currentPage, columns, selected, refresh, gridApi } -->
    <slot
      v-if="view !== 'table'"
      :data="gridData"
      :current-page="currentPage"
      :columns="columns"
      :selected="selected"
      :selected-nodes="selectedNodes"
      :refresh="refresh"
      :row-count="rowCount"
      :grid-api="gridApi"
      :column-api="columnApi"
    >
    </slot>
    <!-- @slot modals - Use for modals that need access to the gridApi -->
    <div class="g-rows">
      <slot
        name="modals"
        :grid-api="gridApi"
        :selected="selected"
        :selected-nodes="selectedNodes"
        :refresh="refresh"
      ></slot>
    </div>
  </div>
</template>

<script>
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import Pagination from './controls/Pagination.vue';
import FilterInput from './controls/FilterInput.vue';
import { ModuleRegistry } from '@ag-grid-community/core';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import sharedTableComponents from './sharedTableComponents.mixin';
import { useJamfTable, sharedTableProps } from './table';
import { uuid } from '@/util';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  CsvExportModule,
  StatusBarModule,
]);

export default {
  name: 'JamfTable',
  mixins: [sharedTableComponents],
  components: { FilterInput, Pagination },
  setup(props, ctx) {
    const {
      canAccess,
      state,
      agGridClasses,
      hasFilters,
      internalColumns,
      internalFilters,
      gridApi,
      columnApi,
      noRowsOverlayComponentParams,
      hasError,
      selected,
      selectedNodes,
      refreshKey,
      clearAllFilters,
      clearFilter,
      setFilters,
      filterChanged,
      setInternalFilters,
      setInitialSortFromQuery,
      defaultMountSetup,
      defaultGridReadySetup,
      forceRecompute,
      setInternalColumns,
      rowClick,
    } = useJamfTable(props, ctx);

    return {
      ...state,
      agGridClasses,
      hasFilters,
      internalColumns,
      internalFilters,
      gridApi,
      columnApi,
      noRowsOverlayComponentParams,
      hasError,
      selected,
      selectedNodes,
      refreshKey,
      canAccess,
      clearAllFilters,
      clearFilter,
      setFilters,
      filterChanged,
      setInternalFilters,
      setInitialSortFromQuery,
      defaultMountSetup,
      defaultGridReadySetup,
      forceRecompute,
      setInternalColumns,
      rowClick,
    };
  },
  emits: [
    'view-changed',
    'pagination-changed',
    'grid-ready',
    'refresh',
    'selection-changed',
  ],
  props: {
    ...sharedTableProps,
    /** available params: { nextToken, pageSize, direction, field, filter } and optionally the api */
    getData: {
      type: Function,
      required: true,
    },
    /** Set the amount shown on each page */
    pageSize: Number,
    /** Set the amount loaded from the server and stored in the cache, default is same as pageSize,
     * but they do not need to be the same, be sure the number is always equal or greater then pageSizeå */
    cacheSize: Number,
    /** Use to toggle between viewing the table and the default slot, can use any string for showing the default slot */
    view: {
      type: String,
      default: 'table',
    },
    /** Set custom search options for search input. { defaultColumn: string, columnOptions: { key: [string] } | { key: [{ label, value }]} */
    searchParams: {
      type: Object,
      default: () => ({ defaultColumn: null, columnOptions: null }),
    },
    /** Hides paginiation count and controls */
    suppressPagination: Boolean,
    /** Hides the pagination controls, leaves count */
    suppressPageButtons: Boolean,
    /** This determines whether a row is selectable */
    isRowSelectable: Function,
    /** Shows page size change options */
    usePageSizeOptions: Boolean,
    /** Sets a fixed row count total for use with selection, alternative views and pagination */
    lastPageOverride: Number,
  },
  data() {
    return {
      pageSizeOptions: [10, 25, 45, 75, 100],
      rowModelType: 'serverSide',
      nextToken: null,
      backTokens: new Map(),
      token: null,
      gridData: [],
      hasLastPage: false,
      total: null,
    };
  },
  computed: {
    classes() {
      return {
        ...this.agGridClasses,
        'has-filters': this.hasFilters,
      };
    },
    validRoutePageSize() {
      return this.usePageSizeOptions &&
        this.pageSizeOptions.includes(+this.$route?.query?.size)
        ? +this.$route?.query?.size
        : null;
    },
    cacheBlockSize() {
      return this.cacheSize || this.validRoutePageSize || this.pageSize || 100;
    },
    internalPageSize() {
      return this.pageSize || this.validRoutePageSize || 100;
    },
    currentPage() {
      this.refreshKey;
      return this.gridApi?.paginationGetCurrentPage() + 1 || 1;
    },
    cacheBlockState() {
      this.refreshKey;
      return this.gridApi
        ? this.gridApi.getCacheBlockState()
        : { 0: { blockNumber: 0, pageStatus: null, endRow: 0, startRow: 0 } };
    },
    latestBlock() {
      return Object.values(this.cacheBlockState).pop().blockNumber;
    },
    currentBlock() {
      this.refreshKey;
      let block = this.currentPage - 1;
      if (this.internalPageSize !== this.cacheBlockSize) {
        Object.values(this.cacheBlockState).forEach((value) => {
          if (
            this.currentPage >= value.startRow &&
            this.currentPage <= value.endRow
          ) {
            block = value.blockNumber;
          }
        });
      }
      return block;
    },
    pageStatus() {
      this.refreshKey;
      return this.cacheBlockState[this.currentBlock]?.pageStatus;
    },
    lastPage() {
      this.refreshKey;
      return this.hasLastPage
        ? this.lastPageOverride || this.gridApi?.paginationGetTotalPages()
        : null;
    },
    rowCount() {
      this.refreshKey;
      return (
        this.lastPageOverride || this.gridApi?.paginationGetRowCount() || 0
      );
    },
  },
  watch: {
    view() {
      this.$emit('view-changed', {
        gridApi: this.gridApi,
        refresh: this.refresh,
        columnApi: this.columnApi,
        view: this.view,
      });
      this.forceRecompute();
    },
  },
  created() {
    this.setInternalColumns();
    this.gridOptions = {
      ...this.gridOptions,
      pagination: true,
      suppressPaginationPanel: true,
      paginationPageSize: this.internalPageSize,
      cacheBlockSize: this.cacheBlockSize,
      onFilterChanged: (params) => {
        if (!this.suppressQueryParams) {
          this.filterChanged(params);
        }
        this.refresh();
      },
      isRowSelectable: this.isRowSelectable,
      statusBar: this.usePageSizeOptions
        ? {
            statusPanels: [
              {
                statusPanel: 'StatusPanelPageSize',
                align: 'left',
                statusPanelParams: {
                  setCacheBlockSize: async (value) =>
                    this.setCacheBlockSize(value),
                  pageSizeOptions: this.pageSizeOptions,
                },
              },
            ],
          }
        : null,
      getRowId: () => {
        // needed for Row Selection, more info: https://www.ag-grid.com/vue-data-grid/server-side-model-configuration/#providing-row-ids
        return uuid();
      },
      ...this.options,
    };
    this.forceRecompute();
  },
  mounted() {
    this.defaultMountSetup();
    this.calculateHeight();
  },
  beforeUnmount() {
    this.gridApi?.removeEventListener('selectionChanged');
    this.gridApi?.removeEventListener('paginationChanged');
    this.forceRecompute();
  },
  methods: {
    calculateHeight() {
      let clientHeight = this.$refs?.table?.parentElement?.clientHeight;
      if (
        typeof this.$refs?.table?.previousSibling?.clientHeight === 'number'
      ) {
        clientHeight =
          clientHeight - this.$refs.table.previousSibling?.clientHeight;
      }
      this.$refs?.table?.style.setProperty(
        '--table-calc-height',
        clientHeight + 'px'
      );
    },
    async setCacheBlockSize(value) {
      try {
        const query = { ...this.$route?.query, size: value };
        await this.$router.replace({ query });
      } catch {
        // do nothing
      } finally {
        this.gridApi.paginationSetPageSize(value);
        this.refresh(value);
      }
    },
    async onGridReady(params) {
      this.defaultGridReadySetup(params);

      if (this.$route?.query) {
        await this.setFilters();
        this.setInitialSortFromQuery(params);
      }

      this.gridApi.addEventListener('paginationChanged', (params) => {
        this.forceRecompute();
        this.$emit('pagination-changed', {
          ...params,
          currentPage: this.currentPage,
          currentBlock: this.currentBlock,
          pageStatus: this.pageStatus,
          hasData: this.gridData.length >= this.currentPage,
        });
      });

      this.gridApi.setCacheBlockSize(this.cacheBlockSize);
      this.gridApi.setServerSideDatasource({ getRows: this.setData });
      this.$emit('grid-ready', params);
    },
    async setData({ request: params, success, fail }) {
      this.hasError = false;
      this.loading = true;
      this.gridData = [];
      const { field, direction } = this.getSort(params.sortModel);
      const filter = this.getFilters(params.filterModel);
      let rowCount;
      this.gridApi.showLoadingOverlay();
      try {
        const response = await this.getData(
          {
            nextToken: this.token,
            pageSize: this.cacheBlockSize,
            direction,
            field,
            filter,
          },
          {
            gridApi: this.gridApi,
            columnApi: this.columnApi,
          }
        );

        if (response.items.length === 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }

        const { next, total } = response.pageInfo
          ? response.pageInfo
          : { next: null, total: null };
        this.total = total;
        rowCount = total;
        if (!next && !total) {
          rowCount =
            this.gridApi.paginationGetCurrentPage() === 0
              ? response.items.length
              : response.items.length + params.startRow;
        }

        this.nextToken = next;
        success({
          rowData: response.items,
          rowCount,
        });

        if (this.$slots.default) {
          // set gridData if default slot in use
          this.gridApi.forEachNode((node) => {
            if (node.data) this.gridData.push(node.data);
          });
        }
      } catch {
        this.hasError = true;
        this.gridApi.showNoRowsOverlay();
        fail();
      }
      this.hasLastPage = this.gridApi.paginationIsLastPageFound();
      this.loading = false;
      this.forceRecompute();
    },
    getFilters(filterModel) {
      const columnsToFilter = Object.keys(filterModel);
      let filters = columnsToFilter.length > 1 ? { and: [] } : {};
      this.internalFilters = [];

      if (columnsToFilter.length > 0) {
        columnsToFilter.forEach((column, index) => {
          let filter = {};
          const { operators } = filterModel[column];
          if (operators && operators.size >= 1) {
            // set initial filter with no operators
            this.setInternalFilters(column, filterModel[column]);
            filter = {
              ...this.buildCommonServerFilters(column, filterModel[column]),
            };
            // add each operator to the filter
            operators.forEach((op) => {
              filter = { ...filter, [op]: [] };
              filterModel[column][op].forEach((f) => {
                this.setInternalFilters(column, f, op);
                filter[op].push(this.buildCommonServerFilters(column, f));
              });
            });
          } else {
            this.setInternalFilters(column, filterModel[column]);
            filter = this.buildCommonServerFilters(column, filterModel[column]);
          }
          if (index === 0) {
            filters = { ...filters, ...filter };
          } else {
            filters.and.push(filter);
          }
        });
      }
      return columnsToFilter.length > 0 ? filters : null;
    },
    buildCommonServerFilters(column, filterModel) {
      const { filter, type } = filterModel;
      return { [column]: { [type]: filter } };
    },
    getSort(sortModel) {
      let field;
      let direction;
      if (sortModel.length > 0) {
        field = sortModel[0].colId;
        direction = sortModel[0].sort.toUpperCase();
      }
      return { field, direction };
    },
    next() {
      this.token = this.nextToken;
      this.gridApi.paginationGoToNextPage();
      this.backTokens.set(this.currentBlock, this.nextToken);
      this.forceRecompute();
    },
    previous() {
      this.token = this.backTokens.get(this.currentBlock);
      this.backTokens.delete(this.currentBlock);
      this.gridApi.paginationGoToPreviousPage();
      this.forceRecompute();
    },
    reset() {
      this.token = null;
      this.backTokens.clear();
      this.selected = [];
      this.selectedNodes = [];
      this.gridApi.deselectAll();
      this.forceRecompute();
    },
    refresh(value) {
      this.reset();
      this.gridApi.setCacheBlockSize(value || this.cacheBlockSize);
      this.gridApi.paginationGoToFirstPage();
      this.gridApi.refreshServerSide({ route: [], purge: true });
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="scss" src="@/components/table/table.scss" />
