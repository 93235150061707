<template>
  <j-icon :data="icon" :original="managed" :data-test-id="testId" />
</template>

<script>
import team from '@jcon/team.svg';
import jamf from '@icon/jamf.svg';

export default {
  name: 'ManagedIndicator',
  props: {
    managed: Boolean,
  },
  computed: {
    icon() {
      return this.managed ? jamf : team;
    },
    testId() {
      return this.managed ? 'managed' : 'custom';
    },
  },
};
</script>
