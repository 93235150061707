<template>
  <div class="gp-content">
    <Errors v-if="!sectionAccess" />
    <template v-else>
      <slot v-if="hasDefault"></slot>
      <router-view
        v-else-if="isFeatureEnabled(sectionFlag)"
        v-slot="{ Component }"
        name="flag"
      >
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
      <router-view v-else v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </template>
  </div>
</template>

<script>
import Errors from './Errors.vue';
import { mapGetters } from 'vuex';
import { useRouteHelpers } from '@/composables/route-helpers';

export default {
  name: 'Section',
  props: {
    /** set in router when nested routes do not match top level permissions,
     *  e.g. { permissions: [RBAC_RESOURCE.PLAN], ...other modifiers } */
    rbac: Object,
  },
  setup() {
    const { hasAccessToSection } = useRouteHelpers();
    return { hasAccessToSection };
  },
  components: { Errors },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    sectionFlag() {
      return this.$route?.meta?.flag;
    },
    hasDefault() {
      let hasDefault = true;
      if (
        !this.$slots.default ||
        this.$slots.default()[0].children.length < 1
      ) {
        hasDefault = false;
      }
      return hasDefault;
    },
    sectionAccess() {
      return this.hasAccessToSection(this.rbac);
    },
  },
};
</script>
