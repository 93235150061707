<template>
  <div>
    <DeleteBox
      class="mt-1"
      :text="`Are you sure you want to delete API Client '${apiClient.name}'?`"
      loader-text="Deleting API Client"
      :show-loader="showLoader"
    >
      <j-delete-button
        @click="submit()"
        style-type="danger"
        label="Delete API Client"
      />
    </DeleteBox>
  </div>
</template>

<script>
import DeleteBox from '@/components/DeleteBox.vue';

export default {
  name: 'ApiClientDelete',
  components: { DeleteBox },
  props: {
    apiClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLoader: false,
    };
  },
  methods: {
    async submit() {
      this.showLoader = true;
      const apiClient = await this.$store.dispatch('primary/deleteApiClient', {
        clientId: this.apiClient.clientId,
      });
      this.showLoader = false;
      if (apiClient) {
        this.$router.push({ name: 'apiClients.index' });
      }
    },
  },
};
</script>
