import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import DataModelDocumentation from './tabs/DataModelDocumentation.vue';

export default [
  {
    path: '/documentation',
    component: Section,
    name: 'documentation',
    meta: {
      breadcrumb: {
        linkName: 'documentation.index',
        linkText: 'Documentation',
      },
      permissions: [RBAC_RESOURCE.ANALYTIC],
    },
    children: [
      {
        path: '',
        component: DataModelDocumentation,
        name: 'documentation.index',
      },
    ],
  },
];
