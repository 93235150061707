<template>
  <JamfTable
    v-if="columns"
    :get-data="getData"
    :page-size="50"
    :cache-size="50"
    :search-params="{ defaultColumn: 'name', columnOptions }"
    :columns="columns"
  >
    <template #right="{ refresh, gridApi }">
      <j-create-button
        @click="$router.push({ name: 'admin.tenants.index.create' })"
        label="Create Tenant"
      />
      <j-checkbox
        v-model="isFull"
        @change="changeColumns(refresh, gridApi)"
        class="is-full"
      >
        Show More Tenant Information
      </j-checkbox>
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { mapGetters } from 'vuex';
import { columnOptionsTimeIntervals } from '@/components/table/utils/search-options.common';
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import { renameFilterKeys } from '@/components/table/utils/rename-filter-keys';
import { success, disabled } from '@/util/constants/feedback.icons';
import expand from '@jcon/expand-2.svg';
import minus from '@jcon/minus.svg';
import { useTenants } from '@/modules/administrative/admin/tabs/tenants/tenants.js';

export default {
  name: 'Tenants',
  components: { JamfTable },
  setup() {
    const { buildVanity, transformAccountBusinessType } = useTenants();

    return {
      buildVanity,
      transformAccountBusinessType,
    };
  },
  data() {
    return {
      isFull: false,
      columnOptions: {
        created: columnOptionsTimeIntervals,
        updated: columnOptionsTimeIntervals,
        releaseGroup: ['DEV', 'ALPHA', 'BETA', 'PROD'],
      },
      columns: null,
      additionalColumns: [
        {
          headerName: 'CAID',
          field: 'caid',
          type: ['codeColumn', 'textFilterColumn'],
        },
        {
          headerName: 'CSRID',
          field: 'csrid',
          type: ['codeColumn', 'textFilterColumn'],
        },
      ],
    };
  },
  mounted() {
    this.columns = [
      {
        headerName: 'Tenant Access',
        field: 'active',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        type: ['iconColumn'],
        cellRendererParams: {
          setIcon: (value) =>
            value
              ? { icon: success, color: 'var(--color-success-base)' }
              : { icon: disabled, color: 'var(--color-danger-base)' },
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        minWidth: 200,
        sortable: true,
        type: ['linkColumn', 'textFilterColumn'],
        cellRendererParams: {
          route: (params) => ({
            name: 'admin.tenants.index.detail',
            params: { id: params.data ? params.data.uuid : null },
          }),
        },
      },
      {
        headerName: 'Domain',
        field: 'hd',
        colId: 'domain',
        sortable: true,
        type: 'textFilterColumn',
      },
      {
        headerName: 'Vanity',
        field: 'vanity',
        sortable: true,
        flex: 2,
        minWidth: 400,
        type: ['iconColumn', 'textFilterColumn'],
        valueFormatter: ({ value }) => this.buildVanity(value),
        cellClass: 'is-link',
        onCellClicked: (params) => {
          window.open(`https://${this.buildVanity(params?.value)}`, 'vanity');
        },
        cellRendererParams: {
          setIcon: (value) => ({
            icon: expand,
            value,
            isSmall: true,
            position: 'trailing',
          }),
        },
      },
      {
        headerName: 'EULA',
        field: 'eulaAccepted',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        type: ['iconColumn'],
        cellRendererParams: {
          setIcon: (value) =>
            value
              ? { icon: success, color: 'var(--color-success-base)' }
              : { icon: minus },
        },
      },
      {
        headerName: 'Release Group',
        field: 'releaseGroup',
        type: ['tagsColumn', 'textFilterColumn'],
        filterParams: {
          suppressTypeOptions: true,
          defaultOption: FILTER_TYPES.EQUALS,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: 'Account Type',
        field: 'accountBusinessType',
        maxWidth: 150,
        valueFormatter: ({ value }) => {
          return this.transformAccountBusinessType(value);
        },
      },
      {
        headerName: this.isFeatureEnabled('HC_MODE')
          ? 'High compliance deployments'
          : 'Compliance Reporter only',
        field: 'complianceReporterEnabled',
        type: ['iconColumn'],
        minWidth: 150,
        cellRendererParams: {
          setIcon: (value) =>
            value
              ? {
                  icon: success,
                  color: 'var(--color-success-base)',
                }
              : minus,
        },
      },
      {
        headerName: 'Cluster Identifier',
        field: 'cluster_id',
        sortable: true,
        type: 'textFilterColumn',
        maxWidth: 125,
      },
      {
        headerName: 'Updated',
        field: 'updated',
        type: 'dateFilterColumn',
        sortable: true,
      },
      {
        headerName: 'Created',
        field: 'created',
        type: 'dateFilterColumn',
        sortable: true,
        sort: 'desc',
      },
    ];
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
  },
  methods: {
    async getData(params) {
      let { filter, field } = params;
      if (field === 'domain') field = 'hd';
      filter = renameFilterKeys(filter, 'domain', 'hd');

      return this.$store.dispatch(
        `admin/listOrganizations${this.isFull ? '' : 'Lite'}`,
        {
          ...params,
          field,
          filter,
        }
      );
    },
    changeColumns(refresh, gridApi) {
      const newColumns = this.isFull
        ? [...this.columns, ...this.additionalColumns]
        : this.columns;
      gridApi.setColumnDefs(newColumns);
      refresh();
    },
  },
};
</script>
