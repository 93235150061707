<template>
  <ManagedIndicator :managed="params.value" />
</template>

<script>
import ManagedIndicator from '@/components/ManagedIndicator.vue';

export default {
  components: { ManagedIndicator },
  name: 'TableCellManaged',
};
</script>
