import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/modules/Main.vue';
import Initialize from '@/modules/Initialize.vue';
import Logout from '@/modules/Logout.vue';
import EULA from '@/modules/eula/EULA.vue';

import AuthLogin from '@/auth/views/AuthLogin.vue';
import AuthCallback from '@/auth/views/AuthCallback.vue';
import AuthRedirect from '@/auth/views/AuthRedirect.vue';

import analyticsRoutes from '@/modules/analytics/analytic.route';
import adminRoutes from '@/modules/administrative/admin/admin.route';
import documentRoutes from '@/modules/administrative/documentation/documentation.route';
import unifiedloggingRoute from '@/modules/unified-logging/unifiedlogging.route';
import telemetryRoute from '@/modules/telemetry/telemetry.route';

import ThreatPrevention from '@/modules/threat-prevention/threat-prevent.routes';
import UsbControls from '@/modules/usb-controls/usb-controls.route.js';
import Actions from '@/modules/actions/actions.route';
import Insights from '@/modules/insights/insights.route';
import Compliance from '@/modules/compliance/compliance.route.js';
import Alerts from '@/modules/alerts/alerts.route';
import AuditLogs from '@/modules/audit-logs/auditlogs.route';
import Account from '@/modules/administrative/account/account.route';
import Data from '@/modules/administrative/data/data.route';
import ApiClients from '@/modules/administrative/api-clients/api-clients.route';
import Downloads from '@/modules/administrative/downloads/downloads.route';
import Plans from '@/modules/plans/plans.route';
import Overviews from '@/modules/overviews/overviews.route';
import Computers from '@/modules/computers/computers.route';

import AuthGuard from './auth/auth.guard';
import StoreQuery from './guards/storeQuery';

const pageRoutes = [
  {
    path: '',
    redirect: { name: 'overview.index' },
    name: 'index',
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'index' },
    },
    {
      path: '/login',
      name: 'login',
      component: AuthLogin,
    },
    {
      path: '/initialize',
      name: 'initialize',
      component: Initialize,
    },
    {
      path: '/redirect',
      name: 'redirect',
      component: AuthRedirect,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/callback',
      name: 'callback',
      component: AuthCallback,
    },
    {
      path: '/eula',
      name: 'eula',
      component: EULA,
    },
    {
      path: '/',
      name: 'main',
      component: Main,
      children: pageRoutes.concat(
        Overviews,
        analyticsRoutes,
        Computers,
        Plans,
        Actions,
        adminRoutes,
        Account,
        Data,
        documentRoutes,
        unifiedloggingRoute,
        telemetryRoute,
        ApiClients,
        ThreatPrevention,
        UsbControls,
        Insights,
        Compliance,
        Alerts,
        AuditLogs,
        Downloads
      ),
    },
  ],
});

router.beforeEach(AuthGuard);
router.afterEach(StoreQuery);

export default router;
