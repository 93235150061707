<template>
  <div>
    <Card class="no-related-info">
      {{ title }}
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  components: { Card },
  name: 'NoRelatedInfo',
  props: {
    title: {
      type: String,
      default: 'No Related Information.',
    },
  },
};
</script>

<style lang="scss" scoped>
.no-related-info {
  background: var(--color-structure-base);
  text-align: center;
  color: var(--color-font-secondary);
  box-shadow: none;
  border: 0;
}
</style>
