<template>
  <TableTooltip :is-visible="isVisible">
    <div class="g-rows">
      <j-tag style-type="secondary">Assigned Directly</j-tag>
      <j-tag>Assigned via a Group</j-tag>
    </div>
  </TableTooltip>
</template>

<script>
import TableTooltip from '@/components/table/framework-components/TableTooltip.vue';

export default {
  name: 'RolesTooltip',
  components: { TableTooltip },
  props: ['params'],
  computed: {
    isVisible() {
      return (
        !!this.params.value?.length && !!this.params.data.assignedGroups?.length
      );
    },
  },
};
</script>
