<template>
  <BaseFilterStyle
    :include-operators="hasActiveFilter"
    :primary-action="apply"
    :secondary-action="clear"
    :disable-action="!value"
  >
    <template #options>
      <j-select
        v-if="hasOptions"
        v-model="type"
        :class="['table-filter-select', 'table-filter-' + filterType]"
        :options="typeOptions"
        :searchable="false"
      />
    </template>
    <template #operators>
      <j-radio
        v-for="(op, key) in operators"
        :key="key"
        v-model="operator"
        :native-value="op"
        name="table-filter-text"
        >{{ op }}</j-radio
      >
    </template>
    <j-input
      ref="input"
      v-model="value"
      data-testid="filter-input-value"
      :placeholder="placeholder"
      @keydown.enter.stop="apply"
      :type="inputType"
    />
  </BaseFilterStyle>
</template>

<script>
import filterMixin from './filter.mixin';

export default {
  name: 'TableFilterText',
  mixins: [filterMixin],
};
</script>
