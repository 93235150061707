<template>
  <transition name="height" mode="out-in">
    <div key="loading" v-if="loading" :class="classes"><gp-loader /></div>
    <div key="content" v-else :class="classes" v-on="keyEvent" v-bind="$attrs">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Card',
  props: {
    loading: Boolean,
  },
  compatConfig: { MODE: 3 },
  computed: {
    hasClick() {
      const clicks = Object.keys(this.$attrs).filter((key) =>
        key.includes('onClick')
      );
      return clicks.length > 0;
    },
    classes() {
      return {
        'has-click': this.hasClick,
        'card-container': true,
      };
    },
    keyEvent() {
      return this.hasClick ? { keydown: this.handleKeyUp } : {};
    },
  },
  methods: {
    handleKeyUp(e) {
      if (['Enter', ' '].includes(e.key)) {
        /**
         * Click event will be triggered on ENTER or SPACE as well.
         * @event click
         */
        this.$attrs?.onClick?.();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  --color-input-base: var(--color-structure-secondary);
  @include transition(background-color);
  background: var(--color-card-primary);
  padding: spacing(2);
  color: var(--color-font-base);
  box-shadow: var(--size-shadow-xsmall) var(--color-card-shadow-base);
  border-radius: var(--size-card-border-radius);
  border: 1px solid;
  border-color: var(--color-border-contrast-only, var(--color-card-border));
  max-width: 100%;

  &.secondary {
    background: var(--color-card-primary);
  }

  &.info {
    background: var(--color-card-info);
  }

  &.danger {
    background: var(--color-card-danger);
    color: var(--color-card-danger-font);
  }

  &.inherit {
    background: inherit;
    color: inherit;
  }
}

.has-click {
  cursor: pointer;
  &:hover {
    background-color: var(--color-card-primary-active);
  }

  &.info:hover {
    background-color: var(--color-card-info-active);
  }
  &.secondary:hover {
    background-color: var(--color-card-secondary-active);
  }
  &.danger:hover {
    background-color: var(--color-card-danger-active);
  }

  &:focus,
  &:active {
    @include has-focus;
  }
}
</style>
