<template>
  <div id="login">
    <Card class="login-container">
      <div class="primary-color-wrap">
        <j-icon
          data="@icon/protect_color_knockout.svg"
          height="50"
          width="250"
          color="var(--color-logo)"
        />
      </div>
      <div class="p-4 g-rows">
        <slot></slot>
      </div>
    </Card>
    <div class="login-message">
      <slot name="message"></slot>
      <transition>
        <j-notification v-if="error" type="danger">
          <p>{{ error }}</p>
        </j-notification>
      </transition>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'LoginBase',
  components: {
    Card,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  height: 100vh;
  margin: 0 auto;
  padding: spacing();
  background: var(--color-structure-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .login-container {
    background: var(--color-structure-secondary);
    @include shadow(medium);
    @include grid(0);
    padding: 0;
    max-width: 350px;
  }
  .primary-color-wrap {
    padding: spacing(2);
    border-radius: spacing() spacing() 0 0;
    background: var(--color-logo-background);
  }
  .login-message {
    @include grid;
    padding: spacing(3);
    max-width: 650px;
    justify-items: center;
  }
}
</style>
