import LIST_UNIFIED_LOGGING_FILTER_LIST from './queries/listUnifiedLoggingFilters.gql';
import GET_UNIFIED_LOGGING_FILTER from './queries/getUnifiedLoggingFilter.gql';
import CREATE_UNIFIED_LOGGING_FILTER from './mutations/createUnifiedLoggingFilter.gql';
import UPDATE_UNIFIED_LOGGING_FILTER from './mutations/updateUnifiedLoggingFilter.gql';
import DELETE_UNIFIED_LOGGING_FILTER from './mutations/deleteUnifiedLoggingFilter.gql';

export default {
  state: {
    unifiedLoggingFilter: null,
    unifiedLoggingFilters: [],
    unifiedLoggingFiltersNextToken: null,
  },
  mutations: {
    SET_UNIFIED_LOGGING_FILTER(state, payload) {
      state.unifiedLoggingFilter = payload;
    },
    SET_UNIFIED_LOGGING_FILTERS(state, payload) {
      state.unifiedLoggingFilters = payload.items;
      state.unifiedLoggingFiltersNextToken = payload.nextToken;
      state.unifiedLoggingFiltersNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    // mutations
    async createUnifiedLoggingFilter({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_UNIFIED_LOGGING_FILTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit(
          'SET_UNIFIED_LOGGING_FILTER',
          result.data.createUnifiedLoggingFilter
        );
        return result.data.createUnifiedLoggingFilter;
      }
      return result;
    },
    async updateUnifiedLoggingFilter({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_UNIFIED_LOGGING_FILTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit(
          'SET_UNIFIED_LOGGING_FILTER',
          result.data.updateUnifiedLoggingFilter
        );
        return result.data.updateUnifiedLoggingFilter;
      }
      return result;
    },
    async deleteUnifiedLoggingFilter({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_UNIFIED_LOGGING_FILTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_UNIFIED_LOGGING_FILTER', null);
        return result.data.deleteUnifiedLoggingFilter;
      }
      return result;
    },
    // queries
    async listUnifiedLoggingFilters({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_UNIFIED_LOGGING_FILTER_LIST,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit(
          'SET_UNIFIED_LOGGING_FILTERS',
          result.data.listUnifiedLoggingFilters
        );
        return result.data.listUnifiedLoggingFilters;
      }
      return result;
    },
    async getUnifiedLoggingFilter({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_UNIFIED_LOGGING_FILTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit(
          'SET_UNIFIED_LOGGING_FILTER',
          result.data.getUnifiedLoggingFilter
        );
        return result.data.getUnifiedLoggingFilter;
      }
      return result;
    },
  },
};
