import Section from '@/components/Section.vue';
import Downloads from './Downloads.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default [
  {
    path: '/downloads',
    component: Section,
    name: 'downloads',
    meta: {
      breadcrumb: {
        linkText: 'Downloads',
      },
      permissions: [RBAC_RESOURCE.DOWNLOAD],
    },
    children: [
      {
        path: '',
        component: Downloads,
        name: 'downloads.index',
      },
    ],
  },
];
