import gql from 'graphql-tag';
import { USBControlSetFields } from './usb-controls.fragment';

export const CREATE_USB_CONTROL_SET = (getPermissions) => gql`
  mutation createUSBControlSet(
    $name: String!
    $description: String
    $defaultMountAction: USBCONTROL_MOUNT_ACTION_TYPE_ENUM!
    $defaultMessageAction: String
    $rules: [USBControlRuleInput!]!
  ) {
    createUSBControlSet(
      input: {
        name: $name
        description: $description
        defaultMountAction: $defaultMountAction
        defaultMessageAction: $defaultMessageAction
        rules: $rules
      }
    ) {
      ...USBControlSetFields
    }
  }
  ${USBControlSetFields(getPermissions)}
`;

export const UPDATE_USB_CONTROL_SET = (getPermissions) => gql`
  mutation updateUSBControlSet(
    $id: ID!
    $name: String!
    $description: String
    $defaultMountAction: USBCONTROL_MOUNT_ACTION_TYPE_ENUM!
    $defaultMessageAction: String
    $rules: [USBControlRuleInput!]!
  ) {
    updateUSBControlSet(
      id: $id
      input: {
        name: $name
        description: $description
        defaultMountAction: $defaultMountAction
        defaultMessageAction: $defaultMessageAction
        rules: $rules
      }
    ) {
      ...USBControlSetFields
    }
  }
  ${USBControlSetFields(getPermissions)}
`;

export const DELETE_USB_CONTROL_SET = gql`
  mutation deleteUSBControlSet($id: ID!) {
    deleteUSBControlSet(id: $id) {
      id
    }
  }
`;
