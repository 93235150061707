<template>
  <div class="gp-content" role="tabpanel">
    <div class="dashboard-container scroll-vertical">
      <div class="g-wrap">
        <!-- Place new widgets here -->
      </div>
      <ComplianceRulesTable />
    </div>
  </div>
</template>

<script>
import ComplianceRulesTable from './components/ComplianceRulesTable';

export default {
  name: 'ComplianceOverview',
  components: { ComplianceRulesTable },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  @include grid;
  @include grid-rows(max-content);
  width: 100%;
  height: 100%;
  padding: spacing(2);

  > div {
    @include grid;
    @include grid-rows(max-content 338px);
    height: 100%;
  }
}
</style>
