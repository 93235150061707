<template>
  <SplitPanel
    display-mobile-as="below"
    :class="{ 'is-mobile': isMobile }"
    @resize="onResize"
    :left-classes="panelClasses"
    :right-classes="panelClasses"
    :split-by="{ left: '40%', right: 'auto' }"
  >
    <template #default>
      <div id="default" class="g-rows mb-1">
        <div class="g-cols ai-center">
          <h1 class="h4 f-cols ai-center">
            <slot name="title"></slot>
            <transition name="fade" mode="in-out">
              <j-icon
                v-if="v$.$error"
                class="ml-1"
                data="@jcon/error.svg"
                height="16"
                color="var(--color-danger-base)"
              />
            </transition>
          </h1>
          <j-button
            v-if="!isReadOnly && !suppressAddButton"
            @click="onAdd"
            style-type="ghost-primary"
            class="js-end"
            data-feature-id="open-modal-add"
            ><template #leading><j-icon data="@icon/plus-small.svg" /></template
            >Add</j-button
          >
        </div>
        <template v-if="isMobile && itemKeys.length > 0">
          <j-dropdown :options="itemKeys" class="item-selector">
            <template #target="{ styleIndicator }">
              <j-button :class="styleIndicator" style-type="secondary">
                <template #trailing>
                  <j-icon data="@jcon/chevron-small.svg" />
                </template>
                <slot
                  name="item-title"
                  :item="selected"
                  :key-value="selectedKey"
                  :is-mobile="isMobile"
                ></slot>
              </j-button>
            </template>
            <template #item="{ item }">
              <div
                role="button"
                :class="{
                  'is-selected': selectedKey === item,
                  'list-item': true,
                }"
                @click="() => selectItem(item)"
              >
                <slot
                  name="item"
                  :item="internalItems[item]"
                  :key-value="item"
                  :is-mobile="isMobile"
                  :error-count="hasItemErrorCount(item)"
                ></slot>
              </div>
            </template>
          </j-dropdown>
        </template>
        <template v-else>
          <Card
            v-for="(item, key) in internalItems"
            :key="key"
            tabindex="0"
            @click.capture="selectItem(key)"
            @keydown.enter="selectItem(key)"
            :data-feature-id="`item-${key}`"
            :class="{
              item: true,
              'is-selected': selectedKey === key,
              'has-error': hasItemErrorCount(key),
            }"
          >
            <div class="g-cols ai-center">
              <slot
                name="item-title"
                :item="item"
                :key-value="key"
                :error-count="hasItemErrorCount(key)"
                :is-mobile="isMobile"
              ></slot>
              <j-remove-button
                v-if="!isReadOnly"
                class="js-end"
                data-feature-id="item-remove"
                @click="remove(key)"
              />
            </div>
            <slot
              name="item"
              :item="item"
              :key-value="key"
              :is-mobile="isMobile"
              :error-count="hasItemErrorCount(key)"
            ></slot>
          </Card>
        </template>
      </div>
    </template>
    <template v-if="selected" #right>
      <slot
        name="selection"
        :item="selected"
        :key-value="selectedKey"
        :is-mobile="isMobile"
      ></slot>
    </template>
  </SplitPanel>
  <j-modal
    v-if="!isReadOnly"
    v-model="isAddModalActive"
    class="unset-overflow"
    :body-is-scrollable="false"
  >
    <p class="g-rows">
      <slot name="add-modal" :select-item="selectItem"></slot>
    </p>
    <template #actions="{ close }">
      <j-button
        @click="close"
        style-type="secondary"
        data-feature-id="item-cancel"
      >
        Cancel
      </j-button>
      <j-button @click="addItem" class="js-end" data-feature-id="item-add">
        <template #leading>
          <j-icon v-if="!hasItem" data="@icon/plus-small.svg" />
        </template>
        {{ addItemBtnText }}
      </j-button>
    </template>
  </j-modal>
</template>

<script>
import SplitPanel from '@/components/SplitPanel.vue';
import useVuelidate from '@vuelidate/core';
import Card from './Card.vue';

const panelClasses = ['p-2', 'scroll-vertical'];

export default {
  name: 'SplitPanelList',
  compatConfig: {
    MODE: 3,
  },
  setup() {
    // collects child component errors
    const v$ = useVuelidate();

    return { v$ };
  },
  props: {
    items: { type: Object, required: true },
    isReadOnly: Boolean,
    keyToAdd: String,
    suppressAddButton: Boolean,
  },
  emits: ['select', 'remove', 'add', 'update:items'],
  components: { SplitPanel, Card },
  data() {
    return {
      selected: null,
      selectedKey: '',
      panelClasses,
      isMobile: false,
      isAddModalActive: false,
      itemErrors: {},
      invalidItemCount: 0,
    };
  },
  validations() {
    return {
      items: {
        hasInvalidItemCount: () => !this.invalidItemCount,
      },
    };
  },
  computed: {
    internalItems: {
      get() {
        return { ...this.items };
      },
      set(value) {
        this.$emit('update:items', value);
      },
    },
    hasItem() {
      return this.keyToAdd ? !!this.internalItems[this.keyToAdd] : false;
    },
    addItemBtnText() {
      return this.hasItem ? 'Select Existing' : 'Add';
    },
    itemKeys() {
      return Object.keys(this.internalItems);
    },
  },
  mounted() {
    // select first item
    this.selectItem(this.itemKeys[0]);
  },
  watch: {
    // track item errors count and set hasInvalidItemCount with state so vuelidate can also track
    'v$.$silentErrors': {
      handler(value) {
        this.setErrorByItems(value, this.selectedKey);
      },
      deep: true,
    },
  },
  methods: {
    setErrorByItems(value, key) {
      if (key) {
        const errors = value.filter((error) => error?.$property !== 'items');
        if (errors.length !== this.itemErrors[key]?.length) {
          this.itemErrors[key] = [...errors];
          this.invalidItemCount = Object.values(this.itemErrors).flat()?.length;
        }
      }
    },
    selectItem(key) {
      this.selectedKey = key;
      this.selected = this.internalItems[key];
      this.$emit('select', key);
    },
    hasItemErrorCount(key) {
      if (this.v$.$error) {
        return this.itemErrors?.[key]?.length;
      }
      return 0;
    },
    onResize(isMobile) {
      // set classes
      // collapse menu
      this.panelClasses = isMobile ? ['p-2'] : panelClasses;
      this.isMobile = isMobile;
    },
    async addItem() {
      const currentKeysState = [...this.itemKeys];
      if (!this.hasItem) {
        this.$emit('add');
        await this.$nextTick();
      }
      const [newKey] = this.itemKeys.filter(
        (key) => !currentKeysState.includes(key)
      );

      this.isAddModalActive = false;
      this.selectItem(this.keyToAdd || newKey);
    },
    async remove(key) {
      this.$emit('remove', key);
      await this.$nextTick();
      this.setErrorByItems([], key);
      if (this.selectedKey === key) {
        this.selectItem(this.itemKeys.length ? this.itemKeys[0] : null);
      }
    },
    onAdd() {
      this.isAddModalActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-mobile {
  height: auto;
  overflow: unset;
}

.item-selector {
  --color-action-secondary-base: var(--color-secondary-base);
  --color-action-secondary-active: var(--color-secondary-active);
  --size-border-radius-action-base: 100px;
  --color-list-base: var(--color-action-secondary-base);
  --color-list-highlight-base: var(--color-action-secondary-active);
  max-width: 100%;
  --size-select-list-width: 100%;

  button {
    width: 100%;
    justify-content: space-between;
  }
  .list-item {
    margin: spacing(-1);
    padding: spacing();
  }

  .is-selected {
    background-color: var(--color-list-highlight-base);
    cursor: not-allowed;
  }
}

.item {
  background: transparent;
  border: 1px solid var(--color-border-base);
  position: relative;
  &:hover,
  &.is-selected {
    background: var(--color-structure-secondary);
  }
  &.is-selected {
    border-color: var(--brand-4);
    &::after {
      content: '';
      position: absolute;
      right: -10px;
      top: 42%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 0 12px 12px;
      border-color: transparent transparent transparent
        var(--color-structure-secondary);
      filter: drop-shadow(2px 0px 0px var(--brand-4));
    }

    &.has-error {
      border-color: var(--color-danger-active);
      &::after {
        filter: drop-shadow(2px 0px 0px var(--color-danger-active));
      }
    }
  }

  &.has-error {
    border-color: var(--color-danger-base);
  }
}
</style>
