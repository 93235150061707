<template>
  <div class="g-cols max-c ai-center group">
    <p class="group-title">
      {{ title ? splitCamelCase(title) : 'None' }} ({{ count }})
      <span v-if="params.checkbox" class="helper-text">
        {{ selectedCount }} selected
      </span>
    </p>
    <template v-for="(value, level) in severity">
      <j-tag
        v-if="value !== 0"
        :key="level"
        class="subtle"
        is-rounded
        @click="() => filter(level)"
        ><SeverityIndicator :level="level" />
        <strong class="ml-1">{{ value }}</strong></j-tag
      ></template
    >
  </div>
</template>

<script>
import SeverityIndicator from '@/components/SeverityIndicator.vue';
import { mapState } from 'vuex';
import { splitCamelCase } from '@/util';

export default {
  props: ['params'],
  components: { SeverityIndicator },
  name: 'AnalyticTableGroupCell',
  data() {
    return {
      title: null,
      severity: null,
      count: null,
      selectedCount: 0,
    };
  },
  computed: {
    ...mapState({
      quickFilter: (state) => state.app.quickFilter,
      columnFilters: (state) => state.app.columnFilters,
    }),
  },
  watch: {
    quickFilter() {
      this.refreshGroup();
    },
    columnFilters() {
      this.refreshGroup();
    },
  },
  mounted() {
    this.count = this.params?.node?.allChildrenCount;
    this.severity = this.params?.node?.aggData;
    const { value, valueFormatted } = this.params;
    this.getSelectedCount();
    this.title = valueFormatted || value;
    this.params.api?.addEventListener(
      'selectionChanged',
      this.getSelectedCount
    );
  },
  methods: {
    getSelectedCount() {
      this.selectedCount = 0;
      this.params?.node?.childrenAfterGroup?.forEach((node) => {
        if (node?.selected) {
          this.selectedCount += 1;
        }
      });
    },
    splitCamelCase,
    refreshGroup() {
      this.params.api.refreshCells();
      this.count = this.params?.node?.allChildrenCount;
      this.severity = this.params?.node?.aggData;
    },
    filter(filter) {
      this.params?.api?.setFilterModel({
        severity: {
          filter,
          filterType: 'int',
          and: [],
          operators: new Set(),
          or: [],
          type: 'equals',
        },
      });
      this.params?.api?.onFilterChanged();
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  padding: 4px;
}
.group-title {
  @include header5;
  @include grid(0);
  color: var(--color-font-base);
}
</style>
