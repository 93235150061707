import Section from '@/components/Section.vue';
import AccountSection from './AccountSection.vue';
import Account from './Account.vue';
import AccountUsers from './AccountUsers.vue';
import AccountUser from './AccountUser.vue';
import AccountGroups from './AccountGroups.vue';
import AccountGroup from './AccountGroup.vue';
import AccountRoles from './AccountRoles.vue';
import AccountRole from './AccountRole.vue';
import AccountIdPs from './AccountIdPs.vue';
import AccountChangeManagement from './AccountChangeManagement.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default [
  {
    path: '/account',
    component: AccountSection,
    name: 'account',
    meta: {
      breadcrumb: {
        linkText: 'Account',
      },
      permissions: [
        RBAC_RESOURCE.USER,
        RBAC_RESOURCE.GROUP,
        RBAC_RESOURCE.CONFIG_FREEZE,
        RBAC_RESOURCE.ROLE,
        RBAC_RESOURCE.CONNECTION,
        RBAC_RESOURCE.ORGANIZATION,
      ],
      requireOnlySome: true,
    },
    children: [
      {
        path: '',
        component: Account,
        name: 'account.index',
        meta: {
          permissions: [RBAC_RESOURCE.ORGANIZATION],
        },
      },
      {
        path: 'users',
        name: 'account.users.index',
        meta: {
          permissions: [RBAC_RESOURCE.USER],
        },
        children: [
          {
            path: '',
            component: AccountUsers,
            name: 'account.users',
          },
          {
            path: '',
            component: Section,
            name: 'account.users.section',
            children: [
              {
                path: 'create',
                component: AccountUser,
                name: 'account.users.create',
                meta: {
                  requireWrite: true,
                },
              },
              {
                path: ':id',
                component: AccountUser,
                name: 'account.users.detail',
                meta: {
                  mode: { edit: true },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'groups',
        name: 'account.groups.index',
        meta: {
          permissions: [RBAC_RESOURCE.GROUP],
        },
        children: [
          {
            path: '',
            component: AccountGroups,
            name: 'account.groups',
          },
          {
            path: '',
            component: Section,
            name: 'account.groups.section',
            children: [
              {
                path: 'create',
                component: AccountGroup,
                name: 'account.groups.create',
                meta: {
                  requireWrite: true,
                },
              },
              {
                path: ':id',
                component: AccountGroup,
                name: 'account.groups.detail',
                meta: {
                  mode: { edit: true },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'roles',
        name: 'account.roles.index',
        meta: {
          permissions: [RBAC_RESOURCE.ROLE],
        },
        children: [
          {
            path: '',
            component: AccountRoles,
            name: 'account.roles',
          },
          {
            path: '',
            component: Section,
            name: 'account.roles.section',
            children: [
              {
                path: 'create',
                component: AccountRole,
                name: 'account.roles.create',
                meta: {
                  requireWrite: true,
                },
              },
              {
                path: ':id',
                component: AccountRole,
                name: 'account.roles.detail',
                meta: {
                  mode: { edit: true },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'idp',
        name: 'account.idp.index',
        meta: {
          permissions: [RBAC_RESOURCE.CONNECTION, RBAC_RESOURCE.CLOUD_ACCESS],
        },
        children: [
          {
            path: '',
            component: AccountIdPs,
            name: 'account.idp',
          },
        ],
      },
      {
        path: 'change-management',
        name: 'account.changeManagement.index',
        meta: {
          permissions: [RBAC_RESOURCE.CONFIG_FREEZE],
        },
        children: [
          {
            path: '',
            component: AccountChangeManagement,
            name: 'account.changeManagement',
          },
        ],
      },
    ],
  },
];
