<template>
  <Card class="mt-2 analytic-context-item">
    <div class="g-rows">
      <slot></slot>
      <div class="top-group">
        <j-select
          v-model="internalContextItem.type"
          :options="contextTypeOptions"
          :searchable="contextTypeOptions.length > 10"
          placeholder="Pick a Type"
          label="Type"
          class="mr-1"
        />
        <j-input
          v-model="internalContextItem.name"
          id="analyticContextName"
          label="Name"
        />
      </div>

      <j-create-button
        @click="addExpression('')"
        style-type="ghost-primary"
        data-feature-id="add-context-expression"
        label="Add Expression"
      />

      <div
        v-for="(expr, index) in internalContextItem.exprs"
        :key="'expr' + index"
        class="f-cols scale-1"
      >
        <j-remove-button
          @click="removeExpression(index)"
          class="expression-button"
        />
        <j-input
          v-model="internalContextItem.exprs[index]"
          class="expression-input"
          placeholder="Expression"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import contextDefinitions from '@/defs/context.json';
import Card from '@/components/Card.vue';

export default {
  components: { Card },
  name: 'AnalyticContextItem',
  props: {
    contextItem: {
      type: Object,
      required: true,
    },
    eventDef: {
      type: Object,
      required: true,
    },
    deleteMethod: {
      type: Function,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:contextItem'],
  data() {
    return {
      contextTypeOptions: contextDefinitions.types,
      internalContextItem: this.contextItem,
    };
  },
  watch: {
    internalContextItem: {
      deep: true,
      handler() {
        this.$emit('update:contextItem', this.computedItem);
      },
    },
  },
  methods: {
    removeExpression(index) {
      // Remove an expression if there is more than one
      if (this.internalContextItem.exprs.length > 1) {
        this.internalContextItem.exprs.splice(index, 1);
      }
    },
    addExpression(value) {
      this.internalContextItem.exprs.push(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.analytic-context-item {
  position: relative;
}

.top-group {
  @include flex-wrap($scale: 0);
}
</style>
