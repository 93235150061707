import { OPERATORS } from '../../utils/operators';
import { FILTER_TYPES } from '../../utils/filter.types';
import { TEXT_TYPE_OPTIONS } from './filter.options';
import BaseFilterStyle from './BaseFilterStyle.vue';

export default {
  components: { BaseFilterStyle },
  data() {
    return {
      value: null,
      defaultValue: '',
      filterType: 'text',
      inputType: 'text',
      operators: OPERATORS,
      active: null,
      operator: 'and',
      opValue: 'and',
      hidePopup: null,
      hasModel: false,
      defaultOption: FILTER_TYPES.CONTAINS,
      typeOptions: TEXT_TYPE_OPTIONS,
      placeholder: '',
      type: null,
      model: {
        filter: '',
        [OPERATORS.AND]: [],
        [OPERATORS.OR]: [],
        operators: new Set(),
      },
    };
  },
  methods: {
    isFilterActive() {
      return this.hasModel;
    },
    getFilterType() {
      return this.filterType;
    },
    getModel() {
      return this.isFilterActive() ? this.model : null;
    },
    setModel(model) {
      this.hasModel = !!model;
      if (model) {
        this.model = {
          ...this.model,
          ...model,
          suppressAndOrCondition: this.params.suppressAndOrCondition,
        };
      } else {
        this.resetModelOps();
      }
    },
    getDefaultOption() {
      return this.params.defaultOption || this.defaultOption;
    },
    afterGuiAttached({ hidePopup }) {
      // points to the internal input ref of the nebula JamfInput (j-input)
      if (this.$refs.input?.$refs.input) {
        this.$refs.input.$refs.input.focus();
      }
      this.hidePopup = hidePopup;
    },
    // use to mutate a value before applying to a filter
    mutateValue(value) {
      value = value.trim().replace(/: +/g, ':');
      return value;
    },
    resetModelOps() {
      this.model[OPERATORS.AND] = [];
      this.model[OPERATORS.OR] = [];
      this.model.operators.clear();
    },
    apply() {
      const currentFilter = this.params.api.getFilterModel();
      const filter = this.mutateValue(this.value);
      if (
        Object.keys(currentFilter).includes(this.params?.column?.colId) &&
        this.hasActiveFilter
      ) {
        this.model[this.operator].push({ type: this.type, filter });
        this.model.operators.add(this.operator);
      } else {
        this.resetModelOps();
        this.model.filter = filter;
        this.model.filterType = this.filterType;
        this.model.type = this.type;
      }
      this.value = this.defaultValue;
      this.hasModel = true;
      this.params.filterChangedCallback();
      this.hidePopup();
    },
    clear() {
      this.params.api.destroyFilter(this.params?.column?.colId);
      this.hidePopup();
    },
    setParamOptions() {
      this.type = this.params.defaultOption || this.defaultOption;
      this.filterType = this.params.filterType || this.filterType;
      this.typeOptions = this.params.typeOptions || this.typeOptions;
      this.defaultValue = this.params.defaultValue || this.defaultValue;
      this.placeholder = this.params.placeholder || this.placeholder;
      this.value = this.defaultValue;
    },
  },
  computed: {
    hasActiveFilter() {
      return !this.params.suppressAndOrCondition && this.isFilterActive();
    },
    hasOptions() {
      return !this.params.suppressTypeOptions;
    },
  },
  created() {
    this.setParamOptions();
  },
};
