export { queryFilter } from './query-filter';
export { sanitizeInput } from './sanitize';
export { downloadFromUrl } from './download-from-url';
export { base64toBlob } from './base64-to-blob';
export { AnalyticNameMatchKey, getKeyNameMatch } from './key-name-match';
export { addDynamicRoute } from './add-dynamic-route';
export { splitCamelCase } from './split-camel-case';
export { macOSVersionDeprecated } from './macos-version-deprecated';
export { countDeprecatedVersions } from './count-deprecated-versions';
export { bytesToSize } from './bytes-to-size';
export { truncate } from './truncate';
export { capitalize } from './capitalize';

export {
  secondsToString,
  secondsToMinutes,
  minutesToSeconds,
  dateGreaterThan,
  convertToISODate,
  dateIsValid,
  ariaDate,
  getTimeZone,
} from './time-helpers';
export { prettifyJson } from './prettify-json';

export const uuid = () => crypto.getRandomValues(new Uint32Array(4)).join('-');
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const capitalizeFirst = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const trim = (value) => value.replace(/ /gi, '');
