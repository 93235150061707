<template>
  <div class="g-rows max-c mb-0">
    <h1 class="subtitle-text">{{ header }}</h1>
    <div class="reports f-wrap">
      <Card
        class="report"
        v-for="(value, key) in report"
        :key="key"
        tabindex="0"
        :data-feature-id="key"
        :aria-label="`${value} ${reportInfo[key].label} insights`"
        @click="filtered(key)"
        role="button"
      >
        <j-icon
          :data="reportInfo[key].icon"
          width="40"
          height="40"
          class="m-2"
          :class="key"
        />
        <div class="g-rows gap-none p-2">
          <span class="big-number">
            {{ value }}
          </span>
          <p class="helper-text">{{ reportInfo[key].label }}</p>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import { mapGetters } from 'vuex';
import { disabled, success, warning } from '@/util/constants/feedback.icons';

export default {
  name: 'InsightsStatusCard',
  components: {
    Card,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    computer: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reportInfo: {
        fail: {
          icon: warning,
          label: 'Noncompliant',
        },
        pass: {
          icon: success,
          label: 'Compliant',
        },
        disabled: {
          icon: disabled,
          label: 'Disabled',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    header() {
      return this.isFeatureEnabled('COMPLIANCE_DASHBOARD')
        ? 'Compliance Status'
        : 'Insights Status';
    },
  },
  methods: {
    filtered(filter) {
      this.$router.push({ ...this.link, query: { filter } });
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  @include grid(0);
  @include grid-columns(max-content);
  justify-content: flex-start;
  padding: 0;
  height: 104px;

  div {
    border-left: 1px solid var(--color-border-secondary);
  }

  svg {
    align-self: center;
  }

  .pass {
    color: var(--color-success-base);
  }
  .fail {
    color: var(--color-warning-base);
  }

  .big-number {
    font-size: 48px;
    line-height: 48px;
  }
}
</style>
