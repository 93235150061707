<template>
  <span class="cell-date">{{ date }}</span>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import { dateIsValid } from '@/util';

export default {
  name: 'TableCellDate',
  props: ['params'],
  setup() {
    const { formatEpochDate } = useGmt();
    return { formatEpochDate };
  },
  data() {
    return {
      empty: '',
    };
  },
  computed: {
    date() {
      return dateIsValid(this.params.value)
        ? this.formatEpochDate(this.params.value)
        : this.params.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-date {
  display: flex;
  line-height: normal;
  width: 86px;
  align-items: flex-start;
  height: 100%;
}
</style>
