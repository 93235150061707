/*
  eslint
  no-param-reassign: ["error", { "props": false }]
  no-console: "off"
*/
import logger from '@/plugins/logger';
import { createStore } from 'vuex';
import { sanitizeInput } from '@/util';
import { captureException } from '@sentry/vue';
import SentryGraphqlTransactionManager from '@/util/sentry';
import AdminStoreModule from './graphql/admin/store';
import PrimaryStoreModule from './graphql/primary/store';
import AuthStoreModule from '../auth/store/auth.module';
import views from './modules/views';
import user from './modules/user';
import app from './modules/app.store';
import router from '@/router';
import { client as ApolloClient } from '@/store/graphql/client/apollo-clients';

export default createStore({
  modules: {
    auth: AuthStoreModule,
    primary: PrimaryStoreModule,
    admin: AdminStoreModule,
    views,
    user,
    app,
  },
  state: {
    showUserInfo: false,
    requestCount: 0,
    globalErrorMessage: null,
    globalInfoMessage: null,
    siteEnv: process.env.VUE_APP_SITE_ENV,
    initialRoute: {
      name: null,
      params: null,
    },
    mutationInProgress: false,
    mutationFailed: false,
    useSubmitWatcher: false,
    submitting: false,
    duplicateName: null,
    submitState: { success: false, failed: false },
    VUE_APP_GIT_VERSION: process.env.VUE_APP_GIT_VERSION,
    VUE_APP_GIT_HASH: process.env.VUE_APP_GIT_HASH,
    VUE_APP_GIT_BRANCH: process.env.VUE_APP_GIT_BRANCH,
    VUE_APP_BUILD_DATE: process.env.VUE_APP_BUILD_DATE,
  },
  mutations: {
    TOGGLE_USER_INFO(state) {
      state.showUserInfo = !state.showUserInfo;
    },
    SET_GLOBAL_ERROR_MESSAGE(state, payload) {
      state.globalErrorMessage = sanitizeInput(payload.message);
    },
    SET_GLOBAL_INFO_MESSAGE(state, payload) {
      state.globalInfoMessage = sanitizeInput(payload.message);
    },
    INCREMENT_REQUEST_COUNT(state) {
      state.requestCount += 1;
    },
    DECREMENT_REQUEST_COUNT(state) {
      if (state.requestCount > 0) {
        state.requestCount -= 1;
      }
    },
    SET_INITIAL_ROUTE(state, payload) {
      state.initialRoute = payload;
    },
    SET_MUTATION_START(state) {
      state.duplicateName = false;
      state.mutationFailed = false;
      state.mutationInProgress = true;
    },
    SET_DUPLICATE_NAME(state, payload) {
      state.duplicateName = payload;
    },
    SET_MUTATION_IN_PROGRESS(state, payload) {
      state.mutationInProgress = payload;
    },
    SET_MUTATION_FAILED(state, payload) {
      state.mutationFailed = payload;
    },
    SET_SUBMIT_WATCH(state) {
      state.useSubmitWatcher = true;
    },
    SET_SUBMIT_START(state) {
      state.submitState = { success: false, failed: false };
      state.submitting = true;
      state.submitComplete = false;
    },
    SET_SUBMIT_COMPLETE(state, payload) {
      state.submitting = false;
      state.useSubmitWatcher = false;
      state.submitComplete = true;
      state.submitState = payload;
    },
    SET_APOLLO_CLIENTS(state, payload) {
      state.$apollo = payload;
    },
  },
  getters: {
    showLoader(state) {
      return state.requestCount > 0;
    },
    mode(state) {
      return {
        [state.route.path.split('/').pop()]: true,
        ...(state.route.meta?.mode ?? {}),
      };
    },
    isMutating(state) {
      return state.mutationInProgress;
    },
    isFailedMutation(state) {
      return state.mutationFailed;
    },
    isSubmitComplete(state) {
      return state.submitComplete;
    },
  },
  actions: {
    setupApolloClients({ commit, rootState }) {
      const config = rootState?.auth?.loginConfig;
      const clients = {
        primary: ApolloClient(config?.primary_graphql_url || '/app'),
        admin: null,
      };
      if (config?.admin_graphql_url) {
        clients.admin = ApolloClient(config.admin_graphql_url, true);
      }
      commit('SET_APOLLO_CLIENTS', clients);
    },
    handleQueryError({ commit }, err) {
      const setSentryGQLScope = (scope) => {
        scope.setTag('operationType', err.operation);
        scope.setExtra('details', {
          ...err.graphQLErrors[0],
          locations: { ...err.graphQLErrors[0]?.locations[0] },
        });
        if (err.networkError?.statusCode) {
          scope.setTag('statusCode', err.networkError.statusCode);
        }
        scope.setTag('graphql', err.operation);
      };

      if (
        Object.prototype.hasOwnProperty.call(err, 'networkError.statusCode')
      ) {
        if (err.networkError.statusCode !== 401) {
          logger.error(err.networkError);
          logger.error(err.graphQLErrors);
          captureException(new Error(err.message), setSentryGQLScope);
        }
      } else {
        logger.error(err);
        captureException(new Error(err.message), setSentryGQLScope);
        if (err.message?.includes('Resource already exists.')) {
          const { name } = err.variables;
          commit('SET_DUPLICATE_NAME', name);
        }
        if (!err.throw) {
          commit('SET_GLOBAL_ERROR_MESSAGE', err);
        } else {
          commit('DECREMENT_REQUEST_COUNT');
          throw err;
        }
      }
    },
    async doMutation({ commit, dispatch, getters, state }, payload) {
      logger.debug('Check Session');
      const session = await dispatch('auth/getOrRefreshToken');

      if (session) {
        logger.debug('Making GraphQL Mutation');
        commit('SET_MUTATION_START');
        commit('INCREMENT_REQUEST_COUNT');
        const client = payload.useAdmin
          ? state.$apollo.admin
          : state.$apollo.primary;
        const mutation =
          typeof payload.query === 'function'
            ? payload.query(
                getters['user/hasReadPermissions'],
                getters['isFeatureEnabled']
              )
            : payload.query;

        const { span } =
          SentryGraphqlTransactionManager.setupTransactionAndSpan(
            mutation?.definitions[0]?.name.value
          );
        const permissionVariables = getters['user/getPermissionsVariables'];
        let result;

        try {
          result = await client.mutate({
            mutation,
            fetchPolicy: 'no-cache',
            variables: {
              hasLimitedAppAccess: getters['hasLimitedAppAccess'],
              ...permissionVariables,
              ...(payload.variables ? payload.variables : {}),
            },
          });
          if (result) {
            span?.setStatus('ok');
            span.isSuccess();
            logger.debug('Got GraphQL Result', result.data);
          }
          if (payload.key) {
            return result.data[payload.key];
          }
          return result;
        } catch (err) {
          span?.setStatus('unknown_error');
          commit('SET_MUTATION_FAILED', true);
          commit('SET_MUTATION_IN_PROGRESS', false);
          dispatch('handleQueryError', {
            ...err,
            throw: payload.throw,
            operation: 'mutation',
            variables: payload.variables,
          });
          SentryGraphqlTransactionManager.finishTransactionAndSpan();
          logger.error('GraphQL Error');
        } finally {
          commit('DECREMENT_REQUEST_COUNT');
          commit('SET_MUTATION_IN_PROGRESS', false);
          SentryGraphqlTransactionManager.finishTransactionAndSpan();
        }
      } else {
        router.push({ name: 'login' });
      }
    },
    async doQuery({ commit, dispatch, getters, state }, payload) {
      logger.debug('Check Session');
      const session = await dispatch('auth/getOrRefreshToken');

      if (session) {
        const { key, variables } = payload;
        commit('INCREMENT_REQUEST_COUNT');
        const client = payload.useAdmin
          ? state.$apollo.admin
          : state.$apollo.primary;
        // if the query is a function pass in the check for permissions and get result
        const query =
          typeof payload.query === 'function'
            ? await payload.query(
                getters['user/hasReadPermissions'],
                getters['isFeatureEnabled']
              )
            : payload.query;
        const opName = query?.definitions[0]?.name.value;
        const { span } =
          SentryGraphqlTransactionManager.setupTransactionAndSpan(
            opName,
            'query',
            false
          );
        const permissionVariables = getters['user/getPermissionsVariables'];
        let result;

        try {
          result = await client.query({
            query,
            fetchPolicy: 'no-cache',
            variables: {
              hasLimitedAppAccess: getters['hasLimitedAppAccess'],
              ...permissionVariables,
              ...(variables ? variables : {}),
            },
          });
          if (result) {
            logger.debug('Got GraphQL Result', result.data);
            span.setStatus('ok');
            span.isSuccess();
          }

          if (key) {
            return result.data[key];
          }
          return result;
        } catch (err) {
          span?.setStatus('unknown_error');
          dispatch('handleQueryError', {
            ...err,
            throw: payload.throw,
            operation: 'query',
          });
          SentryGraphqlTransactionManager.finishTransactionAndSpan();
          logger.error('GraphQL Error');
        } finally {
          commit('DECREMENT_REQUEST_COUNT');
          SentryGraphqlTransactionManager.finishTransactionAndSpan(
            result?.data[opName]
          );
        }
      } else {
        router.push({ name: 'login' });
      }
    },
  },
});
