<template>
  <div class="gp-content">
    <div v-if="plan" class="scroll-vertical">
      <div class="details-container p-2">
        <Card>
          <PlanDetailCard title="Plan Info" :plan="plan" />
        </Card>
        <Card v-if="hasAnalytics" class="chart-container">
          <p class="mb-2 helper-text">
            {{ totalAnalytics }} analytics grouped by category on this plan
          </p>
          <ChartDoughnut
            v-if="datasets"
            :datasets="datasets"
            :labels="labels"
            title="Analytic Categories"
          />
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { countBy } from 'lodash';
import Card from '@/components/Card.vue';
import PlanDetailCard from '../PlanDetailCard';
import ChartDoughnut from '@/components/charts/ChartDoughnut.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapGetters } from 'vuex';

export default {
  name: 'PlanDetails',
  components: {
    Card,
    PlanDetailCard,
    ChartDoughnut,
  },
  setup() {
    const { canWrite, cloudOnlyAccess, canAccess } = useRBAC(
      RBAC_RESOURCE.PLAN
    );

    return { canWrite, cloudOnlyAccess, canAccess };
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labels: null,
      datasets: null,
      analytics: [],
      totalAnalytics: 0,
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    hasAnalytics() {
      return this.analytics && this.analytics.length > 0;
    },
    hasAnalyticsSets() {
      return this.canAccess(RBAC_RESOURCE.ANALYTIC_SET);
    },
  },
  mounted() {
    if (this.hasAnalyticsSets) {
      this.plan.analyticSets?.reduce((arr, item) => {
        arr.push(item.analytics);
        return arr;
      }, this.analytics);

      const dedupeSetAnalytics = new Set([]);
      this.analytics = this.analytics.flat().filter((analytic) => {
        const isNotDupe = !dedupeSetAnalytics.has(analytic.uuid);
        dedupeSetAnalytics.add(analytic.uuid);
        return isNotDupe;
      });

      this.totalAnalytics = this.analytics.length;
      const counts = countBy(
        this.analytics
          ?.map((analytic) =>
            analytic?.categories.length ? analytic.categories : ['None']
          )
          .flat()
      );

      this.labels = Object.keys(counts);
      this.datasets = [
        {
          data: Object.values(counts),
        },
      ];
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  display: block;
}
.details-container {
  @include grid;
  @include grid-columns(minmax(0, 600px) fit-content(100%));
  h2 {
    @include header4;
    padding-bottom: spacing();
  }

  @include breakpoint(small down) {
    grid-auto-flow: row;
    grid-auto-columns: auto;
    > * {
      min-height: 300px;
    }
  }
}
</style>
