<template>
  <CollapseCard :title="title">
    <template v-if="showAutoSelect" #left-header>
      <div class="g-cols gap-1 ml-1">
        <j-radio v-model="autoSelect" native-value="Minimal" :name="toggleName">
          Minimal
        </j-radio>
        <j-radio
          v-model="autoSelect"
          native-value="Everything"
          :name="toggleName"
        >
          Everything
        </j-radio>
        <j-radio v-model="autoSelect" native-value="Custom" :name="toggleName">
          Custom
        </j-radio>
      </div>
    </template>
    <div class="actions-data-config g-col-4">
      <div v-for="(obj, key) in dataFields" :key="key">
        <label class="h5">{{ obj.def.label }}</label>
        <p class="helper-text mb-1">Included Data Attributes:</p>
        <div class="tags">
          <j-tooltip
            v-for="field in obj.included"
            :key="field.label"
            :text="field.description"
            open-position="top"
          >
            <j-tag
              v-on="
                field.coding !== 'base'
                  ? { click: () => removeAttrClick(dataFields[key], field) }
                  : {}
              "
            >
              {{ field.label }}
              <template #icon>
                <j-icon
                  v-if="field.coding !== 'base'"
                  data="@jcon/x-small.svg"
                />
              </template>
            </j-tag>
          </j-tooltip>
        </div>
        <p class="helper-text mb-1">Excluded Data Attributes:</p>
        <div class="tags">
          <j-tooltip
            v-for="field in obj.excluded"
            :key="field.label"
            :text="field.description"
            open-position="top"
          >
            <j-tag @click="addAttrClick(dataFields[key], field)">
              <template #icon>
                <j-icon data-testid="plus-icon" data="@icon/plus-small.svg" />
              </template>
              {{ field.label }}
            </j-tag>
          </j-tooltip>
        </div>
      </div>
    </div>
  </CollapseCard>
</template>

<script>
import CollapseCard from '@/components/CollapseCard.vue';

export default {
  name: 'DataConfigsForm',
  inject: ['$typeDefs'],
  components: {
    CollapseCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showAutoSelect: {
      type: Boolean,
      default: true,
    },
    existing: Boolean,
  },
  data() {
    return {
      dataFields: {},
      mappings: this.$typeDefs.mappings,
      autoSelect: 'Everything',
      defs: this.$typeDefs,
      toggleName: 'auto-select',
    };
  },
  emits: ['update:data'],
  computed: {
    computedData() {
      const data = {};
      Object.keys(this.dataFields).forEach((key) => {
        data[key] = {
          attrs: [],
          related: [],
        };
        this.dataFields[key].included.forEach((field) => {
          if (field.coding === 'extended') {
            // Extended fields are set by value
            data[key].attrs.push(field.value);
          } else if (field.coding === 'related') {
            // Related fields are set by type
            data[key].related.push(field.type);
          }
        });
      });
      return data;
    },
  },
  watch: {
    autoSelect() {
      if (this.showAutoSelect) {
        if (this.autoSelect === 'Minimal') {
          this.selectMinimal();
        } else if (this.autoSelect === 'Everything') {
          this.selectEverything();
        }
      }
    },
  },
  beforeMount() {
    // This builds up the list of included and excluded attributes for each type (e.g. fsEvent)
    let { total, base, included, excluded } = {
      total: 0,
      base: 0,
      included: 0,
      excluded: 0,
    };

    Object.entries(this.mappings).forEach(([key, def]) => {
      if (!def) {
        return;
      }

      const dataFields = {
        def,
        included: this.getBaseFields(def),
        excluded: [],
      };
      base += dataFields.included.length;

      // Fill in excluded or included based on the passed in attr array
      this.getExtendedFields(def).forEach((field) => {
        if (this.data[key]?.attrs?.includes(field.value)) {
          dataFields.included.push(field);
        } else {
          dataFields.excluded.push(field);
        }
      });

      this.getRelatedFields(def).forEach((field) => {
        if (this.data[key]?.related?.includes(field.type)) {
          dataFields.included.push(field);
        } else {
          dataFields.excluded.push(field);
        }
      });

      included += dataFields.included.length;
      excluded += dataFields.excluded.length;

      // Need to use $set to make it reactive
      this.dataFields[key] = dataFields;
    });

    // update after mount so any missing data info can be added
    this.$emit('update:data', this.computedData);

    total = included + excluded;

    this.setupAutoSelect(total, base, excluded, included);
  },
  methods: {
    getBaseFields({ fields }) {
      return fields.filter(({ coding }) => coding === 'base');
    },
    getExtendedFields({ fields }) {
      return fields.filter(({ coding }) => coding === 'extended');
    },
    getRelatedFields({ fields }) {
      return fields.filter(({ coding }) => coding === 'related');
    },
    removeAttrClick(obj, field) {
      this.autoSelect = 'Custom';
      this.handleDataFieldRemove(obj, field);
    },
    addAttrClick(obj, field) {
      this.autoSelect = 'Custom';
      this.handleDataFieldAdd(obj, field);
    },
    handleDataFieldRemove({ included, excluded }, field) {
      const index = included.findIndex((item) => item === field);
      included.splice(index, 1);
      excluded.push(field);
      this.$emit('update:data', this.computedData);
    },
    handleDataFieldAdd({ included, excluded }, field) {
      const index = excluded.findIndex((item) => item === field);
      excluded.splice(index, 1);
      included.push(field);
      this.$emit('update:data', this.computedData);
    },
    selectEverything() {
      Object.values(this.dataFields).forEach((obj) => {
        // Make a copy of items to remove from excluded array
        const excluded = obj.excluded.slice();
        excluded.forEach((field) => this.handleDataFieldAdd(obj, field));
      });
    },
    selectMinimal() {
      Object.values(this.dataFields).forEach((obj) => {
        const included = obj.included.filter(
          (field) => field.coding !== 'base'
        );
        included.forEach((field) => this.handleDataFieldRemove(obj, field));
      });
    },
    setupAutoSelect(total, base, excluded, included) {
      if (this.showAutoSelect) {
        if (this.existing) {
          if (total !== included) this.autoSelect = 'Custom';
          if (total === base + excluded) this.autoSelect = 'Minimal';
        } else {
          this.selectEverything();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-data-config {
  padding-top: spacing();
  @include breakpoint(small down) {
    @include grid-wrap(floor(calc(100% / 2)));
  }

  label {
    border-bottom: 2px solid var(--color-border-secondary);
    padding-bottom: 4px;
    margin-bottom: 4px;
    display: flex;
  }
}
</style>
