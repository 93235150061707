<template>
  <div class="gp-content" v-if="data">
    <j-form-bar :title="data.version">
      <template #left>
        <j-back-button @click="back()" />
      </template>
      <j-save-button
        @click="submit()"
        label="Save"
        :disabled="loginConfig.region != 'us-east-1'"
      />
      <p v-if="loginConfig.region != 'us-east-1'">
        Global package updates are run from the <b>us-east-1</b> admin tenant
      </p>
      <gp-loader :show-loader="showLoader">Saving</gp-loader>
      <p v-if="message">{{ message }}</p>
    </j-form-bar>
    <div class="release-form p-2 scroll-vertical">
      <h1 class="mb-1 h4">
        {{ isCompliance ? 'Compliance Reporter' : 'Protect' }} Package
      </h1>
      <ListItem title="Release Group">
        <p v-if="loginConfig.region !== 'us-east-1'">
          <b>{{ releaseGroup }}</b>
        </p>
        <j-toggle-group v-if="loginConfig.region === 'us-east-1'">
          <j-radio
            v-for="option in groupOptions"
            :key="option"
            v-model="releaseGroup"
            :native-value="option"
            name="release-group"
          >
            {{ capitalize(option) }}
          </j-radio>
        </j-toggle-group>
      </ListItem>
      <ListItem title="Version">{{ data.version }}</ListItem>
      <ListItem title="Vanilla Package"
        >s3://{{ data.bucket }}/{{ data.key }}</ListItem
      >
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/util';
import { mapState } from 'vuex';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'Package',
  components: { ListItem },
  computed: {
    ...mapState('auth', ['loginConfig']),
  },
  props: {
    isCompliance: Boolean,
    item: Object,
    refreshGrid: Function,
  },
  data() {
    return {
      message: null,
      showLoader: false,
      data: null,
      endpoint: '',
      updated: false,
      releaseGroup: null,
      groupOptions: ['PROD', 'BETA', 'ALPHA', 'DEV'],
    };
  },
  async mounted() {
    this.data = this.item
      ? this.item
      : await this.$store.dispatch(
          this.isCompliance
            ? 'admin/getComplianceReporterPackage'
            : 'admin/getPackage',
          {
            version: this.$route.params.id,
          }
        );
    this.releaseGroup = this.data.releaseGroup;
    this.endpoint = this.isCompliance
      ? 'admin/updateComplianceReporterPackage'
      : 'admin/updatePackage';
  },
  methods: {
    capitalize,
    back() {
      this.$router.push({
        name: `admin.packages.${this.isCompliance ? 'compliance' : 'protect'}`,
      });
      if (this.updated) {
        this.refreshGrid();
      }
    },
    async submit() {
      this.updated = false;
      this.message = '';
      this.showLoader = true;
      const action = await this.$store.dispatch(this.endpoint, {
        ...this.data,
        releaseGroup: this.releaseGroup,
      });
      this.showLoader = false;
      if (action) {
        this.data = action;
        this.updated = true;
        this.message = 'Package Updated!';
      } else {
        this.message = 'Error Updating!';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.release-form {
  @include grid(spacing());
  @include grid-rows(min-content);
}
</style>
