import { computed, getCurrentInstance, watch } from 'vue';
import logger from '@/plugins/logger';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { useRouteHelpers } from '@/composables/route-helpers';

export function useForm(vOptions) {
  const currentVueInstance = getCurrentInstance()?.proxy;
  const store = useStore();
  const { mode } = useRouteHelpers();

  const v$ = vOptions ? useVuelidate(vOptions) : useVuelidate();

  const isValid = computed(async () => v$.value.$validate());

  const handleSubmit = async () => {
    if (!currentVueInstance?.submit) {
      logger.error(
        `Your component does not have a submit function. 
          handleSubmit has no built-in submit method. 
          Make sure the component has a submit method for 
          handleSubmit to work properly.`
      );
      return;
    }

    const valid = await isValid.value;
    if (valid) {
      // utilizes submit function of the current component using forms - best used use in tandem with j-form-bar
      await currentVueInstance.submit();
    }
    store.commit('SET_SUBMIT_COMPLETE', {
      success: valid,
      failed: !valid,
    });
  };

  const useSubmitWatcher = computed(() => store.state.useSubmitWatcher);

  watch(useSubmitWatcher, async (value) => {
    // useSubmitWatcher is set by j-form-bar
    if (value === true) {
      await handleSubmit();
    }
  });

  return {
    mode,
    v$,
    handleSubmit,
    isValid,
  };
}
