<template>
  <div class="g-rows">
    <div class="g-col-2">
      <ListItem title="Event Timestamp">
        {{ formatEpochDate(event.timestamp) }}
      </ListItem>
      <ListItem title="Encrypted">
        {{ event.device.encrypted ? 'Yes' : 'No' }}
      </ListItem>
      <ListItem title="Removable">
        {{ event.device.removable ? 'Yes' : 'No' }}
      </ListItem>
      <ListItem title="Writable">
        {{ event.device.writable ? 'Yes' : 'No' }}
      </ListItem>
    </div>

    <div class="g-col-2">
      <ListItem title="Product Name">
        {{ event.device.productName }}
      </ListItem>
      <ListItem title="Product ID">
        <code v-if="event.device.productId">{{ event.device.productId }}</code>
      </ListItem>

      <ListItem title="Vendor Name">
        {{ event.device.vendorName }}
      </ListItem>
      <ListItem title="Vendor ID">
        <code v-if="event.device.vendorId">{{ event.device.vendorId }}</code>
      </ListItem>
      <ListItem title="BSD Name">
        {{ event.device.bsdName }}
      </ListItem>
      <ListItem title="Media Name">
        {{ event.device.mediaName }}
      </ListItem>
    </div>

    <ListItem title="Serial Number">
      <code v-if="event.device.serialNumber || event.device.serialNo">{{
        event.device.serialNumber || event.device.serialNo
      }}</code>
    </ListItem>

    <ListItem title="Class">
      <template v-if="event.device.deviceClass && event.device.deviceSubClass"
        >{{ event.device.deviceClass }} /
        {{ event.device.deviceSubClass }}</template
      >
    </ListItem>
    <ListItem title="Path">
      {{ event.device.devicePath }}
    </ListItem>
    <ListItem title="Volume Type">
      {{ event.device.volumeType }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'MountEvent',
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
