<template>
  <template v-if="isProduct">
    <hr v-if="isSmall" />
    <h1 v-if="isSmall" :class="headerClass">
      {{ USBCONTROL_TYPES_NAMES.Product }}
    </h1>
    <p class="mb-1">A number that identifies an individual product.</p>
    <span>How to add {{ USBCONTROL_TYPES_NAMES.Product }}s:</span>
    <ul class="options">
      <li>
        Upload a CSV with one column containing
        {{ USBCONTROL_TYPES_NAMES.Vendor }} and a second column containing
        {{ USBCONTROL_TYPES_NAMES.Product }}s.
      </li>
      <li>
        Add one {{ USBCONTROL_TYPES_NAMES.Product }} at a time with the Add
        modal. (e.g., <code>0x1921,0x1434</code>).
      </li>
    </ul>
  </template>

  <template v-if="isVendor">
    <hr v-if="isSmall" />
    <h1 v-if="isSmall" :class="headerClass">
      {{ USBCONTROL_TYPES_NAMES.Vendor }}
    </h1>
    <p class="mb-1">A number that identifies a specific company.</p>
    <span>How to add {{ USBCONTROL_TYPES_NAMES.Vendor }}s:</span>
    <ul class="options">
      <li>
        Upload a CSV with one column containing
        {{ USBCONTROL_TYPES_NAMES.Vendor }}s.
      </li>
      <li>
        Add a string list with the Add modal, separated by commas (e.g.,
        <code>0x1921,0x1434</code>).
      </li>
    </ul>
  </template>

  <template v-if="isSerial">
    <hr v-if="isSmall" />
    <h1 v-if="isSmall" :class="headerClass">
      {{ USBCONTROL_TYPES_NAMES.Serial }}
    </h1>
    <p class="mb-1">
      A number string that identifies a removable storage device.
    </p>
    <span>How to add {{ USBCONTROL_TYPES_NAMES.Serial }}s:</span>
    <ul class="options">
      <li>
        Upload as CSV, 1 column containing
        {{ USBCONTROL_TYPES_NAMES.Serial }}s.
      </li>
      <li>
        Add a string list with the Add modal, separated by commas (e.g.,
        <code>575833314133343231313937,0700079716000026</code>).
      </li>
    </ul>
  </template>
</template>

<script>
import {
  USBCONTROL_TYPES,
  USBCONTROL_TYPES_NAMES,
} from '../usb-controls.types';

export default {
  name: 'USBControlsDocumentation',
  props: {
    type: String,
    isSmall: Boolean,
  },
  data() {
    return { USBCONTROL_TYPES_NAMES };
  },
  computed: {
    isProduct() {
      return this.type === USBCONTROL_TYPES.Product;
    },
    isVendor() {
      return this.type === USBCONTROL_TYPES.Vendor;
    },
    isSerial() {
      return this.type === USBCONTROL_TYPES.Serial;
    },
    headerClass() {
      return 'subtitle-text';
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  margin: spacing(1) 0;
}
.options {
  list-style-type: circle;
  padding: spacing(1) spacing(2);
}
</style>
