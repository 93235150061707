<template>
  <AnalyticEventTypeIcon v-if="params.value" :input-type="params.value" />
</template>

<script>
import AnalyticEventTypeIcon from '@/components/analytics/AnalyticEventTypeIcon.vue';

export default {
  components: { AnalyticEventTypeIcon },
  name: 'TableCellEvent',
};
</script>

<style lang="scss" scoped>
.icon-cell {
  @include grid(spacing(0.5));
  @include grid-columns;
  justify-content: flex-start;
  align-items: center;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
