import LIST_PREVENT_LISTS from './queries/listPreventLists.gql';
import GET_PREVENT_LIST from './queries/getPreventList.gql';
import CREATE_PREVENT_LIST from './mutations/createPreventList.gql';
import UPDATE_PREVENT_LIST from './mutations/updatePreventList.gql';
import DELETE_PREVENT_LIST from './mutations/deletePreventList.gql';
import LIST_THREAT_DB_VERSIONS from './queries/listThreatPreventionVersions.gql';
import GET_COMPUTERS_THREAT_PREVENT_VERSION_COUNT from './queries/getComputersThreatPreventVersionCount.gql';

export default {
  state: {
    threatprevent: null,
    threatprevents: [],
    threatPreventsNames: [],
  },
  mutations: {
    SET_PREVENT_LIST(state, payload) {
      state.threatprevent = payload;
    },
    SET_PREVENT_LISTS(state, payload) {
      state.threatprevents = payload.items;
      state.threatPreventsNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    async listPreventLists({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_PREVENT_LISTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PREVENT_LISTS', result.data.listPreventLists);
        return result.data.listPreventLists;
      }
      return result;
    },
    async getPreventList({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_PREVENT_LIST,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PREVENT_LIST', result.data.getPreventList);
        return result.data.getPreventList;
      }
      return result;
    },
    async createPreventList({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_PREVENT_LIST,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PREVENT_LIST', result.data.createPreventList);
        return result.data.createPreventList;
      }
      return result;
    },
    async updatePreventList({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_PREVENT_LIST,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PREVENT_LIST', result.data.updatePreventList);
        return result.data.updatePreventList;
      }
      return result;
    },
    async deletePreventList({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_PREVENT_LIST,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PREVENT_LIST', result.data.deletePreventList);
        return result.data.deletePreventList;
      }
      return result;
    },
    async listThreatPreventionVersions({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_THREAT_DB_VERSIONS,
        },
        { root: true }
      );
      if (result) {
        return result.data.listThreatPreventionVersions;
      }
      return result;
    },

    async getComputersThreatPreventVersionCount({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_COMPUTERS_THREAT_PREVENT_VERSION_COUNT,
          variables: payload,
        },
        { root: true }
      );
      return result.data;
    },
  },
};
