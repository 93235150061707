import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Telemetries from './Telemetries.vue';
import TelemetryForm from './TelemetryForm.vue';
import TelemetryDetails from './TelemetryDetails.vue';
import Telemetry from './Telemetry.vue';

export default [
  {
    path: 'telemetry',
    component: Section,
    name: 'telemetry',
    meta: {
      breadcrumb: {
        linkName: 'telemetry.index',
        linkText: 'Telemetry',
      },
      permissions: [RBAC_RESOURCE.TELEMETRY],
    },
    children: [
      {
        path: '',
        component: Telemetries,
        name: 'telemetry.index',
      },
      {
        path: '',
        component: Telemetry,
        name: 'telemetry.index.update',
        children: [
          {
            path: 'create',
            name: 'telemetry.index.create',
            component: TelemetryForm,
            meta: {
              breadcrumb: {
                linkText: 'Create',
              },
              requireWrite: true,
            },
          },
        ],
      },
      {
        path: ':id',
        component: Telemetry,
        meta: {
          breadcrumb: {
            linkName: 'telemetry.index.detail',
            linkText: 'Details',
          },
        },
        children: [
          {
            path: '',
            name: 'telemetry.index.detail',
            component: TelemetryDetails,
          },
          {
            path: 'edit',
            name: 'telemetry.index.edit',
            component: TelemetryForm,
            props: true,
            meta: {
              breadcrumb: {
                linkText: 'Edit',
              },
              requireWrite: true,
            },
          },
        ],
      },
    ],
  },
];
