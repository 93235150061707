/*
  eslint
  no-param-reassign: ["error", { "props": false }]
  no-console: "off"
*/
import auditlogs from '@/modules/audit-logs/store/auditlogs.store';
import insights from '@/modules/insights/store/insights.store';
import compliance from '@/modules/compliance/store/compliance.store';
import data from '@/modules/administrative/data/store/data.store';
import account from '@/modules/administrative/account/store/account.store';
import downloads from '@/modules/administrative/downloads/store/downloads.store';
import apiclients from '@/modules/administrative/api-clients/store/api-clients.store';
import computers from '@/modules/computers/store/computers.store';
import overviews from '@/modules/overviews/store/overviews.store';
import plans from '@/modules/plans/store/plans.store';
import exceptionSets from '@/modules/plans/exception-sets/store/exception-sets.store';
import alerts from '@/modules/alerts/store/alerts.store';
import threatprevent from '@/modules/threat-prevention/store/threat-prevent.store';
import actions from '@/modules/actions/store/actions.store';
import unifiedlogging from '@/modules/unified-logging/store/unifiedlogging.store';
import telemetry from '@/modules/telemetry/store/telemetry.store';
import analytics from '@/modules/analytics/store/analytic.store';
import analyticSets from '@/modules/analytics/analytic-sets/store/analyticSets.store';
import usbcontrols from '@/modules/usb-controls/store/usb-controls.store';

import GET_ORGANIZATION from './queries/getOrganization.gql';
import GET_STATS_SEVERITY_TIME_SERIES from './queries/getStatsSeverityTimeSeries.gql';

import LIST_COMPUTERS_STATS from './queries/listComputersStats.gql';
import { GET_COUNTS } from './queries/getCounts.js';
import LIST_ALERTS_ANALYTICS from './queries/listAlertsAnalytics.gql';

import LIST_COMPUTERS_ALERT_COUNTS from './queries/listComputersAlertCounts.gql';
import LIST_ALERTS_CONTEXT_VALUES from './queries/listAlertsContextValues.gql';
import LIST_ALERTS_EVENT_VALUES from './queries/listAlertsEventValues.gql';

const PrimaryStoreModule = {
  namespaced: true,
  modules: {
    auditlogs,
    insights,
    compliance,
    data,
    account,
    apiclients,
    computers,
    downloads,
    plans,
    alerts,
    threatprevent,
    actions,
    overviews,
    unifiedlogging,
    analytics,
    analyticSets,
    exceptionSets,
    usbcontrols,
    telemetry,
  },
  state: {
    computer: null,
    deployment: null,
    organization: null,
  },
  mutations: {
    SET_ORGANIZATION(state, payload) {
      state.organization = { ...state.organization, ...payload };
    },
    SET_ALL_PLANS(state, payload) {
      state.plans.plans = payload;
    },
  },
  getters: {
    nameExistsInStore: (state) => (name, resourceType, module) =>
      module
        ? state[module][`${resourceType}s`].map((r) => r.name).includes(name)
        : state[`${resourceType}s`].map((r) => r.name).includes(name),
  },
  actions: {
    async listAllPlans({ dispatch, commit }) {
      const items = [];
      const payload = {
        next: null,
        direction: 'DESC',
        field: 'created',
      };
      const response = await dispatch('listPlans', payload);
      if (response) {
        response.items?.forEach((i) => items.push(i));
        if (response.pageInfo.next) {
          const response2 = await dispatch('listPlans', {
            ...payload,
            next: response.pageInfo.next,
          });
          if (response2) {
            response2.items?.foreEach((i) => items.push(i));
          }
        }
        commit('SET_ALL_PLANS', items);
      }
      return items;
    },
    async listAllPlansMinimal({ dispatch, commit }) {
      const items = [];
      const payload = {
        next: null,
        direction: 'DESC',
        field: 'created',
        minimal: true,
      };
      const response = await dispatch('listPlans', payload);
      if (response) {
        response.items?.forEach((i) => items.push(i));
        if (response.pageInfo.next) {
          const response2 = await dispatch('listPlans', {
            ...payload,
            next: response.pageInfo.next,
          });
          if (response2) {
            response2.items?.foreEach((i) => items.push(i));
          }
        }
        commit('SET_ALL_PLANS', items);
      }
      return items;
    },
    async getOrganization({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ORGANIZATION,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ORGANIZATION', result.data.getOrganization);
        return result.data.getOrganization;
      }
      return result;
    },
    async getStatsSeverityTimeSeries({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_STATS_SEVERITY_TIME_SERIES,
          variables: payload,
        },
        { root: true }
      );
      return result?.data || {};
    },
    async listComputersStats({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_COMPUTERS_STATS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listComputersStats;
      }
      return result;
    },
    async getCount({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_COUNTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.getCount;
      }
      return result;
    },
    async listAlertsAnalytics({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ALERTS_ANALYTICS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAlertsAnalytics;
      }
      return result;
    },
    async listAlertsContextValues({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ALERTS_CONTEXT_VALUES,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAlertsContextValues;
      }
      return result;
    },
    async listAlertsEventValues({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ALERTS_EVENT_VALUES,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAlertsEventValues;
      }
      return result;
    },
    async listComputersAlertCounts({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_COMPUTERS_ALERT_COUNTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listComputersAlertCounts;
      }
      return result;
    },
  },
};

export default PrimaryStoreModule;
