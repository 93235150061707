<template>
  <Tabs :tabs="tabs" />
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'AccountSection',
  components: { Tabs },
  data() {
    return {
      tabs: [
        {
          label: 'Overview',
          route: {
            name: 'account.index',
          },
        },
        {
          label: 'Users',
          route: {
            name: 'account.users',
          },
          nestedRouteNames: ['account.users.detail', 'account.users.create'],
        },
        {
          label: 'Groups',
          route: {
            name: 'account.groups',
          },
          nestedRouteNames: ['account.groups.detail', 'account.groups.create'],
        },
        {
          label: 'Roles',
          route: {
            name: 'account.roles',
          },
          nestedRouteNames: ['account.roles.detail', 'account.roles.create'],
        },
        {
          label: 'Identity Providers',
          route: {
            name: 'account.idp',
          },
          nestedRouteNames: ['account.idp.detail', 'account.idp.create'],
        },
        {
          label: 'Change Management',
          route: {
            name: 'account.changeManagement',
          },
        },
      ],
    };
  },
};
</script>
