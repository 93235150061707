import { captureException, getCurrentHub } from '@sentry/browser';

const sentryCheckOnEach =
  (
    startTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true
  ) =>
  (to, from, next) => {
    const isPageLoadNavigation = from.name == null && from.matched.length === 0;

    const isFromInitialize = [
      'login',
      'callback',
      'redirect',
      'initialize',
    ].includes(from.name);

    const tags = {
      'routing.instrumentation': 'vue-router',
    };
    const data = {
      from: from.name || from.path,
      to: to.name || to.path,
      params: to.params,
      query: to.query,
    };

    // Determine a name for the routing transaction and where that name came from
    let transactionName = to.path;
    let transactionSource = 'url';
    if (to.name) {
      transactionName = to.name.toString();
      transactionSource = 'custom';
    } else if (to.matched[0] && to.matched[0].path) {
      transactionName = to.matched[0].path;
      transactionSource = 'route';
    }

    // keep the same transaction if coming from initialize so pageload can be named
    // the final route instead of splitting them
    if (isFromInitialize) {
      const transaction = getCurrentHub().getScope().getTransaction();
      if (transaction) {
        transaction.setName(transactionName);
        const combineTo = (transaction.data.to += ` - ${to.name}`);
        const combineFrom = (transaction.data.from += ` - ${from.name}`);
        transaction.setData('from', combineFrom);
        transaction.setData('to', combineTo);
        transaction.startChild({
          name: transactionName,
          op: 'pageload',
          tags: { ...tags, 'routing.from_initialize': 'yes' },
          data,
          metadata: {
            source: transactionSource,
          },
        });
      } else {
        startTransaction({
          name: transactionName,
          op: 'pageload',
          tags,
          data,
          metadata: {
            source: transactionSource,
          },
        });
      }
    }

    if (
      startTransactionOnPageLoad &&
      isPageLoadNavigation &&
      !isFromInitialize
    ) {
      startTransaction({
        name: transactionName,
        op: 'pageload',
        tags,
        data,
        metadata: {
          source: transactionSource,
        },
      });
    }

    if (
      startTransactionOnLocationChange &&
      !isPageLoadNavigation &&
      !isFromInitialize
    ) {
      startTransaction({
        name: transactionName,
        op: 'navigation',
        tags,
        data,
        metadata: {
          source: transactionSource,
        },
      });
    }
    next();
  };

function sentryRouterInstrumentation(router) {
  return (
    startTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true
  ) => {
    router.onError((error) => captureException(error));

    router.beforeEach(
      sentryCheckOnEach(
        startTransaction,
        startTransactionOnPageLoad,
        startTransactionOnLocationChange
      )
    );
  };
}

export {
  sentryRouterInstrumentation,
  sentryCheckOnEach, // for testing
};
