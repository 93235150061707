<script>
import ConnectorButton from '@/auth/components/ConnectorButton.vue';
import Card from '@/components/Card.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { computed, reactive, watch } from 'vue';

export default {
  name: 'TenantConnectionCard',
  components: { Card, ConnectorButton },
  props: {
    connection: Object,
    connections: Array,
    users: Array,
  },
  emits: ['update:connection', 'update:users', 'remove'],
  setup(props, ctx) {
    const rules = {
      computedConnection: {
        name: {
          required,
          unique(value) {
            const count = props.connections.filter(
              ({ name }) => name === value
            );
            return count.length <= 1;
          },
        },
        button: {
          required,
        },
      },
      computedUsers: { $each: helpers.forEach({ email: { email, required } }) },
    };

    const computedUsers = reactive(props.users.map((email) => ({ email })));

    const computedConnection = computed({
      get() {
        return props.connection;
      },
      set(newValue) {
        ctx.emit('update:connection', newValue);
      },
    });

    watch(computedUsers, (newValue) => {
      ctx.emit(
        'update:users',
        newValue.map(({ email }) => email)
      );
    });
    const v$ = useVuelidate(rules, { computedConnection, computedUsers });

    const addUser = () => {
      computedUsers.unshift({ email: '' });
    };
    const removeUser = (index) => {
      computedUsers.splice(index, 1);
    };

    const buttonOptions = [
      'google_button',
      'default_button',
      'okta_button',
      'azure_button',
    ];

    const getCount = () => {
      return (
        props.connections?.filter(
          ({ button }) => button === computedConnection.value.button
        ).length || 0
      );
    };

    function validate(error) {
      // $each doesnt seem to care about the dirty state, so need to explicitly check it
      return error && v$.value.$dirty;
    }
    return {
      getCount,
      validate,
      removeUser,
      buttonOptions,
      addUser,
      v$,
      computedConnection,
      computedUsers,
    };
  },
};
</script>

<template>
  <Card class="connection-cols">
    <j-remove-button @click="$emit('remove')" class="js-end" />
    <j-input
      v-model="computedConnection.name"
      :has-error="v$.computedConnection.name.$error"
      :error-text="
        v$.computedConnection.name.unique && v$.computedConnection.name.required
          ? `Only one ${computedConnection.name} connector is allowed`
          : 'Connection name is required'
      "
      label="Login Connector (JamfID, google-oauth2, JamfOkta)"
    />
    <j-checkbox
      v-model="computedConnection.requireKnownUsers"
      :is-read-only="computedConnection.name === 'JamfID'"
    >
      Require Known Users
    </j-checkbox>
    <div class="connectors">
      <j-select
        v-if="computedConnection.name !== 'JamfID'"
        v-model="computedConnection.button"
        :has-error="v$.computedConnection.button.$error"
        error-text="Button is required"
        :options="buttonOptions"
        :searchable="false"
      />
      <ConnectorButton
        :name="computedConnection.name"
        :type="computedConnection.button"
        :count="getCount()"
      />
    </div>
    <template v-if="computedConnection.name === 'JamfID'">
      <j-create-button @click="addUser" label="Add User" />
      <div v-for="(_user, i) in computedUsers" :key="i" class="f-cols scale-1">
        <j-remove-button @click="removeUser(i)" />
        <j-input
          v-model="computedUsers[i].email"
          :has-error="
            validate(v$.computedUsers.$each.$response.$data[i].email.$error)
          "
          error-text="Please enter a valid email address."
          placeholder="e.g. user@jamfprotect.com"
        />
      </div>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.connection-cols {
  --color-input-base: var(--color-card-primary);
  @include grid;
}

.connectors {
  @include grid;
  @include grid-columns(max-content);
  align-items: center;
}
</style>
