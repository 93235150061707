<template>
  <div class="g-col-2">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <template v-for="(item, key) in event" :key="key">
      <!-- display top event level items -->
      <ListItem
        v-if="key !== 'timestamp' && typeof item !== 'object'"
        :title="getTitle(key)"
        :class="{ 'col-span-1': item.length > 100 }"
      >
        <template v-if="typeof item === 'boolean'">
          {{ item ? 'Yes' : 'No' }}
        </template>
        <template v-else-if="key.toLowerCase().includes('time')">
          {{ formatEpochDate(item) }}
        </template>
        <template v-else-if="typeof item === 'number'">
          <code>{{ item }}</code>
        </template>
        <template v-else>
          {{ item }}
        </template>
      </ListItem>
    </template>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';
import { splitCamelCase, capitalize } from '@/util';

export default {
  name: 'UnknownEvent',
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTitle(value) {
      const split = splitCamelCase(value);
      return capitalize(split);
    },
  },
};
</script>
