<template>
  <j-button
    v-show="hasGroups"
    @click="toggleGroup"
    style-type="ghost"
    :class="classes"
  >
    <template #leading>
      <j-icon
        data="@jcon/chevron-small.svg"
        :width="24"
        :height="24"
        :rotate="rotation"
      />
    </template>
  </j-button>
</template>

<script>
export default {
  name: 'TableGroupControlHeader',
  props: ['params'],
  computed: {
    classes() {
      return {
        'expand-button': true,
        'is-expanded': this.allGroupsExpanded,
        'is-contracted': !this.allGroupsExpanded,
      };
    },
    rotation() {
      return this.allGroupsExpanded ? 0 : 270;
    },
    allGroupsExpanded() {
      return this.$route.query?.expanded === 'all';
    },
    hasGroups() {
      return !!this.$route.query?.groups;
    },
  },
  methods: {
    toggleGroup() {
      if (this.allGroupsExpanded) {
        this.params.api.collapseAll();
      } else {
        this.params.api.expandAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-button {
  --size-action-padding-icon-only: 4px;
  --size-action-height-base: 32px;
  margin-left: -9px;
}
</style>
