<template>
  <div class="scroll-vertical" role="tabpanel">
    <div
      id="device-info"
      class="p-2 grid"
      :class="{ 'has-status': hasStatusFeature }"
    >
      <div class="g-rows computer-status" v-if="hasStatusFeature">
        <h1 class="subtitle-text">Computer Status</h1>
        <Card class="g-col-2">
          <ListItem title="Connection status">
            <div class="g-cols max-c ai-center gap-1">
              <ConnectionIndicator :status="connectionStatus" />
              <span v-if="isConnected">Connected</span>
              <span v-else-if="computer.lastDisconnection">
                Not connected {{ fromLastDisconnectionDate }}
              </span>
              <span v-else>Not connected</span>
            </div>
            <div
              v-if="isConnected && canWrite"
              class="g-cols max-c ai-center gap-1 mt-3"
            >
              <j-button @click="forceCheckin()" data-feature-id="force-checkin"
                >Request Check-in</j-button
              >
              <j-updated
                class="ml-1"
                v-model="checkinMessageSent"
                :failed="!forceCheckinResponse"
                :message="
                  forceCheckinResponse
                    ? 'Checkin message sent!'
                    : 'Something went wrong.'
                "
              />
            </div>
          </ListItem>
          <ListItem v-if="isConnected" title="Connected since">
            {{ formatEpochDate(computer.lastConnection) }}
          </ListItem>
          <ListItem v-else title="Not connected since">
            {{ formatEpochDate(computer.lastDisconnection) }}
          </ListItem>
          <p v-if="isPastDue">Connection status is past due.</p>
        </Card>
      </div>
      <div class="g-rows info">
        <h1 class="subtitle-text">Computer Info</h1>
        <Card class="g-rows max-c">
          <ListItem title="Model">
            {{ computer.modelName }}
          </ListItem>
          <ListItem title="Serial">
            {{ computer.serial }}
          </ListItem>
          <ListItem title="Device ID">
            {{ computer.uuid }}
          </ListItem>
          <hr />
          <div class="g-col-2">
            <ListItem v-if="!hasStatusFeature" title="Last Connected">
              {{ formatEpochDate(computer.checkin) }}
            </ListItem>
            <ListItem title="First Connected">
              {{ formatEpochDate(computer.created) }}
            </ListItem>
            <ListItem title="OS">
              {{ osFallback }}
              <j-notification
                v-if="isDeprecated"
                type="warning"
                class="secondary deprecation-message"
              >
                <!-- prettier-ignore -->
                <p>This version of macOS
                  <span class="sr-only">({{ osFallback }}) </span>is no longer supported.
                  For more information, see<a
                    href="https://docs.jamf.com/jamf-protect/documentation/General_Requirements.html"
                    target="_blank"
                    rel="noopener"
                    > General Requirements<span class="sr-only">
                      (opens in a new tab)</span
                    ></a
                  >
                  in the Jamf Protect Documentation.
                </p>
              </j-notification>
            </ListItem>
            <ListItem
              title="Network Threat Prevention"
              v-if="isFeatureEnabled('NTP_R1')"
            >
              <div class="g-cols max-c ai-center gap-1">
                <template v-if="computer.ntpActive"
                  ><j-icon
                    data="@jcon/check-circle.svg"
                    color="var(--color-success-base)"
                    height="20"
                    width="20"
                  />
                  Enabled</template
                >
                <template v-else>Disabled</template>
                <router-link :to="{ name: 'prevent.index.network' }"
                  >View Details</router-link
                >
              </div>
            </ListItem>
            <ListItem title="Architecture">
              {{ computer.arch }}
            </ListItem>
            <ListItem title="Install Type">
              {{
                computer.installType === 'systemExtension'
                  ? 'System Extension'
                  : computer.installType
              }}
            </ListItem>

            <ListItem title="Memory">
              {{ bytesToSize(computer.memorySize) }}
            </ListItem>
          </div>
          <hr />
          <ListItem title="Kernel">
            {{ computer.kernelVersion }}
          </ListItem>
          <ListItem title="Certificate ID">
            {{ computer.certid }}
          </ListItem>
          <ListItem
            v-if="Object.getOwnPropertyDescriptor(computer, 'plan')"
            title="Plan Hash"
          >
            <div class="g-cols ai-center max-c">
              <j-tooltip
                v-if="!latestPlan(computer)"
                :text="latestHash"
                open-position="right"
              >
                <j-icon
                  color="var(--color-warning-base)"
                  data="@jcon/warning.svg"
                />
              </j-tooltip>
              {{ computer.configHash }}
            </div>
          </ListItem>
        </Card>
      </div>
      <div class="g-rows edit">
        <h1 class="g-cols max-c subtitle-text ai-center">
          Edit Computer
          <j-updated v-model="updated" />
          <gp-loader v-if="saving" />
        </h1>
        <Card class="g-rows gap-none">
          <template v-if="!loading">
            <div class="f-col-2 ai-end">
              <j-input
                label="Label"
                v-model="label"
                data-feature-id="computer-label"
                helper-text="Set a label for this computer"
                :is-read-only="!canWrite"
                :placeholder="canWrite ? '' : 'None'"
              />
              <j-select
                v-if="planAccess && planOptions"
                v-model="selectedPlan"
                class="plan-dropdown"
                label="Plan"
                data-feature-id="computer-plan"
                :is-read-only="!canWrite"
                helper-text="Updated on checkin"
                :options="planOptions"
                :searchable="isSearchable(planOptions)"
                :placeholder="canWrite ? 'Select A Plan' : 'None'"
              />
            </div>
            <div class="f-wrap ai-end">
              <j-select
                v-model="tags"
                :options="allTags"
                :searchable="isSearchable(allTags)"
                allow-multi-select
                is-addable
                :is-read-only="!canWrite"
                deselect-from-dropdown
                label="Tags"
                :placeholder="canWrite ? 'Add a Tag' : 'None'"
                data-feature-id="computer-tags"
              />
              <div v-if="canWrite" class="g-cols max-c jc-end">
                <j-button
                  style-type="ghost"
                  @click="cancel"
                  data-feature-id="computer-cancel-button"
                  class="computer-cancel"
                  >Cancel</j-button
                >
                <j-save-button
                  @click="submit"
                  data-feature-id="computer-save-button"
                  class="js-end computer-save"
                />
              </div>
            </div>
          </template>
          <div v-else class="g-rows ji-center p-6">
            <gp-loader />
          </div>
        </Card>
      </div>
      <div v-if="planAccess" class="g-rows plan">
        <h1 class="g-cols max-c subtitle-text ai-center">
          Current Plan
          <j-updated v-model="planUpdated" />
        </h1>
        <Card>
          <PlanDetailCard v-if="plan" :plan="plan" />
          <div v-else class="g-rows">
            <p class="helper-text">
              No existing plan is assigned to this computer.
            </p>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { useComputers } from '@/modules/computers/computers.js';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';
import ConnectionIndicator from '@/components/ConnectionIndicator.vue';
import { mapGetters, mapState } from 'vuex';
import PlanDetailCard from '@/modules/plans/PlanDetailCard.vue';
import { useRBAC } from '@/composables/rbac';
import { useGmt } from '@/composables/gmt';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { bytesToSize } from '@/util';

export default {
  name: 'ComputerDetails',
  components: { Card, ListItem, PlanDetailCard, ConnectionIndicator },
  setup(props) {
    const { formatEpochDate } = useGmt();
    const { canWrite, canAccess } = useRBAC(RBAC_RESOURCE.COMPUTER);
    const {
      isConnected,
      isDeprecated,
      osFallback,
      connectionStatus,
      isPastDue,
      fromLastDisconnectionDate,
    } = useComputers(props);

    return {
      canWrite,
      canAccess,
      formatEpochDate,
      isConnected,
      isDeprecated,
      osFallback,
      connectionStatus,
      isPastDue,
      fromLastDisconnectionDate,
    };
  },
  data() {
    return {
      message: null,
      latestHash: '',
      label: '',
      tags: '',
      allTags: [],
      plan: null,
      plans: null,
      planOptions: null,
      selectedPlan: null,
      loading: true,
      updated: false,
      saving: false,
      planUpdated: false,
      checkinMessageSent: false,
      forceCheckinResponse: false,
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('primary', {
      computer: (state) => state.computers.computer,
    }),
    hasStatusFeature() {
      return this.isFeatureEnabled('COMPUTER_CONNECTION_STATUS');
    },
    planAccess() {
      return this.canAccess([RBAC_RESOURCE.PLAN]);
    },
  },
  watch: {
    selectedPlan() {
      this.plan = this.plans?.find(({ id }) => id === this.selectedPlan);
    },
    'computer.plan.id'() {
      this.plan = this.plans?.find(({ id }) => id === this.computer?.plan?.id);
    },
  },
  async mounted() {
    const tags = await this.$store.dispatch('primary/listComputersTags');
    this.allTags = tags?.map((tag) => tag.value) || [];
    this.getTagsAndLabel();
    if (this.planAccess) {
      await this.getPlanInfo();
    }
    this.loading = false;
  },
  methods: {
    bytesToSize,
    latestPlan(computer) {
      if (computer?.plan) {
        return computer.plan.hash === computer.configHash;
      }
      return true;
    },
    async getPlanInfo() {
      this.plans = await this.$store.dispatch('primary/listAllPlans', {
        direction: 'DESC',
        field: 'created',
      });

      this.planOptions = this.plans?.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      if (this.computer.plan) {
        this.latestHash = `Expected Hash: ${this.computer.plan?.hash}`;
        this.plan = this.plans?.find(({ id }) => id === this.computer.plan?.id);
        this.selectedPlan = this.plan?.id;
      }
    },
    getTagsAndLabel() {
      this.tags = [...(this.computer?.tags || [])];
      this.label = this.computer?.label;
    },
    cancel() {
      this.getTagsAndLabel();
      this.selectedPlan = this.computer?.plan?.id;
    },
    async forceCheckin() {
      const success = await this.$store.dispatch('primary/sendCheckinMessage', {
        uuid: this.computer.uuid,
      });
      this.forceCheckinResponse = success;
      this.checkinMessageSent = true;
    },
    async submit() {
      this.saving = true;
      if (this.computer?.plan?.id !== this.selectedPlan) {
        const computer = await this.$store.dispatch('primary/setComputerPlan', {
          uuid: this.computer.uuid,
          plan: `${this.selectedPlan}`,
        });
        this.plan = this.plans?.filter(({ id }) => id === computer.plan?.id)[0];
        this.planUpdated = true;
      }
      const response = await this.$store.dispatch('primary/updateComputer', {
        uuid: this.computer.uuid,
        label: this.label,
        tags: this.tags,
      });

      this.saving = false;
      if (response) {
        this.updated = true;
      }
    },
    isSearchable(options) {
      return options.length > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  @include grid;
  grid-template-areas:
    'info edit'
    'info plan';
  grid-template-rows: auto 1fr;
  grid-template-columns: 1.5fr 1fr;
  height: max-content;
  max-height: max-content;

  &.has-status {
    grid-template-areas:
      'status edit'
      'info edit'
      'info plan';
    grid-template-rows: auto auto 1fr;
  }

  .force-check-in {
    margin-top: 40px;
  }

  .computer-status {
    grid-area: status;
  }

  .info {
    grid-area: info;
  }

  .edit {
    grid-area: edit;
  }

  .plan {
    grid-area: plan;
  }

  .plan-dropdown {
    align-self: baseline;
  }

  .plan,
  .computer-status,
  .info,
  .edit {
    grid-auto-rows: max-content auto;
  }

  .deprecation-message {
    margin-top: spacing(2);
    margin-bottom: spacing(1);
  }

  @include breakpoint(medium down) {
    grid-template-areas: 'edit' 'plan' 'info';
    grid-template-rows: auto;
    grid-template-columns: auto;
    .card-container {
      height: auto !important;
    }
    &.has-status {
      grid-template-areas: 'status' 'edit' 'plan' 'info';
    }
  }
}
</style>
