<template>
  <div class="g-rows">
    <ListItem title="Event Type"
      >File {{ getEnumString('type', event.type) }}
    </ListItem>
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Path">
      {{ event.path }}
    </ListItem>
    <ListItem title="Process">
      {{ relatedProcess(event.pid) }}
    </ListItem>
    <ListItem title="User">
      {{ relatedUser(event.uid) }}
    </ListItem>
    <ListItem title="Group">
      {{ relatedGroup(event.gid) }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import { useEvents } from '@/modules/alerts/components/events/events';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPFSEvent',
  inject: ['$typeDefs'],
  components: { ListItem },
  setup(props) {
    const { getEnumString, relatedProcess, relatedUser, relatedGroup } =
      useEvents(props);
    const { formatEpochDate } = useGmt();
    return {
      getEnumString,
      relatedProcess,
      relatedUser,
      relatedGroup,
      formatEpochDate,
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      def: this.$typeDefs.events.GPFSEvent,
    };
  },
};
</script>
