<template>
  <j-modal v-model="computedValue" :type="type" :auto-focus="true">
    <div class="g-rows gap-0">
      <p>{{ bodyText }}</p>
      <p v-if="hasItemsToDelete && !hasBlockers">
        <template v-for="(item, index) in computedToDelete"
          >{{
            computedToDelete.length > 1 && computedToDelete.length - 1 === index
              ? ` and ${item}`
              : `${item}${computedToDelete.length > 1 ? ',' : ''} `
          }}
        </template>
      </p>
    </div>
    <router-link v-if="blockers.length === 1" :to="blockers[0].route">
      {{ blockers[0].value }}
    </router-link>
    <div v-else-if="blockers.length > 1" class="tags mt-1">
      <j-tag v-for="{ value, route } in blockers" :key="value" :to="route">
        {{ value }}
      </j-tag>
    </div>
    <template #header="{ alignClass, iconClass }">
      <section :class="alignClass">
        <j-icon
          v-if="type === 'danger'"
          data="@jcon/error.svg"
          :class="iconClass"
        />
        <j-icon v-else data="@jcon/warning.svg" :class="iconClass" />
        <h1 class="h3">{{ titleText }}</h1>
      </section>
    </template>
    <template #actions="{ close }">
      <slot name="actions"></slot>
      <j-button
        v-if="type !== 'danger'"
        @click="close"
        data-feature-id="delete-modal-cancel"
        >Ok</j-button
      >
      <template v-else>
        <j-button
          style-type="secondary"
          @click="close"
          data-feature-id="delete-modal-cancel"
          >Cancel</j-button
        >
        <j-button
          style-type="danger"
          data-feature-id="delete-modal-primary"
          @click="handleClick"
          >Delete</j-button
        >
      </template>
    </template>
  </j-modal>
</template>

<script>
export default {
  name: 'DeleteModal',
  compatConfig: {
    MODE: 3,
  },
  props: {
    text: String,
    title: {
      type: String,
      default: 'Are you sure?',
    },
    /**
     * Set as a normal v-model would be
     * @model
     */
    modelValue: Boolean,
    /** Example ['Name', 'Name 2'] || 'Name'  */
    toDelete: {
      type: [Array, String],
    },
    /** Example [{ value: 'Name', route: { name: 'plans.index.detail', params: { id: 3 }}}] */
    blockers: {
      type: Array,
      default: () => [],
    },
    blockerKind: {
      type: String,
      default: 'Plan(s)',
    },
  },
  emits: ['update:modelValue', 'confirm'],
  data() {
    return {
      newValue: this.modelValue,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('update:modelValue', value);
      },
    },
    computedToDelete() {
      return typeof this.toDelete === 'string'
        ? [this.toDelete]
        : this.toDelete;
    },
    bodyText() {
      const hasToDelete = this.hasItemsToDelete ? ':' : '?';
      return !this.hasBlockers
        ? this.text || `Are you sure you want to delete${hasToDelete}`
        : `Cannot delete because of the following associated ${this.blockerKind}`;
    },
    titleText() {
      return !this.hasBlockers ? this.title : 'Unable to delete';
    },
    type() {
      return this.hasBlockers ? 'warning' : 'danger';
    },
    hasBlockers() {
      return this.blockers.length > 0;
    },
    hasItemsToDelete() {
      return this.computedToDelete?.length > 0;
    },
  },
  watch: {
    /** When v-model change, set internal value. */
    modelValue(value) {
      this.newValue = value;
    },
  },
  methods: {
    handleClick() {
      this.computedValue = false;
      this.$emit('confirm');
    },
  },
};
</script>
