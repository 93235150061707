<template>
  <div class="j-table-tooltip">
    <div v-if="show">
      {{ message }}
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableTooltip',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    params: Object,
  },
  data() {
    return {
      visibility: this.isVisible,
    };
  },
  watch: {
    isVisible(newValue) {
      this.visibility = newValue;
    },
  },
  computed: {
    show: {
      get() {
        return this.params?.condition?.(this.params) ?? this.visibility;
      },
      set(value) {
        this.visibility = value;
      },
    },
    message() {
      return (
        (typeof this.params?.message === 'string'
          ? this.params?.message
          : this.params?.message?.(this.params)) ||
        this.params?.valueFormatted ||
        this.params?.value
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.j-table-tooltip {
  pointer-events: none;
  position: absolute;

  > div {
    @include tooltip;
    opacity: 1;
    margin: 0 spacing();
    transform: translateY(-32px);
    transition: opacity 200ms;
    position: relative;
  }

  &.bottom-start .arrow {
    left: 4px;
  }

  &.ag-tooltip-hiding {
    opacity: 0;
  }
}
</style>
