import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import gql from 'graphql-tag';

export const GET_OVERVIEW_COUNTS = (getPermissions) => {
  const hasInsights = getPermissions(RBAC_RESOURCE.INSIGHT);
  const hasComputer = getPermissions(RBAC_RESOURCE.COMPUTER);
  const hasAlert = getPermissions(RBAC_RESOURCE.ALERT);

  const insightFragment = hasInsights ? 'insightsComputers' : '';
  const computerQuery = hasComputer
    ? `lateCount: getCount(input: {
            computers: {
              checkin: { beforeInterval: "7 days" },
            },
          }) {
              computers @skip(if: $hasConnection)
            }

          issuesCount: getCount(input: {
            computers: {
              lastDisconnection: { beforeInterval: "30 days" },
              and: [{ connectionStatus: { equals: "Disconnected" } }]
            },
            insightsComputers: { and: [] }
          }) {
              computers @include(if: $hasConnection)
              ${insightFragment}
            }
          allCount: getCount(input: {
            computers: { and: [] }
          }) {
              computers
            }
          `
    : '';

  const alertQuery =
    hasAlert && hasComputer
      ? `
          alertCounts: listComputersAlertCounts(input: {
            created: { afterInterval: "24 hours" },
            and: [
              {
                severity: {
                  greaterThanOrEqual: Low,
                },
              },
            ],
          }) {
            computer {
              uuid
              hostName
            }
            count
          }`
      : '';

  return gql`
    query getOverviewCount($hasConnection: Boolean!) {
      ${computerQuery}
      ${alertQuery}
    }`;
};

export const GET_APPLE_ROWS = gql`
  query getAppleRows {
    catalina0: getAnalytic(uuid: "b83111e8-9f75-419a-8513-d625c6b64cb4") {
      uuid
      name
    }

    catalina1: getAnalytic(uuid: "40314161-fe88-11e9-893a-acde48001122") {
      uuid
      name
    }

    catalina2: getAnalytic(uuid: "9806766e-fe88-11e9-bf22-acde48001122") {
      uuid
      name
    }

    catalina3: getAnalytic(uuid: "daaf11e6-fe8e-11e9-95a8-acde48001122") {
      uuid
      name
    }

    catalina4: getAnalytic(uuid: "eb7fdf13-543b-455b-92a6-999646e5c944") {
      uuid
      name
    }
  }
`;

export const GET_APPLE_ANALYTICS = (getPermissions) => {
  const hasInsights = getPermissions([RBAC_RESOURCE.INSIGHT]);
  const hasComputer = getPermissions([RBAC_RESOURCE.COMPUTER]);
  const hasAlert = getPermissions([RBAC_RESOURCE.ALERT]);

  const hasInsightsComputerQuery = hasInsights
    ? 'insightsComputers: $insightsComputers,'
    : '';
  const computerQuery = (num) => {
    return `
    computers: $computers,
    ${hasInsightsComputerQuery}
    alertsComputers: {
      created: { afterInterval: $timeInterval },
      and: [{ analytics: { includes: [$catalina${num}_name] } }]
    }`;
  };

  const hasInsightsComputerInsights = hasInsights ? 'insightsComputers' : '';
  const hasAlertsComputerInsights = hasAlert ? 'alertsComputers' : '';
  const computerInsights = `
    computers
    ${hasInsightsComputerInsights}
    ${hasAlertsComputerInsights}`;

  const hasInsightsComputerFilters = hasInsights
    ? '$insightsComputers: ComputerFiltersInput,'
    : '';
  const computerFilters = `
    $computers: ComputerFiltersInput,
    ${hasInsightsComputerFilters}`;

  const getSetup = (num) => `
    catalina${num}_counts: getCount(input: {
        ${hasAlert ? 'alerts: $alerts,' : ''}
        ${hasComputer ? computerQuery(num) : ''}
      }) {
        ${hasAlert ? 'alerts' : ''}
        ${hasComputer ? computerInsights : ''}
      }
    catalina${num}_alerts: listAlertsAnalytics(input: {
      created: { afterInterval: $timeInterval },
      and: [{ analytics: { includes: [$catalina${num}_name] } }]
    }) {
      value
      count
    }
  `;

  return gql`
    query getAppleAnalytics(
      ${hasAlert ? '$alerts: AlertFiltersInput,' : ''}
      ${hasComputer ? computerFilters : ''}
      $catalina0_name: String,
      $catalina1_name: String,
      $catalina2_name: String,
      $catalina3_name: String,
      $catalina4_name: String,
      $timeInterval: String,
    ) {
      ${getSetup(0)}
      ${getSetup(1)}
      ${getSetup(2)}
      ${getSetup(3)}
      ${getSetup(4)}
    }
  `;
};

export const GET_APPLE_CONTEXT_VALUES = () => {
  const getSetup = (num, valueCountAttribute) => `
    catalina${num}_tags: listAlertsEventValues(
      input: {
        name: "${valueCountAttribute}"
        filter: {
          created: { afterInterval: $timeInterval }
          and: [
            { analytics: { includes: [$catalina${num}_name] } }
            { severity: { greaterThanOrEqual: Informational } }
          ]
        }
      }
    ) {
      value
      count
    }
  `;

  return gql`
    query getAppleContextValues(
      $catalina0_name: String!,
      $catalina1_name: String!,
      $catalina2_name: String!,
      $catalina3_name: String!,
      $catalina4_name: String!,
      $timeInterval: String!,
    ) {
      ${getSetup(0, 'name')}
      ${getSetup(1, 'name')}
      ${getSetup(2, 'name')}
      ${getSetup(3, 'detection')}
      ${getSetup(4, 'XProtectSignatureName')}
    }
  `;
};

export const GET_COMPUTERS_STATS = () => {
  const getSetup = (field) => `
      ${field}: listComputersStats(
        input: { 
          field: ${field}
        }
      ) {
        value
        count
      }
    `;
  return gql`
      query getComputersStats {
        ${getSetup('version')}
        ${getSetup('macosVersion')}
        ${getSetup('modelName')}
      }
    `;
};

export const GET_COMPUTERS_ALERTS_COUNTS = () => {
  const getSetup = (field) => `
    ${field}: listComputersAlertCounts(
      input: {
        created: { 
          afterInterval: "7 days"  
        },
        and: [
          { 
            severity: 
            { 
              equals: ${field} 
            } 
          }
        ],
      }
    ) {
      computer {
        uuid
        hostName
      }
      count
    }
    `;
  return gql`
      query getComputersAlertsCounts {
        ${getSetup('Low')}
        ${getSetup('Medium')}
        ${getSetup('High')}
      }
    `;
};
