<template>
  <div class="g-rows">
    <div
      v-for="{ label, description, examples, types } in ignoreTypeInfo"
      :key="label"
    >
      <template v-if="isFootnote(label)">
        <hr />
        <div class="p-1">
          <label>{{ label }}</label>
          <p class="mb-2 helper-text">{{ description }}</p>

          <div class="notes">
            <div
              v-for="{ value, info } in types"
              :key="value"
              class="wildcards"
            >
              <code>{{ value }}</code>
              <p>{{ info }}</p>
            </div>
          </div>
          <p class="mt-2 helper-text">
            For a literal match, wrap the meta-character in brackets. Typing [*]
            matches the character * instead of using it as a wildcard. For
            example, to match
            <code>'Application/data/*profiletemplate'</code> enter
            <code>'Application/Data/[*]profiletemplate'</code>.
          </p>
        </div>
      </template>
      <template v-else>
        <h1 class="h4">{{ label }}</h1>
        <p class="mb-2">{{ description }}</p>
        <div class="notes">
          <template v-for="{ value, info } in types" :key="info">
            <p>{{ info }}</p>
            <code>{{ value }}</code>
          </template>
          <div v-if="examples" class="examples">
            Example:
            <code v-for="example in examples" :key="example" class="mr-1">{{
              example
            }}</code>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { IGNORE_TYPE_NAMES } from '../exception.types';
export default {
  name: 'ExceptionSetDocumentation',
  data() {
    return {
      ignoreTypeInfo: [
        {
          label: IGNORE_TYPE_NAMES.Path,
          description:
            'The location of an item starting at the root of the file system. Wildcards are supported to implement exceptions for FilePath.',
          examples: [
            '/tmp/log/*',
            '/Users/*/Pictures/Photos Library.photoslibrary/resources/*',
            '*/Library/Cookies/Cookies.binarycookies*',
          ],
        },
        {
          label: 'Signing ID',
          description: `An application's identifier, such as com.apple.calculator. 
          Signing ID requires both a Team ID and an App ID or Signing ID.
          This only applies to Endpoint Threat Prevention, Process, File, Click, and Keylogger events.`,
          types: [
            {
              value: 'codesign -dv /Applications/JamfProtect.app',
              info: 'Both the App ID and Signing ID of an application can be found by running the codesign command line utility from the terminal.',
            },
          ],
          examples: ['com.jamf.protect.daemon'],
        },
        {
          label: IGNORE_TYPE_NAMES.PlatformBinary,
          description: `A platform binary is built into macOS and is specially signed by Apple. 
          These specially signed binaries do not have an associated Team ID, 
          and are referenced by the App ID, such as com.apple.calculator.`,
          types: [
            {
              value: 'codesign -dv /System/Applications/Calculator.app',
              info: 'The App ID of a platform binary can be found by running the codesign command line utility from the terminal.',
            },
          ],

          examples: [
            'com.apple.calculator',
            'com.apple.news.widget',
            'com.apple.photolibraryd',
          ],
        },
        {
          label: IGNORE_TYPE_NAMES.TeamId,
          description: `A unique code issued by Apple that identifies an application developer in the signed certificate. 
          Team IDs are formatted alphanumerically, such as "526FTYP998". This only applies to Endpoint Threat Prevention, Process, 
          File, Click, and Keylogger events.`,
          types: [
            {
              value: 'codesign -dv /Applications/JamfProtect.app',
              info: 'The Team ID of an application can be found by running the codesign command line utility from the terminal',
            },
          ],

          examples: ['483DWKW443'],
        },
        {
          label: IGNORE_TYPE_NAMES.Executable,
          description: `The full path to an application or binary. 
          The path is responsible for the system event or activity targeted by an exception, 
          such as File, Keylogger, and Click events, or to the application itself being launched 
          (process event) or prevented (Endpoint Threat Prevention). Wildcards are supported to implement exceptions for Process Path.`,
          examples: [
            '/Applications/1Password/7.app',
            '/System/Applications/Calculator.app',
            '/Applications/ThisApp.app',
          ],
        },
        {
          label: IGNORE_TYPE_NAMES.User,
          description: `The user account name responsible for generating the event on the monitored computer. This can include system accounts. 
Examples of user and system accounts are:`,
          types: [
            {
              value: 'janet.smith',
              info: 'User Account:',
            },
            {
              value: 'jamfpro',
              info: 'System Account:',
            },
          ],
        },
        {
          label: 'Wildcard Support',
          description: `File path and process path exceptions provide support for Unix shell-style wildcards. 
          Unix shell wildcards and Regular Expressions are similar, however the two are not explicitly interchangeable.`,
          types: [
            { value: '*', info: 'Matches everything' },
            { value: '?', info: 'Matches any single character' },
            { value: '[seq]', info: 'Matches any character in seq' },
            { value: '[!seq]', info: 'Matches any character not in seq' },
          ],
        },
      ],
    };
  },
  methods: {
    isFootnote(label) {
      return label === 'Wildcard Support';
    },
  },
};
</script>

<style lang="scss" scoped>
.notes {
  @include border-radius;
  @include grid(spacing(0.5));
  grid-template-columns: auto;
  background: var(--color-structure-base);
  padding: spacing(2);
  max-width: max-content;

  code {
    word-break: break-all;
    max-width: max-content;
    width: 110%;
    + .examples {
      margin-top: spacing(2);
    }
  }

  .wildcards {
    @include grid;
    grid-template-columns: 60px max-content;
    align-items: center;
  }

  .examples {
    @include grid(spacing());
    grid-template-columns: max-content;
  }
}
</style>
