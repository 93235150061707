<template>
  <div class="gp-content">
    <j-form-bar
      v-if="mode.create || plan"
      :title="mode.create ? 'Create Plan' : plan.name"
      @edit="() => routeTo('edit', { id: statePlan.id })"
      @submit:created="() => routeTo('detail', { id: statePlan.id })"
      @cancel="() => routeTo('detail', { id: statePlan.id })"
      @back="() => routeTo()"
      @remove="() => confirmRemove(plan)"
      :can-write="canWrite"
    >
      <template #[accessButtonSlot]>
        <transition name="fade">
          <j-button
            v-if="!mode.create && !mode.edit"
            @click="getCustomDownload()"
            data-feature-id="plan-download"
          >
            <template #leading v-if="!cloudOnlyAccess">
              <j-icon data="@jcon/download-2.svg" />
            </template>
            <template #leading v-else>
              <j-icon data="@jcon/list-checked-circle.svg" />
            </template>
            {{ accessButton }}
          </j-button>
        </transition>
      </template>
    </j-form-bar>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component v-if="!loading" :is="Component" :plan="plan" />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      @confirm="remove()"
    />

    <PlanProfileDownload
      v-model="showDownloadsModal"
      :plan="plan"
      @plan:download="(options) => downloadConfigProfile(plan, options)"
    />
  </div>
</template>

<script>
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapState } from 'vuex';
import PlanProfileDownload from './tabs/PlanProfileDownload.vue';
import { usePlan } from './plans';
import { useRBAC } from '@/composables/rbac';
import { useRouteHelpers } from '@/composables/route-helpers';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'Plan',
  components: { PlanProfileDownload },
  inject: ['$typeDefs'],
  setup() {
    const { cloudOnlyAccess, canAccess, canWrite } = useRBAC([
      RBAC_RESOURCE.PLAN,
    ]);
    const { downloadConfigProfile, downloadOfflineOptions } = usePlan();
    const { mode, routeTo } = useRouteHelpers();
    const { showRemoveModal, toDelete, confirmRemove } = useRemoveHelpers();
    return {
      cloudOnlyAccess,
      canAccess,
      canWrite,
      mode,
      routeTo,
      showRemoveModal,
      toDelete,
      confirmRemove,
      downloadConfigProfile,
      downloadOfflineOptions,
    };
  },
  computed: {
    ...mapState({
      queryState: (state) => state.app.queryState,
    }),
    ...mapState('primary', {
      statePlan: (state) => state.plans.plan,
    }),
    plan() {
      return this.mode.create ? this.$typeDefs?.configs?.plan : this.statePlan;
    },
    accessButton() {
      return !this.cloudOnlyAccess ? 'Download' : 'Custom Profile';
    },
    accessButtonSlot() {
      return this.canWrite ? 'right' : 'default';
    },
  },
  data() {
    return {
      loading: true,
      updated: false,
      showDownloadsModal: false,
    };
  },
  async beforeMount() {
    if (!this.mode.create) {
      await this.$store.dispatch('primary/getPlan', {
        id: this.$route.params.id,
      });
    }
    this.loading = false;
  },
  methods: {
    async remove() {
      this.showRemoveModal = false;
      const response = await this.$store.dispatch('primary/deletePlan', {
        id: this.statePlan.id,
      });
      if (response) {
        this.$router.push({ name: 'plans.index' });
      }
    },
    async getCustomDownload() {
      if (this.cloudOnlyAccess) {
        this.showDownloadsModal = true;
      } else {
        await this.downloadConfigProfile(
          this.plan,
          this.downloadOfflineOptions
        );
      }
    },
  },
};
</script>
