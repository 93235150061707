import { SEVERITY_LEVEL } from '@/util/constants/severity.types';
import { helpers, email } from '@vuelidate/validators';

const minSeverity = (value, { maxSeverity }) => {
  return SEVERITY_LEVEL[value] <= SEVERITY_LEVEL[maxSeverity];
};

const maxSeverity = (value, { minSeverity }) => {
  return SEVERITY_LEVEL[value] >= SEVERITY_LEVEL[minSeverity];
};

/**
 * @description Use to validate name against list in the store and any known duplicateName
 *              from a recent resource exists error.
 * @param {string} currentName name of existing resource currently being edited
 * @var {array} duplicateNames set as computed prop from store list values (defaults to empty array)
 * @var {array} duplicateName known duplicate found when resource exists error occurs. No action needed.
 * @returns {boolean}
 */
const isUniqueName = (currentName) => {
  return helpers.withMessage(
    'Name already exists.',
    helpers.withParams(
      { type: 'isUniqueName', value: currentName },
      (value, _sibling, vm) => {
        if (value === '') return true;
        let names = vm.duplicateNames || [];
        if (vm?.mode?.edit && currentName) {
          names = names?.filter((name) => name !== currentName);
        }
        return !(
          names?.includes(value) ||
          ('duplicateName' in vm && vm.duplicateName === value)
        );
      }
    )
  );
};

const hexadecimal = helpers.withMessage(
  'Should follow "^0x[a-fA-F0-9]+$" (e.g. 0x1A31).',
  (value) => {
    if (typeof value === 'string') {
      return /^0x[a-fA-F0-9]+$/gm.test(value);
    }
    // don't require value, let that be determined instead by a required validator
    return value ? value.every((val) => /^0x[a-fA-F0-9]+$/gm.test(val)) : true;
  }
);

const hexadecimalIf = (param) => (param ? hexadecimal : true);
const emailIf = (param) => (param ? email : true);

export {
  maxSeverity,
  minSeverity,
  isUniqueName,
  hexadecimal,
  hexadecimalIf,
  emailIf,
};
