<template>
  <div class="bailout">
    <div class="g-cols ai-center max-c">
      <h2 class="subtitle-text">RBAC Bailout</h2>
      <gp-loader class="loader" :show-loader="showLoader"
        >Assigning Full Admin Role</gp-loader
      >
    </div>
    <Card class="g-rows max-c">
      <div class="section-label">
        <h2 class="h4">Restore JamfID access to locked out tenant</h2>
        <p class="helper-text">
          The Full Admin role can be directly assigned to an enabled JamfID
          user. This direct assignment can be removed by the customer once RBAC
          configuration is rectified.
        </p>
      </div>
      <j-checkbox v-model="confirmation">
        This action is being taken to resolve a documented locked-out customer
        support case
      </j-checkbox>
      <div class="f-wrap max-c ai-end">
        <j-select
          v-model="targetUserEmail"
          label="Available JamfID Users"
          placeholder="Select a User..."
          :options="jamfIdUsers"
          :searchable="jamfIdUsers.length > 10"
        />
        <j-button
          class="js-end"
          @click="submit()"
          :is-disabled="!(targetUserEmail && confirmation && !showLoader)"
          ><template #leading><j-icon data="@icon/lightning.svg" /></template
          >Assign Full Admin Role</j-button
        >
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'TenantBailOut',
  components: { Card },
  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLoader: false,
      jamfIdUsers: [...this.tenant.jamfIdUsers],
      targetUserEmail: '',
      confirmation: false,
      message: null,
    };
  },
  methods: {
    async submit() {
      this.message = '';
      this.showLoader = true;
      const response = await this.$store.dispatch('admin/assignFullAdmin', {
        orgUuid: this.tenant.uuid,
        userEmail: this.targetUserEmail,
      });
      if (response) {
        this.showLoader = false;
        this.message = `Full Admin role assigned to JamfID user ${response}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bailout {
  @include grid;
  grid-template-rows: min-content auto;

  .header-bar {
    @include grid;
    @include grid-columns(0.5fr max-content);
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
