import OverviewSection from '@/modules/overviews/OverviewSection.vue';
import OverviewMain from '@/modules/overviews/OverviewDetections.vue';
import OverviewApple from '@/modules/overviews/OverviewApple.vue';
import OverviewComputers from '@/modules/overviews/OverviewComputers.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default [
  {
    path: '/overview',
    component: OverviewSection,
    name: 'overview',
    meta: {
      breadcrumb: {
        linkText: 'Overview',
      },
      permissions: [
        RBAC_RESOURCE.ANALYTIC,
        RBAC_RESOURCE.COMPUTER,
        RBAC_RESOURCE.ALERT,
        RBAC_RESOURCE.INSIGHT,
      ],
      requireOnlySome: true,
    },
    children: [
      {
        path: '',
        redirect: '/overview/detections/', // Set this to the initial page to be displayed
        name: 'overview.index',
      },
      {
        path: 'security',
        component: OverviewApple,
        name: 'overview.apple',
        meta: {
          permissions: [RBAC_RESOURCE.INSIGHT, RBAC_RESOURCE.ANALYTIC],
          requireOnlySome: true,
          requireNested: {
            [RBAC_RESOURCE.ANALYTIC]: [
              RBAC_RESOURCE.COMPUTER,
              RBAC_RESOURCE.ALERT,
            ],
          },
        },
      },
      {
        path: 'computers',
        component: OverviewComputers,
        name: 'overview.computers',
        meta: {
          permissions: [RBAC_RESOURCE.COMPUTER],
        },
      },
      {
        path: 'detections',
        component: OverviewMain,
        name: 'overview.detections',
        meta: {
          permissions: [
            RBAC_RESOURCE.ANALYTIC,
            RBAC_RESOURCE.COMPUTER,
            RBAC_RESOURCE.ALERT,
          ],
          requireOnlySome: true,
          requireNested: {
            [RBAC_RESOURCE.ANALYTIC]: [
              RBAC_RESOURCE.COMPUTER,
              RBAC_RESOURCE.ALERT,
            ],
          },
        },
      },
    ],
  },
];
