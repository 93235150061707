<template>
  <transition-group name="fade" tag="div" class="idp-container scroll-vertical">
    <template v-for="connection in connections" :key="connection.name">
      <Card v-if="connection.name !== 'email'">
        <div class="g-rows">
          <h1>{{ connection.name }}</h1>
          <j-switch
            :is-read-only="isReadOnly || connection.name === 'JamfID'"
            data-feature-id="requireKnownUsers"
            v-model="connection.requireKnownUsers"
            @change="updateConnection(connection)"
          >
            <span>Require Existing Users</span>
            <p class="helper-text">
              When enabled, new users cannot sign in until they are manually
              added in the Users tab.
            </p>
          </j-switch>
        </div>
        <transition name="fade">
          <IdPMappings
            v-if="groups && connection.groupsSupport"
            :data="getData(connection.name)"
            :connection="connection"
          />
        </transition>
      </Card>
    </template>
  </transition-group>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/Card.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import IdPMappings from './components/IdPMappings.vue';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'AccountIdPs',
  components: { IdPMappings, Card },
  setup() {
    const { isReadOnly, canAccess } = useRBAC(RBAC_RESOURCE.CONNECTION);
    return { isReadOnly, canAccess };
  },
  computed: {
    ...mapState('primary', {
      groups: (state) => state.account.groups,
      connections: (state) => state.account.connections,
    }),
  },
  async beforeMount() {
    await this.$store.dispatch('primary/listConnections', {
      direction: 'ASC',
      field: 'name',
    });
    if (this.canAccess(RBAC_RESOURCE.GROUP)) {
      await this.$store.dispatch('primary/listGroups', {
        direction: 'ASC',
        field: 'name',
      });
    }
  },
  methods: {
    getData(type) {
      const mappings = this.groups.filter(
        ({ connection }) => connection?.name === type
      );
      return { items: mappings };
    },
    async updateConnection(connection) {
      await this.$store.dispatch('primary/updateConnection', connection);
    },
  },
};
</script>

<style lang="scss" scoped>
.idp-container {
  @include grid;
  @include grid-rows(max-content);
  width: 100%;
  height: 100%;
  padding: spacing(2);

  h1 {
    @include header4;
  }

  > div {
    @include grid;
    @include grid-rows(max-content 338px);
    height: 100%;
  }
}
</style>
