<template>
  <Card>
    <div class="g-cols ai-center analytic-group-level mb-1">
      <div class="g-cols max-c ai-center">
        <j-select
          v-model="logicalOperator"
          :options="operators"
          :searchable="false"
          class="operator-select mr-1"
        />
        <j-create-button
          @click="addExpression()"
          data-feature-id="add-expression"
          :label="labels.addCondition"
        />
        <j-create-button
          v-if="this.depth < this.maxDepth"
          @click="addGroup()"
          data-feature-id="add-group"
          :label="labels.addGroup"
        />
      </div>
      <j-remove-button @click="remove" class="js-end" />
    </div>
    <component
      v-for="(child, i) in children"
      :is="child.type"
      :key="child.id"
      :index="i"
      :depth="depth + 1"
      :field-prepend="fieldPrepend"
      :def="def"
      @child-deletion-requested="removeChild"
      v-model:expression="child.expression"
    />
  </Card>
</template>

<script>
import QueryBuilderExpression from './QueryBuilderExpression.vue';
import Card from '@/components/Card.vue';

export default {
  name: 'QueryBuilderGroup',
  components: {
    QueryBuilderExpression,
    Card,
  },
  props: {
    depth: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    fieldPrepend: {
      type: String,
      required: true,
    },
    def: {
      type: Object,
      required: true,
    },
    expression: {
      type: String,
      required: true,
    },
  },
  emits: ['update:expression', 'child-deletion-requested'],
  data() {
    return {
      operators: [
        {
          label: 'All of the following are true',
          value: 'AND',
        },
        {
          label: 'One or more of the following are true',
          value: 'OR',
        },
      ],
      logicalOperator: 'AND',
      maxDepth: 20,
      children: [],
      labels: {
        matchType: '',
        matchTypeAll: 'All of the following are true',
        matchTypeAny: 'One or more of the following are true',
        addCondition: 'Add Condition',
        removeAnalytic: '&times;',
        addGroup: 'Add Group',
        removeGroup: '&times;',
        textInputPlaceholder: 'value',
      },
    };
  },
  computed: {
    childCount() {
      return this.children.length;
    },
    computedExpression() {
      const expressions = this.children.map((child) => child.expression);
      const operator = ` ${this.logicalOperator} `;
      return `( ${expressions.join(operator)} )`;
    },
  },
  watch: {
    def() {
      if (!this.children) {
        this.children = [];
      }
    },
    computedExpression() {
      this.$emit('update:expression', this.computedExpression);
    },
  },
  mounted() {
    // groupExpression();
    if (this.children.length === 0) {
      this.addExpression();
    }
  },
  methods: {
    addExpression() {
      this.children.push({
        id: this.children.length,
        expression: '',
        type: 'query-builder-expression',
      });
    },
    removeChild(index) {
      this.children.splice(index, 1);
    },
    addGroup() {
      this.children.push({
        id: this.children.length,
        expression: '',
        type: 'query-builder-group',
      });
    },
    remove() {
      this.$emit('child-deletion-requested', this.index);
    },
  },
};
</script>
