<template>
  <j-tooltip :text="tooltip" open-position="right">
    <j-button
      style-type="ghost-primary"
      :aria-label="tooltip"
      class="p-0"
      data-feature-id="back-button"
      ><template #leading>
        <j-icon data="@jcon/arrow.svg" :rotate="90" height="24" /></template
    ></j-button>
  </j-tooltip>
</template>

<script>
export default {
  name: 'BackButton',
  compatConfig: {
    MODE: 3,
  },
  props: {
    tooltip: {
      type: String,
      default: 'Return to List',
    },
  },
};
</script>
