<template>
  <SplitPanel v-if="loaded">
    <template #top="{ toggle, showPanel }">
      <j-form-bar
        :can-write="true"
        :title="mode.create ? 'Create Analytic' : analytic.name"
        @submit="handleSubmit"
      >
        <template #right>
          <HelpButton
            @click="toggle()"
            :toggle-message="showPanel"
            class="js-end"
          />
        </template>
      </j-form-bar>
    </template>
    <template #default>
      <div class="form-section pt-2">
        <fieldset class="fieldset g-rows">
          <legend>Analytic Description</legend>

          <div class="g-cols max-c">
            <j-input
              v-model="internalAnalytic.name"
              id="analyticName"
              data-feature-id="name"
              label="Analytic Name"
              class="analytic-name"
              :has-error="v$.internalAnalytic.name.$error"
              :error-text="nameErrorMessage"
            />
            <div class="analytic-level">
              <j-input
                v-model="internalAnalytic.level"
                label="Level"
                placeholder="0"
                type="number"
              />
            </div>
          </div>

          <j-select
            v-model="internalAnalytic.categories"
            :options="allCategories"
            :searchable="isSearchable(allCategories)"
            deselect-from-dropdown
            allow-multi-select
            is-addable
            label="Categories"
            class="mb-1"
            data-feature-id="analytic-categories"
          />

          <j-textarea
            v-model="internalAnalytic.description"
            label="Description"
            data-feature-id="analytic-description"
          />
        </fieldset>
      </div>

      <div class="form-section">
        <fieldset class="fieldset">
          <legend>Severity</legend>
          <SeverityLevelSelect
            class="mb-1"
            v-model="internalAnalytic.severity"
          />
        </fieldset>
      </div>

      <div class="form-section">
        <fieldset class="fieldset">
          <legend>Analytic Filter</legend>
          <j-select
            v-model="internalAnalytic.inputType"
            :options="inputTypeOptions"
            :searchable="isSearchable(inputTypeOptions)"
            placeholder="Pick a Event Type"
            label="Sensor Type"
            class="mb-1"
          />
          <div v-if="qbEnabled" class="query-tabs">
            <ul>
              <li :class="{ active: qbView }">
                <a @click="qbView = !qbView" class="g-cols ai-center">
                  <span class="icon">
                    <j-icon data="@jcon/content-3.svg" />
                  </span>
                  <span>Filter Query Builder View</span>
                </a>
              </li>
              <li :class="{ active: !qbView }">
                <a @click="qbView = !qbView" class="g-cols ai-center">
                  <span class="icon">
                    <j-icon data="@jcon/code.svg" />
                  </span>
                  <span>Filter Text View</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="tab-panel">
            <QueryBuilder
              v-if="qbEnabled && qbView && eventDef"
              :def="eventDef"
              v-model:filter="qbFilter"
            />
            <div v-else class="field">
              <j-textarea
                v-model="textFilter"
                data-feature-id="analytic-filter"
                placeholder="Filter"
              />
            </div>
          </div>
        </fieldset>
      </div>

      <div class="form-section">
        <fieldset class="fieldset">
          <legend>Analytic Actions</legend>
          <ActionsSelection
            v-model:actions="internalAnalytic.analyticActions"
          />
          <j-select
            v-model="internalAnalytic.tags"
            :options="allTags"
            :searchable="isSearchable(allTags)"
            data-feature-id="tags"
            deselect-from-dropdown
            allow-multi-select
            is-addable
            label="Tags"
          />
        </fieldset>
      </div>

      <div class="form-section">
        <fieldset class="fieldset">
          <legend>Analytic Context Items</legend>
          <div class="field">
            <j-create-button
              @click="addContextItem()"
              id="addContextButton"
              data-feature-id="add-context"
              label="Add Context Item"
            />
            <div
              v-for="(item, index) in internalAnalytic.context"
              :key="item.key"
              class="context-items"
            >
              <AnalyticContextItem
                v-if="eventDef"
                :context-item="item"
                :event-def="eventDef"
                :index="index"
                :delete-method="removeContextItem"
              >
                <j-remove-button
                  @click="removeContextItem(index)"
                  class="remove"
                />
              </AnalyticContextItem>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="form-section">
        <fieldset class="fieldset">
          <legend>Snapshot Files</legend>
          <j-create-button
            @click="addSnapshotFile()"
            id="addSnapshotFile"
            data-feature-id="add-snapshot"
            label="Add Snapshot File"
          />
          <div
            v-for="file in internalAnalytic.snapshotFiles"
            :key="file.key"
            class="f-cols scale-1 snapshot-field"
          >
            <j-remove-button
              @click="removeSnapshotFile(file.key)"
              class="path-button mr-1"
            />
            <j-input
              v-model="file.value"
              data-feature-id="snapshot-file-path"
              placeholder="File Path"
            />
          </div>
        </fieldset>
      </div>
    </template>

    <template #right>
      <h5 class="h4 pt-2">Analytic Documentation</h5>
      <AnalyticHelpPanel
        v-if="eventDef"
        :def="eventDef"
        :class="{ 'hide-help': !showHelp }"
      />
    </template>
  </SplitPanel>
</template>

<script>
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import AnalyticHelpPanel from './components/AnalyticHelpPanel.vue';
import SplitPanel from '@/components/SplitPanel.vue';
import HelpButton from '@/components/HelpButton.vue';
import SeverityLevelSelect from '@/components/SeverityLevelSelect.vue';
import AnalyticContextItem from './components/AnalyticContextItem.vue';
import QueryBuilder from '@/components/analytics/editor/QueryBuilder.vue';
import ActionsSelection from './components//ActionsSelection.vue';
import { EVENT_TYPES_NAMES_DEFAULT } from '@/util/constants/event.types';
import { useForm } from '@/composables/forms';
import { useAnalytics } from './analytics';
import { mapState } from 'vuex';
import { isUniqueName } from '@/util/custom-validators';

export default {
  name: 'AnalyticForm',
  inject: ['$typeDefs'],
  components: {
    AnalyticContextItem,
    QueryBuilder,
    ActionsSelection,
    AnalyticHelpPanel,
    SplitPanel,
    HelpButton,
    SeverityLevelSelect,
  },
  props: {
    analytic: {
      type: Object,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { v$, mode, handleSubmit } = useForm();
    const { generateInternalAnalytic, uuid } = useAnalytics();
    return {
      v$,
      mode,
      handleSubmit,
      generateInternalAnalytic,
      uuid,
    };
  },
  emits: ['update:analyticEventDef'],
  data() {
    return {
      eventDef: null,
      showHelp: true,
      showLoader: false,
      loaded: false,
      internalAnalytic: null,
      qbEnabled: false,
      textFilter: '',
      qbFilter: '',
      qbView: true,
      inputTypeOptions: Object.entries(EVENT_TYPES_NAMES_DEFAULT).map(
        ([value, label]) => ({ label, value })
      ),
      allTags: [],
      allCategories: [],
    };
  },
  validations() {
    return {
      internalAnalytic: {
        name: {
          required,
          isUniqueName: isUniqueName(this.analytic?.name),
        },
      },
    };
  },
  watch: {
    // eslint-disable-next-line
    'internalAnalytic.inputType': function () {
      this.setEventDef();
    },
    filterValue() {
      this.internalAnalytic.filter = this.filterValue;
    },
  },
  computed: {
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) => state.analytics.analyticsNames,
    }),
    nameErrorMessage() {
      return this.v$.internalAnalytic.name.$errors[0]?.$message;
    },
    endpoint() {
      return `${this.admin ? 'admin' : 'primary'}/${
        this.mode.create ? 'createAnalytic' : 'updateAnalytic'
      }`;
    },
    filterValue() {
      if (this.qbEnabled && this.qbView) {
        return this.qbFilter;
      }
      return this.textFilter;
    },
    computedAnalytic() {
      const { context, snapshotFiles } = this.internalAnalytic;
      return {
        ...this.internalAnalytic,
        context: context.map((c) => _.omit(c, ['key'])),
        snapshotFiles: snapshotFiles.map(({ value }) => value),
      };
    },
  },
  async beforeMount() {
    if (this.$route.query.copy) {
      const baseAnalytic = await this.$store.dispatch('primary/getAnalytic', {
        uuid: this.$route.query.copy,
      });
      this.internalAnalytic = this.generateInternalAnalytic(
        baseAnalytic,
        false
      );
      this.internalAnalytic.name = `${this.internalAnalytic.name} Copy`;
    } else {
      this.internalAnalytic = this.generateInternalAnalytic(
        this.analytic || this.$typeDefs.configs.analytic,
        this.admin
      );
    }
    this.setEventDef();
    if (this.internalAnalytic.filter === '') {
      this.qbEnabled = true;
    } else {
      this.textFilter = this.internalAnalytic.filter;
    }
  },
  async mounted() {
    const categories = await this.$store.dispatch(
      'primary/listAnalyticsCategories'
    );
    if (categories) {
      this.allCategories = categories.map(({ value }) => value);
    }
    const tags = await this.$store.dispatch('primary/listAnalyticsTags');
    if (tags) {
      this.allTags = tags.map(({ value }) => value);
    }
    this.loaded = true;
  },
  methods: {
    addSnapshotFile() {
      this.internalAnalytic.snapshotFiles.push({
        key: this.uuid(),
        value: '',
      });
    },
    removeSnapshotFile(key) {
      const index = this.internalAnalytic.snapshotFiles.findIndex(
        (i) => i === key
      );
      this.internalAnalytic.snapshotFiles.splice(index, 1);
    },
    removeContextItem(index) {
      this.internalAnalytic.context.splice(index, 1);
    },
    addContextItem() {
      const item = {
        key: this.uuid(),
        name: '',
        type: 'String',
        exprs: [''],
      };

      this.internalAnalytic.context.push(item);
    },
    addTag(tag) {
      this.internalAnalytic.tags.push(tag);
    },
    removeTag(tag) {
      this.internalAnalytic.tags = this.internalAnalytic.tags.filter(
        (t) => t !== tag
      );
    },
    addCategory(category) {
      this.internalAnalytic.categories.push(category);
    },
    removeCategory(category) {
      this.internalAnalytic.categories =
        this.internalAnalytic.categories.filter((c) => c !== category);
    },
    setEventDef() {
      if (_.has(this.$typeDefs.events, this.internalAnalytic.inputType)) {
        this.eventDef = this.$typeDefs.events[this.internalAnalytic.inputType];
        this.$emit('update:analyticEventDef', this.eventDef);
      }
    },
    async submit() {
      const response = await this.$store.dispatch(
        this.endpoint,
        this.computedAnalytic
      );
      if (this.mode.create && response) {
        this.$router.push({
          name: 'analytics.index.detail',
          params: { id: response.uuid },
        });
      }
    },
    isSearchable(options) {
      return options.length > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.path-button {
  height: auto;
}

.analytic-level {
  height: var(--size-input-height-base);
}

.snapshot-field {
  margin-top: 1rem;
}

.remove {
  z-index: 1;
  position: absolute;
  right: spacing();
  top: spacing();
}

.analytic-name {
  max-width: 300px;
}
.query-tabs {
  @include tab-container;

  ul {
    @include tab-group-container;
  }

  li {
    @include tab;
  }
}

.tab-panel {
  @include tab-panel;
}
</style>
