<template>
  <JamfTable
    v-if="loaded"
    :get-data="getData"
    :search-params="{ columnOptions, defaultColumn: 'name' }"
    :columns="columns"
    use-page-size-options
  />
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { renameFilterKeys } from '@/components/table/utils/rename-filter-keys';
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import { columnOptionsTimeIntervals } from '@/components/table/utils/search-options.common';
import ComputerTableCellConnectionStatus from './components/ComputerTableCellConnectionStatus.vue';
import ComputersTooltipConnectionStatus from './components/ComputersTooltipConnectionStatus.vue';
import { useRBAC } from '@/composables/rbac';
import dashIcon from '@jcon/minus.svg';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { success, warning } from '@/util/constants/feedback.icons';
import { COMPUTER_CONNECTION_STATUS } from '@/util/constants/computer-status.types';
import { NTP_STATUS } from '@/util/constants/ntp-status.types';
import { macOSVersionDeprecated } from '@/util/macos-version-deprecated';
import { mapGetters, mapState } from 'vuex';

const iconWarning = { icon: warning, color: 'var(--color-warning-base)' };
const iconSuccess = { icon: success, color: 'var(--color-success-base)' };
const smallWidth = {
  minWidth: 130,
  maxWidth: 130,
  width: 130,
};
const mediumWidth = {
  minWidth: 200,
  maxWidth: 200,
  width: 200,
};
const iconColDef = (field, check) => ({
  ...smallWidth,
  field,
  tooltipField: field,
  type: ['iconColumn', 'textFilterColumn'],
  cellRendererParams: {
    setIcon: (value) => (check(value) ? iconWarning : iconSuccess),
  },
});

export default {
  name: 'Computers',
  components: {
    JamfTable,
    // eslint-disable-next-line vue/no-unused-components
    ComputerTableCellConnectionStatus,
    // eslint-disable-next-line vue/no-unused-components
    ComputersTooltipConnectionStatus,
  },
  setup() {
    const { canAccess } = useRBAC();

    return { canAccess };
  },
  data() {
    const connectionStatus = [
      COMPUTER_CONNECTION_STATUS.Disconnected,
      COMPUTER_CONNECTION_STATUS.Connected,
    ];
    return {
      loaded: false,
      endpoint: 'primary/listComputers',
      filter: this.$route.query,
      plans: [],
      planLabels: [],
      columnOptions: {
        checkin: columnOptionsTimeIntervals,
        insightsUpdated: columnOptionsTimeIntervals,
        connectionStatus,
        lastDisconnection: columnOptionsTimeIntervals,
      },
      columns: null,
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState({
      latestTPVersion: (state) => state.primary.computers.latestTPVersion,
      latestProtectVersion: (state) =>
        state.primary.computers.latestProtectVersion,
    }),
    complianceOrInsights() {
      return this.isFeatureEnabled('COMPLIANCE_DASHBOARD')
        ? 'Compliance'
        : 'Insights';
    },
    planAccess() {
      return this.canAccess([RBAC_RESOURCE.PLAN]);
    },
  },
  async beforeMount() {
    if (
      this.canAccess(RBAC_RESOURCE.DOWNLOAD) ||
      this.canAccess(RBAC_RESOURCE.THREAT_PREVENTION_VERSION)
    ) {
      await this.$store.dispatch('primary/getRelatedComputerInformation');
    }
    await this.getColumnOptions();

    this.getColumns();

    this.loaded = true;
  },
  methods: {
    macOSVersionDeprecated(osMajor, osMinor) {
      if (this.isFeatureEnabled('DEPRECATE_MACOS')) {
        return macOSVersionDeprecated(osMajor, osMinor);
      }
      return false;
    },
    getColumns() {
      this.columns = [
        {
          headerName: 'Name',
          field: 'hostName',
          colId: 'name',
          flex: 2,
          minWidth: 200,
          sort: 'asc',
          sortable: true,
          type: ['linkColumn', 'textFilterColumn'],
          cellRendererParams: {
            route: (params) => ({
              name: 'computers.index.overview',
              params: {
                id: params.data?.uuid,
              },
            }),
          },
          valueFormatter: (params) => params?.value || params?.data?.uuid,
        },
        {
          headerName: 'Serial',
          field: 'serial',
          sortable: true,
          type: 'textFilterColumn',
        },
        {
          headerName: 'macOS Version',
          field: 'macosVersion',
          tooltipField: 'osMajor',
          ...smallWidth,
          sortable: true,
          type: ['iconColumn', 'numberFilterColumn'],
          tooltipComponentParams: {
            message:
              'This macOS version is no longer supported. View the computer details for more information.',
            condition: (params) => {
              const { osMajor, osMinor } = params.data || {};
              return this.macOSVersionDeprecated(osMajor, osMinor);
            },
          },
          cellRendererParams: {
            setIcon: (_value, params) => {
              const { osMajor, osMinor, osPatch } = params.data || {};
              return {
                ...iconWarning,
                position: 'leading',
                icon: this.macOSVersionDeprecated(osMajor, osMinor)
                  ? iconWarning.icon
                  : '',
                value: params.data ? `${osMajor}.${osMinor}.${osPatch}` : null,
              };
            },
          },
        },
        {
          headerName: 'Connection Status',
          field: 'connectionStatus',
          sortable: true,
          flag: 'COMPUTER_CONNECTION_STATUS',
          cellRenderer: 'ComputerTableCellConnectionStatus',
          tooltipField: 'connectionStatus',
          tooltipComponent: 'ComputersTooltipConnectionStatus',
          type: 'valueOptionsFilterColumn',
          filterParams: {
            valueOptions: this.columnOptions.connectionStatus,
          },
        },
        {
          headerName: 'Threat Version',
          sortable: true,
          permissions: [RBAC_RESOURCE.THREAT_PREVENTION_VERSION],
          ...iconColDef(
            'signaturesVersion',
            (value) => value !== this.latestTPVersion
          ),
          type: ['iconColumn', 'numberFilterColumn'],
          colId: 'threatVersion',
          tooltipComponentParams: {
            message: (params) => {
              const { osMajor, osMinor, signaturesVersion } = params.data || {};
              if (signaturesVersion === 0) {
                return `Not supported on macOS Version ${osMajor}.${osMinor}`;
              } else if (signaturesVersion !== this.latestTPVersion) {
                return `v${params.value} is out of date`;
              }
              return `v${params.value}`;
            },
          },
          cellRendererParams: {
            setIcon: (value) => {
              if (value === 0) {
                return dashIcon;
              } else if (value === this.latestTPVersion) {
                return success;
              } else {
                return iconWarning;
              }
            },
          },
        },
        {
          headerName: 'Protect Version',
          sortable: true,
          ...iconColDef(
            'version',
            (value) => value !== this.latestProtectVersion
          ),
        },
        {
          headerName: `${this.complianceOrInsights} Failures`,
          field: 'insightsStatsFail',
          colId: 'insightFailures',
          permissions: [RBAC_RESOURCE.INSIGHT],
          sortable: true,
          ...mediumWidth,
          type: ['iconColumn', 'numberFilterColumn'],
          filterParams: {
            suppressAndOrCondition: true,
            defaultOption: FILTER_TYPES.GREATER_THAN,
          },
          cellRendererParams: {
            setIcon: (value) => {
              if (value !== null) {
                return value > 0 ? { ...iconWarning, value } : success;
              }
              return 'minus';
            },
          },
        },
        {
          headerName: 'NTP Status',
          field: 'ntpActive',
          tooltipField: 'ntpActive',
          flag: 'NTP_R1',
          type: ['iconColumn', 'valueOptionsFilterColumn'],
          sortable: true,
          cellRendererParams: {
            setIcon: (value) => {
              return value ? iconSuccess : dashIcon;
            },
          },
          tooltipComponentParams: {
            message: (value) =>
              value?.data?.ntpActive ? NTP_STATUS.Enabled : NTP_STATUS.Disabled,
          },
          filterParams: {
            valueOptions: [NTP_STATUS.Enabled, NTP_STATUS.Disabled],
          },
        },
        {
          headerName: 'Plan',
          field: 'plan',
          permissions: [RBAC_RESOURCE.PLAN],
          tooltipField: 'plan',
          sortable: true,
          type: ['iconColumn', 'valueOptionsFilterColumn'],
          flex: 1,
          maxWidth: null,
          tooltipComponentParams: {
            message:
              'Computer Plan is outdated. Plan update will deploy on next check-in.',
            condition: (params) =>
              params.data?.configHash !== params.data?.plan?.hash,
          },
          cellRendererParams: {
            setIcon: (value, params) => ({
              ...iconWarning,
              position: 'trailing',
              isSmall: true,
              icon:
                params.data?.configHash !== value?.hash && value?.name
                  ? iconWarning.icon
                  : '',
              value: value?.name || 'None',
            }),
            route: (params) =>
              params.data?.plan?.id
                ? {
                    name: 'plans.index.detail',
                    params: { id: params.data.plan.id },
                  }
                : null,
          },
          filterParams: {
            filterType: 'array',
            valueOptions: this.planLabels,
            isSearchable: true,
          },
        },
        {
          headerName: 'Last Check-in',
          field: 'checkin',
          sortable: true,
          type: 'dateFilterColumn',
          flagOff: 'COMPUTER_CONNECTION_STATUS',
        },
        {
          headerName: `${this.complianceOrInsights} Updated`,
          sortable: true,
          ...mediumWidth,
          field: 'insightsUpdated',
          permissions: [RBAC_RESOURCE.INSIGHT],
          type: 'dateFilterColumn',
        },
        {
          headerName: 'Label',
          field: 'label',
          sortable: true,
          type: 'textFilterColumn',
        },
        {
          headerName: 'Tags',
          field: 'tags',
          type: ['arrayFilterColumn', 'tagsColumn'],
        },
        { field: 'modelName', type: ['hiddenColumn', 'textFilterColumn'] },
        {
          field: 'lastDisconnection',
          flag: 'COMPUTER_CONNECTION_STATUS',
          filterParams: {
            defaultOption: FILTER_TYPES.BEFORE_INTERVAL,
          },
          type: ['hiddenColumn', 'dateFilterColumn'],
        },
      ];
    },
    async getColumnOptions() {
      if (this.planAccess) {
        await this.getPlanInfo();
      }
      this.columnOptions.plan = this.planLabels;

      const labels = await this.$store.dispatch(
        'primary/listComputersStatsFilterOptions',
        {
          useSignatures: this.canAccess(
            RBAC_RESOURCE.THREAT_PREVENTION_VERSION
          ),
        }
      );
      if (labels) {
        this.columnOptions = { ...this.columnOptions, ...labels };
      }
      return labels;
    },
    async getData(params) {
      const { field, filter } = await this.customColumnFields(params);

      return this.$store.dispatch(this.endpoint, { ...params, field, filter });
    },
    // Check for custom colId's and match them to actual api names in filters
    async customColumnFields(params) {
      let { filter, field } = params;
      const toRename = {
        name: 'hostName',
        threatVersion: 'signaturesVersion',
        insightFailures: 'insightsStatsFail',
        macosVersion: (value) => {
          const [type] = Object.keys(value);
          const [osMajor, osMinor, osPatch] =
            Object.values(value)[0].split('.');
          const newObj = { osMajor: { [type]: osMajor } };
          if (osMinor) {
            newObj.osMinor = { [type]: osMinor };
          }
          if (osPatch) {
            newObj.osPatch = { [type]: osPatch };
          }
          return newObj;
        },
      };

      Object.entries(toRename).forEach(([key, value]) => {
        if (key === 'macosVersion') {
          if (field === key) field = ['osMajor', 'osMinor', 'osPatch'];
          filter = renameFilterKeys(filter, key, null, value);
        } else {
          if (field === key) field = value;
          filter = renameFilterKeys(filter, key, value);
        }
      });
      return { field, filter };
    },
    async getPlanInfo() {
      const plans = await this.$store.dispatch('primary/listAllPlans', {
        direction: 'DESC',
        field: 'created',
      });

      this.planLabels = plans?.map(({ name }) => name).sort();
    },
  },
};
</script>
