<template>
  <div class="table-pagination">
    <j-button
      v-if="hasPageButtons"
      :is-disabled="currentPage === 1"
      style-type="ghost-primary"
      class="previous p-0"
      @click="$emit('previous')"
    >
      <template #leading>
        <j-icon class="arrow-left" data="@jcon/arrow.svg" :rotate="90" />
      </template>
    </j-button>
    <div v-if="!loading" class="rows-count">
      <span v-if="rowCount === 0">0 of 0</span>
      <span v-else>{{ count }}</span>
    </div>
    <div v-else><gp-loader /></div>
    <j-button
      v-if="hasPageButtons"
      :is-disabled="currentPage === lastPage"
      class="next p-0"
      style-type="ghost-primary"
      @click="$emit('next')"
    >
      <template #leading>
        <j-icon class="arrow-right" data="@jcon/arrow.svg" :rotate="270" />
      </template>
    </j-button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    total: Number,
    rowCount: Number,
    lastPage: Number,
    loading: Boolean,
    suppressPageButtons: Boolean,
  },
  emits: ['previous', 'next'],
  computed: {
    count() {
      const current = (this.currentPage - 1) * parseInt(this.pageSize, 10);
      const total = this.total && this.lastPage ? ` of ${this.total}` : '';
      const size =
        this.currentPage === this.lastPage
          ? this.rowCount
          : this.currentPage * parseInt(this.pageSize, 10);
      return this.pageSize === 1
        ? `${size} of ${this.rowCount}`
        : `${current + 1} - ${size}${total}`;
    },
    hasPageButtons() {
      return (
        !this.suppressPageButtons &&
        !this.loading &&
        this.lastPage !== 1 &&
        this.lastPage !== this.pageSize &&
        this.rowCount !== 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table-pagination {
  @include grid;
  @include grid-columns;
  align-items: center;

  a {
    color: var(--color-font-base);
    padding: 0 0.25rem;
  }
  .rows-count {
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin-top: -2px;
      margin-left: 4px;
    }
  }
}
</style>
