<template>
  <Card class="insight-card">
    <div class="header">
      <InsightLabel
        :route="route"
        :label="label"
        :tags="tags"
        :description="description"
      />
      <j-switch v-if="callback" v-model="enabled" @input="updateEnabled" />
    </div>
    <InsightGraph
      :pass="pass"
      :fail="fail"
      :none="none"
      :total="total"
      :enabled="enabled"
    />
  </Card>
</template>

<script>
import Card from '@/components/Card';
import InsightGraph from './InsightGraph';
import InsightLabel from './InsightLabel';

export default {
  name: 'InsightCard',
  components: { Card, InsightGraph, InsightLabel },
  props: {
    label: String,
    description: String,
    tags: Array,
    route: Object,
    pass: Number,
    fail: Number,
    none: Number,
    total: Number,
    isEnabled: Boolean,
    callback: Function,
  },
  data() {
    return {
      enabled: this.isEnabled,
    };
  },
  watch: {
    isEnabled(newValue) {
      this.enabled = newValue;
    },
  },
  methods: {
    updateEnabled(value) {
      this.callback(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.insight-card {
  @include grid;
  min-height: 120px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .j-switch {
    --size-input-height-secondary: 16px;
    --color-input-base: var(--color-card-primary);

    @include switch;
    display: block;
    margin: 0;
    padding: 0;
    .indicator {
      margin: 0;
    }
  }
}
</style>
