import gql from 'graphql-tag';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export const USBControlSetFields = (getPermissions) => {
  const hasPlan = getPermissions([RBAC_RESOURCE.PLAN]);
  const plan = `plans {
    id
    name
  }`;

  return gql`
  fragment USBControlSetFields on USBControlSet {
    id
    name
    description
    defaultMountAction
    defaultMessageAction
    rules {
      mountAction
      messageAction
      type
      ... on VendorRule {
        vendors
        applyTo
      }
      ... on SerialRule {
        serials
        applyTo
      }
      ... on ProductRule {
        products {
          vendor
          product
        }
        applyTo
      }
    }
    ${hasPlan ? plan : ''}
    created
    updated
  }
`;
};
