<template>
  <div class="g-col-2">
    <ListItem title="Date">
      {{ formatEpochDate(data.date) }}
    </ListItem>
    <ListItem title="User" class="col-span-1">
      {{ getUser }}
    </ListItem>
    <ListItem title="Operation">
      {{ data.op }}
    </ListItem>
    <ListItem title="Resource ID">
      {{ getResourceId }}
    </ListItem>
    <div class="col-span-1 g-rows">
      <hr class="m-0" />
      <ListItem v-if="data?.ips" title="IP's">
        <div class="tags">
          <j-tag v-for="ip in getIPs" :key="ip">
            {{ ip }}
          </j-tag>
        </div>
      </ListItem>
      <ListItem title="Arguments">
        <div class="code">
          <pre><code>{{ getArgs }}</code></pre>
        </div>
      </ListItem>
      <ListItem v-if="data.error" title="Error">
        <div class="code">
          <pre><code>{{ data.error }}</code></pre>
        </div>
      </ListItem>
    </div>
  </div>
</template>

<script>
import ListItem from '@/components/ListItem';
import { useGmt } from '@/composables/gmt';
import { prettifyJson } from '@/util';

export default {
  props: ['data'],
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  components: {
    ListItem,
  },
  computed: {
    getUser() {
      return this.data.user.split('#')[0];
    },
    getIPs() {
      return this.data.ips.split(',');
    },
    getArgs() {
      return prettifyJson(this.data.args);
    },
    getResourceId() {
      if (!this.data.resourceId) {
        return 'N/A';
      }
      return this.data.resourceId;
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  display: grid;
  max-height: 400px;
  overflow: auto;
}
</style>
