import { useStore } from 'vuex';
import { computed } from 'vue';
import {
  formatEpochDate as format,
  convertToISODate as convert,
} from '@/util/time-helpers';

export function useGmt() {
  const store = useStore();
  const userDateFormat = computed(() => store.state.user.dateFormat);

  const formatEpochDate = (value) => {
    return format(
      value,
      store.state.user.dateFormat,
      store.state.user.gmt,
      store.state.user.analog
    );
  };

  const getDate = (value) => {
    const [date] = format(value, store.state.user.dateFormat, true).split(' ');
    return date;
  };

  const convertToISODate = (value) => {
    return convert(value, store.state.user.dateFormat, true);
  };

  return {
    formatEpochDate,
    getDate,
    convertToISODate,
    userDateFormat,
  };
}
