import LIST_API_CLIENTS from './queries/listApiClients.gql';
import CREATE_API_CLIENT from './mutations/createApiClient.gql';
import UPDATE_API_CLIENT from './mutations/updateApiClient.gql';
import DELETE_API_CLIENT from './mutations/deleteApiClient.gql';
import GET_API_CLIENT from './queries/getApiClient.gql';
import GET_API_CLIENT_ALTAIR_CONFIG from './queries/getApiClientAltairConfig.gql';

export default {
  state: {
    apiClients: [],
    apiClient: null,
    apiClientsNames: [],
  },
  mutations: {
    SET_API_CLIENT(state, payload) {
      state.apiClient = payload;
    },
    SET_API_CLIENTS(state, payload) {
      state.apiClients = payload.items;
      state.apiClientsNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    async createApiClient({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_API_CLIENT,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        // Don't record password in store
        const resultCopy = { ...result.data.createApiClient };
        resultCopy.password = '*****';
        commit('SET_API_CLIENT', resultCopy);
        return result.data.createApiClient;
      }
      return result;
    },
    async updateApiClient({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_API_CLIENT,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_API_CLIENT', result.data.updateApiClient);
        return result.data.updateApiClient;
      }
      return result;
    },
    async getApiClient({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_API_CLIENT,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_API_CLIENT', result.data.getApiClient);
        return result.data.getApiClient;
      }
      return result;
    },
    async getApiClientAltairConfig({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        { query: GET_API_CLIENT_ALTAIR_CONFIG },
        { root: true }
      );
      if (result) {
        return result.data.getApiClientAltairConfig;
      }
      return result;
    },
    async deleteApiClient({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_API_CLIENT,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_API_CLIENT', result.data.deleteApiClient);
        return result.data.deleteApiClient;
      }
      return result;
    },
    async listApiClients({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_API_CLIENTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_API_CLIENTS', result.data.listApiClients);
        return result.data.listApiClients;
      }
      return result;
    },
  },
};
