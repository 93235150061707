<template>
  <span class="clock">
    {{ formatEpochDate(time) }}
  </span>
</template>

<script>
import { useGmt } from '@/composables/gmt';

export default {
  name: 'Clock',
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  data() {
    return {
      time: new Date(),
    };
  },
  mounted() {
    this.$options.interval = setInterval(this.updateDateTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.$options.interval);
  },
  methods: {
    updateDateTime() {
      this.time = new Date();
    },
  },
};
</script>
