<template>
  <div class="gp-content" v-if="!loading">
    <j-form-bar
      :can-write="canWrite"
      :title="mode.create ? 'Create Removable Storage Control Set' : name"
      @back="$router.push({ name: 'devicecontrols.index' })"
      @submit="handleSubmit"
      @remove="isConfirmDeleteActive = true"
    />
    <j-delete-modal
      v-if="canWrite && !mode.create"
      v-model="isConfirmDeleteActive"
      :to-delete="name"
      :blockers="hasPlans"
      title="Delete Removable Storage Set"
      @confirm="remove()"
    />
    <div class="gp-content device-controls">
      <div class="g-col-2 p-2">
        <div class="g-rows">
          <j-input
            label="Name"
            :is-read-only="isReadOnly"
            v-model="usbControlSet.name"
            data-feature-id="name"
            :has-error="v$.usbControlSet.name.$error"
            :error-text="nameErrorMessage"
          />
          <j-textarea
            label="Description"
            :rows="3"
            data-feature-id="description"
            :is-read-only="isReadOnly"
            v-model="usbControlSet.description"
          />
        </div>
        <DeviceRules
          is-default
          :is-read-only="isReadOnly"
          v-model:mount-action="usbControlSet.defaultMountAction"
          v-model:message-action="usbControlSet.defaultMessageAction"
        />
      </div>

      <hr class="mt-0 mb-0 ml-2 mr-2" />

      <SplitPanelList
        v-model:items="usbGroups"
        :is-read-only="isReadOnly"
        @remove="removeGroup"
        @select="selectGroup"
        @add="addGroup"
      >
        <template #add-modal>
          <j-select
            v-model="type"
            label="Removable Storage Override Type"
            helper-text="An identifier used to target removable storage devices and override default settings"
            data-feature-id="type"
            :options="typeOptions"
          />
          <CollapseCard
            :is-small="true"
            title="Removable Storage Override Type Details"
            class="usb-all-types"
          >
            <div class="scroll-vertical">
              <USBControlsDocumentation
                v-for="(_, key) in USBCONTROL_TYPES_NAMES"
                :key="key"
                :type="key"
                :is-small="true"
              />
            </div>
          </CollapseCard>
        </template>
        <template #title> Total Overrides ({{ rules.length }}) </template>
        <template #item-title="{ item, isMobile }">
          <template v-if="isMobile && item">{{ getGroupName(item) }}</template>
          <div v-else-if="!isMobile && item" class="f-wrap">
            <j-input-group label="Type" class="gap-none">
              {{ USBCONTROL_TYPES_NAMES[item.type] }}
            </j-input-group>
            <j-input-group label="Permission" class="gap-none">
              {{ mountActionName(item.mountAction) }}
            </j-input-group>
            <j-input-group
              v-if="item.applyTo"
              label="Apply to"
              class="gap-none"
            >
              {{ item.applyTo }}
            </j-input-group>
          </div>
        </template>
        <template #item="{ item, errorCount, isMobile }">
          <template v-if="!isEncryptionType(item.type) && !isMobile">
            <hr />
            <div class="g-cols max-c ai-center">
              {{ item.values.length }} added
              {{ USBCONTROL_TYPES_NAMES[item.type] }}s
              <transition name="fade" mode="in-out">
                <div class="g-cols max-c ai-center gap-1" v-if="errorCount">
                  <j-icon
                    data="@jcon/error.svg"
                    color="var(--color-danger-base)"
                  />
                  <p>{{ errorCount }} Error(s)</p>
                </div>
              </transition>
            </div>
          </template>
          <template v-else-if="isMobile">
            {{ getGroupName(item) }}
          </template>
        </template>
        <template #selection="{ item, isMobile }">
          <h1 v-if="!isMobile" class="mb-1 mt-1 h4">
            {{ USBCONTROL_TYPES_NAMES[item.type] }} Override Details
          </h1>
          <DeviceRules
            :is-read-only="isReadOnly"
            v-model:mount-action="item.mountAction"
            v-model:message-action="item.messageAction"
            v-model:apply-to="item.applyTo"
            :is-encryption="isEncryptionType(item.type)"
          />
          <DeviceRulesTable
            v-if="item && !isEncryptionType(item.type)"
            v-model:values="item.values"
            :can-write="canWrite"
            :type="item.type"
          />
        </template>
      </SplitPanelList>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import { mapGetters, mapState } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import {
  USBCONTROL_TYPES,
  USBCONTROL_MOUNT_ACTIONS,
  USBCONTROL_ENCRYPTION,
  USBCONTROL_TYPES_NAMES,
  USBCONTROL_MOUNT_ACTIONS_NAMES,
  USBCONTROL_ACTION_MESSAGE,
} from './usb-controls.types';
import SplitPanelList from '@/components/SplitPanelList.vue';
import USBControlsDocumentation from './components/USBControlsDocumentation.vue';
import DeviceRules from './components/DeviceRules.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import { useForm } from '@/composables/forms';
import { useRBAC } from '@/composables/rbac';
import { uuid } from '@/util';
import { isUniqueName } from '@/util/custom-validators';
import DeviceRulesTable from './components/DeviceRulesTable.vue';

export default {
  name: 'USBControlsForm',
  components: {
    SplitPanelList,
    USBControlsDocumentation,
    DeviceRules,
    CollapseCard,
    DeviceRulesTable,
  },
  setup() {
    const { v$, mode, handleSubmit } = useForm();
    const { canWrite, isReadOnly } = useRBAC([RBAC_RESOURCE.USB_CONTROLS]);
    return { v$, mode, canWrite, isReadOnly, handleSubmit };
  },
  data() {
    return {
      endpoints: {
        delete: 'primary/deleteUSBControlSet',
        single: 'primary/getUSBControlSet',
        create: 'primary/createUSBControlSet',
        update: 'primary/updateUSBControlSet',
      },
      loading: true,
      typeOptions: Object.entries(USBCONTROL_TYPES_NAMES).map(
        ([value, label]) => ({
          label,
          value,
        })
      ),
      type: USBCONTROL_TYPES.Encryption,
      applyTo: USBCONTROL_ENCRYPTION.All,
      isConfirmDeleteActive: false,
      USBCONTROL_TYPES_NAMES,
      usbControlSet: {
        name: '',
        description: '',
        defaultMountAction: USBCONTROL_MOUNT_ACTIONS.Prevented,
        defaultMessageAction: USBCONTROL_ACTION_MESSAGE.Prevented,
        rules: [],
      },
      usbGroups: {},
      name: '',
      selectedKey: '',
    };
  },
  validations() {
    return {
      usbControlSet: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
      },
    };
  },
  async mounted() {
    if (!this.mode.create) {
      const result = await this.$store.dispatch(this.endpoints.single, {
        id: this.$route.params.id,
      });

      this.setRules(result?.rules);
      this.usbControlSet = { ...result };
      this.name = result.name;
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) => state.usbcontrols.usbcontrolsNames,
      currentName: (state) => state.usbcontrols.usbcontrol?.name,
    }),
    hasPlans() {
      return this.usbControlSet?.plans?.map(({ name, id }) => ({
        value: name,
        route: { name: 'plans.index.detail', params: { id } },
      }));
    },
    rules() {
      return Object.values(this.usbGroups);
    },
    nameErrorMessage() {
      return this.v$.usbControlSet.name.$errors[0]?.$message;
    },
    payload() {
      const rules = this.rules.map(
        ({ type, messageAction, mountAction, applyTo, values }) => {
          const typeRules = {
            [USBCONTROL_TYPES.Encryption]: {},
            [USBCONTROL_TYPES.Vendor]: {
              applyTo,
              vendors: values,
            },
            [USBCONTROL_TYPES.Product]: {
              applyTo,
              products: values,
            },
            [USBCONTROL_TYPES.Serial]: {
              applyTo,
              serials: values,
            },
          };

          return {
            type,
            [`${type.toLowerCase()}Rule`]: {
              messageAction:
                mountAction === USBCONTROL_MOUNT_ACTIONS.ReadWrite
                  ? null
                  : messageAction,
              mountAction,
              ...typeRules[type],
            },
          };
        }
      );

      return { ...this.usbControlSet, rules };
    },
  },
  methods: {
    mountActionName(value) {
      return USBCONTROL_MOUNT_ACTIONS_NAMES[value];
    },
    getGroupName(rule) {
      if (rule) {
        const { type, applyTo, mountAction } = rule;
        const action = this.mountActionName(mountAction);
        return type === USBCONTROL_TYPES.Encryption
          ? `${type} with ${action}`
          : `${type} with ${action} that apply to ${applyTo}`;
      }
      return null;
    },
    setRules(rules) {
      this.usbGroups = {};
      rules?.forEach((rule) => {
        this.usbGroups[uuid()] = {
          ...rule,
          values: rule.vendors || rule.serials || rule.products,
        };
      });
    },
    selectGroup(key) {
      this.selectedKey = key;
    },
    buildRule() {
      return {
        type: this.type,
        messageAction: USBCONTROL_ACTION_MESSAGE.ReadOnly,
        mountAction: USBCONTROL_MOUNT_ACTIONS.ReadOnly,
        ...(!this.isEncryptionType(this.type)
          ? {
              values: [],
              applyTo: USBCONTROL_ENCRYPTION.All,
            }
          : {}),
      };
    },
    addGroup() {
      this.selectedKey = uuid();
      const rule = this.buildRule();
      this.usbGroups[this.selectedKey] = rule;
    },
    removeGroup(key) {
      delete this.usbGroups[key];
    },
    isEncryptionType(type) {
      return type === USBCONTROL_TYPES.Encryption;
    },
    async submit() {
      const result = await this.$store.dispatch(
        this.mode.create ? this.endpoints.create : this.endpoints.update,
        this.payload
      );
      if (result) {
        if (this.mode.create) {
          this.$router.push({
            name: 'devicecontrols.index.detail',
            params: { id: result.id },
          });
        }
        this.usbControlSet = { ...result };
        this.name = result.name;
      }
      this.$store.commit('TRIGGER_TABLE_CELL_REFRESH', true);
    },
    async remove() {
      this.showLoader = true;
      const result = await this.$store.dispatch(this.endpoints.delete, {
        id: this.usbControlSet.id,
      });
      if (result) {
        this.$router.push({ name: 'devicecontrols.index' });
      }
      this.showLoader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-mobile {
  --size-action-height-base: 32px !important;
}

@include breakpoint(small down) {
  .device-controls {
    @include scroll-vertical;
  }
}

.usb-all-types {
  overflow: clip;
  max-height: 46vh;
  :deep(.collapse-content) {
    height: 100%;
  }
}
</style>
