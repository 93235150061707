<template>
  <Card>
    <h1>Compliance Rules</h1>
    <JamfTableClientSide
      v-if="loaded"
      :data="complianceData"
      :columns="columns"
      @refresh="refresh"
      use-page-size-options
    />
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import JamfTableClientSide from '@/components/table/JamfTableClientSide.vue';
import {
  success,
  questionMark,
  warning,
} from '@/util/constants/feedback.icons';
import dashIcon from '@jcon/minus.svg';

const iconWarning = { icon: warning, color: 'var(--color-warning-base)' };
const iconSuccess = { icon: success, color: 'var(--color-success-base)' };
const iconDash = { icon: dashIcon, color: 'var(--color-font-base)' };

export default {
  name: 'ComplianceRulesTable',
  components: {
    JamfTableClientSide,
    Card,
  },
  data() {
    return {
      loaded: false,
      endpoint: 'primary/listCompliance',
      complianceRulesFailed: 0,
      complianceRulesPassed: 0,
      complianceRulesUnknown: 0,
      columns: [],
      complianceData: [],
    };
  },
  async beforeMount() {
    this.columns = [
      {
        headerName: 'Name',
        field: 'label',
        sort: 'asc',
        sortable: true,
        flex: 1,
        type: 'linkColumn',
        minWidth: 250,
        cellRendererParams: {
          route: (params) => ({
            name: 'compliance.index.detail',
            params: { id: params.data?.uuid },
          }),
        },
        valueFormatter: (params) => params?.value || params?.data?.uuid,
      },
      {
        headerName: 'Category',
        field: 'section',
        sortable: true,
        minWidth: 150,
      },
      {
        headerName: 'Computers',
        field: 'total',
        minWidth: 100,
        sortable: true,
      },
      {
        headerName: 'Pass',
        field: 'totalPass',
        minWidth: 100,
        sortable: true,
        type: ['iconColumn'],
        cellRendererParams: {
          setIcon: (value) => {
            if (value > 0) {
              return { ...iconSuccess, value };
            }
            return iconDash;
          },
        },
      },
      {
        headerName: `Fail`,
        field: 'totalFail',
        minWidth: 100,
        sortable: true,
        type: ['iconColumn'],
        cellRendererParams: {
          setIcon: (value) => {
            if (value > 0) {
              return { ...iconWarning, value };
            }
            return iconDash;
          },
        },
      },
      {
        headerName: 'Unknown',
        field: 'totalNone',
        minWidth: 100,
        sortable: true,
        type: ['iconColumn'],
        cellRendererParams: {
          setIcon: (value) => {
            if (value > 0) {
              return { ...questionMark, value };
            }
            return iconDash;
          },
        },
      },
    ];

    await this.refresh();
    this.loaded = true;
  },
  methods: {
    async refresh() {
      this.complianceData = await this.$store.dispatch(this.endpoint);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  @include header4;
  align-self: flex-end;

  &:first-of-type {
    padding-top: 0;
  }
}

.j-table-wrapper {
  --color-table-base: var(--color-card-primary);
  --color-table-border: var(--color-table-border-secondary);
  --table-height: var(--table-wrapper-height);
  --table-nav-height: 0px;
  --size-global-banner-height: 0px;
  overflow: hidden;
  height: 100%;
  border-top: 1px solid var(--color-table-border);
}
</style>
