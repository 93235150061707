import PlanSection from './PlanSection.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Plan from '@/modules/plans/Plan.vue';
import PlanDetails from '@/modules/plans/tabs/PlanDetails.vue';
import PlanForm from '@/modules/plans/PlanForm.vue';
import Plans from '@/modules/plans/Plans.vue';
import exceptionRoutes from '@/modules/plans/exception-sets/exception-sets.routes';
import Section from '@/components/Section.vue';

export default [
  {
    path: '/plans',
    component: PlanSection,
    name: 'plans',
    meta: {
      breadcrumb: {
        linkName: 'plans.index',
        linkText: 'Plans',
      },
      permissions: [RBAC_RESOURCE.PLAN, RBAC_RESOURCE.EXCEPTION_SET],
      requireOnlySome: true,
    },
    children: [
      {
        path: '',
        component: Section,
        name: 'plans.index.section',
        meta: {
          permissions: [RBAC_RESOURCE.PLAN],
        },
        children: [
          {
            path: '',
            component: Plans,
            name: 'plans.index',
          },
          {
            path: '',
            component: Plan,
            name: 'plans.create',
            children: [
              {
                path: 'create',
                component: PlanForm,
                name: 'plans.index.create',
                meta: {
                  breadcrumb: {
                    linkText: 'Create',
                  },
                  requireNested: {
                    [RBAC_RESOURCE.PLAN]: [RBAC_RESOURCE.ACTION_CONFIGS],
                  },
                  requireWrite: true,
                },
              },
            ],
          },
          {
            path: 'plan',
            component: Section,
            name: 'plan',
            children: [
              {
                path: ':id',
                component: Plan,
                meta: {
                  breadcrumb: {
                    linkName: 'plans.index.detail',
                    linkText: 'Plan',
                  },
                },
                children: [
                  {
                    path: '',
                    name: 'plans.index.detail',
                    component: PlanDetails,
                  },
                  {
                    path: 'edit',
                    name: 'plans.index.edit',
                    component: PlanForm,
                    props: true,
                    meta: {
                      requireNested: {
                        [RBAC_RESOURCE.PLAN]: [RBAC_RESOURCE.ACTION_CONFIGS],
                      },
                      requireWrite: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      exceptionRoutes,
    ],
  },
];
