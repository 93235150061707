<template>
  <div class="gp-content" v-if="!loading">
    <div class="editor scroll-vertical">
      <div class="g-rows p-2">
        <j-input
          v-model="internalPlan.name"
          id="planName"
          data-feature-id="name"
          label="Name"
          class="pad-bottom"
          :has-error="v$.internalPlan.name.$error"
          :error-text="
            v$.internalPlan.name.isUniqueName.$invalid
              ? 'A plan with this name already exists'
              : 'Name is required'
          "
        />
        <j-textarea
          v-model="internalPlan.description"
          label="Description"
          data-feature-id="plan-description"
        />
        <j-select
          v-if="configSetOptions.actionConfigs"
          v-model="actionConfigs"
          :options="configSetOptions.actionConfigs"
          :searchable="isSearchable(configSetOptions.actionConfigs)"
          data-feature-id="actionConfigs"
          :has-error="v$.actionConfigs.$error"
          :config="{ clearable: false }"
          error-text="Action is required"
          label="Action Configuration"
        />
      </div>
      <template
        v-for="option in configSetOptions.managedAnalyticSets"
        :key="option.value"
      >
        <!-- This feature is enabled only be uuid and is important for releasing managed analytic sets  -->
        <div class="form-section" v-if="isFeatureEnabled(option.value)">
          <fieldset class="fieldset">
            <legend class="sr-only">{{ option.label }}</legend>
            <j-checkbox
              v-model="managedAnalyticSets"
              :native-value="option.value"
              :data-feature-id="option.value"
            >
              <div class="g-rows gap-0">
                <span>{{ option.label }}</span>
                <span class="helper-text">{{ option.description }}</span>
              </div>
            </j-checkbox>
          </fieldset>
        </div>
      </template>
      <div class="form-section">
        <fieldset class="fieldset">
          <legend>Advanced Agent Configuration</legend>
          <div class="item" v-if="cloudOnlyAccess">
            <span class="label">Agent Update:</span>
            <j-checkbox
              v-model="internalPlan.autoUpdate"
              data-feature-id="autoUpdate"
            >
              Enable Auto Update
            </j-checkbox>
          </div>
          <j-select
            v-if="cloudOnlyAccess"
            v-model="commsConfig"
            :options="commsConfigOption"
            :searchable="false"
            label="Communications Protocol"
            :helper-text="
              !isFeatureEnabled('DEPRECATE_MACOS')
                ? 'Default option uses MQTT:8883 on macOS versions prior to 10.13.4'
                : ''
            "
          />
          <j-toggle-group
            v-if="cloudOnlyAccess"
            label="Endpoint Threat Prevention Options:"
            data-feature-id="threatPreventionConfigs"
            is-vertical
          >
            <j-radio
              v-model="threatPreventionConfigs"
              native-value="blocking"
              :name="toggleName"
            >
              Block & Report
            </j-radio>
            <j-radio
              v-model="threatPreventionConfigs"
              native-value="reportOnly"
              :name="toggleName"
            >
              Report Only
            </j-radio>
            <j-radio
              v-model="threatPreventionConfigs"
              native-value="disabled"
              :name="toggleName"
            >
              Disable
            </j-radio>
          </j-toggle-group>
          <j-select
            v-if="configSetOptions.analyticSets"
            v-model="analyticSets"
            label="Analytic Sets"
            helper-text="Groups of analytics that are included for triggering alerts."
            placeholder="Select Sets"
            :options="configSetOptions.analyticSets"
            :searchable="isSearchable(configSetOptions.analyticSets)"
            data-feature-id="analyticSets"
            allow-multi-select
            deselect-from-dropdown
          />
          <j-select
            v-model="internalPlan.logLevel"
            :options="logLevelOptions"
            :searchable="false"
            data-feature-id="logLevel"
            placeholder="Not Set"
            label="Log Level"
          />
          <j-select
            v-if="configSetOptions.exceptionSets"
            v-model="exceptionSets"
            helper-text="A list of directories, identifiers, or executables that are excluded from triggering alerts."
            :options="configSetOptions.exceptionSets"
            :searchable="isSearchable(configSetOptions.exceptionSets)"
            data-feature-id="exceptionSets"
            allow-multi-select
            deselect-from-dropdown
            :has-error="v$.exceptionSets.$error"
            error-text="Exceeds Set limit of 20"
            placeholder="Select Sets"
            label="Exception Sets"
          />
          <j-select
            v-if="cloudOnlyAccess && configSetOptions.usbControlSets"
            v-model="usbControlSet"
            helper-text="A list of removable storage actions with overrides for encryption, vendors, products, or serial numbers."
            :options="configSetOptions.usbControlSets"
            :searchable="isSearchable(configSetOptions.usbControlSets)"
            data-feature-id="usbControlSets"
            class="none-option"
            label="Removable Storage Control Set"
          />
          <j-select
            v-if="
              isFeatureEnabled('TELEMETRY_STREAM') &&
              configSetOptions.telemetries
            "
            v-model="telemetry"
            :options="configSetOptions.telemetries"
            :searchable="isSearchable(configSetOptions.telemetries)"
            class="none-option"
            data-feature-id="telemetryOptions"
            label="Telemetry"
          />
        </fieldset>
      </div>

      <div class="form-section" v-if="cloudOnlyAccess">
        <fieldset class="fieldset">
          <legend>Endpoint Information Collection</legend>
          <div class="g-rows">
            <p class="h5">Computer Check-in Information:</p>
            <div class="enpoint-wrap">
              <j-checkbox
                v-for="attr in endpointAttrsOptions"
                :key="attr.label"
                v-model="endpointAttrs"
                :native-value="attr.value"
              >
                {{ attr.label }}
              </j-checkbox>
            </div>
          </div>
          <j-input
            v-if="!isFeatureEnabled('COMPUTER_CONNECTION_STATUS')"
            v-model.number="internalPlan.infoSync.infoSyncInterval"
            :has-error="v$.internalPlan.infoSync.infoSyncInterval.$error"
            error-text="Must be between 5 and 1440 minutes"
            data-feature-id="infoSyncInterval"
            label="Sync Interval (minutes)"
            type="number"
          />
        </fieldset>
      </div>
      <div class="form-section" v-if="cloudOnlyAccess">
        <fieldset class="fieldset">
          <legend>{{ insightsOrComplianceMessage }}</legend>

          <j-checkbox
            v-model="endpointAttrs"
            data-feature-id="collectInsights"
            native-value="insights"
          >
            Enable {{ insightsOrComplianceMessage }} Collection
          </j-checkbox>

          <j-input
            v-model.number="internalPlan.infoSync.insightsSyncInterval"
            :label="`${insightsOrComplianceMessage} Collection Interval (minutes)`"
            class="ml-4"
            data-feature-id="insightsSyncInterval"
            :is-disabled="!collectInsights"
            :has-error="
              v$.internalPlan.infoSync.insightsSyncInterval.$error &&
              collectInsights
            "
            error-text="Must be between 5 and 1440 minutes"
            type="number"
          />
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import { required, between, maxLength } from '@vuelidate/validators';
import { secondsToMinutes, minutesToSeconds, capitalize } from '@/util';
import { mapGetters, mapState } from 'vuex';
import { isUniqueName } from '@/util/custom-validators';
import { useForm } from '@/composables/forms';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'PlanForm',
  props: {
    plan: {
      type: Object,
    },
  },
  inject: ['$typeDefs'],
  setup() {
    const { v$, mode } = useForm();
    const { cloudOnlyAccess } = useRBAC();
    return { v$, cloudOnlyAccess, mode };
  },
  data() {
    const noneOption = { label: 'None', value: null };
    return {
      toggleName: 'threat-prevention-configs',
      noneOption,
      interval: { min: 5, max: 1440 },
      endpointAttrsOptions: this.$typeDefs.endpoint,
      endpointAttrs: [
        ...this.$typeDefs.endpoint.map(({ value }) => value),
        'insights',
      ],
      internalPlan: {
        ...this.plan,
        logLevel: this.plan.logLevel ? capitalize(this.plan.logLevel) : null,
        infoSync: {
          infoSyncInterval: secondsToMinutes(
            this.plan.infoSync?.infoSyncInterval
          ),
          insightsSyncInterval: secondsToMinutes(
            this.plan.infoSync?.insightsSyncInterval
          ),
          attrs: this.plan.infoSync?.attrs || [],
        },
      },
      name: this.plan.name,
      configSetOptions: {
        exceptionSets: null,
        telemetries: null,
        usbControlSets: null,
        actionConfigs: null,
        analyticSets: null,
        managedAnalyticSets: null,
      },
      analyticSets: this.plan?.analyticSets?.map((set) => set?.uuid),
      exceptionSets: this.plan?.exceptionSets?.map((set) => set.uuid),
      managedAnalyticSets: this.plan?.managedAnalyticSets?.map(
        (set) => set?.uuid
      ),
      usbControlSet: this.plan?.usbControlSet?.id || null,
      logLevelOptions: ['Error', 'Warning', 'Info', 'Debug', 'Verbose'],
      actionConfigs: this.plan?.actionConfigs?.id,
      telemetry: this.plan?.telemetry?.id || null,
      commsConfigOption: [
        { label: 'MQTT:443 (Default)', value: 'mqtt' },
        { label: 'WebSocket/MQTT:443', value: 'wss/mqtt' },
      ],
      commsConfig: this.plan.commsConfig?.protocol,
      message: null,
      threatPreventionConfigs: this.plan.signaturesFeedConfig?.mode,
      loading: true,
    };
  },
  validations() {
    const interval = {
      required,
      between: between(this.interval.min, this.interval.max),
    };
    return {
      internalPlan: {
        name: {
          required,
          isUniqueName: isUniqueName(this.plan?.name),
        },
        infoSync: {
          infoSyncInterval: this.cloudOnlyAccess ? interval : {},
          insightsSyncInterval: this.collectInsights ? interval : {},
        },
      },
      exceptionSets: {
        maxLength: maxLength(20),
      },
      actionConfigs: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) => state.plans.plansNames,
    }),
    collectInsights() {
      return this.endpointAttrs.includes('insights');
    },
    insightsOrComplianceMessage() {
      return this.isFeatureEnabled('COMPLIANCE_DASHBOARD')
        ? 'Compliance'
        : 'Insights';
    },
    computedPlan() {
      const infoSync = JSON.stringify({
        infoSyncInterval: minutesToSeconds(
          this.internalPlan.infoSync?.infoSyncInterval
        ),
        insightsSyncInterval: minutesToSeconds(
          this.internalPlan.infoSync?.insightsSyncInterval
        ),
        attrs: this.endpointAttrs.flat(),
      });
      const analyticSets = [
        ...(this.analyticSets ?? []),
        ...(this.managedAnalyticSets ?? []),
      ];
      return {
        ...this.plan,
        ...this.internalPlan,
        autoUpdate: this.cloudOnlyAccess ? this.internalPlan.autoUpdate : false,
        actionConfigs: this.actionConfigs,
        exceptionSets: this.exceptionSets,
        usbControlSet: this.usbControlSet,
        analyticSets,
        telemetry: this.telemetry,
        signaturesFeedConfig: {
          mode: this.threatPreventionConfigs,
        },
        logLevel:
          typeof this.internalPlan.logLevel === 'string'
            ? this.internalPlan.logLevel.toUpperCase()
            : null,
        infoSync,
      };
    },
  },
  watch: {
    commsConfig() {
      const { fqdn } = this.plan.commsConfig;
      const protocol = this.commsConfig;
      this.internalPlan.commsConfig = { fqdn, protocol };
    },
  },
  async mounted() {
    await this.getConfigSetOptions();

    if (!this.mode.create) {
      const osVersion = this.$typeDefs.endpoint.find(
        ({ label }) => label === 'OS Version'
      );
      this.endpointAttrs = this.internalPlan.infoSync.attrs.includes(
        osVersion?.value[0]
      )
        ? [
            ...this.internalPlan.infoSync.attrs.filter(
              (value) => !osVersion.value.includes(value)
            ),
            osVersion.value,
          ]
        : [...this.internalPlan.infoSync.attrs];
    }
    this.loading = false;
  },
  methods: {
    async getConfigSetOptions() {
      const result = await this.$store.dispatch(
        'primary/getPlanConfigurationAndSetOptions'
      );
      if (result) {
        Object.entries(result).forEach(([key, list]) => {
          this.configSetOptions[key] = list?.items?.map((item) => ({
            label: item.name,
            value: item.id || item.uuid,
            ...item,
          }));
        });
        this.configSetOptions.telemetries = this.setNoneOptionState(
          this.configSetOptions.telemetries
        );
        this.configSetOptions.usbControlSets = this.setNoneOptionState(
          this.configSetOptions.usbControlSets
        );
        if (this.mode.create) {
          this.actionConfigs = this.configSetOptions.actionConfigs?.[0].value;
          this.exceptionSets = this.setManaged(
            this.configSetOptions.exceptionSets
          );
          this.managedAnalyticSets =
            this.configSetOptions.managedAnalyticSets?.map(
              ({ value }) => value
            );
        }
      }
    },
    setNoneOptionState(options) {
      if (options) {
        return options.length > 0
          ? [this.noneOption, ...options]
          : [this.noneOption];
      }
      return null;
    },
    setManaged(data) {
      const managedSets = data
        ?.filter((item) => item.managed === true)
        .map((item) => item?.uuid);
      return managedSets || [];
    },
    commsConfigLabels({ protocol }) {
      return protocol === 'mqtt' ? 'MQTT:443 (Default)' : 'WebSocket/MQTT:443';
    },
    async submit() {
      const dispatchMethod = this.mode.create ? 'createPlan' : 'updatePlan';
      const plan = await this.$store.dispatch(
        `primary/${dispatchMethod}`,
        this.computedPlan
      );
      if (plan) {
        this.internalPlan.id = plan.id;
      }
    },
    isSearchable(options) {
      return options?.length > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.num-width {
  width: auto;
}

.enpoint-wrap {
  @include flex-wrap($selector: '.checkbox', $scale: 0);
}

fieldset,
.item {
  display: grid;
  gap: var(--size-grid-gap-base);
}
.item {
  grid-auto-flow: column;
  grid-template-columns: max-content;
  > div {
    display: flex;
  }

  @include breakpoint(small down) {
    grid-auto-flow: row;
    gap: spacing();
  }
}
</style>
