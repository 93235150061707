/**
 * @description removes the __typename key from graphQL requests,
 * Checks for any File instances as well to ensure no conversion to
 * object happens
 * @param {object || array} obj graphQL responce to strip from
 * @returns {object || array} wihtout propToDelete
 */
/* eslint-disable */
const stripTypenames = (obj) => {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      const newData = stripTypenames(obj[property]);
      obj[property] = newData;
    } else {
      if (property === '__typename') {
        delete obj[property];
      }
    }
  }
  return obj;
}

export default stripTypenames;


