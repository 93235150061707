<template>
  <div class="gp-content">
    <transition name="fade" mode="out-in">
      <div
        class="j-tabs g-cols"
        tabindex="-1"
        v-if="internalTabs.length > 1 && !onNested"
      >
        <ul class="tab-list" role="tablist">
          <li
            v-for="(tab, index) in internalTabs"
            :key="tab.label"
            role="tab"
            :data-feature-id="`tab-${index}`"
            :aria-selected="active(tab)"
            :aria-label="tab.ariaLabel"
            tabindex="0"
            @click="selectTab(tab)"
            @keypress.enter="selectTab(tab)"
            :class="{ tab: true, active: active(tab) }"
          >
            {{ tab.label }}
          </li>
          <div class="extra">
            <slot name="right"></slot>
          </div>
        </ul>
      </div>
    </transition>
    <Section><slot :selected="selected"></slot></Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Tabs',
  components: { Section },
  props: {
    tabs: {
      /**
       * routed: [{ label: String, route: RouteObject, nestedRouteNames: [String], hideOnNestedRoutes: Boolean }]
       * default: [{ label: String, key: String }]
       * */
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    /** router should replace and not push */
    replace: Boolean,
    /** Prevents tab from returning to main route when pressed and on nestedRoutes's,
     * use this when more then just a route needs to happen on return or replace is true */
    noReturn: Boolean,
  },
  data() {
    return {
      selected: {},
    };
  },
  emits: ['tab-changed'],
  computed: {
    ...mapState('route', ['name', 'meta']),
    ...mapState({
      queryState: (state) => state.app.queryState,
    }),
    ...mapGetters(['hasLimitedAppAccess']),
    onNested() {
      return this.selected?.hideOnNestedRoutes
        ? this.selected?.nestedRouteNames?.includes(this.name)
        : false;
    },
    internalTabs() {
      if (this.hasLimitedAppAccess) {
        return this.tabs.filter((tab) =>
          this.$router.hasRoute(tab.route?.name)
        );
      }
      return [...this.tabs];
    },
  },
  beforeMount() {
    const getActive = this.internalTabs.find(
      ({ route, nestedRouteNames }) =>
        route?.name === this.name || nestedRouteNames?.includes(this.name)
    );
    this.selectTab(getActive || this.internalTabs[0], false);
  },
  methods: {
    selectTab(tab, userActivated = true) {
      if (!this.active(tab)) {
        if (
          Object.prototype.hasOwnProperty.call(tab, 'route') &&
          this.name !== tab.route.name
        ) {
          this.$router[this.replace ? 'replace' : 'push']({
            ...(this.queryState[tab.route.name] || {}),
            ...tab.route,
          });
        } else {
          this.selected = tab;
        }
      } else if (
        this.active(tab) &&
        tab.route &&
        this.name !== tab.route?.name &&
        !this.noReturn &&
        userActivated
      ) {
        // ensure tab returns to main link if currently on an activeLink
        this.$router.push(tab.route);
      }
      this.$emit('tab-changed', this.selected || tab);
    },
    active(tab) {
      let isActive;
      if (Object.prototype.hasOwnProperty.call(tab, 'route')) {
        isActive = Object.prototype.hasOwnProperty.call(tab, 'nestedRouteNames')
          ? [tab.route.name, ...tab.nestedRouteNames].includes(this.name)
          : tab.route.name === this.name;
        this.selected = isActive ? tab : this.selected;
      } else {
        isActive = this.selected?.key === tab.key;
      }
      return isActive ? true : null;
    },
    setTestID(value) {
      return value.toLowerCase().replace(/\s/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.j-tabs {
  --size-tab-list-height: fit-content;
  --size-action-height-base: var(--size-action-height-secondary);
  @include tab-container;
  min-height: 40px;
  max-height: 40px;
  border-bottom-color: var(--color-border-secondary);

  .tab-list {
    @include tab-group-container;
    @include grid(spacing());
    @include grid-columns(max-content);
    grid-template-columns: repeat(12, min-content) 1fr;
    height: max-content;
    padding-left: spacing(2);

    .tab {
      @include tab;
      margin: 0;
      white-space: nowrap;
      &:hover::after {
        background-color: var(--color-secondary-active);
      }
    }
    .extra {
      display: flex;
      place-self: center;
      align-items: center;
      justify-content: flex-end;
      padding-right: spacing(3);
      grid-column: -1/-1;
    }
  }
}

.gp-content {
  position: relative;
}
</style>
