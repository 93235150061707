<template>
  <div class="scroll-vertical">
    <div class="dashboard-container">
      <CardListItems title="API Client Configuration" :values="apiClientValues">
        <ListItem title="Roles">
          <div class="tags">
            <j-tag
              v-for="role in apiClient.assignedRoles"
              data-feature-id="roles"
              :key="role.id"
              :to="{ name: 'account.roles.detail', params: { id: role.id } }"
              >{{ role.name }}</j-tag
            >
          </div>
        </ListItem>
      </CardListItems>
      <CardListItems title="Token and API Endpoints" :values="endpointValues" />
      <Card class="help-container secondary">
        <h2>Authentication Help</h2>
        <p>
          This example executes a curl command that obtains an access token,
          valid for a minimum of one hour, where
          <input
            name="password"
            class="help-text-form-input"
            type="text"
            v-model.trim="secretText"
            placeholder="<PASSWORD>"
          />
          is your API Client password:<br />
        </p>
        <pre>curl --request POST \
     --header 'content-type: application/json' \
     --url "<span class="help-text-mono-hl">{{oauthTokenUrl}}</span>" \
     --data '{"client_id": "<span class="help-text-mono-hl">{{this.apiClient.clientId}}</span>",
            "password": "<span class="help-text-mono-hl">{{helperTemplateSecret}}</span>"}'</pre>
        <p>
          The returned JSON contains an
          <code class="help-text-mono-hl">access_token</code> key, which can be
          used as an authorization header in requests to the Jamf Protect
          GraphQL API at the following URL:
          <code class="help-text-mono-hl">{{ graphqlPrimaryUrl }}</code>
        </p>

        <p></p>
        <hr />
        <h2>Altair GraphQL Client Setup</h2>
        <a class="align-center" @click="getConfigDownload()">
          <j-icon
            data="@jcon/download-2.svg"
            height="16"
            width="16"
            class="mr-1"
          />
          Altair Window Configuration
        </a>
        <span
          >Download and open the configuration file to set up a new window in
          Altair. For additional environment setup instructions, see
          <a
            href="https://docs.jamf.com/jamf-protect/administrator-guide/Jamf_Protect_API.html"
            target="blank"
            rel="noopener"
            >Jamf Protect API</a
          >
          in the Jamf Protect Administrator’s Guide.</span
        >
      </Card>
    </div>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import Card from '@/components/Card.vue';
import CardListItems from '@/components/CardListItems';
import ListItem from '@/components/ListItem.vue';
import { downloadFromUrl } from '@/util';
import { mapState } from 'vuex';

export default {
  name: 'ApiClientDetails',
  components: {
    Card,
    CardListItems,
    ListItem,
  },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  data() {
    return {
      instructionsExpanded: false,
      oauthTokenUrl: `https://${window.location.host}/token`,
      graphqlPrimaryUrl:
        this.$store.state.auth.loginConfig.public_graphql_url ||
        `https://${window.location.host}/graphql`,
      secretText: '',
    };
  },
  computed: {
    ...mapState('primary', {
      apiClient: (state) => state.apiclients.apiClient,
    }),
    apiClientValues() {
      return [
        { label: 'Name', value: this.apiClient.name },
        {
          label: 'Created',
          value: this.formatEpochDate(this.apiClient.created),
        },
        { label: 'Client ID', value: this.apiClient.clientId },
        { label: 'Password', value: this.apiClient.password },
      ];
    },
    endpointValues() {
      return [
        { label: 'Access Token URL', value: this.oauthTokenUrl },
        { label: 'Jamf Protect API URL', value: this.graphqlPrimaryUrl },
      ];
    },
    helperTemplateSecret() {
      return this.secretText === '' ? '<PASSWORD>' : this.secretText;
    },
  },
  methods: {
    async getConfigDownload() {
      const config = await this.$store.dispatch(
        'primary/getApiClientAltairConfig'
      );
      const filename = 'JamfProtect-AltairConfiguration.agq';
      const blob = new Blob([config], { type: 'application/json' });
      downloadFromUrl(window.URL.createObjectURL(blob), filename);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  @include grid(spacing(2));
  grid-template-columns: 2fr 1fr;
  padding: spacing(2);

  @include breakpoint(small down) {
    grid-template-columns: 1fr;
  }
}

.help-container {
  @include grid(spacing(1));
  width: 100%;

  @include breakpoint(medium down) {
    grid-column: span 2;
  }

  @include breakpoint(small down) {
    grid-column: 1;
  }

  h2 {
    @include header4;
  }

  .align-center {
    display: flex;
    align-items: center;
    white-space: break-spaces;
  }

  .help-text-form-input {
    @include input-field-base;
    min-width: unset;
    height: var(--size-font-paragraph-height);
    width: 8em;
    display: inline;
  }
  .help-text-mono-hl {
    color: var(--color-primary-active);
  }
}
</style>
