<template>
  <div>
    <transition name="long-fade" mode="out-in">
      <small
        v-if="computedUpdate"
        :data-testid="`updateIndicator${failed ? '-error' : ''}`"
        class="g-cols max-c ai-center gap-1 helper-text"
      >
        <template v-if="failed">
          <j-icon
            data="@jcon/error.svg"
            width="12"
            height="12"
            color="var(--color-danger-base)"
          />{{ messageFailed }}
        </template>
        <template v-else>
          <j-icon
            data="@jcon/check-bold.svg"
            width="12"
            height="12"
            color="var(--color-success-base)"
          />{{ message }}</template
        >
      </small>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UpdateIndicator',
  compatConfig: {
    MODE: 3,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: 'Updated',
    },
    messageFailed: {
      type: String,
      default: 'Error Updating!',
    },
    failed: Boolean,
  },
  emits: ['update:modelValue'],
  computed: {
    computedUpdate: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  beforeUnmount() {
    this.computedUpdate = false;
    this.$emit('update:modelValue', false);
  },
  beforeCreate() {
    this.computedUpdate = false;
    this.$emit('update:modelValue', false);
  },
  watch: {
    modelValue() {
      if (this.modelValue) {
        setTimeout(() => {
          this.computedUpdate = false;
          this.$emit('update:modelValue', false);
        }, 3000);
      }
    },
  },
};
</script>
