import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import ApiClientForm from './ApiClientForm.vue';
import ApiClients from './ApiClients.vue';
import ApiClient from './ApiClient.vue';
import ApiClientDetails from './ApiClientDetails.vue';
import ApiClientDelete from './ApiClientDelete.vue';

export default [
  {
    path: '/api-clients',
    component: Section,
    name: 'apiClients',
    meta: {
      breadcrumb: {
        linkName: 'apiClients.index',
        linkText: 'API Clients',
      },
      permissions: [RBAC_RESOURCE.API_CLIENT],
    },
    children: [
      {
        path: '',
        component: ApiClients,
        name: 'apiClients.index',
      },
      {
        path: 'create',
        component: ApiClientForm,
        name: 'apiClients.index.create',
        meta: {
          breadcrumb: {
            linkText: 'Create',
          },
          requireWrite: true,
        },
      },
      {
        path: ':clientId',
        component: ApiClient,
        meta: {
          breadcrumb: {
            linkName: 'apiClients.index.detail',
            linkText: 'API Client Summary',
          },
        },
        children: [
          {
            path: '',
            name: 'apiClients.index.detail',
            component: ApiClientDetails,
          },
          {
            path: 'delete',
            name: 'apiClients.index.detail.delete',
            component: ApiClientDelete,
            meta: {
              requireWrite: true,
            },
          },
          {
            path: 'edit',
            name: 'apiClients.index.detail.edit',
            component: ApiClientForm,
            meta: {
              requireWrite: true,
            },
          },
        ],
      },
    ],
  },
];
