/**
 * @description counts the number of deprecated macOS instances
 * @param {number} macosVersion
 */

import { macOSVersionDeprecated } from './macos-version-deprecated';

export const countDeprecatedVersions = (macosVersion) => {
  let depreactedCount = 0;
  const deprecated = macosVersion.filter((item) => {
    const versionData = item.value.split('.');
    const osMajor = Number(versionData[0]);
    const osMinor = Number(versionData[1]);
    return macOSVersionDeprecated(osMajor, osMinor);
  });

  deprecated.forEach((item) => {
    depreactedCount += item.count;
  });

  return depreactedCount;
};

export default countDeprecatedVersions;
