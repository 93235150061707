<template>
  <JamfTable
    v-if="!loading"
    :get-data="getData"
    suppress-filter-bar
    suppress-refresh-button
    suppress-search
    :columns="columns"
  >
    <template v-if="canWrite" #left>
      <j-create-button
        @click="$router.push({ name: 'account.groups.create' })"
        :label="`Create Group`"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useAccount } from './composable/account';

export default {
  name: 'AccountGroups',
  components: { JamfTable },
  setup() {
    const { associatedUsers, getUsers, canWrite } = useAccount(
      RBAC_RESOURCE.GROUP
    );

    return {
      associatedUsers,
      getUsers,
      canWrite,
    };
  },
  data() {
    return {
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          sort: 'asc',
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'account.groups.detail',
              params: { id: params?.data?.id },
            }),
          },
        },
        {
          headerName: 'Role(s)',
          field: 'assignedRoles',
          type: 'tagsColumn',
          minWidth: 160,
          permissions: [RBAC_RESOURCE.ROLE],
          valueFormatter: ({ value }) =>
            value?.map(({ name, id }) => ({ value: name, id })) || [],
          cellRendererParams: {
            route: ({ id }) => ({
              name: 'account.roles.detail',
              params: { id },
            }),
          },
        },
        {
          headerName: 'Associated Users',
          type: 'tagsColumn',
          field: 'id',
          colId: 'assignedUsers',
          valueFormatter: ({ value }) =>
            this.associatedUsers(value, 'assignedGroups'),
          cellRendererParams: {
            route: ({ route }) => route,
          },
          permissions: [RBAC_RESOURCE.USER],
        },
        {
          headerName: 'Modified',
          field: 'updated',
          sortable: true,
          type: 'dateColumn',
        },
      ],
      loading: true,
    };
  },
  async beforeMount() {
    await this.getUsers();
    this.loading = false;
  },
  methods: {
    async getData(params) {
      const result = await this.$store.dispatch('primary/listGroups', params);
      const items = result.items.filter(({ connection }) => !connection?.name);
      return { items, pageInfo: { ...result.pageInfo, total: items.length } };
    },
  },
};
</script>
