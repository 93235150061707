<template>
  <div class="gp-content">
    <TitleDescriptionActionLayout
      title="Analytic Sets"
      description="Use analytic sets to deploy groups of analytics via one or more associated plans. 
      Once added to plans, you can continuously edit existing analytic sets without redeploying the associated plans."
    >
      <j-create-button
        v-if="canWrite"
        class="js-end"
        @click="() => toCreate()"
        label="Create Analytic Set"
      />
    </TitleDescriptionActionLayout>
    <JamfTable
      :get-data="getData"
      :columns="columns"
      suppress-search
      suppress-refresh-button
      :is-row-selectable="isRowSelectable"
    >
      <template #right="{ selected }">
        <j-delete-button
          v-if="canWrite"
          @click="() => confirmRemoveMultipleWithPlans(selected)"
          :is-disabled="selected.length === 0"
          :label="`Delete (${selected.length})`"
        />
      </template>
      <template #modals="{ refresh, selected }">
        <j-delete-modal
          v-model="showRemoveModal"
          :to-delete="toDelete"
          :blockers="selectedPlans"
          @confirm="() => remove(selected, refresh)"
        />
      </template>
    </JamfTable>
  </div>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import TitleDescriptionActionLayout from '@/components/TitleDescriptionActionLayout.vue';
import { useRBAC } from '@/composables/rbac';
import { useRemoveHelpers } from '@/composables/remove-helpers';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapState } from 'vuex';
import { associatedPlansColumn } from '@/components/table/utils/column.types';

export default {
  name: 'AnalyticsSets',
  components: {
    JamfTable,
    TitleDescriptionActionLayout,
  },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.ANALYTIC_SET);
    const {
      confirmRemoveMultipleWithPlans,
      toDelete,
      showRemoveModal,
      selectedPlans,
    } = useRemoveHelpers();

    return {
      canWrite,
      confirmRemoveMultipleWithPlans,
      toDelete,
      showRemoveModal,
      selectedPlans,
    };
  },
  data() {
    return {
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          filter: 'TableFilterText',
          type: 'linkColumn',
          sortable: true,
          flex: 1,
          minWidth: 100,
          cellRendererParams: {
            route: (params) => ({
              name: 'analytics.sets.detail',
              params: { uuid: params.data?.uuid },
              ...this.queryState['analytics.sets.detail'],
            }),
          },
        },
        {
          headerName: 'Description',
          field: 'description',
        },
        {
          headerName: 'Jamf-managed',
          field: 'analytics',
          colId: 'jamfAnalytics',
          valueFormatter: ({ value }) =>
            value?.filter(({ jamf }) => jamf === true).length,
        },
        {
          headerName: 'Custom',
          field: 'analytics',
          valueFormatter: ({ value }) =>
            value?.filter(({ jamf }) => jamf === false).length,
        },
        { ...associatedPlansColumn },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sortable: true,
          sort: 'asc',
        },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      queryState: (state) => state.app.queryState,
    }),
  },
  created() {
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
  },
  methods: {
    async remove(selected, refresh) {
      await Promise.all(
        selected.map(({ uuid }) =>
          this.$store.dispatch('primary/deleteAnalyticSet', { uuid })
        )
      );
      refresh();
    },
    toCreate() {
      const name = 'analytics.sets.create';
      this.$router.push({ name, ...this.queryState[name] });
    },
    isRowSelectable(rowNode) {
      return rowNode?.data?.managed !== true;
    },
    async getData(params) {
      const filter = {
        ...params.filter,
        and: [
          ...(params.filter?.and || []),
          { type: { equals: 'notify' } },
          { managed: { equals: false } },
        ],
      };
      return this.$store.dispatch('primary/listAnalyticSets', {
        ...params,
        filter,
      });
    },
  },
};
</script>
