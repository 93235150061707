<template>
  <div>
    <input ref="file" type="file" @change="fileUpload" hidden />
    <j-button @click="$refs.file.click()" data-feature-id="upload-button"
      ><template #leading><j-icon data="@jcon/upload.svg" /></template
      ><template v-if="label" #default>{{ label }}</template></j-button
    >
  </div>
</template>

<script>
export default {
  name: 'JUpload',
  compatConfig: {
    MODE: 3,
  },
  props: {
    modelValue: File,
    label: {
      type: String,
      default: 'Upload',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      newValue: this.modelValue,
    };
  },
  methods: {
    fileUpload(event) {
      const value = event.target.files;
      if (value.length) {
        const file = value[0];
        this.newValue = file;
      } else {
        this.newValue = null;
      }
      this.$emit('update:modelValue', this.newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-button {
  input {
    position: absolute;
    opacity: 0;
  }
}
</style>
