<template>
  <transition name="fade" mode="out-in">
    <div v-if="!suppressControlBar" class="controls" ref="controls">
      <div class="left">
        <template v-if="!suppressRefreshButton">
          <j-button
            class="p-0"
            style-type="ghost-primary"
            @click="refresh"
            data-feature-id="refresh"
          >
            <template #leading>
              <j-icon data="@jcon/refresh.svg" height="20" width="20" />
            </template>
          </j-button>
          <span class="divider"></span>
        </template>
        <template v-if="!suppressSearch">
          <slot name="search"></slot>
          <span class="divider"></span>
        </template>
        <slot name="left" :is-small="isSmall"></slot>
      </div>
      <div class="right">
        <slot name="right" :is-small="isSmall"></slot>
        <template v-if="!suppressPagination">
          <span class="divider"></span>
          <slot name="pagination"></slot>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TableControlBar',
  props: {
    suppressSearch: Boolean,
    suppressPagination: Boolean,
    suppressControlBar: Boolean,
    suppressRefreshButton: Boolean,
  },
  emits: ['refresh'],
  data() {
    return {
      controlsWidth: 0,
      isSmall: false,
      smallControlsWidth: 612,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.controlsWidth = this.$refs?.controls?.clientWidth;
      this.isSmall = this.controlsWidth <= this.smallControlsWidth;
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  --size-action-height-base: var(
    --size-action-height-secondary
  ); // resizes j-button to fit current bar size
  @include grid;
  @include grid-columns;
  align-items: center;
  height: var(--table-bar-height);
  padding: 0 spacing(2);
  border-bottom: 1px solid var(--color-table-border);
  @include breakpoint(small down) {
    gap: spacing();
  }

  .right,
  .left {
    @include grid;
    @include grid-columns;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .right {
    justify-content: flex-end;
  }
}
</style>
