<template>
  <div class="g-rows p-2 secondary-button-height">
    <div class="g-cols">
      <h1 class="h4">{{ title }}</h1>
      <slot></slot>
    </div>
    <p class="description">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TitleDescriptionActionLayout',
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.secondary-button-height {
  --size-action-height-base: var(--size-action-height-secondary);
}
.description {
  max-width: 1000px;

  @include breakpoint(small down) {
    display: none;
  }
}
</style>
