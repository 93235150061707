<template>
  <div :class="classes">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</template>

<script>
export default {
  name: 'SeverityIndicator',
  props: {
    level: String,
  },
  computed: {
    classes() {
      const level = this.level?.toLowerCase() || 'informational';
      return {
        severity: true,
        [level]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.severity {
  @include grid(spacing(0.5));
  @include grid-columns(max-content);
  .dot {
    @include dot;
  }

  &.high .dot {
    border-color: var(--color-danger-base);
    background: var(--color-danger-base);
  }

  &.medium :is(:nth-child(1), :nth-child(2)) {
    border-color: var(--color-warning-base);
    background: var(--color-warning-base);
  }

  &.low :nth-child(1) {
    border-color: var(--color-low-base);
    background: var(--color-low-base);
  }
}
</style>
