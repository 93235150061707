/**
 * Remove helper.
 * Use these functions/props in components that need to show a confirmation modal before delete mutations
 */

import { ref } from 'vue';

export function useRemoveHelpers() {
  const planRouteName = 'plans.index.detail';
  const showRemoveModal = ref(false);
  const toDelete = ref([]);
  const selectedPlans = ref([]);

  const confirmRemoveMultiple = (selected) => {
    showRemoveModal.value = true;
    toDelete.value = selected.map(({ name }) => name);
  };
  const confirmRemoveMultipleWithPlans = (selected) => {
    showRemoveModal.value = true;
    toDelete.value = selected.map(({ name }) => name);
    selectedPlans.value = selected
      .map(({ plans }) => plans)
      .flat()
      .map(({ name, id }) => ({
        value: name,
        route: { name: planRouteName, params: { id } },
      }));
  };
  const confirmRemove = (selected) => {
    showRemoveModal.value = true;
    toDelete.value = selected?.name;
  };
  const confirmRemoveWithPlans = (selected) => {
    showRemoveModal.value = true;
    toDelete.value = selected.name;
    selectedPlans.value = selected?.plans?.map(({ name, id }) => ({
      value: name,
      route: { name: planRouteName, params: { id } },
    }));
  };
  return {
    toDelete,
    selectedPlans,
    showRemoveModal,
    confirmRemove,
    confirmRemoveMultiple,
    confirmRemoveMultipleWithPlans,
    confirmRemoveWithPlans,
  };
}
