<template>
  <div class="field">
    <div class="control">
      <input
        v-model="value"
        @keydown.enter="chooseItemFromCurrentlySelectedIndex"
        @keydown.tab="chooseItemFromCurrentlySelectedIndex"
        @keydown.arrow-down="moveDown"
        @keydown.arrow-up="moveUp"
        @focus="inputFocused"
        @blur="inputBlur"
        @keydown.esc="inputBlur"
        class="j-input"
        type="text"
        ref="autoinput"
      />
      <ul
        v-if="showAutocomplete && autocompleteList.length > 0"
        :style="optionsStyleObject"
        ref="scrollContainer"
      >
        <li
          v-for="(option, index) in autocompleteList"
          :key="index"
          :class="{ active: selectedIndex === index }"
          @mouseover="autoCompleteMouseOver(index)"
          @click="autoCompleteClick(index)"
          ref="options"
        >
          {{ option }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import CaretCoordinates from 'textarea-caret-position';

export default {
  name: 'AutoCompleteInput',
  props: {
    def: {
      type: Object,
      required: true,
    },
    expression: {
      type: String,
      required: true,
    },
  },
  inject: ['$typeDefs'],
  emits: ['update:expression'],
  data() {
    return {
      allDefs: this.$typeDefs,
      parsedInput: [],
      currentDef: {
        value: this.def.value,
        label: 'FileSystemEvent',
        fields: [
          {
            value: '$event',
            fields: this.def.fields,
          },
          {
            value: '$context',
            fields: [],
          },
          {
            value: '$event',
            fields: [],
          },
        ],
      },
      baseDef: this.currentDef,
      hasFocus: false,
      clickedItem: false,
      selectedIndex: 0,
      optionsStyleObject: {
        left: '0px',
      },
      coordinateHelper: null,
      value: '',
      autocompleteList: [],
      autocompleteFullList: [],
      baseOptions: {},
    };
  },
  computed: {
    frontPredicate() {
      return this.value.substring(0, this.value.lastIndexOf('.'));
    },
    backPredicate() {
      return this.value.substring(
        this.value.lastIndexOf('.') + 1,
        this.value.length
      );
    },
    element() {
      return this.$refs.autoinput;
    },
    showAutocomplete() {
      return this.autocompleteList.length > 0 && this.hasFocus;
    },
  },
  watch: {
    value() {
      this.hasFocus = true;
      this.$emit('update:expression', this.value);
    },
    frontPredicate() {
      this.$log.debug(
        `Finding autocomplete options for ${this.frontPredicate}`
      );
      if (this.frontPredicate === '') {
        this.autocompleteFullList = _.keys(this.baseOptions);
        return;
      }
      const obj = this.getDefFromPredicate(this.frontPredicate);
      this.autocompleteFullList = _.keys(this.getOptionsForDef(obj));
      this.autocompleteList = this.autocompleteFullList;
      this.$log.debug(`Found ${this.autocompleteFullList.length} options`);
    },
    backPredicate() {
      this.$log.debug(`Searching for ${this.backPredicate}`);
      if (this.autocompleteFullList.length > 0) {
        this.autocompleteList = this.autocompleteFullList.filter((option) =>
          option.startsWith(this.backPredicate)
        );
        this.selectedIndex = 0;
      }
    },
    def() {
      this.initialize();
    },
  },
  mounted() {
    this.coordinateHelper = new CaretCoordinates(this.element);
    this.initialize();
  },
  methods: {
    setFocus() {
      this.element.focus();
    },
    setCoords() {
      const coords = this.coordinateHelper.get();
      this.optionsStyleObject.left = `${coords.left}px`;
    },
    inputFocused() {
      this.hasFocus = true;
      this.setCoords();
    },
    inputBlur() {
      // Timeout here so that the click handler can complete before we lose focus
      // and don't display the autocomplete list
      setTimeout(() => {
        if (!this.clickedItem) {
          this.hasFocus = false;
        }
        this.clickedItem = false;
      }, 300);
    },
    moveDown() {
      this.selectedIndex =
        (this.selectedIndex + 1) % this.autocompleteList.length;
      this.setScroll();
    },
    moveUp() {
      if (this.selectedIndex === 0) {
        this.selectedIndex = this.autocompleteList.length - 1;
      } else {
        this.selectedIndex -= 1;
      }
      this.setScroll();
    },
    setScroll() {
      const liH = this.$refs.options[this.selectedIndex].clientHeight;
      this.$refs.scrollContainer.scrollTop = liH * this.selectedIndex;
    },
    autoCompleteClick(index) {
      this.clickedItem = true;
      this.chooseItemFromIndex(index);
      this.setFocus();
    },
    autoCompleteMouseOver(index) {
      this.selectedIndex = index;
    },
    chooseItemFromCurrentlySelectedIndex(e) {
      this.chooseItemFromIndex(this.selectedIndex);
      if (e) {
        e.preventDefault();
      }
    },
    chooseItemFromIndex(index) {
      if (
        this.autocompleteList.length > 0 &&
        index < this.autocompleteList.length
      ) {
        const autoValue = this.autocompleteList[index];

        let newValue = autoValue;
        if (this.frontPredicate !== '') {
          newValue = `${this.frontPredicate}.${autoValue}`;
        }

        const def = this.getDefFromPredicate(newValue);
        if (def.type !== 'string') {
          if (_.has(def, 'fields')) {
            newValue = `${newValue}.`;
          } else {
            newValue = `${newValue} `;
          }
        }
        this.value = newValue;
      }
    },
    getDefFromPredicate(predicate) {
      const delimited = predicate.split('.');
      let def = this.baseDef;
      delimited.forEach((key) => {
        if (def && key !== '') {
          const index = _.findIndex(def.fields, { value: key });
          if (index >= 0) {
            def = def.fields[index];
            if (!_.has(def, 'fields')) {
              const typeDef = this.allDefs.types[def.type];
              if (_.has(typeDef, 'fields')) {
                def.fields = typeDef.fields;
              }
            }
          }
        }
      });
      return def;
    },
    initialize() {
      this.baseDef = {
        value: this.def.value,
        label: this.def.label,
        fields: [
          {
            value: '$event',
            fields: this.def.fields,
          },
          {
            value: '$context',
            fields: [],
          },
          {
            value: '$tags',
            fields: [],
          },
        ],
      };
      this.currentDef = this.baseDef;
      this.value = '';
      this.baseOptions = this.getOptionsForDef(this.baseDef);
      this.autocompleteFullList = _.keys(this.baseOptions);
      this.autocompleteList = this.autocompleteFullList;
      this.lastAutocompleteType = this.baseDef.type;
    },
    getOptionsForDef(defObj) {
      const fieldOptions = {};
      if (_.has(defObj, 'fields')) {
        defObj.fields.forEach((field) => {
          fieldOptions[field.value] = field.type;
        });
      }
      return fieldOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
$spacing: spacing();

.j-input {
  @include input-field-base;
  width: 100%;
}

.control {
  position: relative;
}

ul {
  position: absolute;
  z-index: 1000;
  overflow: auto;
  min-width: 250px;
  max-height: 150px;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  border-radius: 0px;
  background-color: var(--color-structure-secondary);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);

  li {
    color: var(--color-font-base);
    padding: 5px 10px;
  }

  li.active {
    background: var(--color-structure-highlight-base);
  }
}
</style>
