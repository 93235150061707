/**
 * @description identifies if a version of the OS is deprecated
 * @param {number} majorVersion
 * @param {number} minorVersion
 */

import { MACOS_VERSION_DEPRECATED } from './constants/macos-version-deprecated.types';

export const macOSVersionDeprecated = (osMajorVersion, osMinorVersion) => {
  return (
    osMajorVersion <= MACOS_VERSION_DEPRECATED.majorVersion &&
    osMinorVersion <= MACOS_VERSION_DEPRECATED.minorVersion
  );
};

export default macOSVersionDeprecated;
