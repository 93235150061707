import { downloadFromUrl, base64toBlob } from '@/util';
import { reactive } from 'vue';
import { useStore } from 'vuex';

export function usePlan() {
  const store = useStore();

  const planProfileDownloadOptions = reactive({
    tokenOptions: {
      xpc: false,
      keychain_client_id: false,
    },
    sign: true,
    pppc: true,
    token: true,
    ca: true,
    csr: true,
    websocket: true,
    systemExtension: true,
    serviceManagement: true,
  });

  const downloadOfflineOptions = reactive({
    ...planProfileDownloadOptions,
    ca: false,
    csr: false,
    websocket: false,
  });

  const downloadConfigProfile = async ({ id, name }, input) => {
    const data = await store.dispatch('primary/getPlansConfigProfile', {
      id,
      input,
    });
    const blob = base64toBlob(data, 'application/octet-stream');
    const filename = 'JamfProtect-Plan-'.concat(name).concat('.mobileconfig');
    downloadFromUrl(window.URL.createObjectURL(blob), filename);
  };

  return {
    planProfileDownloadOptions,
    downloadOfflineOptions,
    downloadConfigProfile,
  };
}
