<template>
  <li
    tabindex="-1"
    :class="{ 'nav-item': true, 'is-collapsible-item': isCollapsibleItem }"
  >
    <router-link
      :to="{ name: route, query }"
      @keypress.enter="() => $route.push({ name: route, query })"
      :data-feature-id="feartureId"
    >
      <div class="item">
        <j-icon v-if="icon && !isCollapsibleItem" :data="icon" />
        <span>{{ label }}</span>
      </div>
    </router-link>
    <div class="item-hover-bkg"></div>
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    icon: Object,
    isCollapsibleItem: Boolean,
    query: Object,
  },
  computed: {
    feartureId() {
      const id = this.label.toLowerCase();
      return `nav-${id.split(' ').join('-')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: var(--color-sidenav-primary);
.nav-item {
  position: relative;
  display: flex;
  cursor: pointer;
  min-height: var(--size-action-height-base);
  align-items: center;

  .item {
    @include grid;
    @include grid-columns(min-content auto);
    align-items: center;
    color: inherit;
    white-space: nowrap;
    width: 100%;
  }

  a {
    @include transition(color border-color, 300ms, ease-in-out);
    width: 100%;
    color: inherit;
    display: flex;
    align-items: center;
    height: var(--size-action-height-base);
    z-index: 1;
    outline: none;

    &:focus + .item-hover-bkg {
      opacity: var(--color-sidenav-item-opacity);
    }
  }

  .item-hover-bkg {
    @include transition(opacity, 300ms, ease-in);
    position: absolute;
    background: var(--color-sidenav-item-active);
    opacity: 0;
    height: var(--size-action-height-base);
    width: 110%;
    left: spacing(-1);
    top: 0;
    z-index: 0;
    border-radius: 4px;
    outline: none;
  }

  &.is-collapsible-item {
    padding-left: 7px;
    a {
      border-left: 2px solid var(--color-border-secondary);
    }
    .item {
      padding: 4px 21px;
    }
    .router-link-active {
      border-color: $primary;
    }

    &:hover a {
      border-color: $primary;
    }
  }

  svg {
    @include transition(color, 300ms, ease-in-out);
  }

  .router-link-active {
    svg {
      color: $primary;
    }
  }

  &:hover {
    a {
      text-decoration: none !important;
    }

    svg {
      color: $primary;
    }

    &.is-collapsible-item {
      border-color: $primary;
      color: $primary;
    }

    .item-hover-bkg {
      opacity: var(--color-sidenav-item-opacity);
    }
  }
}
</style>
