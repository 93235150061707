import { computed, onBeforeUnmount, ref } from 'vue';

export function useTableCell(props) {
  const eventType = ref(null);
  const position = ref('leading');

  const leading = computed(() => position.value === 'leading');
  const trailing = computed(() => position.value === 'trailing');

  const addEventListener = (type, method) => {
    eventType.value = type;
    props.params?.api.addEventListener(type, (p) => {
      if (
        props.params?.column.colId === p?.column?.colId &&
        props.params?.rowIndex === p?.rowIndex
      ) {
        // ensure only the column/cell being interacted with is effected
        method(p);
      }
    });
  };

  const actions = (event) => {
    // if meta key is used to open in new tab or window actions will not be run. 'ctrlKey' is for windows
    if (!event.metaKey || !event.ctrlKey) {
      props.params.actions?.(props.params);
    }
  };

  // set in template
  const link = ref({});

  const routeTo = (params) => {
    if (params.event.key === 'Enter') {
      link?.value?.$el?.click?.();
      actions(params.event);
    }
  };

  onBeforeUnmount(() => {
    if (eventType.value) {
      props.params.api.removeEventListener(eventType);
    }
  });

  return {
    link,
    routeTo,
    actions,
    addEventListener,
    leading,
    trailing,
    eventType,
    position,
  };
}
