<template>
  <div v-if="orgName" class="sidenav scroll-vertical">
    <div class="sidenav-mobile">
      <span @click="() => (show = !show)">
        <j-icon data="@jcon/hamburger-menu.svg" height="16" width="16" />
      </span>
      <router-link class="jamf" :to="toIndex">
        <j-icon
          data="@icon/protect_color_knockout.svg"
          color="var(--color-font-sidenav-base)"
          width="100%"
        />
      </router-link>
    </div>
    <aside ref="sidenav" :class="{ show }">
      <div class="nav">
        <div class="logo">
          <router-link :to="toIndex">
            <j-icon
              data="@icon/protect_color_knockout.svg"
              color="var(--color-font-sidenav-base)"
              width="100%"
            />
            <span class="sr-only">Jamf Protect </span>
            <span class="release-group" v-if="isPreProd">{{
              devInfo.releaseGroup
            }}</span>
          </router-link>
        </div>
        <span v-if="main.length > 0" class="section-title">{{ orgName }}</span>
        <ul class="sidenav-list">
          <NavItem
            v-for="{ label, route, icon } in main"
            :key="label"
            :ref="getParent(route)"
            @click="moveSelected(route)"
            :route="route"
            :icon="icon"
            :label="label"
            :query="getQuery(route)"
          />
        </ul>
        <span v-if="config.length > 0" class="section-title"
          >Configuration</span
        >
        <ul class="sidenav-list">
          <NavItem
            v-for="{ label, route, icon } in config"
            :ref="getParent(route)"
            @click="moveSelected(route)"
            :key="label"
            :route="route"
            :icon="icon"
            :label="label"
            :query="getQuery(route)"
          />
        </ul>
        <template v-if="expandAdministrative">
          <span class="section-title">Administrative</span>
          <ul class="sidenav-list">
            <NavItem
              v-for="{ label, route, icon } in administrative"
              :ref="getParent(route)"
              @click="moveSelected(route)"
              :key="label"
              :route="route"
              :icon="icon"
              :label="label"
              :query="getQuery(route)"
            />
          </ul>
        </template>
        <CollapsibleNav
          v-else-if="administrative.length > 0"
          v-model="isOpen"
          :is-active="isCollapsibleItem"
        >
          <NavItem
            v-for="{ label, route } in administrative"
            :ref="getParent(route)"
            @click="moveSelected(route)"
            :key="label"
            :is-collapsible-item="true"
            :route="route"
            :label="label"
            :query="getQuery(route)"
          />
        </CollapsibleNav>
        <div
          class="item-active-bkg"
          :style="{ top }"
          :class="{
            'is-collapsible': expandAdministrative ? false : isCollapsibleItem,
            open: isOpen,
          }"
        ></div>
      </div>
      <div class="aws-info">
        <NavItem
          v-if="isJamfAdmin"
          class="mb-2"
          :ref="getParent(admin.route)"
          @click="moveSelected(admin.route)"
          :route="admin.route"
          :icon="admin.icon"
          :label="admin.label"
        />
        <ul v-if="isFeatureEnabled('AWS_ENV')">
          <list-item title="account">{{ devInfo.account }}</list-item>
          <list-item title="app">{{ devInfo.branch || 'local' }} </list-item>
          <list-item title="api"> {{ devInfo.beBranch || 'main' }}</list-item>
          <list-item title="region">{{ devInfo.region }}</list-item>
          <list-item title="admin">{{ isJamfAdmin ? 'yes' : 'no' }}</list-item>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NavItem from './NavItem.vue';
import CollapsibleNav from './CollapsibleNav.vue';
import ListItem from '../ListItem.vue';
import {
  actions,
  alerts,
  computers,
  insights,
  download,
  analytics,
  plans,
  overview,
  threatprevention,
  unifiedlogging,
  usb,
  account,
  auditlogs,
  admin,
  telemetry,
} from './side-nav-icons';

export default {
  name: 'Sidenav',
  components: { NavItem, CollapsibleNav, ListItem },
  props: {
    orgName: {
      type: String,
      required: true,
    },
    isJamfAdmin: Boolean,
    currentRoute: String,
    devInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      main: [],
      config: [],
      administrative: [],
      top: '13%',
      isOpen: false,
      show: false,
      admin: {
        route: 'admin.tenants.index',
        label: 'Admin',
        icon: admin,
      },
    };
  },
  watch: {
    currentRoute() {
      this.show = false;
      this.moveSelected(this.currentRoute);
    },
    async isOpen() {
      await this.$nextTick();
      if (this.isCollapsibleItem) {
        this.moveSelected(this.currentRoute);
      }
    },
  },
  computed: {
    ...mapState({
      queryState: (state) => state.app.queryState,
    }),
    ...mapGetters(['isFeatureEnabled', 'hasLimitedAppAccess']),
    isCollapsibleItem() {
      return (
        this.administrative.filter(
          ({ route }) =>
            this.getParent(this.currentRoute) === this.getParent(route)
        ).length >= 1
      );
    },
    toIndex() {
      return this.hasLimitedAppAccess
        ? { name: 'plans.index' }
        : { name: 'overview.index' };
    },
    isPreProd() {
      return ['dev', 'alpha', 'beta'].includes(
        this.devInfo.releaseGroup.toLowerCase()
      );
    },
    expandAdministrative() {
      return (
        this.hasLimitedAppAccess &&
        [1, 2, 3].includes(this.administrative.length)
      );
    },
  },
  methods: {
    getQuery(route) {
      return this.queryState[route]?.query;
    },
    moveSelected(route) {
      const name = this.getParent(route);
      const { offsetTop } = this.$refs[name]?.length
        ? this.$refs[name][0].$el
        : this.$refs[name]?.$el || {};
      this.top = `${offsetTop}px`;
    },
    getParent(route) {
      return route?.split('.')[0];
    },
    handleClickOutside(event) {
      if (this.$refs.sidenav && !this.$refs.sidenav.contains(event.target)) {
        this.show = false;
      }
    },
  },
  created() {
    this.main = [
      {
        route: 'overview.index',
        icon: overview,
        label: 'Overview',
      },
      {
        route: 'insights.index',
        icon: insights,
        label: 'Insights',
      },
      {
        route: 'computers.index',
        icon: computers,
        label: 'Computers',
      },
      {
        route: 'alerts.index',
        icon: alerts,
        label: 'Alerts',
      },
    ];
    this.config = [
      {
        route: 'analytics.sets',
        icon: analytics,
        label: 'Analytics',
      },
      {
        route: 'plans.index',
        icon: plans,
        label: 'Plans',
      },
      {
        route: 'actions.index',
        icon: actions,
        label: 'Actions',
      },
      {
        route: 'prevent.index',
        icon: threatprevention,
        label: 'Threat Prevention',
      },
      {
        route: 'unifiedLogging.index',
        icon: unifiedlogging,
        label: 'Unified Logging',
      },
    ];

    this.administrative = [
      {
        route: 'account.index',
        label: 'Account',
        icon: account,
      },
      {
        route: 'data.index',
        label: 'Data',
      },
      {
        route: 'downloads.index',
        label: 'Downloads',
        icon: download,
      },
      {
        route: 'apiClients.index',
        label: 'API Clients',
      },
      {
        route: 'auditlogs.index',
        label: 'Audit Logs',
        icon: auditlogs,
      },
      {
        route: 'documentation.index',
        label: 'Documentation',
      },
    ];

    if (this.isFeatureEnabled('USB_CONTROLS')) {
      this.config.splice(4, 0, {
        route: 'devicecontrols.index',
        icon: usb,
        label: 'Device Controls',
      });
    }

    if (this.isFeatureEnabled('TELEMETRY_STREAM')) {
      this.config.push({
        route: 'telemetry.index',
        icon: telemetry,
        label: 'Telemetry',
      });
    }

    if (this.isFeatureEnabled('COMPLIANCE_DASHBOARD')) {
      this.main.splice(1, 1, {
        route: 'compliance.index',
        icon: insights,
        label: 'Compliance',
      });
    }

    if (this.hasLimitedAppAccess) {
      this.main = this.main.filter(({ route }) => this.$router.hasRoute(route));
      this.config = this.config.filter(({ route }) =>
        this.$router.hasRoute(route)
      );
      this.administrative = this.administrative.filter(({ route }) =>
        this.$router.hasRoute(route)
      );

      if (!this.isFeatureEnabled('HC_MODE')) {
        this.main = [
          {
            route: 'downloads.index',
            label: 'Downloads',
            icon: download,
          },
        ];
        this.config = [];
        this.administrative = [];
      }
    }

    this.isOpen = this.isCollapsibleItem;
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  mounted() {
    this.moveSelected(this.getParent(this.currentRoute));
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.sidenav {
  @include border($color: var(--color-border-secondary), $sides: right);
  background: var(--color-sidenav-base);
  color: var(--color-font-sidenav-base);
  font-size: var(--size-font-paragraph-base);

  .section-title {
    text-transform: uppercase;
    font-size: var(--size-font-label-base);
    color: var(--color-font-sidenav-secondary);
    max-width: 160px;
  }

  .keep-open .administrative {
    pointer-events: none;
  }

  aside {
    @include grid();
    background: var(--color-sidenav-base);
    padding: 0 spacing(2);
    position: relative;
    width: fit-content;
    min-width: 200px;
    min-height: calc(100% - (var(--scroll-overflow-calc) * 2));

    .nav {
      @include grid(spacing());
      @include grid-rows;
      height: min-content;
    }

    .sidenav-list {
      @include grid(4px);
      @include grid-rows;
    }

    .item-active-bkg {
      @include transition(top opacity, 400ms, cubic-bezier(0, 0, 0.53, 1.08));
      position: absolute;
      background: var(--color-sidenav-item-active);
      opacity: var(--color-sidenav-item-opacity);
      height: var(--size-action-height-base);
      width: 92%;
      left: 4%;
      z-index: 0;
      border-radius: var(--size-border-radius-base);

      &.is-collapsible {
        opacity: 0;

        &.open {
          opacity: var(--color-sidenav-item-opacity);
        }
      }
    }
  }

  .logo {
    padding-top: spacing();
    margin-bottom: spacing(1);
    max-width: 160px;
    a,
    svg {
      width: 100%;
      height: auto;
    }

    a {
      display: flex;
      flex-direction: column;
    }

    .release-group {
      align-self: flex-end;
      color: var(--color-sidenav-logo);
      size: var(--size-font-link-base);
      letter-spacing: calc(var(--size-font-link-base) / 6);
      font-weight: 500;
      position: relative;
      top: spacing(-1);
      text-transform: uppercase;
      margin-bottom: spacing(-1);
    }
  }

  .aws-info {
    height: min-content;
    align-self: end;
    margin-bottom: spacing(-3);
    > ul {
      border-radius: var(--size-border-radius-base);
      padding: 0 spacing();
      background-color: var(--color-structure-secondary);
      color: var(--color-font-sidenav-base);
      .item {
        grid-template-columns: minmax(0, 0.5fr) 1fr;
        gap: spacing(2);
        padding: spacing(0.5) 0;
        align-items: center;
      }
    }
  }

  @include breakpoint(small down) {
    overflow: hidden;

    .logo {
      opacity: 0;
      pointer-events: none;
    }
    aside {
      @include transition(transform, 400ms, ease-in-out);
      overflow-y: auto;
      overflow-x: hidden;
      grid-auto-rows: 1fr 1fr auto;
      position: absolute;
      top: 0;
      width: auto;
      height: 100%;
      transform: translateX(-220px);
      z-index: 1000;
      box-shadow: var(--size-shadow-medium) rgba(0, 0, 0, 0.9);

      &::after {
        content: '';
        height: 2.5rem;
        display: block;
      }

      .nav {
        height: min-content;
      }

      &.show {
        transform: translateX(0px);
      }
    }
  }

  &-mobile {
    height: 100%;
    display: none;
    align-items: center;

    svg {
      cursor: pointer;
      margin-left: spacing(2);
    }

    .jamf {
      display: flex;
      > svg {
        width: auto;
        max-width: 200px;
      }
    }

    @include breakpoint(small down) {
      display: flex;
    }
  }
}
</style>
