<template>
  <j-button data-feature-id="delete" style-type="ghost">
    <template #leading>
      <j-icon data="@jcon/trash.svg" />
    </template>
    <template v-if="label" #default>{{ label }}</template>
  </j-button>
</template>

<script>
export default {
  name: 'DeleteButton',
  compatConfig: {
    MODE: 3,
  },
  props: {
    label: {
      type: String,
      default: 'Delete',
    },
  },
};
</script>
