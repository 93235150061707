<template>
  <div class="g-rows">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Match Type">
      {{ event.matchType }}
    </ListItem>
    <ListItem title="Match Value">
      {{ event.matchValue }}
    </ListItem>
    <ListItem title="Process Path">
      {{ path }}
    </ListItem>
    <ListItem title="Code Signing Team ID">
      {{ teamId }}
    </ListItem>
    <ListItem title="Code Signing Signing ID">
      {{ signingId }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPPreventedExecutionEvent',
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  computed: {
    path() {
      return this.event?.process?.path || this.related?.processes?.[0]?.path;
    },
    teamId() {
      return (
        this.event.process?.codeSigning?.teamID ||
        this.related?.processes?.[0]?.signingInfo?.teamID
      );
    },
    signingId() {
      return (
        this.event.process?.codeSigning?.signingID ||
        this.related?.processes?.[0]?.signingInfo?.appid
      );
    },
  },
};
</script>
