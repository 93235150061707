import GET_ANALYTIC from './queries/getAnalytic.gql';
import GET_ANALYTIC_LITE from './queries/getAnalyticLite.gql';
import LIST_ANALYTIC_CATEGORIES from './queries/listAnalyticsCategories.gql';
import LIST_ANALYTIC_FILTER_OPTIONS from './queries/listAnalyticsFilterOptions.gql';

import LIST_ANALYTIC_TAGS from './queries/listAnalyticsTags.gql';
import CREATE_ANALYTIC from './mutations/createAnalytic.gql';
import UPDATE_ANALYTIC from './mutations/updateAnalytic.gql';
import DELETE_ANALYTIC from './mutations/deleteAnalytic.gql';
import UPDATE_INTERNAL_ANALYTIC from './mutations/updateInternalAnalytic.gql';
import LIST_ANALYTICS from './queries/listAnalytics.gql';
import LIST_ANALYTICS_LITE from './queries/listAnalyticsLite.gql';

export default {
  state: {
    analytics: [],
    analyticsNextToken: null,
    analytic: null,
  },
  mutations: {
    SET_ANALYTIC(state, payload) {
      state.analytic = payload;
    },
    SET_ANALYTICS(state, payload) {
      state.analytics = payload.items;
      state.analyticsNextToken = payload.nextToken;
      state.analyticsNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    // mutations
    async createAnalytic({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_ANALYTIC,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC', result.data.createAnalytic);
        return result.data.createAnalytic;
      }
      return result;
    },
    async updateInternalAnalytic({ dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_INTERNAL_ANALYTIC,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.updateInternalAnalytic;
      }
      return result;
    },
    async updateAnalytic({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ANALYTIC,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC', result.data.updateAnalytic);
        return result.data.updateAnalytic;
      }
      return result;
    },
    async deleteAnalytic({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_ANALYTIC,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC', result.data.deleteAnalytic);
        return result.data.deleteAnalytic;
      }
      return result;
    },
    // queries
    async getAnalytic({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ANALYTIC,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC', result.data.getAnalytic);
        return result.data.getAnalytic;
      }
      return result;
    },
    async getAnalyticLite({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ANALYTIC_LITE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC', result.data.getAnalytic);
        return result.data.getAnalytic;
      }
      return result;
    },
    async listAnalyticsCategories({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTIC_CATEGORIES,
          variables: {},
        },
        { root: true }
      );
      if (result) {
        return result.data.listAnalyticsCategories;
      }
      return result;
    },
    async listAnalyticsTags({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTIC_TAGS,
          variables: {},
        },
        { root: true }
      );
      if (result) {
        return result.data.listAnalyticsTags;
      }
      return result;
    },
    async listAnalyticsFilterOptions({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTIC_FILTER_OPTIONS,
          variables: {},
        },
        { root: true }
      );
      if (result?.data) {
        const { listAnalyticsTags: tags, listAnalyticsCategories: categories } =
          result.data;
        return { tags, categories };
      }
      return result;
    },
    async listAnalytics({ commit, dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTICS,
          variables: {},
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTICS', result.data.listAnalytics);
        return result.data.listAnalytics;
      }
      return result;
    },
    async listAnalyticsLite({ commit, dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTICS_LITE,
          variables: {},
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTICS', result.data.listAnalytics);
        return result.data.listAnalytics;
      }
      return result;
    },
  },
};
