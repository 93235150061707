<template>
  <div class="checkbox" @click="setCheckbox">
    <span v-if="checked" class="checked"></span>
    <span v-if="!checked && !indeterminate" class="unchecked"></span>
    <span v-if="indeterminate" class="indeterminate"></span>
  </div>
</template>

<script>
export default {
  name: 'CheckboxHeader',
  props: ['params'],
  data() {
    return {
      checked: false,
      indeterminate: false,
    };
  },
  mounted() {
    // listens for if filter or sort have changed
    this.params.api.addEventListener('modelUpdated', this.deselectOnChange);
    this.params.api.addEventListener(
      'paginationChanged',
      this.deselectOnChange
    );
    this.params.api.addEventListener('selectionChanged', this.setIndeterminate);
  },
  beforeUnmount() {
    this.params.api.removeEventListener('modelUpdated', this.deselectOnChange);
    this.params.api.removeEventListener(
      'paginationChanged',
      this.deselectOnChange
    );
    this.params.api.removeEventListener(
      'selectionChanged',
      this.setIndeterminate
    );
  },
  methods: {
    setCheckbox() {
      if (this.compareSelected()) {
        this.checked = false;
        this.indeterminate = false;
        this.params.api.forEachNode((node) => this.deselectAll(node));
      } else {
        this.checked = true;
        this.params.api.forEachNode((node, index) =>
          this.selectAll(node, index)
        );
      }
    },
    getCurrentPageRowSize() {
      const page = this.params.api.paginationGetCurrentPage() + 1;
      const pageSize = this.params.api.paginationGetPageSize();
      const current = (page - 1) * parseInt(pageSize, 10);
      const size =
        page === this.params.api.paginationGetTotalPages()
          ? this.params.api.getDisplayedRowCount()
          : page * parseInt(pageSize, 10);
      let unselectable = 0;
      this.params.api.forEachNode((node) => {
        if (!node.selectable) unselectable += 1;
      });
      return {
        size: size - unselectable,
        all: size,
        current,
        selectedCount: this.params.api.getSelectedRows().length,
      };
    },
    deselectOnChange() {
      this.checked = false;
      this.indeterminate = false;
      this.params.api.forEachNode((node) => {
        if (node.id && node.selected) this.deselectAll(node);
      });
    },
    selectAll(node, index) {
      const { all, current } = this.getCurrentPageRowSize();
      if (index >= current && index <= all - 1 && node.selectable) {
        node.setSelected(true);
      }
    },
    deselectAll(node) {
      node.setSelected(false);
    },
    compareSelected() {
      const { current, size, selectedCount } = this.getCurrentPageRowSize();
      return (
        selectedCount === size ||
        (current && selectedCount === current) ||
        selectedCount === size - current
      );
    },
    setIndeterminate() {
      const { size, selectedCount } = this.getCurrentPageRowSize();
      this.indeterminate = !(this.compareSelected() || selectedCount === 0);
      this.checked = selectedCount === size;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  width: var(--table-checkbox-size);
  height: var(--table-checkbox-size);
  display: flex;
  position: relative;
  cursor: pointer;

  > span {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid var(--color-border-base);
    border-radius: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
    left: 0;
  }
  &:hover > span {
    border-color: var(--color-table-primary);
  }

  .checked {
    border-color: var(--color-table-primary);
    background: var(--color-table-primary);
    @include check(var(--table-checkbox-size));
  }

  .indeterminate {
    @include indeterminate;
    @include change-background-and-border(var(--color-input-primary-base));
    &::after {
      height: 2px;
      width: spacing();
    }
  }
}
</style>
