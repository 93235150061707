<template>
  <j-modal v-model="computedValue">
    <template #header="{ alignClass, iconClass }">
      <section :class="alignClass" v-if="errorMessage">
        <j-icon
          :class="iconClass"
          data="@jcon/error.svg"
          color="var(--color-danger-base)"
        />
        <h1 class="h3">
          {{ errorMessage }}
        </h1>
      </section>
      <h1 class="h3" v-if="!(saved || savingException || errorMessage)">
        Create Exception Set
      </h1>
      <j-updated v-model="saved" :message="message" />
      <gp-loader :show-loader="savingException">
        Creating Exception Set
      </gp-loader>
    </template>
    <form id="new-exception" class="g-rows" @submit.prevent="handleSubmit">
      <j-input
        label="Name"
        v-model="exceptionSet.name"
        data-feature-id="name"
        :has-error="v$.exceptionSet.name.$error"
        :error-text="
          v$.exceptionSet.name.isUniqueName.$invalid
            ? v$.exceptionSet.name.isUniqueName.$message
            : 'Name is Required'
        "
      />
      <j-textarea
        label="Description"
        v-model="exceptionSet.description"
        :rows="3"
        data-feature-id="description"
      />
    </form>
    <template #actions>
      <j-button style-type="secondary" class="js-end" @click="close">
        Cancel
      </j-button>
      <j-button
        style-type="primary"
        class="js-end"
        data-feature-id="item-add"
        type="submit"
        form="new-exception"
        :is-disabled="savingException || saved"
      >
        Save
      </j-button>
    </template>
  </j-modal>
</template>

<script>
import { required } from '@vuelidate/validators';
import { isUniqueName } from '@/util/custom-validators';
import { useForm } from '@/composables/forms';

export default {
  name: 'NewAlertExceptionSet',
  props: {
    modelValue: Boolean,
    exceptionSets: Object,
  },
  setup() {
    const { v$, handleSubmit, mode } = useForm({
      $stopPropagation: true,
    });
    return { v$, handleSubmit, mode };
  },
  emits: ['saved', 'update:modelValue'],
  data() {
    return {
      errorMessage: '',
      exceptionSet: {
        name: '',
        description: '',
      },
      saved: false,
      message: '',
      savingException: false,
    };
  },
  validations() {
    return {
      exceptionSet: {
        name: {
          required,
          isUniqueName: isUniqueName(this.exceptionSet.name),
        },
      },
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    duplicateNames() {
      return this.exceptionSets?.items.map(({ name }) => name);
    },
  },
  methods: {
    close() {
      this.v$.$reset();
      this.exceptionSet = {
        name: '',
        description: '',
      };
      this.errorMessage = null;
      this.$emit('update:modelValue', false);
    },
    async submit() {
      this.errorMessage = null;
      this.savingException = true;
      const result = await this.$store.dispatch('primary/createExceptionSet', {
        ...this.exceptionSet,
        exceptions: [],
      });

      if (result) {
        this.saved = true;
        this.message = 'Exception Set Created!';
        setTimeout(() => {
          this.$emit('saved', result);
          this.saved = false;
          this.close();
        }, 2000);
      } else {
        this.errorMessage = 'Error while attempting to create!';
      }
      this.savingException = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  @include grid;
  @include grid-columns(max-content);
  align-items: center;
}
</style>
