<template>
  <BaseFilterStyle
    :include-operators="hasActiveFilter"
    :primary-action="apply"
    :secondary-action="clear"
    :disable-action="!value"
  >
    <template #options>
      <j-select
        v-if="hasOptions"
        v-model="type"
        :class="['table-filter-select', 'table-filter-' + filterType]"
        :options="typeOptions"
        :searchable="false"
      />
    </template>
    <template #operators>
      <j-radio
        v-for="(op, key) in operators"
        :key="key"
        v-model="operator"
        :native-value="op"
        name="table-filter-value-options"
        >{{ op }}</j-radio
      >
    </template>
    <SeverityLevelSelect
      v-if="params.isSeverity"
      v-model="value"
      label=" "
      :is-small="false"
    />
    <j-select
      v-else
      v-model="value"
      class="table-filter-select table-filter-value"
      :placeholder="placeholder"
      :options="valueOptions"
      :searchable="valueOptions.length > 10"
    />
  </BaseFilterStyle>
</template>

<script>
import filterMixin from '@/components/table/framework-components/filters/filter.mixin';
import { TEXT_TYPE_OPTIONS_SIMPLE } from './filter.options';
import { FILTER_TYPES } from '../../utils/filter.types';
import SeverityLevelSelect from '@/components/SeverityLevelSelect.vue';

export default {
  name: 'TableFilterValueOptions',
  mixins: [filterMixin],
  components: { SeverityLevelSelect },
  props: ['params'],
  data() {
    return {
      valueOptions: [],
      typeOptions: TEXT_TYPE_OPTIONS_SIMPLE,
      defaultOption: FILTER_TYPES.EQUALS,
    };
  },
  created() {
    this.setParamOptions();
    this.valueOptions = this.params.valueOptions || this.valueOptions;
    if (!this.defaultValue) {
      this.value =
        typeof this.valueOptions?.[0] === 'string'
          ? this.valueOptions[0]
          : this.valueOptions[0]?.value;
    }
  },
  methods: {
    hasSome(filter, value) {
      return value?.some(
        (element) => element.toLowerCase() === filter.toLowerCase()
      );
    },
    // Used for client side filtering
    doesFilterPass({ node }) {
      const value = this.params.valueGetter({ node });
      if (this.filterType === 'array' && Array.isArray(value)) {
        return (
          this.hasSome(this.model.filter, value) &&
          (this.model?.and.length
            ? this.model.and
                .map(({ filter }) => this.hasSome(filter, value))
                .filter((el) => el).length === this.model.and.length
            : true)
        );
      }
      return (
        value.toString().toLowerCase() === this.model?.filter.toLowerCase()
      );
    },
  },
};
</script>
