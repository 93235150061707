import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import setAuthorizationHeaderLink from './auth-header-link';
import { SentryLink } from 'apollo-link-sentry';
import removeTypenameLink from './remove-typename-link';

const link = (uri, isAdmin) =>
  ApolloLink.from([
    new SentryLink({
      setTransaction: false,
      attachBreadcrumbs: {
        includeError: true,
        includeQuery: true,
        includeContext: ['headers'],
      },
    }),
    new ApolloLink(removeTypenameLink),
    setContext(setAuthorizationHeaderLink(isAdmin)),
    new HttpLink({
      uri,
    }),
  ]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

const clientOptions = (uri, isAdmin) => ({
  link: link(uri, isAdmin),
  cache: new InMemoryCache(),
  defaultOptions,
});

// Create client and pass in appropriate uri
export const client = (uri, isAdmin) =>
  new ApolloClient(clientOptions(uri, isAdmin));
