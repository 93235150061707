import UPDATE_ROLE from './mutations/updateRole.gql';
import CREATE_ROLE from './mutations/createRole.gql';
import DELETE_ROLE from './mutations/deleteRole.gql';
import {
  getGroup as GET_GROUP,
  listGroups as LIST_GROUPS,
  getUser as GET_USER,
  listUsers as LIST_USERS,
  getRole as GET_ROLE,
  listRoles as LIST_ROLES,
  listConnections as LIST_CONNECTIONS,
} from './account.queries.gql';
import {
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  UPDATE_CONNECTION,
  UPDATE_FREEZE_CONTROL,
} from './account.mutations';

const updateList = (type, state, payload) => {
  const existing = state[type]?.findIndex(({ id }) => id === payload.id);
  if (existing !== -1) {
    state[type]?.splice(existing, 1, payload);
  } else {
    state[type]?.push(payload);
  }
};

export default {
  state: {
    roles: null,
    rolesNames: [],
    role: null,
    connections: null,
    groups: null,
    groupsNames: [],
    group: null,
    users: null,
    usersNames: [],
    user: null,
  },
  mutations: {
    SET_ROLES(state, payload) {
      state.roles = payload;
      state.rolesNames = payload.map(({ name }) => name);
    },
    SET_ROLE(state, payload) {
      state.role = payload;
      updateList('roles', state, payload);
    },
    SET_USERS(state, payload) {
      state.users = payload;
      state.usersNames = payload.map(({ email }) => email);
    },
    SET_USER(state, payload) {
      state.user = payload;
      updateList('users', state, payload);
    },
    SET_GROUPS(state, payload) {
      state.groups = payload;
      state.groupsNames = payload.map(({ name }) => name);
    },
    SET_GROUP(state, payload) {
      state.group = payload;
      updateList('groups', state, payload);
    },
    SET_CONNECTIONS(state, payload) {
      state.connections = payload;
    },
    SET_CONNECTION(state, payload) {
      updateList('connections', state, payload);
    },
  },
  actions: {
    // mutations
    async updateGroup({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_GROUP,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_GROUP', result.data.updateGroup);
        return result.data.updateGroup;
      }
      return result;
    },
    async createGroup({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_GROUP,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_GROUP', result.data.createGroup);
        return result.data.createGroup;
      }
      return result;
    },
    async deleteGroup({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_GROUP,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_GROUP', result.data.deleteGroup);
        return result.data.deleteGroup;
      }
      return result;
    },
    async updateUser({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_USER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_USER', result.data.updateUser);
        return result.data.updateUser;
      }
      return result;
    },
    async createUser({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_USER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_USER', result.data.createUser);
        return result.data.createUser;
      }
      return result;
    },
    async deleteUser({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_USER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_USER', result.data.deleteUser);
        return result.data.deleteUser;
      }
      return result;
    },
    async updateRole({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ROLE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_ROLE', result.data.updateRole);
        return result.data.updateRole;
      }
      return result;
    },
    async createRole({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_ROLE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_ROLE', result.data.createRole);
        return result.data.createRole;
      }
      return result;
    },
    async deleteRole({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_ROLE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_ROLE', result.data.deleteRole);
        return result.data.deleteRole;
      }
      return result;
    },
    // queries
    async listUsers({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_USERS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_USERS', result.data.listUsers.items);
        return result.data.listUsers;
      }
      return result;
    },
    async listGroups({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_GROUPS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_GROUPS', result.data.listGroups.items);
        return result.data.listGroups;
      }
      return result;
    },
    async listRoles({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ROLES,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_ROLES', result.data.listRoles.items);
        return result.data.listRoles;
      }
      return result;
    },
    async listConnections({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_CONNECTIONS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_CONNECTIONS', result.data.listConnections.items);
        return result.data.listConnections;
      }
      return result;
    },
    async getUser({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_USER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_USER', result.data.getUser);
        return result.data.getUser;
      }
      return result;
    },
    async getGroup({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_GROUP,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_GROUP', result.data.getGroup);
        return result.data.getGroup;
      }
      return result;
    },
    async getRole({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ROLE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_ROLE', result.data.getRole);
        return result.data.getRole;
      }
      return result;
    },
    async updateConnection({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_CONNECTION,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit('SET_CONNECTION', result.data.updateConnection);
        return result.data.updateConnection;
      }
      return result;
    },
    async updateOrganizationConfigFreeze({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_FREEZE_CONTROL,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        await commit(
          'SET_CONFIG_FREEZE',
          result.data.updateOrganizationConfigFreeze.configFreeze,
          { root: true }
        );
        return result.data.updateOrganizationConfigFreeze;
      }
      return result;
    },
  },
};
