<template>
  <span class="j-table-tags">
    <template v-for="(tag, index) in tags" :key="tag + index">
      <j-tag
        v-if="type.includes(typeof tag)"
        class="ml-1 j-table-tag"
        :class="{ 'greater-then-1': tags.length > 1 }"
        :style-type="params.type"
        >{{ tag }}</j-tag
      >
      <j-tag
        v-else
        :key="tag.value + index"
        class="ml-1 j-table-tag"
        :class="{ 'greater-than-1': tags.length > 1 }"
        :style-type="tag.type"
        :to="tagTo(tag)"
      >
        {{ tag.value }}
      </j-tag>
    </template>
  </span>
</template>

<script>
export default {
  name: 'TableCellTags',
  props: ['params'],
  data() {
    return {
      tags: [],
      type: ['string', 'number'],
    };
  },
  mounted() {
    const { value, valueFormatted } = this.params;
    if (valueFormatted && Array.isArray(valueFormatted)) {
      this.tags = valueFormatted;
    } else if (this.type.includes(typeof value)) {
      if (value !== '') this.tags.push(value);
    } else {
      this.tags = value;
    }
  },
  methods: {
    tagTo(tag) {
      return tag ? this.params?.route?.(tag) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.j-table {
  &-tags {
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    border-radius: 4px;
  }

  &-tag {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px;
    // ensures no matter the length of the tag the ellipsis will still be visible to indicate more
    &.greater-than-1 {
      max-width: calc(100% - 24px);
    }
  }
}
</style>
