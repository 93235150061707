<template>
  <JamfTable
    :get-data="() => data"
    suppress-filter-bar
    :page-size="500"
    :options="{
      noRowsOverlayComponent: 'NoRowsAddMappingOverlay',
      noRowsOverlayComponentParams: { create, canWrite },
    }"
    suppress-refresh-button
    suppress-search
    :view="view"
    :columns="columns"
  >
    <template #left="{ refresh }">
      <j-create-button
        v-if="canWrite && !notTableView"
        @click="create"
        :label="`Add ${areaTitle}`"
      />
      <p v-if="!notTableView">
        Map groups from your identity provider to Jamf Protect for user role
        assignment.
      </p>
      <j-form-bar
        v-if="notTableView && internalMapping"
        @back="() => back(refresh)"
        :title="mode.create ? `Create ${areaTitle}` : internalMapping.name"
        @submit:created="() => back(refresh)"
        @submit="save"
        :can-write="canWrite"
      />

      <j-modal v-model="showWarning" type="warning">
        <strong>You are not a member of this access group.</strong>
        <p>
          If you are not a member of any access groups, you may become locked
          out of your Jamf Protect tenant. Are you sure you want to save this
          access group?
        </p>
        <template #header="{ alignClass, iconClass }">
          <section :class="alignClass">
            <j-icon data="@jcon/warning.svg" :class="iconClass" />
            <h1 class="h3">Are you sure?</h1>
          </section>
        </template>
        <template #actions>
          <j-button style-type="secondary" @click="showWarning = false">
            Cancel
          </j-button>
          <j-button @click="submit"> Save </j-button>
        </template>
      </j-modal>
    </template>
    <template v-if="canWrite && notTableView" #right>
      <j-delete-button
        v-if="!mode.create"
        @click="showDeleteWarning = true"
        :label="`Delete ${areaTitle}`"
      />
    </template>
    <template #modals="{ refresh }">
      <j-delete-modal
        v-model="showDeleteWarning"
        :to-delete="internalMapping.name"
        :title="`Delete ${areaTitle}`"
        @confirm="confirmDelete(refresh)"
      />
    </template>
    <template v-if="internalMapping && !loading" #default>
      <div class="scroll-vertical">
        <div class="form-content">
          <j-input
            label="Identity Provider Group Name"
            data-feature-id="name"
            :is-read-only="!canWrite"
            :has-error="v$.internalMapping.name.$error"
            error-text="Identity Provider Group Name is required"
            helper-text="The name of a group in your cloud identitity provider"
            v-model="internalMapping.name"
          />
          <j-select
            v-if="roleOptions"
            v-model="internalMapping.assignedRoles"
            label="Roles"
            helper-text="Roles to assign users in the mapped group name from your identity provider"
            :options="roleOptions"
            :placeholder="
              !internalMapping.assignedRoles.length && !canWrite
                ? 'N/A'
                : 'Add Role'
            "
            allow-multi-select
            :is-read-only="!canWrite"
            :searchable="isSearchable(roleOptions)"
          />
          <hr class="m-0" />
          <j-checkbox
            :is-read-only="!canWrite"
            v-model="internalMapping.accessGroup"
          >
            Use as Access Group
          </j-checkbox>
          <transition name="fade" mode="out-in">
            <div
              v-if="internalMapping.accessGroup"
              class="access-group-warning"
            >
              <j-icon
                data="@jcon/warning.svg"
                color="var(--color-warning-base)"
              />
              When enabled, only users in an access group can access your Jamf
              Protect tenant. Make sure you are a member of this group in your
              IdP and that the group name exists in a token received by Jamf
              Protect.
            </div>
          </transition>
        </div>
      </div>
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { mapGetters, mapState } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import NoRowsAddMappingOverlay from './NoRowsAddMappingOverlay.vue';
import { required } from '@vuelidate/validators';
import { useAccount } from '../composable/account';

export default {
  name: 'AccountIdPs',
  // eslint-disable-next-line vue/no-unused-components
  components: { JamfTable, NoRowsAddMappingOverlay },
  props: {
    data: Object,
    connection: Object,
  },
  setup() {
    const {
      mode,
      canAccess,
      canWrite,
      roleOptions,
      getRoles,
      handleSubmit,
      v$,
      isSearchable,
    } = useAccount([RBAC_RESOURCE.GROUP, RBAC_RESOURCE.CONNECTION]);
    return {
      mode,
      canAccess,
      canWrite,
      roleOptions,
      getRoles,
      handleSubmit,
      v$,
      isSearchable,
    };
  },
  validations: {
    internalMapping: {
      name: {
        required,
      },
    },
  },
  data() {
    return {
      areaTitle: 'Mapping',
      routeToItem: null,
      routeToCreate: null,
      endpointCreate: 'primary/createGroup',
      endpointUpdate: 'primary/updateGroup',
      showWarning: false,
      showDeleteWarning: false,
      loading: true,
      internalMapping: {
        name: '',
        assignedRoles: [],
        connection: null,
        accessGroup: false,
      },
      view: 'table',
      newItem: {},
      columns: [
        {
          headerName: 'Identity Provider Group Name',
          field: 'name',
          sort: 'asc',
          cellClass: 'is-link',
          onCellClicked: async (params) => this.viewItem(params),
        },
        {
          headerName: 'Role(s)',
          field: 'assignedRoles',
          type: 'tagsColumn',
          minWidth: 160,
          permissions: [RBAC_RESOURCE.ROLE],
          valueFormatter: ({ value }) => value?.map(({ name }) => name) || [],
        },
        {
          headerName: 'Use as Access Group',
          field: 'accessGroup',
          valueGetter: ({ data }) => (data?.accessGroup ? 'Yes' : 'No'),
        },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
        },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['namespace']),
    ...mapGetters('auth', ['identity']),
    notTableView() {
      return this.view !== 'table';
    },
    localUserGroups() {
      return this.identity[`${this.namespace}/user`].groups || [];
    },
    localConnectionMatch() {
      return (
        this.identity[`${this.namespace}/connection`] === this.connection.name
      );
    },
    payload() {
      return {
        ...this.internalMapping,
        roleIds: this.internalMapping.assignedRoles,
        connectionId: this.internalMapping.connection,
      };
    },
  },
  async beforeMount() {
    await this.getRoles();
  },
  methods: {
    async create() {
      this.v$.$reset();
      this.name = '';
      this.message = '';
      this.internalMapping = {
        name: '',
        accessGroup: false,
        connection: this.connection.id,
        assignedRoles: [],
      };
      this.mode.create = true;
      this.view = 'item';
      this.loading = false;
    },
    async viewItem(params) {
      this.loading = true;
      this.mode.create = false;
      this.message = '';
      this.internalMapping = {
        ...params.data,
        assignedRoles: params.data?.assignedRoles?.map(({ id }) => id) || [],
      };
      this.view = 'item';
      this.loading = false;
    },
    async back(refresh) {
      this.loading = true;
      this.view = 'table';
      await this.$store.dispatch('primary/listGroups', {
        direction: 'ASC',
        field: 'name',
      });
      refresh();
    },
    async delete(refresh) {
      this.loading = true;
      try {
        await this.$store.dispatch('primary/deleteGroup', {
          id: this.internalMapping.id,
        });
        this.back(refresh);
      } catch {
        this.message = `Error Deleting ${this.areaTitle}`;
      }
      this.loading = false;
    },
    confirmDelete(refresh) {
      this.delete(refresh);
      this.showDeleteWarning = false;
    },
    async submit() {
      this.showWarning = false;
      await this.$store.dispatch(
        this.mode.create ? this.endpointCreate : this.endpointUpdate,
        this.payload
      );
    },
    async save() {
      if (
        this.localConnectionMatch &&
        !this.localUserGroups.includes(this.internalMapping.name) &&
        this.internalMapping.accessGroup
      ) {
        this.showWarning = true;
      } else {
        await this.handleSubmit();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.access-group-warning {
  @include grid(spacing());
  @include grid-columns(max-content auto);
  border-left: 1px solid var(--color-border-base);
  margin-left: spacing(1.5);
  padding-left: spacing(1.5);
  align-items: flex-start;

  svg {
    margin-top: 3px;
  }
}
.j-table-wrapper {
  --color-table-base: var(--color-card-primary);
  --color-table-border: var(--color-border-base);
  --table-nav-height: 0px;
  --table-height: var(--table-wrapper-height);
  --size-global-banner-height: 0px;
  border: 1px solid var(--color-table-border);
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
}

.form-control {
  --size-form-control-bar-height: 100%;
  padding: 0px;
}
</style>
