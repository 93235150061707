<template>
  <div class="g-rows">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Path">
      {{ event.path }}
    </ListItem>
    <div v-if="related.files" class="g-rows">
      <Card v-for="files in related.files" :key="files.sha1hex" class="g-rows">
        <ListItem title="Downloaded From">
          <div class="g-rows">
            <span
              v-for="downloadedFrom in files.downloadedFrom"
              :key="downloadedFrom"
            >
              {{ downloadedFrom }}
            </span>
          </div>
        </ListItem>
        <ListItem title="sha1 Hex">
          <pre class="w-max-c">{{ files.sha1hex }}</pre>
        </ListItem>
      </Card>
    </div>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';
import Card from '@/components/Card.vue';

export default {
  name: 'GPDownloadEvent',
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  inject: ['$typeDefs'],
  components: { ListItem, Card },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      def: this.$typeDefs.events.GPDownloadEvent,
    };
  },
};
</script>
