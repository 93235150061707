<template>
  <BaseFilterStyle
    :include-operators="hasActiveFilter"
    :primary-action="apply"
    :secondary-action="clear"
    :disable-action="!value"
  >
    <template #options>
      <j-select
        v-if="hasOptions"
        v-model="type"
        :class="['table-filter-select', 'table-filter-' + filterType]"
        :options="typeOptions"
        :searchable="false"
      />
    </template>
    <template #operators>
      <j-radio
        v-for="(op, key) in operators"
        :key="key"
        v-model="operator"
        :native-value="op"
        name="table-filter-int"
        >{{ op }}</j-radio
      >
    </template>
    <j-input
      ref="input"
      data-testid="filter-input-value"
      v-model="value"
      :placeholder="placeholder"
      @keydown.enter.stop="apply"
      :type="inputType"
    />
  </BaseFilterStyle>
</template>

<script>
import { FILTER_TYPES } from '../../utils/filter.types';
import { INT_TYPE_OPTIONS } from './filter.options';
import filterMixin from './filter.mixin';

export default {
  name: 'TableFilterInt',
  mixins: [filterMixin],
  data() {
    return {
      filterType: 'int',
      typeOptions: INT_TYPE_OPTIONS,
      defaultOption: FILTER_TYPES.EQUALS,
    };
  },
};
</script>
