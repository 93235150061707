<template>
  <EmptyState :allow-events="allowEvents" class="no-results-overlay">
    <h3>{{ title }}</h3>
    <span>{{ message }}</span>
    <slot></slot>
  </EmptyState>
</template>

<script>
import EmptyState from './EmptyState.vue';
export default {
  name: 'NoResultsOverlay',
  props: {
    message: {
      type: String,
      default: "Try adjusting the filters to find what you're looking for.",
    },
    title: {
      type: String,
      default: 'No Results.',
    },
    allowEvents: Boolean,
  },
  components: { EmptyState },
};
</script>

<style lang="scss" scoped>
.no-results-overlay {
  h3 {
    font-size: 24px;
    font-weight: 500;
  }

  &.allow-events {
    pointer-events: all;
  }
}
</style>
