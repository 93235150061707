<template>
  <NoResultsOverlay message="" title=""><gp-loader /></NoResultsOverlay>
</template>

<script>
import NoResultsOverlay from '@/components/NoResultsOverlay.vue';

export default {
  name: 'LoaderOverlay',
  components: { NoResultsOverlay },
};
</script>
