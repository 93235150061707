<template>
  <div v-if="alert" class="scroll-vertical alert-details">
    <div class="g-rows">
      <Markdown v-if="description" :text="description" />
      <div class="g-rows">
        <ListItem v-if="alert.computer" title="Host IP">
          {{ alert.parsed.host.ips.join(', ') }}
        </ListItem>
        <ListItem v-if="alert.parsed.match.tags.length >= 1" title="Tags">
          <div class="tags">
            <j-tag v-for="tag in alert.parsed.match.tags" :key="tag">
              {{ getKeyNameMatch(tag) }}
            </j-tag>
          </div>
        </ListItem>
        <ListItem
          v-for="item in alert.parsed.match.context"
          :key="item.name"
          :title="item.name"
        >
          {{ item.value }}
        </ListItem>
      </div>
      <hr />
      <EventDetails
        :event="alert.parsed.match.event"
        :related="alert.parsed.related"
        :type="alert.parsed.eventType"
      />
      <AlertProcess
        v-if="relatedEventProcess"
        :process="relatedEventProcess"
        :related="related"
        :exclude_fields="['Pid']"
      />
    </div>
  </div>
</template>

<script>
import EventDetails from '@/modules/alerts/components/events/EventDetails.vue';
import AlertProcess from '@/modules/alerts/components/AlertProcess.vue';
import ListItem from '@/components/ListItem.vue';
import Markdown from '@/components/Markdown.vue';
import { getKeyNameMatch } from '@/util';
import { mapState } from 'vuex';
import { useAlerts } from '@/modules/alerts/alerts.js';

export default {
  name: 'AlertDetails',
  components: {
    EventDetails,
    AlertProcess,
    ListItem,
    Markdown,
  },
  compatConfig: {
    MODE: 3,
  },
  setup(props) {
    const { getName, relatedProcessObject } = useAlerts(props);

    return { getName, relatedProcessObject };
  },
  props: {
    alert: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('primary', { analytics: (state) => state.analytics.analytics }),
    name() {
      return this.getName(this.alert);
    },
    relatedEventProcess() {
      if (this.alert.parsed.eventType === 'GPProcessEvent') {
        return this.relatedProcessObject(this.alert.parsed.match.event.pid);
      }
      return null;
    },
    description() {
      const description = this.alert.parsed?.match?.facts?.map(
        ({ uuid, human, name }) =>
          this.analytics.find(
            (analytic) => analytic.uuid.toLowerCase() === uuid.toLowerCase()
          ) || {
            description: human,
            name,
          }
      );

      return this.separateDescriptions(description);
    },
  },
  methods: {
    getKeyNameMatch,
    separateDescriptions(description) {
      let descriptionText = [];
      description.forEach((value) => {
        const remediationText = value.remediation
          ? `| Remediation: |\n| ---- |\n|${value.remediation}|`
          : '';
        descriptionText.push(`#### ${value.name} 
        \n${value.longDescription || value.description || ''}\n
${remediationText}`);
      });

      return descriptionText.join('\n');
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-details {
  padding: spacing(2);
}
</style>
