<template>
  <PanelBrowser title="Event and Type Documentation" v-model:search="search">
    <a
      v-for="item in displayList"
      :key="item.type"
      class="p-1 panel-button"
      :class="{ 'is-active': item.label === selected }"
      @click="() => selectDef(item.label)"
    >
      <span class="g-cols max-c ai-center">
        <AnalyticEventTypeIcon v-if="item.icon" :input-type="item.type" />
        <j-icon v-else data="@icon/objects.svg" />
        <span>{{ item.label }}</span>
      </span>
    </a>
    <template #filter-tabs>
      <a
        @click="filter = null"
        :class="{ active: filter === null }"
        class="tabs"
        >All</a
      >
      <a
        @click="filter = 'events'"
        :class="{ active: filter === 'events' }"
        class="tabs"
        >Events</a
      >
      <a
        @click="filter = 'types'"
        :class="{ active: filter === 'types' }"
        class="tabs"
        >Types</a
      >
    </template>
    <template #right>
      <EventDefinitionDetails :def="currentDef" />
    </template>
  </PanelBrowser>
</template>

<script>
import { findIndex } from 'lodash';
import AnalyticEventTypeIcon from '@/components/analytics/AnalyticEventTypeIcon.vue';
import PanelBrowser from '../components/PanelBrowser.vue';
import EventDefinitionDetails from '../components/EventDefinitionDetails.vue';

export default {
  name: 'DataModelDocumentation',
  components: { EventDefinitionDetails, PanelBrowser, AnalyticEventTypeIcon },
  inject: ['$typeDefs'],
  data() {
    return {
      filter: null,
      search: '',
      currentDef: this.$typeDefs.events.GPFSEvent,
      selected: this.$typeDefs.events.GPFSEvent.label,
    };
  },
  computed: {
    filteredList() {
      if (this.filter === null) {
        return this.eventsList.concat(this.typesList);
      }
      if (this.filter === 'events') {
        return this.eventsList;
      }
      return this.typesList;
    },
    displayList() {
      if (this.search.length >= 0) {
        return this.filteredList.filter((item) =>
          item.label.toLowerCase().startsWith(this.search)
        );
      }
      return this.filteredList;
    },
    eventsList() {
      return Object.values(this.$typeDefs.events).filter(
        ({ type }) =>
          !['GPThreatMatchExecEvent', 'GPPreventedExecutionEvent'].includes(
            type
          )
      );
    },
    typesList() {
      return Object.values(this.$typeDefs.types);
    },
  },
  methods: {
    selectDef(labelValue) {
      this.selected = labelValue;
      const index = findIndex(this.displayList, { label: labelValue });
      this.currentDef = this.displayList[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: var(--color-font-base);
}

.panel-button {
  color: var(--color-font-base);
  border-bottom: 1px solid var(--color-border-secondary);
  cursor: pointer;
  display: flex;
  &:hover,
  &.is-active {
    background-color: var(--color-structure-highlight-base);
  }
}

.tabs {
  @include tab;
  &:hover {
    text-decoration: none;
  }
}
</style>
