<template>
  <div class="g-rows">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Product Name">
      {{ event.device.productName }}
    </ListItem>
    <ListItem title="Vendor Name">
      {{ event.device.vendorName }}
    </ListItem>
    <ListItem title="Serial">
      {{ event.device.serialNumber }}
    </ListItem>
    <ListItem title="Removable">
      {{ event.device.removable }}
    </ListItem>
    <ListItem title="Writable">
      {{ event.device.writable }}
    </ListItem>
    <ListItem title="Class">
      {{ event.device.deviceClass }} / {{ event.device.deviceSubClass }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPUSBEvent',
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
