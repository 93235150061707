import Section from '@/components/Section.vue';
import Actions from '@/modules/actions/Actions.vue';
import ActionForm from '@/modules/actions/ActionForm.vue';
import Action from '@/modules/actions/Action.vue';
import ActionDetails from '@/modules/actions/ActionDetails.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default [
  {
    path: '/actions',
    component: Section,
    name: 'actions',
    meta: {
      breadcrumb: {
        linkName: 'actions.index',
        linkText: 'Actions',
      },
      permissions: [RBAC_RESOURCE.ACTION_CONFIGS],
    },
    children: [
      {
        path: '',
        component: Actions,
        name: 'actions.index',
      },
      {
        path: '',
        component: Action,
        name: 'actions.index.update',
        children: [
          {
            path: 'create',
            component: ActionForm,
            name: 'actions.index.create',
            meta: {
              breadcrumb: {
                linkText: 'Create',
              },
              requireWrite: true,
            },
          },
        ],
      },
      {
        path: ':id',
        component: Action,
        meta: {
          breadcrumb: {
            linkName: 'actions.index.detail',
            linkText: 'Action Summary',
          },
        },
        children: [
          {
            path: '',
            name: 'actions.index.detail',
            component: ActionDetails,
          },
          {
            path: 'edit',
            name: 'actions.index.edit',
            component: ActionForm,
            meta: {
              requireWrite: true,
            },
          },
        ],
      },
    ],
  },
];
