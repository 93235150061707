<template>
  <JamfTable
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
  >
    <template #left v-if="canWrite">
      <j-create-button
        @click="$router.push({ name: 'telemetry.index.create' })"
        label="Create Telemetry"
      />
    </template>
    <template #right="{ selected }">
      <j-delete-button
        v-if="canWrite"
        @click="confirmRemoveMultiple(selected)"
        :is-disabled="selected.length === 0"
        :label="`Delete (${selected.length})`"
      />
    </template>
    <template #modals="{ refresh, selected }">
      <j-delete-modal
        v-model="showRemoveModal"
        :to-delete="toDelete"
        :blockers="selectedPlans"
        @confirm="remove(selected, refresh)"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { associatedPlansColumn } from '@/components/table/utils/column.types';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { success, dashIcon } from '@/util/constants/feedback.icons';
import { mapGetters } from 'vuex';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'Telemetries',
  components: {
    JamfTable,
  },
  setup() {
    const { showRemoveModal, toDelete, selectedPlans, confirmRemoveMultiple } =
      useRemoveHelpers();
    const { canWrite } = useRBAC(RBAC_RESOURCE.TELEMETRY);
    return {
      showRemoveModal,
      toDelete,
      selectedPlans,
      confirmRemoveMultiple,
      canWrite,
    };
  },
  data() {
    return {
      endpoint: 'primary/listTelemetries',
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          type: 'linkColumn',
          sortable: true,
          flex: 1,
          minWidth: 200,
          cellRendererParams: {
            route: (params) => ({
              name: 'telemetry.index.detail',
              params: { id: params.data?.id },
            }),
          },
        },
        {
          headerName: 'Description',
          field: 'description',
        },
        {
          headerName: 'Level',
          field: 'level',
        },
        {
          headerName: 'Collect Diagnostic and Crash Files',
          field: 'logFileCollection',
          minWidth: 160,
          width: 160,
          maxWidth: 160,
          type: ['iconColumn'],
          flag: 'TELEMETRY_EO3',
          cellRendererParams: {
            setIcon: (value) =>
              value
                ? { icon: success, color: 'var(--color-success-base)' }
                : { icon: dashIcon, color: 'var(--color-font-base)' },
          },
        },
        {
          headerName: 'Performance Metrics',
          field: 'performanceMetrics',
          minWidth: 160,
          width: 160,
          maxWidth: 160,
          type: ['iconColumn'],
          flag: 'TELEMETRY_EO3',
          cellRendererParams: {
            setIcon: (value) =>
              value
                ? { icon: success, color: 'var(--color-success-base)' }
                : { icon: dashIcon, color: 'var(--color-font-base)' },
          },
        },
        {
          headerName: 'Verbose',
          field: 'verbose',
          maxWidth: 100,
          valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
        },
        { ...associatedPlansColumn },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sortable: true,
          sort: 'asc',
        },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
  },
  created() {
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
  },
  methods: {
    async getData(params) {
      return this.$store.dispatch(this.endpoint, params);
    },
    async remove(selected, refresh) {
      this.loading = true;
      this.showWarning = false;
      await Promise.all(
        selected.map(({ id }) =>
          this.$store.dispatch('primary/deleteTelemetry', { id })
        )
      );
      refresh();
    },
  },
};
</script>
