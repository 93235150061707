<template>
  <div class="full-height">
    <j-form-bar>
      <j-button @click="generateToken()"
        ><template #leading><j-icon data="@jcon/binary-code.svg" /></template
        >Generate Token</j-button
      >
      <j-button
        @click="copyTokenToClipboard()"
        class="p-0"
        style-type="ghost-primary"
        ><template #leading><j-icon data="@jcon/clipboard-file.svg" /></template
      ></j-button>
      <j-switch v-model="xpc">XPC</j-switch>
      <j-select
        v-model="logLevel"
        :options="logLevelOptions"
        is-small
        :searchable="false"
      />
      <j-select
        v-if="organizations"
        v-model="org"
        :options="organizations"
        :searchable="organizations.length > 10"
        is-small
      />
    </j-form-bar>
    <div class="token-container">
      <div class="raw-token">
        <pre>{{ tokenText }}</pre>
      </div>
      <div class="parsed-token">
        <TextEditor
          :text="jsonText"
          :line-numbers="false"
          :read-only="true"
          class="editor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import TextEditor from '@/components/TextEditor.vue';

export default {
  name: 'GenerateToken',
  components: {
    TextEditor,
  },
  data() {
    return {
      token: null,
      tokenText: '',
      xpc: false,
      logLevel: 'Error',
      logLevelOptions: ['Error', 'Warning', 'Info', 'Debug', 'Verbose'],
      config: null,
      organizations: null,
      org: '',
    };
  },
  computed: {
    jsonText() {
      if (this.token) {
        return JSON.stringify(this.token, null, 2);
      }
      return '';
    },
  },
  async mounted() {
    const orgs = await this.$store.dispatch('admin/listOrganizationsLite', {
      nexToken: null,
      pageSize: 100,
      direction: 'DESC',
      field: 'created',
      filter: null,
    });
    this.organizations = orgs.items.map(({ hd }) => ({
      label: hd,
      value: hd,
    }));
  },
  methods: {
    async generateToken() {
      const token = await this.$store.dispatch('admin/getToken', {
        hd: this.org,
        xpc: this.xpc,
        logLevel: this.logLevel.toUpperCase(),
        config: this.config,
      });
      this.tokenText = token.token;
      this.token = jwtDecode(this.tokenText);
    },
    copyTokenToClipboard() {
      this.$clipboard(this.tokenText);
      this.$store.commit('SET_GLOBAL_INFO_MESSAGE', {
        message: 'Token copied to clipboard',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.cke_reset_all .CodeMirror-scroll) * {
  white-space: pre-wrap;
}

.full-height {
  height: 100%;
}

.editor {
  width: 100%;
  height: 100%;
}

.token-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.raw-token,
.parsed-token {
  flex: 1 1 0;
  height: 100%;
}

.raw-token {
  pre {
    height: 100%;
    background: var(--color-structure-base);
    color: var(--color-font-base);
    padding: 0.5rem;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
  }
}
</style>
