<template>
  <canvas
    ref="chart"
    :aria-label="title"
    role="img"
    style="max-height: 51vh"
    tabindex="0"
    aria-describedby="chartAria"
  >
    <ul
      id="chartAria"
      v-for="{ data, label, parsing } in datasets"
      tabindex="0"
      readonly
      :key="label"
      :aria-label="`${label || title} Content`"
      class="g-cols"
    >
      <template v-if="labels">
        <li v-for="(label, index) in labels" :key="index">
          {{ label }} {{ data[index] }}
        </li>
      </template>
      <template v-else>
        <li v-for="(item, index) in data" :key="index" scope="row">
          <template v-if="parsing">
            {{ item[parsing.xAxisKey] }}{{ item[parsing.yAxisKey] }}
            {{ label }}
          </template>
          <template v-else> {{ item.x }}{{ item.y }} {{ label }} </template>
        </li>
      </template>
    </ul>
  </canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import { mapState } from 'vuex';
import { shallowRef } from 'vue';

Chart.defaults.font.family = '"Proxima Nova", sans-serif';

export default {
  name: 'ChartDoughnut',
  compatConfig: {
    MODE: 3,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
    },
    title: String,
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    ...mapState('user', ['colors']),
    config() {
      const tooltipFont = this.colors['--color-font-inverse'];
      return {
        type: 'doughnut',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '80%',
          borderJoinStyle: 'round',
          plugins: {
            legend: {
              align: 'start',
              position: 'bottom',
              labels: {
                color: this.colors['--color-font-base'],
                usePointStyle: true,
                textAlign: 'left',
                boxWidth: 8,
                boxHeight: 8,
                padding: 16,
                font: {
                  size: 12,
                  weight: '400',
                },
              },
            },
            tooltip: {
              cornerRadius: 4,
              displayColors: false,
              padding: 8,
              backgroundColor: this.colors['--color-indicator-tooltip'],
              bodyColor: tooltipFont,
              titleColor: tooltipFont,
            },
          },
        },
      };
    },
  },
  watch: {
    colors: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    datasets: {
      handler() {
        this.updateChart();
      },
    },
  },
  methods: {
    updateChart() {
      const datasets = this.setDatasetColors(this.datasets);
      this.chart.options = { ...this.config.options };
      this.chart.data = {
        datasets,
        labels: this.labels,
      };
      this.chart.update();
    },
    setDatasetColors(datasets) {
      const chartColors = Object.entries(this.colors)
        .filter(([key]) => key.includes('chart'))
        .map(([, value]) => value);
      datasets.forEach((_, index) => {
        datasets[index]['backgroundColor'] = chartColors;
        datasets[index]['borderColor'] = this.colors['--color-card-primary'];
      });

      return datasets;
    },
  },
  mounted() {
    const datasets = this.setDatasetColors(this.datasets);
    this.chart = shallowRef(
      new Chart(this.$refs.chart, {
        ...this.config,
        data: {
          labels: this.labels,
          datasets,
        },
      })
    );
  },
  beforeUnmount() {
    this.chart.destroy();
  },
};
</script>
