import ComplianceSection from '@/modules/compliance/ComplianceSection.vue';
import ComplianceOverview from '@/modules/compliance/ComplianceOverview.vue';
import ComplianceSettings from '@/modules/compliance/ComplianceSettings.vue';
import ComplianceRuleDetails from '@/modules/compliance/ComplianceRuleDetails.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import FlagGuard from '@/guards/flag.guard';

export default [
  {
    path: '/compliance',
    component: ComplianceSection,
    name: 'compliance',
    beforeEnter: (to, from, next) =>
      FlagGuard(to, from, next, 'COMPLIANCE_DASHBOARD'),
    meta: {
      breadcrumb: {
        linkName: 'compliance.index',
        linkText: 'Compliance',
      },
      permissions: [RBAC_RESOURCE.INSIGHT],
      requireOnlySome: true,
    },
    children: [
      {
        path: '',
        component: ComplianceOverview,
        name: 'compliance.index',
        meta: {
          permissions: [RBAC_RESOURCE.INSIGHT],
        },
      },
      {
        path: 'settings',
        component: ComplianceSettings,
        name: 'compliance.index.settings',
        meta: {
          permissions: [RBAC_RESOURCE.INSIGHT],
        },
      },
      {
        path: ':id',
        component: ComplianceRuleDetails,
        name: 'compliance.index.detail',
        meta: {
          breadcrumb: {
            linkName: 'compliance.index.detail',
            linkText: 'Details',
          },
          permissions: [RBAC_RESOURCE.INSIGHT],
        },
      },
    ],
  },
];
