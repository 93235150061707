import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import gql from 'graphql-tag';
import { PlanFields } from './plans.fragment.gql';

const setupPlanInput = (getPermissions, update = true) => {
  const name = update ? 'updatePlan' : 'createPlan';
  const hasUsbControls = getPermissions([
    RBAC_RESOURCE.CLOUD_ACCESS,
    RBAC_RESOURCE.USB_CONTROLS,
  ]);
  const hasAnalyticSets = getPermissions([
    RBAC_RESOURCE.ANALYTIC_SET,
    RBAC_RESOURCE.ANALYTIC,
  ]);

  const cloudAccess = getPermissions(RBAC_RESOURCE.CLOUD_ACCESS);
  const variables = cloudAccess
    ? `
        $commsConfig: CommsConfigInput!
        $infoSync: AWSJSON!
        $autoUpdate: Boolean!
        $signaturesFeedConfig: SignaturesFeedConfigInput!
      `
    : '';
  const input = cloudAccess
    ? `
        commsConfig: $commsConfig
        infoSync: $infoSync
        autoUpdate: $autoUpdate
        signaturesFeedConfig: $signaturesFeedConfig
      `
    : '';
  return `
    mutation ${name}(
      ${update ? '$id: ID!' : ''}
      $hasLimitedAppAccess: Boolean!
      $excludeAnalytics: Boolean = false
      $name: String!
      $description: String!
      $logLevel: LOG_LEVEL_ENUM
      $actionConfigs: ID!
      $exceptionSets: [ID!]
      $telemetry: ID
      ${hasAnalyticSets ? '$analyticSets: [ID!]' : ''}
      ${hasUsbControls ? '$usbControlSet: ID' : ''}
      ${variables}
      $RBAC_AnalyticSet: Boolean!
      $RBAC_Telemetry: Boolean!
      $RBAC_ActionConfigs: Boolean!
      $RBAC_USBControlSet: Boolean!
      $RBAC_ExceptionSet: Boolean!
      $RBAC_AnalyticSet: Boolean!
    ) {
    ${name}(${update ? 'id: $id, ' : ''}input: {
      name: $name
      description: $description
      logLevel: $logLevel
      actionConfigs: $actionConfigs
      exceptionSets: $exceptionSets
      telemetry: $telemetry
      ${hasAnalyticSets ? 'analyticSets: $analyticSets' : ''}
      ${hasUsbControls ? 'usbControlSet: $usbControlSet' : ''}
      ${input}
    }) {
        ...PlanFields
      }
    }
  `;
};

export const CREATE_PLAN = (getPermissions) => gql`
  ${setupPlanInput(getPermissions, false)}
  ${PlanFields}
`;

export const UPDATE_PLAN = (getPermissions) => gql`
  ${setupPlanInput(getPermissions)}
  ${PlanFields}
`;

export const DELETE_PLAN = gql`
  mutation deletePlan($id: ID!) {
    deletePlan(id: $id) {
      id
    }
  }
`;
