<template>
  <div class="action-data-config p-1">
    <label class="h5">{{ title }}</label>
    <div class="tags mt-1">
      <j-tag v-for="field in getBaseFields(def)" :key="field.label">
        {{ field.label }}
      </j-tag>
      <j-tag v-for="field in attrs" :key="field.label">
        {{ field.label }}
      </j-tag>
      <j-tag v-for="field in related" :key="field.label">
        {{ field.label }}
      </j-tag>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TileActionDataConfig',
  props: {
    title: {
      type: String,
      requried: true,
    },
    config: {
      type: Object,
      required: true,
    },
    def: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attrs: [],
      related: [],
    };
  },
  mounted() {
    this.getExtendedFields(this.def).forEach((f) => {
      if (this.config.attrs.includes(f.value)) {
        this.attrs.push(f);
      }
    });
    this.getRelatedFields(this.def).forEach((f) => {
      if (this.config.related.includes(f.type)) {
        this.attrs.push(f);
      }
    });
  },
  methods: {
    getBaseFields(def) {
      return _.filter(def.fields, { coding: 'base' });
    },
    getExtendedFields(def) {
      return _.filter(def.fields, { coding: 'extended' });
    },
    getRelatedFields(def) {
      return _.filter(def.fields, { coding: 'related' });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-data-config label {
  border-bottom: 2px solid var(--color-border-secondary);
  padding-bottom: 4px;
  margin-bottom: 4px;
  display: flex;
}
</style>
