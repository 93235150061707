export const SEVERITY = {
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  Informational: 'Informational',
};

export const SEVERITY_LEVEL = {
  [SEVERITY.High]: 3,
  [SEVERITY.Medium]: 2,
  [SEVERITY.Low]: 1,
  [SEVERITY.Informational]: 0,
};
