<template>
  <div v-if="!loading && computer" class="gp-content">
    <j-form-bar :title="computer.hostName">
      <template #left>
        <div class="g-cols ai-center">
          <j-back-button @click="$router.push({ name: 'computers.index' })" />
          <j-icon
            :data="computerImg"
            height="46"
            width="46"
            style="margin: -8px 0"
          />
        </div>
      </template>
      <j-button
        class="p-0"
        style-type="ghost-primary"
        @click="refresh"
        data-feature-id="refresh"
      >
        <template #leading>
          <j-icon data="@jcon/refresh.svg" height="20" width="20" />
        </template>
      </j-button>
      <template #right>
        <j-delete-button
          v-if="canWrite"
          @click="showWarning = true"
          label="Delete Computer"
        />
      </template>
    </j-form-bar>
    <Tabs :tabs="tabs">
      <router-view :computer="computer" :refresh="showLoader" />
    </Tabs>
    <j-delete-modal
      v-model="showWarning"
      :to-delete="computer.hostName"
      title="Delete Computer"
      @confirm="remove"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Tabs from '@/components/Tabs.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { macModelImage } from '@/util/mac-model-image';

export default {
  name: 'Computer',
  components: { Tabs },
  setup() {
    const { canWrite } = useRBAC([RBAC_RESOURCE.COMPUTER, RBAC_RESOURCE.ALERT]);
    return { canWrite };
  },
  data() {
    return {
      tabs: null,
      loading: true,
      showWarning: false,
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('primary', {
      computer: (state) => state.computers.computer,
    }),
    computerImg() {
      return macModelImage(this.computer.modelName);
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    await this.refresh();
    this.tabs = [
      {
        label: 'Overview',
        route: {
          name: 'computers.index.overview',
          params: { id },
        },
        ariaLabel: `Overview for ${this.computer?.hostName}`,
      },
      {
        label: 'Computer Details',
        route: {
          name: 'computers.index.detail',
          params: { id },
        },
      },
      {
        label: this.isFeatureEnabled('COMPLIANCE_DASHBOARD')
          ? 'Compliance'
          : 'Insights',
        route: {
          name: 'computers.index.compliance',
          params: { id },
        },
      },
    ];

    this.loading = false;
  },
  methods: {
    async remove() {
      this.showLoader = true;
      const response = await this.$store.dispatch('primary/deleteComputer', {
        uuid: this.computer.uuid,
      });
      this.showLoader = false;
      if (response) {
        this.$router.push({ name: 'computers.index' });
      }
    },
    async refresh() {
      this.showLoader = true;
      await this.$store.dispatch('primary/getComputer', {
        uuid: this.$route.params.id,
      });
      this.showLoader = false;
    },
  },
};
</script>
