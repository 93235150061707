<template>
  <j-button :data-feature-id="dataFeatureId"
    ><template #leading><j-icon data="@jcon/floppy-disk.svg" /></template
    >{{ label }}</j-button
  >
</template>

<script>
export default {
  name: 'SaveButton',
  compatConfig: {
    MODE: 3,
  },
  props: {
    label: {
      type: String,
      default: 'Save',
    },
    dataFeatureId: {
      type: String,
      default: 'save',
    },
  },
};
</script>
