<template>
  <JamfTable
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
  >
    <template #left v-if="canWrite">
      <j-create-button
        @click="$router.push({ name: 'unifiedLogging.index.create' })"
        data-feature-id="create-filter"
      />
    </template>
    <template #right="{ selected }">
      <j-delete-button
        v-if="canWrite"
        @click="confirmRemoveMultiple(selected)"
        :is-disabled="selected.length === 0"
        :label="`Delete (${selected.length})`"
      />
    </template>
    <template #modals="{ refresh, selected }">
      <j-delete-modal
        v-model="showRemoveModal"
        :to-delete="toDelete"
        @confirm="remove(selected, refresh)"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { disabled, success } from '@/util/constants/feedback.icons';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'UnifiedLoggingList',
  components: {
    JamfTable,
  },
  setup() {
    const { showRemoveModal, confirmRemoveMultiple, toDelete } =
      useRemoveHelpers();
    const { canWrite } = useRBAC(RBAC_RESOURCE.UNIFIED_LOGGING_FILTER);
    return {
      showRemoveModal,
      canWrite,
      toDelete,
      confirmRemoveMultiple,
    };
  },
  data() {
    return {
      endpoint: 'primary/listUnifiedLoggingFilters',
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          type: 'linkColumn',
          sortable: true,
          flex: 1,
          minWidth: 100,
          cellRendererParams: {
            route: (params) => ({
              name: 'unifiedLogging.index.detail',
              params: { id: params.data?.uuid },
            }),
          },
        },
        {
          headerName: 'Enabled',
          field: 'enabled',
          sortable: true,
          type: 'iconColumn',
          maxWidth: 110,
          cellRendererParams: {
            setIcon: (value) =>
              value
                ? {
                    icon: success,
                    color: 'var(--color-success-base)',
                  }
                : disabled,
          },
        },
        {
          headerName: 'Tags',
          field: 'tags',
          type: 'tagsColumn',
        },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sortable: true,
          sort: 'asc',
        },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
          sortable: true,
        },
      ],
      title: '',
    };
  },
  created() {
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
  },
  methods: {
    async getData(params) {
      // Hard setting filter due to error on backend that does not allow for a null value
      return this.$store.dispatch(this.endpoint, {
        ...params,
        filter: { and: [] },
      });
    },
    async remove(selected, refresh) {
      this.loading = true;
      this.showWarning = false;
      await Promise.all(
        selected.map(({ uuid }) =>
          this.$store.dispatch('primary/deleteUnifiedLoggingFilter', { uuid })
        )
      );
      refresh();
    },
  },
};
</script>
