<template>
  <button class="connector-button">
    <span class="connector-icon">
      <j-icon :data="icons[type]" :original="original" width="24" height="24" />
    </span>
    <div class="connector-text">
      <span>Continue with {{ text }}</span>
      <small v-if="count > 1">{{ name }}</small>
    </div>
  </button>
</template>

<script>
import default_button from '@icon/default_button.svg';
import jamf_button from '@icon/jamf_button.svg';
import google_button from '@icon/google_button.svg';
import azure_button from '@icon/azure_button.svg';
import okta_button from '@icon/okta_button.svg';

export default {
  name: 'ConnectorButton',
  compatConfig: {
    MODE: 3,
  },
  props: {
    type: {
      type: String,
      default: 'default_button',
    },
    name: String,
    login: Function,
    count: Number,
  },
  data() {
    return {
      icons: {
        default_button,
        jamf_button,
        google_button,
        azure_button,
        okta_button,
      },
    };
  },
  computed: {
    original() {
      return ['azure_button', 'google_button'].includes(this.type);
    },
    text() {
      switch (this.type) {
        case 'okta_button':
          return 'Okta';
        case 'jamf_button':
          return 'Jamf ID';
        case 'google_button':
          return 'Google';
        case 'azure_button':
          return 'Microsoft';
        default:
          return 'Other';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.connector {
  &-button {
    --color-action-secondary-base: var(--color-connector-btn-base);
    --color-action-secondary-active: var(--color-connector-btn-active);
    --size-action-height-base: auto;
    @include button;
    width: 100%;
    justify-content: flex-start;
    padding: spacing();
    border-radius: var(--size-border-radius-base);
    min-width: auto;
    color: var(--color-font-base);
  }
  &-icon {
    margin-right: spacing();
    display: inline-flex;
    height: 100%;
    align-items: top;
    &::after {
      content: '';
      @include divider(var(--color-border-base));
      margin-left: spacing();
    }
  }
  &-text {
    @include grid(0);
    justify-items: start;
    small {
      color: var(--color-font-secondary);
    }
  }
}
</style>
