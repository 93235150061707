import { useGmt } from '@/composables/gmt';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { macOSVersionDeprecated } from '@/util/macos-version-deprecated';
import { dateGreaterThan, fromDate } from '@/util/time-helpers';
import { COMPUTER_CONNECTION_STATUS } from '@/util/constants/computer-status.types';

export function useComputers(props) {
  const store = useStore();
  const pastDueTimespan = 30;
  const { formatEpochDate } = useGmt();

  const computer = computed(
    () => props?.params?.data || store.state.primary.computers.computer
  );
  const isConnected = computed(
    () =>
      computer.value.connectionStatus === COMPUTER_CONNECTION_STATUS.Connected
  );

  const isDeprecated = computed(
    () =>
      macOSVersionDeprecated(computer.value.osMajor, computer.value.osMinor) &&
      store.getters.isFeatureEnabled('DEPRECATE_MACOS')
  );

  const osFallback = computed(
    () =>
      computer.value.osString ||
      `Version ${computer.value.osMajor}.${computer.value.osMinor}.${computer.value.osPatch}`
  );

  const connectionStatus = computed(() =>
    isPastDue.value
      ? COMPUTER_CONNECTION_STATUS.DisconnectedOverdue
      : computer.value.connectionStatus
  );

  const isPastDue = computed(
    () =>
      computer.value.connectionStatus !==
        COMPUTER_CONNECTION_STATUS.Connected &&
      dateGreaterThan(computer.value.lastDisconnection, pastDueTimespan, 'days')
  );

  const fromLastDisconnectionDate = computed(() =>
    fromDate(computer.value.lastDisconnection)
  );

  const connectionStatusTimestamp = computed(() =>
    formatEpochDate(
      isConnected.value
        ? computer.value.lastConnection
        : computer.value.lastDisconnection,
      store.state.user.gmt
    )
  );

  return {
    computer,
    isConnected,
    isDeprecated,
    osFallback,
    connectionStatus,
    isPastDue,
    fromLastDisconnectionDate,
    connectionStatusTimestamp,
  };
}
