import alerts from '@jcon/pulse.svg';
import computers from '@jcon/laptop.svg';
import overview from '@jcon/layout.svg';
import insights from '@jcon/firewall-checked.svg';
import unifiedlogging from '@icon/unified-logging.svg';
import analytics from '@icon/analytics.svg';
import threatprevention from '@jcon/firewall-block.svg';
import plans from '@icon/plans.svg';
import actions from '@icon/lightning.svg';
import download from '@jcon/download.svg';
import usb from '@icon/usb.svg';
import auditlogs from '@jcon/inbox-full.svg';
import account from '@jcon/users.svg';
import telemetry from '@icon/telemetry.svg';
import admin from '@icon/jamf.svg';

export {
  alerts,
  computers,
  overview,
  insights,
  unifiedlogging,
  analytics,
  threatprevention,
  plans,
  actions,
  download,
  usb,
  account,
  auditlogs,
  telemetry,
  admin,
};
