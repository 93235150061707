import gql from 'graphql-tag';
import { AlertFields } from './alerts.fragments';

export const GET_ALERT = (getPermissions) => gql`
  query getAlert($uuid: ID!) {
    getAlert(uuid: $uuid) {
      ...AlertFields
    }
  }
  ${AlertFields(getPermissions)}
`;

export const LIST_ALERTS = (getPermissions) => gql`
  query listAlerts(
    $nextToken: String
    $pageSize: Int = 100
    $field: AlertOrderField!
    $direction: OrderDirection!
    $filter: AlertFiltersInput
  ) {
    listAlerts(
      input: {
        next: $nextToken
        pageSize: $pageSize
        filter: $filter
        order: { direction: $direction, field: $field }
      }
    ) {
      items {
        ...AlertFields
      }
      pageInfo {
        next
        total
      }
    }
  }
  ${AlertFields(getPermissions)}
`;

export const UPDATE_ALERTS = (getPermissions) => gql`
  mutation updateAlerts($input: AlertUpdateInput!) {
    updateAlerts(input: $input) {
      items {
        ...AlertFields
      }
    }
  }
  ${AlertFields(getPermissions)}
`;
