import { DEFAULT_DATE_FORMAT, ALLOWED_DATE_FORMATS } from '@/util/time-helpers';
import { RBAC_RESOURCE } from './rbac.resource';
import GET_CURRENT_PERMISSIONS from './queries/getCurrentPermissions.gql';
import GET_USER_SETTINGS from './queries/getUserSettings.gql';
import UPDATE_USER_SETTINGS from './queries/updateUserSettings.gql';
import * as getters from './rbac.getters';

export default {
  namespaced: true,
  state: {
    mode: 'dark',
    /** These are common colors that are utilized in canvas elements which can not use css vars.
     * The color is set in App.vue by getting the computed value in the DOM */
    commonColors: [
      '--color-danger-base',
      '--color-warning-base',
      '--color-low-base',
      '--color-border-base',
      '--color-indicator-tooltip',
      '--color-font-base',
      '--color-font-inverse',
      '--color-font-secondary',
      '--color-card-primary',
      ...Array.from({ length: 16 }, (_, i) => `--color-chart-${i + 1}`),
    ],
    colors: {},
    scheme: {
      color: 'default',
      contrast: 0,
    },
    gmt: true,
    analog: true,
    dateFormat: DEFAULT_DATE_FORMAT,
    checkPermissions: null,
    permissions: {
      R: [],
      W: [],
    },
  },
  mutations: {
    SET_USER_SETTINGS(state, payload) {
      state.mode = payload.theme || state.mode;
      state.gmt = payload.gmt;
      state.scheme = payload.scheme || state.scheme;
      state.analog =
        typeof payload.analog === 'boolean' ? payload.analog : true;
      state.dateFormat = ALLOWED_DATE_FORMATS.includes(payload.dateFormat)
        ? payload.dateFormat
        : state.dateFormat;
    },
    SET_COLORS(state, payload) {
      state.colors = payload;
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
    SET_PERMISSIONS_CHECK_TIME(state) {
      const now = new Date();
      now.setSeconds(now.getSeconds() + 30);
      state.checkPermissions = now / 1000;
    },
    SET_CLOUD_ACCESS_PERMISSION(state, payload) {
      if (payload === false) {
        state.permissions.R.push(RBAC_RESOURCE.CLOUD_ACCESS);
      }
    },
  },
  getters,
  actions: {
    async getCurrentPermissions({ dispatch, commit, rootState }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_CURRENT_PERMISSIONS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PERMISSIONS_CHECK_TIME');
        commit('SET_PERMISSIONS', result.data.getCurrentPermissions);
        commit(
          'SET_CLOUD_ACCESS_PERMISSION',
          rootState.app.info.complianceReporterEnabled
        );
        return result.data.getCurrentPermissions;
      }
      commit('SET_PERMISSIONS', { R: [], W: [] });
      return result;
    },
    async getUserSettings({ commit, dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_USER_SETTINGS,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USER_SETTINGS', result.data.getUserSettings.settings);
      }
    },
    async updateUserSettings({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_USER_SETTINGS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USER_SETTINGS', result.data.updateUserSettings.settings);
      }
    },
  },
};
