<template>
  <div></div>
</template>

<script>
export default {
  name: 'AuthCallback',
  async mounted() {
    try {
      await this.$store.dispatch('auth/handleCallbackAuth');
      this.$router.push({ name: 'initialize' }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } catch {
      this.$router.push({ name: 'login' });
    }
  },
};
</script>
