<template>
  <div class="g-rows">
    <ListItem title="Event Type"
      >Process {{ getEnumString('type', event.type) }}
    </ListItem>
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Pid">
      {{ event.pid }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import { useEvents } from '@/modules/alerts/components/events/events';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPProcessEvent',
  inject: ['$typeDefs'],
  components: { ListItem },
  setup(props) {
    const { getEnumString } = useEvents(props);
    const { formatEpochDate } = useGmt();
    return {
      getEnumString,
      formatEpochDate,
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      def: this.$typeDefs.events.GPProcessEvent,
    };
  },
};
</script>
