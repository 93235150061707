<template>
  <j-notification class="delete-box m-2" type="danger">
    <div class="f-cols max-c">
      <slot></slot>
      <p class="delete-text">{{ text }}</p>
      <gp-loader class="delete-text" :show-loader="showLoader">{{
        loaderText
      }}</gp-loader>
    </div>
  </j-notification>
</template>

<script>
export default {
  name: 'DeleteBox',
  props: {
    text: {
      type: String,
      required: true,
    },
    loaderText: {
      type: String,
      required: true,
    },
    showLoader: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
