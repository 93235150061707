<template>
  <Card
    class="hero"
    tabindex="0"
    role="button"
    :aria-label="`${value}`"
    :aria-roledescription="label"
  >
    <j-icon v-if="icon" :data="icon" width="24" height="24" />
    <div>
      <span v-if="value !== null" class="value">{{ value }}</span>
      <div v-else class="fix-height"><gp-loader /></div>
      <div v-if="label" class="value-label">
        {{ label }}
      </div>
    </div>
    <slot></slot>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'HeroNumber',
  components: { Card },
  compatConfig: {
    MODE: 3,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  width: 100%;
  position: relative;

  .value {
    @include header1;
  }

  svg {
    position: absolute;
    right: spacing();
    top: spacing();
  }

  .value-label {
    font-size: var(--size-font-paragraph-base);
  }

  .fix-height {
    height: 60px;
  }

  &:focus {
    @include has-focus;
  }
}
</style>
