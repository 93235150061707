<template>
  <div class="gp-content" :data-testid="isCopy ? 'is-copy' : ''">
    <j-form-bar
      v-if="mode.create || analyticSet"
      :title="mode.create ? 'Create Analytic Set' : analyticSet.name"
      @back="routeTo()"
      @submit:created="routeTo('detail')"
      @remove="confirmRemoveWithPlans(analyticSet)"
      :can-remove="planAccess"
      :can-write="canWrite && !isManaged"
    >
      <template #right>
        <j-button
          v-if="canWrite && !isManaged && !mode.create"
          @click="copy"
          data-feature-id="clone"
          style-type="secondary"
        >
          <template #leading>
            <j-icon data="@jcon/clipboard.svg" />
          </template>
          Clone
        </j-button>
      </template>
    </j-form-bar>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component
          :is="Component"
          v-if="!loading"
          :key="isCopy"
          :analytic-set="analyticSet"
        />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      :blockers="selectedPlans"
      @confirm="remove()"
    />
  </div>
</template>

<script>
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapState } from 'vuex';
import { useRemoveHelpers } from '@/composables/remove-helpers';
import { useRBAC } from '@/composables/rbac';
import { useRouteHelpers } from '@/composables/route-helpers';

export default {
  name: 'AnalyticsSet',
  setup() {
    const { canWrite, canAccess } = useRBAC(RBAC_RESOURCE.ANALYTIC_SET);
    const { mode } = useRouteHelpers();
    const { showRemoveModal, confirmRemoveWithPlans, toDelete, selectedPlans } =
      useRemoveHelpers();

    return {
      mode,
      canWrite,
      canAccess,
      showRemoveModal,
      confirmRemoveWithPlans,
      toDelete,
      selectedPlans,
    };
  },
  data() {
    return {
      loading: true,
      parentRoute: 'analytics.sets',
      isCopy: false,
    };
  },
  computed: {
    ...mapState({
      analyticSet: (state) => state.primary.analyticSets.analyticSet,
      queryState: (state) => state.app.queryState,
    }),
    isManaged() {
      return this.analyticSet?.managed;
    },
    planAccess() {
      return this.canAccess(RBAC_RESOURCE.PLAN);
    },
  },
  async mounted() {
    if (
      !this.mode.create ||
      (this.mode.create && this.$route.query.copy && !this.analyticSet.name)
    ) {
      this.isCopy = false;
      this.loading = true;
      await this.$store.dispatch('primary/getAnalyticSet', {
        uuid: this.$route.params.uuid || this.$route.hash,
      });
    }

    this.loading = false;
  },
  methods: {
    copy() {
      const name = 'analytics.sets.create';
      this.isCopy = true;
      this.$router.push({
        name,
        query: { ...this.queryState[name].query, copy: this.analyticSet.uuid },
      });
    },
    async remove() {
      const result = await this.$store.dispatch('primary/deleteAnalyticSet', {
        uuid: this.analyticSet.uuid,
      });
      if (result) {
        this.$router.push({
          name: this.parentRoute,
          ...this.queryState[this.parentRoute],
        });
      }
    },
    routeTo(child) {
      const name = child ? `${this.parentRoute}.${child}` : this.parentRoute;
      this.$router.push({
        name,
        params: child ? { uuid: this.analyticSet?.uuid } : {},
        ...this.queryState[name],
      });
    },
  },
};
</script>
