<template>
  <div class="g-rows" :data-testid="inputType">
    <j-tooltip
      v-if="includeTooltip && description"
      open-position="right"
      :text="description"
    >
      <j-icon :data="icon" />
    </j-tooltip>
    <j-icon v-else :data="icon" />
  </div>
</template>

<script>
import file from '@jcon/paper-earmarked.svg';
import click from '@icon/click.svg';
import av from '@jcon/firewall-block.svg';
import malware from '@icon/malware.svg';
import process from '@icon/process.svg';
import download from '@jcon/download.svg';
import usb from '@icon/usb.svg';
import screenshot from '@icon/screenshot.svg';
import prevent from '@icon/prevent.svg';
import circle from '@jcon/more-circle.svg';
import {
  EVENT_TYPES,
  EVENT_TYPES_NAMES_ALL,
} from '@/util/constants/event.types';

export default {
  name: 'AnalyticEventTypeIcon',
  props: {
    includeTooltip: Boolean,
    inputType: {
      type: String,
      required: true,
    },
  },
  inject: ['$typeDefs'],
  data() {
    return {
      icons: {
        [EVENT_TYPES.FILE_SYSTEM]: file,
        [EVENT_TYPES.DOWNLOAD]: download,
        [EVENT_TYPES.USB]: usb,
        [EVENT_TYPES.MOUNT]: usb,
        [EVENT_TYPES.USB_STORAGE_EVENT]: usb,
        [EVENT_TYPES.SCREENSHOT]: screenshot,
        [EVENT_TYPES.PROCESS]: process,
        [EVENT_TYPES.PREVENTION]: prevent,
        [EVENT_TYPES.KEY_LOG]: malware,
        [EVENT_TYPES.MRT]: malware,
        [EVENT_TYPES.THREAT]: av,
        [EVENT_TYPES.GATEKEEPER]: malware,
        [EVENT_TYPES.CLICK]: click,
      },
    };
  },
  computed: {
    icon() {
      return this.icons[this.inputType] || circle;
    },
    description() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.$typeDefs.events,
          this.inputType
        )
      ) {
        const def = this.$typeDefs.events[this.inputType];
        return `${EVENT_TYPES_NAMES_ALL[def.type]}: ${def.description}`;
      }
      return null;
    },
  },
};
</script>
