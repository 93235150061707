<template>
  <div class="gp-content" v-if="!loading">
    <j-form-bar
      v-if="mode.create || user"
      :title="mode.create ? 'Create User' : name"
      v-bind="submitActions"
      @back="routeTo()"
      @remove="confirmRemove"
      :can-write="canWrite"
    />

    <div class="scroll-vertical">
      <div class="form-content">
        <j-input
          v-model="internalUser.email"
          :error-text="nameErrorMessage"
          :has-error="v$.internalUser.email.$error"
          :is-read-only="!mode.create"
          :label="mode.create ? 'Email' : 'User'"
          data-feature-id="name"
        />
        <j-input-group v-if="!mode.create" label="Last Login">
          {{
            internalUser.lastLogin
              ? formatEpochDate(internalUser.lastLogin)
              : 'Never'
          }}
        </j-input-group>
        <j-input-group v-if="!mode.create" label="Origin">
          {{
            internalUser.source === 'connection'
              ? 'Identity Provider'
              : 'Added Manually'
          }}
        </j-input-group>
        <j-select
          v-if="connectionOptions && connectionOptions.length > 1"
          label="Identity Provider"
          :helper-text="idpHelperText"
          data-feature-id="connections"
          v-model="internalUser.connection"
          :is-read-only="!mode.create"
          :has-error="v$.internalUser.connection.$error"
          :options="connectionOptions"
          :searchable="isSearchable(connectionOptions)"
        />
        <j-select
          v-if="groupOptions"
          v-model="internalUser.assignedGroups"
          label="Groups"
          data-feature-id="groups"
          helper-text="Add a user to a group in Jamf Protect to assign additional roles"
          :is-disabled="mode.create && connectionNone"
          :options="groupOptions"
          :searchable="isSearchable(groupOptions)"
          :placeholder="
            (!internalUser.assignedGroups.length && !canWrite) || connectionNone
              ? 'N/A'
              : 'Add Group'
          "
          allow-multi-select
          :is-read-only="isReadOnly"
        />
        <j-select
          v-if="roleOptions"
          v-model="internalUser.assignedRoles"
          label="Roles"
          class="user-roles"
          data-feature-id="roles"
          helper-text="Roles to assign to this user in addition to groups and identity provider mappings"
          :is-disabled="mode.create && connectionNone"
          :options="roleOptions"
          :searchable="isSearchable(roleOptions)"
          :placeholder="
            (!internalUser.assignedRoles?.length && !canWrite) || connectionNone
              ? 'N/A'
              : 'Add Role'
          "
          allow-multi-select
          :is-read-only="isReadOnly"
        />
        <template v-if="cloudOnlyAccess">
          <j-checkbox
            v-if="internalUser.email || mode.create"
            :is-disabled="!canWrite"
            data-feature-id="email-notification"
            v-model="internalUser.receiveEmailAlert"
          >
            Send Email Notifications
          </j-checkbox>
          <SeverityLevelSelect
            v-model="internalUser.emailAlertMinSeverity"
            label="Email Severity"
            data-feature-id="email-severity"
            class="ml-4"
            exclude-info
            helper-text="Minimum severity of alerts to send email notification"
            :is-disabled="!internalUser.receiveEmailAlert"
            :is-read-only="!canWrite"
            :placeholder="!canWrite ? 'N/A' : 'Add Email Severity'"
          />
        </template>
        <j-input-group
          v-if="internalUser.extraAttrs && !connectionNone && cloudOnlyAccess"
          class="mt-2"
          label="Additional Identity Provider Information"
        >
          <pre><code>{{ prettifyJson(internalUser.extraAttrs) }}</code></pre>
        </j-input-group>
      </div>
    </div>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="name"
      @confirm="remove"
      data-feature-id="delete-modal"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { prettifyJson } from '@/util';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { SEVERITY } from '@/util/constants/severity.types';
import SeverityLevelSelect from '@/components/SeverityLevelSelect.vue';
import { useGmt } from '@/composables/gmt';
import { useAccount } from './composable/account';
import { required, email } from '@vuelidate/validators';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'AccountUser',
  components: { SeverityLevelSelect },
  setup() {
    const {
      mode,
      getRoles,
      getGroups,
      getConnections,
      roleOptions,
      roles,
      users,
      groupOptions,
      connections,
      isSearchable,
      handleSubmit,
      v$,
      canWrite,
      cloudOnlyAccess,
    } = useAccount(RBAC_RESOURCE.USER);
    const { formatEpochDate } = useGmt();
    const { confirmRemove, showRemoveModal } = useRemoveHelpers();
    return {
      mode,
      getRoles,
      getGroups,
      getConnections,
      roleOptions,
      groupOptions,
      connections,
      isSearchable,
      roles,
      users,
      v$,
      handleSubmit,
      canWrite,
      formatEpochDate,
      confirmRemove,
      showRemoveModal,
      cloudOnlyAccess,
    };
  },
  data() {
    return {
      loading: true,
      endpointUpdate: 'primary/updateUser',
      endpointCreate: 'primary/createUser',
      internalUser: {
        email: '',
        assignedRoles: [],
        assignedGroups: [],
        connection: null,
        receiveEmailAlert: false,
        emailAlertMinSeverity: SEVERITY.Low,
        extraAttrs: null,
      },
    };
  },
  validations() {
    return {
      internalUser: {
        email: {
          required,
          emailIf: this.mode.create ? email : () => true,
        },
        connection: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState('primary', {
      user: (state) => state?.account.user,
    }),
    name() {
      return this.user?.email || this.user?.sub;
    },
    nameErrorMessage() {
      return this.v$.internalUser.email.$errors[0]?.$message;
    },
    submitActions() {
      const actions = {
        'onSubmit:created': () => this.routeTo('detail', this.user?.id),
        onSubmit: this.handleSubmit,
      };
      const limitedActions = this.mode.create ? actions : {};
      return !this.cloudOnlyAccess ? limitedActions : actions;
    },
    idpHelperText() {
      if (this.mode.create) {
        return `Select an identity provider to assign any user roles.
            If this user is associated with an identity provider, the user may receive additional roles via an identity provider mapping in Jamf Protect.
            Compare this user’s group membership in your identity provider with the mappings created in Jamf Protect.`;
      }
      if (!this.connectionNone && !this.mode.create) {
        return `This user is associated with an identity provider and may receive additional roles via an identity provider mapping in Jamf Protect. 
        Compare this user’s group membership in your identity provider with the mappings created in Jamf Protect.`;
      }
      return '';
    },
    connectionNone() {
      return this.internalUser.connection === 'none';
    },
    isReadOnly() {
      return (
        !this.canWrite ||
        !this.connectionOptions ||
        (!this.mode.create && (!this.connectionOptions || this.connectionNone))
      );
    },
    connectionOptions() {
      const options = this.connections ? [...this.connections] : null;
      if (this.cloudOnlyAccess && options) {
        options.push({ value: 'none', label: 'None' });
      }
      return options;
    },
    payload() {
      const payload = {
        roleIds: this.internalUser.assignedRoles,
        groupIds: this.internalUser.assignedGroups,
        emailAlertMinSeverity: this.internalUser.emailAlertMinSeverity,
        receiveEmailAlert: this.internalUser.receiveEmailAlert,
      };

      return this.mode.create
        ? {
            ...payload,
            email: this.internalUser.email,
            connectionId:
              this.internalUser.connection === 'none'
                ? null
                : this.internalUser.connection,
          }
        : { ...payload, id: this.user.id };
    },
  },
  watch: {
    connectionNone(value) {
      if (value) {
        this.internalUser.assignedRoles = [];
        this.internalUser.assignedGroups = [];
      }
    },
  },
  async beforeMount() {
    await this.getRoles();
    await this.getGroups();
    await this.getConnections();

    if (this.mode.create) {
      this.internalUser.connection =
        this.connectionOptions?.length >= 1
          ? this.connectionOptions[0].value
          : 'none';
    }
  },
  async mounted() {
    if (!this.mode.create) {
      await this.$store.dispatch('primary/getUser', {
        id: this.$route.params.id,
      });
      this.internalUser = {
        ...this.user,
        assignedRoles: this.user.assignedRoles?.map(({ id }) => id),
        assignedGroups: this.user.assignedGroups?.map(({ id }) => id),
        email: this.name,
        connection: this.user.connection?.id,
      };
    }
    this.loading = false;
  },
  methods: {
    routeTo(name, id) {
      const parent = 'account.users';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: id ? { id } : {},
      });
    },
    prettifyJson,
    async remove() {
      const result = await this.$store.dispatch('primary/deleteUser', {
        id: this.user.id,
      });
      if (result) {
        this.routeTo();
      }
    },
    // used by handleSubmit
    async submit() {
      await this.$store.dispatch(
        this.mode.create ? this.endpointCreate : this.endpointUpdate,
        this.payload
      );
      this.internalUser.id = this.user?.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-roles {
  --color-input-tag: var(--color-indicator-tag-secondary);
}
</style>
