<template>
  <Card
    class="status-count"
    role="group"
    :aria-label="`Status counts for ${severity} severity`"
  >
    <div class="header">
      <SeverityIndicator :level="severity" /><span>{{ severity }}</span
      ><j-button
        :aria-label="`Link to alerts filtered by ${severity} severity`"
        style-type="ghost-primary"
        class="link-button"
        @click="goToAlerts"
        ><template #leading><j-icon data="@jcon/arrow.svg" /></template
      ></j-button>
    </div>
    <div class="counts pl-2 p-1 gap-0">
      <div v-for="(count, key) in counts" :key="key" :id="key.replace(' ')">
        <transition name="slide-up-small" mode="out-in">
          <h1
            v-if="!count || typeof count === 'number'"
            class="h2"
            :key="count"
          >
            {{ count ? count.toLocaleString() : 0 }}
          </h1>
        </transition>
        <p>{{ ALERT_STATUS[key] }}</p>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from './Card.vue';
import SeverityIndicator from './SeverityIndicator.vue';
import { ALERT_STATUS } from '@/util/constants/alert-status.types';
import { FILTER_TYPES } from './table/utils/filter.types';

export default {
  name: 'StatusCount',
  components: { Card, SeverityIndicator },
  props: {
    severity: {
      type: String,
      required: true,
    },
    counts: {
      type: Object,
      required: true,
      default: () => {
        const status = {};
        Object.keys(ALERT_STATUS).forEach((key) => {
          status[key] = 0;
        });
        return status;
      },
    },
    since: {
      type: String,
      required: true,
    },
    computer: String,
  },
  data() {
    return {
      ALERT_STATUS,
    };
  },
  methods: {
    goToAlerts() {
      const query = {
        column: 'severity|created',
        filterType: 'int|date',
        type: `${FILTER_TYPES.EQUALS}|${FILTER_TYPES.AFTER_INTERVAL}`,
        value: `${this.severity}|${this.since}`,
      };
      if (this.computer) {
        query.column += '|computerUuid';
        query.filterType += '|text';
        query.type += `|${FILTER_TYPES.EQUALS}`;
        query.value += `|${this.computer}`;
      }
      this.$router.push({
        name: 'alerts.index',
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-count {
  padding: 0 !important;
  p {
    @include paragraph;
  }

  .counts {
    @include flex-wrap($gap: 0, $size: 49%, $scale: 0);
    .h2 {
      line-height: var(--size-font-h3-height);
    }
  }
}
.header {
  @include grid(spacing());
  @include grid-columns(max-content auto);
  @include border($color: var(--color-border-secondary), $sides: bottom);
  padding: spacing() spacing(2);
  align-items: center;

  .link-button {
    justify-self: flex-end;
    margin-right: -8px;

    svg {
      @include transition(transform);
      transform: rotate(270deg) translateY(0px);
    }

    &:hover svg,
    &:focus svg {
      transform: rotate(270deg) translateY(2px);
    }
  }
}
</style>
