<template>
  <div class="scroll-vertical p-2 related-groups">
    <transition-group name="list" tag="div" class="g-rows max-c">
      <Card v-for="(group, index) in groups" :key="index" class="g-rows">
        <ListItem title="Name">
          {{ group?.name }}
        </ListItem>
        <ListItem title="gid">
          {{ group?.gid }}
        </ListItem>
        <ListItem title="uuid">
          {{ group?.uuid }}
        </ListItem>
      </Card>
      <NoRelatedInfo v-if="!groups.length" title="0 Groups" :key="'0-empty'" />
    </transition-group>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';
import NoRelatedInfo from './NoRelatedInfo.vue';

export default {
  name: 'AlertRelatedGroups',
  compatConfig: {
    MODE: 3,
  },
  components: {
    Card,
    ListItem,
    NoRelatedInfo,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
};
</script>
