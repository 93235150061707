<template>
  <div :class="{ 'has-trailing-icon': icon, 'j-select': true }">
    <div>
      <select
        v-model="computedValue"
        ref="select"
        v-bind="$attrs"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
      >
        <template v-if="placeholder">
          <option v-if="computedValue == null" :value="null" disabled hidden>
            {{ placeholder }}
          </option>
        </template>
        <slot></slot>
      </select>
      <span class="indicator"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectNative',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, Array, Function],
      default: null,
    },
    placeholder: String,
    iconColor: String,
  },
  emits: ['blur', 'focus', 'update:modelValue'],
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.j-select {
  display: inline-flex;
  align-items: center;
  width: 100%;
  > div {
    display: inline-flex;
    width: 100%;
    select {
      @include input-field-base;
      min-width: auto;
      appearance: none;
      padding-right: spacing(3);

      &:hover {
        cursor: pointer;
      }
    }
    .indicator {
      @include transition(transform);
      @include chevron(down);
      width: 32px;
      height: auto;
      margin-left: -32px;
      pointer-events: none;
    }
  }

  &.has-trailing-icon {
    position: relative;
    select {
      padding-right: spacing(6);
    }
  }
}
</style>
