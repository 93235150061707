import LIST_INSIGHTS from './queries/listInsights.gql';
import LIST_INSIGHT_COMPUTERS from './queries/listInsightComputers.gql';
import UPDATE_INSIGHT_STATUS from './mutations/updateInsightStatus.gql';

export default {
  state: {
    items: [],
  },
  mutations: {
    SET_COMPLIANCE(state, payload) {
      state.items = payload;
    },
    UPDATE_COMPLIANCES(state, payload) {
      const index = state.items.findIndex(({ uuid }) => uuid === payload.uuid);
      state.items.splice(index, 1, payload);
    },
  },
  actions: {
    async listCompliance({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_INSIGHTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        let newResult = [];
        result.data.listInsights.forEach((value) => {
          newResult.push({
            total: value.totalPass + value.totalFail + value.totalNone,
            ...value,
          });
        });
        commit('SET_COMPLIANCE', newResult);
        return newResult;
      }
      return result;
    },
    async listComplianceComputers({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_INSIGHT_COMPUTERS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_COMPLIANCE', result.data.listInsightComputers);
        return result.data.listInsightComputers;
      }
      return result;
    },
    async updateComplianceStatus({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_INSIGHT_STATUS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('UPDATE_COMPLIANCES', result.data.updateInsightStatus);
        return result.data.updateInsightStatus;
      }
      return result;
    },
  },
};
