import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import InsightsOverview from './InsightsOverview';
import InsightDetails from './InsightDetails';

export default [
  {
    path: '/insights',
    component: Section,
    name: 'insights',
    meta: {
      breadcrumb: {
        linkName: 'insights.index',
        linkText: 'Insights',
      },
      permissions: [RBAC_RESOURCE.INSIGHT],
    },
    children: [
      {
        path: '',
        component: InsightsOverview,
        name: 'insights.index',
      },
      {
        path: ':id',
        component: InsightDetails,
        name: 'insights.index.detail',
        meta: {
          breadcrumb: {
            linkName: 'insights.index.detail',
            linkText: 'Details',
          },
        },
      },
    ],
  },
];
