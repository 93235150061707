<template>
  <Card :class="{ 'is-small': isSmall }">
    <div class="g-cols gap-1 ai-center max-c">
      <j-button
        @click="isOpen = !isOpen"
        style-type="ghost-primary"
        class="collapse-button"
      >
        <template #leading>
          <j-icon
            data="@jcon/x-small.svg"
            width="24"
            :class="{ 'is-open': isOpen, icon: true }"
            height="24"
          />
        </template>
      </j-button>
      <span
        @click="isOpen = !isOpen"
        class="collapse-title"
        :class="{ 'subtitle-text': !isSmall }"
      >
        {{ title }}
      </span>
      <slot name="left-header"></slot>
    </div>

    <Card
      :aria-expanded="isOpen"
      class="collapse-content"
      :class="{ 'mt-1': isOpen }"
    >
      <transition name="height">
        <slot v-if="isOpen"></slot>
      </transition>
    </Card>
  </Card>
</template>

<script>
import Card from './Card.vue';
export default {
  components: { Card },
  name: 'CollapseCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    open: Boolean,
    isSmall: Boolean,
  },
  data() {
    return {
      isOpen: this.open,
    };
  },
  watch: {
    open(value) {
      this.isOpen = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-title {
  cursor: pointer;
  user-select: none;
}

.is-small {
  --size-action-height-base: var(--size-action-height-secondary);
  box-shadow: none;
  padding: 0;
  border: 0;
  background: transparent;
  .collapse-button {
    margin: spacing(0.5);
    padding: spacing(0.5);
  }
  .collapse-content {
    background: transparent;
  }
}
.icon {
  @include transition(transform);
  transform: rotate(45deg);
  &.is-open {
    transform: rotate(0deg);
  }
}

.collapse-content {
  box-shadow: none;
  padding: 0;
  border: 0;
  margin: 0;
}
</style>
