<template>
  <div class="scroll-vertical">
    <div v-if="!loading" class="form-content">
      <span>Endpoint Threat Prevention Version History</span>
      <h1 class="h4 g-cols gap-6 max-c">
        <span>Latest Version: {{ latestVersion.id }}</span>
        <span>Created: {{ formatEpochDate(latestVersion.created) }}</span>
      </h1>
      <div class="f-wrap fit-c">
        <Card
          v-if="computerCount"
          class="g-rows max-c"
          :loading="loading"
          v-on="
            computerCount.latestVersionCount === computerCount.total
              ? { click: toComputers }
              : {}
          "
        >
          <j-icon
            class="center"
            width="48"
            height="48"
            data="@jcon/laptop.svg"
          />
          <span v-if="computerCount.latestVersionCount === computerCount.total">
            <strong>All</strong> computers are on the latest version
          </span>
          <ul v-else>
            <li>
              <router-link
                v-if="computerCount.latestVersionCount >= 1"
                :to="filteredLink(latestVersion)"
              >
                {{ computerCount.latestVersionCount }} of
                {{ computerCount.total }} computers on latest version
              </router-link>
              <span v-else
                >0 of {{ computerCount.total }} computers on latest
                version</span
              >
            </li>
          </ul>
        </Card>
        <j-notification v-if="planTPSetup.length > 0" type="warning">
          <RelatedPlansTagList
            label="Jamf strongly recommends configuring endpoint threat prevention to Block &amp; Report on the following plans to protect against malicious processes."
            :plans="planTPSetup"
          />
        </j-notification>
      </div>
      <div class="version-list">
        <table v-if="versions.length > 1">
          <tr>
            <th>Previous versions</th>
            <th>Created</th>
          </tr>
          <tr v-for="({ id, created }, index) in versions" :key="index">
            <template v-if="id !== latestVersion.id">
              <td>Version {{ id }}</td>
              <td>{{ formatEpochDate(created) }}</td>
            </template>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import Card from '@/components/Card.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import RelatedPlansTagList from '@/components/RelatedPlansTagList.vue';

export default {
  name: 'ThreatProtectionsOverview',
  components: { Card, RelatedPlansTagList },
  setup() {
    const { formatEpochDate } = useGmt();
    const { canAccess } = useRBAC();

    return { formatEpochDate, canAccess };
  },
  data() {
    return {
      versions: null,
      computerCount: null,
      loading: true,
      planTPSetup: [],
    };
  },
  computed: {
    latestVersion() {
      return this.versions[0];
    },
  },
  async mounted() {
    this.versions = await this.$store.dispatch(
      'primary/listThreatPreventionVersions'
    );

    if (
      this.canAccess([
        RBAC_RESOURCE.COMPUTER,
        RBAC_RESOURCE.THREAT_PREVENTION_VERSION,
      ])
    ) {
      const { totalCount, versionCount } = await this.$store.dispatch(
        'primary/getComputersThreatPreventVersionCount',
        {
          id: this.latestVersion.id,
        }
      );
      this.computerCount = {
        total: totalCount.computers,
        latestVersionCount: versionCount.computers,
      };
    }
    if (this.canAccess(RBAC_RESOURCE.PLAN)) {
      const plans = await this.$store.dispatch('primary/listAllPlansMinimal');

      plans.forEach((element) => {
        if (
          element.signaturesFeedConfig.mode === 'reportOnly' ||
          element.signaturesFeedConfig.mode === 'disabled'
        ) {
          this.planTPSetup.push(element);
        }
      });
    }
    this.loading = false;
  },
  methods: {
    filteredLink() {
      return {
        name: 'computers.index',
        query: {
          column: 'threatVersion',
          type: FILTER_TYPES.EQUALS,
          value: this.latestVersion.id,
          filterType: 'int',
        },
      };
    },
    toComputers() {
      this.$router.push({ name: 'computers.index' });
    },
  },
};
</script>

<style lang="scss" scoped>
.version-list {
  th {
    color: var(--color-font-base);
  }
  td,
  th {
    padding: spacing();
  }

  tr:nth-child(2n + 3) {
    background-color: var(--color-shadow-base);
  }
  display: grid;
  row-gap: 8px;
  li {
    padding-top: 8px;
    border-top: 1px solid;
    margin-top: 8px;
  }
}

.latest + li {
  padding-top: 8px;
  border-top: 1px solid;
  margin-top: 8px;
}
</style>
