<template>
  <div class="gp-content" role="tabpanel">
    <div class="dashboard-container scroll-vertical">
      <template v-if="canAccessComputers">
        <h1>Computers</h1>
        <div class="g-wrap">
          <HeroNumber
            v-if="computersWithAlertsCount || computersWithAlertsCount === 0"
            id="computerAlertCount"
            @click="routeTo('alerts.index', alertsQuery)"
            :class="{ ...isDanger(computersWithAlertsCount) }"
            :value="computersWithAlertsCount"
            :label="`Computer${isPlural(
              computersWithAlertsCount
            )} with alerts in the past ${twentyfourHours}`"
          />
          <HeroNumber
            v-if="computersWithIssuesCount || computersWithIssuesCount === 0"
            id="computerInsightsCount"
            @click="routeTo('computers.index', complianceRulesQuery)"
            :value="computersWithIssuesCount"
            :label="`Computer${isPlural(
              computersWithIssuesCount
            )} with noncompliant ${
              isFeatureEnabled('COMPLIANCE_DASHBOARD')
                ? 'Compliance Rules'
                : 'Insights'
            }`"
          />
          <HeroNumber
            v-if="isFeatureEnabled('COMPUTER_CONNECTION_STATUS')"
            id="computerConnection"
            @click="routeTo('computers.index', connectionQuery)"
            :class="{ ...isDanger(pastDueComputers) }"
            :value="pastDueComputers"
            :label="`Computer${isPlural(
              lateComputers
            )} not connected for 30 or more days`"
          />
          <HeroNumber
            v-else
            id="computerCheckin"
            @click="routeTo('computers.index', checkinQuery)"
            :value="lateComputers"
            :label="`Computer${isPlural(
              lateComputers
            )} ${sevenDays} past last check-in`"
          />
          <HeroNumber
            id="computersCount"
            @click="$router.push({ name: 'computers.index' })"
            :value="totalComputers"
            :label="`Total Computer${isPlural(totalComputers)}`"
          />
        </div>
      </template>
      <template v-if="canAccessAlerts">
        <h1>Alerts</h1>
        <SeverityStatusTimeSeries @updated="loadCounts" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import HeroNumber from '@/components/HeroNumber.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import SeverityStatusTimeSeries from '@/components/SeverityStatusTimeSeries.vue';
import { SEVERITY } from '@/util/constants/severity.types';
import { COMPUTER_CONNECTION_STATUS } from '@/util/constants/computer-status.types';

export default {
  name: 'DashBoardMain',
  components: {
    HeroNumber,
    SeverityStatusTimeSeries,
  },
  setup() {
    const { canAccess } = useRBAC();

    return { canAccess };
  },
  data() {
    const sevenDays = '7 days';
    return {
      FILTER_TYPES,
      computersWithAlertsCount: null,
      computersWithIssuesCount: null,
      lateComputers: null,
      pastDueComputers: null,
      totalComputers: null,
      twentyfourHours: '24 hours',
      sevenDays,
      threatEventType: 'GPThreatMatchExecEvent',
      complianceRulesQuery: {
        column: 'insightFailures',
        type: FILTER_TYPES.GREATER_THAN,
        value: '0',
        filterType: 'int',
      },
      checkinQuery: {
        column: 'checkin',
        type: FILTER_TYPES.BEFORE_INTERVAL,
        value: sevenDays,
        filterType: 'date',
      },
      connectionQuery: {
        column: 'lastDisconnection|connectionStatus',
        type: `${FILTER_TYPES.BEFORE_INTERVAL}|${FILTER_TYPES.EQUALS}`,
        value: `30 days|${COMPUTER_CONNECTION_STATUS.Disconnected}`,
        filterType: 'date|text',
      },
      alertsQuery: {
        column: 'created|severity',
        type: `${FILTER_TYPES.AFTER_INTERVAL}|${FILTER_TYPES.NOT_EQUAL}`,
        value: `24 hours|${SEVERITY.Informational}`,
        filterType: 'date|int',
      },
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    canAccessComputers() {
      return this.canAccess(RBAC_RESOURCE.COMPUTER);
    },
    canAccessAlerts() {
      return this.canAccess(RBAC_RESOURCE.ALERT);
    },
  },
  mounted() {
    if (this.canAccessComputers) {
      this.loadCounts();
    }
  },
  methods: {
    routeTo(name, query) {
      this.$router.push({ name, query });
    },
    isPlural(value) {
      return value === 1 ? '' : 's';
    },
    isDanger(value) {
      return { danger: value > 0 };
    },
    async loadCounts() {
      const { lateCount, issuesCount, allCount, alertCounts } =
        await this.$store.dispatch('primary/getOverviewCount', {
          hasConnection: this.isFeatureEnabled('COMPUTER_CONNECTION_STATUS'),
        });

      this.computersWithIssuesCount = issuesCount?.insightsComputers;

      this.lateComputers = lateCount?.computers;

      this.pastDueComputers = issuesCount?.computers;

      this.totalComputers = allCount?.computers;

      this.computersWithAlertsCount = alertCounts?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  padding: spacing(2);
  height: 100%;

  h1 {
    @include header4;
    align-self: flex-end;
    padding-bottom: spacing();
    padding-top: spacing(2);

    &:first-of-type {
      padding-top: 0;
    }
  }
}
</style>
