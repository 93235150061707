<template>
  <div v-if="params.data" class="icon-cell" :data-testid="testId">
    <j-icon
      v-if="icon && leading"
      :data="icon"
      :color="color"
      :height="size"
      :width="size"
    />
    <span v-if="typeof value !== 'undefined'">
      <router-link
        v-if="to"
        :to="to"
        ref="link"
        class="table-link"
        @click="actions"
      >
        {{ value }}
      </router-link>
      <template v-else>{{ value }}</template>
    </span>
    <j-icon
      v-if="icon && trailing"
      :data="icon"
      :color="color"
      :height="size"
      :width="size"
    />
  </div>
</template>

<script>
import { useTableCell } from './tableCell';

export default {
  name: 'TableCellIcon',
  setup(props) {
    const {
      position,
      addEventListener,
      routeTo,
      link,
      leading,
      trailing,
      actions,
    } = useTableCell(props);
    let to = null;
    const settings = {
      icon: null,
      color: 'currentColor',
      value: null,
      size: '16',
      testId: 'dash',
    };
    if (props.params.setIcon) {
      const config = props.params.setIcon(
        props.params.valueFormatted || props.params.value,
        props.params
      );
      if (!config.icon && !config.value) {
        settings.icon = config;
        settings.testId = config?.name;
      } else if (config !== null) {
        settings.size = config.isSmall ? '12' : settings.size;
        settings.icon = config.icon;
        settings.color = config.color;
        settings.value = config.value;
        settings.testId = config?.icon?.name;
        position.value = config.position || position.value;
      }
    } else {
      // eslint-disable-next-line
      console.error(`[Ag-grid Column error] The icon cell component requires a setIcon function in cellRendererParams to set the name of the icon. Returns: Null | Icon | Object
      Example: cellRendererParams: { setIcon: value => (value ? warning : { icon: check, color: 'var(--color-success-base)' }) }`);
    }

    if (props.params.route) {
      addEventListener('cellKeyDown', routeTo);
      to = props.params.route(props.params);
    }
    return { position, link, to, ...settings, leading, trailing, actions };
  },
};
</script>

<style lang="scss" scoped>
.icon-cell {
  @include grid(spacing(0.5));
  @include grid-columns;
  justify-content: flex-start;
  align-items: center;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
