<template>
  <div class="event-def">
    <div class="def-description">
      <div class="g-cols ai-center max-c gap-1">
        <AnalyticEventTypeIcon v-if="def.icon" :input-type="def.type" />
        <j-icon v-else data="@icon/objects.svg" />
        <h1 class="h4">
          {{ def.label }}
        </h1>
      </div>
      <p v-if="def.description" class="pt-1 helper-text">
        {{ def.description }}
      </p>
      <a
        v-if="def.native"
        :href="def.native"
        target="_"
        class="g-cols max-c gap-1"
      >
        <j-icon data="@jcon/apple.svg" />
        <span>Apple Documentation Link</span>
      </a>
    </div>
    <div class="def-table scroll-vertical">
      <FieldDefinitionTable :def="def" />
    </div>
  </div>
</template>

<script>
import FieldDefinitionTable from '@/components/analytics/FieldDefinitionTable.vue';
import AnalyticEventTypeIcon from '@/components/analytics/AnalyticEventTypeIcon.vue';

export default {
  name: 'EventDefinitionDetails',
  components: { FieldDefinitionTable, AnalyticEventTypeIcon },
  props: {
    def: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.event-def {
  display: flex;
  flex-direction: column;
  flex: 1;

  .def-description {
    flex: 0;
    padding: spacing();
    border-bottom: 2px solid var(--color-border-secondary);

    a {
      margin-top: 10px;
    }
  }

  .def-table {
    flex: 1;
    height: 100%;
  }
}
</style>
