<template>
  <ConnectionIndicator :status="connectionStatus" />
</template>

<script>
import ConnectionIndicator from '@/components/ConnectionIndicator.vue';
import { useComputers } from '@/modules/computers/computers.js';

export default {
  components: { ConnectionIndicator },
  name: 'ComputerTableCellConnectionStatus',
  setup(props) {
    const { connectionStatus } = useComputers(props);

    return { connectionStatus };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>
