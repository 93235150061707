export const FILTER_TYPES = {
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  CONTAINS: 'contains',
  EQUALS: 'equals',
  NOT_EQUAL: 'notEqual',
  LESS_THAN_OR_EQUAL: 'lessThanOrEqual',
  LESS_THAN: 'lessThan',
  BEFORE_INTERVAL: 'beforeInterval',
  AFTER_INTERVAL: 'afterInterval',
  GREATER_THAN_OR_EQUAL: 'greaterThanOrEqual',
  GREATER_THAN: 'greaterThan',
  JSON_CONTAINS: 'jsonContains',
  JSON_CONTAINS_KEY: 'jsonContainsKey',
  JSON_CONTAINS_ALL_KEYS: 'jsonContainsAllKeys',
  JSON_CONTAINS_ANY_KEYS: 'jsonContainsAnyKeys',
  JSON_CONTAINED_BY: 'jsonContainedBy',
  ARRAY_INCLUDES: 'includes',
  ARRAY_ANY_NOT_EQUAL: 'anyNotEqualTo',
  ARRAY_ANY_EQUAL: 'anyEqualTo',
};

// Used to display specific characters to represent the type
export const FILTER_TYPES_CHARS = {
  [FILTER_TYPES.NOT_EQUAL]: '≠ ',
  [FILTER_TYPES.LESS_THAN]: '< ',
  [FILTER_TYPES.LESS_THAN_OR_EQUAL]: '⋜ ',
  [FILTER_TYPES.GREATER_THAN_OR_EQUAL]: '⋝ ',
  [FILTER_TYPES.GREATER_THAN]: '> ',
  [FILTER_TYPES.EQUALS]: '',
  [FILTER_TYPES.STARTS_WITH]: '',
  [FILTER_TYPES.ENDS_WITH]: '',
  [FILTER_TYPES.BEFORE_INTERVAL]: '',
  [FILTER_TYPES.AFTER_INTERVAL]: '',
  [FILTER_TYPES.CONTAINS]: '',
  [FILTER_TYPES.ARRAY_ANY_EQUAL]: '',
  [FILTER_TYPES.ARRAY_ANY_NOT_EQUAL]: '',
  [FILTER_TYPES.ARRAY_INCLUDES]: '',
  [FILTER_TYPES.JSON_CONTAINED_BY]: '',
  [FILTER_TYPES.JSON_CONTAINS]: '',
  [FILTER_TYPES.JSON_CONTAINS_ALL_KEYS]: '',
  [FILTER_TYPES.JSON_CONTAINS_ANY_KEYS]: '',
  [FILTER_TYPES.JSON_CONTAINS_KEY]: '',
};
