<template>
  <j-button style-type="ghost-primary" class="help-toggle">
    <template #leading>
      <j-icon data="@jcon/question.svg" height="16" />
    </template>
    <template v-if="!iconOnly" #default>{{ helpMessage }}</template>
  </j-button>
</template>

<script>
export default {
  name: 'HelpButton',
  compatConfig: {
    MODE: 3,
  },
  props: {
    toggleMessage: Boolean,
    iconOnly: Boolean,
  },
  computed: {
    helpMessage() {
      return this.toggleMessage ? 'Hide Help' : 'Show Help';
    },
  },
};
</script>

<style lang="scss" scoped>
.help-toggle {
  --size-action-height-base: var(--size-action-height-secondary);
  padding: spacing(0.5);
  svg {
    min-width: 24px;
  }
}
</style>
