import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRBAC } from '@/composables/rbac';
import { useForm } from '@/composables/forms';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export function useAccount(permissions = []) {
  const { canAccess, canWrite, isReadOnly, cloudOnlyAccess } =
    useRBAC(permissions);
  const { v$, mode, handleSubmit } = useForm();
  const $store = useStore();

  // user information
  const users = computed(() => $store.state.primary.account.users);
  const getUsers = async () => {
    if (canAccess(RBAC_RESOURCE.USER) && !users.value) {
      await $store.dispatch('primary/listUsers', {
        direction: 'ASC',
        field: 'email',
      });
    }
  };

  const associatedUsers = (id, kind = 'assignedRoles') => {
    return (
      users.value
        ?.filter((user) =>
          user[kind]?.some(({ id: associatedId }) => id === associatedId)
        )
        .map(({ email, sub, id: userId }) => ({
          value: email || sub,
          route: { name: 'account.users.detail', params: { id: userId } },
        })) || []
    );
  };

  // role information
  const roles = computed(() => $store.state.primary.account.roles);
  const getRoles = async () => {
    if (canAccess(RBAC_RESOURCE.ROLE) && !roles.value) {
      await $store.dispatch('primary/listRoles', {
        direction: 'DESC',
        field: 'name',
      });
    }
  };
  const roleOptions = computed(() =>
    roles.value?.map(({ name, id }) => ({ label: name, value: id }))
  );

  // group information
  const groups = computed(() => $store.state.primary.account.groups);
  const getGroups = async () => {
    if (canAccess(RBAC_RESOURCE.GROUP) && !groups.value) {
      await $store.dispatch('primary/listGroups', {
        direction: 'DESC',
        field: 'name',
      });
    }
  };
  const groupOptions = computed(() =>
    groups.value
      ?.filter(({ connection }) => !connection)
      .map(({ name, id }) => ({ label: name, value: id }))
  );

  const associatedGroups = (id) => {
    return (
      groups.value
        ?.filter(
          ({ assignedRoles, connection }) =>
            assignedRoles.some(({ id: roleId }) => id === roleId) && !connection
        )
        .map(({ name, id: groupId }) => ({
          value: name,
          route: { name: 'account.groups.detail', params: { id: groupId } },
        })) || []
    );
  };

  // connection information
  const connections = computed(() =>
    $store.state.primary.account.connections?.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
  );

  const getConnections = async () => {
    if (canAccess(RBAC_RESOURCE.CONNECTION) && !connections.value) {
      await $store.dispatch('primary/listConnections', {
        direction: 'DESC',
        field: 'name',
      });
    }
  };
  const associatedConnections = (id) => {
    return (
      groups.value
        ?.filter(
          ({ assignedRoles, connection }) =>
            assignedRoles.some(({ id: roleId }) => id === roleId) &&
            !!connection
        )
        .map(({ name }) => ({
          value: name,
          route: { name: 'account.idp' },
        })) || []
    );
  };

  const isSearchable = (options) => {
    return options.length > 10;
  };

  return {
    users,
    getUsers,
    associatedUsers,
    roles,
    roleOptions,
    getRoles,
    groups,
    getGroups,
    associatedGroups,
    groupOptions,
    connections,
    getConnections,
    associatedConnections,
    isSearchable,
    v$,
    mode,
    canAccess,
    canWrite,
    isReadOnly,
    handleSubmit,
    cloudOnlyAccess,
  };
}
