import macbook from '@icon/macbook.svg';
import macpro from '@icon/macpro.svg';
import macmini from '@icon/macmini.svg';
import imac from '@icon/imac.svg';

export const macModelImage = (modelName) => {
  let name = macbook;
  if (typeof modelName === 'string') {
    if (modelName.toLowerCase().includes('macpro')) {
      name = macpro;
    } else if (modelName.toLowerCase().includes('macmini')) {
      name = macmini;
    } else if (modelName.toLowerCase().includes('imac')) {
      name = imac;
    }
  }
  return name;
};
