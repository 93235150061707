<template>
  <JamfTable
    v-if="!loading"
    :get-data="getData"
    suppress-filter-bar
    suppress-search
    :columns="columns"
  >
    <template v-if="canWrite" #left>
      <j-create-button
        @click="$router.push({ name: 'account.users.create' })"
        label="Create User"
      />
    </template>
    <template v-if="canWrite" #right="{ selected }">
      <j-delete-button
        @click="confirmRemoveMultiple(selected)"
        :is-disabled="selected.length === 0"
        :label="`Delete (${selected.length})`"
      />
    </template>
    <template #modals="{ refresh, selected }">
      <j-delete-modal
        v-model="showRemoveModal"
        :to-delete="toDelete"
        @confirm="remove(selected, refresh)"
      />
    </template>
  </JamfTable>
</template>

<script>
import { unionBy } from 'lodash';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import JamfTable from '@/components/table/JamfTable.vue';
import RolesTooltip from './components/RolesTooltip.vue';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'AccountUsers',
  // eslint-disable-next-line vue/no-unused-components
  components: { JamfTable, RolesTooltip },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.USER);
    return { canWrite };
  },
  data() {
    return {
      loading: true,
      showRemoveModal: false,
      columns: [
        {
          headerName: 'User',
          field: 'email',
          flex: 2,
          sort: 'asc',
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'account.users.detail',
              params: { id: params?.data?.id },
            }),
          },
          valueGetter: ({ data }) => data?.email || data?.sub,
        },
        {
          headerName: 'Role(s)',
          field: 'assignedRoles',
          type: 'tagsColumn',
          permissions: [RBAC_RESOURCE.ROLE, RBAC_RESOURCE.CLOUD_ACCESS],
          flex: 2,
          minWidth: 160,
          tooltipField: 'assignedRoles',
          tooltipComponent: 'RolesTooltip',
          valueFormatter: (params) => this.combineRoles(params),
          cellRendererParams: {
            route: (tag) => ({
              name: 'account.roles.detail',
              params: { id: tag.id },
            }),
          },
        },
        {
          headerName: 'Group(s)',
          field: 'assignedGroups',
          type: 'tagsColumn',
          permissions: [RBAC_RESOURCE.GROUP, RBAC_RESOURCE.CLOUD_ACCESS],
          minWidth: 160,
          valueFormatter: ({ value }) =>
            value?.map(({ name, id }) => ({ value: name, id })) || [],
          cellRendererParams: {
            route: (tag) => ({
              name: 'account.groups.detail',
              params: { id: tag.id },
            }),
          },
        },
        {
          headerName: 'Identity Provider',
          field: 'connection',
          permissions: [RBAC_RESOURCE.CONNECTION],
          valueGetter: (params) => params?.data?.connection?.name || 'None',
        },
        {
          headerName: 'Last Login',
          type: 'dateColumn',
          field: 'lastLogin',
          sortable: true,
          valueGetter: (params) => params?.data?.lastLogin || 'Never',
        },
        {
          headerName: 'Send Email Notifications',
          field: 'receiveEmailAlert',
          sortable: true,
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          flex: 1,
          valueGetter: (params) =>
            params?.data?.receiveEmailAlert ? 'Yes' : 'No',
        },
        {
          headerName: 'Email Severity',
          field: 'emailAlertMinSeverity',
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          type: 'tagsColumn',
          minWidth: 160,
          valueGetter: (params) => params?.data?.emailAlertMinSeverity || 'N/A',
        },
        {
          headerName: 'Modified',
          sortable: true,
          field: 'updated',
          type: 'dateColumn',
        },
      ],
      toDelete: [],
    };
  },
  created() {
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
    this.loading = false;
  },
  methods: {
    confirmRemoveMultiple(selected) {
      this.showRemoveModal = true;
      this.toDelete = selected.map(({ email, sub }) => email || sub);
    },
    combineRoles(params) {
      if (params.data) {
        const roles =
          params.data?.assignedRoles.map(({ name, id }) => ({
            value: name,
            id,
            type: 'secondary',
            icon: 'user-2',
          })) || [];
        const groupRoles =
          params.data?.assignedGroups?.flatMap(({ assignedRoles }) =>
            assignedRoles?.map(({ name, id }) => ({
              value: name,
              id,
              type: null,
              icon: 'users',
            }))
          ) || [];

        return unionBy(roles, groupRoles, 'value');
      }
      return null;
    },
    async remove(selected, refresh) {
      await Promise.all(
        selected.map(({ id }) =>
          this.$store.dispatch('primary/deleteUser', { id })
        )
      );
      refresh();
    },
    async getData(params) {
      return this.$store.dispatch('primary/listUsers', params);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-roles {
  --color-input-tag: var(--color-indicator-tag-secondary);
}
.email-severity {
  margin-left: spacing(4);
}
</style>
