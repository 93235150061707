import GET_ORGANIZATION_DOWNLOADS from './queries/getOrganizationDownloads.gql';
import GENERATE_UNINSTALLER_TOKEN from './queries/generateUninstallerToken.gql';
import UPDATE_INSTALLER_UUID from './mutations/updateInstallerUuid.gql';

export default {
  actions: {
    async getOrganizationDownloads({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ORGANIZATION_DOWNLOADS,
          variables: payload,
        },
        { root: true }
      );
      return result?.data ?? {};
    },
    async updateInstallerUuid({ dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_INSTALLER_UUID,
          key: 'updateInstallerUuid',
          variables: payload,
        },
        { root: true }
      );
      return result;
    },
    async generateUninstallerToken({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GENERATE_UNINSTALLER_TOKEN,
          variables: payload,
        },
        { root: true }
      );
      return result;
    },
  },
};
