<template>
  <div class="scroll-vertical p-2 related-users">
    <transition-group name="list" tag="div" class="g-rows max-c">
      <Card v-for="(user, index) in users" :key="index" class="spacer g-rows">
        <ListItem title="Name">
          {{ user?.name }}
        </ListItem>
        <ListItem title="uid">
          {{ user?.uid }}
        </ListItem>
        <ListItem title="uuid">
          {{ user?.uuid }}
        </ListItem>
      </Card>
      <NoRelatedInfo v-if="!users.length" title="0 Users" :key="'0-empty'" />
    </transition-group>
  </div>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
import Card from '@/components/Card.vue';
import NoRelatedInfo from './NoRelatedInfo.vue';

export default {
  name: 'AlertRelatedUsers',
  compatConfig: {
    MODE: 3,
  },
  components: {
    ListItem,
    Card,
    NoRelatedInfo,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
};
</script>
