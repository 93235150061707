<template>
  <div class="g-rows">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Match Reason">
      <div class="no-break">
        <template v-if="event.matchType === 'Threat Signature'"
          >The executable
          <strong>{{
            event.scriptPath
              ? event.scriptPath
              : !!event.process
              ? event.process.path
              : related.processes[0].path
          }}</strong>
          was detected as
          <strong>{{ event.matchName || event.matchValue }}</strong> because it
          matched a known bad <strong>threat</strong> signature.
        </template>
        <template v-else
          >The executable
          <strong>{{
            !!event.process ? event.process.path : related.processes[0].path
          }}</strong>
          was detected as
          <strong>{{ event.matchName || event.matchValue }}</strong> because it
          matched a known bad <strong>certificate</strong>.
        </template>
      </div>
    </ListItem>
    <ListItem title="Match Name">
      {{ event.matchName || event.matchValue }}
    </ListItem>
    <ListItem title="Match Type">
      {{ event.matchType }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPThreatMatchExecEvent',
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.no-break {
  word-break: normal;
}
</style>
