<script>
import { uuid, trim } from '@/util';
import { minSeverity, maxSeverity } from '@/util/custom-validators';

import Card from '@/components/Card.vue';
import { SEVERITY } from '@/util/constants/severity.types';
import { useVuelidate } from '@vuelidate/core';
import { required, url, helpers } from '@vuelidate/validators';
import { reactive, watchEffect } from 'vue';

export default {
  name: 'EndpointsForm',
  components: { Card },
  props: {
    endpoints: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    required: Object,
    parentDirty: String,
    hasSeverity: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:endpoints'],
  compatConfig: {
    MODE: 3,
  },
  setup(props, ctx) {
    const severity = {
      minSeverity: SEVERITY.Informational,
      maxSeverity: SEVERITY.High,
    };

    const state = reactive({
      internalEndpoints: props.endpoints.map((endpoint) => ({
        ...(props.hasSeverity ? severity : {}),
        ...endpoint,
        key: uuid(),
        headers: endpoint.headers.map((header) => ({
          key: uuid(),
          ...header,
        })),
      })),
    });
    const trimmedTitle = trim(props.title);

    watchEffect(() => {
      const removeKey = state.internalEndpoints.map((endpoint) => {
        const e = { ...endpoint };
        delete e.key;
        return {
          ...e,
          headers: e.headers.map((header) => {
            const h = { ...header };
            delete h.key;
            return { ...h };
          }),
        };
      });
      ctx.emit('update:endpoints', removeKey);
    });

    const rules = {
      internalEndpoints: {
        $each: helpers.forEach({
          url: { required, url },
          minSeverity: {
            required,
            min: minSeverity,
          },
          maxSeverity: {
            required,
            max: maxSeverity,
          },
        }),
      },
    };

    const v$ = useVuelidate(rules, state);

    function removeEndpoint(key) {
      const index = state.internalEndpoints.findIndex(
        ({ key: k }) => k === key
      );
      state.internalEndpoints.splice(index, 1);
    }

    function addEndpoint() {
      state.internalEndpoints.push({
        ...(props.hasSeverity ? severity : {}),
        key: uuid(),
        url: '',
        headers: [],
      });
    }

    function addHeader(key) {
      const index = state.internalEndpoints.findIndex(
        ({ key: k }) => k === key
      );
      state.internalEndpoints[index].headers.push({
        key: uuid(),
        header: '',
        value: '',
      });
    }

    function removeHeader(endpointKey, headerkey) {
      const index = state.internalEndpoints.findIndex(
        ({ key }) => key === endpointKey
      );
      const headerIndex = state.internalEndpoints[index].headers.findIndex(
        ({ key }) => key === headerkey
      );
      state.internalEndpoints[index].headers.splice(headerIndex, 1);
    }

    function validate(error) {
      // $each doesnt seem to care about the dirty state, so need to explicitly check it
      return error && v$.value.$dirty;
    }
    function minMaxError(v) {
      return validate(v?.minSeverity.$error || v?.maxSeverity.$error);
    }
    return {
      minMaxError,
      validate,
      removeHeader,
      addEndpoint,
      addHeader,
      removeEndpoint,
      v$,
      state,
      trimmedTitle,
    };
  },
};
</script>

<template>
  <div class="endpoints-form">
    <div class="g-cols max-c ai-center">
      <j-create-button
        @click="addEndpoint()"
        data-feature-id="endpoint-add-button"
        :label="title"
      />
      <p>{{ endpoints.length }} Endpoints</p>
    </div>
    <Card
      class="endpoint-card"
      v-for="(endpoint, index) in state.internalEndpoints"
      :key="endpoint.key"
    >
      <div class="card-title">
        <h2>HTTP Endpoint</h2>
        <j-remove-button
          @click="removeEndpoint(endpoint.key)"
          data-feature-id="endpoint-remove-button"
        />
      </div>
      <div class="endpoint-settings">
        <j-input
          v-model="endpoint.url"
          class="endpoint-url"
          placeholder="https://example.com"
          label="URL"
          :has-error="
            validate(
              v$.internalEndpoints.$each.$response.$data[index].url.$error
            )
          "
          error-text="Valid url is required"
        />
        <div v-if="hasSeverity" class="g-rows gap-0">
          <div
            class="g-cols max-c"
            :aria-describedby="
              minMaxError(v$.internalEndpoints.$each.$response.$data[index])
                ? `${index}-${trimmedTitle}-severity-alert`
                : ''
            "
          >
            <slot
              name="severity"
              :endpoint="endpoint"
              :has-error="
                minMaxError(v$.internalEndpoints.$each.$response.$data[index])
              "
              :id="`${index}-${trimmedTitle}-severity`"
            ></slot>
          </div>
          <p
            v-if="
              minMaxError(v$.internalEndpoints.$each.$response.$data[index])
            "
            class="error-text helper-text"
            :id="`${index}-${trimmedTitle}-severity-alert`"
          >
            Min severity must be less than or equal to max severity.
          </p>
        </div>
      </div>

      <j-create-button
        @click="addHeader(endpoint.key)"
        style-type="ghost-primary"
        data-feature-id="endpoint-add-header-button"
        label="Add HTTP Header"
      />
      <div
        v-for="header in endpoint.headers"
        :key="header.key"
        class="header-row"
      >
        <j-remove-button
          data-feature-id="endpoint-remove-header-button"
          @click="removeHeader(endpoint.key, header.key)"
        />
        <j-input
          v-model="header.header"
          class="endpoint-header"
          placeholder="Name"
        />
        <j-input
          v-model="header.value"
          class="endpoint-header"
          placeholder="Value"
        />
      </div>
    </Card>
  </div>
</template>

<style lang="scss" scoped>
.endpoints-form {
  @include grid;
}

.endpoint-card {
  @include grid;
  --size-select-container-width: auto;

  .endpoint-settings {
    @include grid;
    @include grid-columns(auto max-content);
  }

  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: spacing();

    h2 {
      @include header4;
    }
  }

  .add-http-header {
    width: max-content;
  }

  .header-row {
    @include grid;
    @include grid-columns(min-content auto auto);
    align-items: center;
  }
}
</style>
