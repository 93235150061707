<template>
  <div class="p-2 scroll-vertical">
    <div class="f-wrap max-c">
      <Card data-feature-id="card-details-1" class="g-col-2">
        <ListItem title="Name">
          {{ telemetry.name }}
        </ListItem>
        <ListItem title="Description" class="col-span-1">
          {{ telemetry.description }}
        </ListItem>
        <ListItem title="Verbose">
          {{ telemetry.verbose ? 'Yes' : 'No' }}
        </ListItem>
        <ListItem title="Level">
          {{ telemetry.level }}
        </ListItem>
        <ListItem
          v-if="canAccessPlans"
          title="Associated Plans"
          class="col-span-1"
        >
          <template v-if="hasPlans">
            <j-tag
              v-for="plan in telemetry.plans"
              :key="plan.id"
              @click="
                $router.push({
                  name: 'plans.index.detail',
                  params: { id: plan.id },
                })
              "
            >
              {{ plan.name }}
            </j-tag>
          </template>
        </ListItem>
        <ListItem title="Created">
          {{ formatEpochDate(telemetry.created) }}
        </ListItem>
        <ListItem title="Updated">
          {{ formatEpochDate(telemetry.updated) }}
        </ListItem>
        <ListItem
          v-if="isFeatureEnabled('TELEMETRY_EO3')"
          title="Performance Metrics"
        >
          <div
            class="g-cols max-c ai-center gap-1"
            v-if="telemetry.performanceMetrics"
          >
            <j-icon
              data="@jcon/check-circle.svg"
              color="var(--color-success-base)"
            />
            Yes
          </div>
        </ListItem>
        <ListItem
          title="Collect Diagnostic and Crash Files"
          v-if="isFeatureEnabled('TELEMETRY_EO3')"
        >
          <div
            class="g-cols max-c ai-center gap-1"
            v-if="telemetry.logFileCollection"
          >
            <j-icon
              data="@jcon/check-circle.svg"
              color="var(--color-success-base)"
            />
            Yes
          </div>
        </ListItem>

        <ListItem title="Simple Log Files" class="col-span-1">
          <div v-if="telemetry.logFiles.length > 0" class="f-wrap max-c">
            <code v-for="file in telemetry.logFiles" :key="file">{{
              file
            }}</code>
          </div>
        </ListItem>
      </Card>
      <Card
        v-if="telemetry.localLogConfig"
        data-feature-id="card-details-2"
        class="g-col-2 g-rows-max-c"
      >
        <label class="col-span-1 subtitle-text">Local log file</label>
        <ListItem title="Ownership">
          {{ telemetry.localLogConfig.fileOwnership }}
        </ListItem>
        <ListItem title="Permissions">
          {{ telemetry.localLogConfig.filePermission }}
        </ListItem>
        <ListItem title="Max number of backups">
          {{ telemetry.localLogConfig.maxBackups }}
        </ListItem>
        <ListItem title="Max size">
          {{ telemetry.localLogConfig.maxSizeMB }} MB
        </ListItem>
        <ListItem title="Path" class="col-span-1">
          {{ telemetry.localLogConfig.path }}
        </ListItem>
      </Card>
    </div>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import { useRBAC } from '@/composables/rbac';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';
import { mapGetters } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'TelemetryDetails',
  components: {
    Card,
    ListItem,
  },
  setup() {
    const { formatEpochDate } = useGmt();
    const { canAccess } = useRBAC();

    return { formatEpochDate, canAccess };
  },
  props: {
    telemetry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    logFiles() {
      return this.telemetry.logFiles?.join();
    },
    canAccessPlans() {
      return this.canAccess(RBAC_RESOURCE.PLAN);
    },
    hasPlans() {
      return this.telemetry.plans?.length > 0;
    },
  },
};
</script>
