<template>
  <div class="gp-content">
    <j-form-bar
      v-if="mode.create || unifiedLoggingFilter"
      :title="mode.create ? 'Create Filter' : name"
      @back="routeTo()"
      @submit:created="routeTo('detail')"
      @cancel="routeTo('detail')"
      @edit="routeTo('edit')"
      @remove="confirmRemove(unifiedLoggingFilter)"
      :can-write="canWrite"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component
          :is="Component"
          v-if="!loading"
          :data="unifiedLoggingFilter"
        />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      @confirm="remove()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRouteHelpers } from '@/composables/route-helpers';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'UnifiedLoggingFilter',
  setup() {
    const { showRemoveModal, toDelete, confirmRemove } = useRemoveHelpers();
    const { mode } = useRouteHelpers();
    const { canWrite } = useRBAC(RBAC_RESOURCE.UNIFIED_LOGGING_FILTER);
    return {
      showRemoveModal,
      toDelete,
      mode,
      confirmRemove,
      canWrite,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('primary', {
      unifiedLoggingFilter: (state) =>
        state.unifiedlogging.unifiedLoggingFilter,
    }),
    name() {
      return this.unifiedLoggingFilter?.name;
    },
  },
  async beforeMount() {
    if (!this.mode.create) {
      await this.$store.dispatch('primary/getUnifiedLoggingFilter', {
        uuid: this.$route.params.id,
      });
    }
    this.loading = false;
  },
  methods: {
    routeTo(name) {
      const parent = 'unifiedLogging.index';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: name ? { id: this.unifiedLoggingFilter?.uuid } : {},
      });
    },
    async remove() {
      this.loading = true;
      this.showRemoveModal = false;
      const response = await this.$store.dispatch(
        'primary/deleteUnifiedLoggingFilter',
        {
          uuid: this.unifiedLoggingFilter.uuid,
        }
      );
      if (response) {
        this.$router.push({ name: 'unifiedLogging.index' });
      }
    },
  },
};
</script>
