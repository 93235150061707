export const columnOptionsTimeIntervals = [
  {
    label: 'Over the past 7 Days',
    value: '7 days',
  },
  {
    label: 'Over the past 30 Days',
    value: '30 days',
  },
  {
    label: 'Over the past 2 Months',
    value: '2 Months',
  },
  {
    label: 'Over the past 4 Months',
    value: '4 Months',
  },
  {
    label: 'Over the past 6 Months',
    value: '6 Months',
  },
];
