import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import gql from 'graphql-tag';
import { UserFields } from './fragments/user.fragment.gql';
import { ConnectionFields } from './fragments/connection.fragment.gql';
import { GroupFields } from './fragments/group.fragment.gql';

export const CREATE_GROUP = (getPermissions) => {
  const hasRole = getPermissions([RBAC_RESOURCE.ROLE]);
  return gql`
    mutation createGroup(
      $name: String!
      $connectionId: ID
      $accessGroup: Boolean
      ${hasRole ? '$roleIds: [ID],' : ''}
      $RBAC_Connection: Boolean!
      $RBAC_Role: Boolean! 
    ) {
      createGroup(input: {
        name: $name,
        connectionId: $connectionId,
        accessGroup: $accessGroup,
        ${hasRole ? 'roleIds: $roleIds,' : ''}
      }){
        ...GroupFields
      }
    }
    ${GroupFields}
  `;
};

export const UPDATE_GROUP = (getPermissions) => {
  const hasRole = getPermissions([RBAC_RESOURCE.ROLE]);
  return gql`
    mutation updateGroup(
      $id: ID!
      $name: String!
      $accessGroup: Boolean
      ${hasRole ? '$roleIds: [ID],' : ''}
      $RBAC_Connection: Boolean!
      $RBAC_Role: Boolean!
    ) {
      updateGroup(id: $id, input: {
        name: $name,
        accessGroup: $accessGroup,
        ${hasRole ? 'roleIds: $roleIds,' : ''}
      }){
        ...GroupFields
      }
    }
    ${GroupFields}
  `;
};

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;

export const CREATE_USER = (getPermissions) => {
  const hasRole = getPermissions([RBAC_RESOURCE.ROLE]);
  const hasGroup = getPermissions([RBAC_RESOURCE.GROUP]);
  return gql`
  mutation createUser(
    $email: AWSEmail!
    ${hasRole ? '$roleIds: [ID],' : ''} 
    ${hasGroup ? '$groupIds: [ID],' : ''}
    $connectionId: ID
    $receiveEmailAlert: Boolean!
    $emailAlertMinSeverity: SEVERITY!
    $RBAC_Role: Boolean!
    $RBAC_Group: Boolean!
    $RBAC_Connection: Boolean!
    $hasLimitedAppAccess: Boolean!) {
    createUser(input: {
      email: $email
      ${hasRole ? 'roleIds: $roleIds' : ''}
      ${hasGroup ? 'groupIds: $groupIds' : ''}
      connectionId: $connectionId
      receiveEmailAlert: $receiveEmailAlert
      emailAlertMinSeverity: $emailAlertMinSeverity
    }) {
      ...UserFields
    }
  }
  ${UserFields}
`;
};

export const UPDATE_USER = (getPermissions) => {
  const hasRole = getPermissions([RBAC_RESOURCE.ROLE]);
  const hasGroup = getPermissions([RBAC_RESOURCE.GROUP]);
  return gql`
    mutation updateUser(
      $id: ID!
      ${hasRole ? '$roleIds: [ID],' : ''} 
      ${hasGroup ? '$groupIds: [ID],' : ''}
      $receiveEmailAlert: Boolean!
      $emailAlertMinSeverity: SEVERITY!
      $RBAC_Role: Boolean!
      $RBAC_Group: Boolean!
      $RBAC_Connection: Boolean!
      $hasLimitedAppAccess: Boolean!
    ) {
      updateUser(id: $id, input: {
        ${hasRole ? 'roleIds: $roleIds,' : ''}
        ${hasGroup ? 'groupIds: $groupIds,' : ''}
        receiveEmailAlert: $receiveEmailAlert,
        emailAlertMinSeverity: $emailAlertMinSeverity,
      }) {
        ...UserFields
      }
    }
    ${UserFields}
  `;
};

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONNECTION = gql`
  mutation updateConnection(
    $id: ID!
    $requireKnownUsers: Boolean
    $button: String
  ) {
    updateConnection(
      id: $id
      input: { button: $button, requireKnownUsers: $requireKnownUsers }
    ) {
      ...ConnectionFields
    }
  }
  ${ConnectionFields}
`;

export const UPDATE_FREEZE_CONTROL = gql`
  mutation updateOrganizationConfigFreeze($configFreeze: Boolean!) {
    updateOrganizationConfigFreeze(input: { configFreeze: $configFreeze }) {
      configFreeze
    }
  }
`;
