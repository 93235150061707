<template>
  <j-select
    v-if="isTypeSelectable"
    v-model="typeComputed"
    :is-read-only="isReadOnly"
    :options="typeOptions"
    :searchable="typeOptions.length > 10"
    class="small-select"
    :is-small="!isReadOnly"
    data-feature-id="rule-type"
    placeholder="Select Type"
  />
  <template v-if="typeComputed !== 'AppSigningInfo'">
    <j-input
      v-if="!isReadOnly"
      :placeholder="typesPlaceholder"
      v-model="valueComputed"
      data-feature-id="rule-value"
      :has-error="v$.valueComputed.$error"
    />
    <j-input-group v-else data-feature-id="rule-value">{{
      valueComputed
    }}</j-input-group>
  </template>
  <template v-else>
    <j-input
      placeholder="Team ID"
      :is-read-only="isReadOnly"
      v-model="teamIdComputed"
      data-feature-id="rule-teamId"
      :has-error="v$.teamIdComputed.$error"
    />
    <j-input
      placeholder="Signing ID"
      :is-read-only="isReadOnly"
      v-model="appIdComputed"
      data-feature-id="rule-appId"
      :has-error="v$.appIdComputed.$error"
    />
  </template>
</template>

<script>
import { IGNORE_TYPE, IGNORE_TYPE_PLACEHOLDERS } from './../exception.types';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

export default {
  name: 'ExceptionRule',
  props: {
    isDirty: Boolean,
    typeOptions: { type: Array, required: true },
    isTypeSelectable: Boolean,
    isReadOnly: Boolean,
    type: String,
    value: String,
    teamId: String,
    appId: String,
  },
  emits: ['update:value', 'update:teamId', 'update:appId', 'update:type'],
  setup() {
    const v$ = useVuelidate({ $autoDirty: true });
    return { v$ };
  },
  mounted() {
    if (this.isDirty) {
      this.v$.$touch();
    }
  },
  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
    typeComputed: {
      get() {
        return this.type;
      },
      set(value) {
        this.$emit('update:type', value);
      },
    },
    teamIdComputed: {
      get() {
        return this.teamId;
      },
      set(value) {
        this.$emit('update:teamId', value);
      },
    },
    appIdComputed: {
      get() {
        return this.appId;
      },
      set(value) {
        this.$emit('update:appId', value);
      },
    },
    typesPlaceholder() {
      return IGNORE_TYPE_PLACEHOLDERS[this.type] || '';
    },
  },
  validations() {
    return {
      valueComputed: {
        required: requiredIf(this.typeComputed !== IGNORE_TYPE.AppSigningInfo),
      },
      teamIdComputed: {
        required: requiredIf(this.typeComputed === IGNORE_TYPE.AppSigningInfo),
      },
      appIdComputed: {
        required: requiredIf(this.typeComputed === IGNORE_TYPE.AppSigningInfo),
      },
    };
  },
};
</script>
