export const RBAC_RESOURCE = Object.freeze({
  CLOUD_ACCESS: 'CloudAccess', // webapp only resource for app features that will never be included in offline mode
  ALL: 'all',
  ACTION_CONFIGS: 'ActionConfigs',
  ALERT: 'Alert',
  ANALYTIC_SET: 'AnalyticSet',
  ANALYTIC: 'Analytic',
  API_CLIENT: 'ApiClient',
  AUDIT_LOG: 'AuditLog',
  COMPUTER: 'Computer',
  CONNECTION: 'Connection',
  CONFIG_FREEZE: 'ConfigFreeze',
  GROUP: 'Group',
  INSIGHT: 'Insight',
  ORGANIZATION: 'Organization',
  PLAN: 'Plan',
  PREVENT_LIST: 'PreventList',
  ROLE: 'Role',
  THREAT_PREVENTION_VERSION: 'ThreatPreventionVersion',
  UNIFIED_LOGGING_FILTER: 'UnifiedLoggingFilter',
  USER: 'User',
  DATA_FORWARD: 'DataForward',
  DATA_RETENTION: 'DataRetention',
  DOWNLOAD: 'Download',
  UNINSTALLER_TOKEN: 'UninstallerToken',
  EXCEPTION_SET: 'ExceptionSet',
  EXCEPTION: 'Exception',
  USB_CONTROLS: 'USBControlSet',
  TELEMETRY: 'Telemetry',
});

export const RBAC_RESOURCE_LABELS = Object.freeze({
  [RBAC_RESOURCE.INSIGHT]: 'Insights',
  [RBAC_RESOURCE.COMPUTER]: 'Computers',
  [RBAC_RESOURCE.ALERT]: 'Alerts',
  [RBAC_RESOURCE.PLAN]: 'Plans',
  [RBAC_RESOURCE.ACTION_CONFIGS]: 'Actions',
  [RBAC_RESOURCE.ANALYTIC_SET]: {
    label: 'Analytic Sets',
    requires: RBAC_RESOURCE.ANALYTIC,
  },
  [RBAC_RESOURCE.ANALYTIC]: {
    label: 'Analytics',
    dependencies: [RBAC_RESOURCE.ANALYTIC_SET],
  },
  [RBAC_RESOURCE.PREVENT_LIST]: 'Prevent Lists',
  [RBAC_RESOURCE.UNIFIED_LOGGING_FILTER]: 'Unified Logging',
  [RBAC_RESOURCE.TELEMETRY]: 'Telemetry',
  [RBAC_RESOURCE.EXCEPTION_SET]: 'Exception Sets',
  [RBAC_RESOURCE.USB_CONTROLS]: 'Removable Storage Control Sets',

  [RBAC_RESOURCE.DATA_RETENTION]: {
    label: 'Data Retention',
    requires: RBAC_RESOURCE.ORGANIZATION,
  },
  [RBAC_RESOURCE.DATA_FORWARD]: {
    label: 'Data Forwarding',
    requires: RBAC_RESOURCE.ORGANIZATION,
  },
  [RBAC_RESOURCE.API_CLIENT]: 'API Clients',
  [RBAC_RESOURCE.CONNECTION]: 'Account Identity Providers',
  [RBAC_RESOURCE.USER]: 'Account Users',
  [RBAC_RESOURCE.GROUP]: 'Account Groups & Mappings',
  [RBAC_RESOURCE.ROLE]: 'Account Roles',
  [RBAC_RESOURCE.CONFIG_FREEZE]: {
    label: 'Change Management',
    requires: RBAC_RESOURCE.ORGANIZATION,
  },
  [RBAC_RESOURCE.DOWNLOAD]: 'Downloads',
  [RBAC_RESOURCE.ORGANIZATION]: {
    label: 'Account Information',
    dependencies: [
      RBAC_RESOURCE.DATA_FORWARD,
      RBAC_RESOURCE.DATA_RETENTION,
      RBAC_RESOURCE.CONFIG_FREEZE,
    ],
  },
  [RBAC_RESOURCE.THREAT_PREVENTION_VERSION]: 'Endpoint Threat Prevention',
  [RBAC_RESOURCE.AUDIT_LOG]: 'Audit Logs',
});
