<template>
  <j-select
    v-model="valueComputed"
    :options="severityOptions"
    label="Severity"
    class="severity"
    :is-small="true"
    :searchable="false"
    placeholder="Select a severity"
  />
</template>

<script>
import { SEVERITY } from '@/util/constants/severity.types';

export default {
  name: 'SeverityLevelSelect',
  compatConfig: {
    MODE: 3,
  },
  props: {
    modelValue: String,
    excludeInfo: Boolean,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue || SEVERITY.High,
      severityOptions: Object.keys(SEVERITY),
    };
  },
  mounted() {
    if (this.excludeInfo) {
      this.severityOptions = this.severityOptions.filter(
        (value) => value !== SEVERITY.Informational
      );
    }
  },
  computed: {
    valueComputed: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.value = value;
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// Should no longer be done this way after UXE-1024 is available.
:deep() [data-wa='nebula--select'] {
  li {
    @include severity-option;
  }
}
</style>
