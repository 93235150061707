import { findIndex } from 'lodash';
import { getKeyNameMatch } from '@/util';

export function useAlerts(props) {
  const getName = (alert) => {
    const names = alert.parsed.match.facts
      .map(({ name }) => getKeyNameMatch(name))
      .reverse()
      .sort();
    return names.join(' & ');
  };

  const relatedUser = (uid) => {
    const index = props?.related?.users.findIndex((item) => item === uid);
    if (index >= 0) {
      return props?.related?.users[index].name;
    }
    return `${uid}`;
  };

  const relatedGroup = (gid) => {
    const index = props?.related?.groups.findIndex((item) => item === gid);
    if (index >= 0) {
      return props?.related?.groups[index].name;
    }
    return `${gid}`;
  };

  const relatedProcess = (pid) => {
    const index = props?.related?.processes.findIndex((item) => item === pid);
    if (index >= 0) {
      return `${props?.related?.processes[index].name} (${props.related.processes[index].path})`;
    }
    return `${pid}`;
  };

  const getEnumString = (fields, label, value) => {
    const index = findIndex(fields, { label });
    if (index >= 0) {
      const enumIndex = findIndex(fields[index].options, { value });
      if (enumIndex >= 0) {
        return fields[index].options[enumIndex].label;
      }
    }
    return `${value}`;
  };

  const relatedProcessObject = (pid) => {
    const index = props?.related?.processes.findIndex((item) => item === pid);
    if (index >= 0) {
      return props?.related?.processes[index];
    }
    return null;
  };

  return {
    getName,
    relatedUser,
    relatedGroup,
    relatedProcess,
    getEnumString,
    relatedProcessObject,
  };
}
