<template>
  <div v-if="alert" class="gp-content">
    <div class="alert-info">
      <div class="item date">
        <span class="alert-label">Date</span>
        <span class="value">{{ createdDate }}</span>
      </div>
      <div class="item">
        <span class="alert-label">Severity</span>
        <severity-indicator :level="severity" />
      </div>
      <div v-if="action" class="item">
        <span class="alert-label">Action</span>
        <span class="value">{{ action }}</span>
      </div>
      <div class="item">
        <span class="alert-label">Status</span>
        <status-indicator
          v-if="statusValue === 'AutoResolved' || !canWrite"
          :status="statusValue"
        />
        <j-select
          v-else
          v-model="statusValue"
          class="table-filter-select"
          data-feature-id="select-status"
          :options="statusOptions"
          :searchable="false"
          is-small
        />
      </div>
      <div class="item">
        <span class="alert-label">Computer</span>
        <router-link
          v-if="computer.route"
          :to="computer.route"
          data-feature-id="alert-computer"
          >{{ computer.name }}</router-link
        >
        <span v-else>{{ computer.name }}</span>
      </div>
    </div>
    <Tabs v-if="tabs" :tabs="tabs" data-feature-id="alert-tabs" replace>
      <router-view
        :alert="alert"
        :related="alert.parsed.related"
        :processes="alert.parsed.related.processes"
        :files="alert.parsed.related.files"
        :binaries="alert.parsed.related.binaries"
        :groups="alert.parsed.related.groups"
        :users="alert.parsed.related.users"
        :data="alert.json"
        :hide-exception-tab="hideExceptionsTab"
      />
    </Tabs>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import SeverityIndicator from '@/components/SeverityIndicator.vue';
import { ALERT_STATUS } from '@/util/constants/alert-status.types';
import StatusIndicator from '@/components/StatusIndicator.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { EVENT_TYPES_NAMES_EXCEPTIONS } from '@/util/constants/event.types';
import { mapGetters } from 'vuex';
import { useGmt } from '@/composables/gmt';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'Alert',
  components: { Tabs, SeverityIndicator, StatusIndicator },
  setup() {
    const { formatEpochDate } = useGmt();
    const { canWrite } = useRBAC([RBAC_RESOURCE.ALERT]);
    return { canWrite, formatEpochDate };
  },
  compatConfig: {
    MODE: 3,
  },
  props: {
    alert: Object,
  },
  emits: ['update:alert', 'status-changed'],
  data() {
    return {
      status: this.alert?.status || 'AutoResolved',
      statusOptions: Object.entries(ALERT_STATUS)
        .filter(([, value]) => value !== ALERT_STATUS.AutoResolved)
        .map(([value, label]) => ({ label, value })),
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    computer() {
      return this.alert?.computer
        ? {
            route: {
              name: 'computers.index.overview',
              params: { id: this.alert?.computer?.uuid },
            },
            name: this.alert?.computer?.hostName,
          }
        : { name: this.alert?.parsed?.host?.hostname };
    },
    action() {
      return this.alert?.actions
        ? this.alert?.actions[this.alert?.actions.length - 1]
        : null;
    },
    statusValue: {
      get() {
        return this.alert?.status || 'AutoResolved';
      },
      set(value) {
        this.$emit('update:alert', { ...this.alert, status: value });
      },
    },
    createdDate() {
      return this.formatEpochDate(this.alert?.created);
    },
    severity() {
      return this.alert?.severity;
    },
    hideExceptionsTab() {
      return this.alert &&
        Object.keys(EVENT_TYPES_NAMES_EXCEPTIONS).includes(
          this.alert?.parsed?.eventType
        ) &&
        this.isFeatureEnabled('EXCEPTIONS_ANALYTICS')
        ? null
        : true;
    },
    tabs() {
      return [
        {
          label: 'Summary',
          route: {
            name: 'alerts.item',
          },
        },
        {
          label: `Processes (${this.alert?.parsed.related.processes.length})`,
          route: {
            name: 'alerts.item.processes',
          },
        },
        {
          label: `Files (${this.alert?.parsed.related.files.length})`,
          route: {
            name: 'alerts.item.files',
          },
        },
        {
          label: `Binaries (${this.alert?.parsed.related.binaries.length})`,
          route: {
            name: 'alerts.item.binaries',
          },
        },
        {
          label: `Users (${this.alert?.parsed.related.users.length})`,
          route: {
            name: 'alerts.item.users',
          },
        },
        {
          label: `Groups (${this.alert?.parsed.related.groups.length})`,
          route: {
            name: 'alerts.item.groups',
          },
        },
        {
          label: 'Json',
          route: {
            name: 'alerts.item.json',
          },
        },
        !this.hideExceptionsTab && {
          label: 'Add Exception',
          route: {
            name: 'alerts.item.exceptions',
          },
        },
      ];
    },
  },
  methods: {
    async setStatus(value) {
      const response = await this.$store.dispatch('primary/updateAlerts', {
        input: {
          uuids: [this.alert?.uuid],
          status: value,
        },
      });

      this.$emit('status-changed', response.items);
    },
  },
  watch: {
    async statusValue(value) {
      await this.setStatus(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-info {
  @include grid;
  @include grid-wrap(140px);
  padding: spacing(3);

  .alert-label {
    color: var(--color-font-secondary);
  }
  .item {
    @include grid(spacing(1));
    @include grid-rows(max-content);

    &.date {
      max-width: 96px;
    }
  }

  // bug workaround https://jamfpdd.atlassian.net/browse/UXE-1636
  @media only screen and (min-width: 1920px) {
    max-width: calc(100vw / 2);
  }
}
</style>
