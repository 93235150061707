<template>
  <Compliance v-if="loaded" :compliance="compliance">
    <template #default="{ section, setEnabled }">
      <ComplianceCard
        v-for="{
          uuid,
          label,
          totalFail,
          totalNone,
          totalPass,
          description,
          enabled,
          tags,
        } in section"
        :key="uuid"
        :label="label"
        :route="{ name: 'compliance.index.detail', params: { id: uuid } }"
        :is-enabled="enabled"
        :callback="canWrite ? (value) => setEnabled(uuid, value) : null"
        :tags="tags"
        :fail="totalFail"
        :pass="totalPass"
        :none="totalNone"
        :description="description"
      />
    </template>
  </Compliance>
</template>

<script>
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapState } from 'vuex';
import Compliance from './components/Compliance';
import ComplianceCard from './components/ComplianceCard';

export default {
  name: 'ComplianceSettings',
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.INSIGHT);
    return { canWrite };
  },
  components: {
    ComplianceCard,
    Compliance,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('primary', {
      compliance: (state) => state.compliance.items,
    }),
  },
  async created() {
    await this.$store.dispatch('primary/listInsights');
    this.loaded = true;
  },
};
</script>
