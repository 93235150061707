import getters from './auth.getters';
import mutations from './auth.mutations';
import state from './auth.state';
import actions from './auth.actions';

const AuthStoreModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default AuthStoreModule;
