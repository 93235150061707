<template>
  <TextEditor
    v-if="jsonText"
    :text="jsonText"
    @update:textUpdate="config = $event"
    :line-numbers="true"
    :read-only="true"
    class="editor"
  />
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';

export default {
  name: 'AlertJson',
  components: {
    TextEditor,
  },
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  computed: {
    jsonText() {
      return JSON.stringify(JSON.parse(this.data), null, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  width: 100%;
  height: calc(100% - 40px);
}
</style>
