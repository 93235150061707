<template>
  <JamfTable
    :get-data="getData"
    :page-size="maxClients"
    :suppress-search="true"
    :suppress-page-buttons="true"
    :suppress-refresh-button="true"
    :suppress-filter-bar="hideFilters"
    :columns="columns"
  >
    <template #left v-if="canWrite">
      <j-create-button
        v-if="currentLength < maxClients"
        :is-disabled="loading"
        @click="$router.push({ name: 'apiClients.index.create' })"
        label="Create API Client"
      />
      <j-tag
        is-rounded
        :style-type="limitWarning ? 'danger' : null"
        class="counts"
      >
        {{ currentLength }} of {{ maxClients }} Available Clients Created
      </j-tag>
    </template>
    <template #right="{ selected }">
      <j-delete-button
        v-if="canWrite"
        @click="confirmDelete(selected)"
        :is-disabled="selected.length === 0"
        :label="`Delete API Clients (${selected.length})`"
      />
    </template>
    <template #modals="{ selected, refresh }">
      <j-delete-modal
        v-model="showWarning"
        :title="title"
        :to-delete="toDelete"
        @confirm="removeApiClients(selected, refresh)"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'ApiClients',
  components: {
    JamfTable,
  },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.API_CLIENT);
    return { canWrite };
  },
  data() {
    return {
      hideFilters: true,
      endpoint: 'primary/listApiClients',
      maxClients: 5,
      currentLength: 0,
      loading: false,
      columns: null,
      title: null,
      toDelete: null,
      showWarning: false,
    };
  },
  computed: {
    limitWarning() {
      return this.maxClients - this.currentLength < 2;
    },
  },
  created() {
    this.columns = [
      {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        type: 'linkColumn',
        cellRendererParams: {
          route: (params) => ({
            name: 'apiClients.index.detail',
            params: { clientId: params.data?.clientId },
          }),
        },
      },
      {
        headerName: 'Role',
        field: 'assignedRoles',
        type: 'tagsColumn',
        permissions: [RBAC_RESOURCE.ROLE, RBAC_RESOURCE.CLOUD_ACCESS],
        flex: 2,
        minWidth: 100,
        valueFormatter: (params) =>
          params.data?.assignedRoles.map(({ name }) => ({
            value: name,
          })),
      },
      { headerName: 'Client ID', field: 'clientId', type: 'codeColumn' },

      {
        headerName: 'Created',
        field: 'created',
        type: 'dateColumn',
        sort: 'desc',
      },
    ];
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
  },
  methods: {
    async getData(params) {
      this.loading = true;
      const response = await this.$store.dispatch(this.endpoint, params);
      this.currentLength = response.items.length;
      this.loading = false;
      return response;
    },
    viewClient(params) {
      this.$router.push({
        name: 'apiClients.index.detail',
        params: { clientId: params.data.clientId },
      });
    },
    confirmDelete(selected) {
      this.showWarning = true;
      this.title = `Delete API Client${selected.length > 1 ? 's' : ''}`;
      this.toDelete = selected.map(({ name }) => name);
    },
    async removeApiClients(selected, refresh) {
      this.loading = true;
      await Promise.all(
        selected.map(({ clientId }) =>
          this.$store.dispatch('primary/deleteApiClient', { clientId })
        )
      );
      refresh();
    },
  },
};
</script>
