<template>
  <JamfTable
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
  >
    <template #left v-if="canWrite">
      <j-create-button
        @click="$router.push({ name: 'actions.index.create' })"
        label="Create Action"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { success } from '@/util/constants/feedback.icons';
import dashIcon from '@jcon/minus.svg';

const iconColDef = (value) => {
  return value
    ? { icon: success, color: 'var(--color-success-base)' }
    : { icon: dashIcon, color: 'var(--color-font-base)' };
};

export default {
  name: 'Actions',
  components: {
    JamfTable,
  },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.ACTION_CONFIGS);
    return { canWrite };
  },
  data() {
    return {
      endpoint: 'primary/listActionConfigs',
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          flex: 1,
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'actions.index.detail',
              params: { id: params.data?.id },
            }),
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          flex: 1,
        },
        {
          headerName: 'Alert Data',
          type: 'iconColumn',
          maxWidth: 100,
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          field: 'alertConfig.jamfCloud.enabled',
          cellRendererParams: {
            setIcon: iconColDef,
          },
        },
        {
          headerName: 'Unified Log Data',
          type: 'iconColumn',
          maxWidth: 100,
          field: 'ulogConfig.jamfCloud.enabled',
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          cellRendererParams: {
            setIcon: iconColDef,
          },
        },
        {
          headerName: 'Telemetry Data',
          type: 'iconColumn',
          maxWidth: 100,
          flag: 'TELEMETRY_STREAM',
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          field: 'telemetryConfig.jamfCloud.enabled',
          cellRendererParams: {
            setIcon: iconColDef,
          },
        },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sortable: true,
          sort: 'asc',
        },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
          sortable: true,
          maxWidth: null,
        },
      ],
    };
  },
  methods: {
    async getData(params) {
      return this.$store.dispatch(this.endpoint, params);
    },
  },
};
</script>
