<template>
  <div class="card-list-item">
    <h2
      v-if="title"
      @click="$emit('click')"
      :class="{ 'has-click': hasClick, 'list-title': true }"
    >
      {{ title }}
    </h2>
    <Card class="card-list-item">
      <div class="g-rows max-c">
        <ListItem
          v-for="{ label, value, link, externalLink } in values"
          :key="label"
          :title="label"
        >
          <router-link v-if="link" :to="link">{{ value }}</router-link>
          <a v-else-if="externalLink" :href="`https://${value}`">{{ value }}</a>
          <template v-else>{{ value }}</template>
        </ListItem>
        <slot></slot>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from './Card';
import ListItem from './ListItem';

export default {
  name: 'CardListItems',
  components: { Card, ListItem },
  props: {
    title: String,
    values: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click'],
  computed: {
    hasClick() {
      return this.$attrs && this.$attrs.click;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-list-item {
  @include grid;
  grid-template-rows: min-content auto;
}
.list-title {
  @include grid;
  @include grid-columns(max-content);
  @include subtitle-text;
  align-items: center;
  .icon {
    margin: -8px 0;
  }

  &.has-click {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
