<template>
  <j-form-bar
    can-write
    @submit="handleSubmit"
    updated-message="Exception added!"
  >
    <template #custom-save-button="{ submitTrigger }">
      <j-button @click="submitTrigger" data-feature-id="save-exception">
        Add Exception
      </j-button>
    </template>
  </j-form-bar>

  <div v-if="alert" class="scroll-vertical alert-exceptions">
    <h2 class="h4 mb-3">
      Configure an Exception that an Analytic should ignore when detected.
    </h2>
    <transition name="long-fade">
      <div class="f-wrap ai-end max-c" v-if="exceptionOptions">
        <j-select
          v-model="selectedException"
          label="Add to Exception Set"
          :options="exceptionOptions"
          :has-error="v$.selectedException.$error"
          placeholder="Select Exception Set"
          data-feature-id="exception-select"
          :searchable="isSearchable(exceptionOptions)"
        />
        <j-create-button
          @click="showNewExceptionModal = true"
          style-type="ghost-primary"
          data-feature-id="add-exception-btn"
          label="Create Exception Set"
        />
      </div>
    </transition>
    <j-select
      v-if="availableAlerts && availableAlerts.length > 1"
      v-model="selectedAnalytic"
      label="Rule applies to"
      :options="availableAlerts"
      placeholder="Add exceptions"
      class="mt-2"
      data-feature-id="alert-type"
      :searchable="isSearchable(availableAlerts)"
    />
    <div v-if="availableRules.length > 0" class="exception-rules">
      <strong>Add Rule</strong>
      <p class="helper-text">Select one of the following identifiers</p>
      <div
        class="f-cols ai-center"
        v-for="rule in availableRules"
        :key="rule.value"
      >
        <j-radio
          name="rule"
          :data-feature-id="rule.type"
          v-model="selectedRule"
          :native-value="rule.type"
        >
          <div class="g-rows gap-none">
            {{ getRuleText(rule.type) }}
            <span v-if="rule.type === 'AppSigningInfo'" class="helper-text">
              <strong>Team ID: </strong> {{ rule.appSigningInfo.teamId }},
              <strong>Signing ID: </strong> {{ rule.appSigningInfo.appId }}
            </span>
            <span v-else class="helper-text">
              {{ rule.value }}
            </span>
          </div>
        </j-radio>
      </div>
    </div>
    <NewAlertExceptionSet
      v-model="showNewExceptionModal"
      @saved="onExceptionCreated"
      :exception-sets="exceptionSets"
    />
  </div>
</template>

<script>
import NewAlertExceptionSet from './NewAlertExceptionSet.vue';
import {
  IGNORE_TYPE_NAMES,
  IGNORE_TYPE,
  IGNORE_ACTIVITY,
} from '@/modules/plans/exception-sets/exception.types';
import { EVENT_TYPES } from '@/util/constants/event.types';
import { required, requiredIf } from '@vuelidate/validators';
import { useForm } from '@/composables/forms';

export default {
  name: 'AlertExceptions',
  props: {
    alert: {
      type: Object,
      required: true,
    },
    hideExceptionTab: {
      type: Boolean,
    },
  },
  setup() {
    const { v$, handleSubmit } = useForm();
    return { v$, handleSubmit };
  },
  components: {
    NewAlertExceptionSet,
  },
  inheritAttrs: false,
  data() {
    return {
      exceptions: null,
      rules: null,
      selectedRule: null,
      selectedException: null,
      selectedAnalytic: null,
      showNewExceptionModal: false,
      exceptionSets: [],
    };
  },
  validations() {
    return {
      selectedException: { required },
      selectedAnalytic: {
        required: requiredIf(function () {
          return this.availableAlerts.length > 1;
        }),
      },
    };
  },
  computed: {
    exceptionOptions() {
      return this.exceptions?.items
        .filter((exception) => !exception.managed)
        .map(({ name, uuid }) => ({
          label: name,
          value: uuid,
        }));
    },
    availableAlerts() {
      if (this.alert) {
        const facts = this.alert.parsed?.match?.facts;
        if (facts) {
          return facts.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          }));
        }
      }
      return null;
    },
    process() {
      return this.alert.parsed.related?.processes[0];
    },
    filePath() {
      const fileEventTypes = [
        EVENT_TYPES.FILE_SYSTEM,
        EVENT_TYPES.SCREENSHOT,
        EVENT_TYPES.DOWNLOAD,
      ];
      if (!fileEventTypes.includes(this.alert.parsed.eventType)) {
        return false;
      }
      return this.alert.parsed?.match?.event?.path;
    },
    processPath() {
      return this.process?.path;
    },
    appId() {
      return this.process?.signingInfo?.appid;
    },
    teamId() {
      return this.process?.signingInfo?.teamid;
    },
    isPlatformBinaryAvailable() {
      // if appid is available and signerType is 0, then platformBinary is available
      return this.appId && this.process?.signingInfo?.signerType === 0;
    },
    availableRules() {
      const rules = [];
      if (this.filePath) {
        rules.push({
          type: IGNORE_TYPE.Path,
          value: this.filePath,
        });
      }

      if (this.processPath) {
        rules.push({
          type: IGNORE_TYPE.Executable,
          value: this.processPath,
        });
      }

      if (this.appId && this.teamId) {
        // Ensure appSigningInfo appears as the first one
        rules.unshift({
          type: IGNORE_TYPE.AppSigningInfo,
          appSigningInfo: {
            appId: this.appId,
            teamId: this.teamId,
          },
        });
      }

      if (this.isPlatformBinaryAvailable) {
        rules.push({
          type: IGNORE_TYPE.PlatformBinary,
          value: this.appId,
        });
      }

      if (this.teamId) {
        rules.push({
          type: IGNORE_TYPE.TeamId,
          value: this.teamId,
        });
      }

      return rules;
    },
    ruleValue() {
      return this.availableRules.find((el) => el.type === this.selectedRule);
    },
    selectedExceptionPayload() {
      if (this.ruleValue) {
        const payloadData = {
          ...this.ruleValue,
        };

        if (this.alert.parsed.eventType === EVENT_TYPES.THREAT) {
          payloadData.ignoreActivity = IGNORE_ACTIVITY.ThreatPrevention;
        } else {
          payloadData.analyticUuid = this.selectedAnalytic;
          payloadData.ignoreActivity = IGNORE_ACTIVITY.Analytics;
        }

        return payloadData;
      }
      return null;
    },
    analyticExceptionPayload() {
      const currentException = this.exceptions.items.find(
        (el) => el.uuid === this.selectedException
      );
      return {
        uuid: this.selectedException,
        name: currentException.name,
      };
    },
  },
  watch: {
    $route() {
      // Condition is added to watchers in case the user tries to switch between alerts directly from the Alert page
      this.setDefaultSelectedAnalytic();

      if (this.availableRules.length > 0) {
        this.setDefaultSelectedRule();
      }
      // Route users back to summary tabs if add-exceptions is not available
      if (this.hideExceptionTab) {
        this.$router.push({ name: 'alerts.item' });
      }
    },
  },
  mounted() {
    this.loadExceptions();
    this.setDefaultSelectedRule();
    this.setDefaultSelectedAnalytic();
  },
  methods: {
    getRuleText(type) {
      return IGNORE_TYPE_NAMES[type];
    },
    onExceptionCreated(newException) {
      this.exceptions.items?.splice(0, 0, newException);
      // Set selectedException to the just created exception
      this.selectedException = this.exceptionOptions[0].value;
    },
    setDefaultSelectedAnalytic() {
      this.selectedAnalytic = this.alert?.parsed?.match?.facts[0]?.uuid;
    },
    setDefaultSelectedRule() {
      if (this.appId && this.teamId) {
        this.selectedRule = IGNORE_TYPE.AppSigningInfo;
      } else {
        this.selectedRule = this.availableRules[0]?.type;
      }
    },
    async getExceptionPayload() {
      const exceptions = await this.$store.dispatch('primary/getExceptionSet', {
        uuid: this.selectedException,
      });

      return [
        ...exceptions.exceptions.map((obj) => {
          const filterNull = Object.fromEntries(
            Object.entries(obj).filter(([, value]) => value !== null)
          );

          if (filterNull.analyticTypes?.length === 0) {
            delete filterNull.analyticTypes;
          }

          if (filterNull.analytic) {
            delete filterNull.analytic;
            return {
              ...filterNull,
              analyticUuid: obj.analytic.uuid,
            };
          }

          return filterNull;
        }),
        this.selectedExceptionPayload,
      ];
    },
    async loadExceptions() {
      this.exceptions = await this.$store.dispatch(
        'primary/listExceptionSets',
        {
          direction: 'DESC',
          field: 'created',
        }
      );
      this.exceptions.items.forEach((value) => {
        this.exceptionSets.push({
          name: value.name,
          description: value.description,
        });
      });
    },
    async submit() {
      await this.$store.dispatch('primary/updateExceptionSet', {
        ...this.analyticExceptionPayload,
        exceptions: await this.getExceptionPayload(),
      });
    },
    isSearchable(options) {
      return options.length > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-exceptions {
  padding: spacing(2);

  .exception-rules {
    margin-top: spacing(4);

    &-title {
      margin-bottom: spacing(2);
    }

    .radio {
      margin: spacing() 0;
    }

    .exception-rule-value {
      margin-bottom: 0;
      margin-left: spacing(4);
    }
  }
}

.new-exception-modal:deep(.modal-close) {
  display: inline;
}
</style>
