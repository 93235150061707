<template>
  <j-modal
    v-model="computedValue"
    helper-text="Select the items to include in the configuration profile download."
  >
    <template #header="{ alignClass }">
      <section :class="alignClass">
        <h1 class="h3">Download Custom Profile</h1>
      </section>
    </template>
    <template #actions="{ close }">
      <gp-loader :show-loader="showLoader">Downloading</gp-loader>
      <j-button style-type="secondary" @click="close" data-feature-id="cancel"
        >Cancel</j-button
      >
      <j-button @click="submit()" data-feature-id="download-button">
        <template #leading> <j-icon data="@jcon/download-2.svg" /></template>
        Download</j-button
      >
    </template>
    <div class="g-rows" data-testid="download-options">
      <j-checkbox v-model="input.sign">Sign the Profile</j-checkbox>
      <j-checkbox v-model="input.pppc">Include PPPC payload</j-checkbox>
      <j-checkbox v-model="input.systemExtension">
        Include System Extension payload
      </j-checkbox>
      <j-checkbox
        v-if="isFeatureEnabled('SERVICE_MANAGEMENT_PROFILE')"
        v-model="input.serviceManagement"
      >
        Include Login & Background Item payload
      </j-checkbox>
      <j-checkbox v-model="input.websocket">
        Include Websocket Authorizer Key
      </j-checkbox>
      <j-checkbox v-model="input.ca">Include Root CA Certificate</j-checkbox>
      <j-checkbox v-model="input.csr">Include CSR Certificate</j-checkbox>
      <j-checkbox v-model="input.token">Include Bootstrap Token</j-checkbox>
      <j-checkbox
        v-if="releaseGroup === 'DEV'"
        v-model="input.tokenOptions.xpc"
      >
        Include XPC flag in token
      </j-checkbox>
      <j-checkbox
        v-if="releaseGroup === 'DEV'"
        v-model="input.tokenOptions.keychain_client_id"
      >
        Include Keychain Client Id flag in token
      </j-checkbox>
    </div>
  </j-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { sleep } from '@/util';
import { usePlan } from '@/modules/plans/plans.js';

export default {
  name: 'PlanProfileDownload',
  setup() {
    const { planProfileDownloadOptions } = usePlan();

    return { planProfileDownloadOptions };
  },
  compatConfig: { MODE: 3 },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    modelValue: Boolean,
  },
  emits: ['update:modelValue', 'plan:download'],
  data() {
    return {
      input: {
        ...this.planProfileDownloadOptions,
        tokenOptions: {
          ...this.planProfileDownloadOptions.tokenOptions,
        },
      },
      message: null,
      showLoader: false,
      newValue: this.modelValue,
    };
  },
  computed: {
    ...mapState({
      releaseGroup: (state) => state.app.info.releaseGroup,
    }),
    ...mapGetters(['isFeatureEnabled']),
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    /** When v-model change, set internal value. */
    modelValue(value) {
      this.newValue = value;
    },
  },
  methods: {
    async submit() {
      this.showLoader = true;
      await this.$emit('plan:download', this.input);
      await sleep(500);
      this.showLoader = false;
      this.computedValue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-form {
  @include grid;
  @include grid-rows(min-content);
  grid-template-columns: minmax(0, 1fr);
  padding: var(--size-grid-gap-base);

  h2 {
    @include header4;
  }

  p {
    @include paragraph;
  }

  .enable-column {
    @include flex-rows($selector: '.checkbox', $scale: 0);
  }
}
</style>
