<template>
  <BaseFilterStyle
    :include-operators="hasActiveFilter"
    :primary-action="apply"
    :secondary-action="clear"
    :disable-action="!value"
  >
    <template #options>
      <j-select
        v-if="hasOptions"
        :class="['table-filter-select', 'table-filter-' + filterType]"
        v-model="type"
        :options="typeOptions"
        :searchable="false"
      />
    </template>
    <template #operators>
      <j-radio
        v-for="(op, key) in operators"
        :key="key"
        v-model="operator"
        :native-value="op"
        name="table-filter-date"
        >{{ op }}</j-radio
      >
    </template>
    <template v-if="isIntervalType">
      <j-input
        ref="input"
        data-testid="filter-input-value"
        v-model="value"
        @keydown.enter.stop="apply"
        placeholder="e.g. 7 days"
      />
    </template>
    <template v-else>
      <j-datetime-picker
        v-model="value"
        class="datetime-picker"
        calendar-class="ag-custom-component-popup"
        :include-time="true"
      />
    </template>
  </BaseFilterStyle>
</template>

<script>
import { FILTER_TYPES } from '../../utils/filter.types';
import { DATE_TYPE_OPTIONS } from './filter.options';
import filterMixin from './filter.mixin';

export default {
  name: 'TableDateFilter',
  mixins: [filterMixin],
  data() {
    return {
      filterType: 'date',
      placeholder: 'yyyy-mm-dd',
      typeOptions: DATE_TYPE_OPTIONS,
      defaultOption: FILTER_TYPES.AFTER_INTERVAL,
      time: '',
    };
  },
  computed: {
    isIntervalType() {
      return this.isInterval(this.type);
    },
  },
  methods: {
    isInterval(value) {
      return (
        value === FILTER_TYPES.BEFORE_INTERVAL ||
        value === FILTER_TYPES.AFTER_INTERVAL
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.datetime-picker:deep() {
  input {
    --size-border-radius-input: var(--size-border-radius-base);
    --color-input-base: transparent;
  }
}
</style>
