import ThreatPreventionSection from '@/modules/threat-prevention/ThreatPreventionSection.vue';
import PreventList from '@/modules/threat-prevention/prevent-sets/PreventList.vue';
import PreventLists from '@/modules/threat-prevention/prevent-sets/PreventLists.vue';
import PreventListForm from '@/modules/threat-prevention/prevent-sets/PreventListForm.vue';
import PreventListDetails from '@/modules/threat-prevention/prevent-sets/PreventListDetails.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import NetworkThreatPreventionOverview from './NetworkThreatPreventionOverview.vue';
import ThreatPreventionOverview from './ThreatPreventionOverview.vue';
import Section from '@/components/Section.vue';

export default [
  {
    path: '/threat-prevention',
    component: ThreatPreventionSection,
    name: 'prevent',
    meta: {
      breadcrumb: {
        linkName: 'prevent.index',
        linkText: 'Threat Prevention',
      },
      permissions: [
        RBAC_RESOURCE.THREAT_PREVENTION_VERSION,
        RBAC_RESOURCE.PREVENT_LIST,
      ],
    },
    children: [
      {
        path: '',
        component: ThreatPreventionOverview,
        name: 'prevent.index',
        meta: {
          permissions: [RBAC_RESOURCE.THREAT_PREVENTION_VERSION],
        },
      },
      {
        path: 'network',
        component: NetworkThreatPreventionOverview,
        name: 'prevent.index.network',
        meta: {
          permissions: [RBAC_RESOURCE.THREAT_PREVENTION_VERSION],
        },
      },
      {
        path: 'custom',
        component: Section,
        name: 'prevent.custom.index',
        meta: {
          permissions: [RBAC_RESOURCE.PREVENT_LIST],
          breadcrumb: {
            linkText: 'Custom Prevention Lists',
            linkName: 'prevent.custom',
          },
        },
        children: [
          {
            path: '',
            component: PreventLists,
            name: 'prevent.custom',
          },
          {
            path: '',
            component: PreventList,
            name: 'prevent.custom.index.create',
            children: [
              {
                path: 'create',
                component: PreventListForm,
                name: 'prevent.custom.create',
                meta: {
                  breadcrumb: {
                    linkText: 'Create',
                  },
                  requireWrite: true,
                },
              },
            ],
          },
          {
            path: ':id',
            component: PreventList,
            meta: {
              breadcrumb: {
                linkName: 'prevent.custom.detail',
                linkText: 'Prevent List Summary',
              },
            },
            children: [
              {
                path: '',
                name: 'prevent.custom.detail',
                component: PreventListDetails,
              },
              {
                path: 'edit',
                name: 'prevent.custom.edit',
                component: PreventListForm,
                props: true,
                meta: {
                  requireWrite: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
