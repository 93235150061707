<template>
  <div v-if="detailParams" class="detail-container">
    <span v-for="option in detailParams.options" :key="option.label">
      {{ option.value }} = {{ option.label }} &nbsp;&nbsp;&nbsp;&nbsp;
    </span>
  </div>
</template>
<script>
export default {
  name: 'FieldDefinitionDetail',
  props: ['params'],
  data() {
    return {
      detailParams: null,
    };
  },
  beforeMount() {
    this.detailParams = this.params.data;
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  padding: 0.5rem;
  white-space: normal;

  span {
    margin: spacing(0.5) 0;
    display: inline-block;
  }
}
</style>
