import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useRBAC(permissions = []) {
  const store = useStore();

  const canRead = computed(() =>
    store.getters['user/hasReadPermissions'](permissions)
  );
  const canWrite = computed(() =>
    store.getters['user/hasWritePermissions'](permissions)
  );

  const isReadOnly = computed(() => !canWrite.value);

  const cloudOnlyAccess = computed(() =>
    store.getters['user/hasReadPermissions'](RBAC_RESOURCE.CLOUD_ACCESS)
  );

  const canAccess = (
    permissions,
    { requireWrite, requireNested, requireOnlySome } = {}
  ) => {
    const results = new Set([
      requireOnlySome
        ? store.getters['user/hasSomePermissions'](permissions)
        : store.getters['user/hasReadPermissions'](permissions),
    ]);
    if (requireWrite) {
      results.add(store.getters['user/hasWritePermissions'](permissions));
    }
    if (requireNested) {
      const permitted =
        store.getters['user/getAvailablePermissions'](permissions);
      if (permitted.length) {
        results.add(
          permitted
            .map((r) =>
              requireNested[r]
                ? store.getters['user/hasReadPermissions'](requireNested[r])
                : true
            )
            .includes(true)
        );
      } else {
        results.add(false);
      }
    }
    return !results.has(false);
  };

  return {
    canAccess,
    canRead,
    canWrite,
    cloudOnlyAccess,
    isReadOnly,
    hasWritePermissions: (permissions) =>
      store.getters['user/hasWritePermissions'](permissions),
  };
}
