<template>
  <div v-if="internalAction" class="g-rows p-2 max-c scroll-vertical">
    <j-input
      id="actionName"
      v-model="internalAction.name"
      data-feature-id="name"
      label="Name"
      :has-error="v$.internalAction.name.$error"
      :error-text="nameErrorMessage"
    />
    <j-textarea
      v-model="internalAction.description"
      label="Description"
      data-feature-id="action-description"
    />
    <template v-if="cloudOnlyAccess">
      <hr />
      <h2 class="h5">Cloud Collection Options</h2>
      <div v-if="forward && !forwardEnabled" class="warning">
        <j-icon data="@jcon/warning.svg" height="16" />
        <p>
          Enable
          <router-link :to="{ name: 'data.index' }"
            >Data Forwarding</router-link
          >
          to forward Unified Log
          {{ isFeatureEnabled('TELEMETRY_STREAM') ? ' or Telemetry' : '' }} data
        </p>
      </div>
      <j-checkbox
        v-model="internalAction.alertConfig.jamfCloud.enabled"
        data-feature-id="alert-cloud-enabled"
      >
        Send Alert Data to Jamf Protect
      </j-checkbox>

      <div
        class="g-cols max-c ml-4"
        :aria-describedby="alertConfig ? alertId : ''"
      >
        <SeverityLevelSelect
          id="alert-config-min"
          v-model="internalAction.alertConfig.jamfCloud.minSeverity"
          :has-error="alertConfig"
          data-feature-id="alert-cloud-min-severity"
          label="Min Severity"
        />
        <SeverityLevelSelect
          id="alert-config-max"
          v-model="internalAction.alertConfig.jamfCloud.maxSeverity"
          :has-error="alertConfig"
          data-feature-id="alert-cloud-max-severity"
          label="Max Severity"
        />
      </div>
      <p v-if="alertConfig" :id="alertId" class="error-text helper-text ml-4">
        {{ minMaxErrorMessage }}
      </p>
      <j-checkbox
        v-model="internalAction.ulogConfig.jamfCloud.enabled"
        data-feature-id="ulog-cloud-enabled"
      >
        Forward Unified Log Data to a Third Party Storage Solution
      </j-checkbox>
      <div
        v-if="isFeatureEnabled('ULOG_SEVERITY')"
        class="g-cols max-c ml-4"
        :aria-describedby="ulogConfig ? ulogId : ''"
      >
        <SeverityLevelSelect
          id="ulog-config-min"
          v-model="internalAction.ulogConfig.jamfCloud.minSeverity"
          :has-error="ulogConfig"
          data-feature-id="ulog-cloud-min-severity"
          label="Min Severity"
        />
        <SeverityLevelSelect
          id="ulog-config-max"
          v-model="internalAction.ulogConfig.jamfCloud.maxSeverity"
          :has-error="ulogConfig"
          data-feature-id="ulog-cloud-max-severity"
          label="Max Severity"
        />
      </div>
      <p v-if="ulogConfig" :id="ulogId" class="error-text helper-text ml-4">
        {{ minMaxErrorMessage }}
      </p>
      <j-checkbox
        v-if="isFeatureEnabled('TELEMETRY_STREAM')"
        v-model="internalAction.telemetryConfig.jamfCloud.enabled"
        data-feature-id="telemetry-stream-enabled"
        >Forward Telemetry Data to a Third Party Storage Solution</j-checkbox
      >
      <hr />

      <h2 class="h5">Alert Collection Endpoints</h2>
      <EndpointsForm
        v-model:endpoints="internalAction.alertConfig.endpoints"
        title="Remote Alert Collection Endpoints"
      >
        <template #severity="{ endpoint, hasError, id }">
          <SeverityLevelSelect
            :id="`${id}-min`"
            v-model="endpoint.minSeverity"
            :has-error="hasError"
            label="Min Severity"
          />
          <SeverityLevelSelect
            :id="`${id}-max`"
            v-model="endpoint.maxSeverity"
            :has-error="hasError"
            label="Max Severity"
          />
        </template>
      </EndpointsForm>
      <hr />
      <h2 class="h5">Unified Logs Collection Endpoints</h2>
      <EndpointsForm
        v-model:endpoints="internalAction.ulogConfig.endpoints"
        title="Unified Logs Collection Endpoints"
      >
        <template
          v-if="isFeatureEnabled('ULOG_SEVERITY')"
          #severity="{ endpoint, hasError, id }"
        >
          <SeverityLevelSelect
            :id="`${id}-min`"
            v-model="endpoint.minSeverity"
            :has-error="hasError"
            label="Min Severity"
          />
          <SeverityLevelSelect
            :id="`${id}-max`"
            v-model="endpoint.maxSeverity"
            :has-error="hasError"
            label="Max Severity"
          />
        </template>
      </EndpointsForm>
    </template>
    <hr />
    <template v-if="isFeatureEnabled('TELEMETRY_STREAM')">
      <h2 class="h5">Telemetry Collection Endpoints</h2>
      <EndpointsForm
        v-model:endpoints="internalAction.telemetryConfig.endpoints"
        title="Telemetry Collection Endpoints"
        :has-severity="false"
      />
    </template>
    <template v-if="cloudOnlyAccess">
      <hr />
      <h2 class="h5">Alert Data Collection Options</h2>
      <DataConfigsForm
        v-model:data="internalAction.alertConfig.data"
        title="Remote Alert"
        :existing="mode.edit"
        :show-auto-select="mode.create || mode.edit"
      />
    </template>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import DataConfigsForm from '@/modules/actions/components/DataConfigsForm.vue';
import EndpointsForm from '@/modules/actions/components/EndpointsForm.vue';
import { mapGetters, mapState } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { SEVERITY } from '@/util/constants/severity.types';
import SeverityLevelSelect from '@/components/SeverityLevelSelect.vue';
import { useForm } from '@/composables/forms';
import { useRBAC } from '@/composables/rbac';
import {
  minSeverity,
  maxSeverity,
  isUniqueName,
} from '@/util/custom-validators';
import { reactive } from 'vue';
import { cloneDeep } from 'lodash';

const buildAction = () => {
  const dataTypes = [
    'fsEvent',
    'downloadEvent',
    'procEvent',
    'screenshotEvent',
    'clickEvent',
    'usbEvent',
    'mrtEvent',
    'gkEvent',
    'keylogRegisterEvent',
    'binary',
    'file',
    'user',
    'group',
    'process',
  ];
  const configBase = {
    endpoints: [],
    data: {},
  };
  const jamfCloud = {
    minSeverity: SEVERITY.Low,
    maxSeverity: SEVERITY.High,
    enabled: true,
  };
  const logConfig = { ...configBase };
  const alertConfig = {
    ...configBase,
    jamfCloud: { ...jamfCloud },
  };

  dataTypes.forEach((value) => {
    const dataConfig = {
      attrs: [],
      related: [],
    };
    logConfig.data[value] = { ...dataConfig };
    alertConfig.data[value] = { ...dataConfig };
  });

  return {
    name: '',
    description: '',
    alertConfig,
    ulogConfig: {
      endpoints: [],
      jamfCloud: {
        ...jamfCloud,
        minSeverity: SEVERITY.Informational,
        enabled: false,
      },
    },
    telemetryConfig: {
      endpoints: [],
      jamfCloud: {
        enabled: false,
      },
    },
  };
};

export default {
  name: 'ActionForm',
  components: {
    DataConfigsForm,
    EndpointsForm,
    SeverityLevelSelect,
  },
  inject: ['$typeDefs'],
  compatConfig: {
    MODE: 3,
  },
  props: {
    action: {
      type: Object,
    },
  },
  setup(props) {
    const { mode, v$ } = useForm();
    const { canAccess, cloudOnlyAccess } = useRBAC();
    const internalAction = reactive(
      !mode.value.create ? cloneDeep(props.action) : buildAction()
    );

    return {
      mode,
      v$,
      canAccess,
      cloudOnlyAccess,
      internalAction,
    };
  },
  validations() {
    const severity = {
      jamfCloud: {
        minSeverity: {
          required,
          min: minSeverity,
        },
        maxSeverity: {
          required,
          max: maxSeverity,
        },
      },
    };
    const nonCloudOnlyAction = {
      internalAction: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
      },
    };
    const cloudOnlyAction = {
      internalAction: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
        alertConfig: { ...severity },
        ulogConfig: { ...severity },
      },
    };

    return this.cloudOnlyAccess ? cloudOnlyAction : nonCloudOnlyAction;
  },
  data() {
    return {
      ulogId: 'ulog-alert-error',
      alertId: 'alert-config-error',
      minMaxErrorMessage:
        'Min severity must be less than or equal to max severity.',
    };
  },
  async beforeMount() {
    if (
      this.canAccess([
        RBAC_RESOURCE.DATA_FORWARD,
        RBAC_RESOURCE.ORGANIZATION,
      ]) &&
      !this.forward
    ) {
      await this.$store.dispatch('primary/getDataForward');
    }
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('primary', {
      forward: (state) => state.data.forward,
      // this is used in isUniqueName
      duplicateNames: (state) => state.actions.actionsNames,
      currentName: (state) => state.actions.action?.name,
    }),
    nameErrorMessage() {
      return this.v$.internalAction.name.$errors[0]?.$message;
    },
    endpoint() {
      return `primary/${
        this.mode.create ? 'createActionConfigs' : 'updateActionConfigs'
      }`;
    },
    forwardEnabled() {
      return Object.entries(this.forward || {})
        .map(([, value]) => value.enabled)
        .includes(true);
    },
    alertConfig() {
      return (
        this.v$.internalAction.alertConfig.jamfCloud.maxSeverity.$error ||
        this.v$.internalAction.alertConfig.jamfCloud.minSeverity.$error
      );
    },
    ulogConfig() {
      return (
        this.v$.internalAction.ulogConfig.jamfCloud.maxSeverity.$error ||
        this.v$.internalAction.ulogConfig.jamfCloud.minSeverity.$error
      );
    },
  },
  methods: {
    async submit() {
      const response = await this.$store.dispatch(
        this.endpoint,
        this.internalAction
      );
      if (response) {
        this.internalAction.id = response.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  @include grid(spacing());
  @include grid-columns(max-content auto);
  align-items: center;
  margin-bottom: spacing();
  margin-top: spacing(-1);

  svg {
    color: var(--color-warning-base);
  }
}

.pad-bottom {
  margin-bottom: 0.5rem;
}
</style>
