<template>
  <router-link v-if="to && value" ref="link" :to="to" @click="actions">
    {{ value }}
  </router-link>
</template>

<script>
import { useTableCell } from './tableCell';

export default {
  name: 'TableCellLink',
  props: ['params'],
  setup(props) {
    const { routeTo, eventType, addEventListener, actions, link } =
      useTableCell(props);
    eventType.value = 'cellKeyDown';
    return { routeTo, addEventListener, eventType, actions, link };
  },
  computed: {
    value() {
      return this.params.valueFormatted || this.params.value;
    },
    to() {
      return this.params.route(this.params);
    },
  },
  mounted() {
    if (!this.params.route) {
      // eslint-disable-next-line
      console.error(`[Ag-grid Column error] must provide cellRendererParams -> "route"`);
      return;
    }
    this.addEventListener(this.eventType, this.routeTo);
  },
};
</script>
