import gql from 'graphql-tag';
import DataRetentionFields from './fragments/data-retention.fragment.gql';
import DataForwardFields from './fragments/data-forward.fragment.gql';

export const GET_DATA_RETENTION = gql`
  query getDataRetention {
    getOrganization {
      ...DataRetentionFields
    }
  }
  ${DataRetentionFields}
`;

export const GET_DATA_FORWARD = gql`
  query getDataForward {
    getOrganization {
      ...DataForwardFields
    }
  }
  ${DataForwardFields}
`;
