export const IGNORE_TYPE = Object.freeze({
  AppSigningInfo: 'AppSigningInfo',
  TeamId: 'TeamId',
  Executable: 'Executable',
  PlatformBinary: 'PlatformBinary',
  Path: 'Path',
  User: 'User',
});

export const IGNORE_TYPE_NAMES = Object.freeze({
  [IGNORE_TYPE.AppSigningInfo]: 'App Signing Info',
  [IGNORE_TYPE.TeamId]: 'Team ID',
  [IGNORE_TYPE.Executable]: 'Process Path',
  [IGNORE_TYPE.PlatformBinary]: 'Platform Binary',
  [IGNORE_TYPE.Path]: 'File Path',
  [IGNORE_TYPE.User]: 'User',
});

export const IGNORE_TYPE_PLACEHOLDERS = Object.freeze({
  [IGNORE_TYPE.TeamId]: 'e.g. 526FTYP998',
  [IGNORE_TYPE.Executable]: 'e.g. /Applications/ThisApp.app',
  [IGNORE_TYPE.Path]: 'e.g. /tmp/log/*',
  [IGNORE_TYPE.PlatformBinary]: 'com.apple.calculator',
  [IGNORE_TYPE.User]: 'John Doe',
});

export const IGNORE_ACTIVITY = Object.freeze({
  All: 'All',
  ThreatPrevention: 'ThreatPrevention',
  Analytics: 'Analytics',
  AnalyticsEvents: 'AnalyticsEvents', // not a value on the BE, FE only use
  Telemetry: 'Telemetry',
});

export const IGNORE_ACTIVITY_NAMES = Object.freeze({
  [IGNORE_ACTIVITY.ThreatPrevention]: 'Override Endpoint Threat Prevention',
  [IGNORE_ACTIVITY.AnalyticsEvents]: 'Ignore System Events for Analytics',
  [IGNORE_ACTIVITY.Analytics]: 'Ignore for Analytic',
  [IGNORE_ACTIVITY.Telemetry]: 'Ignore for Telemetry',
});
