<template>
  <j-button :data-feature-id="dataFeatureId"
    ><template #leading><j-icon data="@icon/plus-small.svg" /></template
    ><template v-if="label" #default>{{ label }}</template></j-button
  >
</template>

<script>
export default {
  name: 'CreateButton',
  compatConfig: {
    MODE: 3,
  },
  props: {
    label: {
      type: String,
      default: 'Create',
    },
    dataFeatureId: {
      type: String,
      default: 'create',
    },
  },
};
</script>
