<template>
  <div class="gp-content">
    <slot name="top" :toggle="toggle" :show-panel="showPanel"></slot>

    <div class="split-view" :class="{ [displayMobileAs]: true }">
      <div
        :class="['left', showPanel ? '' : 'hide', ...leftClasses]"
        :style="{ 'max-width': splitBy.left }"
      >
        <slot :toggle="toggle" :show-panel="showPanel"></slot>
      </div>
      <transition name="fade">
        <div
          v-if="showPanel"
          :class="['overlay', showPanel ? '' : 'hide']"
          @click="close"
        ></div
      ></transition>
      <div
        :class="['right', showPanel ? '' : 'hide', ...rightClasses]"
        @click="close"
        :style="{ 'max-width': splitBy.right }"
        @keydown.enter="close"
      >
        <slot name="right" :toggle="toggle" :show-panel="showPanel"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitPanel',
  props: {
    splitBy: {
      type: Object,
      default: () => ({ left: 'auto', right: '40%' }),
    },
    displayMobileAs: {
      type: String,
      default: 'panel',
      validator(value) {
        // The value must match one of these strings
        return ['panel', 'below'].includes(value);
      },
    },
    rightClasses: {
      type: Array,
      default: () => ['scroll-vertical', 'pl-2', 'pr-2'],
    },
    leftClasses: {
      type: Array,
      default: () => ['scroll-vertical', 'pl-2', 'pr-2'],
    },
  },
  emits: ['resize'],
  data() {
    return {
      showPanel: true,
      smallScreenWidth: 812,
      clientWidth: document.documentElement.clientWidth,
      isMobile: false,
    };
  },
  methods: {
    close() {
      if (this.isMobile && this.displayMobileAs.includes('panel')) {
        this.showPanel = false;
      }
    },
    handleResize() {
      this.clientWidth = document.documentElement.clientWidth;
      this.isMobile = this.clientWidth <= this.smallScreenWidth;
      this.$emit('resize', this.isMobile);
      this.close();
    },
    toggle() {
      this.showPanel = !this.showPanel;
    },
  },
  mounted() {
    this.clientWidth = document.documentElement.clientWidth;
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.split-view {
  @include flex-columns(0, 60%);
  height: 100%;
  .right {
    @include transition(
      flex-basis max-width opacity background-color transform,
      300ms,
      ease-in-out
    );

    border-left: 1px solid var(--color-border-secondary);
    opacity: 1;
    height: 100%;
    transform: 0;
    flex-basis: 40%;

    &.hide {
      flex-basis: 0;
      opacity: 0;
      padding: 0 !important;
      transform: translateX(100%);
    }
  }

  .left {
    @include transition(flex-basis, 300ms, ease-in-out);
    &.hide {
      flex-basis: 100%;
    }
  }
  .overlay {
    display: none;
  }

  @include breakpoint(small down) {
    &.panel {
      @include flex-columns(0, 100%, 0);

      .overlay {
        @include modal-overlay;
        cursor: pointer;
        display: block;
      }

      .right {
        position: fixed;
        top: 0;
        right: 0;
        background-color: var(--color-structure-base);
        max-width: 75% !important;
        width: 75%;

        &.hide {
          pointer-events: none;
          opacity: 1;
          padding: inherit !important;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    &.below {
      @include grid;
      @include grid-rows(max-content auto);
      height: 100%;

      .left,
      .right {
        max-width: 100% !important;
      }

      .right {
        border-top: 1px solid;
        border-left: 0px;
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          transparent 2%,
          var(--color-border-secondary) 2%,
          var(--color-border-secondary) 98%,
          transparent 98%
        );
        border-image-slice: 1;
      }
    }
  }
}
</style>
