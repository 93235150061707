<template>
  <div class="item">
    <p class="item-title">{{ title }}</p>
    <div class="item-info">
      <slot></slot>
      <j-icon
        v-if="isEmpty"
        data="@jcon/minus.svg"
        color="var(--color-font-secondary)"
      />
    </div>
  </div>
</template>

<script>
import { computed, useSlots } from 'vue';
export default {
  name: 'ListItem',
  props: {
    title: String,
  },
  setup() {
    const slots = useSlots();
    const isEmpty = computed(() => {
      const slot = slots?.default?.()[0]?.children;
      const isEmptyArray =
        Array.isArray(slot) && slot[0].children?.length === 0;
      return !slot || isEmptyArray || ['v-if', ''].includes(slot)
        ? true
        : false;
    });
    return { isEmpty };
  },
};
</script>

<style lang="scss" scoped>
.item {
  @include grid(spacing(0.5));
  @include grid-rows(max-content);
  &-title {
    @include helper-text;
    &.has-icon {
      grid-auto-columns: 20px minmax(0, auto);
    }
  }

  &-info {
    @include input-label;
    white-space: pre-wrap;
    word-wrap: anywhere;
  }
}
</style>
