<template>
  <div class="initialize">
    <j-icon data="@icon/protect_color_knockout.svg" height="150" width="350" />
    <gp-loader :show-loader="loading" />
    <p v-if="message" class="info">
      {{ message }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { addPendoSnippet } from '@/services/pendo-snippet';
import { sleep } from '@/util';
import { captureException, setUser } from '@sentry/vue';
import { useRouteHelpers } from '@/composables/route-helpers';

export default {
  name: 'Initialize',
  data() {
    return {
      message: 'Initializing User',
      loading: true,
    };
  },
  setup() {
    const { removeCloudOnlyRoutes } = useRouteHelpers();

    return { removeCloudOnlyRoutes };
  },
  computed: {
    ...mapState(['siteEnv', 'initialRoute', 'app']),
    ...mapGetters('auth', ['isLoggedIn', 'identity']),
    ...mapGetters('user', ['hasAppAccess']),
    ...mapGetters(['hasLimitedAppAccess']),
  },
  async mounted() {
    if (this.isLoggedIn) {
      setUser({ id: this.identity.sub });

      await this.$store.dispatch('user/getCurrentPermissions');

      if (!this.hasAppAccess) {
        captureException(
          new Error('User Does Not Have App Access - Not Authorized'),
          (scope) => {
            scope.setTag('app_access', 'none');
          }
        );
        this.$router.push({ name: 'logout' });
        return;
      }

      await this.$store.dispatch('getAppInitializationData');

      this.message = 'Getting app information';

      // re-initialize to ensure server side flags are set.
      await this.$store.dispatch('initializeLDClient');

      if (this.app.info === null) {
        this.message = 'Error getting app information';
        this.loading = false;
        return;
      }

      if (this.hasLimitedAppAccess) {
        this.removeCloudOnlyRoutes();
      }

      if (this.siteEnv === 'production') {
        addPendoSnippet(this.app.info, this.identity);
      }

      if (
        !this.app.info.eulaAccepted ||
        this.app.info.acceptedSlasaVersion !== 2
      ) {
        this.$router.push({ name: 'eula' });
        return;
      }

      this.message = 'All good, welcome to Jamf Protect!';
      await sleep(400);
      this.loading = false;

      this.toInitialRoute();
    } else {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    toInitialRoute() {
      const index = { name: 'index' };
      if (
        this.initialRoute?.name &&
        !['eula', 'initialize'].includes(this.initialRoute.name)
      ) {
        this.$router.push(
          this.hasLimitedAppAccess &&
            !this.$router.hasRoute(this.initialRoute.name)
            ? index
            : this.initialRoute
        );
      } else {
        this.$router.push(index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.initialize {
  @include transition(background);
  height: 100vh;
  width: 100%;
  padding: spacing();
  background: var(--color-initialize-base);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .info {
    text-align: center;
    margin-bottom: 15px;
    color: #919191;
  }
}
</style>
