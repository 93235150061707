import { LIST_ALERTS, GET_ALERT, UPDATE_ALERTS } from './alerts.queries';

export default {
  actions: {
    // queries
    async getAlert({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ALERT,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.getAlert;
      }
      return result;
    },
    async listAlerts({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ALERTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listAlerts;
      }
      return result;
    },
    // mutations
    async updateAlerts({ dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ALERTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.updateAlerts;
      }
      return result;
    },
  },
};
