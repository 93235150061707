import Section from '@/components/Section.vue';
import AnalyticsSection from './AnalyticsSection.vue';
import analyticSetsRoutes from '@/modules/analytics/analytic-sets/analyticSets.route';
import Analytics from './Analytics.vue';

import AnalyticForm from './AnalyticForm.vue';
import Analytic from './Analytic.vue';
import AnalyticDetails from './tabs/AnalyticDetails.vue';
import AnalyticDelete from './tabs/AnalyticDelete.vue';
import AnalyticInternalEdit from './tabs/AnalyticInternalEdit.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default [
  {
    path: '/analytics',
    component: AnalyticsSection,
    name: 'analytics',
    meta: {
      breadcrumb: {
        linkName: 'analytics.index',
        linkText: 'Analytics',
      },
      permissions: [RBAC_RESOURCE.ANALYTIC],
    },
    children: [
      {
        path: '',
        component: Analytics,
        name: 'analytics.index',
      },
      {
        path: 'analytic',
        component: Section,
        children: [
          {
            path: 'create',
            component: AnalyticForm,
            name: 'analytics.index.create',
            meta: {
              breadcrumb: {
                linkText: 'Create',
              },
              requireWrite: true,
            },
          },
          {
            path: ':id',
            component: Analytic,
            meta: {
              breadcrumb: {
                linkName: 'analytics.index.detail',
                linkText: 'Analytic Info',
              },
            },
            children: [
              {
                path: '',
                name: 'analytics.index.detail',
                component: AnalyticDetails,
                props: true,
              },
              {
                path: 'edit',
                name: 'analytics.index.detail.edit',
                component: AnalyticForm,
                props: true,
                meta: {
                  requireWrite: true,
                },
              },
              {
                path: 'delete',
                name: 'analytics.index.detail.delete',
                component: AnalyticDelete,
                props: true,
                meta: {
                  requireWrite: true,
                },
              },
              {
                path: 'actions',
                name: 'analytics.index.detail.actions',
                component: AnalyticInternalEdit,
                props: true,
                meta: {
                  requireWrite: true,
                },
              },
            ],
          },
        ],
      },
      analyticSetsRoutes,
    ],
  },
];
