<template>
  <div class="scroll-vertical">
    <div class="form-content">
      <j-switch v-model="internalData.enabled" data-feature-id="enable-filter"
        >Enable Filter</j-switch
      >
      <j-input
        v-model="internalData.name"
        data-feature-id="name"
        label="Name"
        :has-error="v$.internalData.name.$error"
        :error-text="nameErrorMessage"
      />
      <j-textarea
        v-model="internalData.filter"
        :has-error="v$.internalData.filter.$error"
        data-feature-id="unified-logging-filter"
        label="Filter"
        error-text="Filter is required"
      />
      <j-select
        v-model="internalData.tags"
        :options="tagOptions"
        :searchable="tagOptions.length > 10"
        allow-multi-select
        is-addable
        deselect-from-dropdown
        label="Tags"
        placeholder="Add a Tag"
        data-feature-id="filter-tags"
      />
    </div>
  </div>
</template>

<script>
import { useForm } from '@/composables/forms';
import { mapState } from 'vuex';
import { isUniqueName } from '@/util/custom-validators';
import { required } from '@vuelidate/validators';

export default {
  name: 'UnifiedLoggingFilterForm',
  props: {
    data: Object,
  },
  setup() {
    const { v$, mode } = useForm();
    return { v$, mode };
  },
  validations() {
    return {
      internalData: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
        filter: {
          required,
        },
      },
    };
  },
  data() {
    return {
      internalData: { enabled: true, tags: [] },
      tagOptions: [],
    };
  },
  mounted() {
    if (!this.mode.create) {
      this.tagOptions = [...this.data.tags];
      this.internalData = { ...this.data, tags: [...this.data.tags] };
    }
  },
  computed: {
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) =>
        state.unifiedlogging.unifiedLoggingFiltersNames,
      currentName: (state) => state.unifiedlogging.unifiedLoggingFilter?.name,
    }),
    nameErrorMessage() {
      return this.v$.internalData.name.$errors[0]?.$message;
    },
    endpoint() {
      return `primary/${
        this.mode.create
          ? 'createUnifiedLoggingFilter'
          : 'updateUnifiedLoggingFilter'
      }`;
    },
    existing() {
      return Object.prototype.hasOwnProperty.call(this.data, 'uuid');
    },
  },
  methods: {
    async submit() {
      const response = await this.$store.dispatch(
        this.endpoint,
        this.internalData
      );
      this.internalData.uuid = response?.uuid;
    },
  },
};
</script>
