import DELETE_COMPUTER from './mutations/deleteComputer.gql';
import SEND_CHECKIN_MESSAGE from './mutations/sendCheckinMessage.gql';
import SET_COMPUTER_PLAN from './mutations/setComputerPlan.gql';
import UPDATE_COMPUTER from './mutations/updateComputer.gql';
import GET_COMPUTER from './queries/getComputer.gql';
import GET_RELATED_COMP_INFO from './queries/getRelatedComputerInformation.gql';
import LIST_COMPUTERS from './queries/listComputers.gql';
import LIST_COMPUTERS_STATS_FILTER_OPTIONS from './queries/listComputersStatsFilterOptions.gql';
import LIST_COMPUTERS_TAGS from './queries/listComputersTags.gql';

export default {
  state: {
    computer: null,
    latestTPVersion: null,
    latestProtectVersion: null,
  },
  mutations: {
    SET_COMPUTER(state, payload) {
      state.computer = payload;
    },
    SET_LATEST_VERSION_INFO(state, payload) {
      state.latestTPVersion = Number(payload.threatPreventionVersions?.[0]?.id);
      state.latestProtectVersion = payload.version;
    },
  },
  actions: {
    // queries
    async getComputer({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_COMPUTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_COMPUTER', result.data.getComputer);
        return result.data.getComputer;
      }
      return result;
    },
    async listComputers({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_COMPUTERS,
          variables: payload,
        },
        { root: true }
      );
      return result?.data?.listComputers;
    },
    async getRelatedComputerInformation({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_RELATED_COMP_INFO,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        const versions = {
          threatPreventionVersions: result.data?.listThreatPreventionVersions,
          version:
            result.data?.getOrganizationDownloads?.vanillaPackage?.version,
        };
        commit('SET_LATEST_VERSION_INFO', versions);
        return versions;
      }
      return result;
    },
    async listComputersStatsFilterOptions({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_COMPUTERS_STATS_FILTER_OPTIONS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        const labels = Object.entries(result.data).reduce(
          (map, [key, value]) => {
            map[key] = value.sort();
            return map;
          },
          {}
        );

        return labels;
      }
      return result;
    },
    // mutations
    async updateComputer({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_COMPUTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_COMPUTER', result.data.updateComputer);
        return result.data.updateComputer;
      }
      return result;
    },
    async deleteComputer({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_COMPUTER,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_COMPUTER', null);
        return result.data.deleteComputer;
      }
      return result;
    },
    async setComputerPlan({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: SET_COMPUTER_PLAN,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_COMPUTER', result.data.setComputerPlan);
        return result.data.setComputerPlan;
      }
      return result;
    },
    async sendCheckinMessage({ dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: SEND_CHECKIN_MESSAGE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.sendCheckinMessage.success;
      }
      return result;
    },
    async listComputersTags({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_COMPUTERS_TAGS,
        },
        { root: true }
      );
      return result?.data.listComputersTags;
    },
  },
};
