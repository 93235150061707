<template>
  <div class="actions-data-config g-col-4">
    <template v-for="(obj, name) in config.data" :key="name">
      <TileActionDataConfig
        v-if="defs.mappings[name]"
        :title="`${defs.mappings[name].label}`"
        :config="obj"
        :def="defs.mappings[name]"
      />
    </template>
  </div>
</template>

<script>
import TileActionDataConfig from '@/modules/actions/components/TileActionDataConfig.vue';

export default {
  name: 'DataConfigsTiles',
  inject: ['$typeDefs'],
  components: { TileActionDataConfig },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defs: this.$typeDefs,
    };
  },
};
</script>

<style lang="scss" scoped>
.actions-data-config {
  @include breakpoint(small down) {
    @include grid-wrap(floor(calc(100% / 2)));
  }
}
</style>
