/* eslint-disable camelcase */
const getters = {
  isLoggedIn({ token }) {
    return !!token;
  },
  idToken(state) {
    return state.token;
  },
  identity({ user, token }) {
    return token ? user : null;
  },
  isJamfAdmin({ namespace, loginConfig: { admin_graphql_url } }, { identity }) {
    const rolesKey = `${namespace}/roles`;
    if (
      identity !== null &&
      Object.prototype.hasOwnProperty.call(identity, rolesKey) &&
      admin_graphql_url
    ) {
      return identity[rolesKey].includes('protect.jamfadmin');
    }
    return false;
  },
  isJamfDev({ namespace }, { identity }) {
    const rolesKey = `${namespace}/roles`;
    if (
      identity !== null &&
      Object.prototype.hasOwnProperty.call(identity, rolesKey)
    ) {
      return identity[rolesKey].includes('protect.jamfdev');
    }
    return false;
  },
};

export default getters;
