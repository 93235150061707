<template>
  <NoResultsOverlay
    data-testid="no-access"
    title="Sorry, you can’t access this page."
    message="Unfortunately, you do not have the required permissions to access this page."
  />
</template>

<script>
import NoResultsOverlay from './NoResultsOverlay.vue';

export default {
  name: 'Errors',
  components: { NoResultsOverlay },
};
</script>
