<template>
  <div class="filter-container">
    <slot name="options"></slot>
    <div v-if="includeOperators" class="operators" role="radioGroup">
      <slot name="operators"></slot>
    </div>
    <slot></slot>
    <div class="filter-buttons">
      <j-button style-type="ghost" @click="secondaryAction">Clear</j-button>
      <j-button @click="primaryAction" :is-disabled="disableAction"
        >Apply</j-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFilterStyle',
  props: {
    includeOperators: Boolean,
    secondaryAction: { type: Function, default: () => null },
    primaryAction: { type: Function, default: () => null },
    disableAction: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  @include grid;
  padding: spacing(2);

  > input {
    @include input-field-base;
    min-width: auto;
  }
  .filter-buttons {
    --size-action-height-base: var(--size-action-height-secondary);
    @include grid;
    @include grid-columns(max-content);
    justify-content: end;
  }

  .operators {
    @include grid;
    @include grid-columns(max-content);
  }
}
</style>
