<template>
  <div class="scroll-vertical p-2 related-binaries">
    <transition-group name="list" tag="div" class="g-rows max-c">
      <Card
        v-for="(binary, index) in binaries"
        :key="binary.sha1hex"
        class="spacer g-col-2"
      >
        <h1 v-if="binaries.length > 1" class="h4">Binary {{ index + 1 }}</h1>
        <ListItem title="Path" class="col-span-1">
          {{ binary.path }}
        </ListItem>
        <AlertSigningInfo
          class="col-span-1"
          :signing-info="binary.signingInfo"
          :related="related"
        />
        <ListItem
          class="col-span-1"
          v-if="binary.xattrs && binary.xattrs.length > 0"
          title="XAttrs"
        >
          <div class="vertical">
            <div v-for="attr in binary.xattrs" :key="attr" class="attr">
              {{ attr }}
            </div>
          </div>
        </ListItem>
        <HashItem
          :sha1="binary.sha1hex"
          :sha256="binary.sha256hex"
          class="col-span-1"
        />
        <hr class="col-span-1" />
        <ListItem title="Modified">
          {{ formatEpochDate(binary.modified) }}
        </ListItem>
        <ListItem title="Changed">
          {{ formatEpochDate(binary.changed) }}
        </ListItem>
        <ListItem title="Created">
          {{ formatEpochDate(binary.created) }}
        </ListItem>
        <ListItem title="Accessed">
          {{ formatEpochDate(binary.accessed) }}
        </ListItem>
      </Card>
      <NoRelatedInfo
        v-if="!binaries.length"
        title="0 Binaries"
        :key="'0-empty'"
      />
    </transition-group>
  </div>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
import Card from '@/components/Card.vue';
import { useGmt } from '@/composables/gmt';
import AlertSigningInfo from '@/modules/alerts/components/AlertSigningInfo.vue';
import HashItem from './HashItem.vue';
import NoRelatedInfo from './NoRelatedInfo.vue';

export default {
  name: 'AlertRelatedBinaries',
  compatConfig: {
    MODE: 3,
  },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  components: {
    ListItem,
    Card,
    AlertSigningInfo,
    HashItem,
    NoRelatedInfo,
  },
  props: {
    binaries: {
      type: Array,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
};
</script>
