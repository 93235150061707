<template>
  <div class="gp-content" v-if="filtered">
    <div class="compliance-bar">
      <div class="compliance-info">
        <h1>Viewing {{ totalCount }} Compliance Rules</h1>
        <h6 class="has-divider">
          {{ failCount }}/{{ totalCount }} Noncompliant
        </h6>
      </div>
      <div v-if="hasKey" class="key">
        <span> <span class="color fail"></span>Noncompliant </span>
        <span> <span class="color pass"></span>Compliant </span>
        <span> <span class="color"></span>Unknown </span>
      </div>
    </div>
    <div class="filters-bar">
      <div class="toggle-group compliance-tags">
        <label>Filter:</label>
        <label
          v-for="(item, key) in tags"
          :key="key"
          class="toggle"
          :data-feature-id="key"
        >
          <input
            :value="item.value"
            :checked="item.value"
            @input="setBenchmark(key)"
            type="checkbox"
          />
          <span class="indicator">{{ item.tag }}</span>
        </label>
      </div>
      <div class="toggle-group has-divider">
        <label class="toggle" data-feature-id="enabled">
          <input v-model="includeEnabled" type="checkbox" />
          <span class="indicator"
            >Enabled Compliance Rules ({{ enabledCount }})</span
          >
        </label>
        <label class="toggle" data-feature-id="disabled">
          <input v-model="includeDisabled" type="checkbox" />
          <span class="indicator"
            >Disabled Compliance Rules ({{ disabledCount }})</span
          >
        </label>
      </div>
      <slot name="filters" :filtered="filtered"></slot>
      <div class="has-divider">
        <j-tooltip text="Export current view as CSV" open-position="bottom">
          <j-button
            style-type="primary"
            @click="exportCsv"
            data-feature-id="export-csv-compliance"
            :data-csv-name="csvFileName"
            aria-label="Export current view as CSV"
            class="is-small"
          >
            <template #leading>
              <j-icon data="@jcon/download-2.svg" height="12" width="12" />
            </template>
            Download Report
          </j-button>
        </j-tooltip>
      </div>
    </div>
    <div class="scroll-vertical compliance">
      <template v-for="(items, title) in sections" :key="title">
        <template v-if="items.length > 0">
          <h4>{{ title }}</h4>
          <transition-group
            name="fade"
            tag="div"
            class="compliance-section"
            :duration="200"
          >
            <slot :section="items" :set-enabled="setEnabled"></slot>
          </transition-group>
        </template>
      </template>
      <NoResultsOverlay v-if="filtered.length <= 0" />
    </div>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import NoResultsOverlay from '@/components/NoResultsOverlay.vue';
import { downloadFromUrl } from '@/util';
import { groupBy } from 'lodash';

export default {
  name: 'Compliance',
  components: { NoResultsOverlay },
  props: {
    compliance: {
      type: Array,
      required: true,
    },
    hasKey: {
      type: Boolean,
      default: true,
    },
    singleDevice: {
      type: String,
      default: null,
    },
    externalFilters: Function,
    getFailCount: Function,
  },
  setup() {
    const { getDate } = useGmt();
    return { getDate };
  },
  data() {
    return {
      benchmarks: [],
      tags: {
        level1: { tag: 'CIS Level 1', value: false },
        level2: { tag: 'CIS Level 2', value: false },
        jamf: { tag: 'Jamf', value: false },
      },
      includeEnabled: true,
      includeDisabled: false,
      filtered: null,
    };
  },
  watch: {
    benchmarks: {
      deep: true,
      handler() {
        this.filtered = this.getFiltered();
      },
    },
    compliance() {
      this.filtered = this.getFiltered();
    },
    includeEnabled() {
      this.filtered = this.getFiltered();
    },
    includeDisabled() {
      this.filtered = this.getFiltered();
    },
  },
  computed: {
    sections() {
      return groupBy(this.filtered ?? this.compliance, 'section');
    },
    totalCount() {
      return this.getFiltered().length;
    },
    failCount() {
      return this.getFailCount
        ? this.getFailCount(this.filtered)
        : this.filtered.filter(({ totalFail }) => totalFail >= 1).length;
    },
    enabledCount() {
      const items = this.externalFilters
        ? this.externalFilters(this.filterTags(this.compliance))
        : this.filterTags(this.compliance);
      return items.filter(({ enabled }) => enabled).length;
    },
    disabledCount() {
      const items = this.externalFilters
        ? this.externalFilters(this.filterTags(this.compliance))
        : this.filterTags(this.compliance);
      return items.filter(({ enabled }) => !enabled).length;
    },
    csvFileName() {
      const date = this.getDate(Date.now() / 1000).replaceAll('/', '-');
      const device = this.singleDevice ? ` ${this.singleDevice}` : '';
      return `Jamf Protect Compliance Report${device} ${date}.csv`;
    },
    csvItems() {
      // rename and filter out item fields we don't want in CSV
      if (this.singleDevice) {
        const passStatus = {
          true: 'Yes',
          false: 'No',
          null: 'Unknown',
        };
        return this.filtered.map((item) => ({
          'Compliance Rule Name': item.label,
          Status: item.enabled ? 'Enabled' : 'Disabled',
          Compliant: passStatus[String(item.pass)],
          'Benchmark Level': item.tags,
          Category: item.section,
          'Compliance Rule Description': item.description,
        }));
      }
      return this.filtered.map((item) => ({
        'Compliance Rule Name': item.label,
        Status: item.enabled ? 'Enabled' : 'Disabled',
        'Total Compliant': item.totalPass,
        'Total Noncompliant': item.totalFail,
        'Total Unknown': item.totalNone,
        'Benchmark Level': item.tags,
        Category: item.section,
        'Compliance Rule Description': item.description,
      }));
    },
  },
  mounted() {
    this.filtered = this.getFiltered();
  },
  methods: {
    getFiltered() {
      const items = this.compliance.filter(({ enabled }) => {
        if (this.includeDisabled && !this.includeEnabled) {
          return !enabled;
        }
        if (!this.includeDisabled && this.includeEnabled) {
          return enabled;
        }
        return true;
      });
      const result = this.externalFilters
        ? this.externalFilters(this.filterTags(items))
        : this.filterTags(items);
      return result;
    },
    filterTags(items) {
      return this.benchmarks.length > 0
        ? this.benchmarks
            .map((tag) => items.filter((item) => item.tags?.includes(tag)))
            .flat()
        : items;
    },
    setBenchmark(key) {
      const { tag, value } = this.tags[key];
      this.tags[key].value = !value;
      if (!value) {
        this.benchmarks.push(tag);
      } else {
        this.benchmarks = this.benchmarks.filter((item) => item !== tag);
      }
    },
    getSection(section) {
      return this.filtered.filter(
        (compliance) => compliance.section === section
      );
    },
    async setEnabled(uuid, enabled) {
      const result = await this.$store.dispatch(
        'primary/updateComplianceStatus',
        {
          uuid,
          enabled: enabled?.target?.checked,
        }
      );
      if (result) {
        this.filtered = this.getFiltered(this.benchmarks);
      }
    },
    jsonToCsv(json) {
      const replacer = (_, value) => {
        if (value === null) {
          return '';
        } else if (typeof value === 'string' || value instanceof String) {
          return value.replace(/"/g, "'");
        } else if (Array.isArray(value)) {
          return value.join(', ');
        }
        return value;
      };
      const header = Object.keys(json[0]);
      return [
        header.join(','), // header row first
        ...json.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        ),
      ].join('\n');
    },
    exportCsv() {
      const csvStr = this.jsonToCsv(this.csvItems);
      const url = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
      downloadFromUrl(url, this.csvFileName);
    },
  },
};
</script>

<style lang="scss" scoped>
$color-fail: var(--color-warning-base);
$color-pass: var(--color-success-base);
$color-none: var(--color-font-base);

.compliance-section {
  @include grid;
  grid-template-columns: repeat(auto-fill, calc(25% - 12px));
  height: min-content;

  @include breakpoint(medium down) {
    grid-template-columns: repeat(auto-fill, calc(33% - 9px));
  }

  @include breakpoint(small down) {
    grid-template-columns: repeat(auto-fill, calc(50% - 8px));
  }
}
.compliance {
  @include grid;
  padding: var(--size-grid-gap-base);
  grid-auto-rows: min-content;
  position: relative;

  h4 {
    @include header4;
  }
}

.compliance-bar,
.filters-bar {
  height: 48px;
  border-bottom: 1px solid var(--color-border-secondary);
  display: flex;
  align-items: center;
  padding: 0 var(--size-grid-gap-base);
  flex-wrap: wrap;

  @include breakpoint(small down) {
    height: auto;
    > div {
      padding-top: spacing();
      padding-bottom: spacing();
    }

    .compliance-info {
      padding-right: var(--size-grid-gap-base);
    }
  }
}
.compliance-bar {
  justify-content: space-between;

  .compliance-info {
    display: flex;
    h1 {
      @include header4;
      padding-right: var(--size-grid-gap-base);
    }
  }
  .key {
    @include grid;
    grid-auto-flow: column;
    border-left: 1px solid var(--color-border-secondary);
    padding-left: var(--size-grid-gap-base);
    height: 100%;
    align-items: center;
    span {
      display: flex;
      align-items: center;

      .color {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border: 1px solid $color-none;

        &.pass {
          border-color: $color-pass;
          background: $color-pass;
        }
        &.fail {
          border-color: $color-fail;
          background: $color-fail;
        }
      }
    }
    @include breakpoint(small down) {
      border-left: 0;
      height: auto;
      padding-left: 0;
    }
  }
}

:deep(.has-divider) {
  padding-left: var(--size-grid-gap-base);
  border-left: 1px solid var(--color-border-secondary);
}

.filters-bar {
  @include grid;
  @include grid-columns(max-content);

  @include breakpoint(small down) {
    grid-auto-columns: auto;
    > div {
      padding-bottom: 0;
    }
    :deep(.toggle) {
      margin-bottom: spacing() !important;
    }
  }
}

:deep(.toggle-group) {
  @include toggle-group-container;
}

:deep(.toggle) {
  @include toggle();
  margin-bottom: 0 !important;
  &:last-child {
    margin-right: 0 !important;
  }
}

.is-small {
  --size-action-height-base: var(--size-action-height-secondary);
}
</style>
