const mutations = {
  SET_SESSION(state, { session }) {
    state.user = session.user;
    state.token = session.token;
  },
  SET_LOGIN_CONFIG(state, payload) {
    state.hasLoginConfig = !!payload.client_id;
    if (state.hasLoginConfig) {
      state.loginConfig = payload;
      const nsIndex = payload.audience?.indexOf('-', 7) + 1;
      state.loginConfig.beBranch =
        nsIndex === 0 ? '' : payload.audience?.substring(nsIndex);
      state.loginConfig.branch =
        process.env.VUE_APP_PRIMARY_DOMAIN?.split('.')[0].toUpperCase() ||
        state.loginConfig.beBranch;

      state.loginConfig.account = payload.domain?.split('.')[1].split('-')[0];
    }
  },
  SET_AUTH_CLIENT(state, payload) {
    state.hasAuthClient = payload;
  },
  LOGOUT(state) {
    state.token = null;
    state.expiresAt = null;
  },
  SET_ERROR_MESSAGE(state, payload) {
    state.error = payload;
  },
  SET_SESSION_NULL(state) {
    state.user = null;
    state.token = null;
    state.expiresAt = null;
  },
};

export default mutations;
