<template>
  <AnalyticsList
    :include-selection="includeSelection"
    v-model="analytics"
    :is-admin="isAdmin"
  >
    <TitleDescriptionActionLayout
      v-if="!isAdmin"
      title="All Analytics"
      description="Analytics detect suspicious user behavior and system activity. 
      Jamf-managed analytics and categories are maintained by Jamf. 
      Create custom analytics to detect activity specific to your security needs. 
      To deploy analytics, add individual analytics to one or more analytic sets, and then add each analytic set to one or more plans."
    >
      <div class="g-cols max-c pt-1 js-end">
        <slot></slot>
        <j-create-button
          v-if="canWrite && !includeSelection"
          @click="$router.push({ name: 'analytics.index.create' })"
          label="Create custom analytic"
        />
        <j-save-button
          v-if="canWritePlan && includeSelection"
          @click="selectAction"
          :label="`Save plan with ${analytics ? analytics.length : 0}`"
        />
      </div>
    </TitleDescriptionActionLayout>
  </AnalyticsList>
</template>

<script>
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import AnalyticsList from './components/AnalyticsList.vue';
import TitleDescriptionActionLayout from '@/components/TitleDescriptionActionLayout.vue';

export default {
  name: 'Analytics',
  components: { AnalyticsList, TitleDescriptionActionLayout },
  props: {
    includeSelection: Boolean,
    plan: Object,
    isAdmin: Boolean,
  },
  emits: ['submit:analytics'],
  setup() {
    const { hasWritePermissions, canWrite } = useRBAC([RBAC_RESOURCE.ANALYTIC]);
    return { hasWritePermissions, canWrite };
  },
  data() {
    return {
      analytics: this.plan?.analytics || [],
    };
  },
  computed: {
    canWritePlan() {
      return this.hasWritePermissions(RBAC_RESOURCE.PLAN);
    },
  },
  methods: {
    // used in old plan state
    selectAction() {
      this.$emit(
        'submit:analytics',
        this.analytics.map(({ uuid }) => uuid)
      );
    },
  },
};
</script>
