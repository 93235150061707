<template>
  <div class="login-page" tabindex="-1">
    <login-base v-if="loginConfig.client_id && loaded" :error="error">
      <template v-if="loginConfig.connections">
        <template
          v-for="connection in loginConfig.connections"
          :key="connection.name"
        >
          <ConnectorButton
            :name="connection.name"
            :count="getCount(connection.button)"
            :type="connection.button"
            @click="doLogin(connection.name)"
          />
        </template>
      </template>
      <ConnectorButton
        v-else-if="loginConfig.connector"
        :name="loginConfig.connector"
        :count="getCount(loginConfig.button)"
        :type="loginConfig.button"
        @click="doLogin(loginConfig.connector)"
      />
      <template #message>
        <transition name="fade">
          <j-notification v-if="message" :type="messagetype">
            <p>
              <template v-for="(node, n) in message" :key="node">
                <a
                  v-if="n % 2"
                  :href="messageLinks[node]"
                  rel="noopener"
                  target="_blank"
                  >{{ messageLinks[node] }}</a
                >
                <template v-else>{{ node }}</template>
              </template>
            </p>
          </j-notification>
        </transition>
      </template>
    </login-base>
    <login-base v-else-if="loaded">
      <h3>There doesn't seem to be anything here!</h3>
      <span>
        The webpage you are trying to access does not exist or has an incorrect
        link.
      </span>
    </login-base>
    <div v-else class="loading">
      <gp-loader />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LoginBase from '../components/LoginBase.vue';
import ConnectorButton from '../components/ConnectorButton.vue';

export default {
  name: 'AuthLogin',
  components: { LoginBase, ConnectorButton },
  computed: {
    ...mapState('auth', ['error', 'loginConfig']),
    ...mapState({
      LOGIN_MESSAGE: (state) => state.app.clientFlags.LOGIN_MESSAGE,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
    message() {
      const split = this.LOGIN_MESSAGE?.message?.split(/#([\w]+)/);
      return split;
    },
    messageLinks() {
      return this.LOGIN_MESSAGE?.links;
    },
    messagetype() {
      return this.LOGIN_MESSAGE?.type || 'info';
    },
  },
  data() {
    return {
      login: null,
      loaded: false,
    };
  },
  async mounted() {
    // The route does not call getOrRefreshToken because this is the login page
    // Call it and redirect to index if user is already logged in
    await this.$store.dispatch('auth/getOrRefreshToken');
    this.loaded = true;

    if (this.isLoggedIn) {
      this.$router.push({ name: 'initialize' });
      return;
    }
  },
  methods: {
    async doLogin(connector) {
      await this.$store.dispatch('auth/loginUser', { connector });
    },
    getCount(connectorButton) {
      return (
        this.loginConfig?.connections?.filter(
          ({ button }) => button === connectorButton
        ).length || 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  background-color: var(--color-structure-base);
  h3 {
    @include header3;
  }

  .loading {
    height: 100vh;
    margin: 0 auto;
    padding: spacing();
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
