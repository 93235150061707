import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

const FORMATS = {
  day: 'MMM D',
  week: 'll',
  month: 'MMM YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY',
};

const FORMATS_12HR = {
  datetime: 'MMM D, YYYY, h:mm:ss a',
  millisecond: 'h:mm:ss.SSS a',
  second: 'h:mm:ss a',
  minute: 'h:mm a',
  hour: 'hA',
  ...FORMATS,
};

const FORMATS_24HR = {
  datetime: 'MMM D, YYYY, HH:mm:ss',
  millisecond: 'HH:mm:ss.SSS',
  second: 'HH:mm:ss',
  minute: 'HH:mm',
  hour: 'HH:mm',
  ...FORMATS,
};

export const timeAdapter = (context) => ({
  formats: function () {
    return context.analog ? FORMATS_12HR : FORMATS_24HR;
  },

  parse: function (value) {
    if (!(value instanceof dayjs)) {
      value = dayjs(value).utc();
    }
    return value.isValid() ? value.valueOf() : null;
  },

  format: function (time, format) {
    return dayjs.utc(time).format(format);
  },

  add: function (time, amount, unit) {
    return dayjs(time).utc().add(amount, unit).valueOf();
  },

  diff: function (max, min, unit) {
    return dayjs(max).utc().diff(dayjs(min).utc(), unit);
  },

  startOf: function (time, unit, weekday) {
    time = dayjs(time).utc();
    if (unit === 'isoWeek') {
      weekday = Math.trunc(Math.min(Math.max(0, weekday), 6));
      return time.isoWeekday(weekday).startOf('day').valueOf();
    }
    return time.startOf(unit).valueOf();
  },

  endOf: function (time, unit) {
    return dayjs(time).utc().endOf(unit).valueOf();
  },
});
