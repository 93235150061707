<template>
  <DeleteBox
    class="mt-1"
    :text="text"
    loader-text="Deleting Analytic"
    :show-loader="showLoader"
  >
    <j-delete-button
      @click="submit()"
      style-type="danger"
      label="Delete Analytic"
    />
  </DeleteBox>
</template>

<script>
import DeleteBox from '@/components/DeleteBox.vue';
import { mapState } from 'vuex';

export default {
  name: 'AnalyticDelete',
  components: { DeleteBox },
  props: {
    analytic: {
      type: Object,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLoader: false,
    };
  },
  computed: {
    ...mapState({
      queryState: (state) => state.app.queryState,
    }),
    endpoint() {
      if (this.admin) {
        return 'admin/deleteAnalytic';
      }
      return 'primary/deleteAnalytic';
    },
    redirect() {
      if (this.admin) {
        return 'admin.analytics.index';
      }
      return 'analytics.index';
    },
    text() {
      if (this.admin) {
        return 'This will remove the analytic from all organizations. Are you sure you want to delete this analytic?';
      }
      return 'Are you sure you want to delete this analytic?';
    },
  },
  methods: {
    async submit() {
      this.showLoader = true;
      const analytic = await this.$store.dispatch(this.endpoint, {
        uuid: this.analytic.uuid,
      });
      this.showLoader = false;
      if (analytic) {
        this.$router.push({
          name: this.redirect,
          ...(this.queryState[this.redirect]
            ? this.queryState[this.redirect]
            : {}),
        });
      }
    },
  },
};
</script>
