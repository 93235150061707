<template>
  <div class="gp-content">
    <j-form-bar
      v-if="mode.create || action"
      :title="mode.create ? 'Create Action' : name"
      @back="routeTo()"
      @submit:created="routeTo('detail')"
      @cancel="routeTo('detail')"
      @edit="routeTo('edit')"
      @remove="confirmRemoveWithPlans(actionWithPlans)"
      :loading="loading"
      :can-write="canWrite"
      :can-remove="canAccessPlans"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" v-if="!loading" :action="action" />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      :blockers="selectedPlans"
      @confirm="remove()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRouteHelpers } from '@/composables/route-helpers';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'Action',
  setup() {
    const { showRemoveModal, toDelete, selectedPlans, confirmRemoveWithPlans } =
      useRemoveHelpers();
    const { mode } = useRouteHelpers();
    const { canWrite, canAccess } = useRBAC(RBAC_RESOURCE.ACTION_CONFIGS);
    return {
      showRemoveModal,
      toDelete,
      selectedPlans,
      confirmRemoveWithPlans,
      mode,
      canWrite,
      canAccess,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('primary', {
      action: (state) => state.actions.action,
      plans: (state) => state.plans.plans,
    }),
    name() {
      return this.action?.name;
    },
    actionWithPlans() {
      return {
        ...this.action,
        plans: this.plans?.filter(
          (p) => p.actionConfigs?.id === this.action.id
        ),
      };
    },
    canAccessPlans() {
      return this.canAccess(RBAC_RESOURCE.PLAN);
    },
  },
  methods: {
    routeTo(name) {
      const parent = 'actions.index';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: name ? { id: this.action?.id } : {},
      });
    },
    async remove() {
      this.loading = true;
      this.showRemoveModal = false;
      const response = await this.$store.dispatch(
        'primary/deleteActionConfigs',
        {
          id: this.action.id,
        }
      );
      if (response) {
        this.$router.push({ name: 'actions.index' });
      }
    },
  },
  async beforeMount() {
    if (!this.mode.create) {
      const { id } = this.$route.params;
      if (this.canAccessPlans) {
        await this.$store.dispatch('primary/listAllPlansMinimal');
      }
      await this.$store.dispatch('primary/getActionConfigs', {
        id,
      });
    }
    this.loading = false;
  },
};
</script>
