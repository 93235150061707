<template>
  <div class="p-2 scroll-vertical">
    <Card class="ulog-filter-details">
      <ListItem title="Name">
        {{ data.name }}
      </ListItem>

      <ListItem title="Enabled">
        <div class="g-cols max-c ai-center gap-1">
          <template v-if="data.enabled"
            ><j-icon
              data="@jcon/check-circle.svg"
              color="var(--color-success-base)"
            />
            Yes</template
          >
          <template v-else><j-icon data="@jcon/not-allowed.svg" />No</template>
        </div>
      </ListItem>

      <ListItem title="Created">
        {{ formatEpochDate(data.created) }}
      </ListItem>

      <ListItem title="Updated">
        {{ formatEpochDate(data.created) }}
      </ListItem>

      <ListItem title="Filter" class="col-span-1">
        <code>{{ data.filter }}</code>
      </ListItem>

      <ListItem title="Tags">
        <div class="tags">
          <j-tag v-for="tag in data.tags" :key="tag">{{ tag }}</j-tag>
        </div>
      </ListItem>
    </Card>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'UnifiedLoggingFilterDetails',
  components: {
    Card,
    ListItem,
  },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ulog-filter-details {
  @include grid;
  @include grid-rows;
  max-width: 600px;
  grid-template-columns: 1fr 1fr;
}
</style>
