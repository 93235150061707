<template>
  <div class="details g-col-2">
    <div class="col-span-1 g-rows">
      <div class="g-col-2">
        <ListItem title="Name">
          <router-link
            class="h4"
            :to="{ name: 'plans.index.detail', params: { id: plan.id } }"
            >{{ plan.name }}</router-link
          >
        </ListItem>
        <slot></slot>
      </div>
      <ListItem v-if="plan.description" title="Description">
        {{ plan.description }}
      </ListItem>
      <ListItem title="Hash">
        {{ plan.hash }}
      </ListItem>
      <hr class="m-0" />
    </div>
    <template v-for="value in keyValues(plan)" :key="value.label">
      <ListItem
        v-if="!excludedValues.includes(value.label)"
        :title="value.label"
      >
        <router-link v-if="value.link" :to="value.link">{{
          value.value
        }}</router-link>
        <template v-else-if="value.field === 'logLevel'">{{
          capitalize(value.value)
        }}</template>
        <template v-else>{{ value.value }}</template>
      </ListItem>
    </template>
    <template v-for="set in managedAnalyticSets">
      <!-- This feature is enabled only for uuid and is important for releasing managed analytic sets -->
      <ListItem
        v-if="isFeatureEnabled(set.uuid)"
        :key="set.uuid"
        :title="set.name"
        :data-feature-id="set.name"
        >{{ getManagedAnalyticSetState(set.uuid) }}</ListItem
      >
    </template>
    <ListItem v-if="hasTelemetry" title="Telemetry">
      <router-link
        v-if="plan.telemetry"
        :to="{
          name: 'telemetry.index.detail',
          params: { id: plan.telemetry.id },
        }"
        >{{ plan.telemetry.name }}</router-link
      >
    </ListItem>
    <ListItem
      v-if="hasUsbControl"
      title="Removable Storage Control Set"
      class="mb-0"
    >
      <router-link
        v-if="plan.usbControlSet"
        :to="{
          name: 'devicecontrols.index.detail',
          params: { id: plan.usbControlSet.id },
        }"
        >{{ plan.usbControlSet.name }}</router-link
      >
    </ListItem>
    <ListItem v-if="hasAnalyticSets" title="Analytic Sets" class="col-span-1">
      <div class="tags">
        <j-tag
          v-for="set in planComputed.analyticSets"
          :key="set.uuid"
          @click="
            $router.push({
              name: 'analytics.sets.detail',
              params: { uuid: set.uuid },
            })
          "
        >
          {{ set.name }}
        </j-tag>
      </div>
    </ListItem>
    <ListItem v-if="hasExceptionSets" title="Exception Sets" class="col-span-1">
      <div class="tags">
        <j-tag
          v-for="set in plan.exceptionSets"
          :key="set.uuid"
          @click="
            $router.push({
              name: 'exceptions.index.detail',
              params: { uuid: set.uuid },
            })
          "
        >
          {{ set.name }}
        </j-tag>
      </div>
    </ListItem>
  </div>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
import { secondsToString, capitalize } from '@/util';
import { mapGetters, mapState } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useGmt } from '@/composables/gmt';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'PlanDetailCard',
  components: { ListItem },
  props: ['plan', 'title'],
  setup() {
    const { cloudOnlyAccess, canAccess } = useRBAC([RBAC_RESOURCE.PLAN]);
    const { formatEpochDate } = useGmt();
    return { formatEpochDate, canAccess, cloudOnlyAccess, capitalize };
  },
  computed: {
    ...mapState({
      managedAnalyticSets: (state) =>
        state.primary.analyticSets.managedAnalyticSets,
    }),
    ...mapGetters(['isFeatureEnabled']),
    hasExceptionSets() {
      return this.canAccess(RBAC_RESOURCE.EXCEPTION_SET);
    },
    hasAnalyticSets() {
      return this.canAccess(RBAC_RESOURCE.ANALYTIC_SET);
    },
    planComputed() {
      let newPlan;
      if (
        this.plan.analyticSets &&
        !('managedAnalyticSets' in this.plan) &&
        this.hasAnalyticSets
      ) {
        newPlan = {
          ...this.plan,
          analyticSets: this.plan.analyticSets?.filter(
            ({ managed }) => !managed
          ),
          managedAnalyticSets: this.plan.analyticSets?.filter(
            ({ managed }) => managed
          ),
        };
      }
      return newPlan ?? this.plan;
    },
    excludedValues() {
      const exclude = ['Name', 'Description', 'Hash'];
      if (!this.cloudOnlyAccess) {
        return [
          ...exclude,
          'Insights',
          'Auto Update',
          'Agent Protocol',
          'Endpoint Threat Prevention',
        ];
      }
      return exclude;
    },
    hasTelemetry() {
      return this.canAccess(RBAC_RESOURCE.TELEMETRY);
    },
    hasUsbControl() {
      return this.canAccess(RBAC_RESOURCE.USB_CONTROLS);
    },
  },
  async mounted() {
    if (this.hasAnalyticSets && !this.managedAnalyticSets) {
      await this.$store.dispatch('primary/listManagedAnalyticSets', {
        excludeAnalytics: true,
      });
    }
  },
  methods: {
    getManagedAnalyticSetState(uuid) {
      return this.planComputed.managedAnalyticSets?.find(
        (set) => set.uuid === uuid
      )
        ? 'Enabled'
        : 'Disabled';
    },
    keyValues(plan) {
      let mode = 'Disabled';
      if (plan.signaturesFeedConfig?.mode === 'reportOnly')
        mode = 'Report Only';
      if (plan.signaturesFeedConfig?.mode === 'blocking')
        mode = 'Block & Report';

      const values = [
        {
          label: 'Name',
          field: 'name',
          value: plan.name,
          link: {
            name: 'plans.index.detail',
            params: { id: plan.id },
          },
        },
        {
          label: 'Description',
          field: 'description',
          value: plan.description,
        },
        {
          label: 'Created',
          field: 'created',
          value: this.formatEpochDate(plan.created),
        },
        {
          label: 'Modified',
          field: 'updated',
          value: this.formatEpochDate(plan.updated),
        },
        {
          label: 'Auto Update',
          field: 'autoUpdate',
          value: plan.autoUpdate === true ? 'Yes' : 'No',
          centered: true,
        },
        {
          label: 'Agent Protocol',
          field: 'commsConfig',
          value: plan.commsConfig?.protocol,
          centered: true,
        },
        {
          label: 'Endpoint Threat Prevention',
          field: 'signaturesFeedConfig',
          value: mode,
        },
        {
          label: 'Log Level',
          field: 'logLevel',
          value: !plan.logLevel ? 'Not Set' : plan.logLevel,
        },
        {
          label: this.isFeatureEnabled('COMPLIANCE_DASHBOARD')
            ? 'Compliance'
            : 'Insights',
          field: 'insightsSyncInterval',
          value: plan.infoSync?.attrs.includes('insights')
            ? 'Enabled'
            : 'Disabled',
        },

        {
          label: 'Hash',
          field: 'hash',
          value: plan.hash,
        },
      ];

      if (!this.isFeatureEnabled('COMPUTER_CONNECTION_STATUS')) {
        values.splice(6, 0, {
          label: 'Sync Interval',
          field: 'infoSyncInterval',
          value: secondsToString(plan.infoSync?.infoSyncInterval),
        });
      }

      if (plan.actionConfigs) {
        values.splice(10, 0, {
          label: 'Action',
          field: 'actionConfigs',
          value: plan.actionConfigs.name,
          link: {
            name: 'actions.index.detail',
            params: { id: plan.actionConfigs.id },
          },
        });
      }

      return values;
    },
  },
};
</script>

<style lang="scss" scoped>
.details h2 {
  @include header4;
  padding-bottom: spacing();
}

.card-container {
  @include grid;
}
</style>
