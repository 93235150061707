import { findIndex } from 'lodash';
import { getCurrentInstance } from 'vue';

export function useEvents(props) {
  const currentVueInstance = getCurrentInstance()?.proxy;

  const getEnumString = (label, value) => {
    const index = findIndex(currentVueInstance.def.fields, { label });
    if (index >= 0) {
      const enumIndex = findIndex(
        currentVueInstance.def.fields[index].options,
        { value }
      );
      if (enumIndex >= 0) {
        return currentVueInstance.def.fields[index].options[enumIndex].label;
      }
    }
    return `${value}`;
  };

  const relatedUser = (uid) => {
    const index = findIndex(props.related.users, { uid });
    if (index >= 0) {
      return props.related.users[index].name;
    }
    return `${uid}`;
  };

  const relatedGroup = (gid) => {
    const index = findIndex(props.related.groups, { gid });
    if (index >= 0) {
      return props.related.groups[index].name;
    }
    return `${gid}`;
  };

  const relatedProcess = (pid) => {
    const index = findIndex(props.related.processes, { pid });
    if (index >= 0) {
      return `${props.related.processes[index].name} (${props.related.processes[index].path})`;
    }
    return `${pid}`;
  };

  return { getEnumString, relatedUser, relatedGroup, relatedProcess };
}
