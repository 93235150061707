<template>
  <div :class="['alert-status', statusClass]">
    <span :class="['indicator', statusClass]"></span>
    {{ value }}
  </div>
</template>

<script>
import { ALERT_STATUS } from '@/util/constants/alert-status.types';

export default {
  name: 'StatusIndicator',
  props: {
    status: String,
  },
  computed: {
    value() {
      const unknown = 'Unknown';
      return this.status ? ALERT_STATUS[this.status] || unknown : unknown;
    },
    statusClass() {
      const key = Object.keys(ALERT_STATUS).find((val) => val === this.status);
      return key?.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-status {
  @include grid(spacing(1));
  @include grid-columns(max-content);
  align-items: center;

  // have to reset .progress styles that come with buefy
  border-radius: 0;
  overflow: unset;
  height: unset;

  .indicator {
    &.progress,
    &.new {
      @include dot;
    }
    &.new {
      background: var(--color-font-base);
      border-color: var(--color-font-base);
    }
    &.autoresolved,
    &.resolved {
      @include check($color: var(--color-font-base));
    }
  }

  &.autoresolved,
  &.resolved {
    opacity: 0.6;
  }
}
</style>
