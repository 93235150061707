<template>
  <div :class="classes"></div>
</template>

<script>
export default {
  name: 'ConnectionIndicator',
  props: {
    status: String,
  },
  computed: {
    classes() {
      const connectionStatus = this.status?.toLowerCase();
      return {
        status: true,
        [connectionStatus]: !!connectionStatus,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  @include dot(size-item(3));
  border-color: var(--color-border-secondary);
  border-width: 1px;
  background: radial-gradient(
    var(--color-structure-base) 50%,
    var(--color-structure-tertiary) 51%
  );
  display: flex;

  &.connected {
    background: var(--color-success-base);
  }

  &.overdue {
    background: var(--color-danger-base);
    align-items: center;
    justify-content: center;
    &::after {
      content: '';
      width: 2px;
      height: 50%;
      border-radius: 2px;
      background: var(--color-structure-base);
      display: block;
    }
  }
}
</style>
