<template>
  <div class="analytic-editor">
    <div class="predicate">Predicate: {{ expression }}</div>
    <QueryBuilderGroup
      :index="0"
      :depth="1"
      :field-prepend="''"
      :def="def"
      v-model:expression="expression"
    />
  </div>
</template>

<script>
import QueryBuilderGroup from '@/components/analytics/editor/QueryBuilderGroup.vue';

export default {
  name: 'QueryBuilder',
  components: {
    QueryBuilderGroup,
  },
  props: {
    def: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      requred: true,
    },
  },
  emits: ['update:filter'],
  data() {
    return {
      expression: '',
      labels: {
        matchType: '',
        matchTypeAll: 'All of the following are true',
        matchTypeAny: 'One or more of the following are true',
        addAnalytic: 'Add Condition',
        removeAnalytic: '&times;',
        addGroup: 'Add Group',
        removeGroup: '&times;',
        textInputPlaceholder: 'value',
      },
    };
  },
  watch: {
    expression() {
      this.$emit('update:filter', this.expression);
    },
  },
};
</script>

<style lang="scss">
.predicate {
  background: var(--color-structure-base);
  color: var(--color-font-base);
  overflow-wrap: anywhere;
  padding: spacing(2);
}
</style>
