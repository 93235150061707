<template>
  <div class="gp-content" role="tabpanel">
    <div class="g-rows max-c p-2 scroll-vertical">
      <div
        id="compliance"
        class="g-rows max-c"
        v-if="canAccess(RBAC_RESOURCE.INSIGHT)"
      >
        <h1 class="h4">Apple Protections Status</h1>

        <transition-group name="fade" class="f-wrap min-height-150" tag="span">
          <component
            :is="
              isFeatureEnabled('COMPLIANCE_DASHBOARD')
                ? 'ComplianceCard'
                : 'InsightCard'
            "
            v-for="{
              uuid,
              label,
              totalFail,
              totalNone,
              totalPass,
              description,
              enabled,
              tags,
            } in compliance"
            :key="uuid"
            :label="label"
            :route="{
              name: isFeatureEnabled('COMPLIANCE_DASHBOARD')
                ? 'compliance.index.detail'
                : 'insights.index.detail',
              params: { id: uuid },
            }"
            :is-enabled="enabled"
            :tags="tags"
            :fail="totalFail"
            :pass="totalPass"
            :none="totalNone"
            :description="description"
          />
        </transition-group>
      </div>
      <div
        id="catalina"
        class="g-rows max-c min-height-150"
        v-if="canAccessAlertAnalytic"
      >
        <h2 class="h4">macOS 10.15+ Detections</h2>
        <div v-for="(item, key) in catalinaRows" :key="key" class="f-col-3">
          <HeroNumber
            @click="routeTo(item.severity, item.name)"
            :class="{ danger: item.detectionCount > 0 }"
            :value="item.detectionCount"
            :label="`${item.name} detections in the past ${timeInterval}`"
            :icon="pulse"
          />

          <HeroNumber
            v-if="canAccessComputer"
            @click="routeTo(item.severity, item.name)"
            :value="item.computerCount"
            :label="`Computers with ${item.name} detections`"
            :icon="laptop"
          />

          <Card class="g-rows gap-1 max-c">
            <h1 class="h5">{{ item.name }} Detections Details</h1>
            <transition-group name="fade" class="tags" tag="span">
              <template
                v-for="({ count, value }, index) in item.tags"
                :key="index"
              >
                <j-tag style-type="inverse" :count="count">{{
                  value || 'unknown'
                }}</j-tag>
              </template>
            </transition-group>
          </Card>
        </div>
      </div>
      <div
        id="preCatalina"
        class="g-rows min-height-150"
        v-if="canAccessAlertAnalytic && !isFeatureEnabled('DEPRECATE_MACOS')"
      >
        <h3 class="h4">macOS 10.12-10.14 Detections</h3>
        <div class="f-col-3">
          <HeroNumber
            @click="routeTo(preCatalinaRows.severity, preCatalinaRows.name)"
            :class="{ danger: preCatalinaRows.detectionCount > 0 }"
            :value="preCatalinaRows.detectionCount"
            :label="`${preCatalinaRows.name} detections in the past ${timeInterval}`"
            :icon="pulse"
          />

          <HeroNumber
            v-if="canAccessComputer"
            @click="routeTo(item.severity, item.name)"
            :value="preCatalinaRows.computerCount"
            :label="`Computers with ${preCatalinaRows.name} detections`"
          />

          <Card class="g-rows gap-1 max-c">
            <h1 class="h5">{{ preCatalinaRows.name }} Detections Details</h1>
            <div class="tags">
              <template
                v-for="({ count, value }, index) in preCatalinaRows.tags"
                :key="index"
              >
                <j-tag style-type="inverse" :count="count">{{
                  value || 'unknown'
                }}</j-tag>
              </template>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InsightCard from '@/modules/insights/components/InsightCard';
import ComplianceCard from '@/modules/compliance/components/ComplianceCard';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import { SEVERITY } from '@/util/constants/severity.types';
import HeroNumber from '@/components/HeroNumber.vue';
import Card from '@/components/Card.vue';
import pulse from '@jcon/pulse.svg';
import laptop from '@jcon/laptop.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'DashBoardApple',
  setup() {
    const { canAccess } = useRBAC();

    return { canAccess };
  },
  components: {
    InsightCard,
    ComplianceCard,
    HeroNumber,
    Card,
  },
  data() {
    return {
      stats: null,
      computersCount: null,
      compliance: [],
      RBAC_RESOURCE,
      timeInterval: '7 days',
      loaded: false,
      pulse,
      laptop,
      catalinaRows: {
        catalina0: {
          // TBD -> Gatekeeper Blocked Revoked
          severity: SEVERITY.Informational,
          name: '',
          tags: null,
          featureID: 'catalinaDetection-0',
        },
        catalina1: {
          // TBD -> Gatekeeper Blocked Unsigned/Invalid/Unknown
          severity: SEVERITY.Informational,
          name: '',
          tags: null,
          featureID: 'catalinaDetection-1',
        },
        catalina2: {
          // TBD -> Gatekeeper Blocked Signed
          severity: null,
          name: '',
          tags: null,
          featureID: 'catalinaDetection-2',
        },
        catalina3: {
          // TBD -> MRT Analytic
          severity: SEVERITY.Informational,
          name: '',
          tags: null,
          featureID: 'catalinaDetection-3',
        },
        catalina4: {
          // XProtect Analytic
          severity: SEVERITY.Informational,
          name: '',
          tags: null,
          featureID: 'catalinaDetection-4',
        },
      },
      preCatalinaRows: {},
    };
  },
  computed: {
    canAccessComputer() {
      return this.canAccess(RBAC_RESOURCE.COMPUTER);
    },
    canAccessAlertAnalytic() {
      return this.canAccess([RBAC_RESOURCE.ANALYTIC, RBAC_RESOURCE.ALERT]);
    },
    ...mapGetters(['isFeatureEnabled']),
  },
  methods: {
    routeTo(severity, name) {
      if (name && severity) {
        this.$router.push({
          name: 'alerts.index',
          query: {
            column: 'created|severity|analytics',
            type: `${FILTER_TYPES.AFTER_INTERVAL}|${FILTER_TYPES.NOT_EQUAL}|${FILTER_TYPES.ARRAY_INCLUDES}`,
            value: `${this.timeInterval}|${severity}|${name}`,
            filterType: 'date|int|array',
          },
        });
      } else if (!severity && name) {
        this.$router.push({
          name: 'alerts.index',
          query: {
            column: 'created|analytics',
            type: `${FILTER_TYPES.AFTER_INTERVAL}|${FILTER_TYPES.ARRAY_INCLUDES}`,
            value: `${this.timeInterval}|${name}`,
            filterType: 'date|array',
          },
        });
      }
    },
    async getCompliance() {
      const benchmarks = [
        '8554fc5f-624a-4dbf-89b9-625c0ea3b508', // XProtect
        '7e52fd09-78bb-454b-9eb7-bb64848c77ff', // MRT
        '53e37bdb-3436-4dc8-98a1-6c8dfe8f85a0', // Gatekeeper
      ];

      const response = await this.$store.dispatch('primary/listInsights');
      this.compliance = benchmarks
        .map((id) => response.filter(({ uuid }) => uuid === id))
        .flat();
    },
    async getCatalina() {
      const catalinaDetections = await this.$store.dispatch(
        'primary/getAppleRows'
      );

      const catalinaNames = {};
      Object.entries(catalinaDetections).forEach(([key, { name }]) => {
        catalinaNames[`${key}_name`] = name;
        this.catalinaRows[key].name = name;
      });

      const { computerCount, detectionCount } = await this.$store.dispatch(
        'primary/getAppleAnalytics',
        {
          ...catalinaNames,
          timeInterval: this.timeInterval,
        }
      );

      if (this.canAccessComputer) {
        Object.entries(computerCount).forEach(([key, { alertsComputers }]) => {
          this.catalinaRows[key]['computerCount'] = alertsComputers;
        });
      }

      this.loaded = true;

      /** each catAlerts can return multiple counts unrelated to the one we want,
       * so need to iterate through them and make sure we are getting the accurate count
       */
      Object.entries(this.catalinaRows).forEach(([key, { name }]) => {
        Object.values(detectionCount).forEach((items) => {
          const [item] = detectionCount[key].filter(
            ({ value }) => value === name
          );
          if (item) {
            this.catalinaRows[key]['detectionCount'] = item.count;
          } else if (!items.length) {
            this.catalinaRows[key]['detectionCount'] = 0;
          }
        });
      });

      this.preCatalinaRows = this.catalinaRows.catalina4;
      delete this.catalinaRows.catalina4;

      const { catalinaAlertsValues } = await this.$store.dispatch(
        'primary/getAppleContextValues',
        {
          timeInterval: this.timeInterval,
          ...catalinaNames,
        }
      );

      const tags = Object.entries(catalinaAlertsValues);
      this.preCatalinaRows.tags = tags.pop()[1];

      tags.forEach(([key, value]) => {
        this.catalinaRows[key].tags = value;
      });
    },
  },
  async mounted() {
    if (this.canAccess(RBAC_RESOURCE.INSIGHT)) {
      await this.getCompliance();
    }

    if (this.canAccessAlertAnalytic) {
      await this.getCatalina();
    }
  },
};
</script>

<style lang="scss" scoped>
.min-height-150 {
  min-height: 150px;
}
</style>
