import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import gql from 'graphql-tag';

export const AlertFields = (getPermissions) => {
  const hasComputers = getPermissions([RBAC_RESOURCE.COMPUTER]);
  const hasPlan = getPermissions([RBAC_RESOURCE.PLAN]);

  const plan = `plan {
    uuid
    name
  }`;

  const computer = `computer {
    uuid
    hostName
    modelName
  }`;

  return gql`
    fragment AlertFields on Alert {
      uuid
      json
      created
      status
      severity
      actions
      tags
      eventType
      ${hasPlan ? plan : ''}
      ${hasComputers ? computer : ''}
    }
  `;
};
