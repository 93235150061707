import Store from '../store/store';

const setChecks = async () => {
  if (Store.state.app.hasAppInfo) {
    const now = new Date() / 1000;
    if (now > Store.state.user.checkPermissions) {
      await Store.dispatch('user/getCurrentPermissions');
    }
    if (now > Store.state.app.checkConfigFreeze) {
      await Store.dispatch('getConfigFreeze');
    }
  }
};

const AuthGuard = async (to, from, next) => {
  // Initialize auth0 client if none exists
  if (!Store.state.auth.hasAuthClient) {
    await Store.dispatch('auth/initializeAuthClient');
  }

  // If we are on the login page, don't do the auth checks below
  if (['login', 'callback', 'redirect'].includes(to.name)) {
    next();
    return;
  }

  // This function will return a token if a valid session is found
  // If not, go back to login page
  const session = await Store.dispatch('auth/getOrRefreshToken');

  if (session) {
    if (
      from.name === 'initialize' ||
      to.name === 'initialize' ||
      Store.state.app.hasAppInfo
    ) {
      await setChecks();
      next();
      return;
    }
    Store.commit('SET_INITIAL_ROUTE', to);
    next({ name: 'initialize' });
    return;
  }
  next({ name: 'login' });
};

export default AuthGuard;
