import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import UnifiedLoggingList from './UnifiedLoggingList.vue';
import UnifiedLoggingFilter from './UnifiedLoggingFilter.vue';
import UnifiedLoggingFilterForm from './UnifiedLoggingFilterForm.vue';
import UnifiedLoggingFilterDetails from './UnifiedLoggingFilterDetails.vue';

export default [
  {
    path: 'unified-logging',
    component: Section,
    name: 'unifiedLogging',
    meta: {
      breadcrumb: {
        linkName: 'unifiedLogging.index',
        linkText: 'Unified Logging',
      },
      permissions: [RBAC_RESOURCE.UNIFIED_LOGGING_FILTER],
    },
    children: [
      {
        path: '',
        component: UnifiedLoggingList,
        name: 'unifiedLogging.index',
      },
      {
        path: '',
        component: UnifiedLoggingFilter,
        name: 'unifiedLogging.update',
        children: [
          {
            path: 'create',
            name: 'unifiedLogging.index.create',
            component: UnifiedLoggingFilterForm,
            meta: {
              breadcrumb: {
                linkText: 'Create',
              },
              requireWrite: true,
            },
          },
        ],
      },
      {
        path: ':id',
        component: UnifiedLoggingFilter,
        meta: {
          breadcrumb: {
            linkName: 'unifiedLogging.index.detail',
            linkText: 'Filter',
          },
        },
        children: [
          {
            path: '',
            name: 'unifiedLogging.index.detail',
            component: UnifiedLoggingFilterDetails,
          },
          {
            path: 'edit',
            name: 'unifiedLogging.index.edit',
            component: UnifiedLoggingFilterForm,
            props: true,
            meta: {
              breadcrumb: {
                linkText: 'Edit',
              },
              requireWrite: true,
            },
          },
        ],
      },
    ],
  },
];
