import {
  listPlans as LIST_PLANS,
  getPlan as GET_PLAN,
  getPlansConfigProfile as GET_PLANS_CONFIG_PROFILE,
  getPlanConfigurationAndSetOptions as GET_PLAN_CONFIGURATION_AND_SET_OPTIONS,
} from './plans.queries.gql';

import { CREATE_PLAN, UPDATE_PLAN, DELETE_PLAN } from './plans.mutations';

export default {
  state: {
    plan: {},
    plans: null,
    plansNames: [],
  },
  mutations: {
    SET_PLAN(state, payload) {
      if (typeof payload.infoSync === 'string') {
        // eslint-disable-next-line no-param-reassign
        payload.infoSync = JSON.parse(payload.infoSync);
      }
      state.plan = {
        ...state.plan,
        ...payload,
        analyticSets: payload.analyticSets?.filter(({ managed }) => !managed),
        managedAnalyticSets: payload.analyticSets?.filter(
          ({ managed }) => managed
        ),
      };
    },
    SET_PLANS(state, payload) {
      state.plans = payload.items;
      state.plansNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    // queries
    async getPlan({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_PLAN,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PLAN', result.data.getPlan);
        return result.data.getPlan;
      }
      return result;
    },
    async getPlanConfigurationAndSetOptions({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_PLAN_CONFIGURATION_AND_SET_OPTIONS,
          variables: payload,
        },
        { root: true }
      );
      return result?.data;
    },
    async listPlans({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_PLANS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        result.data.listPlans.items.forEach((p) => {
          if (typeof p.infoSync === 'string') {
            // eslint-disable-next-line no-param-reassign
            p.infoSync = JSON.parse(p.infoSync);
          }
        });
        commit('SET_PLANS', result.data.listPlans);
        return result.data.listPlans;
      }
      return result;
    },
    async getPlansConfigProfile({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_PLANS_CONFIG_PROFILE,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.getPlansConfigProfile;
      }
      return result;
    },
    async createPlan({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_PLAN,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PLAN', result.data.createPlan);
        return result.data.createPlan;
      }
      return result;
    },
    async updatePlan({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_PLAN,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PLAN', result.data.updatePlan);
        return result.data.updatePlan;
      }
      return result;
    },
    async deletePlan({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_PLAN,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_PLAN', result.data.deletePlan);
        return result.data.deletePlan;
      }
      return result;
    },
  },
};
