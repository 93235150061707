import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Section from '@/components/Section.vue';
import Alerts from './Alerts.vue';
import AlertDetails from './components/AlertDetails.vue';
import AlertJson from './components/AlertJson.vue';
import AlertRelatedBinaries from './components/AlertRelatedBinaries.vue';
import AlertRelatedFiles from './components/AlertRelatedFiles.vue';
import AlertRelatedGroups from './components/AlertRelatedGroups.vue';
import AlertRelatedUsers from './components/AlertRelatedUsers.vue';
import AlertRelatedProcesses from './components/AlertRelatedProcesses.vue';
import AlertExceptions from './components/AlertExceptions.vue';
import Alert from './Alert.vue';

export default [
  {
    path: '/alerts',
    component: Section,
    name: 'alerts',
    props: {
      rbac: { permissions: [RBAC_RESOURCE.ALERT, RBAC_RESOURCE.COMPUTER] },
    },
    meta: {
      breadcrumb: {
        linkName: 'alerts.index',
        linkText: 'Alerts',
      },
    },
    children: [
      {
        path: '',
        component: Alerts,
        name: 'alerts.index',
        props: true,
        children: [
          {
            path: ':uuid',
            name: 'alert',
            component: Alert,
            meta: {
              breadcrumb: {
                linkText: 'Alert',
              },
            },
            children: [
              {
                path: '',
                props: true,
                component: AlertDetails,
                name: 'alerts.item',
              },
              {
                path: 'processes',
                props: true,
                component: AlertRelatedProcesses,
                name: 'alerts.item.processes',
              },
              {
                path: 'files',
                props: true,
                component: AlertRelatedFiles,
                name: 'alerts.item.files',
              },
              {
                path: 'binaries',
                props: true,
                component: AlertRelatedBinaries,
                name: 'alerts.item.binaries',
              },
              {
                path: 'users',
                props: true,
                component: AlertRelatedUsers,
                name: 'alerts.item.users',
              },
              {
                path: 'groups',
                props: true,
                component: AlertRelatedGroups,
                name: 'alerts.item.groups',
              },
              {
                path: 'json',
                props: true,
                component: AlertJson,
                name: 'alerts.item.json',
              },
              {
                path: 'exceptions',
                props: true,
                component: AlertExceptions,
                name: 'alerts.item.exceptions',
                meta: {
                  requireWrite: true,
                  permissions: [RBAC_RESOURCE.EXCEPTION_SET],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
