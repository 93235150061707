const state = {
  namespace: 'https://protect.jamfcloud.com',
  error: null,
  token: null,
  expiresAt: null,
  loginConfig: {},
  hasLoginConfig: false,
  hasAuthClient: false,
};

export default state;
