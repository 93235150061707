import { getCurrentHub, startTransaction } from '@sentry/vue';
/**
 * @description Checks if a transaction is currently in scope and creates a new one if not.
 * @param {object} options Sentry Transaction options
 * @param {boolean} alwaysStart if this should always start a new transaction or use a current one
 * @param {Transaction} provideTransaction optionally provide a Transaction if one is already available.
 * @return {object} with Span and Transaction
 */
export const sentrySetupTransactionAndSpan = (
  options,
  alwaysStart,
  provideTransaction
) => {
  let transaction =
    provideTransaction || getCurrentHub().getScope().getTransaction();

  if (!transaction || alwaysStart === true) {
    transaction = startTransaction(options);
    getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
  }
  return { transaction, span: transaction?.startChild(options) };
};

class SentryGraphqlTransactionManager {
  transaction = null;
  span = null;
  isGraphql = false;

  setupTransactionAndSpan(name, op = 'mutation', alwaysStart = true) {
    const tags = { graphql: op };
    const transactionOp = 'graphql';
    const currentTransaction = getCurrentHub()?.getScope()?.getTransaction();
    const { transaction, span } = sentrySetupTransactionAndSpan(
      {
        name,
        op: `${transactionOp}.${op}`,
        tags,
        description: name,
      },
      alwaysStart || currentTransaction?.op?.includes(transactionOp),
      currentTransaction
    );
    this.isGraphql = transaction?.op?.includes(transactionOp);
    this.span = span;
    this.transaction = transaction;
    return {
      transaction: this.transaction,
      span: this.span,
      isGraphql: this.isGraphql,
    };
  }

  finishTransactionAndSpan(data) {
    // add list total information if available
    if (data?.pageInfo?.total != null) {
      this.transaction?.setTag(
        'resource.total',
        data.pageInfo.total || 'unknown'
      );

      const groups = {
        large: data.pageInfo.total >= 15000,
        medium: data.pageInfo.total >= 2000 && data.pageInfo.total < 15000,
        small: data.pageInfo.total < 2000,
      };
      this.transaction?.setTag(
        'resource.group',
        Object.entries(groups).find(([, value]) => value)[0]
      );
    }
    this.span?.finish();

    if (this.isGraphql) {
      this.transaction?.finish();
    }
  }
}

export default new SentryGraphqlTransactionManager();
