<template>
  <div class="p-2 croll-vertical">
    <div class="g-rows">
      <j-switch
        :is-read-only="isReadOnly"
        :is-disabled="loading"
        v-model="toggleFreeze"
        data-feature-id="freezeControl"
        @change="showWarningModal"
      >
        <span class="subtitle-text">Enable Freeze</span>
        <p class="helper-text">
          When enabled, updates to the Jamf Protect agent will be unavailable
          until an administrator account disables the freeze. Users will be
          restricted to read-only access with limited exceptions.
        </p>
      </j-switch>
      <j-modal v-model="showWarning" type="warning" :close-on-overlay="false">
        <p>
          {{ message }}
        </p>
        <template #header="{ alignClass, iconClass }">
          <section :class="alignClass">
            <j-icon data="@jcon/warning.svg" :class="iconClass" />
            <h1 class="h3">Are You Sure?</h1>
          </section>
        </template>
        <template #actions>
          <j-button style-type="secondary" @click="cancel"> Cancel </j-button>
          <j-button @click="updateConfigFreeze" data-feature-id="enable-freeze">
            {{ confirmButton }}
          </j-button>
        </template>
      </j-modal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'AccountChangeManagement',
  setup() {
    const { isReadOnly } = useRBAC(RBAC_RESOURCE.CONFIG_FREEZE);
    return { isReadOnly };
  },
  data() {
    return {
      toggleFreeze: false,
      loading: false,
      showWarning: false,
      confirmButton: null,
      message: null,
    };
  },
  computed: {
    ...mapState({
      configFreeze: (state) => state.app.info.configFreeze,
    }),
  },
  async mounted() {
    await this.$store.dispatch('getConfigFreeze');
    this.toggleFreeze = this.configFreeze;
  },
  methods: {
    async updateConfigFreeze() {
      this.showWarning = false;
      this.loading = true;
      await this.$store.dispatch('primary/updateOrganizationConfigFreeze', {
        configFreeze: this.toggleFreeze,
      });
      // ensure current users permissions are updated
      await this.$store.dispatch('user/getCurrentPermissions');
      this.loading = false;
    },
    async showWarningModal() {
      this.message = this.toggleFreeze
        ? `Updates to the Jamf Protect agent will be
          unavailable until an administrator account disables the freeze. Users
          will be restricted to read-only access with limited exceptions.`
        : `User access will no longer be restricted. 
          Any automatic updates to the Jamf Protect agent affected by the freeze will resume, 
          and updates to Jamf Protect analytics will be applied.`;
      this.confirmButton = this.toggleFreeze ? 'Enable' : 'Disable';
      this.showWarning = true;
    },
    async cancel() {
      this.toggleFreeze = this.configFreeze;
      this.showWarning = false;
    },
  },
};
</script>
