<template>
  <div class="gp-content">
    <div class="p-2 g-rows max-c scroll-vertical">
      <j-input
        v-model="name"
        label="Name"
        data-feature-id="custom-prevent-name"
        :has-error="v$.name.$error"
        :error-text="nameErrorMessage"
        descender-text="Required"
      />
      <j-textarea
        v-model="description"
        data-feature-id="description"
        :rows="3"
        label="Description"
      />
      <div>
        <h2 class="h5">Prevent Type</h2>
        <p class="helper-text">
          For file hashes, include the SHA-1 or SHA-256 hash of the executing
          binary file in the list data.
        </p>
        <p class="helper-text mb-1">
          Signing information analyzes the signature information of the
          executing binary. Select a prevent type and include the values in the
          list data.
        </p>
        <j-toggle-group>
          <j-radio
            v-for="option in listTypeOptions"
            :key="option"
            v-model="listType"
            :native-value="option"
            name="list-type-options"
          >
            {{ listTypeEnumMap[option] }}
          </j-radio>
        </j-toggle-group>
        <div class="g-rows signing-info" v-if="listType !== 'FILEHASH'">
          <j-radio
            v-for="option in listSubTypeOptions"
            :key="option"
            :native-value="option"
            v-model="listSubType"
            class="m-0"
            name="list-type-sub-options"
            data-feature-id="signing-information"
          >
            <div class="g-rows gap-none">
              {{ listTypeEnumMap[option] }}
              <small v-if="listType !== 'FILEHASH'" class="helper-text">
                {{ subTypeMetaData[option] }}
              </small>
            </div>
          </j-radio>
        </div>
      </div>
      <j-input-group
        label="List Data"
        helper-text="Lists are limited to 100K items."
      >
        <j-toggle-group>
          <j-radio
            v-for="option in inputTypeOptions"
            :key="option"
            v-model="inputType"
            :native-value="option"
            name="input-type-options"
          >
            {{ option }}
          </j-radio>
        </j-toggle-group>
        <div v-if="inputType === plainText" class="g-rows gap-0">
          <j-textarea
            v-model="multipleValues"
            data-feature-id="plain-text"
            :has-error="v$.list.$error"
            :error-text="
              v$.list.required.$invalid
                ? 'List Data is required'
                : 'List Data cannot contain blank entries'
            "
          />
          <p class="helper-text js-end">{{ listMetaData }}</p>
        </div>

        <div v-else class="g-rows">
          <Upload v-model="dropFile" />
          <span v-if="dropFile">
            <j-icon data="@jcon/file-table.svg" height="16" />
            {{ dropFile.name }}
            <p class="helper-text">{{ listMetaData }}</p>
          </span>
        </div>
      </j-input-group>
    </div>
  </div>
</template>

<script>
import { required, helpers } from '@vuelidate/validators';
import { useForm } from '@/composables/forms';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapState } from 'vuex';
import { isUniqueName } from '@/util/custom-validators';
import Upload from '@/components/Upload.vue';

export default {
  name: 'PreventListForm',
  components: { Upload },
  props: {
    preventList: {
      type: Object,
    },
  },
  setup() {
    const { v$, handleSubmit, mode } = useForm();
    const { canWrite } = useRBAC([RBAC_RESOURCE.PREVENT_LIST]);

    return { v$, handleSubmit, canWrite, mode };
  },
  validations() {
    return {
      name: {
        required,
        isUniqueName: isUniqueName(this.currentName),
      },
      list: {
        required,
        requireEach: (value) => {
          return value ? value.every((val) => helpers.req(val.trim())) : true;
        },
      },
    };
  },
  data() {
    const plainText = 'Plain Text';
    // backend has only 4 types, but we want to displayed them in different groups
    const typeValues = {
      FILEHASH: 'FILEHASH',
      TEAMID: 'PLACEHOLDER',
      CDHASH: 'PLACEHOLDER',
      SIGNINGID: 'PLACEHOLDER',
    };
    return {
      name: this.preventList.name,
      description: this.preventList.description,
      plainText,
      dropFile: null,
      inputTypeOptions: [plainText, 'File Upload'],
      inputType: plainText,
      data: [],
      listType: typeValues[this.preventList.type],
      listTypeOptions: ['FILEHASH', 'PLACEHOLDER'],
      listSubType:
        this.preventList.type === 'FILEHASH' ? 'CDHASH' : this.preventList.type,
      listSubTypeOptions: ['TEAMID', 'CDHASH', 'SIGNINGID'],
      listTypeEnumMap: {
        PLACEHOLDER: 'Signing Information',
        FILEHASH: 'File Hash',
        TEAMID: 'Team ID',
        CDHASH: 'Code Directory Hash',
        SIGNINGID: 'Signing ID',
      },
      multipleValues: this.preventList.list.join('\n'),
      validMessage: null,
      sizes: [32, 40, 64],
      subTypeMetaData: {
        TEAMID:
          'The alphanumeric ID of the developer signing certificate issued by Apple.',
        CDHASH: "The SHA-1 of the executing binary's code section.",
        SIGNINGID:
          'The application identifier in the signing information. (e.g. com.apple.calculator)',
      },
      id: null,
    };
  },
  computed: {
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) => state.threatprevent.threatPreventsNames,
      currentName: (state) => state.threatprevent.threatprevent?.name,
    }),
    listMetaData() {
      return this.inputType === this.plainText
        ? `${this.list.length}/100000`
        : `Found ${this.list.length} items`;
    },
    nameErrorMessage() {
      return this.v$.name.$errors[0]?.$message;
    },
    listTypeValue() {
      if (this.listType === 'PLACEHOLDER') {
        return this.listSubType;
      }
      return this.listType;
    },
    payload() {
      return {
        ...this.preventList,
        type: this.listTypeValue,
        name: this.name,
        tags: [], // currently not used for anything, but required by backend
        list: this.list,
        description: this.description,
      };
    },
    list() {
      const values = [];
      switch (this.inputType) {
        case 'File Upload':
          this.data.forEach((d) => {
            if (this.sizes.includes(d[0].length)) {
              values.push(d[0]);
            }
          });
          return values;
        case this.plainText:
          if (this.multipleValues.length > 0) {
            return this.multipleValues.split('\n');
          }
          return values;
        default:
          return values;
      }
    },
  },
  watch: {
    dropFile() {
      if (this.dropFile) {
        // eslint-disable-next-line
        const _vm = this;
        this.$papa.parse(this.dropFile, {
          complete(results) {
            _vm.data = results.data;
          },
          error(errors) {
            // eslint-disable-next-line
            console.log(errors);
          },
        });
      }
    },
  },
  methods: {
    async submit() {
      const dispatchMethod = this.mode.create
        ? 'createPreventList'
        : 'updatePreventList';
      const response = await this.$store.dispatch(
        `primary/${dispatchMethod}`,
        this.payload
      );
      if (response) {
        this.id = response.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signing-info {
  margin: spacing();
  .radio + .radio {
    margin: 0;
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
