import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { EVENT_TYPES_NAMES_ALL } from '@/util/constants/event.types';

export const defaultDateColumn = {
  minWidth: 160,
  maxWidth: 160,
  cellStyle: { 'white-space': 'normal' },
  cellRenderer: 'TableCellDate',
};

export const restrictedColumn = {
  sortable: false,
  suppressMenu: true,
  resizable: false,
  suppressExport: true,
};

export const associatedPlansColumn = {
  headerName: 'Associated Plans',
  field: 'plans',
  permissions: RBAC_RESOURCE.PLAN,
  valueGetter: (params) =>
    params?.data?.plans?.map(({ name, id }) => ({ value: name, id })),
  cellRendererParams: {
    route: (tag) => ({
      name: 'plans.index.detail',
      params: { id: tag.id },
    }),
  },
  cellRenderer: 'TableCellTags',
};

const selectColumn = {
  ...restrictedColumn,
  checkboxSelection: true,
  colId: 'checkbox',
  minWidth: 50,
  maxWidth: 50,
  width: 50,
  cellClass: ['checkbox-column'],
  headerClass: 'checkbox-column',
};

export default {
  groupControlColumn: {
    ...restrictedColumn,
    pinned: 'left',
    colId: 'group',
    width: 57,
    minWidth: 57,
    maxWidth: 57,
    suppressMovable: true,
    headerComponent: 'TableGroupControlHeader',
    cellClass: 'group-control-column',
    headerClass: 'group-control-column',
    headerTooltip: 'Click to expand or collapse all groups.',
  },
  buttonColumn: {
    ...restrictedColumn,
    cellClass: 'no-cell-focus',
    cellRenderer: 'TableCellButton',
  },
  linkColumn: {
    cellClass: 'column-link',
    cellRenderer: 'TableCellLink',
  },
  dateColumn: {
    ...defaultDateColumn,
  },
  dateFilterColumn: {
    ...defaultDateColumn,
    filter: 'TableFilterDate',
    filterParams: {
      suppressAndOrCondition: true,
    },
  },
  textFilterColumn: {
    filter: 'TableFilterText',
  },
  numberFilterColumn: {
    filter: 'TableFilterInt',
  },
  tagsColumn: {
    cellRenderer: 'TableCellTags',
  },
  iconColumn: {
    minWidth: 120,
    cellClass: ['overflow-cell'],
    cellRenderer: 'TableCellIcon',
  },
  codeColumn: {
    minWidth: 400,
    cellRenderer: 'TableCellCode',
  },
  arrayFilterColumn: {
    filter: 'TableFilterArray',
    filterParams: {
      suppressTypeOptions: true,
    },
  },
  hiddenColumn: {
    hide: true,
    suppressColumnsToolPanel: true,
    suppressNavigable: true,
  },
  selectColumn,
  selectAllColumn: {
    ...selectColumn,
    headerComponent: 'TableCheckboxHeader',
  },
  optionsColumn: {
    cellClass: ['overflow-cell'],
    cellRenderer: 'TableCellOptions',
  },
  valueOptionsFilterColumn: {
    filter: 'TableFilterValueOptions',
  },
  managedColumn: {
    headerName: 'Owner',
    minWidth: 80,
    maxWidth: 100,
    tooltipField: 'managed',
    cellRenderer: 'TableCellManaged',
    // used for tooltip
    valueFormatter: (params) => (params.value ? 'Managed by Jamf' : 'Custom'),
  },
  severityColumn: {
    headerName: 'Severity',
    field: 'severity',
    width: 90,
    sortable: true,
    maxWidth: 130,
    filter: 'TableFilterValueOptions',
    tooltipField: 'severity',
    filterParams: {
      isSeverity: true,
      defaultValue: 'High',
    },
    cellRenderer: 'TableCellSeverity',
  },
  eventTypeColumn: {
    headerName: 'Event',
    field: 'eventType',
    tooltipField: 'eventType',
    valueFormatter: (params) =>
      EVENT_TYPES_NAMES_ALL[params.value] || params.value,
    filter: 'TableFilterValueOptions',
    cellRenderer: 'TableCellEvent',
    filterParams: {
      valueOptions: Object.entries(EVENT_TYPES_NAMES_ALL).map(
        ([value, label]) => ({
          label,
          value,
        })
      ),
    },
  },
};
