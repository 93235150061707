<template>
  <div class="gp-content" role="tabpanel">
    <div class="f-rows p-2 scroll-vertical">
      <div class="f-wrap mb-0">
        <div class="f-rows mb-0 versions-container">
          <h1 class="subtitle-text">Version Status</h1>
          <Card class="versions">
            <div class="g-rows gap-1 p-2">
              <h2 class="h5">Protect Version</h2>
              <template v-if="latestProtectVersion">
                <span
                  v-if="computer.version === latestProtectVersion"
                  class="version-state"
                >
                  <j-icon
                    data="@jcon/check-circle.svg"
                    color="var(--color-success-base)"
                    class="mr-1"
                  />
                  Up to date
                </span>

                <span v-else class="version-state">
                  <j-icon
                    data="@jcon/warning.svg"
                    color="var(--color-warning-base)"
                    class="mr-1"
                  />
                  <div class="g-rows gap-none">
                    <span>Out of date</span>
                    <small class="helper-text version-number"
                      >{{ computer.version }}, latest:
                      {{ latestProtectVersion }}</small
                    >
                  </div>
                </span>
              </template>
              <span v-else class="version-number">{{ computer.version }}</span>
            </div>
            <div
              v-if="hasThreatPermissions"
              class="g-rows gap-1 p-2 has-divider"
            >
              <h2 class="h5">Threat Version</h2>
              <template v-if="latestTPVersion">
                <span
                  v-if="computer.signaturesVersion === latestTPVersion"
                  class="version-state"
                >
                  <j-icon
                    data="@jcon/check-circle.svg"
                    color="var(--color-success-base)"
                    class="mr-1"
                  />
                  Up to date
                </span>
                <span
                  v-else-if="computer.signaturesVersion === 0"
                  class="version-state"
                >
                  <div class="g-rows gap-none not-supported">
                    <p>Not Supported on macOS {{ osVersion }}</p>
                  </div>
                </span>
                <span v-else class="version-state">
                  <j-icon
                    data="@jcon/warning.svg"
                    color="var(--color-warning-base)"
                    class="mr-1"
                  />
                  <div class="g-rows gap-none">
                    <span>Out of date</span>
                    <small class="helper-text version-number"
                      >v{{ computer.signaturesVersion }}, latest: v{{
                        latestTPVersion
                      }}</small
                    >
                  </div>
                </span>
              </template>
              <p v-else class="version-number">
                v{{ computer.signaturesVersion }}
              </p>
            </div>
            <template v-if="isDeprecated">
              <div class="g-rows gap-1 p-2 has-divider">
                <h2 class="h5">macOS Version</h2>
                <span class="os-state">
                  <j-icon
                    data="@jcon/warning.svg"
                    color="var(--color-warning-base)"
                    class="mr-1"
                  />
                  <div class="g-rows gap-none">
                    <p>{{ osVersion }} is no longer supported</p>
                    <small class="helper-text"
                      >See
                      <a
                        href="https://docs.jamf.com/jamf-protect/documentation/General_Requirements.html"
                        target="_blank"
                        rel="noopener"
                        >General Requirements<span class="sr-only">
                          (opens in a new tab)</span
                        ></a
                      ></small
                    >
                  </div>
                </span>
              </div>
            </template>
          </Card>
        </div>
        <InsightsStatusCard
          v-if="report"
          :link="insightLink"
          :report="report"
          :computer="computer"
        />
      </div>
      <template v-if="hasAlertPermissions">
        <h1 class="subtitle-text">Alerts</h1>
        <SeverityStatusTimeSeries
          :computer="computer.uuid"
          :refresh="refresh"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import SeverityStatusTimeSeries from '@/components/SeverityStatusTimeSeries.vue';
import InsightsStatusCard from '@/modules/computers/components/InsightsStatusCard.vue';
import Card from '@/components/Card.vue';
import { useComputers } from '@/modules/computers/computers.js';
import { mapState } from 'vuex';

export default {
  components: { SeverityStatusTimeSeries, InsightsStatusCard, Card },
  name: 'ComputerOverview',
  setup(props) {
    const { canAccess } = useRBAC();
    const { isDeprecated } = useComputers(props);

    return { canAccess, isDeprecated };
  },
  props: {
    computer: {
      type: Object,
      required: true,
    },
    refresh: Boolean,
  },
  data() {
    return {
      permissions: [RBAC_RESOURCE.COMPUTER],
    };
  },
  computed: {
    ...mapState({
      latestTPVersion: (state) => state.primary.computers.latestTPVersion,
      latestProtectVersion: (state) =>
        state.primary.computers.latestProtectVersion,
    }),
    hasAlertPermissions() {
      return this.canAccess(RBAC_RESOURCE.ALERT);
    },
    hasThreatPermissions() {
      return this.canAccess(RBAC_RESOURCE.THREAT_PREVENTION_VERSION);
    },
    hasProtectVersionPermissions() {
      return this.canAccess(RBAC_RESOURCE.DOWNLOAD);
    },
    osVersion() {
      return `${this.computer.osMajor}.${this.computer.osMinor}`;
    },
    insightLink() {
      return {
        name: 'computers.index.compliance',
        params: { id: this.computer.uuid },
      };
    },
    report() {
      return this.computer?.scorecard
        ? {
            fail: this.computer.scorecard.filter(
              ({ pass, enabled }) => pass === false && enabled
            ).length,
            pass: this.computer.scorecard.filter(
              ({ pass, enabled }) => pass && enabled
            ).length,
            disabled: this.computer.scorecard.filter(({ enabled }) => !enabled)
              .length,
          }
        : null;
    },
  },
  async beforeMount() {
    if (this.hasThreatPermissions || this.hasProtectVersionPermissions) {
      await this.$store.dispatch('primary/getRelatedComputerInformation');
    }

    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.versions-container {
  flex: 0 0 auto;
}
.versions {
  @include grid;
  @include grid-columns;
  height: 104px;
  min-width: 324px;
  padding: 0;

  .has-divider {
    padding-left: spacing(3);
    border-left: 1px solid var(--color-border-secondary);
  }

  div {
    align-content: flex-start;
  }
  .version-state {
    display: flex;
    align-items: center;
  }

  .version-number {
    white-space: nowrap;
    max-width: 156px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .os-state {
    max-width: 250px;
    display: flex;
    align-items: center;
  }

  @include breakpoint(medium down) {
    height: auto;
  }

  @include breakpoint(small down) {
    @include grid-rows;

    .has-divider {
      margin-top: 0;
      border-top: 1px solid var(--color-border-secondary);
      border-left: none;
    }
  }
}

@include breakpoint(medium down) {
  .versions-container {
    flex: 1 1 auto;
  }
}

.scroll-vertical {
  @include breakpoint(small down) {
    margin-bottom: 64px;
  }
}
</style>
