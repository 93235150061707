<template>
  <div class="empty-state">
    <div :class="{ 'empty-state-overlay': true, 'allow-events': allowEvents }">
      <div class="inner-wrapper">
        <Card class="m-4 p-4 secondary">
          <slot></slot>
        </Card>
        <div class="bottom-extra">
          <slot name="bottom"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';
export default {
  name: 'EmptyState',
  props: {
    allowEvents: {
      type: Boolean,
      default: true,
    },
  },
  components: { Card },
};
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  flex: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.empty-state-overlay {
  height: 100%;
  position: absolute;
  width: 100%;
  color: var(--color-table-header-foreground);
  top: 0;

  &:not(.allow-events) {
    pointer-events: none;
  }

  .inner-wrapper {
    @include grid();
    @include grid-rows(max-content);
    height: 100%;
    width: 100%;
    place-content: center;
    place-items: center;
    > div {
      @include grid(spacing());
      padding: 0 spacing(4);
      max-width: 800px;
    }

    @include breakpoint(small down) {
      @include scroll-vertical;
    }
  }
}
</style>
