import { AgGridVue } from '@ag-grid-community/vue3';
import FilterTagBar from './controls/FilterTagBar.vue';
import TableControlBar from './controls/TableControlBar.vue';
// core components
import TableTooltip from './framework-components/TableTooltip.vue';
import TableHeader from './framework-components/TableHeader.vue';
import TableCheckboxHeader from './framework-components/TableCheckboxHeader.vue';
import TableGroupControlHeader from './framework-components/TableGroupControlHeader.vue';
import NoRowsOverlay from './framework-components/NoRowsOverlay.vue';
import LoadingOverlay from './framework-components/LoadingOverlay.vue';
import StatusPanelPageSize from './framework-components/StatusPanelPageSize.vue';

// filter components
import TableFilterInt from './framework-components/filters/TableFilterInt.vue';
import TableFilterText from './framework-components/filters/TableFilterText.vue';
import TableFilterDate from './framework-components/filters/TableFilterDate.vue';
import TableFilterArray from './framework-components/filters/TableFilterArray.vue';
import TableFilterValueOptions from './framework-components/filters/TableFilterValueOptions.vue';

// cell components
import TableCellIcon from './framework-components/cells/TableCellIcon.vue';
import TableCellDate from './framework-components/cells/TableCellDate.vue';
import TableCellTags from './framework-components/cells/TableCellTags.vue';
import TableCellCode from './framework-components/cells/TableCellCode.vue';
import TableCellOptions from './framework-components/cells/TableCellOptions.vue';
import TableCellButton from './framework-components/cells/TableCellButton.vue';
import TableCellLink from './framework-components/cells/TableCellLink.vue';
import TableCellManaged from './framework-components/cells/TableCellManaged.vue';
import TableCellSeverity from './framework-components/cells/TableCellSeverity.vue';
import TableCellEvent from './framework-components/cells/TableCellEvent.vue';

export default {
  components: {
    AgGridVue,
    FilterTagBar,
    TableControlBar,
    TableHeader,
    TableTooltip,
    NoRowsOverlay,
    LoadingOverlay,
    TableCheckboxHeader,
    TableGroupControlHeader,
    StatusPanelPageSize,
    TableFilterArray,
    TableFilterInt,
    TableFilterText,
    TableFilterDate,
    TableFilterValueOptions,
    TableCellIcon,
    TableCellDate,
    TableCellTags,
    TableCellCode,
    TableCellOptions,
    TableCellButton,
    TableCellLink,
    TableCellManaged,
    TableCellSeverity,
    TableCellEvent,
  },
};
