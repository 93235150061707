<template>
  <div class="p-2 scroll-vertical">
    <Card class="card-details">
      <ListItem title="Name">
        {{ preventList.name }}
      </ListItem>
      <ListItem title="Description">
        {{ preventList.description }}
      </ListItem>
      <ListItem title="Created">
        {{ formatEpochDate(preventList.created) }}
      </ListItem>
      <ListItem title="Type">
        <j-tag>{{ preventList.type }}</j-tag>
      </ListItem>
      <ListItem title="Count">
        <j-tag is-rounded>{{ preventList.count }}</j-tag>
      </ListItem>
      <ListItem title="List Values">
        <ul>
          <li v-for="value in preventList.list" :key="value">
            {{ value }}
          </li>
        </ul>
      </ListItem>
    </Card>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'PreventListDetails',
  components: {
    Card,
    ListItem,
  },
  setup() {
    const { formatEpochDate } = useGmt();

    return {
      formatEpochDate,
    };
  },
  props: {
    preventList: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-details {
  @include grid;
  @include grid-rows;
  max-width: 600px;
}
</style>
