<template>
  <Tabs :tabs="tabs" id="compliance">
    <template #right>
      <j-link
        open-in-new-tab
        to="https://docs.jamf.com/jamf-protect/documentation/Configuring_Network_Threat_Prevention.html"
        >Additional Resources</j-link
      >
    </template>
  </Tabs>
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'ComplianceSection',
  components: { Tabs },
  data() {
    return {
      tabs: [
        {
          label: 'Overview',
          route: {
            name: 'compliance.index',
          },
        },
        {
          label: 'Compliance Settings',
          route: {
            name: 'compliance.index.settings',
          },
          hideOnNestedRoutes: true,
          nestedRouteNames: ['compliance.index.detail'],
        },
      ],
    };
  },
};
</script>
