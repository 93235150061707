import { createApp } from 'vue';
import { sync } from 'vuex-router-sync';

import {
  JamfSelect,
  JamfInput,
  JamfTooltip,
  JamfInputGroup,
  JamfTextarea,
  JamfToggleGroup,
  JamfDropdown,
  JamfButton,
  JamfDateTimePicker,
  JamfIcon,
  JamfModal,
  JamfCheckbox,
  JamfSwitch,
  JamfRadio,
  JamfLink,
  JamfSpinner,
} from '@jamf/design-system-vue';
import { LicenseManager } from '@ag-grid-enterprise/core';
import VueClipboard from 'v-clipboard';
import * as Sentry from '@sentry/vue';
import { sentryRouterInstrumentation } from './util/sentry-router-instrumentation';
import { BrowserTracing } from '@sentry/tracing';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import VuePapaParse from 'vue-papa-parse';
import BackButton from '@/components/BackButton.vue';
import CreateButton from '@/components/CreateButton.vue';
import DeleteButton from '@/components/DeleteButton.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import SaveButton from '@/components/SaveButton.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import Tag from '@/components/ui/Tag.vue';
import UpdateIndicator from '@/components/UpdateIndicator.vue';
import Notification from '@/components/ui/Notification.vue';
import Loader from '@/components/ui/Loader.vue';
import FormControlBar from '@/components/forms/FormControlBar.vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import typeDefs from './defs';
import logger from './plugins/logger';

const getConfig = async () => {
  try {
    const response = await fetch(
      window.location.origin + '/config/login.json',
      {
        method: 'GET',
        mode: 'same-origin',
        credentials: 'same-origin', // no-cors, *cors, same-origin
      }
    );
    const config = await response.json();

    return config;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Unable to fetch tenant configuration');
  }
};

LicenseManager.setLicenseKey(
  // eslint-disable-next-line
  'CompanyName=Jamf Software, LLC,LicensedApplication=Protect,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-033692,SupportServicesEnd=3_December_2023_[v2]_MTcwMTU2MTYwMDAwMA==21d601ed7a51e6c408f3077b14583801'
);

// Do not report errors to sentry in development mode
// VUE_APP_SENTRY must also be set in the .env file
const registerSentry = (instance) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.VUE_APP_SENTRY !== undefined
  ) {
    Sentry.init({
      app: instance,
      sendClientReports: false,
      dsn: process.env.VUE_APP_SENTRY,
      environment: process.env.VUE_APP_DEPLOYMENT_PREFIX,
      attachProps: true,
      beforeBreadcrumb: excludeGraphQLFetch,
      tracePropagationTargets: [`${window.location}/app`, /^\//],
      integrations: [
        new BrowserTracing({
          tracingOrigins: [`${window.location}/app`, /^\//],
          routingInstrumentation: sentryRouterInstrumentation(router),
        }),
      ],

      tracesSampleRate: 1.0,
      tracingOptions: {
        hooks: ['mount', 'update', 'destroy'],
        trackComponents: true,
      },
    });

    Sentry.addGlobalEventProcessor((event) => {
      if (event.type === 'transaction') {
        // filter out 'http.client' which is a less useful dupe of the graphql span created
        event.spans = event?.spans?.filter(({ op }) => {
          return op !== 'http.client';
        });
      }
      if (event.user) {
        delete event.user.ip;
      }
      return event;
    });
  }
};

const registerUtils = (instance) => {
  instance.component('JBackButton', BackButton);
  instance.component('JCreateButton', CreateButton);
  instance.component('JDeleteButton', DeleteButton);
  instance.component('JDeleteModal', DeleteModal);
  instance.component('JSaveButton', SaveButton);
  instance.component('JRemoveButton', RemoveButton);
  instance.component('GpLoader', Loader);
  instance.component('JFormBar', FormControlBar);
  instance.component('JCheckbox', JamfCheckbox);
  instance.component('JSwitch', JamfSwitch);
  instance.component('JRadio', JamfRadio);
  instance.component('JNotification', Notification);
  instance.component('JSelect', JamfSelect);
  instance.component('JTag', Tag);
  instance.component('JInput', JamfInput);
  instance.component('JTooltip', JamfTooltip);
  instance.component('JInputGroup', JamfInputGroup);
  instance.component('JTextarea', JamfTextarea);
  instance.component('JToggleGroup', JamfToggleGroup);
  instance.component('JDropdown', JamfDropdown);
  instance.component('JButton', JamfButton);
  instance.component('JDatetimePicker', JamfDateTimePicker);
  instance.component('JUpdated', UpdateIndicator);
  instance.component('JIcon', JamfIcon);
  instance.component('JModal', JamfModal);
  instance.component('JLink', JamfLink);
  instance.component('JSpinner', JamfSpinner);

  instance.use(VueClipboard);
  instance.use(VuePapaParse);
  instance.use(logger);
};

// get login.json before bootstrapping the app
getConfig().then((result) => {
  sync(store, router);
  // store login configuration
  if (result) {
    store.dispatch('auth/loginConfig', result);
    store.dispatch('setupApolloClients');
  }

  const app = createApp(App);
  registerSentry(app);
  registerUtils(app);

  // Load the defintion files and save them to Vue.$typeDefs
  app.provide('$typeDefs', typeDefs.getTypeDefinitions());

  app.use(router);
  app.use(store);

  // Save the main Vue to window.app, used for the tester to get access to the main Vue instance
  if (window.Cypress) {
    window.vue = {
      app,
      store,
    };
  }

  // Finally mount the app to #app
  app.mount('#app');
});
