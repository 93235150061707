<template>
  <div class="help-panel">
    <div class="def-header g-cols max-c ai-center">
      <AnalyticEventTypeIcon :input-type="def.type" />
      <h1 class="h4">
        {{ def.label }}
      </h1>
    </div>
    <p v-if="def.description" class="p-1">
      {{ def.description }}
    </p>
    <FieldDefinitionTable
      :def="def"
      class="definition-table"
      :is-auto-height="true"
    />
  </div>
</template>

<script>
import FieldDefinitionTable from '@/components/analytics/FieldDefinitionTable.vue';
import AnalyticEventTypeIcon from '@/components/analytics/AnalyticEventTypeIcon.vue';

export default {
  name: 'AnalyticHelpPanel',
  components: {
    FieldDefinitionTable,
    AnalyticEventTypeIcon,
  },
  props: {
    def: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.help-panel {
  height: auto;
}

.definition-table {
  border-top: 1px solid var(--color-border-secondary);
}
.def-header {
  padding: spacing();
  border-bottom: 1px solid var(--color-border-secondary);
}
</style>
