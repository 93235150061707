<template>
  <Tabs :tabs="tabs" />
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PlanSection',
  components: { Tabs },
  data() {
    return {
      tabs: [
        {
          label: 'Plans',
          route: { name: 'plans.index' },
          hideOnNestedRoutes: true,
          nestedRouteNames: [
            'plans.index.detail',
            'plans.index.create',
            'plans.index.edit',
            'plans.index.delete',
            'plans.index.profile',
            'exceptions.index.detail',
            'exceptions.index.create',
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
  },
  beforeMount() {
    if (this.isFeatureEnabled('EXCLUSION_SETS')) {
      this.tabs.push({
        label: 'Exception Sets',
        route: { name: 'exceptions.index' },
        hideOnNestedRoutes: true,
        nestedRouteNames: [
          'plans.index.detail',
          'exceptions.index.detail',
          'exceptions.index.create',
        ],
      });
    }
  },
};
</script>
