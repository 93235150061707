<template>
  <JamfTable
    v-if="!loading"
    :get-data="getData"
    :page-size="pageSize"
    :cache-size="cacheSize"
    :view="view"
    :search-params="{ columnOptions }"
    :suppress-search="!isTableView"
    @view-changed="viewUpdate"
    :suppress-refresh-button="!isTableView"
    :suppress-pagination="loadedSingleAlert"
    :suppress-filter-bar="hideFilters"
    @pagination-changed="setAlertData"
    :columns="columns"
    use-page-size-options
    :last-page-override="lastPageOverride"
  >
    <template #left>
      <j-back-button
        v-if="!isTableView"
        @click="
          () =>
            $router.replace({
              name: 'alerts.index',
              query: currentTableQuery,
            })
        "
      />
      <div v-if="currentItem" class="alert-title">
        <template v-for="({ name, uuid }, i) in facts" :key="i">
          <template v-if="i !== 0">&</template>
          <router-link
            v-if="uuid && hasMatchingAnalytic(uuid)"
            :data-feature-id="`analytic-link-${i}`"
            :to="{ name: 'analytics.index.detail', params: { id: uuid } }"
            class="host-link ellipsis"
          >
            {{ getKeyNameMatch(name) }}
          </router-link>
          <template v-else>
            {{ getKeyNameMatch(name) }}
          </template>
        </template>
      </div>
    </template>
    <template #right="{ selectedNodes, gridApi, rowCount }">
      <j-button
        v-if="isTableView"
        style-type="secondary"
        :is-disabled="rowCount === 0"
        data-feature-id="view-details-button"
        @click="viewSelected(selectedNodes, gridApi)"
      >
        {{
          selectedNodes.length > 1
            ? `Compare ${selectedNodes.length}`
            : 'Detail View'
        }}
      </j-button>
      <j-dropdown :options="status" :is-disabled="selectedNodes.length === 0">
        <template #target>
          <j-button
            v-if="isTableView && canWrite"
            data-feature-id="alerts-status"
            :is-disabled="!selectedNodes.length"
          >
            Change Status
          </j-button>
        </template>
        <template #item="{ item }">
          <div
            role="button"
            @click="updateStatuses(selectedNodes, gridApi, item.label)"
          >
            <status-indicator :status="item.label" />
          </div>
        </template>
      </j-dropdown>
    </template>
    <template #default="{ gridApi }">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component
            v-if="currentItem"
            :is="Component"
            v-model:alert="currentItem"
            @status-changed="(alerts) => refreshRowsFromAlerts(alerts, gridApi)"
          />
        </transition>
      </router-view>
    </template>
  </JamfTable>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { getKeyNameMatch } from '@/util';
import JamfTable from '@/components/table/JamfTable.vue';
import { EVENT_TYPES_NAMES_ALL } from '@/util/constants/event.types';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';
import { ALERT_STATUS } from '@/util/constants/alert-status.types';
import { SEVERITY } from '@/util/constants/severity.types';
import { mapState } from 'vuex';
import StatusIndicator from '@/components/StatusIndicator.vue';
import AlertTableCellAlertDescription from './components/AlertTableCellDescription.vue';
import AlertTableCellStatus from './components/AlertTableCellStatus.vue';

import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import { columnOptionsTimeIntervals } from '@/components/table/utils/search-options.common';

export default {
  name: 'Alerts',
  components: {
    JamfTable,
    AlertTableCellAlertDescription,
    AlertTableCellStatus,
    StatusIndicator,
  },
  setup() {
    const { canAccess, canWrite } = useRBAC([RBAC_RESOURCE.ALERT]);
    return { canAccess, canWrite };
  },
  data() {
    const severityValues = Object.values(SEVERITY);
    const statusEntries = Object.entries(ALERT_STATUS);
    const eventTypeEntries = Object.entries(EVENT_TYPES_NAMES_ALL);
    return {
      endpoint: 'primary/listAlerts',
      isSelected: false,
      lastPageOverride: null,
      collection: [],
      pageSize: 25,
      hideFilters: false,
      loading: true,
      block: 0,
      sizeChangeEvent: false, // using this to determine if the paginationEvent that is triggered is a size change or a page change
      title: null,
      loadedSingleAlert: false,
      status: statusEntries
        .filter(
          ([, value]) =>
            value !== ALERT_STATUS.None && value !== ALERT_STATUS.AutoResolved
        )
        .map(([key]) => ({
          label: key,
          value: key,
        })),
      columnOptions: {
        eventType: eventTypeEntries.map(([key, value]) => ({
          label: value,
          value: key,
        })),
        analytics: [],
        tags: [],
        severity: severityValues.map((value) => ({
          value,
          class: `severity-option ${value.toLowerCase()}`,
        })),
        status: statusEntries.map(([key, value]) => ({
          label: value,
          value: key,
        })),
        created: columnOptionsTimeIntervals,
      },
      currentItem: null,
      columns: [
        { type: 'selectAllColumn' },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateFilterColumn',
          sortable: true,
          sort: 'desc',
        },
        {
          headerName: 'Description',
          colId: 'description',
          field: 'json',
          flex: 2,
          cellClass: 'clickable',
          minWidth: 400,
          cellRendererParams: {
            action: this.viewAlert,
          },
          cellRenderer: 'AlertTableCellAlertDescription',
          valueFormatter: (params) =>
            params.value ? JSON.parse(params.value) : {},
        },
        {
          type: 'severityColumn',
        },
        {
          headerName: 'Action',
          field: 'actions',
          sortable: true,
          width: 90,
          maxWidth: 140,
          type: 'arrayFilterColumn',
          filterParams: {
            placeholder: 'Prevented, Smart Group',
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          width: 90,
          maxWidth: 160,
          type: 'valueOptionsFilterColumn',
          cellRenderer: 'AlertTableCellStatus',
          filterParams: {
            defaultOption: FILTER_TYPES.EQUALS,
            valueOptions: statusEntries.map(([value, label]) => ({
              label,
              value,
            })),
          },
        },
        {
          headerName: 'Computer',
          field: 'computer',
          sortable: true,
          type: ['linkColumn', 'textFilterColumn'],
          filterParams: {
            placeholder: 'Computer Name',
          },
          cellRendererParams: {
            route: (params) => ({
              name: 'computers.index.overview',
              params: { id: params.value?.uuid },
            }),
          },
          flex: 1,
          valueFormatter: (params) =>
            params?.value?.hostName || params?.value?.uuid,
        },
        {
          headerName: 'Computer UUID',
          field: 'computer',
          colId: 'computerUuid',
          sortable: true,
          wrapText: true,
          type: 'textFilterColumn',
          filterParams: {
            suppressTypeOptions: true,
            defaultOption: FILTER_TYPES.EQUALS,
            suppressAndOrCondition: true,
            placeholder: 'xxxx-xxxx-xxxx-xxxx-xxxxx',
          },
          flex: 1,
          valueFormatter: (params) => params?.value?.uuid || 'Unknown',
        },
        {
          type: 'eventTypeColumn',
          tooltipField: 'eventType',
        },
        // filter only columns
        {
          field: 'tags',
          type: ['hiddenColumn', 'arrayFilterColumn'],
        },
        {
          field: 'analytics',
          type: ['hiddenColumn', 'arrayFilterColumn'],
          permissions: RBAC_RESOURCE.ANALYTIC,
        },
        {
          field: 'plan',
          type: ['hiddenColumn', 'numberFilterColumn'],
          permissions: RBAC_RESOURCE.PLAN,
          filterParams: {
            suppressTypeOptions: true,
            defaultOption: FILTER_TYPES.EQUALS,
            suppressAndOrCondition: true,
          },
        },
      ],
    };
  },
  async beforeMount() {
    this.pageSize = this.cacheSize;
    if (this.$route.params?.uuid) {
      const response = await this.$store.dispatch('primary/getAlert', {
        uuid: this.$route.params?.uuid,
      });
      this.updateAlert(response);
      this.loadedSingleAlert = true;
    }
    // Load all of the possible name and tag options from the list of analytics
    if (this.canAccess(RBAC_RESOURCE.ANALYTIC)) {
      await this.$store.dispatch('primary/listAnalyticsLite');
      const tags = new Set();
      this.analytics?.forEach(({ name, tags: t }) => {
        this.columnOptions.analytics.push(name);
        t.forEach((tag) => tags.add(tag));
      });
      tags.forEach((tag) => this.columnOptions.tags.push(tag));
    }

    this.loading = false;
  },
  computed: {
    ...mapState({
      isItem: (state) => state.route.name.includes('alerts.item'),
      routeName: (state) => state.route.name,
      queryState: (state) => state.app.queryState,
    }),
    ...mapState('primary', { analytics: (state) => state.analytics.analytics }),
    isTableView() {
      return this.view === 'table';
    },
    cacheSize() {
      return +this.$route?.query?.size || +this.currentTableQuery?.size || 25;
    },
    currentTableQuery() {
      return this.queryState['alerts.index']?.query;
    },
    // set view based on current route
    view() {
      return this.isItem ? 'item' : 'table';
    },
    facts() {
      return this.currentItem?.parsed?.match?.facts || [{ name: 'Unknown' }];
    },
  },
  watch: {
    cacheSize() {
      if (this.pageSize !== 1) {
        this.pageSize = this.cacheSize;
      }
    },
  },
  methods: {
    getKeyNameMatch,
    hasMatchingAnalytic(uuid) {
      return !!this.analytics?.find((analytic) => analytic?.uuid === uuid);
    },
    viewSelected(selectedNodes, gridApi) {
      if (selectedNodes.length === 0) {
        this.viewAlert({ api: gridApi });
      } else {
        this.isSelected = true;
        this.collection = selectedNodes.map((item) => item.data);
        this.viewAlert({ api: gridApi });
        this.lastPageOverride = selectedNodes.length;
      }
    },
    viewAlert({ api, rowIndex = 0 }) {
      this.sizeChangeEvent = rowIndex !== 0;
      api.showLoadingOverlay();
      this.pageSize = 1;
      this.hideFilters = true;
      api.paginationSetPageSize(this.pageSize);
      api.paginationGoToPage(rowIndex);
    },
    updateAlert(value) {
      this.currentItem = { ...value, parsed: this.getItem(value?.json) };
      this.title = this.getTitle(this.currentItem.parsed);
    },
    async updateStatuses(selectedNodes, gridApi, newStatus) {
      selectedNodes.forEach((item) => {
        item.setData({ ...item.data, status: null });
      });

      const response = await this.$store.dispatch('primary/updateAlerts', {
        input: {
          uuids: selectedNodes.map((item) => item.data?.uuid),
          status: newStatus,
        },
      });

      this.refreshRowsFromAlerts(response?.items, gridApi);
    },
    refreshRowsFromAlerts(alerts, gridApi) {
      const map = alerts.reduce((map, { uuid, status }) => {
        map[uuid] = status;
        return map;
      }, {});

      gridApi.forEachNode((item) => {
        if (map[item.data?.uuid]) {
          item.setData({ ...item.data, status: map[item.data?.uuid] });
        }
      });
    },
    back(gridApi, refresh) {
      this.title = null;
      this.pageSize = this.cacheSize;
      this.hideFilters = false;
      this.currentItem = null;
      this.loadedSingleAlert = false;
      this.lastPageOverride = null;
      gridApi.paginationSetPageSize(this.pageSize);
      gridApi.paginationGoToPage(this.block);
      gridApi.hideOverlay();

      if (this.isSelected) {
        this.isSelected = false;
        refresh();
      }
    },
    viewUpdate({ gridApi, refresh, view }) {
      if (view === 'table') {
        this.back(gridApi, refresh);
      }
    },

    // temporary functions jsonparser for the log info (should not be needed when backend has latest schema)
    getTitle(item) {
      return item.match?.facts.map(({ name }) => name).join(' & ');
    },
    getItem(item) {
      return item ? JSON.parse(item) : {};
    },
    async getData(params) {
      return this.$store.dispatch(this.endpoint, params);
    },
    async setAlertData(params) {
      if (
        params.hasData &&
        params.pageStatus !== 'loading' &&
        this.pageSize === 1 &&
        !this.sizeChangeEvent
      ) {
        this.currentItem = null;
        const page = params.currentPage - 1;
        const data = this.isSelected
          ? this.collection[page]
          : params.api.getDisplayedRowAtIndex(page)?.data;
        if (data) {
          this.updateAlert(data);
        }
        try {
          await this.$router.replace({
            name: this.isItem ? this.routeName : 'alerts.item',
            params: { uuid: data?.uuid },
            query: {},
          });
        } catch (err) {
          if (
            err.name?.includes('NavigationDuplicated') &&
            err.message?.includes(data?.uuid)
          ) {
            // do nothing
          } else {
            throw err;
          }
        }
      }
      this.sizeChangeEvent = false;
      // set the block so we can go back to the right cache block in relation to the current item.
      this.block = params.currentBlock;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-title {
  @include grid(spacing());
  @include grid-columns;

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 20px;
  }
}
</style>
