<template>
  <div>
    <h2 class="h5 g-cols gap-1 max-c ai-center mb-3">
      <AnalyticEventTypeIcon :input-type="type" />
      {{ EVENT_TYPES_NAMES_ALL[type] || type }} Details
    </h2>
    <gpfsEvent
      v-if="type === EVENT_TYPES.FILE_SYSTEM"
      :event="event"
      :related="related"
    />
    <gpprocessEvent
      v-else-if="type === EVENT_TYPES.PROCESS"
      :event="event"
      :related="related"
    />
    <gpdownloadEvent
      v-else-if="type === EVENT_TYPES.DOWNLOAD"
      :event="event"
      :related="related"
    />
    <gpscreenshotEvent
      v-else-if="type === EVENT_TYPES.SCREENSHOT"
      :event="event"
      :related="related"
    />
    <gpclickEvent
      v-else-if="type === EVENT_TYPES.CLICK"
      :event="event"
      :related="related"
    />
    <gpusbEvent v-else-if="type === EVENT_TYPES.USB" :event="event" />
    <MountEvent
      v-else-if="
        type === EVENT_TYPES.MOUNT || type === EVENT_TYPES.USB_STORAGE_EVENT
      "
      :event="event"
    />
    <gpmrtEvent
      v-else-if="type === EVENT_TYPES.MRT"
      :event="event"
      :related="related"
    />
    <gpgkEvent
      v-else-if="type === EVENT_TYPES.GATEKEEPER"
      :event="event"
      :related="related"
    />
    <gpkeyloggerEvent
      v-else-if="type === EVENT_TYPES.KEY_LOG"
      :event="event"
      :related="related"
    />
    <gppreventedExecutionEvent
      v-else-if="type === EVENT_TYPES.PREVENTION"
      :event="event"
      :related="related"
    />
    <GPThreatMatchExecEvent
      v-else-if="type === EVENT_TYPES.THREAT"
      :event="event"
      :related="related"
    />
    <UnknownEvent v-else :event="event" />
  </div>
</template>

<script>
import gpfsEvent from '@/modules/alerts/components/events/GPFSEvent.vue';
import gpprocessEvent from '@/modules/alerts/components/events/GPProcessEvent.vue';
import gpdownloadEvent from '@/modules/alerts/components/events/GPDownloadEvent.vue';
import gpscreenshotEvent from '@/modules/alerts/components/events/GPScreenshotEvent.vue';
import gpclickEvent from '@/modules/alerts/components/events/GPClickEvent.vue';
import gpusbEvent from '@/modules/alerts/components/events/GPUSBEvent.vue';
import gpmrtEvent from '@/modules/alerts/components/events/GPMRTEvent.vue';
import gpgkEvent from '@/modules/alerts/components/events/GPGatekeeperEvent.vue';
import gpkeyloggerEvent from '@/modules/alerts/components/events/GPKeylogRegisterEvent.vue';
import gppreventedExecutionEvent from '@/modules/alerts/components/events/GPPreventedExecutionEvent.vue';
import GPThreatMatchExecEvent from '@/modules/alerts/components/events/GPThreatMatchExecEvent.vue';
import UnknownEvent from '@/modules/alerts/components/events/UnknownEvent.vue';
import {
  EVENT_TYPES,
  EVENT_TYPES_NAMES_ALL,
} from '@/util/constants/event.types';
import AnalyticEventTypeIcon from '@/components/analytics/AnalyticEventTypeIcon.vue';
import MountEvent from '@/modules/alerts/components/events/MountEvent.vue';

export default {
  name: 'EventDetails',
  components: {
    gpfsEvent,
    gpprocessEvent,
    gpdownloadEvent,
    gpscreenshotEvent,
    gpclickEvent,
    gpusbEvent,
    gpmrtEvent,
    gpgkEvent,
    gpkeyloggerEvent,
    gppreventedExecutionEvent,
    GPThreatMatchExecEvent,
    UnknownEvent,
    AnalyticEventTypeIcon,
    MountEvent,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { EVENT_TYPES, EVENT_TYPES_NAMES_ALL };
  },
};
</script>

<style lang="scss" scoped>
.event-details {
  @include breakpoint(medium) {
    max-width: 70%;
  }
}
</style>
