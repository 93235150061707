<template>
  <div class="gp-content" role="tabpanel">
    <div class="g-rows max-c p-2 scroll-vertical">
      <h1 v-if="canAccessAlerts" class="h4">Alerts</h1>
      <div v-if="canAccessAlerts" class="f-wrap">
        <Card
          v-for="(computerCount, key) in computersWithAlertsCount"
          :key="key"
          :id="`computerAlerts${key}`"
          class="g-rows gap-1 max-c"
        >
          <h1 class="h5">
            Computers with the most {{ key.toLowerCase() }} severity alerts over
            the last
            {{ timeInterval }}
          </h1>
          <transition-group name="fade">
            <div
              v-for="{ count, computer } in computerCount.slice(0, 50)"
              class="tags"
              :key="computer.uuid"
            >
              <j-tag
                :style-type="styleTypes[key]"
                :count="count"
                @click="
                  routeTo('alerts.index', {
                    column: 'severity|computerUuid|created',
                    type: 'equals|equals|afterInterval',
                    value: `${key}|${computer.uuid}|${timeInterval}`,
                    filterType: 'int|text|date',
                  })
                "
                >{{ computer.hostName }}</j-tag
              >
            </div>
          </transition-group>
        </Card>
      </div>
      <h2 class="h4" v-if="canAccessComputers">Specs</h2>
      <div class="f-wrap" v-if="canAccessComputers">
        <Card
          v-for="(stat, key) in stats"
          :key="key"
          :id="`computerSpecs${key}`"
          class="g-rows gap-1 max-c"
        >
          <h1 class="h5">
            {{ statLabels[key] }}
          </h1>
          <div class="tags">
            <template v-for="({ value, count }, i) in stat" :key="i">
              <j-tag
                :count="count"
                style-type="inverse"
                @click="
                  routeTo('computers.index', {
                    column: key,
                    type:
                      key === 'macosVersion'
                        ? FILTER_TYPES.EQUALS
                        : FILTER_TYPES.CONTAINS,
                    value,
                    filterType: key === 'macosVersion' ? 'int' : 'text',
                  })
                "
                >{{ value }}</j-tag
              >
            </template>
            <template
              v-if="
                isFeatureEnabled('DEPRECATE_MACOS') &&
                deprecatedStats &&
                stat === stats.macosVersion
              "
            >
              <j-tag
                data-feature-id="deprecated-tag"
                style-type="warning"
                :count="deprecatedStats"
                @click="
                  routeTo('computers.index', {
                    column: key,
                    type: FILTER_TYPES.LESS_THAN_OR_EQUAL,
                    value: deprecationPath,
                    filterType: key === 'macosVersion' ? 'int' : 'text',
                  })
                "
                ><j-icon
                  data="@jcon/warning.svg"
                  height="12"
                  class="mr-1"
                />Unsupported macOS Versions</j-tag
              >
            </template>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import Card from '@/components/Card.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';
import { SEVERITY } from '@/util/constants/severity.types';
import { MACOS_VERSION_DEPRECATED } from '@/util/constants/macos-version-deprecated.types';
import { countDeprecatedVersions } from '@/util/count-deprecated-versions';
import { mapGetters } from 'vuex';

export default {
  name: 'DashBoardComputers',
  components: {
    Card,
  },
  setup() {
    const { canAccess } = useRBAC();

    return { canAccess };
  },
  data() {
    return {
      FILTER_TYPES,
      computersWithAlertsCount: {},
      stats: {
        macosVersion: {},
        modelName: {},
        version: {},
      },
      statLabels: {
        macosVersion: 'macOS Versions',
        modelName: 'Hardware Models',
        version: 'Jamf Protect Versions',
      },
      deprecatedStats: 0,
      deprecationPath: `${MACOS_VERSION_DEPRECATED.majorVersion}.${MACOS_VERSION_DEPRECATED.minorVersion}`,
      timeInterval: '7 days',
      severity: Object.keys(SEVERITY)
        .filter((level) => level !== SEVERITY.Informational)
        .sort(),
      styleTypes: {
        [SEVERITY.High]: 'danger',
        [SEVERITY.Medium]: 'warning',
        [SEVERITY.Low]: 'low',
      },
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    canAccessAlerts() {
      return this.canAccess(RBAC_RESOURCE.ALERT);
    },
    canAccessComputers() {
      return this.canAccess(RBAC_RESOURCE.COMPUTER);
    },
  },
  async mounted() {
    await this.loadCounts();
  },
  methods: {
    routeTo(name, query) {
      this.$router.push({ name, query });
    },
    async loadCounts() {
      const labels = await this.$store.dispatch('primary/getComputersStats');

      this.stats = labels;
      this.deprecatedStats = countDeprecatedVersions(this.stats?.macosVersion);

      if (this.canAccessAlerts) {
        const counts = await this.$store.dispatch(
          'primary/getComputersAlertsCounts'
        );
        this.computersWithAlertsCount = {
          [SEVERITY.High]: counts.High,
          [SEVERITY.Medium]: counts.Medium,
          [SEVERITY.Low]: counts.Low,
        };
      }
    },
  },
};
</script>
