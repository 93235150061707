/**
 * @description creates a document link to take action with after retrieving a url
 * @param {string} url
 * @param {string} fileName
 */
export const downloadFromUrl = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFromUrl;
