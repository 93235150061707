<template>
  <TableTooltip>
    <div class="g-rows gap-1">
      <span v-if="isConnected">Connected</span>
      <span v-else-if="connectionStatusTimestamp">
        Not connected {{ fromLastDisconnectionDate }}
      </span>
      <span v-else>Not connected</span>
      <span v-if="connectionStatusTimestamp">{{
        connectionStatusTimestamp
      }}</span>
    </div>
  </TableTooltip>
</template>

<script>
import TableTooltip from '@/components/table/framework-components/TableTooltip.vue';
import { COMPUTER_CONNECTION_STATUS } from '@/util/constants/computer-status.types';
import { useComputers } from '@/modules/computers/computers.js';

export default {
  name: 'ComputersTooltipConnectionStatus',
  components: { TableTooltip },
  setup(props) {
    const {
      fromLastDisconnectionDate,
      connectionStatusTimestamp,
      connectionStatus,
      isConnected,
    } = useComputers(props);

    return {
      fromLastDisconnectionDate,
      connectionStatusTimestamp,
      connectionStatus,
      isConnected,
    };
  },
  props: {
    params: {
      data: {
        connectionStatus: COMPUTER_CONNECTION_STATUS.Disconnected,
        timestamp: '',
      },
    },
  },
};
</script>
