import { RBAC_RESOURCE } from './rbac.resource';

export const hasAppAccess = ({ permissions: { R, W } }) =>
  W.length > 0 || R.length > 0;

export const getAvailablePermissions =
  ({ permissions: { R, W } }) =>
  (resources) => {
    if (R.includes(RBAC_RESOURCE.ALL) || W.includes(RBAC_RESOURCE.ALL)) {
      return [RBAC_RESOURCE.ALL];
    }

    if (typeof resources === 'string') {
      return R.includes(resources) || W.includes(resources) ? [resources] : [];
    }
    return resources?.filter(
      (resource) => R.includes(resource) || W.includes(resource)
    );
  };

export const hasReadPermissions = ({ permissions: { R } }) => {
  return (resources) => {
    if (R.includes(RBAC_RESOURCE.ALL)) {
      return true;
    }
    if (typeof resources === 'string') {
      return R.includes(resources);
    }

    return resources.every((resource) => R.includes(resource));
  };
};

export const hasWritePermissions =
  ({ permissions: { W } }) =>
  (resources) =>
    W.includes(RBAC_RESOURCE.ALL) ||
    (typeof resources === 'string'
      ? W.includes(resources)
      : resources?.every((resource) => W.includes(resource)));

export const hasSomePermissions =
  ({ permissions: { R, W } }) =>
  (resources) =>
    R.includes(RBAC_RESOURCE.ALL) ||
    W.includes(RBAC_RESOURCE.ALL) ||
    (typeof resources === 'string'
      ? W.includes(resources) || R.includes(resources)
      : resources?.some((resource) => R.includes(resource)) ||
        resources?.some((resource) => W.includes(resource)));

export const getPermissionsVariables = (state) => {
  const variables = {};
  const getReadPermissions = hasReadPermissions(state);
  for (const resource of Object.values(RBAC_RESOURCE)) {
    variables[`RBAC_${resource}`] = getReadPermissions(resource);
  }
  return variables;
};
