<template>
  <JamfTableClientSide
    v-if="def.fields"
    use-dom-layout
    :data="def.fields"
    :suppress-control-bar="true"
    :options="gridOptions"
    :columns="columns"
    suppress-query-params
  />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import JamfTableClientSide from '@/components/table/JamfTableClientSide.vue';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ModuleRegistry } from '@ag-grid-community/core';
import FieldDefinitionDetail from './FieldDefinitionDetail.vue';

ModuleRegistry.registerModules([MasterDetailModule]);

export default {
  name: 'FieldDefinitionTable',
  props: {
    def: {
      type: Object,
      required: true,
    },
    isAutoHeight: Boolean,
  },
  components: {
    JamfTableClientSide,
    FieldDefinitionDetail,
  },
  data() {
    return {
      columns: [
        {
          headerName: 'Attribute',
          field: 'value',
          minWidth: 200,
          cellRenderer: 'agGroupCellRenderer',
        },
        {
          headerName: 'Type',
          field: 'type',
          minWidth: 100,
        },
        {
          headerName: 'Class',
          field: 'coding',
          minWidth: 100,
        },
        {
          headerName: 'Description',
          field: 'description',
          wrapText: true,
          autoHeight: true,
          minWidth: 300,
          cellStyle: {
            padding: '10px 0',
            'word-break': 'break-word',
          },
        },
      ],
      gridOptions: {
        masterDetail: true,
        detailCellRenderer: 'FieldDefinitionDetail',
        detailRowAutoHeight: true,
        isRowMaster: (item) => {
          return item ? item.type === 'enum' : false;
        },
        domLayout: this.isAutoHeight ? 'autoHeight' : 'normal',
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
:deep(.ag-cell-expandable.ag-row-group) {
  align-items: center;

  .ag-group-contracted,
  .ag-group-expanded {
    margin-right: 5px;
  }
}
</style>
