<template>
  <Tabs :tabs="tabs" />
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ThreatPreventionSection',
  components: { Tabs },
  data() {
    return {
      tabs: [
        {
          label: 'Threat Prevention',
          route: {
            name: 'prevent.index',
          },
        },
        {
          label: 'Custom Prevention Lists',
          route: {
            name: 'prevent.custom',
          },
          hideOnNestedRoutes: true,
          nestedRouteNames: [
            'prevent.custom.detail',
            'prevent.custom.create',
            'prevent.custom.edit',
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
  },
  created() {
    if (this.isFeatureEnabled('NTP_R1')) {
      this.tabs[0].label = 'Endpoint Threat Prevention';
      this.tabs.push({
        label: 'Network Threat Prevention',
        route: {
          name: 'prevent.index.network',
        },
      });
    }
  },
};
</script>
