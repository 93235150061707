import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import gql from 'graphql-tag';
import ActionConfigsFields from '../actions.fragment.gql';

const setupActionConfigInput = (getPermissions, update = true) => {
  const name = update ? 'updateActionConfigs' : 'createActionConfigs';
  const hasUlog = getPermissions([
    RBAC_RESOURCE.CLOUD_ACCESS,
    RBAC_RESOURCE.UNIFIED_LOGGING_FILTER,
  ]);
  const cloudAccess = getPermissions(RBAC_RESOURCE.CLOUD_ACCESS);
  return `
    mutation ${name}(
      ${update ? '$id: ID!' : ''}
      $name: String!
      $description: String!
      ${cloudAccess ? '$alertConfig: ActionConfigsAlertConfigInput!' : ''}
      ${hasUlog ? ' $ulogConfig: ActionConfigsULogConfigInput!' : ''}
      $telemetryConfig: ActionConfigsTelemetryConfigInput!
      $hasLimitedAppAccess: Boolean!
      $RBAC_UnifiedLoggingFilter: Boolean!
    ) {
    ${name}(${update ? 'id: $id, ' : ''}input: {
      name: $name
      description: $description
      ${cloudAccess ? 'alertConfig: $alertConfig' : ''}
      ${hasUlog ? 'ulogConfig: $ulogConfig' : ''}
      telemetryConfig: $telemetryConfig
    }) {
        ...ActionConfigsFields
      }
    }
  `;
};

export const UPDATE_ACTION = (getPermissions) => gql`
  ${setupActionConfigInput(getPermissions)}
  ${ActionConfigsFields}
`;

export const CREATE_ACTION = (getPermissions) => gql`
  ${setupActionConfigInput(getPermissions, false)}
  ${ActionConfigsFields}
`;
