<template>
  <Insights v-if="loaded" :insights="insights">
    <template #default="{ section, setEnabled }">
      <InsightCard
        v-for="{
          uuid,
          label,
          totalFail,
          totalNone,
          totalPass,
          description,
          enabled,
          tags,
        } in section"
        :key="uuid"
        :label="label"
        :route="{ name: 'insights.index.detail', params: { id: uuid } }"
        :is-enabled="enabled"
        :callback="canWrite ? (value) => setEnabled(uuid, value) : null"
        :tags="tags"
        :fail="totalFail"
        :pass="totalPass"
        :none="totalNone"
        :description="description"
      />
    </template>
  </Insights>
</template>

<script>
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapState } from 'vuex';
import Insights from './components/Insights';
import InsightCard from './components/InsightCard';

export default {
  name: 'InsightsOverview',
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.INSIGHT);
    return { canWrite };
  },
  components: {
    InsightCard,
    Insights,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('primary', {
      insights: (state) => state.insights.items,
    }),
  },
  async created() {
    await this.$store.dispatch('primary/listInsights');
    this.loaded = true;
  },
};
</script>
