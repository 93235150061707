<template>
  <router-view v-slot="{ Component }">
    <transition v-if="analytic" name="fade" mode="out-in">
      <component :is="Component" :analytic="analytic" />
    </transition>
  </router-view>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AnalyticAdmin',
  computed: {
    ...mapState('admin', ['analytic']),
    ...mapState('route', ['name']),
  },
  async beforeMount() {
    await this.$store.dispatch('admin/getAnalytic', {
      uuid: this.$route.params.id,
    });
  },
};
</script>
