<template>
  <div class="f-rows">
    <j-toggle-group data-feature-id="toggleSeverityTime">
      <j-radio
        v-model="since"
        :native-value="availableSince.hours24"
        aria-label="Filter Alert Data by 24 hours"
        :name="toggleName"
        >24 Hours</j-radio
      >
      <j-radio
        v-model="since"
        :native-value="availableSince.days7"
        aria-label="Filter Alert Data by 7 days"
        :name="toggleName"
        >7 Days</j-radio
      >
      <j-radio
        v-model="since"
        :native-value="availableSince.days30"
        aria-label="Filter Alert Data by 30 days"
        :name="toggleName"
        >30 Days</j-radio
      >
      <gp-loader v-if="!updated" class="ml-2" />
    </j-toggle-group>
    <div class="status-count-container" :class="{ 'is-updating': !updated }">
      <StatusCount
        v-for="(counts, level) in statusCounts"
        :data-feature-id="`status-${level}`"
        :key="level"
        :computer="computer"
        :severity="level"
        :counts="counts"
        :since="since"
      />
    </div>
    <Card
      :class="{
        'chart-container': true,
        'is-empty': !loaded,
        'is-updating': !updated,
      }"
    >
      <div v-if="!loaded" class="g-rows ji-center pt-6 mt-6"><gp-loader /></div>
      <ChartLine
        v-else
        :datasets="datasets"
        title="Line chart time series by severity from 0 to the largest reported alert number."
        is-time
        :time-options="timeOptions"
      />
    </Card>
  </div>
</template>

<script>
import ChartLine from '@/components/charts/ChartLine.vue';
import Card from './Card.vue';
import { SEVERITY } from '@/util/constants/severity.types';
import { ALERT_STATUS } from '@/util/constants/alert-status.types';
import StatusCount from './StatusCount.vue';
import { getTimeZone } from '@/util';
import { mapState } from 'vuex';

export default {
  name: 'SeverityStatusTimeSeries',
  components: { ChartLine, Card, StatusCount },
  props: {
    computer: String,
    refresh: Boolean,
  },
  emits: ['updated'],
  data() {
    const availableSince = {
      hours24: '24 hours',
      days7: '7 days',
      days30: '30 days',
    };

    return {
      labels: null,
      datasets: null,
      loaded: false,
      updated: false,
      severity: Object.values(SEVERITY).sort(),
      since: '7 days',
      availableSince,
      toggleName: 'availible-since',
      statusCounts: {},
    };
  },
  watch: {
    async since() {
      await this.getData();

      this.$emit('updated');
      if (this.since !== this.$route.query?.series) {
        this.$router.replace({ query: { series: this.since } });
      }
    },
    async gmt() {
      // Ensures counts are accurate based on the users time settings.
      await this.getData();

      this.$emit('updated');
    },
    async refresh() {
      await this.getData();

      this.$emit('updated');
    },
  },
  computed: {
    ...mapState(['gmt', 'analog']),
    tooltipFormat() {
      let hourFormat = this.analog ? 'MMM Do, h:mm A' : 'MMM Do, HH:mm';
      return this.interval === 'hour' ? hourFormat : 'MMM D';
    },
    interval() {
      const [, unit] = this.since.split(' ');
      return unit.split('s')[0];
    },
    timeOptions() {
      const [amount] = this.since.split(' ');
      return {
        x: {
          unit: this.interval,
          round: this.interval,
          tooltipFormat: this.tooltipFormat,
          stepSize: amount > 16 ? 2 : 1,
        },
      };
    },
  },
  methods: {
    async getData() {
      this.updated = false;
      const timezone = getTimeZone(this.gmt);
      const computerFilter =
        this.computer !== undefined
          ? [
              {
                computer: {
                  equals: this.computer,
                },
              },
            ]
          : [];

      const {
        highStatusCount,
        mediumStatusCount,
        lowStatusCount,
        infoTimeSeries,
        lowTimeSeries,
        mediumTimeSeries,
        highTimeSeries,
      } = await this.$store.dispatch('primary/getStatsSeverityTimeSeries', {
        interval: this.interval,
        since: this.since,
        timezone,
        computerFilter,
      });

      const defaultCounts = {
        [ALERT_STATUS.AutoResolved]: 0,
        [ALERT_STATUS.InProgress]: 0,
        [ALERT_STATUS.New]: 0,
        [ALERT_STATUS.Resolved]: 0,
      };
      this.statusCounts = {
        [SEVERITY.High]: highStatusCount || defaultCounts,
        [SEVERITY.Medium]: mediumStatusCount || defaultCounts,
        [SEVERITY.Low]: lowStatusCount || defaultCounts,
      };

      const baseset = {
        tension: 0.3,
        borderWidth: 1,
        parsing: {
          yAxisKey: 'count',
          xAxisKey: 'timeInterval',
        },
      };
      this.datasets = [
        {
          ...baseset,
          data: highTimeSeries,
          color: '--color-danger-base',
          label: 'High',
        },
        {
          ...baseset,
          data: mediumTimeSeries,
          color: '--color-warning-base',
          label: 'Medium',
        },
        {
          ...baseset,
          data: lowTimeSeries,
          color: '--color-low-base',
          label: 'Low',
        },
        {
          ...baseset,
          data: infoTimeSeries,
          color: {
            border: '--color-font-secondary',
            background: '--color-card-primary',
          },
          label: 'Informational',
          borderDash: [5, 5],
        },
      ];
      this.updated = true;
    },
  },
  async mounted() {
    this.since = Object.values(this.availableSince).includes(
      this.$route.query?.series
    )
      ? this.$route.query?.series
      : this.since;

    await this.getData();
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  &.is-empty {
    min-height: 300px;
  }
}

.is-updating {
  @include transition(opacity);
  opacity: 0.7;
  pointer-events: none;
}

.status-count-container {
  @include grid-wrap(248px);
}
</style>
