<template>
  <div class="scroll-vertical p-2 related-files">
    <transition-group name="list" tag="div" class="g-rows max-c">
      <Card v-for="(file, index) in files" :key="index" class="spacer g-col-2">
        <div class="col-span-1 g-rows">
          <ListItem title="Path">
            {{ file.path }}
          </ListItem>
          <ListItem title="Download From">
            <div class="g-rows">
              <span v-for="file in file.downloadedFrom" :key="file">
                {{ file }}
              </span>
            </div>
          </ListItem>
          <HashItem :sha1="file.sha1hex" :sha256="file.sha256hex" />
          <AlertSigningInfo
            :signing-info="file.signingInfo"
            :related="related"
          />
          <hr />
        </div>
        <ListItem title="File Size">
          {{ bytesToSize(file.size) }}
        </ListItem>
        <ListItem title="User">
          {{ relatedUser(file.uid) }}
        </ListItem>
        <ListItem title="Group">
          {{ relatedGroup(file.gid) }}
        </ListItem>
        <ListItem title="File Accessed Time">
          {{ formatEpochDate(file.accessed) }}
        </ListItem>
        <ListItem title="File Changed Time">
          {{ formatEpochDate(file.changed) }}
        </ListItem>
        <ListItem title="File Created Time">
          {{ formatEpochDate(file.created) }}
        </ListItem>
        <ListItem title="File Modified Time">
          {{ formatEpochDate(file.modified) }}
        </ListItem>
        <ListItem v-if="file.xattrs && file.xattrs.length > 0" title="XAttrs">
          <div class="vertical">
            <div v-for="attr in file.xattrs" :key="attr" class="attr">
              {{ attr }}
            </div>
          </div>
        </ListItem>
      </Card>
      <NoRelatedInfo v-if="!files.length" title="0 Files" :key="'0-empty'" />
    </transition-group>
  </div>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
import AlertSigningInfo from '@/modules/alerts/components/AlertSigningInfo.vue';
import { useGmt } from '@/composables/gmt';
import Card from '@/components/Card.vue';
import HashItem from './HashItem.vue';
import NoRelatedInfo from './NoRelatedInfo.vue';
import { bytesToSize } from '@/util';
import { useAlerts } from '@/modules/alerts/alerts.js';

export default {
  name: 'AlertRelatedFiles',
  compatConfig: {
    MODE: 3,
  },
  setup(props) {
    const { formatEpochDate } = useGmt();
    const { relatedUser, relatedGroup } = useAlerts(props);

    return { formatEpochDate, relatedUser, relatedGroup };
  },
  components: {
    ListItem,
    AlertSigningInfo,
    Card,
    HashItem,
    NoRelatedInfo,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bytesToSize,
  },
};
</script>
