/*
  eslint
  no-param-reassign: ["error", { "props": false }]
  no-console: "off"
*/
import admin from '@/modules/administrative/admin/store/admin.store';
import LIST_ANALYTICS from './queries/listAnalytics.gql';
import GET_ANALYTIC from './queries/getAnalytic.gql';

const AdminStoreModule = {
  namespaced: true,
  modules: {
    admin,
  },
  state: {
    analytic: null,
  },
  getters: {},
  mutations: {
    SET_ANALYTIC(state, payload) {
      state.analytic = payload;
    },
  },
  actions: {
    async listAnalytics({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_ANALYTICS,
          variables: {},
          key: 'listAnalytics',
        },
        { root: true }
      );
      return result;
    },
    async getAnalytic({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: GET_ANALYTIC,
          variables: payload,
          key: 'getAnalytic',
        },
        { root: true }
      );
      commit('SET_ANALYTIC', result);
      return result;
    },
  },
};

export default AdminStoreModule;
