<template>
  <Tabs v-if="apiClient" :tabs="tabs">
    <router-view :api-client="apiClient" />
  </Tabs>
</template>

<script>
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'ApiClient',
  components: { Tabs },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.API_CLIENT);
    return { canWrite };
  },
  data() {
    return {
      apiClient: null,
      tabs: [
        {
          label: 'Summary',
          route: {
            name: 'apiClients.index.detail',
            params: { clientId: this.$route.params.clientId },
          },
        },
      ],
    };
  },
  async beforeMount() {
    if (this.canWrite) {
      this.tabs = [
        {
          label: 'Summary',
          route: {
            name: 'apiClients.index.detail',
            params: { clientId: this.$route.params.clientId },
          },
        },
        {
          label: 'Edit',
          route: {
            name: 'apiClients.index.detail.edit',
            params: { clientId: this.$route.params.clientId },
          },
        },
        {
          label: 'Delete',
          route: {
            name: 'apiClients.index.detail.delete',
            params: { clientId: this.$route.params.clientId },
          },
        },
      ];
    }
    this.apiClient = await this.$store.dispatch('primary/getApiClient', {
      clientId: this.$route.params.clientId,
    });
  },
};
</script>
