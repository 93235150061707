/**
 * @description truncate text and add '...'
 * @param {string} value string to truncate
 * @param {number} length max character length before truncation
 * @returns {string}
 */
export const truncate = (value, length = 10) =>
  value.length > length ? `${value.substring(0, length)}...` : value;

export default truncate;
