<template>
  <div class="g-rows">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Detection">
      {{ event.detection }}
    </ListItem>
    <ListItem title="Message">
      {{ event.composedMessage }}
    </ListItem>
    <ListItem title="Process">
      {{ event.process }}
    </ListItem>
    <ListItem title="Process ID">
      {{ event.processIdentifier }}
    </ListItem>
    <ListItem title="Process Path">
      {{ event.processImagePath }}
    </ListItem>
    <ListItem title="Sender">
      {{ event.sender }}
    </ListItem>
    <ListItem title="Subsystem">
      {{ event.subsystem }}
    </ListItem>
    <ListItem title="Category" img-name="category">
      {{ event.sender }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPMRTEvent',
  inject: ['$typeDefs'],
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      def: this.$typeDefs.events.GPMRTEvent,
    };
  },
};
</script>
