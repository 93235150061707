export const EVENT_TYPES = {
  FILE_SYSTEM: 'GPFSEvent',
  DOWNLOAD: 'GPDownloadEvent',
  PROCESS: 'GPProcessEvent',
  SCREENSHOT: 'GPScreenshotEvent',
  CLICK: 'GPClickEvent',
  USB: 'GPUSBEvent',
  MRT: 'GPMRTEvent',
  GATEKEEPER: 'GPGatekeeperEvent',
  KEY_LOG: 'GPKeylogRegisterEvent',
  PREVENTION: 'GPPreventedExecutionEvent',
  THREAT: 'GPThreatMatchExecEvent',
  MOUNT: 'auth-mount',
  USB_STORAGE_EVENT: 'USBStorageEvent',
};

export const EVENT_TYPES_NAMES_EXCEPTIONS = Object.freeze({
  [EVENT_TYPES.FILE_SYSTEM]: 'File System Event',
  [EVENT_TYPES.DOWNLOAD]: 'Download Event',
  [EVENT_TYPES.PROCESS]: 'Process Event',
  [EVENT_TYPES.SCREENSHOT]: 'Screenshot Event',
  [EVENT_TYPES.KEY_LOG]: 'Keylog Register Event',
  [EVENT_TYPES.CLICK]: 'Synthetic Click Event',
});

export const EVENT_TYPES_NAMES_DEFAULT = Object.freeze({
  ...EVENT_TYPES_NAMES_EXCEPTIONS,
  [EVENT_TYPES.MRT]: 'Malware Removal Tool Event',
  [EVENT_TYPES.USB]: 'USB Event',
  [EVENT_TYPES.GATEKEEPER]: 'Gatekeeper Event',
});

export const EVENT_TYPES_NAMES_ALL = {
  ...EVENT_TYPES_NAMES_DEFAULT,
  [EVENT_TYPES.THREAT]: 'Threat Event',
  [EVENT_TYPES.PREVENTION]: 'Prevention Event',
  [EVENT_TYPES.MOUNT]: 'Mount Event',
  [EVENT_TYPES.USB_STORAGE_EVENT]: 'USB Storage Event',
};
