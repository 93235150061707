import LIST_TELEMETRIES from './queries/listTelemetries.gql';
import GET_TELEMETRY from './queries/getTelemetry.gql';
import CREATE_TELEMETRY from './mutations/createTelemetry.gql';
import UPDATE_TELEMETRY from './mutations/updateTelemetry.gql';
import DELETE_TELEMETRY from './mutations/deleteTelemetry.gql';

export default {
  state: {
    telemetry: null,
    telemetries: null,
    telemetryNames: [],
  },
  mutations: {
    SET_TELEMETRY(state, payload) {
      state.telemetry = payload;
    },
    SET_TELEMETRIES(state, payload) {
      state.telemetries = payload.items;
      state.telemetryNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    // mutations
    async createTelemetry({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_TELEMETRY,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_TELEMETRY', result.data.createTelemetry);
        return result.data.createTelemetry;
      }
      return result;
    },
    async updateTelemetry({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_TELEMETRY,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_TELEMETRY', result.data.updateTelemetry);
        return result.data.updateTelemetry;
      }
      return result;
    },
    async deleteTelemetry({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_TELEMETRY,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_TELEMETRY', null);
        return result.data.deleteTelemetry;
      }
      return result;
    },
    // queries
    async listTelemetries({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_TELEMETRIES,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_TELEMETRIES', result.data.listTelemetries);
        return result.data.listTelemetries;
      }
      return result;
    },
    async getTelemetry({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_TELEMETRY,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_TELEMETRY', result.data.getTelemetry);
        return result.data.getTelemetry;
      }
      return result;
    },
  },
};
