import GET_ANALYTIC_SET from './queries/getAnalyticSet.gql';
import LIST_ANALYTIC_SETS from './queries/listAnalyticSets.gql';
import CREATE_ANALYTIC_SET from './mutations/createAnalyticSet.gql';
import UPDATE_ANALYTIC_SET from './mutations/updateAnalyticSet.gql';
import DELETE_ANALYTIC_SET from './mutations/deleteAnalyticSet.gql';

export default {
  state: {
    analyticSets: [],
    managedAnalyticSets: null,
    analyticSet: {},
  },
  mutations: {
    SET_ANALYTIC_SET(state, payload) {
      state.analyticSet = payload;
    },
    SET_ANALYTIC_SETS(state, payload) {
      state.analyticSets = payload;
      state.analyticSetsNames = payload?.map(({ name }) => name);
    },
    SET_MANAGED_ANALYTIC_SETS(state, payload) {
      state.managedAnalyticSets = payload;
    },
  },
  actions: {
    // mutations
    async createAnalyticSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_ANALYTIC_SET,
          variables: payload,
          key: 'createAnalyticSet',
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC_SET', result);
      }
      return result;
    },
    async updateAnalyticSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ANALYTIC_SET,
          variables: payload,
          key: 'updateAnalyticSet',
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC_SET', result);
      }
      return result;
    },
    async deleteAnalyticSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_ANALYTIC_SET,
          variables: payload,
          key: 'deleteAnalyticSet',
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC_SET', null);
      }
      return result;
    },
    // queries
    async getAnalyticSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_ANALYTIC_SET,
          variables: payload,
          key: 'getAnalyticSet',
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC_SET', result);
      }
      return result;
    },
    async listAnalyticSets({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTIC_SETS,
          variables: payload,
          key: 'listAnalyticSets',
        },
        { root: true }
      );
      if (result) {
        commit('SET_ANALYTIC_SETS', result.items);
      }
      return result;
    },
    async listManagedAnalyticSets({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_ANALYTIC_SETS,
          variables: {
            ...payload,
            filter: { ...(payload.filter ?? {}), managed: { equals: true } },
          },
          key: 'listAnalyticSets',
        },
        { root: true }
      );
      if (result) {
        commit('SET_MANAGED_ANALYTIC_SETS', result.items);
      }
      return result;
    },
  },
};
