<template>
  <div class="gp-content" v-if="dataSettings">
    <j-form-bar
      title="Edit Data Forwarding"
      v-if="canWrite"
      :can-write="canWrite"
      data-feature-id="data-forwarding-formbar"
      @submit="resetAndHandleSubmit"
    />
    <form class="data-form scroll-vertical" @input="v$.reset">
      <j-notification
        :hide="!saveErrorMessage"
        type="danger"
        data-feature-id="error-notification"
      >
        {{ saveErrorMessage }}
      </j-notification>
      <div class="enable-column">
        <j-switch v-model="s3.enabled" :is-read-only="!canWrite" />
        <div :class="{ 'is-disabled': !s3.enabled }">
          <div class="section-label">
            <h2>Amazon S3 Forwarding</h2>
            <p>
              Allow Jamf Protect to send data to an Amazon S3 Bucket. Download
              an
              <a
                @click="downloadCloudFormation"
                data-feature-id="cloud-formation-download"
                >AWS CloudFormation template</a
              >
              to create an S3 bucket and IAM role. <br />
              See the
              <a
                href="https://docs.jamf.com/jamf-protect/administrator-guide/Data_Forwarding_to_a_Third_Party_Storage_Solution.html"
                target="blank"
                rel="noopener"
              >
                documentation</a
              >
              for more information.
            </p>
          </div>
          <j-checkbox v-model="s3.encrypted" :is-read-only="!canWrite">
            Encrypt Forwarded Data
          </j-checkbox>
          <div class="group">
            <j-input
              v-if="canWrite"
              data-feature-id="bucket-name"
              v-model.trim="s3.bucket"
              label="Amazon S3 Bucket Name"
              :has-error="v$.s3.bucket.$error"
              :error-text="
                s3.bucket.length > 0
                  ? 'Invalid Amazon S3 Bucket Name'
                  : 'Amazon S3 Bucket Name is required'
              "
            />
            <template v-else>{{ s3.bucket || 'N/A' }}</template>

            <j-input
              v-if="canWrite"
              v-model.trim="s3.prefix"
              label="Prefix"
              data-feature-id="prefix"
              helper-text="A Prefix for Jamf Protect data objects in your S3 Bucket"
            />
            <template v-else>{{ s3.prefix || 'N/A' }}</template>
          </div>

          <j-input
            v-if="canWrite"
            v-model.trim="s3.role"
            data-feature-id="s3-role"
            label="IAM Role"
            helper-text="Amazon Resource Name (ARN) of the role assumed by Jamf Protect"
            :has-error="v$.s3.role.$error"
            :error-text="
              s3.role.length > 0 ? 'Invalid IAM Role' : 'IAM Role is required'
            "
          />
          <template v-else>{{ s3.role || 'N/A' }}</template>
          <div class="section-label">
            <h3 class="h5">External ID</h3>
            <p class="helper-text">
              A unique identifier for the tenant, included by Jamf Protect in
              the request to assume the role used to write data to the S3
              bucket. To use External Id as an additional layer of verification,
              include a condition element in that role's trust policy.
            </p>
          </div>

          <Card
            data-feature-id="external-id"
            v-if="organizationUuid"
            class="w-max-c"
          >
            <p>{{ organizationUuid }}</p>
          </Card>
        </div>
      </div>
      <div class="enable-column">
        <j-switch v-model="sentinel.enabled" :is-read-only="!canWrite" />
        <div :class="{ 'is-disabled': !sentinel.enabled }">
          <div class="section-label">
            <h2>Microsoft Sentinel Forwarding</h2>
            <p>
              Allow Jamf Protect to send data to Microsoft Sentinel. Configure
              the
              <a
                href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/jamfsoftwareaustraliaptyltd1620360395539.jamf_protect"
                target="blank"
                rel="noopener"
              >
                Microsoft Sentinel integration</a
              >
              for enhanced workflows and visibility. <br />
              See the
              <a
                href="https://docs.jamf.com/jamf-protect/administrator-guide/Data_Forwarding_to_a_Third_Party_Storage_Solution.html"
                target="blank"
                rel="noopener"
              >
                documentation</a
              >
              for more information.
            </p>
          </div>
          <div class="group">
            <j-input
              v-if="canWrite"
              v-model.trim="sentinel.customerId"
              data-feature-id="workspace-id"
              label="Workspace ID"
              :has-error="v$.sentinel.customerId.$error"
              :error-text="
                sentinel.customerId.length === 0
                  ? 'Workspace ID is required'
                  : saveErrorMessage
              "
            />
            <template v-else>{{ sentinel.customerId || 'N/A' }}</template>

            <j-input
              v-if="canWrite"
              v-model.trim="sentinel.sharedKey"
              data-feature-id="client-auth-key"
              label="Client Authentication Key"
              helper-text="Primary or the secondary Connected Sources client authentication key"
              :has-error="v$.sentinel.sharedKey.$error"
              :error-text="
                sentinel.sharedKey.length === 0
                  ? 'Client Authentication Key is required'
                  : saveErrorMessage
              "
            />
            <template v-else>{{ sentinel.sharedKey || 'N/A' }}</template>
          </div>
          <j-input
            v-if="canWrite"
            v-model.trim="sentinel.logType"
            data-feature-id="log-type"
            label="Log Type"
            helper-text="The log type is the name of the event that is being submitted"
            :has-error="v$.sentinel.logType.$error"
            :error-text="
              sentinel.logType.length === 0
                ? 'Log Type is required'
                : saveErrorMessage
            "
          />
          <template v-else>{{ sentinel.logType || 'N/A' }}</template>
          <j-select
            v-model="sentinel.domain"
            :options="sentinelDomainOptions"
            :has-error="v$.sentinel.domain.$error"
            :is-read-only="!canWrite"
            :searchable="false"
            label="Azure Domain"
            helper-text="The Azure domain to send data to"
            class="query-select"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { downloadFromUrl } from '@/util';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Card from '@/components/Card.vue';
import { useRBAC } from '@/composables/rbac';
import { useForm } from '@/composables/forms';
import { requiredIf } from '@vuelidate/validators';

export default {
  name: 'DataForward',
  components: {
    Card,
  },
  setup() {
    requiredIf;
    const { v$, handleSubmit } = useForm();
    const { canWrite } = useRBAC([RBAC_RESOURCE.DATA_FORWARD]);

    return { v$, canWrite, handleSubmit };
  },
  data() {
    return {
      saveErrorMessage: '',
      s3: {
        encrypted: true,
      },
      sentinel: null,
      sentinelDomainOptions: [
        { label: 'azure.com', value: 'azure.com' },
        { label: 'azure.us', value: 'azure.us' },
      ],
    };
  },
  validations() {
    const validator = (enabled, message = 'test file') => {
      return {
        required: requiredIf(enabled),
        saveError() {
          return !(this.saveErrorMessage.includes(message) && enabled);
        },
      };
    };
    return {
      s3: {
        bucket: validator(this.s3?.enabled, 'Could not copy file'),
        role: validator(this.s3?.enabled, 'Could not assume role'),
      },
      sentinel: {
        customerId: validator(this.sentinel?.enabled),
        sharedKey: validator(this.sentinel?.enabled),
        logType: validator(this.sentinel?.enabled),
        domain: validator(this.sentinel?.enabled),
      },
    };
  },
  computed: {
    ...mapState('primary', {
      dataSettings: (state) => state.data.forward,
      organizationUuid: (state) => state.data.organizationUuid,
    }),
  },
  beforeMount() {
    this.s3 = JSON.parse(JSON.stringify(this.dataSettings.s3));
    this.sentinel = JSON.parse(JSON.stringify(this.dataSettings.sentinel));
    delete this.s3.cloudformation;
    if (this.sentinel.logType === '') {
      this.sentinel.logType = 'jamfprotect';
    }
  },
  methods: {
    async downloadCloudFormation() {
      const blob = new Blob([this.dataSettings.s3.cloudformation], {
        type: 'text/yaml',
      });
      const filename = 'JamfProtect-S3-Forwarding-Bucket-CloudFormation'.concat(
        '.yml'
      );
      downloadFromUrl(window.URL.createObjectURL(blob), filename);
    },
    resetAndHandleSubmit() {
      this.saveErrorMessage = '';
      this.handleSubmit();
    },
    async submit() {
      try {
        await this.$store.dispatch('primary/updateOrganizationForward', {
          s3: this.s3,
          sentinel: this.sentinel,
        });
      } catch (error) {
        const alphaNumericalErrorMessage = `input → s3 → bucket:`;
        this.saveErrorMessage = error.message;
        if (this.saveErrorMessage.includes(alphaNumericalErrorMessage)) {
          this.saveErrorMessage =
            's3 forwarding values name must only contain alphanumericals or select special characters (hypens or colon). All letters must also be lowercase.';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-form {
  @include grid;
  @include grid-rows(min-content);
  padding: var(--size-grid-gap-base);

  h2 {
    @include header4;
  }

  p {
    @include paragraph;
  }

  .enable-column {
    @include grid;
    @include grid-columns(min-content auto);
    align-items: flex-start;
    > div {
      @include grid;
    }
  }

  .is-disabled > *:not(:first-child):not(.notification-container) {
    opacity: var(--opacity-disabled);
    pointer-events: none;
  }

  .download {
    @include grid(spacing());
    @include grid-columns(24px auto);
    align-items: center;
    padding-bottom: spacing(2);
  }

  .group {
    @include grid;
    @include grid-columns;
  }
}
</style>
