<template>
  <NoResultsOverlay :message="message" :title="title">
    <p v-if="storeGlobalErrorMessage" class="g-cols ai-center gap-1">
      <j-icon data="@jcon/error.svg" color="var(--color-danger-base)" />
      <q
        ><strong>{{ storeGlobalErrorMessage }}</strong></q
      >
    </p>
  </NoResultsOverlay>
</template>

<script>
import NoResultsOverlay from '@/components/NoResultsOverlay.vue';
import { mapState } from 'vuex';

export default {
  name: 'NoRowsOverlay',
  props: ['params'],
  components: { NoResultsOverlay },
  computed: {
    ...mapState(['globalErrorMessage']),
  },
  data() {
    return {
      message: "Try adjusting the filters to find what you're looking for.",
      title: 'No Results',
      storeGlobalErrorMessage: null,
    };
  },
  mounted() {
    if (this.params.hasError) {
      this.message =
        'There was potentially an error with one of the filters applied. ';
      this.title = 'An Error occurred';
      this.storeGlobalErrorMessage = this.globalErrorMessage;
    }
  },
};
</script>
