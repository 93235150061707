<template>
  <div></div>
</template>

<script>
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/rubyblue.css';
import 'codemirror/addon/fold/foldgutter.css';

const CodeMirror = require('codemirror/lib/codemirror.js');
require('codemirror/mode/javascript/javascript.js');
require('codemirror/addon/fold/foldcode.js');
require('codemirror/addon/fold/foldgutter.js');
require('codemirror/addon/fold/brace-fold');
require('codemirror/addon/fold/comment-fold');

export default {
  name: 'TextEditor',
  props: {
    text: {
      type: String,
      required: true,
    },
    lineNumbers: {
      type: Boolean,
      default: true,
    },
    foldCodeEnabled: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:text'],
  data() {
    return {
      options: {
        mode: 'javascript',
        lineNumbers: this.lineNumbers,
        lineWrapping: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        theme: 'rubyblue',
        viewportMargin: Infinity,
        readOnly: this.readOnly,
        extraKeys: { 'Ctrl-Q': (cm) => cm.foldCode(cm.getCursor()) },
      },
      code: '',
    };
  },
  watch: {
    text() {
      this.editor.setValue(this.text);
    },
  },
  mounted() {
    this.editor = CodeMirror(this.$el, this.options);
    this.editor.setValue(this.text);
    const vm = this;
    this.editor.on('change', (cm) => {
      vm.code = cm.getValue();
      vm.$emit('update:text', vm.code);
    });
    if (this.foldCodeEnabled) {
      this.editor.setOption('foldGutter', true);
      this.editor.setOption('gutters', [
        'CodeMirror-linenumbers',
        'CodeMirror-foldgutter',
      ]);
    }
  },
  methods: {
    onCmCodeChange(newCode) {
      this.code = newCode;
    },
  },
};
</script>

<style lang="scss">
.CodeMirror {
  height: 100%;
}
</style>
