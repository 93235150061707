import stripTypenames from './strip-typename';

const removeTypenameLink =
  // eslint-disable-next-line consistent-return
  (operation, forward) => {
    if (operation.variables) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = stripTypenames(operation.variables);
      return forward ? forward(operation) : null;
    }
  };

export default removeTypenameLink;
