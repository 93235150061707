<template>
  <div class="g-col-2 mt-1">
    <ListItem
      v-if="displayField('Process Arguments') && process?.args?.length > 0"
      class="col-span-1"
      title="Process Arguments"
    >
      {{ process.args.join(' ') }}
    </ListItem>
    <AlertSigningInfo
      class="col-span-1"
      v-if="displayField('Signing Info') && process?.signingInfo"
      :signing-info="process?.signingInfo"
      :related="related"
    />
    <hr class="col-span-1" />
    <ListItem v-if="displayField('Path')" title="Path">
      {{ process?.path }}
    </ListItem>
    <ListItem v-if="displayField('Process UUID')" title="Process UUID">
      {{ process?.uuid }}
    </ListItem>
    <ListItem v-if="displayField('Pid')" title="Pid">
      {{ process?.pid }}
    </ListItem>
    <ListItem v-if="displayField('Name')" title="Name">
      {{ process?.name }}
    </ListItem>
    <ListItem v-if="displayField('User')" title="User">
      {{ relatedUser(process?.uid) }}
    </ListItem>
    <ListItem v-if="displayField('Group')" title="Group">
      {{ relatedGroup(process?.gid) }}
    </ListItem>
    <ListItem
      v-if="displayField('Process Start Time')"
      title="Process Start Time"
    >
      {{ formatEpochDate(process?.startTimestamp) }}
    </ListItem>
    <ListItem
      v-if="displayField('Process End Time') && process?.endTimestamp"
      title="Process End Time"
    >
      {{ formatEpochDate(process?.endTimestamp) }}
    </ListItem>
    <ListItem
      v-if="displayField('Parent Process') && process?.ppid"
      title="Parent Process"
    >
      {{ relatedProcess(process?.ppid) }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import AlertSigningInfo from '@/modules/alerts/components/AlertSigningInfo.vue';
import ListItem from '@/components/ListItem.vue';
import { useAlerts } from '@/modules/alerts/alerts.js';

export default {
  name: 'AlertProcess',
  inject: ['$typeDefs'],
  components: {
    AlertSigningInfo,
    ListItem,
  },
  compatConfig: {
    MODE: 3,
  },
  setup(props) {
    const { formatEpochDate } = useGmt();
    const { relatedUser, relatedGroup, relatedProcess } = useAlerts(props);

    return { formatEpochDate, relatedUser, relatedGroup, relatedProcess };
  },
  props: {
    process: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [
        'Process Start Time',
        'Process End Time',
        'User',
        'Group',
        'Pid',
        'Path',
        'Process Arguments',
        'Process Path',
        'Name',
        'Signing Info',
        'Parent Process',
        'Process UUID',
      ],
    },
    // eslint-disable-next-line vue/prop-name-casing
    exclude_fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defs: this.$typeDefs,
    };
  },
  methods: {
    displayField(field) {
      return (
        this.fields.includes(field) && !this.exclude_fields.includes(field)
      );
    },
  },
};
</script>
