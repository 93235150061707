<template>
  <div class="bar" :class="{ 'is-disabled': !enabled }">
    <div class="fail" :style="{ width: failPercentage }">
      <span class="count">{{ fail }}</span>
      <span class="scale">{{ fail }}</span>
    </div>
    <div class="pass" :style="{ width: passPercentage }">
      <span class="count">{{ pass }}</span>
      <span class="scale">{{ pass }}</span>
    </div>
    <div class="none" :style="{ width: nonePercentage }">
      <span class="count">{{ none }}</span>
      <span class="scale">{{ none }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComplianceGraph',
  props: ['enabled', 'none', 'fail', 'pass'],
  computed: {
    failPercentage() {
      return this.getPercentage(this.fail);
    },
    passPercentage() {
      return this.getPercentage(this.pass);
    },
    nonePercentage() {
      return this.getPercentage(this.none);
    },
    total() {
      return this.none + this.pass + this.fail;
    },
  },
  methods: {
    getPercentage(value) {
      return `${Math.round((value / this.total) * 100)}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-fail: var(--color-warning-base);
$color-pass: var(--color-success-base);
$color-none: var(--color-font-base);
$color-disabled: var(--color-input-border-base);

.bar {
  display: flex;
  align-items: flex-end;
  position: relative;
  font-size: 12px;

  > div {
    @include transition(color background-color border-color);
    height: 16px;
    position: relative;
    min-width: max-content;
    width: 100%;

    &:not(:last-child) {
      margin-right: 4px;
    }

    > .count {
      position: absolute;
      top: -23px;
      right: 0;
      font-weight: 500;
    }

    .scale {
      opacity: 0;
    }
  }

  .fail {
    background: $color-fail;
    border: 1px solid $color-fail;
    color: $color-fail;
  }
  .pass {
    background: $color-pass;
    border: 1px solid $color-pass;
    color: $color-pass;
  }
  .none {
    border: 1px solid $color-none;
    color: $color-none;
  }

  &.is-disabled {
    .pass,
    .fail,
    .none {
      background-color: $color-disabled;
      border-color: $color-disabled;
      color: $color-disabled;
    }
  }
}
</style>
