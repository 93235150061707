import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

const config = {
  rounding: (value) => Math.floor(value),
};

dayjs.extend(relativeTime, config);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(timezone);

export const secondsToString = (value) => {
  if (value) {
    return dayjs.duration(value, 'seconds').humanize();
  }
  return '';
};

export const fromDate = (date) => dayjs().from(dayjs(date));

export const secondsToMinutes = (value) =>
  dayjs.duration(value, 'seconds').asMinutes();

export const minutesToSeconds = (value) =>
  dayjs.duration(value, 'minutes').asSeconds();

export const convertToISODate = (value, dateFormat, gmt) =>
  gmt
    ? dayjs.utc(value, dateFormat).format()
    : dayjs(value, dateFormat).format();

export const setISODateToStartOfDay = (value, timezone = undefined) =>
  value ? dayjs(value).tz(timezone).startOf('day').format() : null;

export const setISODateToEndOfDay = (value, timezone = undefined) =>
  value ? dayjs(value).tz(timezone).endOf('day').format() : null;

export const dateGreaterThan = (date, amount, type = 'hours') => {
  const value = dayjs().diff(dayjs(date), type);
  return value >= amount;
};

export const dateIsValid = (value, strict = false) =>
  dayjs(value, 'YYYY-MM-DD', strict).isValid();

export const ariaDate = (value, gmt, includeHour) => {
  const format = includeHour ? 'MMMM Do, h:mm A' : 'MMMM Do';
  return gmt ? dayjs(value).utc().format(format) : dayjs(value).format(format);
};

export const getTimeZone = (gmt) => {
  if (gmt) {
    return 'GMT';
  }
  const tz = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ');

  return tz[tz.length - 1] || 'GMT';
};

export const formatEpochDate = (value, dateFormat, gmt, analog = true) => {
  const format = `${dateFormat} ${analog ? 'h' : 'HH'}:mm ${
    analog ? 'A ' : ''
  }`;
  let tsValue = value;
  if (tsValue) {
    if (typeof tsValue === 'number') {
      tsValue = value * 1000;
    }

    const ts = dayjs(tsValue).utc();

    if (gmt) {
      return `${ts.format(format)}GMT`;
    }
    return `${ts.local().format(format)}${getTimeZone(false)}`;
  }
  return '';
};

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const ALLOWED_DATE_FORMATS = [
  DEFAULT_DATE_FORMAT,
  'DD/MM/YYYY',
  'YYYY/MM/DD',
];
export const DATETIME_WITH_TZ_RE =
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.{1,3}\d{2}:\d{2}/g;
