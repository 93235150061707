<template>
  <div class="g-rows max-c">
    <div class="f-wrap max-c">
      <j-select
        :label="labelPermissions"
        :is-read-only="isReadOnly"
        v-model="mountActionComputed"
        :options="mountOptions"
        :data-feature-id="`${featureId}-mount-action`"
        :searchable="false"
      />
      <j-select
        v-if="!isEncryption && !isDefault"
        label="Apply to"
        v-model="applyToComputed"
        :is-read-only="isReadOnly"
        :options="encryptionOptions"
        :data-feature-id="`${featureId}-apply-to`"
        :searchable="false"
      />
    </div>
    <j-textarea
      v-if="hasActionMessage"
      :label="labelNotificaion"
      :rows="2"
      :helper-text="messageActionHelpers.helperText"
      :is-read-only="isReadOnly"
      v-model="messageActionComputed"
      :data-feature-id="`${featureId}-message-action`"
      :has-error="v$.messageActionComputed.$error"
      :error-text="messageActionHelpers.errorText"
    />
  </div>
</template>

<script>
import { sleep } from '@/util';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import {
  USBCONTROL_ENCRYPTION,
  USBCONTROL_MOUNT_ACTIONS,
  USBCONTROL_MOUNT_ACTIONS_NAMES,
  USBCONTROL_ACTION_MESSAGE,
} from '../usb-controls.types';

export default {
  name: 'DeviceRules',
  compatConfig: {
    MODE: 3,
  },
  emits: ['update:mountAction', 'update:applyTo', 'update:messageAction'],
  props: {
    isEncryption: Boolean,
    isDefault: Boolean,
    isReadOnly: Boolean,
    mountAction: String,
    applyTo: String,
    messageAction: String,
  },
  setup() {
    const v$ = useVuelidate();

    return { v$ };
  },
  data() {
    return {
      encryptionOptions: Object.values(USBCONTROL_ENCRYPTION),
      mountOptions: Object.entries(USBCONTROL_MOUNT_ACTIONS_NAMES).map(
        ([value, label]) => ({
          label,
          value,
        })
      ),
      cacheMessage: {
        [USBCONTROL_MOUNT_ACTIONS.Prevented]: '',
        [USBCONTROL_MOUNT_ACTIONS.ReadOnly]: '',
      },
      messageActionHelpers: {
        errorText: `Required for ${USBCONTROL_MOUNT_ACTIONS_NAMES.Prevented} and ${USBCONTROL_MOUNT_ACTIONS_NAMES.ReadOnly} permissions.`,
        helperText:
          'Enter a notification displayed to users when a removable storage device is restricted.',
      },
    };
  },
  validations() {
    return {
      messageActionComputed: {
        requiredIf: requiredIf(
          this.mountActionComputed !== USBCONTROL_MOUNT_ACTIONS.ReadWrite
        ),
      },
    };
  },
  computed: {
    mountActionComputed: {
      get() {
        return this.mountAction;
      },
      set(value) {
        this.$emit('update:mountAction', value);
      },
    },
    applyToComputed: {
      get() {
        return this.applyTo;
      },
      set(value) {
        this.$emit('update:applyTo', value);
      },
    },
    messageActionComputed: {
      get() {
        return this.messageAction;
      },
      set(value) {
        this.cacheMessage[this.mountActionComputed] = value;
        this.$emit('update:messageAction', value);
      },
    },
    labelPermissions() {
      return `${this.isDefault ? 'Default ' : ''}Permission`;
    },
    featureId() {
      return this.isDefault ? 'default' : 'rule';
    },
    labelNotificaion() {
      return `${this.isDefault ? 'Default ' : ''}Local Notification Message`;
    },
    hasActionMessage() {
      return this.mountActionComputed !== USBCONTROL_MOUNT_ACTIONS.ReadWrite;
    },
  },
  watch: {
    async mountActionComputed(value) {
      await sleep(100); // slight delay to ensure component is rendered first
      this.messageActionComputed =
        this.cacheMessage[value] || USBCONTROL_ACTION_MESSAGE[value];
    },
  },
};
</script>
