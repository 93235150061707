import gql from 'graphql-tag';
import { USBControlSetFields } from './usb-controls.fragment';

export const GET_USB_CONTROL_SET = (getPermissions) => gql`
  query getUSBControlSet($id: ID!) {
    getUSBControlSet(id: $id) {
      ...USBControlSetFields
    }
  }
  ${USBControlSetFields(getPermissions)}
`;

export const LIST_USB_CONTROL_SETS = (getPermissions) => gql`
  query listUSBControlSets(
    $nextToken: String
    $direction: OrderDirection!
    $field: USBControlOrderField!
  ) {
    listUSBControlSets(
      input: {
        next: $nextToken
        order: { direction: $direction, field: $field }
        pageSize: 100
      }
    ) {
      items {
        ...USBControlSetFields
      }
      pageInfo {
        next
        total
      }
    }
  }
  ${USBControlSetFields(getPermissions)}
`;
