/* eslint-disable camelcase */
import createAuth0Client from '@auth0/auth0-spa-js';

class AuthClient {
  client = null;

  redirect_uri = `${window.location.origin}/callback`;

  user = null;

  async getClient({ domain, client_id, audience }) {
    const options = {
      domain,
      client_id,
      cacheLocation:
        process.env.VUE_APP_SITE_ENV === 'test' ? 'localstorage' : 'memory',
      redirect_uri: this.redirect_uri,
      advancedOptions: {
        defaultScope: 'openid profile email',
      },
    };
    this.client = await createAuth0Client(
      audience
        ? {
            ...options,
            useRefreshTokens: true,
            audience,
          }
        : options
    );
  }

  async login({ connector, audience }) {
    const options = {
      connection: connector,
      redirect_uri: this.redirect_uri,
    };
    await this.client.loginWithRedirect(
      audience
        ? {
            ...options,
            audience,
          }
        : options
    );
  }

  logout({ client_id }) {
    this.client.logout({
      returnTo: window.location.origin,
      clientID: client_id,
    });
  }

  async getToken({ audience }) {
    let token;

    try {
      if (audience) {
        token = await this.client.getTokenSilently({ audience });
      } else {
        const getToken = await this.client.getIdTokenClaims();
        // eslint-disable-next-line no-underscore-dangle
        token = getToken.__raw;
        this.user = getToken;
      }
    } catch {
      token = null;
    }
    return token;
  }

  async getUser({ audience }) {
    return !audience ? this.user : this.client.getUser();
  }
}

export default new AuthClient();
