import Store from '@/store/store';

const AdminGuard = async (to, from, next) => {
  if (Store.getters['auth/isJamfAdmin']) {
    next();
  } else {
    next({ name: 'index' });
  }
};

export default AdminGuard;
