<template>
  <div
    class="j-table-wrapper"
    :class="{ 'use-calc': !useDomLayout }"
    ref="table"
  >
    <TableControlBar
      :suppress-search="suppressSearch"
      :suppress-pagination="suppressCount"
      :suppress-control-bar="suppressControlBar"
      :suppress-refresh-button="suppressRefreshButton"
      @refresh="refresh"
    >
      <template #search>
        <div class="table-filter-wrapper">
          <div class="table-filter-input-wrapper">
            <input
              v-if="columnApi && gridApi"
              class="table-filter-input"
              ref="searchInput"
              data-feature-id="search"
              v-model="filterInput"
              aria-label="Table search input"
              placeholder="Search all..."
              @input="($event) => quickFilter($event.target.value)"
            />
            <transition name="fade">
              <j-button
                v-show="filterInput && filterInput.length > 0"
                style-type="ghost"
                aria-label="Clear Search"
                data-feature-id="clear"
                class="table-filter-button"
                @click="clearQuickFilter"
              >
                <template #leading>
                  <j-icon data="@jcon/x-small.svg" />
                </template>
              </j-button>
            </transition>
          </div>
        </div>
      </template>
      <template #left>
        <slot
          name="left"
          :grid-api="gridApi"
          :column-api="columnApi"
          :selected="selected"
          :selected-nodes="selectedNodes"
        ></slot>
      </template>
      <template #right="{ isSmall }">
        <slot
          name="right"
          :grid-api="gridApi"
          :column-api="columnApi"
          :is-small="isSmall"
          :selected="selected"
          :selected-node="selectedNodes"
        ></slot>
      </template>
      <template #pagination>
        <span class="rows-count"
          >{{ count ? `1 - ${count}` : count }} of {{ total }}</span
        >
      </template>
    </TableControlBar>
    <!-- Filter Section -->
    <FilterTagBar
      v-if="hasFilters"
      :filters="internalFilters"
      @clear="clearFilter"
      @clear:all="clearAllFilters"
    />

    <ag-grid-vue
      :class="agGridClasses"
      :grid-options="gridOptions"
      :column-defs="internalColumns"
      @grid-ready="onGridReady"
    />
    <!-- @slot modals - Use for modals that need access to the gridApi -->
    <div class="g-rows">
      <slot
        name="modals"
        :grid-api="gridApi"
        :selected="selected"
        :selected-nodes="selectedNodes"
        :refresh="refresh"
      ></slot>
    </div>
  </div>
</template>

<script>
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import sharedTableComponents from './sharedTableComponents.mixin';
import { mapState } from 'vuex';
import { FILTER_SEPARATOR } from './utils/query-build-and-parse';
import { useJamfTable, sharedTableProps } from './table';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

export default {
  name: 'JamfTableClientSide',
  mixins: [sharedTableComponents],
  props: {
    ...sharedTableProps,
    data: {
      type: Array,
      required: true,
    },
    useDomLayout:
      Boolean /** Causes issues when used in conjunction with row groups, use caution */,
    suppressCount: Boolean,
  },
  emits: ['refresh', 'grid-ready', 'first-data-rendered', 'selection-changed'],
  setup(props, ctx) {
    const {
      state,
      agGridClasses,
      hasFilters,
      internalColumns,
      internalFilters,
      gridApi,
      columnApi,
      selected,
      selectedNodes,
      refreshKey,
      clearAllFilters,
      clearFilter,
      setFilters,
      filterChanged,
      setInternalFilters,
      setInitialSortFromQuery,
      defaultMountSetup,
      defaultGridReadySetup,
      forceRecompute,
      setInternalColumns,
    } = useJamfTable(props, ctx);

    return {
      ...state,
      agGridClasses,
      hasFilters,
      internalColumns,
      internalFilters,
      gridApi,
      columnApi,
      selected,
      selectedNodes,
      refreshKey,
      clearAllFilters,
      clearFilter,
      setFilters,
      filterChanged,
      setInternalFilters,
      setInitialSortFromQuery,
      defaultMountSetup,
      defaultGridReadySetup,
      forceRecompute,
      setInternalColumns,
    };
  },
  computed: {
    ...mapState({
      cachedQuickFilter: (state) => state.app.cachedQuickFilter,
    }),
    count() {
      this.refreshKey;
      let count = 0;
      this.gridApi?.forEachNodeAfterFilter((node) => {
        // ensure groups are not counted
        if (!node.group) {
          count += 1;
        }
      });
      return count;
    },
    total() {
      this.forceRecompute();
      return this.data?.length;
    },
    allGroupsExpanded() {
      return this.$route.query?.expanded === 'all';
    },
  },
  watch: {
    cachedQuickFilter(value) {
      this.filterInput = value;
      this.forceRecompute();
    },
    data: {
      handler(val) {
        this.gridApi?.setRowData(val);
      },
      deep: true,
    },
  },
  created() {
    this.setInternalColumns();
    this.gridOptions = {
      ...this.gridOptions,
      domLayout: this.useDomLayout ? 'autoHeight' : null,
      cacheQuickFilter: true,
      onColumnRowGroupChanged: (event) => this.rowGroupChanged(event),
      onRowGroupOpened: (event) => this.expandOrCollapseGroups(event),
      onExpandOrCollapseAll: (event) => this.expandOrCollapseGroups(event),
      onFirstDataRendered: this.onFirstDataRendered,
    };
    this.forceRecompute();
  },
  mounted() {
    this.defaultMountSetup();
    if (!this.useDomLayout) {
      this.$refs?.table?.style.setProperty(
        '--table-calc-height',
        this.$refs?.table?.parentElement?.clientHeight + 'px'
      );
    }
  },
  methods: {
    async onGridReady(params) {
      this.defaultGridReadySetup(params);
      this.gridApi.setRowData(this.data);
      this.gridApi.showLoadingOverlay();
      if (this.$route?.query && !this.suppressQueryParams) {
        await this.setFilters(this.$route.query);
        this.setInitialSortFromQuery(params);
      }
      if (this.data?.length === 0) {
        this.toggleNoRowsOverlay();
      }
      this.$emit('grid-ready', params);
    },
    async onFirstDataRendered(params) {
      if (this.$route?.query && !this.suppressQueryParams) {
        this.setInitialRowGroupsExpanded();
      }
      this.toggleNoRowsOverlay();
      this.$emit('first-data-rendered', params);
    },
    setInitialRowGroupsExpanded() {
      if (this.$route?.query?.expanded && !this.allGroupsExpanded) {
        const individualExpandedGroups = this.$route.query?.expanded?.split(
          FILTER_SEPARATOR.MAIN
        );
        this.gridApi.forEachNode((node) => {
          if (node.group && individualExpandedGroups?.includes(node.key)) {
            node.setExpanded(true);
          }
        });
      }
    },
    async rowGroupChanged(event) {
      const groups = event.columns
        .map(({ colId }) => colId)
        .join(FILTER_SEPARATOR.MAIN);

      // keep all expanded if set
      if (this.allGroupsExpanded) {
        this.gridApi.expandAll();
      } else {
        this.gridApi.collapseAll();
      }

      try {
        const query = {
          ...this.$route.query,
          groups,
        };
        await this.$router.replace({ query });
      } catch {
        // do nothing
      }
    },
    async quickFilter(value) {
      this.gridApi.setQuickFilter(value);
      this.$store.commit('SET_CACHED_QUICK_FILTER', value);
      this.$refs.searchInput.focus();
      this.forceRecompute();
    },
    getFilters(filterModel) {
      const columnsToFilter = Object.keys(filterModel);
      this.internalFilters = [];
      if (columnsToFilter?.length > 0) {
        columnsToFilter.forEach((column) => {
          const { operators } = filterModel[column];
          if (operators && operators.size >= 1) {
            this.setInternalFilters(column, filterModel[column]);
            operators.forEach((op) => {
              filterModel[column][op].forEach((f) => {
                this.setInternalFilters(column, f, op);
              });
            });
          } else {
            this.setInternalFilters(column, filterModel[column]);
          }
        });
      }
      this.toggleNoRowsOverlay();
      this.forceRecompute();
    },
    toggleNoRowsOverlay() {
      if (!this.gridApi.getDisplayedRowCount()) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
      this.forceRecompute();
    },
    async expandOrCollapseGroups(event) {
      let totalCount = 0;
      let expandedCount = 0;
      const individualGroups = [];
      if (event.type === 'rowGroupOpened') {
        this.gridApi.forEachNode((node) => {
          if (node.group) {
            // use counts to set all if all groups are expanded individually
            totalCount = totalCount += 1;
            if (node.expanded && !event.initial) {
              expandedCount = expandedCount += 1;
              individualGroups.push(node.key);
            }
          }
        });
      }

      const all =
        event.source === 'expandAll' ||
        (totalCount && totalCount === expandedCount);
      try {
        const query = {
          ...this.$route.query,
          expanded: all ? 'all' : individualGroups.join(FILTER_SEPARATOR.MAIN),
        };
        await this.$router.replace({ query });
      } catch {
        // do nothing
      }
    },
    reset() {
      this.selected = [];
      this.selectedNodes = [];
    },
    clearQuickFilter() {
      this.filterInput = '';
      this.quickFilter(this.filterInput);
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/components/table/controls/table-filter-input.scss';

.table-filter-input {
  --color-input-base: transparent !important;
  width: 100%;
  min-width: unset;
}

.rows-count {
  min-width: size-item(3);
  display: flex;
  justify-content: center;
}
</style>
