<template>
  <transition name="fade" mode="out-in">
    <div v-if="show" class="toast bottom" :class="[type]">
      <j-icon v-if="type" :data="icons[type]" />
      <span>
        <!-- @slot Use to set message -->
        <slot></slot>
      </span>
      <j-button
        class="close-button"
        style-type="ghost"
        @keyup.enter="hideToast"
        tabindex="0"
        @click="hideToast"
      >
        <template #leading>
          <j-icon data="@jcon/x-small.svg" />
        </template>
      </j-button>
    </div>
  </transition>
</template>

<script>
import * as icons from '@/util/constants/feedback.icons';

export default {
  name: 'Toast',
  props: {
    show: Boolean,
    type: {
      type: String,
      default: 'info',
    },
  },
  data() {
    return { icons };
  },
  emits: ['hide'],
  methods: {
    hideToast() {
      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  @include toast;
  align-items: flex-start;
  max-width: 500px;
  max-height: 40%;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
  animation-duration: 500ms;

  .close-button {
    --size-action-height-base: var(--size-action-height-secondary);
    padding: 4px;
    border-radius: 40px;
  }
}
</style>
