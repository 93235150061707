/* eslint-disable prettier/prettier */
import gql from 'graphql-tag';
import LDClient from './ld.client';
import logger from '@/plugins/logger';
import { sentrySetupTransactionAndSpan } from '@/util/sentry';
import { getAppInitializationData as GET_APP_INITIALIZATION_DATA, getConfigFreeze as GET_CONFIG_FREEZE } from './queries/app.queries.gql';
import { FILTER_TYPES } from '@/components/table/utils/filter.types';
import { SEVERITY } from '@/util/constants/severity.types';
import { ALERT_STATUS } from '@/util/constants/alert-status.types';

const UPDATE_SLASA_ACCEPTANCE = gql`mutation updateSlasaAcceptance($acceptedSlasaVersion: Int!) {
  updateSlasaAcceptance(
    input: { acceptedSlasaVersion: $acceptedSlasaVersion }
  ) {
    acceptedSlasaVersion
  }
}
`;
const ldTransactionName = 'launchdarkly'
const analyticsQueryState = { query: { groups: 'categories' } }
export default {
  namespaced: false,
  state: {
    info: { flags: {} },
    hasAppInfo: false,
    checkConfigFreeze: null,
    hasLDClient: false,
    clientFlags: {},
    triggerTableCellRefresh: false,
    quickFilter: null,
    columnFilters: null,
    queryState: {
      'analytics.index': analyticsQueryState,
      'analytics.sets.detail': analyticsQueryState,
      'analytics.sets.create': analyticsQueryState,

      'alerts.index': {
        query: {
          column: 'severity|status',
          type: `${FILTER_TYPES.NOT_EQUAL}|${FILTER_TYPES.NOT_EQUAL}`,
          value: `${SEVERITY.Informational}|${ALERT_STATUS.Resolved}`,
          filterType: 'int|text',
          size: 25,
        }
      },
      'computers.index': { query: { size: 100 } }
    }
  },
  mutations: {
    SET_SLASA(state, payload) {
      state.info.acceptedSlasaVersion = payload;
    },
    SET_APP_INFO(state, payload) {
      state.info = {
        ...payload,
        flags:
          typeof payload.flags === 'string'
            ? JSON.parse(payload.flags)
            : payload.flags,
      };
      state.hasAppInfo = true;
    },
    SET_CLIENT_FLAGS(state, payload) {
      state.clientFlags = payload;
      if (Object.keys(state.info.flags).length === 0) {
        // removes errors on startup
        state.info.flags = payload;
      }
      state.hasLDClient = true;
    },
    SET_CONFIG_FREEZE(state, payload) {
      state.info.configFreeze = payload;
    },
    SET_CONFIG_FREEZE_CHECK_TIME(state) {
      const now = new Date();
      now.setSeconds(now.getSeconds() + 30);
      state.checkConfigFreeze = now / 1000;
    },
    TRIGGER_TABLE_CELL_REFRESH(state, payload) {
      state.triggerTableCellRefresh = payload;
    },
    SET_CACHED_QUICK_FILTER(state, payload) {
      state.quickFilter = payload;
    },
    SET_CURRENT_FILTER(state, payload) {
      state.columnFilters = payload;
    },
    SET_QUERY_STATE(state, payload = {}) {
      const query = { ...payload.query }
      delete query.copy; // do not keep copy state
      state.queryState = { ...state.queryState, [payload.name]: { query } };
    }
  },
  getters: {
    isFeatureEnabled: (state) => (feature) => {
      if (!Object.prototype.hasOwnProperty.call(state.info.flags, feature)) {
        return false;
      }
      const { span } = sentrySetupTransactionAndSpan({ name: ldTransactionName, op: `${ldTransactionName}.evaluate`, description: `flag: ${feature}` })
      const flagEval = LDClient.evaluateFlag(feature, state.info.flags[feature])
      span.setTag('launchdarkly.flag', feature);
      span.finish();
      return flagEval;
    },
    hasLimitedAppAccess(state) {
      return state.info.complianceReporterEnabled;
    },
  },
  actions: {
    async initializeLDClient({
      commit,
      state: { flags, info },
      rootState: {
        auth: { loginConfig, user, hasLoginConfig },
      },
    }) {
      if (hasLoginConfig) {
        logger.debug('Initialize LD Client');
        const { span } = sentrySetupTransactionAndSpan({ name: ldTransactionName, op: `${ldTransactionName}.initialize`, description: 'Initialize client and get client flags' });
        LDClient.getClient({ ...loginConfig, ...info }, user, flags);
        const clientFlags = await LDClient.getClientOnlyFlags();
        commit('SET_CLIENT_FLAGS', clientFlags);
        span?.setStatus('ok');
        span?.finish();
      }
    },
    async updateSlasaAcceptance({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_SLASA_ACCEPTANCE,
          variables: payload,
          key: 'updateSlasaAcceptance',
        },
        { root: true }
      );
      if (result) {
        commit('SET_SLASA', result.acceptedSlasaVersion);
      }
      return result;
    },
    async getAppInitializationData({ commit, dispatch }) {
      logger.debug('Get App Initialize Data');
      const result = await dispatch(
        'doQuery',
        {
          query: GET_APP_INITIALIZATION_DATA,
        },
        { root: true }
      );
      if (result) {
        const { getAppInitializationData, getUserSettings } = result.data;
        commit('SET_APP_INFO', getAppInitializationData);
        commit('SET_CONFIG_FREEZE_CHECK_TIME');
        commit('user/SET_USER_SETTINGS', getUserSettings.settings, { root: true });
        commit('user/SET_CLOUD_ACCESS_PERMISSION', getAppInitializationData.complianceReporterEnabled, { root: true });
      }
      return result;
    },
    async getConfigFreeze({ commit, dispatch }) {
      logger.debug('Get App Initialize Data');
      const result = await dispatch(
        'doQuery',
        {
          query: GET_CONFIG_FREEZE,
          key: 'getAppInitializationData',
        },
        { root: true }
      );
      if (result) {
        commit('SET_CONFIG_FREEZE', result.configFreeze);
        commit('SET_CONFIG_FREEZE_CHECK_TIME');
      }
      return result;
    },
  },
};
