<template>
  <Tabs :tabs="tabs" id="analytics" />
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AnalyticsSection',
  components: { Tabs },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    tabs() {
      return [
        {
          label: 'Analytic Sets',
          route: {
            name: 'analytics.sets',
          },
          hideOnNestedRoutes: true,
          nestedRouteNames: [
            'analytics.sets.detail',
            'analytics.sets.create',
            'analytics.index.create',
            'analytics.index.detail',
            'analytics.index.detail.edit',
            'analytics.index.detail.delete',
            'analytics.index.detail.actions',
          ],
        },
        {
          label: 'All Analytics',
          route: {
            name: 'analytics.index',
          },
          hideOnNestedRoutes: true,
          nestedRouteNames: [
            'analytics.index.create',
            'analytics.index.detail',
            'analytics.index.detail.edit',
            'analytics.index.detail.delete',
            'analytics.index.detail.actions',
            'analytics.sets.detail',
            'analytics.sets.create',
          ],
        },
      ];
    },
  },
};
</script>
