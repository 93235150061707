<template>
  <component
    class="j-tag"
    :tabindex="hasClick ? 0 : null"
    :class="classes"
    :aria-label="ariaLabel"
    @keydown.enter="handleClick"
    :to="to"
    :is="to ? 'router-link' : 'div'"
  >
    <slot></slot>
    <span v-if="count" class="tag-number h5">{{ count }}</span>
    <span v-if="hasIcon" class="tag-button is-small" type="button">
      <slot name="icon"></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'JamfTag',
  compatConfig: {
    MODE: 3,
  },
  props: {
    isRounded: Boolean,
    count: [String, Number],
    /** Set as RouterLink would be, this will update parent component to RouterLink */
    to: Object,
    styleType: {
      type: String,
      validator(value) {
        // The value must match one of these strings
        return [
          'warning',
          'subtle',
          'outline',
          'danger',
          'filter',
          'secondary',
          'inverse',
          'low',
          null,
        ].includes(value);
      },
    },
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    },
    hasClick() {
      return !!this.$attrs?.onClick || this.to;
    },
    classes() {
      return {
        rounded: this.isRounded,
        [this.styleType]: !!this.styleType,
        ['is-clickable']: this.hasClick,
      };
    },
    ariaLabel() {
      const labelCount = this.count ? `has ${this.count}` : '';
      return `${this.$slots?.default()[0].text} ${labelCount}`;
    },
  },
  methods: {
    handleClick() {
      if (this.hasClick) {
        this.$attrs.onClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.j-tag {
  &.outline {
    // replaces old subtle
    color: var(--color-indicator-tag-outline, var(--color-indicator-tag-base));
    border: 1px solid
      var(--color-indicator-tag-outline, var(--color-indicator-tag-base));
  }

  @include tag;
  @include transition(background-color);
  border: 1px solid var(--color-indicator-tag-border);
  max-height: var(--size-input-height-secondary);
  display: inline-flex;
  place-items: center;

  &.filter {
    // to be deprecated
    background-color: var(
      --filter-tags-background-color,
      var(--color-indicator-tag-base)
    );
  }

  &.warning {
    color: var(
      --color-font-indicator-tag-warning,
      var(--color-font-inverse)
    ); // light mode uses warning as inverse is unreadable
  }

  &.danger {
    color: var(--color-font-indicator-tag-danger, var(--color-font-inverse));
  }

  &.low {
    color: var(--color-font-indicator-tag-low, var(--color-font-inverse));
    background-color: var(--color-low-base, var(--color-indicator-tag-inverse));
  }

  &.subtle {
    background-color: var(--color-indicator-tag-subtle);
  }

  &.inverse {
    // to be deprecated
    color: var(--color-font-inverse);
    background-color: var(
      --color-indicator-tag-inverse,
      var(--color-indicator-tag-base)
    );
  }

  &.rounded {
    .tag-number {
      border-radius: 4px var(--size-input-height-secondary)
        var(--size-input-height-secondary) 0px;
    }
  }

  &.is-clickable {
    &:hover {
      cursor: pointer;
      background-color: var(--color-primary-active);

      &.danger {
        background-color: var(--color-danger-active);
      }

      &.warning {
        background-color: var(--color-warning-active);
      }

      &.low {
        background-color: var(--color-low-active, var(--color-primary-active));
      }

      &.outline,
      &.subtle {
        background-color: var(
          --color-structure-highlight-active,
          var(--color-secondary-active)
        );
      }
    }
    &:focus {
      @include has-focus;
    }
  }

  .tag-number {
    border-left: 1px solid currentColor;
    padding: 2px spacing();
    margin-right: -8px;
    margin-left: 8px;
    color: inherit;
  }
}

.icon {
  color: inherit;
}

.tag-button {
  margin-left: spacing(0.5);
  display: flex;
}
</style>

<style lang="scss">
.tags {
  @include flex-wrap($gap: spacing(1), $scale: 0);
  &:last-child {
    //override beufy
    margin-bottom: unset !important;
  }
}
</style>
