export const renameFilterKeys = (filter, fromKey, toKey, callback) => {
  let newObj = filter;
  if (newObj) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        newObj[key] = value.map((item) =>
          renameFilterKeys(item, fromKey, toKey, callback)
        );
      }
      if (key === fromKey) {
        if (callback) {
          newObj = { ...newObj, ...callback(value, key, filter) };
          delete newObj[fromKey];
        } else if (toKey) {
          newObj[toKey] = value;
          delete newObj[fromKey];
        }
      }
    });
  }
  return newObj;
};
