import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useRBAC } from './rbac';
import logger from '@/plugins/logger';

export function useRouteHelpers() {
  const $router = useRouter();
  const $route = useRoute();
  const [one, index] = $route?.name?.split('.');
  const parent = `${one}.${index}`;

  const { canAccess } = useRBAC();
  const store = useStore();
  const mode = computed(() => store?.getters?.mode ?? {});

  const routeTo = (child, params = {}) => {
    $router.push({
      name: child ? `${parent}.${child}` : parent,
      params,
    });
  };

  const removeCloudOnlyRoutes = () => {
    // replace the current index with a new redirect for offline mode
    $router.addRoute('main', {
      path: '',
      redirect: { name: 'downloads.index' },
      name: 'index',
    });
    const routes = $router.getRoutes();
    for (const route of routes) {
      const permissions =
        route.props?.default?.rbac?.permissions ?? route.meta?.permissions;
      if (permissions && !canAccess(permissions, route.meta)) {
        $router.removeRoute(route.name);
        logger.info('Removed:', route.name);
      }
    }
  };

  const hasAccessToSection = (rbac) => {
    const fullRoutePermissions =
      rbac?.permissions ?? $route?.meta?.permissions ?? [];

    // don't use merged meta for rbac params, get from matched instead. Prevents parent pollution.
    const routeMeta =
      rbac ??
      [...$route.matched]
        .reverse()
        .find((route) =>
          Object.keys(route.meta ?? {}).some((key) =>
            [
              'requireWrite',
              'requireNested',
              'requireOnlySome',
              'permissions',
            ].includes(key)
          )
        )?.meta ??
      {};
    return canAccess(fullRoutePermissions, routeMeta);
  };

  return {
    hasAccessToSection,
    removeCloudOnlyRoutes,
    routeTo,
    $router,
    mode,
    parent,
  };
}
