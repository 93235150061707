<template>
  <div class="gp-content">
    <j-form-bar
      :title="mode.create ? 'Create API Client' : apiClient.name"
      @submit="handleSubmit"
      :can-write="true"
    />
    <div class="scroll-vertical">
      <div class="form-section">
        <j-input
          v-model.trim="name"
          data-feature-id="api-client-name"
          label="API Client Name"
          :has-error="v$.name.$error"
          :error-text="nameErrorMessage"
        />
        <div class="mt-2">
          <div>
            <label class="pr-1 role-text">Roles</label>
            <span class="helper-text">
              Assign specific role or roles to this client API to limit access.
              To view or edit roles see
              <router-link
                v-if="hasAccessToRoles"
                :to="{ name: 'account.roles' }"
                >Roles</router-link
              >.
            </span>
          </div>
          <j-select
            v-if="roleOptions"
            v-model="roleIds"
            class="mt-1"
            data-feature-id="role"
            :is-read-only="isReadOnly"
            :options="roleOptions"
            placeholder="Select Roles"
            allow-multi-select
          />
        </div>
        <j-modal
          v-model="showWarning"
          type="warning"
          :close-on-overlay="false"
          :auto-focus="true"
        >
          <div class="g-rows">
            <p>The password for API Client "{{ name }}" is:</p>
            <pre class="secret">{{ secretDisclosure }}</pre>
            <j-notification type="warning">
              Please copy it to a secure place now. <br />
              <span class="warning-text">It will not be shown again.</span>
            </j-notification>
          </div>
          <template #header="{ alignClass, iconClass }">
            <section :class="alignClass">
              <j-icon data="@jcon/warning.svg" :class="iconClass" />
              <h1 class="h3">API Client Password</h1>
            </section>
          </template>
          <template #actions>
            <j-button style-type="secondary" @click="dismiss">Dismiss</j-button>
            <j-button @click="copyAndDismiss" data-feature-id="copy-secret">
              Copy to Clipboard
            </j-button>
          </template>
        </j-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required, maxLength } from '@vuelidate/validators';
import { useForm } from '@/composables/forms';
import { useRBAC } from '@/composables/rbac';
import { isUniqueName } from '@/util/custom-validators';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'ApiClientForm',
  setup() {
    const { v$, mode, handleSubmit } = useForm();
    const { canAccess, isReadOnly } = useRBAC([RBAC_RESOURCE.API_CLIENT]);
    return { v$, mode, handleSubmit, canAccess, isReadOnly };
  },
  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(64),
        isUniqueName: isUniqueName(this.apiClient?.name),
      },
    };
  },
  data() {
    return {
      roleOptions: null,
      roleIds: ['1'],
      message: null,
      name: '',
      showWarning: false,
      secretDisclosure: null,
      saved: false,
    };
  },
  computed: {
    endpoint() {
      return `primary/${
        this.mode.create ? 'createApiClient' : 'updateApiClient'
      }`;
    },
    invalidMaxLengthMessage() {
      return this.v$.name.maxLength.$invalid
        ? 'Name cannot exceed 64 characters'
        : 'A client with this name already exists';
    },
    nameErrorMessage() {
      return this.v$.name.required.$invalid
        ? 'Name is required'
        : this.invalidMaxLengthMessage;
    },
    ...mapState('primary', {
      apiClient: (state) => state.apiclients.apiClient,
      // this is used in isUniqueName
      duplicateNames: (state) => state.apiclients.apiClientsNames,
    }),
    hasAccessToRoles() {
      return this.canAccess(RBAC_RESOURCE.ROLE);
    },
  },
  mounted() {
    this.setupRoles();
    if (!this.mode.create) this.name = this.apiClient?.name;
  },
  methods: {
    async setupRoles() {
      if (this.canAccess(RBAC_RESOURCE.ROLE)) {
        const roleList = await this.$store.dispatch('primary/listRoles', {
          direction: 'DESC',
          field: 'name',
        });

        this.roleOptions = roleList?.items?.map(({ name, id }) => {
          return { label: name, value: id };
        });
      }
      if (!this.mode.create) {
        this.roleIds = this.apiClient?.assignedRoles?.map(({ id }) => id);
      }
    },
    async submit() {
      const response = await this.$store.dispatch(this.endpoint, {
        ...this.apiClient,
        name: this.name,
        roleIds: this.roleIds,
      });
      if (response && this.mode.create) {
        this.secretDisclosure = response.password;
        this.showWarning = true;
      }
    },
    dismiss() {
      this.showWarning = false;
      this.$router.push({
        name: 'apiClients.index.detail',
        params: { clientId: this.apiClient.clientId },
      });
    },
    copyAndDismiss() {
      this.$clipboard(this.secretDisclosure);
      this.dismiss();
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-text {
  font-weight: bold;
}
.role-text {
  @include input-group-label;
}
</style>
