<template>
  <EmptyState allow-events>
    <div class="ntp-content">
      <h1 class="h3">Enable Network Threat Prevention in RADAR</h1>
      <ul class="g-rows">
        <li class="g-cols pli-center">
          <j-icon
            data="@jcon/check-circle.svg"
            color="var(--color-success-base)"
          />
          Network Threat Prevention further protects your macOS endpoints by
          denying access to malicious, unsecure, or unwanted network resources.
        </li>
        <li class="g-cols pli-center">
          <j-icon
            data="@jcon/check-circle.svg"
            color="var(--color-success-base)"
          />
          Network Threat Prevention is deployed utilizing Apple-native
          technology built into macOS and is managed in the Jamf Security RADAR
          portal.
        </li>
      </ul>
      <div class="ntp-buttons">
        <j-link
          as-button
          button-style-type="secondary"
          open-in-new-tab
          to="https://docs.jamf.com/jamf-protect/documentation/Configuring_Network_Threat_Prevention.html"
          >Learn more</j-link
        >
        <j-link as-button to="https://radar.jamf.com/login" open-in-new-tab
          >Go to RADAR</j-link
        >
      </div>
    </div>
    <template #bottom>
      <p class="helper-text">
        Enabling Network Threat Prevention in Jamf Protect results in all
        customer data (including personal data) processed by this feature being
        transferred, processed, and stored within Jamf infrastructure located in
        Ireland. If you do not enable Network Threat Prevention there will be no
        change to how your data is processed when using Jamf Protect.
      </p>
    </template>
  </EmptyState>
</template>

<script>
import EmptyState from '@/components/EmptyState.vue';

export default {
  name: 'NetworkThreatPreventionOverview',
  components: { EmptyState },
};
</script>

<style lang="scss" scoped>
.ntp-content {
  @include grid();
  @include grid-rows();
  place-content: center;
  place-items: center;

  ul {
    text-align: left;
  }
}
.ntp-buttons {
  @include grid();
  @include grid-columns(max-content);
  place-items: center;
}
</style>
