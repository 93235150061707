import {
  getExceptionSet as GET_EXCEPTION_SET,
  listExceptionSets as LIST_EXCEPTION_SETS,
} from './exception-sets.queries.gql';
import {
  createExceptionSet as CREATE_EXCEPTION_SET,
  deleteExceptionSet as DELETE_EXCEPTION_SET,
  updateExceptionSet as UPDATE_EXCEPTION_SET,
} from './exception-sets.mutations.gql';

export default {
  mutations: {
    SET_EXCEPTION_SET(state, payload) {
      state.exceptionSet = payload;
    },
    SET_EXCEPTION_SETS(state, payload) {
      state.exceptionSets = payload;
      state.exceptionSetsNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    async listExceptionSets({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_EXCEPTION_SETS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_EXCEPTION_SETS', result.data.listExceptionSets);
        return result.data.listExceptionSets;
      }
      return result;
    },
    async getExceptionSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_EXCEPTION_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_EXCEPTION_SET', result.data.getExceptionSet);
        return result.data.getExceptionSet;
      }
      return result;
    },
    async createExceptionSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_EXCEPTION_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_EXCEPTION_SET', result.data.createExceptionSet);
        return result.data.createExceptionSet;
      }
      return result;
    },
    async updateExceptionSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_EXCEPTION_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_EXCEPTION_SET', result.data.updateExceptionSet);
        return result.data.updateExceptionSet;
      }
      return result;
    },
    async deleteExceptionSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_EXCEPTION_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_EXCEPTION_SET', result.data.deleteExceptionSet);
        return result.data.deleteExceptionSet;
      }
      return result;
    },
  },
};
