<template>
  <Tabs v-if="!loading" :tabs="tabs">
    <template v-if="canWrite" #right>
      <j-button @click="copy" style-type="secondary">
        <template #leading> <j-icon data="@jcon/clipboard.svg" /></template
        >Clone</j-button
      >
    </template>
    <router-view :analytic="analytic" />
  </Tabs>
</template>

<script>
import { mapState } from 'vuex';
import { useRBAC } from '@/composables/rbac';
import Tabs from '@/components/Tabs.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'Analytic',
  components: { Tabs },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.ANALYTIC);
    return { canWrite };
  },
  data() {
    return {
      tabs: null,
      loading: true,
    };
  },
  computed: {
    ...mapState('primary', { analytic: (state) => state.analytics.analytic }),
    ...mapState('route', ['name']),
  },
  async beforeMount() {
    const { id } = this.$route.params;
    this.tabs = [
      {
        label: 'Summary',
        route: {
          name: 'analytics.index.detail',
          params: { id },
        },
      },
    ];
    await this.$store.dispatch('primary/getAnalytic', {
      uuid: id,
    });
    if (this.canWrite) {
      if (!this.analytic.jamf) {
        this.tabs = [
          ...this.tabs,
          {
            label: 'Edit',
            route: {
              name: 'analytics.index.detail.edit',
              params: { id },
            },
          },
          {
            label: 'Delete',
            route: {
              name: 'analytics.index.detail.delete',
              params: { id },
            },
          },
        ];
      } else {
        this.tabs.push({
          label: 'Edit Analytic',
          route: {
            name: 'analytics.index.detail.actions',
            params: { id },
          },
        });
      }
    }
    this.loading = false;
  },
  methods: {
    copy() {
      this.$router.push({
        name: 'analytics.index.create',
        query: { copy: this.$route.params.id },
      });
    },
  },
};
</script>
