<template>
  <div data-feature-id="plans">
    <p class="mb-1">{{ label }}</p>
    <div class="tags mb-0" v-if="hasPlans">
      <template v-for="(plan, index) in plans" :key="plan.id">
        <j-tag
          v-if="index < planLimit"
          @click="
            $router.push({
              name: 'plans.index.detail',
              params: { id: plan.id },
            })
          "
        >
          {{ plan.name }}
        </j-tag>
      </template>
      <a v-if="plans.length > planLimit" @click="viewAllPlans = !viewAllPlans"
        >... View All</a
      >
    </div>
    <j-icon v-else data="@jcon/minus.svg" color="var(--color-font-secondary)" />
    <j-modal v-model="viewAllPlans">
      <div class="tags">
        <j-tag
          v-for="plan in plans"
          :key="plan.id"
          @click="
            $router.push({
              name: 'plans.index.detail',
              params: { id: plan.id },
            })
          "
        >
          {{ plan.name }}
        </j-tag>
      </div>
    </j-modal>
  </div>
</template>

<script>
export default {
  name: 'RelatedPlansTagList',
  props: {
    plans: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'Associated Plans',
    },
  },
  data() {
    return {
      planLimit: 10,
      viewAllPlans: false,
    };
  },
  computed: {
    hasPlans() {
      return this.plans?.length > 0;
    },
  },
};
</script>
