import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import gql from 'graphql-tag';

export const GET_COUNTS = (getPermissions) => {
  const hasInsights = getPermissions([RBAC_RESOURCE.INSIGHT]);
  const hasComputer = getPermissions([RBAC_RESOURCE.COMPUTER]);
  const hasAlert = getPermissions([RBAC_RESOURCE.ALERT]);

  const hasInsightsComputerFilters = hasInsights
    ? '$insightsComputers: ComputerFiltersInput,'
    : '';
  const hasAlertsFiltersInput = hasAlert
    ? '$alertsComputers: AlertFiltersInput,'
    : '';
  const filteredComputerQuery = `
    $computers: ComputerFiltersInput,
    ${hasInsightsComputerFilters}
    ${hasAlertsFiltersInput}
    `;

  const hasInsightsCustomQuery = hasInsights
    ? 'insightsComputers: $insightsComputers,'
    : '';
  const hasAlertsCustomQuery = hasAlert
    ? 'alertsComputers: $alertsComputers,'
    : '';
  const customComputerQuery = `
    computers: $computers,
    ${hasInsightsCustomQuery}
    ${hasAlertsCustomQuery}
    `;

  const hasInsightsGenericQuery = hasInsights ? 'insightsComputers' : '';
  const hasAlertsGenericQuery = hasAlert ? 'alertsComputers' : '';
  const genericComputerQuery = `
    computers
    ${hasInsightsGenericQuery}
    ${hasAlertsGenericQuery}
    `;

  return gql`
    query getCount(
      ${hasAlert ? '$alerts: AlertFiltersInput,' : ''}
      ${hasComputer ? filteredComputerQuery : ''}
    ) {
    getCount(input: {
      ${hasAlert ? 'alerts: $alerts,' : ''}
      ${hasComputer ? customComputerQuery : ''}
    }) {
      ${hasAlert ? 'alerts' : ''}
      ${hasComputer ? genericComputerQuery : ''}
    }
  }`;
};
