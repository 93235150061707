<template>
  <transition name="fade">
    <div v-if="!hide" class="notification-container" :class="{ [type]: true }">
      <j-icon :data="typeIcon" width="24" height="24" />
      <p><slot></slot></p>
    </div>
  </transition>
</template>

<script>
import {
  success,
  danger,
  warning,
  info,
} from '@/util/constants/feedback.icons';

export default {
  name: 'Notification',
  props: {
    icon: {
      type: Object,
    },
    type: {
      type: String,
      default: 'info',
    },
    hide: Boolean,
  },
  computed: {
    typeIcon() {
      switch (this.type) {
        case 'danger':
          return danger;
        case 'warning':
          return warning;
        case 'info':
          return info;
        case 'success':
          return success;
        default:
          return this.icon;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-container {
  @include notification-types;
  @include grid;
  @include grid-columns(spacing(3) auto);
  align-items: baseline;
  padding: spacing(2);
  border-radius: 4px;

  p {
    @include paragraph;
  }
}
</style>
