import GET_ORGANIZATION from './queries/getOrganization.gql';
import UPDATE_ORGANIZATION from './mutations/updateOrganization.gql';
import LIST_COMPLIANCE_REPORTER_PACKAGES from './queries/listComplianceReporterPackages.gql';
import GET_COMPLIANCE_REPORTER_PACKAGE from './queries/getComplianceReporterPackage.gql';
import GET_PACKAGE from './queries/getPackage.gql';
import LIST_PACKAGES from './queries/listPackages.gql';
import LIST_ORGANIZATIONS from './queries/listOrganizations.gql';
import LIST_ORGANIZATIONS_LITE from './queries/listOrganizationsLite.gql';
import GET_TOKEN from './queries/getToken.gql';
import UPDATE_COMPLIANCE_REPORTER_PACKAGE from './mutations/updateComplianceReporterPackage.gql';
import UPDATE_PACKAGE from './mutations/updatePackage.gql';
import ASSIGN_FULL_ADMIN from './mutations/assignFullAdmin.gql';
import DELETE_ORGANIZATION from './mutations/deleteOrganization.gql';
import CREATE_ORGANIZATION from './mutations/createOrganization.gql';
import LIST_AUDIT_LOGS_DATE from '@/modules/audit-logs/store/queries/listAuditLogsByDate.gql';
import LIST_AUDIT_LOGS_USER from '@/modules/audit-logs/store/queries/listAuditLogsByUser.gql';
import LIST_AUDIT_LOGS_OP from '@/modules/audit-logs/store/queries/listAuditLogsByOp.gql';

export default {
  state: {
    package: null,
    tenant: null,
    tenants: [],
    vanityNames: [],
  },
  mutations: {
    SET_PACKAGE(state, payload) {
      state.package = payload;
    },
    SET_TENANT(state, payload) {
      state.tenant = payload;
    },
    SET_TENANTS(state, payload) {
      state.tenants = payload.items;
      state.vanityNames = payload.items?.map(({ vanity }) => vanity);
    },
  },
  actions: {
    // mutations
    async getOrganization({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: GET_ORGANIZATION,
          variables: payload,
          key: 'getOrganization',
        },
        { root: true }
      );
      if (result) {
        commit('SET_TENANT', result);
      }
      return result;
    },
    async updateOrganization({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          useAdmin: true,
          query: UPDATE_ORGANIZATION,
          variables: payload,
          key: 'updateOrganization',
        },
        { root: true }
      );
      if (result) {
        commit('SET_TENANT', result);
      }
      return result;
    },
    async updateComplianceReporterPackage({ dispatch }, payload) {
      return dispatch(
        'doMutation',
        {
          useAdmin: true,
          query: UPDATE_COMPLIANCE_REPORTER_PACKAGE,
          variables: payload,
          key: 'updateComplianceReporterPackage',
        },
        { root: true }
      );
    },
    async updatePackage({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          useAdmin: true,
          query: UPDATE_PACKAGE,
          variables: payload,
          key: 'updatePackage',
        },
        { root: true }
      );
      commit('SET_PACKAGE', result);
      return result;
    },
    async assignFullAdmin({ dispatch }, payload) {
      return dispatch(
        'doMutation',
        {
          useAdmin: true,
          query: ASSIGN_FULL_ADMIN,
          variables: payload,
          key: 'assignFullAdmin',
        },
        { root: true }
      );
    },
    async deleteOrganization({ dispatch }, payload) {
      return dispatch(
        'doMutation',
        {
          useAdmin: true,
          query: DELETE_ORGANIZATION,
          variables: payload,
          key: 'deleteOrganization',
        },
        { root: true }
      );
    },
    // queries
    async listPackages({ dispatch }, payload) {
      return dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_PACKAGES,
          variables: payload,
          key: 'listPackages',
        },
        { root: true }
      );
    },
    async listComplianceReporterPackages({ dispatch }, payload) {
      return dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_COMPLIANCE_REPORTER_PACKAGES,
          variables: payload,
          key: 'listComplianceReporterPackages',
        },
        { root: true }
      );
    },
    async getPackage({ commit, dispatch }, payload) {
      commit('SET_PACKAGE', null);
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: GET_PACKAGE,
          variables: payload,
          key: 'getPackage',
        },
        { root: true }
      );
      commit('SET_PACKAGE', result);
      return result;
    },
    async getComplianceReporterPackage({ commit, dispatch }, payload) {
      commit('SET_PACKAGE', null);
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: GET_COMPLIANCE_REPORTER_PACKAGE,
          variables: payload,
          key: 'getComplianceReporterPackage',
        },
        { root: true }
      );
      commit('SET_PACKAGE', result);
      return result;
    },
    async createOrganization({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          useAdmin: true,
          query: CREATE_ORGANIZATION,
          variables: payload,
          key: 'createOrganization',
        },
        { root: true }
      );
      if (result) {
        commit('SET_TENANT', result);
      }
      return result;
    },
    async listOrganizations({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_ORGANIZATIONS,
          variables: payload,
          key: 'listOrganizations',
        },
        { root: true }
      );
      commit('SET_TENANTS', result);
      return result;
    },
    async listOrganizationsLite({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_ORGANIZATIONS_LITE,
          variables: payload,
          key: 'listOrganizations',
        },
        { root: true }
      );
      commit('SET_TENANTS', result);
      return result;
    },
    async getToken({ dispatch }, payload) {
      return dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: GET_TOKEN,
          variables: payload,
          key: 'getToken',
        },
        { root: true }
      );
    },
    async listAuditLogsByDate({ dispatch }, payload) {
      return dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_AUDIT_LOGS_DATE,
          variables: payload,
          key: 'listAuditLogsByDate',
        },
        { root: true }
      );
    },
    async listAuditLogsByUser({ dispatch }, payload) {
      return dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_AUDIT_LOGS_USER,
          variables: payload,
          key: 'listAuditLogsByUser',
        },
        { root: true }
      );
    },
    async listAuditLogsByOp({ dispatch }, payload) {
      return dispatch(
        'doQuery',
        {
          useAdmin: true,
          query: LIST_AUDIT_LOGS_OP,
          variables: payload,
          key: 'listAuditLogsByOp',
        },
        { root: true }
      );
    },
  },
};
