export const AnalyticNameMatchKey = {
  ThreatMatchExecEvent: 'Threat Prevention',
};

export const getKeyNameMatch = (name, obj = AnalyticNameMatchKey) => {
  let result = name;
  Object.entries(obj).forEach(([key, value]) => {
    if (name === key) {
      result = value;
    } else if (name === value) {
      result = key;
    }
  });
  return result;
};
