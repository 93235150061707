<template>
  <div class="gp-content" v-if="dataSettings">
    <j-form-bar
      v-if="!disabled && canWrite"
      title="Edit Data Retention"
      :can-write="true"
      @submit="handleSubmit"
    />
    <div class="data-form scroll-vertical">
      <j-notification :hide="!disabled">
        Data Retention Settings can only be updated once every 24 hours. Last
        updated at
        {{ formatEpochDate(dataSettings.retention.updated) }}
      </j-notification>
      <div class="section-label">
        <h2>In-App Alert Access</h2>
        <p>
          Alerts are accessible in the app until the set amount of days or until
          the maximum record threshold (2 million) is reached.
        </p>
        <p>
          Alerts are assigned a retention value based on their severity
          (informational, low, medium, or high).
        </p>
      </div>
      <j-select
        :is-read-only="!canWrite"
        data-feature-id="alert"
        v-model="retention.database.alert.numberOfDays"
        :options="availableDays"
        :is-disabled="disabled"
        :searchable="false"
        is-small
        label="Keep Low, Medium, High Severity Alerts For"
      />
      <j-select
        :is-read-only="!canWrite"
        data-feature-id="log"
        v-model="retention.database.log.numberOfDays"
        :options="availableDays"
        :is-disabled="disabled"
        :searchable="false"
        is-small
        label="Keep Informational Alerts For"
      />
      <div class="section-label">
        <h2>Long Term Data Storage</h2>
        <p>
          All alert, telemetry, and unified log records sent to Jamf Protect
          Cloud are securely archived for the set amount of days. To access
          archived records, contact Jamf Support.
        </p>
      </div>
      <j-select
        :is-read-only="!canWrite"
        data-feature-id="coldAlert"
        v-model="retention.cold.alert.numberOfDays"
        :options="availableDays"
        :is-disabled="disabled"
        :searchable="false"
        is-small
        label="Keep Archived Data For"
      />
      <j-modal v-model="showWarning" type="warning">
        Data Retention settings can only be updated once every
        <strong>24 hours</strong>.
        <template #header="{ alignClass, iconClass }">
          <section :class="alignClass">
            <j-icon data="@jcon/warning.svg" :class="iconClass" />
            <h1 class="h3">Are You Sure?</h1>
          </section>
        </template>
        <template #actions>
          <j-button style-type="secondary" @click="showWarning = false">
            Cancel
          </j-button>
          <j-button @click="warningAccepted()"> Save </j-button>
        </template>
      </j-modal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dateGreaterThan } from '@/util';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useForm } from '@/composables/forms';
import { useRBAC } from '@/composables/rbac';
import { useGmt } from '@/composables/gmt';

export default {
  name: 'DataRetention',
  setup() {
    const { handleSubmit } = useForm();
    const { canWrite } = useRBAC([RBAC_RESOURCE.DATA_RETENTION]);
    const { formatEpochDate } = useGmt();
    return { canWrite, handleSubmit, formatEpochDate };
  },
  data() {
    return {
      availableDays: [
        { label: '30 days', value: 30 },
        { label: '60 days', value: 60 },
        { label: '90 days', value: 90 },
        { label: '180 days', value: 180 },
        { label: '365 days', value: 365 },
      ],
      retention: null,
      warningSeen: false,
      showWarning: false,
    };
  },
  computed: {
    ...mapState('primary', {
      dataSettings: (state) => state.data.retention,
    }),
    disabled() {
      const { updated } = this.dataSettings.retention;
      return updated ? !dateGreaterThan(updated, 24) : false;
    },
  },
  beforeMount() {
    this.retention = JSON.parse(JSON.stringify(this.dataSettings.retention));
    delete this.retention.updated;
  },
  methods: {
    warningAccepted() {
      this.warningSeen = true;
      this.showWarning = false;
      this.submit();
    },
    async submit() {
      if (!this.warningSeen) {
        this.showWarning = true;
      } else {
        await this.$store.dispatch('primary/updateOrganizationRetention', {
          retention: this.retention,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-form {
  @include grid;
  @include grid-rows(min-content);
  padding: var(--size-grid-gap-base);

  .counter {
    max-width: 96px;
    min-width: 96px;
  }

  h2 {
    @include header4;
  }

  p {
    @include paragraph;
  }
}
</style>
