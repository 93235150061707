import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import AuditLogs from './AuditLogs.vue';

export default [
  {
    path: '/auditlogs',
    component: Section,
    name: 'auditlogs',
    meta: {
      breadcrumb: {
        linkName: 'auditlogs.index',
        linkText: 'Audit Logs',
      },
      permissions: [RBAC_RESOURCE.AUDIT_LOG],
    },
    children: [
      {
        path: '',
        component: AuditLogs,
        name: 'auditlogs.index',
      },
    ],
  },
];
