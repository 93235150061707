<template>
  <div class="g-rows">
    <ListItem title="Event Timestamp">
      {{ formatEpochDate(event.timestamp) }}
    </ListItem>
    <ListItem title="Source Process ID">
      {{ event.sourcePID }}
    </ListItem>
    <ListItem title="Destination Process ID">
      {{ event.destinationPID }}
    </ListItem>
    <ListItem title="Tap Options">
      {{ event.options }}
    </ListItem>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPKeylogRegisterEvent',
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  inject: ['$typeDefs'],
  components: { ListItem },
  props: {
    event: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      def: this.$typeDefs.events.GPKeylogRegisterEvent,
    };
  },
};
</script>
