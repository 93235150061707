import * as client from 'launchdarkly-js-client-sdk';

class LDClient {
  client;

  client_flags;

  getClient(
    {
      account,
      client_id,
      tenant,
      releaseGroup,
      uuid,
      name,
      branch,
      complianceReporterEnabled,
    },
    user,
    flags
  ) {
    const ldClientIds = {
      sbox: '5f3129c2e00b830874171a69',
      dev: '5f3129d5c8369f081c5ccab7',
      stage: '5f312707ff9a7e08efefb9d1',
      prod: '5f312707ff9a7e08efefb9d2',
    };

    this.client = client.initialize(
      ldClientIds[process.env.VUE_APP_DEPLOYMENT_PREFIX || account],
      // client_id is used to evaluate users before login
      {
        key: uuid || client_id,
        ...(user?.sub ? { secondary: user.sub } : {}),
        name: name || tenant,
        custom: {
          tenant: tenant || window.location.host.split('.')[0],
          releaseGroup,
          isHighCompliance: complianceReporterEnabled,
          branch,
        },
        options: {
          bootstrap: flags,
        },
      }
    );
  }

  async getClientOnlyFlags() {
    await this.client.waitUntilReady();
    return this.client.allFlags();
  }

  evaluateFlag(flag, value) {
    return this.client?.variation(flag, value);
  }
}

export default new LDClient();
