import gql from 'graphql-tag';
import DataRetentionFields from './fragments/data-retention.fragment.gql';
import DataForwardFields from './fragments/data-forward.fragment.gql';

export const UPDATE_ORGANIZATION_RETENTION = gql`
  mutation updateOrganizationRetention(
    $retention: OrganizationRetentionInputs!
  ) {
    updateOrganizationRetention(input: { retention: $retention }) {
      ...DataRetentionFields
    }
  }
  ${DataRetentionFields}
`;

export const UPDATE_ORGANIZATION_FORWARD = gql`
  mutation updateOrganizationForward(
    $s3: OrganizationS3ForwardInput!
    $sentinel: OrganizationSentinelForwardInput!
  ) {
    updateOrganizationForward(input: { s3: $s3, sentinel: $sentinel }) {
      ...DataForwardFields
    }
  }
  ${DataForwardFields}
`;
