<template>
  <div class="g-cols ai-center mb-1 mt-2">
    <div class="g-cols max-c gap-1 ai-center">
      <h2 class="h5">{{ USBCONTROL_TYPES_NAMES[type] }} Overrides</h2>
      <HelpButton icon-only @click="isHelpActive = true" />
      <j-modal v-model="isHelpActive" size="large">
        <template #header
          ><h1 class="h3">{{ USBCONTROL_TYPES_NAMES[type] }}</h1></template
        >
        <USBControlsDocumentation :type="type" />
      </j-modal>
    </div>
  </div>
  <hr class="m-0" />
  <JamfTableClientSide
    v-if="internalValues"
    use-dom-layout
    :data="internalValues"
    :options="options"
    :columns="columns[type]"
    suppress-refresh-button
    suppress-filter-bar
    suppress-query-params
  >
    <template #right="{ isSmall, selected, gridApi }">
      <div class="g-cols ai-center" :class="{ 'is-mobile': isSmall }">
        <j-button data-feature-id="export-csv" @click="exportData(gridApi)"
          ><template #leading><j-icon data="@jcon/file-export.svg" /></template
          ><template v-if="!isSmall" #default>Export</template>
        </j-button>
        <template v-if="canWrite">
          <Upload
            v-model="uploadFile"
            @change="addUploadData"
            :label="isSmall ? null : 'Upload CSV'"
          />
          <j-create-button
            data-feature-id="add-list-items"
            @click="isAddItemActive = true"
            :label="isSmall ? null : 'Add'"
          />
          <j-delete-button
            data-feature-id="remove-list-items-modal"
            :label="isSmall ? null : 'Delete'"
            :is-disabled="selected.length === 0"
            @click="isRemoveItemsActive = true"
          />
        </template>
      </div>
    </template>
    <template v-if="canWrite" #modals="{ gridApi }">
      <j-modal v-model="isAddItemActive">
        <div v-if="isTypeProduct" class="g-cols">
          <template v-if="isUpload">
            <h1 class="h3" v-if="dataListCount">
              {{ dataListCount }} Products to add.
            </h1>
            <div v-else class="g-rows">
              <h1 class="h3">No products were added.</h1>
              <p>
                Upload a CSV with one column containing Vendor ID and a second
                column containing Product ID.
              </p>
            </div>
          </template>
          <template v-else>
            <j-input
              :label="USBCONTROL_TYPES_NAMES.Vendor"
              v-model="productToAdd.vendor"
              :has-error="v$.productToAdd.vendor.$error"
              :error-text="v$.productToAdd.vendor.hexadecimal.$message"
              data-feature-id="product-vendor-id"
            />
            <j-input
              :label="USBCONTROL_TYPES_NAMES.Product"
              v-model="productToAdd.product"
              :has-error="v$.productToAdd.product.$error"
              :error-text="v$.productToAdd.product.hexadecimal.$message"
              data-feature-id="product-product-id"
            />
          </template>
        </div>
        <j-textarea
          v-else
          :label="USBCONTROL_TYPES_NAMES[type]"
          :helper-text="vendorOrSerialHelperText"
          data-feature-id="list-values"
          :has-error="v$.vendorOrSerialList.$error"
          :error-text="vendorOrSerialErrorMessage"
          v-model="dataToAdd"
        />
        <template #actions>
          <j-button
            style-type="secondary"
            @click="cancelAdd"
            data-feature-id="cancel-button"
            >Cancel</j-button
          >
          <j-button
            v-if="(!isTypeProduct || isUpload) && internalValues.length > 0"
            @click="addItems(true)"
            :is-disabled="!dataToAdd || !dataToAdd.length"
            data-feature-id="replace-button"
            >Replace</j-button
          >
          <j-button
            @click="addItems(false)"
            data-feature-id="append-button"
            :is-disabled="modalAddButtonDisabled"
            >{{ internalValues.length > 0 ? 'Append' : 'Add' }}</j-button
          >
        </template>
      </j-modal>
      <j-delete-modal
        v-model="isRemoveItemsActive"
        :text="`Delete selected ${USBCONTROL_TYPES_NAMES[type]}(s)`"
        @confirm="removeItems(gridApi)"
      />
    </template>
  </JamfTableClientSide>
</template>

<script>
import HelpButton from '@/components/HelpButton.vue';
import JamfTableClientSide from '@/components/table/JamfTableClientSide.vue';
import NoRowsAddUSBControl from './NoRowsAddUSBControl.vue';
import Upload from '@/components/Upload.vue';
import { hexadecimal, hexadecimalIf } from '@/util/custom-validators';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import {
  USBCONTROL_TYPES,
  USBCONTROL_TYPES_NAMES,
} from '../usb-controls.types';
import USBControlsDocumentation from './USBControlsDocumentation.vue';

export default {
  name: 'DeviceRulesTable',
  compatConfig: {
    MODE: 3,
  },
  components: {
    Upload,
    JamfTableClientSide,
    USBControlsDocumentation,
    HelpButton,
    // eslint-disable-next-line vue/no-unused-components
    NoRowsAddUSBControl,
  },
  props: {
    values: Array,
    type: String,
    canWrite: Boolean,
  },
  emits: ['update:values'],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  validations() {
    const activeAndNotUpload = this.isAddItemActive && !this.isUpload;
    return {
      vendorOrSerialList: {
        requiredIf: requiredIf(activeAndNotUpload && !this.isTypeProduct),
        hexadecimalIf: hexadecimalIf(
          activeAndNotUpload && !this.isTypeProduct && this.isTypeVendor
        ),
      },
      productToAdd: {
        vendor: {
          requiredIf: requiredIf(activeAndNotUpload && this.isTypeProduct),
          hexadecimal,
        },
        product: {
          requiredIf: requiredIf(activeAndNotUpload && this.isTypeProduct),
          hexadecimal,
        },
      },
    };
  },
  data() {
    return {
      USBCONTROL_TYPES_NAMES,
      isRemoveItemsActive: false,
      isAddItemActive: false,
      isHelpActive: false,
      productToAdd: { vendor: null, product: null },
      dataToAdd: null,
      isUpload: false,
      uploadFile: null,
      options: {
        noRowsOverlayComponent: 'NoRowsAddUSBControl',
        noRowsOverlayComponentParams: {
          controlType: () => USBCONTROL_TYPES_NAMES[this.type],
        },
      },
      columns: {
        [USBCONTROL_TYPES.Vendor]: [
          { type: 'selectAllColumn' },
          {
            headerName: USBCONTROL_TYPES_NAMES.Vendor,
            valueGetter: (params) => params.data,
          },
        ],
        [USBCONTROL_TYPES.Serial]: [
          { type: 'selectAllColumn' },
          {
            headerName: USBCONTROL_TYPES_NAMES.Serial,
            valueGetter: (params) => params.data,
          },
        ],
        [USBCONTROL_TYPES.Product]: [
          { type: 'selectAllColumn' },
          {
            headerName: USBCONTROL_TYPES_NAMES.Vendor,
            field: 'vendor',
          },
          {
            headerName: USBCONTROL_TYPES_NAMES.Product,
            field: 'product',
          },
        ],
      },
    };
  },
  computed: {
    internalValues: {
      get() {
        return this.values;
      },
      set(value) {
        this.$emit('update:values', value);
      },
    },
    vendorOrSerialHelperText() {
      const valuesType = this.isTypeVendor
        ? '0x1921,0x1434'
        : '575833314133343231313937,0700079716000026';
      return `Comma separated values (e.g. ${valuesType})`;
    },
    vendorOrSerialErrorMessage() {
      return this.isTypeVendor
        ? `One or more entries have an invalid format. ${this.v$.vendorOrSerialList.hexadecimalIf.$message}`
        : this.v$.vendorOrSerialList.requiredIf.$message;
    },
    isTypeVendor() {
      return this.type === USBCONTROL_TYPES.Vendor;
    },
    isTypeProduct() {
      return this.type === USBCONTROL_TYPES.Product;
    },
    vendorOrSerialList() {
      return typeof this.dataToAdd === 'string'
        ? this.dataToAdd?.split(',').map((value) => value.trim())
        : this.dataToAdd || [];
    },
    dataListCount() {
      return this.vendorOrSerialList.length;
    },
    productsData() {
      return this.isUpload ? this.dataToAdd : [this.productToAdd];
    },
    modalAddButtonDisabled() {
      return (
        (!this.dataToAdd || !this.dataListCount) &&
        !(this.productToAdd.vendor && this.productToAdd.product)
      );
    },
  },
  methods: {
    async addUploadData() {
      const data = await this.parse();
      this.dataToAdd = this.isTypeProduct ? data : data?.join();
      this.isUpload = true;
      this.isAddItemActive = true;
    },
    addItems(replace) {
      this.v$.$touch();
      if (!this.v$.vendorOrSerialList.$error && !this.v$.productToAdd.$error) {
        // important to close modal first so visuals dont shift
        this.isAddItemActive = false;
        const items = this.isTypeProduct
          ? this.productsData
          : this.vendorOrSerialList?.filter((val) => val !== '');
        this.internalValues = [
          ...(replace ? [] : this.internalValues),
          ...items,
        ];
        this.cancelAdd();
      }
    },
    removeItems(gridApi) {
      const afterRemove = [];
      const selected = gridApi.getSelectedRows();
      gridApi.applyTransaction({ remove: selected });
      gridApi.forEachNode((node) => {
        afterRemove.push(node.data);
      });
      this.internalValues = afterRemove;

      this.isRemoveItemsActive = false;
    },
    exportData(gridApi) {
      gridApi.exportDataAsCsv({
        fileName: `device-controls-${this.type}s`,
      });
    },
    parse() {
      if (this.uploadFile) {
        const isProduct = this.isTypeProduct;
        return new Promise((resolve, reject) => {
          this.$papa.parse(this.uploadFile, {
            complete(results) {
              if (isProduct) {
                resolve(
                  results.data
                    .filter((item) => item.length === 2)
                    .map(([vendor, product]) => [
                      vendor?.trim(),
                      product?.trim(),
                    ])
                    .filter(
                      ([vendor, product]) =>
                        hexadecimal.$validator(vendor) &&
                        hexadecimal.$validator(product)
                    )
                    .map(([vendor, product]) => ({ vendor, product }))
                );
              } else {
                resolve(results.data.flat().filter((item) => item !== ''));
              }
            },
            error(err) {
              reject(err);
            },
          });
        });
      }
    },
    cancelAdd() {
      this.v$.$reset();
      this.isAddItemActive = false;
      this.dataToAdd = null;
      this.isUpload = false;
      this.uploadFile = null;
      this.productToAdd = { vendor: null, product: null };
    },
  },
};
</script>
<style lang="scss" scoped>
.is-mobile {
  --size-action-height-base: 32px !important;
}
</style>
