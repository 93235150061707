<template>
  <AnalyticsList
    include-selection
    :is-managed="isManaged"
    :is-read-only="isReadOnly"
    v-model="analytics"
  >
    <template #default>
      <div class="p-2 g-rows">
        <div class="g-wrap min-wrap-400">
          <div class="g-rows">
            <j-input
              label="Name"
              :is-read-only="isReadOnly"
              v-model="set.name"
              data-feature-id="name"
              :has-error="v$.set.$error"
              :error-text="nameErrorMessage"
            />
            <j-textarea
              label="Description"
              :rows="3"
              data-feature-id="description"
              is-not-resizable
              :is-read-only="isReadOnly"
              v-model="set.description"
            />
          </div>
          <RelatedPlansTagList v-if="hasPlans" :plans="set.plans" />
        </div>
        <label class="h4" v-if="!isManaged">Analytics in this set</label>
      </div>
    </template>
  </AnalyticsList>
</template>

<script>
import { required } from '@vuelidate/validators';
import { mapState } from 'vuex';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import AnalyticsList from '../components/AnalyticsList.vue';
import RelatedPlansTagList from '@/components/RelatedPlansTagList.vue';
import { isUniqueName } from '@/util/custom-validators';
import { useRBAC } from '@/composables/rbac';
import { useForm } from '@/composables/forms';

export default {
  name: 'AnalyticsSet',
  components: { AnalyticsList, RelatedPlansTagList },
  setup() {
    const { canWrite, canAccess } = useRBAC(RBAC_RESOURCE.ANALYTIC_SET);
    const { v$, mode } = useForm();

    return { v$, mode, canWrite, canAccess };
  },
  props: {
    analyticSet: Object,
  },
  validations() {
    return {
      set: {
        name: {
          required,
          isUniqueName: isUniqueName(this.analyticSet?.name),
        },
      },
    };
  },
  data() {
    return {
      set: {
        plans: null,
        analytics: [],
        name: '',
        description: '',
        type: 'notify',
      },
      name: null,
      loaded: false,
      analytics: [],
    };
  },
  computed: {
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) => state.analyticSets.analyticSetsNames,
    }),
    nameErrorMessage() {
      return this.v$.set.name.$errors[0]?.$message;
    },
    hasPlans() {
      return (
        this.canAccess(RBAC_RESOURCE.PLAN) &&
        !this.mode.create &&
        this.set.plans
      );
    },
    isReadOnly() {
      return this.isManaged || !this.canWrite;
    },
    isManaged() {
      return this.set.managed;
    },
    analyticsUuids() {
      return this.analytics.map(({ uuid }) => uuid);
    },
  },
  async mounted() {
    if (this.$route.query.copy) {
      this.set = {
        ...this.analyticSet,
        name: `${this.analyticSet.name} Copy`,
        managed: false,
      };

      this.analytics = [...this.analyticSet.analytics];
    }
    if (!this.mode.create) {
      this.set = { ...this.analyticSet };
      this.analytics = [...this.set.analytics];
    }
  },
  methods: {
    async submit() {
      const result = await this.$store.dispatch(
        this.mode.create
          ? 'primary/createAnalyticSet'
          : 'primary/updateAnalyticSet',
        {
          ...this.set,
          analytics: this.analyticsUuids,
        }
      );
      this.set.uuid = result?.uuid;
    },
  },
};
</script>
