import AdminSection from './AdminSection.vue';
import Tenants from './tabs/tenants/Tenants.vue';
import Tenant from './tabs/tenants/Tenant.vue';
import TenantDetails from './tabs/tenants/TenantDetails.vue';
import TenantForm from './tabs/tenants/TenantForm.vue';
import AnalyticAdmin from './tabs/analytics/AnalyticAdmin.vue';
import AnalyticDetails from '../../analytics/tabs/AnalyticDetails.vue';
import Package from './tabs/packages/Package.vue';
import Packages from './tabs/packages/Packages.vue';
import Section from '@/components/Section.vue';
import GenerateToken from './tabs/utilities/GenerateToken.vue';
import AdminGuard from './admin.guard';
import AuditLogs from '@/modules/audit-logs/AuditLogs.vue';
import Analytics from '@/modules/analytics/Analytics.vue';

export default [
  {
    path: '/admin',
    component: AdminSection,
    meta: {
      breadcrumb: {
        linkName: 'admin.tenants.index',
        linkText: 'Admin',
      },
    },
    beforeEnter: AdminGuard,
    children: [
      {
        path: 'tenants',
        component: Section,
        meta: {
          breadcrumb: {
            linkName: 'admin.tenants.index',
            linkText: 'Tenants',
          },
        },
        children: [
          {
            path: '',
            component: Tenants,
            name: 'admin.tenants.index',
          },
          {
            path: '',
            component: Tenant,
            children: [
              {
                path: 'create',
                component: TenantForm,
                name: 'admin.tenants.index.create',
                meta: {
                  breadcrumb: {
                    linkText: 'Create',
                  },
                },
              },
            ],
          },
          {
            path: ':id',
            component: Tenant,
            meta: {
              breadcrumb: {
                linkText: 'Tenant',
              },
            },
            children: [
              {
                path: '',
                component: TenantDetails,
                name: 'admin.tenants.index.detail',
              },
              {
                path: 'edit',
                component: TenantForm,
                name: 'admin.tenants.index.edit',
              },
            ],
          },
        ],
      },
      {
        path: 'analytics',
        component: Section,
        meta: {
          breadcrumb: {
            linkName: 'admin.analytics.index',
            linkText: 'Analytics',
          },
        },
        children: [
          {
            path: '',
            component: Analytics,
            name: 'admin.analytics.index',
            props: { isAdmin: true },
          },
          {
            path: ':id',
            component: AnalyticAdmin,
            meta: {
              breadcrumb: {
                linkName: 'admin.analytics.index.detail',
                linkText: 'Analytic Info',
              },
            },
            children: [
              {
                path: '',
                name: 'admin.analytics.index.detail',
                component: AnalyticDetails,
                props: { isAdmin: true },
              },
            ],
          },
        ],
      },
      {
        path: 'packages',
        component: Section,
        meta: {
          breadcrumb: {
            linkText: 'Packages',
          },
        },
        children: [
          {
            path: 'protect',
            component: Packages,
            name: 'admin.packages.protect',
            props: { isCompliance: false },
            children: [
              {
                path: ':id',
                component: Package,
                name: 'admin.packages.protect.item',
              },
            ],
          },

          {
            path: 'compliance',
            component: Packages,
            name: 'admin.packages.compliance',
            props: { isCompliance: true },
            children: [
              {
                path: ':id',
                component: Package,
                name: 'admin.packages.compliance.item',
              },
            ],
          },
        ],
      },
      {
        path: 'audit-logs',
        component: AuditLogs,
        name: 'admin.auditlogs.index',
        meta: {
          breadcrumb: {
            linkName: 'admin.auditlogs.index',
            linkText: 'Audit Logs',
          },
        },
      },
      {
        path: 'utilities',
        component: Section,
        name: 'admin.utilities.index',
        meta: {
          breadcrumb: {
            linkName: 'admin.utilities.index',
            linkText: 'Utilities',
          },
        },
        children: [
          {
            path: 'token',
            component: GenerateToken,
            name: 'admin.utilities.index.token',
            meta: {
              breadcrumb: {
                linkName: 'admin.utilities.index.token',
                linkText: 'Token',
              },
            },
            children: [],
          },
        ],
      },
    ],
  },
];
