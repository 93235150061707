<template>
  <ListItem title="Event Timestamp">
    {{ formatEpochDate(event.timestamp) }}
  </ListItem>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'GPSyntheticClickEvent',
  components: { ListItem },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
