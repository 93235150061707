<template>
  <div class="expression">
    <span style="display: none">{{ computedExpression }}</span>
    <div class="field">
      <div class="field-body g-cols">
        <AutoCompleteInput :def="def" v-model:expression="leftExpression" />
        <j-remove-button @click="remove" class="js-end" />
      </div>
    </div>

    <div class="field">
      <div class="field-body g-cols middle-row">
        <div class="field">
          <div class="control">
            <j-select
              v-model="selectedFieldComparison"
              :options="operators"
              placeholder="Select an operator"
            />
          </div>
        </div>
        <div class="field">
          <j-select
            v-model="selectedStringOptions"
            :options="stringOptions"
            :searchable="false"
            deselect-from-dropdown
            placeholder=" "
          />
        </div>
      </div>
    </div>

    <div class="field">
      <div class="field-body g-cols">
        <div class="field">
          <p class="control">
            <j-input v-model="selectedFieldValue" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoCompleteInput from '@/components/analytics/editor/AutoCompleteInput.vue';

export default {
  name: 'QueryBuilderExpression',
  components: {
    AutoCompleteInput,
  },
  props: {
    depth: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    fieldPrepend: {
      type: String,
      required: true,
    },
    def: {
      type: Object,
      required: true,
    },
    expression: {
      type: String,
      required: true,
    },
  },
  emits: ['update:expression', 'child-deletion-requested'],
  data() {
    return {
      leftExpression: '',
      selectedFieldObject: null,
      selectedField: null,
      selectedFieldComparison: '',
      selectedFieldValue: '',
      selectedStringOptions: '',
      stringOptions: ['c', 'd', 'cd'],
      operators: [
        'CONTAINS',
        'IN',
        'BEGINSWITH',
        'ENDSWITH',
        'LIKE',
        'MATCHES',
        '==',
        '!=',
        '>',
        '<',
        '>=',
        '<=',
      ],
    };
  },
  computed: {
    processedField() {
      return `${this.fieldPrepend}${this.selectedField}`;
    },
    processedValue() {
      return `${this.selectedFieldValue}`;
    },
    processStringOptions() {
      if (this.selectedStringOptions) {
        return `[${this.selectedStringOptions}]`;
      }
      return '';
    },
    computedExpression() {
      let expression = '';
      expression = `${this.leftExpression}`;
      if (this.selectedFieldComparison) {
        expression = `${expression} ${this.selectedFieldComparison}`;
      }
      if (this.processStringOptions) {
        expression = `${expression}${this.processStringOptions}`;
      }
      if (this.processedValue) {
        expression = `${expression} ${this.processedValue}`;
      }
      this.$emit('update:expression', expression);
      return expression;
    },
  },
  watch: {
    computedExpression() {
      this.$emit('update:expression', this.computedExpression);
    },
  },
  methods: {
    remove() {
      this.$emit('child-deletion-requested', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
$spacing: 10px;

.middle-field {
  margin: $spacing 0;
}

.middle-row {
  justify-content: left;
}

.control {
  position: relative;
}

.expression {
  @include border;
  padding: spacing(2);
  margin: spacing(2) 0;
  border-radius: 4px;
}
</style>
