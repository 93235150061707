<template>
  <div class="scroll-vertical p-2">
    <div v-if="analytic" class="analytic-details">
      <Card
        v-if="description && description.length >= 600"
        class="analytic-description"
      >
        <Markdown :text="description" />
      </Card>
      <Card class="analytic-info g-col-2 row-max-c">
        <div class="col-span-1 g-rows">
          <Markdown
            v-if="description && description.length < 600"
            :text="description"
          />
          <div class="f-wrap">
            <ListItem v-if="isAdmin" title="Release Group">
              <j-tag>{{ analytic.releaseGroup }}</j-tag>
            </ListItem>
            <ListItem v-else title="Owner">
              <div class="g-cols max-c ai-center gap-1">
                <template v-if="analytic.jamf"
                  ><j-icon data="@icon/jamf.svg" />Jamf</template
                >
                <template v-else
                  ><j-icon data="@jcon/team.svg" />{{ orgName }}</template
                >
              </div>
            </ListItem>
            <ListItem title="Severity">
              <div class="g-cols max-c ai-center gap-1">
                <severity-indicator
                  :level="analytic.tenantSeverity || analytic.severity"
                />
                {{ analytic.tenantSeverity || analytic.severity }}
              </div>
            </ListItem>
            <ListItem title="Event Type">
              <div class="g-cols max-c ai-center gap-1">
                <AnalyticEventTypeIcon :input-type="analytic.inputType" />
                {{ eventName }}
              </div>
            </ListItem>
          </div>

          <span v-if="predicate" class="helper-text">Predicate</span>
          <Markdown v-if="predicate" :text="predicate" />
          <hr />
        </div>
        <ListItem title="Created">
          {{ formatEpochDate(analytic.created) }}
        </ListItem>
        <ListItem title="Updated">
          {{ formatEpochDate(analytic.updated) }}
        </ListItem>
        <ListItem title="Level">
          {{ analytic.level }}
        </ListItem>
        <ListItem title="Category">
          <div class="tags">
            <j-tag v-for="cat in analytic.categories" :key="cat">{{
              cat
            }}</j-tag>
          </div>
        </ListItem>
        <ListItem title="Actions">
          <div v-if="analytic.tenantActions" class="tags">
            <j-tag v-for="{ name } in tenantActions" :key="name">
              {{ name }}
            </j-tag>
          </div>
          <div v-else class="tags">
            <j-tag v-for="{ name } in analyticActions" :key="name">
              {{ name }}
            </j-tag>
          </div>
        </ListItem>
        <ListItem title="Tags">
          <div class="tags">
            <j-tag v-for="tag in analytic.tags" :key="tag">{{ tag }}</j-tag>
          </div>
        </ListItem>
        <ListItem title="Snapshot Files">
          <code v-if="analytic.snapshotFiles.length">{{
            analytic.snapshotFiles.join(', ')
          }}</code>
        </ListItem>
        <ListItem v-if="analytic.context.length" title="Context Items">
          {{ analytic.context.length }}
        </ListItem>
      </Card>

      <Card
        v-if="analytic.context.length"
        class="analytic-context g-rows max-c"
      >
        <h1 class="subtitle-text">
          Context Items ({{ analytic.context.length }})
        </h1>
        <div
          class="context-info g-rows max-c"
          v-for="(item, index) in analytic.context"
          :key="item.name + index"
        >
          <div class="g-col-2">
            <ListItem title="Name">
              {{ item.name }}
            </ListItem>
            <ListItem title="Type">
              {{ item.type }}
            </ListItem>
          </div>
          <div
            v-for="(expr, i) in item.exprs"
            :key="expr + i"
            class="g-rows gap-0"
          >
            <span class="helper-text">Expression ({{ i + 1 }})</span>
            <Markdown :text="codeBlock(expr, 'javascript')" />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useGmt } from '@/composables/gmt';
import AnalyticEventTypeIcon from '@/components/analytics/AnalyticEventTypeIcon.vue';
import SeverityIndicator from '@/components/SeverityIndicator.vue';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';
import Markdown from '@/components/Markdown.vue';
import { EVENT_TYPES_NAMES_ALL } from '@/util/constants/event.types';

export default {
  name: 'AnalyticDetails',
  components: {
    AnalyticEventTypeIcon,
    SeverityIndicator,
    Card,
    ListItem,
    Markdown,
  },
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    analytic: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      orgName: (state) => state.app.info.name,
    }),
    eventName() {
      return EVENT_TYPES_NAMES_ALL[this.analytic.inputType];
    },
    predicate() {
      return this.analytic.filter ? this.codeBlock(this.analytic.filter) : null;
    },
    analyticActions() {
      return this.analytic?.analyticActions?.filter((action) =>
        action.name.match('SmartGroup')
      );
    },
    tenantActions() {
      return this.analytic?.tenantActions?.filter((action) =>
        action.name.match('SmartGroup')
      );
    },
    description() {
      const remediationText = this.analytic.remediation
        ? `| Remediation: |\n| ---- |\n|${this.analytic.remediation}|`
        : '';
      return `#### ${this.analytic.name} 
        \n${this.analytic.longDescription || this.analytic.description || ''}\n
${remediationText}`;
    },
  },
  methods: {
    codeBlock(value, type = 'bash') {
      return `\`\`\` ${type}
${value}
\`\`\``;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytic-details {
  @include grid;
  grid-template-columns: repeat(2, fit-content(50%));

  > div {
    height: max-content;
  }
  .row-max-c {
    grid-auto-rows: max-content;
  }
  @include breakpoint(small down) {
    @include flex-wrap;
  }
}

.context-info {
  @include border($color: var(--color-border-secondary));
  @include border-radius;
  padding: spacing();
}
</style>
