<template>
  <div class="scroll-vertical">
    <div class="grid">
      <transition name="fade">
        <div
          class="g-rows"
          v-if="!hasLimitedAppAccess || isFeatureEnabled('HC_MODE')"
        >
          <div class="account-downloads">
            <h1>Agent Download</h1>
            <div class="downloads">
              <a
                class="g-cols gap-1"
                data-feature-id="vanillaPackage"
                tabindex="0"
                @click="getDownload('vanillaPackage')"
                @keydown.enter="getDownload('vanillaPackage')"
              >
                <j-icon data="@jcon/download-2.svg" height="12" width="12" />
                <span class="align-center">
                  Jamf Protect PKG
                  <transition name="fade"
                    ><span v-if="vanillaPackageVersion"
                      >({{ vanillaPackageVersion }})</span
                    ></transition
                  >
                </span>
              </a>
            </div>
          </div>
          <div class="account-downloads">
            <h1>Uninstaller Download</h1>
            <p>
              Removes the agent and files installed by the Jamf Protect PKG.
              This uninstaller does not remove the Plan profile.
            </p>
            <div class="downloads">
              <a
                data-feature-id="uninstallerPackage"
                tabindex="0"
                class="g-cols gap-1"
                @click="getDownload('vanillaPackage', 'uninstallerPackage')"
                @keydown.enter="
                  getDownload('vanillaPackage', 'uninstallerPackage')
                "
              >
                <j-icon data="@jcon/download-2.svg" height="12" width="12" />
                <span>
                  Jamf Protect Uninstaller PKG
                  <transition name="fade"
                    ><span v-if="vanillaPackageVersion"
                      >({{ vanillaPackageVersion }})</span
                    ></transition
                  >
                </span>
              </a>
            </div>
            <div
              class="uninstaller-token"
              v-if="isFeatureEnabled('UNINSTALLER_TOKEN') && cloudOnlyAccess"
            >
              <h2 class="h5">Generate Uninstaller Token</h2>
              <p>This token will be valid for the number of days selected.</p>
              <div class="f-cols mb-2 ai-center" v-if="canWrite">
                <j-select
                  data-feature-id="days"
                  v-model="days"
                  :options="numberOfDays"
                  :searchable="numberOfDays > 10"
                  is-small
                />
                <j-button data-feature-id="generateToken" @click="generateToken"
                  >Generate Token</j-button
                >
              </div>
              <transition name="fade">
                <j-notification
                  v-if="profile && canWrite"
                  type="success"
                  class="downloads-notification"
                  data-feature-id="profile-notification"
                  ><div class="downloads">
                    <a
                      data-feature-id="tokenProfile"
                      tabindex="0"
                      class="g-cols gap-1"
                      @click="
                        decodeAndDownloadProfile(
                          '.mobileconfig',
                          'UninstallerProfile',
                          profile
                        )
                      "
                      @keydown.enter="
                        decodeAndDownloadProfile(
                          '.mobileconfig',
                          'UninstallerProfile',
                          profile
                        )
                      "
                    >
                      <j-icon
                        data="@jcon/download-2.svg"
                        height="16"
                        width="16"
                      />
                      <strong> Token Profile </strong>
                    </a>
                  </div>
                  <p>
                    Make sure to download the profile now. You won't be able to
                    see it again!
                  </p>
                </j-notification>
              </transition>
              <transition name="fade">
                <j-notification
                  v-if="token && canWrite"
                  type="success"
                  class="downloads-notification"
                  data-feature-id="token-notification"
                >
                  <j-tooltip
                    text="Copy Token"
                    open-position="top"
                    class="w-100"
                  >
                    <pre
                      tabindex="0"
                      class="copy w-100 mb-2"
                      data-feature-id="uninstallerToken"
                      @keydown.enter="copyInfo(token, 'Uninstaller Token')"
                      @click="copyInfo(token, 'Uninstaller Token')"
                    >
              {{ token }}
            </pre
                    >
                  </j-tooltip>
                  <p>
                    Make sure to copy the token now. You won't be able to see it
                    again!
                  </p>
                </j-notification>
              </transition>
              <transition name="fade">
                <j-notification
                  v-if="!canWrite"
                  type="warning"
                  class="downloads-notification"
                  data-feature-id="cant-write-notification"
                >
                  <span>
                    No Access. You do not have the correct permissions to
                    generate a Token.
                  </span>
                </j-notification>
              </transition>
            </div>
          </div>
          <div class="account-downloads">
            <h1>
              {{ cloudOnlyAccess ? 'Advanced Downloads' : 'Safelist Profile' }}
            </h1>
            <p>
              {{ advancedDownloadDescription }}
            </p>
            <div class="downloads">
              <a
                v-for="{ label, type, ext } of downloadTypes"
                :key="type"
                :data-feature-id="type"
                class="g-cols gap-1"
                tabindex="0"
                @keydown.enter="getDownload(type, ext, label)"
                @click="getDownload(type, ext, label)"
              >
                <j-icon data="@jcon/download-2.svg" height="12" width="12" />
                <span>{{ label }}</span>
              </a>
            </div>
          </div>
          <transition name="fade">
            <div class="account-downloads" v-if="installerUuid">
              <h1>Generate Download URL</h1>
              <p>
                Generate a unique URL to use to download packages without
                signing into the Jamf Protect web app.
              </p>
              <div class="f-cols mb-1">
                <j-toggle-group data-feature-id="toggleUrlPkg">
                  <j-radio
                    v-model="packageType"
                    native-value="installer"
                    :name="toggleName"
                    >Installer</j-radio
                  >
                  <j-radio
                    v-model="packageType"
                    native-value="uninstaller"
                    :name="toggleName"
                    >Uninstaller</j-radio
                  >
                </j-toggle-group>
                <j-button
                  v-if="!app.info.configFreeze && canWrite"
                  data-feature-id="generateUrl"
                  @click="showWarning = true"
                  >Generate New URL</j-button
                >
              </div>
              <Card class="g-rows mb-2">
                <j-tooltip
                  text="Copy Download URL"
                  open-position="top"
                  class="w-100"
                >
                  <pre
                    v-if="!generatingUrl"
                    tabindex="0"
                    class="copy w-100"
                    data-feature-id="installerUuid"
                    @keydown.enter="copyInfo(uuidUrl, 'Download URL')"
                    @click="copyInfo(uuidUrl, 'Download URL')"
                  >
                {{ uuidUrl }}
              </pre
                  >
                  <gp-loader v-else />
                </j-tooltip>
              </Card>
            </div>
          </transition>
        </div>
      </transition>
      <transition name="fade">
        <div class="account-downloads" v-if="complianceReporter">
          <h1>Compliance Reporter</h1>
          <p class="mb-1">
            For more information, see the
            <a
              class="docs-link"
              href="http://docs.jamf.com/compliance-reporter/documentation/index.html"
              target="_blank"
              tabindex="0"
              rel="noopener"
            >
              Compliance Reporter documentation
              <j-icon data="@jcon/expand-2.svg" height="12" width="12" />
            </a>
          </p>
          <div class="downloads">
            <a
              v-for="{ label, type, ext } of complianceReporterDownload"
              :key="type"
              class="g-cols gap-1"
              :data-feature-id="type"
              tabindex="0"
              @keydown.enter="getDownload(type, ext, label)"
              @click="getDownload(type, ext, label)"
            >
              <template v-if="label.includes('PKG')">
                <j-icon
                  data="@icon/os_icon.svg"
                  height="24"
                  width="24"
                  :original="true"
                />
                <span>{{ label }} ({{ complianceReporterVersion }})</span>
              </template>
              <template v-else
                ><j-icon
                  data="@jcon/download-2.svg"
                  height="12"
                  width="12"
                /><span>{{ label }}</span></template
              >
            </a>
          </div>
          <Card class="mt-2 g-rows">
            <ListItem :title="complianceReporterLabels.signature">
              <j-tooltip
                :text="`Copy ${complianceReporterLabels.signature}`"
                open-position="top"
              >
                <pre
                  class="copy"
                  tabindex="0"
                  data-feature-id="signature"
                  @keydown.enter="
                    copyInfo(
                      complianceReporter.signature,
                      complianceReporterLabels.signature
                    )
                  "
                  @click="
                    copyInfo(
                      complianceReporter.signature,
                      complianceReporterLabels.signature
                    )
                  "
                  >{{ complianceReporter.signature }}</pre
                >
              </j-tooltip>
            </ListItem>
            <ListItem :title="complianceReporterLabels.expirationDate">
              <j-tooltip
                :text="`Copy ${complianceReporterLabels.expirationDate}`"
                open-position="right"
              >
                <span
                  class="copy"
                  tabindex="0"
                  data-feature-id="expirationDate"
                  @keydown.enter="
                    copyInfo(
                      complianceReporter.expirationDate,
                      complianceReporterLabels.expirationDate
                    )
                  "
                  @click="
                    copyInfo(
                      complianceReporter.expirationDate,
                      complianceReporterLabels.expirationDate
                    )
                  "
                >
                  {{ complianceReporter.expirationDate }}
                </span>
              </j-tooltip>
            </ListItem>
            <ListItem :title="complianceReporterLabels.emailContact">
              <j-tooltip
                :text="`Copy ${complianceReporterLabels.emailContact}`"
                open-position="right"
              >
                <span
                  class="copy"
                  tabindex="0"
                  data-feature-id="emailContact"
                  @click="
                    copyInfo(
                      complianceReporter.emailContact,
                      complianceReporterLabels.emailContact
                    )
                  "
                  @keydown.enter="
                    copyInfo(
                      complianceReporter.emailContact,
                      complianceReporterLabels.emailContact
                    )
                  "
                  >{{ complianceReporter.emailContact }}</span
                >
              </j-tooltip>
            </ListItem>
            <ListItem :title="complianceReporterLabels.licenseCount">
              <span>{{ complianceReporter.licenseCount }}</span>
            </ListItem>
          </Card>
        </div>
      </transition>
      <j-modal v-model="showWarning" type="warning" :close-on-overlay="false">
        <template #header="{ alignClass, iconClass }">
          <section :class="alignClass">
            <j-icon data="@jcon/warning.svg" :class="iconClass" />
            <h1 class="h3">Are you sure?</h1>
          </section>
        </template>
        <p>
          Your current URL will no longer be valid. This action cannot be
          undone.
        </p>
        <template #actions="{ close }">
          <j-button style-type="secondary" @click="close">No</j-button>
          <j-button @click="updateUuid()">Yes</j-button>
        </template>
      </j-modal>
    </div>
  </div>
</template>

<script>
import { base64toBlob, downloadFromUrl } from '@/util';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';
import { useGmt } from '@/composables/gmt';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapGetters, mapState } from 'vuex';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'Downloads',
  components: { Card, ListItem },
  setup() {
    const { getDate } = useGmt();
    const { canWrite, cloudOnlyAccess } = useRBAC(RBAC_RESOURCE.DOWNLOAD);

    return { getDate, canWrite, cloudOnlyAccess };
  },
  data() {
    return {
      toggleName: 'package-type-installer',
      downloadTypes: [
        {
          label: 'Jamf Protect Safelist Profile',
          type: 'pppc',
          ext: '.mobileconfig',
        },
        { label: 'Root CA Certificate', type: 'rootCA', ext: '.der' },
        { label: 'CSR Certificate', type: 'csr', ext: '.p12' },
        {
          label: 'WebSocket Authorizer Key',
          type: 'websocket_auth',
          ext: '.p12',
        },
      ],
      complianceReporterDownload: [
        {
          label: 'Compliance Reporter Installer PKG',
          type: 'complianceReporter.vanillaPackage',
        },
        {
          label: 'Compliance Reporter Uninstaller PKG',
          type: 'complianceReporter.uninstallerPackage',
          ext: 'uninstallerPackage',
        },
        {
          label: 'Compliance Reporter PPPC Profile',
          type: 'complianceReporter.pppc',
          ext: '.mobileconfig',
        },
      ],
      numberOfDays: Array.from({ length: 31 }, (_, i) => ({
        label: `${i + 1} Days`,
        value: i + 1,
      })),
      vanillaPackageVersion: null,
      downloads: null,
      tokenDownloads: null,
      complianceReporter: null,
      complianceReporterLabels: {
        signature: 'License Key',
        expirationDate: 'Expiration Date',
        emailContact: 'Contact',
        licenseCount: 'License Count',
      },
      complianceReporterVersion: null,
      installerUuid: null,
      packageType: 'installer',
      generatingUrl: false,
      days: 1,
      showWarning: false,
    };
  },
  computed: {
    ...mapState(['app']),
    ...mapGetters(['isFeatureEnabled', 'hasLimitedAppAccess']),
    uuidUrl() {
      return `curl -f "https://${window.location.host}/${this.packageType}.pkg?${this.installerUuid}" -o ${this.packageType}.pkg`;
    },
    token() {
      return this.tokenDownloads?.data.generateUninstallerToken.token;
    },
    profile() {
      return this.tokenDownloads?.data.generateUninstallerToken.profile;
    },
    advancedDownloadDescription() {
      return this.cloudOnlyAccess
        ? `Individual files that can be downloaded for advanced deployments.
            All files are included in a plan profile by default. The Jamf Protect Safelist Profile includes PPPC, System Extension,
        and Login & Background Item payloads.`
        : `You must safelist Jamf Protect on computers. Distribute the Jamf Protect Safelist Profile using your MDM solution before
            you install Jamf Protect. This profile includes PPPC, System Extension, and Login & Background Item payloads.`;
    },
  },
  async beforeMount() {
    if (!this.cloudOnlyAccess) {
      this.downloadTypes = [this.downloadTypes[0]];
    }
    const { downloads, complianceReporter } = await this.$store.dispatch(
      'primary/getOrganizationDownloads',
      {
        hasCompliance: this.app.info.complianceReporterEnabled || false,
      }
    );
    if (downloads) {
      this.downloads = downloads;
      this.vanillaPackageVersion = this.downloads.vanillaPackage.version;
      this.installerUuid = this.downloads.installerUuid;
    }
    if (complianceReporter) {
      this.complianceReporter = {
        ...complianceReporter.complianceReporter,
        expirationDate: this.getDate(
          complianceReporter.complianceReporter.expirationDate
        ),
      };
      this.complianceReporterVersion = this.complianceReporter.version;
    }
  },
  methods: {
    async getDownload(type, ext, label) {
      // prevent action before downloads has data
      if (this.downloads || this.complianceReporter) {
        const item = type.includes('complianceReporter')
          ? this.complianceReporter
          : this.downloads[type];
        if (type.includes('vanillaPackage') && ext !== 'uninstallerPackage') {
          downloadFromUrl(item.url);
        } else if (ext === 'uninstallerPackage') {
          downloadFromUrl(item.uninstaller_url);
        } else {
          const profile = item.pppc || item;

          this.decodeAndDownloadProfile(ext, label, profile);
        }
      }
    },
    async decodeAndDownloadProfile(ext, label, profile) {
      const filename = 'JamfProtect-'
        .concat(label.split(' ').join(''))
        .concat(ext);
      const blob = base64toBlob(profile, 'application/octet-stream');
      downloadFromUrl(window.URL.createObjectURL(blob), filename);
    },
    async generateToken() {
      this.tokenDownloads = await this.$store.dispatch(
        'primary/generateUninstallerToken',
        { days: this.days }
      );
    },
    copyInfo(value, type) {
      this.$clipboard(value);
      this.$store.commit('SET_GLOBAL_INFO_MESSAGE', {
        message: `Copied ${type}`,
      });
    },
    async updateUuid() {
      this.showWarning = false;
      this.generatingUrl = true;
      const result = await this.$store.dispatch('primary/updateInstallerUuid', {
        id: this.app.info.uuid,
      });
      this.installerUuid = result.installerUuid;
      this.generatingUrl = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$max-width: 870px;
.grid {
  @include grid;
  @include grid-rows;
  height: auto;
  padding: spacing(2);
  width: 100%;

  .downloads-notification {
    max-width: $max-width;
    margin-bottom: spacing(2);
  }

  .account-downloads {
    --size-action-height-base: var(--size-action-height-secondary);

    grid-column: 1/3;
    h1 {
      @include header4;
      padding-bottom: 0;
    }

    .card-container {
      max-width: $max-width;
    }

    p {
      @include helper-text;
      padding-bottom: spacing();
    }
    .docs-link {
      font-size: var(--size-font-helper-base);
    }

    a:not(.docs-link) {
      display: flex;
      align-items: center;
      width: max-content;

      &:focus {
        @include has-focus;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    .downloads {
      @include flex-wrap($scale: 0);
    }

    .copy {
      cursor: pointer;
      &:hover {
        color: var(--color-font-link-base);
        text-decoration: underline;
      }
      &:focus {
        @include has-focus;
      }
    }
    pre.copy {
      background-color: var(--color-structure-secondary);
      white-space: pre-line;
      width: 100%;
    }
  }

  @include breakpoint(small down) {
    grid-template-columns: unset;

    .account-downloads {
      grid-column: unset;
    }
  }
}
</style>
