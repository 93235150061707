<template>
  <div class="insight-label">
    <template v-if="route">
      <j-tooltip :text="description" open-position="bottom-start">
        <router-link :to="route">{{ label }}</router-link>
      </j-tooltip>
      <div>
        <j-tag
          v-for="(tag, index) in tags"
          is-rounded
          class="outline"
          :key="index"
          >{{ tag }}</j-tag
        >
      </div>
    </template>

    <template v-else>
      <h1>{{ label }}</h1>
      <div>
        <j-tag
          v-for="(tag, index) in tags"
          is-rounded
          class="outline"
          :key="index"
          >{{ tag }}</j-tag
        >
      </div>
      <p>{{ description }}</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InsightLabel',
  props: {
    label: String,
    tags: Array,
    route: Object,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.insight-label {
  @include grid(spacing());
  padding-right: spacing();
  padding-bottom: spacing(2);
  width: 100%;
  height: min-content;

  a {
    font-size: var(--size-font-link-base);
    font-weight: 500;
    color: inherit;
    text-transform: capitalize;
    transform: translateY(-4px); // line up switch and text
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    @include header4;
    text-transform: capitalize;
  }
}
</style>
