<template>
  <div class="gp-content">
    <j-form-bar
      v-if="mode.create || storeThreatprevent"
      :title="mode.create ? 'Create Prevent List' : storeThreatprevent.name"
      :can-write="canWrite"
      @back="() => routeTo()"
      @remove="confirmRemove(storeThreatprevent)"
      @edit="() => routeTo('edit', { id: storeThreatprevent.id })"
      @submit:created="() => routeTo('detail', { id: storeThreatprevent.id })"
      @cancel="() => routeTo('detail', { id: storeThreatprevent.id })"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component
          v-if="!loading"
          :is="Component"
          :prevent-list="threatprevent"
        />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      @confirm="remove"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRouteHelpers } from '@/composables/route-helpers';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'PreventList',
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.PREVENT_LIST);
    const { mode, routeTo } = useRouteHelpers();
    const { confirmRemove, showRemoveModal, toDelete } = useRemoveHelpers();

    return {
      canWrite,
      confirmRemove,
      showRemoveModal,
      toDelete,
      mode,
      routeTo,
    };
  },
  computed: {
    ...mapState('primary', {
      storeThreatprevent: (state) => state.threatprevent.threatprevent,
    }),
    threatprevent() {
      return this.mode.create
        ? {
            name: '',
            description: '',
            type: 'FILEHASH',
            multipleValues: '',
            list: [],
          }
        : this.storeThreatprevent;
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  async beforeMount() {
    if (!this.mode.create) {
      await this.$store.dispatch('primary/getPreventList', {
        id: this.$route.params.id,
      });
    }
    this.loading = false;
  },
  methods: {
    async remove() {
      const response = await this.$store.dispatch('primary/deletePreventList', {
        id: this.storeThreatprevent.id,
      });
      if (response) {
        this.$router.push({ name: 'prevent.custom' });
      }
    },
  },
};
</script>
