<template>
  <j-select-native
    v-if="params.data"
    class="options-cell"
    @input="onChange"
    v-model="value"
    :placeholder="placeholder"
    :icon-color="color"
  >
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option.value || option"
    >
      {{ option.label || option }}
    </option>
  </j-select-native>
</template>

<script>
import SelectNative from '@/components/ui/SelectNative.vue';

export default {
  name: 'TableCellOptions',
  props: ['params'],
  components: { 'j-select-native': SelectNative },
  data() {
    return {
      options: [],
      value: null,
      placeholder: null,
      color: 'inherit',
      callback: null,
    };
  },
  methods: {
    onChange() {
      if (this.callback) {
        this.callback(this.params, this.value);
      }
    },
  },
  computed: {
    hasLoaded() {
      return true;
    },
  },
  mounted() {
    if (this.params.setSelect) {
      const config = this.params.setSelect(this.params);
      if (config) {
        this.options = config.options;
        this.value = config.value;
        this.placeholder = config.placeholder;
        this.callback = config.callback;
      }
    } else {
      // eslint-disable-next-line
      console.error(`[Ag-grid Column error] The tooltip cell component requires a setTooltip function in cellRendererParams to set the message of the tooltip. Returns: Null | String | Object
      Example: cellRendererParams: { setTooltip: params => (params.value === null ? null : { message: 'Something here' }) }`);
    }
  },
};
</script>

<style lang="scss" scoped>
.options-cell {
  --color-input-border-base: transparent;
}
</style>
