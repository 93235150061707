<template>
  <div class="scroll-vertical">
    <div class="form-content">
      <div class="f-wrap">
        <div class="g-rows max-c">
          <j-input
            v-model="internalTelemetry.name"
            data-feature-id="name"
            label="Name"
            :has-error="v$.internalTelemetry.name.$error"
            :error-text="
              v$.internalTelemetry.name.isUniqueName.$invalid
                ? 'A telemetry configuration with this name already exists'
                : 'Name is required'
            "
          />
          <j-textarea
            v-model="internalTelemetry.description"
            data-feature-id="description"
            :rows="3"
            label="Description"
          />

          <j-checkbox
            v-if="isFeatureEnabled('TELEMETRY_EO3')"
            v-model="internalTelemetry.performanceMetrics"
            class="mb-1"
          >
            <span>Collect Performance Metrics</span>
            <p class="helper-text">
              Identifies the processes using the most resources on the system.
            </p></j-checkbox
          >

          <div class="g-rows gap-0">
            <h3 class="h5">Telemetry Log Levels</h3>
            <p class="helper-text">
              Defines the scope of information included in the telemetry audit
              log. Select the level appropriate for the target devices.
              <template v-if="isFeatureEnabled('DOCS_TELEMETRY')">
                See
                <a
                  href="https://docs.jamf.com/jamf-protect/documentation/Telemetry.html"
                  target="blank"
                  rel="noopener"
                >
                  Telemetry
                </a>
                in the Jamf Protect documentation.
              </template>
            </p>

            <div class="g-rows mt-1">
              <j-radio
                v-model="internalTelemetry.level"
                :native-value="1"
                :name="toggleName"
                >Level 1 - Standard
                <p class="helper-text">Suitable for most computers</p></j-radio
              >
              <j-radio
                class="ml-0"
                v-model="internalTelemetry.level"
                :native-value="2"
                :name="toggleName"
                >Level 2 - Sensitive
                <p class="helper-text">
                  Suitable for computers handling sensitive information
                </p></j-radio
              >
              <j-checkbox v-model="internalTelemetry.verbose" class="mb-1"
                >Verbose logging
                <p class="helper-text">
                  Records log data from both administrative and
                  non-administrative activity. When deselected, only actions
                  with administrative rights are logged.
                </p>
              </j-checkbox>
            </div>
          </div>

          <div class="g-rows">
            <h3 class="h5">Log File Collection</h3>
            <j-checkbox
              v-model="internalTelemetry.logFileCollection"
              class="mb-1"
              data-feature-id="collect-log-files"
              v-if="isFeatureEnabled('TELEMETRY_EO3')"
              ><span>Collect Diagnostic and Crash Files</span>
            </j-checkbox>
            <j-textarea
              label="Simple Log Files"
              helper-text="A list of simple-format log files to collect from the monitored device."
              v-model="logFiles"
              :has-error="v$.internalTelemetry.logFiles.$error"
              error-text="Cannot include local log file in list of file paths."
              data-feature-id="log-files"
            />
          </div>
        </div>

        <Card class="g-rows">
          <fieldset class="g-rows max-c" v-if="includeLogFile">
            <legend class="subtitle-text mb-2 g-cols ai-center w-100">
              Local Log File<j-remove-button
                class="js-end"
                @click="includeLogFile = false"
              />
            </legend>

            <j-input
              v-model="localLogConfig.fileOwnership"
              :has-error="v$.localLogConfig.fileOwnership.$error"
              :error-text="fileOwnershipErrorText"
              label="Ownership"
              placeholder="root:wheel"
            />
            <j-input
              v-model="localLogConfig.filePermission"
              label="Permissions"
              placeholder="640"
              :has-error="v$.localLogConfig.filePermission.$error"
              :error-text="filePermissionErrorText"
            />
            <j-input
              v-model.number="localLogConfig.maxBackups"
              label="Max number of backups"
              placeholder="0"
              type="number"
              :has-error="v$.localLogConfig.maxBackups.$error"
              error-text="Required"
            />
            <j-input
              v-model.number="localLogConfig.maxSizeMB"
              label="Max size in megabytes"
              placeholder="0"
              type="number"
              :has-error="v$.localLogConfig.maxSizeMB.$error"
              error-text="Required"
            />
            <j-input
              v-model="localLogConfig.path"
              label="Path"
              :has-error="v$.localLogConfig.path.$error"
              error-text="Required"
            />
          </fieldset>

          <div v-else class="add-config">
            <h2 class="h5">Include local log file</h2>
            <p class="helper-text">
              Configure redundancy rules for all telemetry logs saved to the
              local disk
            </p>
            <j-create-button
              @click="includeLogFile = true"
              label="Add"
              data-feature-id="add-config"
            />
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minValue, between } from '@vuelidate/validators';
import Card from '@/components/Card.vue';
import { mapState, mapGetters } from 'vuex';
import { useForm } from '@/composables/forms';
import { isUniqueName } from '@/util/custom-validators';

export default {
  name: 'TelemetryForm',
  components: { Card },
  props: {
    telemetry: {
      type: Object,
    },
  },
  setup() {
    const { v$, mode } = useForm();
    return { v$, mode };
  },
  validations() {
    return {
      internalTelemetry: {
        name: {
          required,
          isUniqueName: isUniqueName(this.telemetry?.name),
        },
        level: {
          between: between(1, 2),
        },
        logFiles: {
          validLogFiles: (value) => {
            return !value.includes(this.localLogConfig.path);
          },
        },
      },
      localLogConfig: {
        maxBackups: { required, minValue: minValue(1) },
        maxSizeMB: { required, minValue: minValue(1) },
        fileOwnership: {
          required,
          validOwnership: (value) =>
            typeof value === 'string'
              ? value.match(/\w+?:\w+?/g)?.length === 1
              : true,
        },
        filePermission: {
          required,
          validBinary: (value) =>
            typeof value === 'string'
              ? [4, 3].includes(value.match(/[0-7]/g)?.length)
              : false,
        },
        path: { required },
      },
    };
  },
  data() {
    return {
      toggleName: 'telemetry-level',
      includeLogFile: false,
      internalTelemetry: {
        name: '',
        description: '',
        verbose: false,
        level: 1,
        logFiles: [],
        performanceMetrics: false,
        logFileCollection: false,
      },
      localLogConfig: {
        maxBackups: 10,
        maxSizeMB: 100,
        fileOwnership: 'root:wheel',
        filePermission: '640',
        path: '/var/log/JamfProtect.log',
      },
    };
  },
  computed: {
    // this is used in isUniqueName
    ...mapState('primary', {
      duplicateNames: (state) => state.telemetry.telemetryNames,
    }),
    ...mapGetters(['isFeatureEnabled']),
    logFiles: {
      get() {
        return this.internalTelemetry?.logFiles?.join('\n');
      },
      set(value) {
        this.internalTelemetry.logFiles = value.split('\n');
      },
    },
    endpoint() {
      return `primary/${this.mode.create ? 'create' : 'update'}Telemetry`;
    },
    filePermissionErrorText() {
      return !this.v$.localLogConfig.filePermission.required
        ? 'Required'
        : 'Must be 3-4 digits, each ranging 0-7';
    },
    fileOwnershipErrorText() {
      return !this.v$.localLogConfig.fileOwnership.required
        ? 'Required'
        : 'Must be a valid ownership format e.g. root:wheel';
    },
  },
  mounted() {
    if (!this.mode.create) {
      this.internalTelemetry = { ...this.telemetry };
      if (this.telemetry.localLogConfig) {
        this.localLogConfig = { ...this.telemetry.localLogConfig };
        this.includeLogFile = true;
      }
    }
  },
  methods: {
    async trimLogFiles() {
      var trimmedLogFiles = [];
      for (let i = 0; i < this.internalTelemetry.logFiles.length; i++) {
        trimmedLogFiles[i] = this.internalTelemetry.logFiles[i].trim();
      }
      this.internalTelemetry.logFiles = trimmedLogFiles;
    },
    async submit() {
      this.trimLogFiles();
      const response = await this.$store.dispatch(this.endpoint, {
        ...this.internalTelemetry,
        localLogConfig: this.includeLogFile ? { ...this.localLogConfig } : null,
      });
      if (response) {
        this.internalTelemetry.id = response.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-config {
  @include grid(spacing());
  justify-items: center;
  align-content: center;
  height: 100%;
  max-width: 280px;
  justify-self: center;
  line-height: spacing();
  p {
    text-align: center;
    margin-bottom: spacing();
  }
}

// normal placeholder attribute does not allow for line breaks in safari, this fixes that and displays for other browsers as well.
.list-placeholder > :deep() [class*='input-group'] {
  position: relative;

  &::after {
    content: '/var/log/system.log \a /var/log/install.log \a /var/log/jamf.log';
    white-space: pre;
    position: absolute;
    padding: spacing();
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
