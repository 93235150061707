<template>
  <div id="app" tabindex="-1">
    <router-view v-slot="{ Component }">
      <transition name="long-fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Toast
      :show="toast"
      :type="globalErrorMessage ? 'danger' : 'info'"
      @hide="hideToast"
    >
      {{ globalErrorMessage || globalInfoMessage }}
    </Toast>
    <div ref="colors" class="common-colors thanks-chrome">
      <!-- These are common colors that are utilized in canvas elements which can not use css vars -->
      <span
        v-for="color in commonColors"
        :key="color"
        :class="color"
        :style="`background-color: var(${color});`"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Toast from '@/components/ui/Toast.vue';

export default {
  name: 'App',
  components: { Toast },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState({
      configFreeze: (state) => state.app.info.configFreeze,
      hasLDClient: (state) => state.app.hasLDClient,
    }),
    ...mapState('user', ['commonColors', 'scheme', 'mode']),
    ...mapState(['globalErrorMessage', 'globalInfoMessage']),
    isNewLook() {
      return this.hasLDClient && this.isFeatureEnabled('NEW_LOOK');
    },
  },
  data() {
    return {
      toast: false,
      type: null,
      html: null,
    };
  },
  async beforeCreate() {
    // ensure client side flags are initialized immediately
    await this.$store.dispatch('initializeLDClient');
  },
  watch: {
    $route() {
      // 🚨 $nextTick = DOM updated, route successfully transitioned
      this.$nextTick(() => {
        this.$el.focus();
      });
    },
    globalErrorMessage() {
      if (this.globalErrorMessage) {
        this.showToast('SET_GLOBAL_ERROR_MESSAGE');
      }
    },
    globalInfoMessage() {
      if (this.globalInfoMessage) {
        this.showToast('SET_GLOBAL_INFO_MESSAGE');
      }
    },
    mode() {
      this.setMode(this.mode);
      this.setColors();
    },
    isNewLook() {
      this.setThemeFeature();
    },
    scheme: {
      deep: true,
      handler() {
        if (this.scheme) {
          this.setScheme(this.scheme);
          this.setColors();
        }
      },
    },
    configFreeze() {
      this.setFreezeControl();
    },
    hasLDClient() {
      this.setColors();
    },
  },
  beforeMount() {
    this.html = document.documentElement;
    this.setFreezeControl();
    this.setMode(this.mode);
    this.setThemeFeature();
    this.setScheme(this.scheme);
  },
  methods: {
    ...mapMutations(['SET_SCRIPTS_LOADED']),
    setMode(mode) {
      this.html.setAttribute('data-color-mode', mode);
    },
    setScheme(scheme) {
      this.html.setAttribute('data-color-scheme', scheme.color);
      this.html.setAttribute('data-color-contrast', scheme.contrast);
    },
    setFreezeControl() {
      this.html.setAttribute('data-has-banner', this.configFreeze);
    },
    setThemeFeature() {
      this.html.setAttribute('data-new-look', this.isNewLook);
    },
    setColors() {
      const colors = {};

      Array.from(this.$refs?.colors.children || []).forEach((el) => {
        colors[el.className] = window.getComputedStyle(el)['backgroundColor'];
      });
      this.$store.commit('user/SET_COLORS', colors);
    },
    showToast(type) {
      this.toast = true;
      this.type = type;
      setTimeout(() => {
        this.toast = false;
        this.$store.commit(type, { message: null });
      }, 10 * 1000);
    },
    hideToast() {
      this.toast = false;
      this.$store.commit(this.type, { message: null });
    },
  },
};
</script>

<style lang="scss">
// helper classs, e.g. ml-1 which adds margin-left: spacing(1);
@import '~@jamf/design-system-shared/dist/layout.scss';
@import '~@jamf/design-system-shared/dist/spacing.scss';
// all jamf-design-system component styles
@import '~@jamf/design-system-vue/dist/jamf.css';

// Normalizes the base css
@include normalize;
// sets default html styles and size variables
@include defaults;

@include color-mode(dark, true, '', true) {
  @include mode-dark-old;
}

@include color-mode(light, false, '', true) {
  @include mode-light-old;
}

// Set themes for the app
@include color-mode(dark, true) {
  @include mode-dark;
  @include scheme-dark-default;
}

@include color-mode {
  @include mode-light;
  @include scheme-light-default;
}

@include color-mode-contrast(1, dark) {
  @include contrast-dark-1;
}

@include color-mode-scheme(redgreen, dark) {
  @include scheme-dark-redgreen;
}

@include color-mode-scheme(tritanopia, dark) {
  @include scheme-dark-tritanopia;
}

@include color-mode-contrast(1, light) {
  @include contrast-light-1;
}

@include color-mode-scheme(redgreen, light) {
  @include scheme-light-redgreen;
}

@include color-mode-scheme(tritanopia, light) {
  @include scheme-light-tritanopia;
}

@include theme-shared;

// For markdown component
@include hljs;

@import '@/scss/transitions.scss';

// Import vue2-animate
$animationDuration: 0.5s; // specify animation duration. Default value: 1s
@import '~vue2-animate/src/sass/vue2-animate.scss';

@import '@/scss/tables.scss';
@import '@/scss/utils.scss';
@import '@/scss/forms.scss';

.common-colors {
  visibility: hidden;
}

html,
body {
  letter-spacing: normal;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: fixed;
  background: var(--color-structure-base);
  overflow: hidden;
  font-size: var(--size-font-paragraph-base);
  line-height: var(--size-font-paragraph-height);
  color: var(--color-font-base);

  a {
    color: var(--color-primary-base);
    font-size: inherit;
  }

  a:hover {
    color: var(--color-primary-active);
  }

  hr {
    margin: spacing(2) 0;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
  }

  strong {
    color: var(--color-font-base);
  }
}

#app {
  height: 100%;
  color: var(--color-font-base);
}

pre,
code {
  font-size: var(--size-font-label-base);
  font-family: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
  background-color: var(--color-code-block);
  color: var(--color-font-base);
  border-radius: 4px;
  border-style: none;
  box-decoration-break: clone;
  overflow: auto;
  overflow-wrap: break-word;
  padding: spacing(0.25) spacing(0.5);
}
pre {
  tab-size: spacing(2);
  padding: spacing();
  word-break: break-all;
  white-space: pre-wrap;
}

.pre-app-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body::-webkit-scrollbar {
  display: none;
}
</style>
