import LIST_INSIGHTS from './queries/listInsights.gql';
import LIST_INSIGHT_COMPUTERS from './queries/listInsightComputers.gql';
import UPDATE_INSIGHT_STATUS from './mutations/updateInsightStatus.gql';

export default {
  state: {
    items: [],
  },
  mutations: {
    SET_INSIGHTS(state, payload) {
      state.items = payload;
    },
    UPDATE_INSIGHTS(state, payload) {
      const index = state.items.findIndex(({ uuid }) => uuid === payload.uuid);
      state.items.splice(index, 1, payload);
    },
  },
  actions: {
    async listInsights({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_INSIGHTS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_INSIGHTS', result.data.listInsights);
        return result.data.listInsights;
      }
      return result;
    },
    async listInsightComputers({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_INSIGHT_COMPUTERS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        return result.data.listInsightComputers;
      }
      return result;
    },
    async updateInsightStatus({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_INSIGHT_STATUS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('UPDATE_INSIGHTS', result.data.updateInsightStatus);
        return result.data.updateInsightStatus;
      }
      return result;
    },
  },
};
