<template>
  <div class="gp-content" v-if="!loading">
    <j-form-bar
      v-if="mode.create || group"
      :title="mode.create ? 'Create Group' : name"
      @back="routeTo()"
      @submit:created="routeTo('detail', group.id)"
      @submit="handleSubmit"
      v-bind="onRemove"
      :can-write="canWrite"
    />
    <div class="scroll-vertical">
      <div class="form-content">
        <j-input
          label="Group Name"
          :has-error="v$.internalGroup.name.$error"
          :error-text="nameErrorMessage"
          :is-read-only="isDefault || isReadOnly"
          v-model="internalGroup.name"
          data-feature-id="name"
        />
        <j-select
          v-if="roleOptions"
          v-model="internalGroup.assignedRoles"
          label="Roles"
          helper-text="Roles to assign users in this group in Jamf Protect. Adding and removing roles will affect any user assigned this group."
          :options="roleOptions"
          :placeholder="
            !internalGroup.assignedRoles.length && isReadOnly
              ? 'N/A'
              : 'Add Role'
          "
          allow-multi-select
          :is-read-only="isReadOnly"
          :searchable="isSearchable(roleOptions)"
        />
      </div>
    </div>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="name"
      blocker-kind="User(s)"
      :blockers="assignedUsers"
      @confirm="remove"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isUniqueName } from '@/util/custom-validators';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { required } from '@vuelidate/validators';
import { useAccount } from './composable/account';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'AccountGroup',
  setup() {
    const {
      mode,
      getUsers,
      getRoles,
      roleOptions,
      roles,
      users,
      associatedUsers,
      isSearchable,
      handleSubmit,
      isReadOnly,
      canWrite,
      v$,
    } = useAccount(RBAC_RESOURCE.GROUP);
    const { confirmRemove, showRemoveModal } = useRemoveHelpers();
    return {
      mode,
      getUsers,
      getRoles,
      roleOptions,
      isSearchable,
      roles,
      users,
      associatedUsers,
      v$,
      handleSubmit,
      isReadOnly,
      canWrite,
      confirmRemove,
      showRemoveModal,
    };
  },
  data() {
    return {
      loading: true,
      internalGroup: {
        name: '',
        assignedRoles: [],
        connection: null,
        accessGroup: false,
      },
      endpointCreate: 'primary/createGroup',
      endpointUpdate: 'primary/updateGroup',
    };
  },
  validations() {
    return {
      internalGroup: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
      },
    };
  },
  computed: {
    ...mapState('primary', {
      group: (state) => state.account.group,
      // this is used in isUniqueName
      duplicateNames: (state) => state.account.groupsNames,
      currentName: (state) => state.account.group?.name,
    }),
    nameErrorMessage() {
      return this.v$.internalGroup.name.$errors[0]?.$message;
    },
    onRemove() {
      return this.isDefault ? {} : { onRemove: () => this.confirmRemove() };
    },
    name() {
      return this.group?.name || '';
    },
    isDefault() {
      return this.name === 'Default';
    },
    assignedUsers() {
      return this.associatedUsers(this.group?.id, 'assignedGroups');
    },
    payload() {
      const base = {
        name: this.internalGroup.name,
        roleIds: this.internalGroup.assignedRoles,
        accessGroup: this.internalGroup.accessGroup,
        connectionId: this.internalGroup.connection,
      };
      return this.mode.create ? base : { ...base, id: this.internalGroup?.id };
    },
  },
  async beforeMount() {
    await this.getUsers();
    await this.getRoles();
  },
  async mounted() {
    if (!this.mode.create) {
      this.loading = true;

      await this.$store.dispatch('primary/getGroup', {
        id: this.$route.params.id,
      });
      this.internalGroup = {
        ...this.group,
        assignedRoles: this.group.assignedRoles?.map(({ id }) => id),
      };
    }
    this.loading = false;
  },
  methods: {
    routeTo(name, id) {
      const parent = 'account.groups';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: id ? { id } : {},
      });
    },
    async remove() {
      const result = await this.$store.dispatch('primary/deleteGroup', {
        id: this.internalGroup.id,
      });
      if (result) {
        this.routeTo();
      }
    },
    async submit() {
      await this.$store.dispatch(
        this.mode.create ? this.endpointCreate : this.endpointUpdate,
        this.payload
      );
      this.internalGroup.id = this.group.id;
    },
  },
};
</script>
