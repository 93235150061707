<template>
  <SeverityIndicator :level="value" />
</template>

<script>
import SeverityIndicator from '@/components/SeverityIndicator.vue';

export default {
  components: { SeverityIndicator },
  name: 'TableCellSeverity',
  props: ['params'],
  data() {
    return {
      value: null,
    };
  },
  mounted() {
    const { value, valueFormatted } = this.params;
    this.value = valueFormatted || value;
  },
};
</script>
