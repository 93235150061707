<template>
  <div class="gp-content" v-if="!loading">
    <j-form-bar
      :title="mode.create ? 'Create Exception Set' : name"
      @submit="handleSubmit"
      @submit:created="
        $router.push({
          name: 'exceptions.index.detail',
          params: { uuid: set.uuid },
        })
      "
      :can-write="!isReadOnly && !noAnalyticAccessAndSetInlcludesAnalytics"
      @back="$router.push({ name: 'exceptions.index' })"
      :validation-message="validationMessage"
    >
      <template v-if="!mode.create && canWrite" #right>
        <j-button
          v-if="!noAnalyticAccessAndSetInlcludesAnalytics"
          @click="copy"
          style-type="secondary"
        >
          <template #leading>
            <j-icon
              data-feature-id="clone"
              data="@jcon/clipboard.svg" /></template
          >Clone</j-button
        >
        <j-delete-button
          v-if="!set.managed"
          @click="confirmRemoveWithPlans(set)"
      /></template>
    </j-form-bar>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      :blockers="selectedPlans"
      @confirm="removeSet()"
    />
    <div class="gp-content exception-set">
      <div class="g-col-2 p-2">
        <div class="g-rows">
          <j-input
            label="Name"
            :is-read-only="isReadOnly"
            v-model="set.name"
            data-feature-id="name"
            :has-error="v$.set.$error"
            :error-text="v$.set.name.$errors[0]?.$message"
          />
          <j-textarea
            label="Description"
            :rows="3"
            data-feature-id="description"
            :is-read-only="isReadOnly"
            v-model="set.description"
          />
        </div>
        <div>
          <RelatedPlansTagList
            v-if="!mode.create && hasPlanAccess"
            :plans="set.plans"
            :displayed-limit="6"
          />
          <h1 class="h5">What is an Exception Set?</h1>
          <p class="helper-text">
            An Exception Set allows you to optimize what system activity is
            monitored<template v-if="!hasLimitedAppAccess"
              >, and which applications will be ignored by Threat
              Prevention</template
            >. Each Exception Set contains one or more Exception rules that
            define the process to exclude. Exception Sets can be applied
            directly to a plan.
          </p>
        </div>
      </div>

      <hr class="mt-0 mb-0 ml-2 mr-2" />

      <SplitPanelList
        v-model:items="exceptionGroups"
        :is-read-only="!canWrite || set.managed || limitedIgnoreActivityOptions"
        :key-to-add="groupToAdd"
        @remove="removeGroup"
        @select="selectGroup"
        @add="addGroup"
        :suppress-add-button="noAnalyticAccessAndSetInlcludesAnalytics"
      >
        <template #add-modal>
          <div class="f-wrap max-c">
            <j-select
              v-model="ignoreActivity"
              label="Select a Type of Exception"
              :options="ignoreActivityOptions"
            />
            <j-select
              v-if="
                analyticsOptions && ignoreActivity === IGNORE_ACTIVITY.Analytics
              "
              label="Analytic to Ignore"
              v-model="analytic"
              :options="analyticsOptions"
              :searchable="isSearchable(analyticsOptions)"
              deselect-from-dropdown
            />
            <j-select
              v-if="ignoreActivity === IGNORE_ACTIVITY.AnalyticsEvents"
              label="System Events to Ignore"
              v-model="analyticTypes"
              :options="ignoreEventOptions"
              :searchable="isSearchable(ignoreEventOptions)"
            />
          </div>
          <CollapseCard title="Exception Type Details" :is-small="true">
            <div class="g-rows gap-1">
              <p v-if="!hasLimitedAppAccess" class="helper-text">
                <strong>Override Endpoint Threat Prevention</strong> - This is
                an exception to Jamf Protect’s managed malware feed. It provides
                management capabilities to allow execution of processes that
                would otherwise be blocked by Endpoint Threat Prevention.
              </p>
              <template v-if="hasAnalyticAccess">
                <p class="helper-text">
                  <strong>Ignore System Events for Analytics</strong> - Provides
                  management capabilities to optimize performance of the agent
                  by ignoring trusted locations and/or processes on a
                  monitor-wide basis.
                </p>
                <p class="helper-text">
                  <strong>Ignore for Analytic</strong> - Provides management
                  capabilities to reduce noise of trusted activity on a
                  per-analytic basis.
                </p>
              </template>
              <p class="helper-text">
                <strong>Ignore for Telemetry</strong> - Provides management
                capabilities to reduce noise of trusted activity for telemetry.
              </p>
            </div>
          </CollapseCard>
        </template>
        <template #title> Total Rules ({{ exceptions.length }}) </template>
        <template #item-title="{ keyValue }">{{
          getGroupName(keyValue)
        }}</template>
        <template #item="{ keyValue, item, isMobile, errorCount }">
          <template v-if="isMobile">
            {{ getGroupName(keyValue) }}
          </template>
          <template v-else>
            <hr />
            <div
              class="g-cols max-c ai-center"
              data-feature-id="item-rules-count"
            >
              {{ item.length }} Rules
              <transition name="fade" mode="in-out">
                <div class="g-cols max-c ai-center gap-1" v-if="errorCount">
                  <j-icon
                    data="@jcon/error.svg"
                    color="var(--color-danger-base)"
                  />
                  <p>{{ errorCount }} Error(s)</p>
                </div>
              </transition>
            </div>
          </template>
        </template>
        <template #selection="{ item, isMobile, errorCount }">
          <template v-if="!isMobile">
            <router-link
              v-if="
                !noAnalyticAccessAndSetInlcludesAnalytics &&
                item?.[0]?.analyticUuid
              "
              class="mb-2 mt-1 h4 g-cols"
              :to="{
                name: 'analytics.index.detail',
                params: { id: item[0].analyticUuid },
              }"
              >{{ selectedName }}</router-link
            >
            <h1 v-else class="mb-2 mt-1 h4">
              {{ selectedName }}
            </h1>
          </template>
          <div class="g-cols ai-center mb-1">
            <div class="g-cols max-c gap-1 ai-center">
              <h2 class="h5" data-feature-id="rules-count">
                Rules ({{ item.length }})
              </h2>
              <HelpButton
                :icon-only="true"
                @click="isHelpActive = !isHelpActive"
              />
              <template v-if="errorCount">
                <j-icon
                  class="ml-1"
                  data="@jcon/error.svg"
                  color="var(--color-danger-base)"
                />
                <p v-if="isMobile">
                  {{ errorCount }}
                  Error(s)
                </p>
              </template>
            </div>
            <j-button
              @click="addRule()"
              style-type="ghost-primary"
              v-if="!isReadOnly"
              data-feature-id="rule-add"
              class="js-end"
              ><template #leading
                ><j-icon data="@icon/plus-small.svg" /></template
              >Add Rule</j-button
            >
          </div>
          <div
            class="rule mb-2 ai-center"
            v-for="rule in item"
            :class="{
              'app-info': rule.type === 'AppSigningInfo',
            }"
            :key="rule.key"
          >
            <div
              data-feature-id="rule-type"
              v-if="isRuleTypeReadOnly && rule.type === filePath.value"
            >
              {{ filePath.label }}
            </div>
            <ExceptionRule
              v-if="rule"
              :is-type-selectable="
                !(isRuleTypeReadOnly && rule.type === filePath.value)
              "
              :is-dirty="v$.$dirty"
              :type-options="computedTypeOptions"
              :is-read-only="isReadOnly"
              v-model:type="rule.type"
              v-model:value="rule.value"
              v-model:teamId="rule.teamId"
              v-model:appId="rule.appId"
            />
            <j-remove-button
              v-if="!isReadOnly"
              class="js-end"
              data-feature-id="rule-remove"
              @click="removeRule(index)"
            />
          </div>
        </template>
      </SplitPanelList>
      <j-modal v-model="isHelpActive" size="large">
        <ExceptionSetDocumentation />
      </j-modal>
    </div>
  </div>
</template>

<script>
import { useRBAC } from '@/composables/rbac';
import { useRemoveHelpers } from '@/composables/remove-helpers';
import { groupBy } from 'lodash';
import { required, maxLength } from '@vuelidate/validators';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useForm } from '@/composables/forms';
import {
  EVENT_TYPES,
  EVENT_TYPES_NAMES_EXCEPTIONS,
} from '@/util/constants/event.types';
import {
  IGNORE_TYPE,
  IGNORE_ACTIVITY,
  IGNORE_ACTIVITY_NAMES,
  IGNORE_TYPE_NAMES,
} from './exception.types';
import { isUniqueName } from '@/util/custom-validators';
import ExceptionRule from './components/ExceptionRule.vue';
import SplitPanelList from '@/components/SplitPanelList.vue';
import ExceptionSetDocumentation from './components/ExceptionSetDocumentation.vue';
import { mapGetters, mapState } from 'vuex';
import CollapseCard from '@/components/CollapseCard.vue';
import HelpButton from '@/components/HelpButton.vue';
import RelatedPlansTagList from '@/components/RelatedPlansTagList.vue';
import { uuid } from '@/util';

export default {
  name: 'ExceptionSetsForm',
  components: {
    SplitPanelList,
    ExceptionSetDocumentation,
    CollapseCard,
    HelpButton,
    RelatedPlansTagList,
    ExceptionRule,
  },
  setup() {
    const { v$, mode, handleSubmit } = useForm();
    const { canWrite, canAccess } = useRBAC(RBAC_RESOURCE.EXCEPTION_SET);
    const { showRemoveModal, toDelete, selectedPlans, confirmRemoveWithPlans } =
      useRemoveHelpers();

    return {
      handleSubmit,
      showRemoveModal,
      toDelete,
      selectedPlans,
      confirmRemoveWithPlans,
      v$,
      mode,
      uuid,
      canWrite,
      canAccess,
    };
  },
  data() {
    const typeOptions = Object.entries(IGNORE_TYPE_NAMES).map(
      ([value, label]) => ({
        label,
        value,
      })
    );

    const ignoreActivityOptions = Object.entries(IGNORE_ACTIVITY_NAMES).map(
      ([value, label]) => ({
        label,
        value,
      })
    );
    const ignoreEventOptions = Object.entries(EVENT_TYPES_NAMES_EXCEPTIONS).map(
      ([value, label]) => ({
        label,
        value: value,
      })
    );

    const ignoreEvents = Object.keys(EVENT_TYPES_NAMES_EXCEPTIONS);
    return {
      loading: true,
      ignoreEventOptions,
      typeOptions,
      ignoreEvents,
      ignoreActivityOptions,
      ignoreActivity: IGNORE_ACTIVITY.ThreatPrevention,
      analyticTypes: EVENT_TYPES.FILE_SYSTEM,
      analytic: null,
      analyticsOptions: null,
      analyticNames: null,
      IGNORE_ACTIVITY,
      filePath: { label: IGNORE_TYPE_NAMES.Path, value: IGNORE_TYPE.Path },
      isHelpActive: false,
      endpoints: {
        delete: 'primary/deleteExceptionSet',
        single: 'primary/getExceptionSet',
        create: 'primary/createExceptionSet',
        update: 'primary/updateExceptionSet',
      },
      set: {
        name: '',
        description: null,
        exceptions: [],
      },
      exceptionGroups: {},
      name: '',
      selectedKey: '',
      reducedTypeOptions: [...typeOptions].filter(
        (option) => option.value !== IGNORE_TYPE.Path
      ),
    };
  },
  validations() {
    return {
      set: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
      },
      exceptions: {
        required,
        maxLength: maxLength(1000),
      },
    };
  },
  async mounted() {
    if (this.hasAnalyticAccess) {
      await this.setupAnalyticState();
    } else {
      this.ignoreActivityOptions = this.ignoreActivityOptions.filter(
        ({ value }) =>
          value !== IGNORE_ACTIVITY.Analytics &&
          value !== IGNORE_ACTIVITY.AnalyticsEvents
      );
    }

    if (!this.mode.create || this.$route.query.copy) {
      const result = await this.$store.dispatch(this.endpoints.single, {
        uuid: this.$route.query.copy
          ? this.$route.query.copy
          : this.$route.params.uuid,
      });

      // add key for rule list
      const exceptions = this.setExceptions(result?.exceptions);
      this.exceptionGroups = groupBy(exceptions, (exception) =>
        this.getGroupKey(exception)
      );

      this.set = { ...result };
      if (this.$route.query.copy) {
        this.set.name = `${result.name} Copy`;
        this.set.description = `Copy of ${result.name}`;
        this.set.managed = false;
      } else {
        this.name = result?.name;
      }
    }

    if (this.hasLimitedAppAccess) {
      this.ignoreActivityOptions = this.ignoreActivityOptions.filter(
        ({ value }) => value !== IGNORE_ACTIVITY.ThreatPrevention
      );
      this.ignoreActivity = IGNORE_ACTIVITY.Telemetry;
    }

    if (
      !this.hasAnalyticAccess &&
      this.hasLimitedAppAccess &&
      this.mode.create
    ) {
      this.addGroup();
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters(['hasLimitedAppAccess']),
    ...mapState('primary', {
      // this is used in isUniqueName
      duplicateNames: (state) => state.exceptionSets.exceptionSetsNames,
      currentName: (state) => state.exceptionSets.exceptionSet?.name,
    }),
    validationMessage() {
      const exceptionMessage = this.v$.exceptions.required.$invalid
        ? 'Must have at least one Exception'
        : `Exceeds rule limit of
          ${this.v$.exceptions?.maxLength.$params?.max}`;
      return this.v$.exceptions.$invalid ? exceptionMessage : 'Error in form.';
    },
    hasAnalyticAccess() {
      return this.canAccess([
        RBAC_RESOURCE.CLOUD_ACCESS,
        RBAC_RESOURCE.ANALYTIC,
      ]);
    },
    noAnalyticAccessAndSetInlcludesAnalytics() {
      return (
        !this.hasAnalyticAccess &&
        this.exceptions.some(
          (exception) => exception.ignoreActivity === IGNORE_ACTIVITY.Analytics
        )
      );
    },
    limitedIgnoreActivityOptions() {
      return this.ignoreActivityOptions.length <= 1;
    },
    isReadOnly() {
      return (
        !this.canWrite ||
        this.set.managed ||
        this.noAnalyticAccessAndSetInlcludesAnalytics
      );
    },
    isRuleTypeReadOnly() {
      const fileTypes = new Set([EVENT_TYPES.SCREENSHOT, EVENT_TYPES.DOWNLOAD]);
      return (
        fileTypes.has(this.selectedKey) ||
        fileTypes.has(this.analyticNames?.[this.selectedKey]?.event)
      );
    },
    allowsTypePath() {
      const fileTypes = new Set([
        EVENT_TYPES.FILE_SYSTEM,
        'all',
        'All',
        EVENT_TYPES.SCREENSHOT,
        EVENT_TYPES.DOWNLOAD,
      ]);
      return (
        fileTypes.has(this.selectedKey) ||
        fileTypes.has(this.analyticNames?.[this.selectedKey]?.event)
      );
    },
    computedTypeOptions() {
      return this.allowsTypePath ? this.typeOptions : this.reducedTypeOptions;
    },
    selectedName() {
      return this.getGroupName(this.selectedKey) || this.selectedKey;
    },
    hasPlanAccess() {
      return this.canAccess(RBAC_RESOURCE.PLAN);
    },
    groupToAdd() {
      return this.getGroupKey({
        ignoreActivity: this.ignoreActivity,
        analyticTypes: this.analyticTypes,
        analyticUuid:
          this.ignoreActivity === IGNORE_ACTIVITY.AnalyticsEvents
            ? null
            : this.analytic,
      });
    },
    exceptions() {
      return Object.values(this.exceptionGroups).flat();
    },
    payload() {
      // remove key from rules
      const exceptions = this.exceptions.map(
        ({
          type,
          value,
          ignoreActivity,
          teamId,
          appId,
          analyticTypes,
          analyticUuid,
        }) => ({
          type,
          ignoreActivity,
          ...(type === IGNORE_TYPE.AppSigningInfo
            ? {
                appSigningInfo: {
                  teamId,
                  appId,
                },
              }
            : { value }),
          ...(ignoreActivity === IGNORE_ACTIVITY.Analytics
            ? {
                ...(analyticTypes?.length > 0
                  ? { analyticTypes }
                  : { analyticUuid }),
              }
            : {}),
        })
      );
      return { ...this.set, exceptions };
    },
  },
  methods: {
    async setupAnalyticState() {
      const analytics = await this.$store.dispatch('primary/listAnalyticsLite');

      this.analyticsOptions = analytics?.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      this.analyticNames = analytics?.items.reduce(
        (map, { name, uuid, inputType }) => {
          map[uuid] = { name, event: inputType };
          return map;
        },
        {}
      );
      this.analytic = this.analyticsOptions[0].value;
    },
    getGroupName(key) {
      // account for all events and multiple events from api
      if (key === 'all') {
        return 'All Events';
      }
      return (
        `${
          IGNORE_ACTIVITY_NAMES[key] ||
          key
            .split(',')
            .map(
              (k) =>
                EVENT_TYPES_NAMES_EXCEPTIONS[k] || this.analyticNames?.[k]?.name
            )
            .join(', ')
        }` || key
      );
    },
    getGroupKey({ ignoreActivity, analyticTypes, analyticUuid }) {
      return ![
        IGNORE_ACTIVITY.Telemetry,
        IGNORE_ACTIVITY.ThreatPrevention,
        IGNORE_ACTIVITY.All,
      ].includes(ignoreActivity)
        ? analyticUuid || analyticTypes
        : ignoreActivity;
    },
    setExceptions(exceptions) {
      return (
        exceptions?.map((exception) => ({
          ...exception,
          analyticUuid: exception.analytic?.uuid,
          teamId: exception.appSigningInfo?.teamId,
          appId: exception.appSigningInfo?.appId,
          key: uuid(),
        })) || []
      );
    },
    selectGroup(key) {
      this.selectedKey = key;
    },
    buildRule(key) {
      const exceptionType = [
        IGNORE_ACTIVITY.ThreatPrevention,
        IGNORE_ACTIVITY.Telemetry,
      ].includes(key)
        ? {
            ignoreActivity: key,
            analyticTypes: null,
            analyticUuid: null,
          }
        : {
            ignoreActivity: IGNORE_ACTIVITY.Analytics,
            ...(this.ignoreEvents.includes(key)
              ? { analyticTypes: [key] }
              : { analyticUuid: key }),
          };
      return {
        ...exceptionType,
        type: this.allowsTypePath
          ? this.filePath.value
          : this.computedTypeOptions[0].value,
        value: '',
        teamId: '',
        appId: '',
        key: uuid(),
      };
    },
    addRule() {
      const rule = this.buildRule(this.selectedKey);
      this.exceptionGroups[this.selectedKey].splice(0, 0, rule);
    },
    addGroup() {
      this.selectedKey = this.groupToAdd;
      const rule = this.buildRule(this.groupToAdd);
      this.exceptionGroups[this.groupToAdd] = [rule];
    },
    removeGroup(key) {
      delete this.exceptionGroups[key];
    },
    removeRule(index) {
      this.exceptionGroups[this.selectedKey].splice(index, 1);
    },
    removeSet() {
      this.showWarning = false;
      this.delete();
    },
    copy() {
      this.$router.push({
        name: 'exceptions.index.create',
        query: { copy: this.$route.params.uuid },
      });
    },
    async submit() {
      const result = await this.$store.dispatch(
        this.mode.create ? this.endpoints.create : this.endpoints.update,
        this.payload
      );
      if (result) {
        this.set = result;
        this.name = result.name;
      }
    },
    async delete() {
      const result = await this.$store.dispatch(this.endpoints.delete, {
        uuid: this.set.uuid,
      });
      if (result) {
        this.$router.push({ name: 'exceptions.index' });
      }
    },
    isSearchable(options) {
      return options.length > 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.helper-text > strong {
  color: inherit;
}

.exception-set {
  .tags {
    --size-action-height-base: var(--size-action-height-secondary);
  }

  @include breakpoint(small down) {
    @include scroll-vertical;
  }
}

.rule {
  --size-input-width-base: 100%;
  --size-input-height-base: 40px;
  @include grid;
  @include grid-columns(minmax(120px, max-content) 1fr 32px);
  &.app-info {
    grid-auto-columns: minmax(120px, max-content) 1fr 1fr 32px;
  }
}

.error-message {
  @include grid;
  @include grid-columns(max-content);
  align-items: center;
}
</style>
