<template>
  <NoResultsOverlay
    :allow-events="true"
    message="Map groups from your identity provider to Jamf Protect for user role assignment."
    title="No Mappings"
  >
    <j-create-button
      v-if="params.canWrite"
      @click="params.create"
      data-feature-id="row-overlay-create"
      label="Add Mapping"
    />
  </NoResultsOverlay>
</template>

<script>
import NoResultsOverlay from '@/components/NoResultsOverlay.vue';

export default {
  name: 'NoRowsAddMappingOverlay',
  components: { NoResultsOverlay },
};
</script>
