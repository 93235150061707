import { buildFilterModelFromQueryOps } from './operators';

export const FILTER_SEPARATOR = Object.freeze({
  MAIN: '|',
});

/**
 * @description builds a filterModel array from query params
 * @param {object} query query to filter
 * @param {Object} query.column column that is to be filtered
 * @param {Object} query.type how to filter it
 * @param {Object} query.value value or values to filter with
 * @param {Object} query.filterType type of filter e.g. 'date'
 * @returns {array}
 */
export const parseQueryFilter = ({ column, type, value, filterType }) => {
  let filters = null;
  if (column) {
    filters = {};
    const columns = column?.split(FILTER_SEPARATOR.MAIN) || [];
    const types = type?.split(FILTER_SEPARATOR.MAIN) || [];
    const values = value?.split(FILTER_SEPARATOR.MAIN) || [];
    const filterTypes = filterType?.split(FILTER_SEPARATOR.MAIN) || [];
    columns.forEach((col, index, arr) => {
      const getType =
        arr.length > 1 && types.length === 1 ? types[0] : types[index];
      const getFilterType =
        arr.length > 1 && filterTypes.length === 1
          ? filterTypes[0]
          : filterTypes[index];
      const filter = buildFilterModelFromQueryOps(types[index], values[index]);

      filters[col] = {
        type: getType,
        filterType: getFilterType,
        ...filter,
      };
    });
  }
  return filters;
};

/**
 * @description builds a single query object from an array of filters
 * @param {array} filters
 * @returns {object}
 */
export const buildQueryFilter = (filters) => {
  const query = { column: '', type: '', value: '', filterType: '' };
  filters.forEach(({ column, type, value, filterType }, index) => {
    const isFirst = index === 0;
    query.column = isFirst
      ? column
      : `${query.column}${FILTER_SEPARATOR.MAIN}${column}`;
    query.type = isFirst
      ? type
      : `${query.type}${FILTER_SEPARATOR.MAIN}${type}`;
    query.value = isFirst
      ? value
      : `${query.value}${FILTER_SEPARATOR.MAIN}${value}`;
    query.filterType = isFirst
      ? filterType
      : `${query.filterType}${FILTER_SEPARATOR.MAIN}${filterType}`;
  });
  return query;
};

export const buildQuerySort = (column) => {
  const query = { sort: '' };
  column.forEach(({ sort, colId, sortIndex }, index) => {
    const isFirst = sortIndex === 0 || index === 0;
    const sortquery = `${colId}.${sort}`;
    query.sort = isFirst
      ? sortquery
      : `${query.sort}${FILTER_SEPARATOR.MAIN}${sortquery}`;
  });
  return query;
};

export const parseQuerySort = ({ sort }) => {
  const sorts = sort?.split(FILTER_SEPARATOR.MAIN) || [];
  const states = [];
  sorts.forEach((value, index) => {
    const [colId, direction] = value.split('.');
    states.push({ colId, sort: direction, sortIndex: index });
  });

  return states;
};
