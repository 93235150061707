<template>
  <Insights
    :insights="computerScorecard"
    :key="key"
    :has-key="false"
    :external-filters="filter"
    :get-fail-count="getFailCount"
    ref="insights"
    role="tabpanel"
    :single-device="computer.serial"
  >
    <template #filters>
      <j-toggle-group class="fail-pass-group has-divider">
        <j-checkbox
          data-feature-id="noncompliant"
          v-model="status"
          native-value="fail"
        >
          <j-icon
            class="toggle-icon"
            data="@jcon/warning.svg"
            width="12"
            height="12"
          />Noncompliant
        </j-checkbox>
        <j-checkbox
          data-feature-id="compliant"
          v-model="status"
          native-value="pass"
        >
          <j-icon
            class="toggle-icon"
            data="@jcon/check-circle.svg"
            width="12"
            height="12"
          />Compliant
        </j-checkbox>
      </j-toggle-group>
    </template>
    <template #default="{ section }">
      <Card
        v-for="{ uuid, label, description, tags, enabled, pass } in section"
        class="report"
        :key="uuid"
      >
        <ComplianceLabel
          v-if="isFeatureEnabled('COMPLIANCE_DASHBOARD')"
          :route="{ name: 'compliance.index.detail', params: { id: uuid } }"
          :label="label"
          :tags="tags"
          :description="description"
        />
        <InsightLabel
          v-else
          :label="label"
          :description="description"
          :route="{ name: 'insights.index.detail', params: { id: uuid } }"
          :tags="tags"
        />
        <template v-if="enabled">
          <j-icon
            v-if="pass === false"
            data="@jcon/warning.svg"
            class="fail"
            width="24"
            height="24"
          />
          <j-icon
            v-if="pass === null"
            data="@jcon/error.svg"
            width="24"
            height="24"
          />
          <j-icon
            v-if="pass"
            data="@jcon/check-circle.svg"
            class="pass"
            width="24"
            height="24"
          />
        </template>
        <j-icon v-else data="@jcon/not-allowed.svg" width="24" height="24" />
      </Card>
    </template>
  </Insights>
</template>

<script>
import { mapGetters } from 'vuex';
import Insights from '@/modules/insights/components/Insights';
import InsightLabel from '@/modules/insights/components/InsightLabel';
import ComplianceLabel from '@/modules/compliance/components/ComplianceLabel';
import Card from '@/components/Card';

export default {
  name: 'ComputerInsights',
  props: {
    computer: {
      type: Object,
      required: true,
    },
    refresh: Boolean,
  },
  components: { Insights, InsightLabel, Card, ComplianceLabel },
  data() {
    return {
      status: [],
      key: 0,
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    computerScorecard() {
      return this.computer.scorecard || [];
    },
  },
  watch: {
    status() {
      this.$refs.insights.filtered = this.$refs.insights?.getFiltered();
    },
    refresh(value) {
      if (value) {
        this.key += 1;
      }
    },
  },
  mounted() {
    if (['fail', 'pass'].includes(this.$route.query.filter)) {
      this.status.push(this.$route.query.filter);
      this.setFilterRef();
    } else if (this.$route.query.filter === 'disabled') {
      this.$refs.insights.includeDisabled = true;
      this.$refs.insights.includeEnabled = false;
    }
  },
  methods: {
    setFilterRef() {
      this.$refs.insights.filtered = this.$refs.insights?.getFiltered();
    },
    filter(items) {
      return this.status.length > 0
        ? this.status
            .map((key) => items.filter(({ pass }) => pass === (key === 'pass')))
            .flat()
        : items;
    },
    getFailCount(items) {
      return items.filter(({ pass }) => pass === false).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  @include grid;
  @include grid-columns;
  justify-content: space-between;

  .fail {
    color: var(--color-warning-base);
  }

  .pass {
    color: var(--color-success-base);
  }
}

.fail-pass-group :deep(.toggle .indicator) {
  display: none;
}
.toggle-icon {
  margin-right: 4px;
}
</style>
