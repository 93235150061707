import Section from '@/components/Section.vue';
import AnalyticSets from './AnalyticSets.vue';
import AnalyticSet from './AnalyticSet.vue';
import AnalyticSetForm from './AnalyticSetForm.vue';

import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  path: 'sets',
  component: Section,
  name: 'analytics.sets.index',
  meta: {
    breadcrumb: {
      linkText: 'Analytic Sets',
      linkName: 'analytics.sets',
    },
    permissions: [RBAC_RESOURCE.ANALYTIC_SET],
  },
  children: [
    {
      path: '',
      component: AnalyticSets,
      name: 'analytics.sets',
    },
    {
      path: '',
      component: AnalyticSet,
      name: 'analytics.set',
      children: [
        {
          path: 'create',
          name: 'analytics.sets.create',
          component: AnalyticSetForm,
          meta: {
            breadcrumb: {
              linkText: 'Create analytic set',
            },
            requireWrite: true,
          },
        },
        {
          path: ':uuid',
          name: 'analytics.sets.detail',
          component: AnalyticSetForm,
          meta: {
            breadcrumb: {
              linkText: 'Set',
            },
            mode: { edit: true },
          },
        },
      ],
    },
  ],
};
