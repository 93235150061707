<template>
  <Tabs v-if="tabs" :tabs="tabs" />
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'AccountSection',
  components: { Tabs },
  setup() {
    const { canAccess } = useRBAC();
    return { canAccess };
  },
  data() {
    return {
      tabs: null,
    };
  },
  async mounted() {
    if (
      this.canAccess([RBAC_RESOURCE.DATA_FORWARD, RBAC_RESOURCE.ORGANIZATION])
    )
      await this.$store.dispatch('primary/getDataForward');
    if (
      this.canAccess([RBAC_RESOURCE.DATA_RETENTION, RBAC_RESOURCE.ORGANIZATION])
    )
      await this.$store.dispatch('primary/getDataRetention');
    this.tabs = [
      {
        label: 'Forwarding',
        route: {
          name: 'data.index.forwarding',
        },
      },
      {
        label: 'Retention',
        route: {
          name: 'data.index.retention',
        },
      },
    ];
  },
};
</script>
