<template>
  <JamfTable
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
    :is-row-selectable="isRowSelectable"
  >
    <template #left v-if="canWrite">
      <j-create-button
        @click="$router.push({ name: 'exceptions.index.create' })"
        label="Create Exception Set"
      />
    </template>
    <template #right="{ selected }">
      <j-delete-button
        v-if="canWrite"
        @click="confirmRemoveMultipleWithPlans(selected)"
        :is-disabled="selected.length === 0"
        :label="`Delete Exception Sets (${selected.length})`"
      />
    </template>
    <template #modals="{ refresh, selected }">
      <j-delete-modal
        v-model="showRemoveModal"
        :to-delete="toDelete"
        :blockers="selectedPlans"
        @confirm="removeSets(selected, refresh)"
      />
    </template>
  </JamfTable>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import JamfTable from '@/components/table/JamfTable.vue';
import { useRemoveHelpers } from '@/composables/remove-helpers';
import { associatedPlansColumn } from '@/components/table/utils/column.types';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'ExceptionSets',
  components: { JamfTable },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.EXCEPTION_SET);
    const {
      showRemoveModal,
      toDelete,
      selectedPlans,
      confirmRemoveMultipleWithPlans,
    } = useRemoveHelpers();

    return {
      canWrite,
      showRemoveModal,
      toDelete,
      selectedPlans,
      confirmRemoveMultipleWithPlans,
    };
  },
  data() {
    return {
      filter: this.$route.query,
      endpoints: {
        list: 'primary/listExceptionSets',
        delete: 'primary/deleteExceptionSet',
      },
      columns: [
        {
          field: 'managed',
          sortable: true,
          sort: 'desc',
          type: 'managedColumn',
        },
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 200,
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'exceptions.index.detail',
              params: { uuid: params?.data?.uuid },
            }),
          },
        },
        {
          headerName: 'Description',
          field: 'description',
        },
        { ...associatedPlansColumn },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
          sortable: true,
        },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sortable: true,
        },
      ],
    };
  },
  created() {
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
  },
  methods: {
    async getData(params) {
      return this.$store.dispatch(this.endpoints.list, params);
    },
    async removeSets(selected, refresh) {
      this.loading = true;
      this.showWarning = false;
      await Promise.all(
        selected.map(({ uuid }) =>
          this.$store.dispatch(this.endpoints.delete, { uuid })
        )
      );
      refresh();
    },
    isRowSelectable(rowNode) {
      return rowNode?.data?.managed !== true;
    },
  },
};
</script>
