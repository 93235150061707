<template>
  <JamfTable
    v-if="loaded"
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
  >
    <template #left v-if="canWrite">
      <j-create-button
        @click="$router.push({ name: 'plans.index.create' })"
        label="Create Plan"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { secondsToString, capitalize } from '@/util';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';
import { usePlan } from '@/modules/plans/plans.js';
import download from '@jcon/download-2.svg';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Plans',
  components: {
    JamfTable,
  },
  setup() {
    const { canWrite, cloudOnlyAccess, canAccess } = useRBAC(
      RBAC_RESOURCE.PLAN
    );
    const { downloadConfigProfile, downloadOfflineOptions } = usePlan();

    return {
      canWrite,
      canAccess,
      cloudOnlyAccess,
      downloadConfigProfile,
      downloadOfflineOptions,
    };
  },
  data() {
    return {
      loaded: false,
      endpoint: 'primary/listPlans',
      columns: [
        {
          headerName: '',
          field: 'download',
          type: 'buttonColumn',
          minWidth: 74,
          maxWidth: 74,
          width: 74,
          resizable: false,
          cellRendererParams: {
            action: ({ data }) =>
              data
                ? this.downloadConfigProfile(
                    data,
                    this.cloudOnlyAccess ? null : this.downloadOfflineOptions
                  )
                : null,
            icon: download,
            styleType: 'primary',
          },
        },
        {
          headerName: 'Name',
          field: 'name',
          flex: 2,
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'plans.index.detail',
              params: { id: params.data?.id },
            }),
          },
        },
        {
          headerName: 'Auto Update',
          field: 'autoUpdate',
          width: 160,
          sortable: true,
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
        },
        {
          field: 'analyticSets',
          permissions: [RBAC_RESOURCE.ANALYTIC_SET, RBAC_RESOURCE.ANALYTIC],
          type: 'tagsColumn',
          valueGetter: (params) =>
            params?.data?.analyticSets
              ?.filter(({ managed }) => !managed)
              .map(({ name, uuid }) => ({
                value: name,
                uuid,
              })),
          cellRendererParams: {
            route: (tag) => ({
              name: 'analytics.sets.detail',
              params: { uuid: tag.uuid },
            }),
          },
        },
        {
          headerName: 'Agent Protocol',
          field: 'commsConfig.protocol',
          colId: 'commsConfig',
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          sortable: true,
        },
        {
          headerName: 'Endpoint Threat Prevention',
          field: 'signaturesFeedConfig.mode',
          colId: 'signaturesFeedConfig',
          permissions: [
            RBAC_RESOURCE.CLOUD_ACCESS,
            RBAC_RESOURCE.THREAT_PREVENTION_VERSION,
          ],
          sortable: true,
          valueFormatter: ({ value }) => {
            let display = 'Disabled';
            if (value === 'reportOnly') display = 'Report Only';
            if (value === 'blocking') display = 'Block & Report';
            return display;
          },
        },
        {
          headerName: 'Log Level',
          field: 'logLevel',
          valueFormatter: ({ value }) =>
            value ? capitalize(value) : 'Not Set',
        },
        {
          headerName: 'Sync Interval',
          field: 'infoSync.infoSyncInterval',
          flagOff: 'COMPUTER_CONNECTION_STATUS',
          permissions: RBAC_RESOURCE.CLOUD_ACCESS,
          colId: 'infoSyncInterval',
          valueFormatter: ({ value }) => (value ? secondsToString(value) : ''),
        },
        {
          headerName: 'Action',
          field: 'actionConfigs.name',
          colId: 'actionConfigs',
          sortable: true,
          type: 'linkColumn',
          permissions: RBAC_RESOURCE.ACTION_CONFIGS,
          cellRendererParams: {
            route: (params) => ({
              name: 'actions.index.detail',
              params: { id: params.data?.actionConfigs.id },
            }),
          },
        },
        {
          headerName: 'Telemetry',
          field: 'telemetry.name',
          colId: 'telemetry',
          sortable: true,
          type: 'linkColumn',
          permissions: RBAC_RESOURCE.TELEMETRY,
          flag: 'TELEMETRY_STREAM',
          cellRendererParams: {
            route: (params) =>
              params.data?.telemetry
                ? {
                    name: 'telemetry.index.detail',
                    params: { id: params.data?.telemetry?.id },
                  }
                : null,
          },
        },
        {
          headerName: 'Exception Sets',
          field: 'exceptionSets',
          permissions: RBAC_RESOURCE.EXCEPTION_SET,
          type: 'tagsColumn',
          valueGetter: (params) =>
            params?.data?.exceptionSets?.map(({ name, uuid }) => ({
              value: name,
              uuid,
            })),
          cellRendererParams: {
            route: (tag) => ({
              name: 'exceptions.index.detail',
              params: { uuid: tag.uuid },
            }),
          },
        },
        {
          headerName: 'Removable Storage Control Set',
          field: 'usbControlSet.name',
          colId: 'usbControlSet',
          flag: 'USB_CONTROLS',
          permissions: RBAC_RESOURCE.USB_CONTROLS,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) =>
              params.data?.usbControlSet
                ? {
                    name: 'devicecontrols.index.detail',
                    params: { id: params.data?.usbControlSet?.id },
                  }
                : null,
          },
        },
        {
          headerValueGetter: () =>
            this.isFeatureEnabled('COMPLIANCE_DASHBOARD')
              ? 'Compliance'
              : 'Insights',
          field: 'infoSync.attrs',
          colId: 'insights',
          width: 160,
          permissions: RBAC_RESOURCE.INSIGHT,
          valueFormatter: ({ value }) =>
            value?.includes('insights') ? 'Enabled' : 'Disabled',
        },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sortable: true,
          sort: 'asc',
        },
        {
          headerName: 'Modified',
          field: 'updated',
          type: 'dateColumn',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState({
      managedAnalyticSets: (state) =>
        state.primary.analyticSets.managedAnalyticSets,
    }),
  },
  async created() {
    if (this.canAccess([RBAC_RESOURCE.ANALYTIC_SET, RBAC_RESOURCE.ANALYTIC])) {
      if (!this.managedAnalyticSets) {
        await this.$store.dispatch('primary/listManagedAnalyticSets', {
          excludeAnalytics: true,
        });
      }

      // This feature is enabled only be uuid and is important for releasing managed analytic sets
      this.managedAnalyticSets?.forEach((set) => {
        this.columns.splice(8, 0, {
          headerName: set.name,
          field: 'analyticSets',
          colId: set.uuid,
          width: 160,
          flag: set.uuid,
          permissions: RBAC_RESOURCE.ANALYTIC_SET,
          valueGetter: ({ data }) => {
            const managedOnly = data?.analyticSets
              ?.filter(({ managed, name }) => managed && name === set.name)
              .map((a) => `${a.name}`);

            return managedOnly.length > 0 ? 'Enabled' : 'Disabled';
          },
        });
      });
    }
    this.loaded = true;
  },
  methods: {
    async getData(params) {
      return this.$store.dispatch(this.endpoint, params);
    },
  },
};
</script>
