<template>
  <div class="scroll-vertical">
    <div class="grid" v-if="organization">
      <Card class="org-info g-rows">
        <h2>Organization Information</h2>
        <ListItem title="Name" data-feature-id="account.name" icon="name">
          {{ organization.name }}
        </ListItem>
        <ListItem title="Created" data-feature-id="account.created" icon="cal">
          {{ formatEpochDate(organization.created) }}
        </ListItem>
        <ListItem title="Domain" data-feature-id="account.domain" icon="org">
          {{ organization.hd }}
        </ListItem>
        <ListItem
          title="Certificate Authority ID"
          data-feature-id="account.caid"
          icon="cert"
          v-if="cloudOnlyAccess"
        >
          {{ organization.caid }}
        </ListItem>
        <template v-if="data && cloudOnlyAccess">
          <ListItem
            title="In-App High, Medium, Low Alert Access"
            data-feature-id="account.alertDays"
            >Kept For {{ data.retention.database.alert.numberOfDays }} Days
          </ListItem>
          <ListItem
            title="In-App Informational Alert Access"
            data-feature-id="account.logDays"
            >Kept For {{ data.retention.database.log.numberOfDays }} Days
          </ListItem>
          <ListItem
            title="Long Term Data Storage"
            data-feature-id="account.coldAlert"
            >Archived For {{ data.retention.cold.alert.numberOfDays }} Days
          </ListItem>
        </template>
      </Card>
      <Card class="app-info g-rows">
        <h2>App Information</h2>
        <ListItem title="Build Date">
          {{ formatEpochDate(VUE_APP_BUILD_DATE) }}
        </ListItem>
        <ListItem title="GIT_HASH">
          {{ VUE_APP_GIT_HASH }}
        </ListItem>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useGmt } from '@/composables/gmt';
import Card from '@/components/Card.vue';
import ListItem from '@/components/ListItem.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'AccountInfo',
  components: { Card, ListItem },
  setup() {
    const { formatEpochDate } = useGmt();
    const { canAccess, cloudOnlyAccess } = useRBAC();

    return { formatEpochDate, canAccess, cloudOnlyAccess };
  },
  data() {
    return {
      downloadTypes: [
        { label: 'PPPC Profile', type: 'pppc', ext: '.mobileconfig' },
        { label: 'Root CA Certificate', type: 'rootCA', ext: '.der' },
        { label: 'Certificate Request Identity', type: 'csr', ext: '.p12' },
        {
          label: 'WebSocket Authorizer Key',
          type: 'websocket_auth',
          ext: '.p12',
        },
      ],
      version: null,
      downloads: null,
    };
  },
  computed: {
    ...mapState('primary', {
      organization: 'organization',
      data: (state) => state.data?.retention,
    }),
    ...mapState('auth', ['loginConfig']),
    ...mapState(['VUE_APP_GIT_HASH', 'VUE_APP_BUILD_DATE']),
  },
  async mounted() {
    await this.$store.dispatch('primary/getOrganization');
    if (this.canAccess(RBAC_RESOURCE.DATA_RETENTION)) {
      await this.$store.dispatch('primary/getDataRetention');
    }
  },
};
</script>

<style lang="scss" scoped>
.grid {
  @include grid;
  @include grid-rows;
  grid-template-columns: minmax(0, auto);
  height: auto;
  padding: var(--size-grid-gap-base);
  width: 100%;
  h2 {
    @include header3;
    padding-bottom: spacing();
  }

  @include breakpoint(small down) {
    grid-template-columns: unset;
  }
}
</style>
