<template>
  <transition name="user-menu" mode="out-in">
    <div class="user-menu" v-if="showUserInfo">
      <aside class="menu">
        <ul class="g-rows gap-1">
          <li class="mt-2">
            <j-switch
              v-model="settings.gmt"
              :is-read-only="loading"
              data-feature-id="gmt-switch"
              @change="updateUserSettings()"
            >
              GMT Timestamps</j-switch
            >
          </li>
          <li>
            <j-switch
              v-model="settings.twentyFourHour"
              :is-read-only="loading"
              data-feature-id="analog-switch"
              @change="updateUserSettings()"
              >24-Hour Time</j-switch
            >
          </li>

          <li v-if="isJamfDev" class="link" @click="doCopy">Copy ID Token</li>
          <li class="pt-2">
            <p class="pb-1 date-label">Date Format:</p>
            <j-select
              :options="datesChoices"
              :searchable="false"
              v-model="settings.dateFormat"
              is-small
            />
          </li>

          <li class="pt-2">
            <j-toggle-group class="mode" label="Mode:" :is-vertical="true">
              <j-radio
                v-model="settings.mode"
                data-feature-id="auto"
                native-value="auto"
                name="auto"
                @change="updateUserSettings()"
                >Auto</j-radio
              >
              <j-radio
                v-model="settings.mode"
                data-feature-id="dark"
                native-value="dark"
                name="dark"
                @change="updateUserSettings()"
                >Dark</j-radio
              >
              <j-radio
                v-model="settings.mode"
                native-value="light"
                data-feature-id="light"
                name="light"
                @change="updateUserSettings()"
                >Light</j-radio
              >
            </j-toggle-group>
          </li>
          <template v-if="isNewLook">
            <li>
              <j-toggle-group
                class="mode"
                label="Contrast:"
                :is-vertical="true"
              >
                <j-radio
                  v-model="settings.scheme.contrast"
                  data-feature-id="contrast-0"
                  :native-value="0"
                  name="low"
                  @change="updateUserSettings()"
                  >Low</j-radio
                >
                <j-radio
                  v-model="settings.scheme.contrast"
                  :native-value="1"
                  data-feature-id="contrast-1"
                  name="high"
                  @change="updateUserSettings()"
                  >High</j-radio
                >
              </j-toggle-group>
            </li>
            <li class="g-rows gap-1">
              <label>Theme:</label>
              <div class="g-rows schemes">
                <j-radio
                  v-model="settings.scheme.color"
                  native-value="default"
                  data-feature-id="color-default"
                  name="default"
                  @change="updateUserSettings()"
                  ><span class="g-rows gap-0 default ai-center"
                    >Default<span class="red-green"></span> </span
                ></j-radio>
                <j-radio
                  v-model="settings.scheme.color"
                  data-feature-id="color-redgreen"
                  native-value="redgreen"
                  name="red-green"
                  @change="updateUserSettings()"
                >
                  <span class="g-rows gap-0 protanopia"
                    >Protanopia & Deuteranopia<span class="red-green"></span
                  ></span>
                </j-radio>
                <j-radio
                  v-model="settings.scheme.color"
                  native-value="tritanopia"
                  data-feature-id="color-tritanopia"
                  name="tritanopia"
                  @change="updateUserSettings()"
                >
                  <span class="g-rows gap-0 tritanopia"
                    >Tritanopia <span class="red-green"></span
                  ></span>
                </j-radio>
              </div>
            </li>
          </template>
          <li class="link p-2 g-cols max-c ai-center" @click="logout">
            <j-icon data="@jcon/logout.svg" />Logout
          </li>
        </ul>
      </aside>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { DEFAULT_DATE_FORMAT, ALLOWED_DATE_FORMATS } from '@/util/time-helpers';

export default {
  name: 'UserSlideOut',
  data() {
    return {
      settings: {
        gmt: true,
        twentyFourHour: false,
        dateFormat: DEFAULT_DATE_FORMAT,
        mode: 'dark',
        sync: true,
        scheme: {
          color: 'default',
          contrast: 0,
        },
      },
      loading: false,
      datesChoices: ALLOWED_DATE_FORMATS,
    };
  },
  computed: {
    ...mapState(['showUserInfo']),
    ...mapState('user', [
      'gmt',
      'theme',
      'analog',
      'mode',
      'scheme',
      'dateFormat',
    ]),
    ...mapState('auth', ['token']),
    ...mapGetters('auth', ['isJamfDev', 'identity']),
    ...mapGetters(['isFeatureEnabled']),
    isNewLook() {
      return (
        this.isFeatureEnabled('NEW_LOOK') &&
        this.isFeatureEnabled('COLOR_SCHEMES')
      );
    },
  },
  beforeMount() {
    this.settings.gmt = this.gmt;
    this.settings.twentyFourHour = !this.analog;
    this.settings.mode = this.mode;
    this.settings.scheme = { ...this.scheme };
    this.settings.dateFormat = this.dateFormat;
  },
  watch: {
    async 'settings.dateFormat'() {
      if (this.showUserInfo) {
        await this.updateUserSettings();
      }
    },
  },
  methods: {
    ...mapMutations(['TOGGLE_USER_INFO']),
    logout() {
      this.$router.push({ name: 'logout' });
      this.TOGGLE_USER_INFO();
    },
    async updateUserSettings(mode) {
      this.loading = true;
      await this.$store.dispatch('user/updateUserSettings', {
        gmt: this.settings.gmt,
        analog: !this.settings.twentyFourHour,
        dateFormat: this.settings.dateFormat,
        theme: mode || this.settings.mode,
        scheme: this.settings.scheme,
      });
      this.loading = false;
    },
    doCopy() {
      this.$clipboard(this.token);
      this.TOGGLE_USER_INFO();
    },
  },
};
</script>
<style lang="scss">
@include color-mode(dark, false, ' .schemes .default') {
  @include scheme-dark-default;
}

@include color-mode(dark, false, ' .schemes .protanopia') {
  @include scheme-dark-redgreen;
}

@include color-mode(dark, false, ' .schemes .tritanopia') {
  @include scheme-dark-tritanopia;
}

@include color-mode(light, false, ' .schemes .default') {
  @include scheme-light-default;
}

@include color-mode(light, false, ' .schemes .protanopia') {
  @include scheme-light-redgreen;
}

@include color-mode(light, false, ' .schemes .tritanopia') {
  @include scheme-light-tritanopia;
}

.schemes {
  .red-green {
    display: flex;
    border-radius: 4px;
    width: size-item(4);
    height: 4px;
    background: linear-gradient(
      45deg,
      var(--color-danger-base) 51%,
      var(--color-success-base) 50%
    );
  }
}
</style>

<style lang="scss" scoped>
.user-menu {
  --color-input-base: var(--color-structure-secondary);
  position: absolute;
  right: 10px;
  top: calc(var(--size-top-nav-height) - 4px);
  max-width: 300px;
  background-color: var(--color-structure-secondary);
  z-index: 1001;
  box-shadow: 0 3px 6px 1px var(--color-shadow-base);
  border: 1px solid var(--color-border-contrast-only, var(--color-shadow-base));
  @include border-radius();
  color: var(--color-font-base);

  @include breakpoint(small down) {
    top: 5.2rem;
  }

  .date-label {
    color: var(--color-font-base);
  }

  &-leave-from,
  &-enter-to,
  &-enter-active {
    transition-property: all;
    transition-timing-function: linear, ease-in;
    transition-duration: 200ms;
    transform: translateY();
    opacity: 1;
  }

  &-enter-from,
  &-leave-to {
    transition-property: all;
    transition-timing-function: linear, ease-in;
    transition-duration: 200ms;
    transform: translateY(15px);
    opacity: 0;
  }
}

aside {
  li {
    padding: 0 spacing(2);
    height: max-content;

    &.link {
      @include transition(background-color);
      cursor: pointer;
      &:hover {
        background-color: var(
          --color-structure-highlight-active,
          var(--color-structure-highlight-base)
        );
        &:last-child {
          @include border-radius($corners: bottom left);
          @include border-radius($corners: bottom right);
        }
      }
    }
  }
}
</style>
