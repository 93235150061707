import DataSection from './DataSection';
import DataForward from './DataForward';
import DataRetention from './DataRetention';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Section from '@/components/Section.vue';

export default [
  {
    path: '/data',
    component: DataSection,
    name: 'data.index',
    redirect: { name: 'data.index.forwarding' },
    meta: {
      breadcrumb: {
        linkName: 'data.index',
        linkText: 'Data',
      },
      permissions: [RBAC_RESOURCE.DATA_FORWARD, RBAC_RESOURCE.DATA_RETENTION],
      requireOnlySome: true,
    },
    children: [
      {
        path: '',
        component: Section,
        name: 'data.index.section',
        children: [
          {
            path: 'forwarding',
            component: DataForward,
            name: 'data.index.forwarding',
            meta: {
              permissions: [
                RBAC_RESOURCE.DATA_FORWARD,
                RBAC_RESOURCE.ORGANIZATION,
              ],
            },
          },
          {
            path: 'retention',
            component: DataRetention,
            name: 'data.index.retention',
            meta: {
              permissions: [
                RBAC_RESOURCE.DATA_RETENTION,
                RBAC_RESOURCE.ORGANIZATION,
              ],
            },
          },
        ],
      },
    ],
  },
];
