<template>
  <div>
    <p v-if="pageSize" class="g-cols gap-1 ai-center pt-1 pb-1">
      Show
      <j-dropdown :options="params.pageSizeOptions" open-position="top">
        <template #target="{ styleIndicator }">
          <j-button
            style-type="ghost"
            :class="['page-size-input', styleIndicator]"
          >
            <template #trailing>
              <j-icon data="@jcon/chevron-small.svg" />
            </template>
            {{ pageSize }}
          </j-button>
        </template>
        <template #item="{ item }">
          <div
            class="page-size-list-item"
            :class="{ 'is-selected': item === pageSize }"
            @click="updatePageSize(item)"
          >
            {{ item }}
          </div>
        </template>
      </j-dropdown>
      rows per page
    </p>
  </div>
</template>

<script>
export default {
  name: 'StatusPanelPageSize',
  props: ['params'],
  data() {
    return { pageSize: null };
  },
  mounted() {
    this.params.api.addEventListener('paginationChanged', this.getPageSize);
  },
  beforeUnmount() {
    this.params.api.removeEventListener('paginationChanged');
  },
  methods: {
    getPageSize() {
      this.pageSize = this.params.api?.paginationGetPageSize();
    },
    updatePageSize(value) {
      if (value !== this.pageSize) {
        this.params.setCacheBlockSize(value);
        this.getPageSize();
      }
    },
  },
};
</script>
