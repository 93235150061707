<template>
  <div class="gp-container scroll-vertical">
    <div class="g-rows p-2">
      <div class="f-wrap">
        <Card class="g-rows max-c" data-testid="basic-details">
          <ListItem title="Name">
            {{ action.name }}
          </ListItem>
          <ListItem title="Description">
            {{ action.description }}
          </ListItem>
          <ListItem title="Created">
            {{ formatEpochDate(action.created) }}
          </ListItem>
          <ListItem title="Updated">
            {{ formatEpochDate(action.updated) }}
          </ListItem>
        </Card>
        <div class="g-rows" v-if="cloudOnlyAccess">
          <Card class="g-rows">
            <ListItem title="Sending Alert Data to Jamf Protect">
              <div
                v-if="action.alertConfig.jamfCloud.enabled"
                class="g-rows gap-0"
              >
                <span>Enabled </span>
                <small
                  >Min Severity:
                  <strong>{{
                    action.alertConfig.jamfCloud.minSeverity
                  }}</strong></small
                >
                <small
                  >Max Severity:
                  <strong>{{
                    action.alertConfig.jamfCloud.maxSeverity
                  }}</strong>
                </small>
              </div>
              <template v-else>Disabled</template>
            </ListItem>
            <ListItem title="Alert Collection Endpoints">
              <template v-if="action.alertConfig.endpoints.length > 0">
                <span
                  v-for="(endpoint, index) in action.alertConfig.endpoints"
                  :key="endpoint.url"
                >
                  {{ endpoint.url
                  }}{{
                    action.alertConfig.endpoints.length - 1 !== index ? ',' : ''
                  }}
                </span>
              </template>
            </ListItem>
          </Card>
          <Card class="g-rows">
            <ListItem
              title="Forwarding Unified Log Data to a Third Party Storage Solution"
            >
              <div
                v-if="action.ulogConfig.jamfCloud.enabled"
                class="g-rows gap-0"
              >
                <span>Enabled</span>
                <template v-if="isFeatureEnabled('ULOG_SEVERITY')">
                  <small
                    >Min Severity:
                    <strong>{{
                      action.ulogConfig.jamfCloud.minSeverity
                    }}</strong></small
                  >
                  <small
                    >Max Severity:
                    <strong>{{
                      action.ulogConfig.jamfCloud.maxSeverity
                    }}</strong>
                  </small>
                </template>
              </div>
              <template v-else>Disabled</template>
            </ListItem>
            <ListItem title="Unified Log Collection Endpoints">
              <template v-if="action.ulogConfig.endpoints.length > 0">
                <span
                  v-for="(endpoint, index) in action.ulogConfig.endpoints || []"
                  :key="endpoint.url"
                >
                  {{ endpoint.url
                  }}{{
                    action.ulogConfig.endpoints.length - 1 !== index ? ',' : ''
                  }}
                </span>
              </template>
            </ListItem>
          </Card>
          <Card class="g-rows" v-if="isFeatureEnabled('TELEMETRY_STREAM')">
            <ListItem
              title="Forwarding Telemetry Data to a Third Party Storage Solution"
            >
              <div
                v-if="action.telemetryConfig.jamfCloud.enabled"
                class="g-rows gap-0"
              >
                <span>Enabled</span>
              </div>
              <template v-else>Disabled</template>
            </ListItem>
            <ListItem title="Telemetry Collection Endpoints">
              <template v-if="action.telemetryConfig.endpoints.length > 0">
                <span
                  v-for="(endpoint, index) in action.telemetryConfig
                    .endpoints || []"
                  :key="endpoint.url"
                >
                  {{ endpoint.url
                  }}{{
                    action.telemetryConfig.endpoints.length - 1 !== index
                      ? ','
                      : ''
                  }}
                </span>
              </template>
            </ListItem>
          </Card>
        </div>
        <Card v-else>
          <ListItem
            v-if="action.ulogConfig"
            title="Unified Log Collection Endpoints"
          >
            <template v-if="action.ulogConfig.endpoints.length > 0">
              <span
                v-for="(endpoint, index) in action.ulogConfig.endpoints || []"
                :key="endpoint.url"
              >
                {{ endpoint.url
                }}{{
                  action.ulogConfig.endpoints.length - 1 !== index ? ',' : ''
                }}
              </span>
            </template>
          </ListItem>
          <ListItem
            v-if="isFeatureEnabled('TELEMETRY_STREAM')"
            title="Telemetry Collection Endpoints"
          >
            <template v-if="action.telemetryConfig.endpoints.length > 0">
              <span
                v-for="(endpoint, index) in action.telemetryConfig.endpoints ||
                []"
                :key="endpoint.url"
              >
                {{ endpoint.url
                }}{{
                  action.telemetryConfig.endpoints.length - 1 !== index
                    ? ','
                    : ''
                }}
              </span>
            </template>
          </ListItem>
        </Card>
      </div>
      <CollapseCard v-if="cloudOnlyAccess" title="Remote Alerting Collection">
        <DataConfigsTiles :config="action.alertConfig" />
      </CollapseCard>
    </div>
  </div>
</template>

<script>
import DataConfigsTiles from '@/modules/actions/components/DataConfigsTiles.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import { mapGetters, mapState } from 'vuex';
import ListItem from '@/components/ListItem.vue';
import Card from '@/components/Card.vue';
import { useGmt } from '@/composables/gmt';
import { useRBAC } from '@/composables/rbac';

export default {
  name: 'ActionDetails',
  components: {
    DataConfigsTiles,
    CollapseCard,
    ListItem,
    Card,
  },
  setup() {
    const { formatEpochDate } = useGmt();
    const { cloudOnlyAccess } = useRBAC();

    return { formatEpochDate, cloudOnlyAccess };
  },
  computed: {
    ...mapState('primary', {
      action: (state) => state.actions.action,
    }),
    ...mapGetters(['isFeatureEnabled']),
  },
};
</script>
