<template>
  <div>
    <div :class="{ 'is-open': open, 'collapsible-nav': true }" @click="onOpen">
      <j-icon
        data="@jcon/gear.svg"
        height="16"
        :class="{ 'is-active': open || isActive }"
      />
      <span>{{ label }}</span>
      <span class="indicator"></span>
    </div>
    <transition name="height" mode="out-in">
      <ul v-if="open" class="collapsible-nav-list">
        <slot></slot>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },
  props: {
    modelValue: Boolean,
    label: {
      type: String,
      default: 'Administrative',
    },
    isActive: Boolean,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      open: this.modelValue,
    };
  },
  watch: {
    modelValue(val) {
      this.open = val;
    },
  },
  methods: {
    onOpen() {
      this.open = !this.open;
      this.$emit('update:modelValue', this.open);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: var(--color-sidenav-primary);

.collapsible-nav {
  @include grid;
  @include grid-columns(min-content auto auto);
  align-items: center;
  cursor: pointer;
  min-width: 160px;
  z-index: 10;

  .indicator {
    @include transition(transform, 300ms, ease-in-out);
    @include chevron($color: var(--color-font-sidenav-base));
    transform: scale(1.5) rotate(180deg);
    &::before,
    &::after {
      height: 0.5px;
    }
  }

  svg {
    @include transition(color, 300ms, ease-in-out);
  }

  &:hover {
    svg {
      color: $primary;
    }
  }

  &.is-open {
    .indicator {
      transform: scale(1.5) rotate(0deg);
    }
  }

  .is-active {
    color: $primary;
  }

  &-list {
    @include grid(0);
    margin-top: spacing(2);
  }
}

.section-title {
  text-transform: uppercase;
  font-size: var(--size-font-label-base);
  color: var(--color-font-sidenav-secondary);
  max-width: 160px;
}
</style>
