<template>
  <router-link
    v-if="to && facts"
    class="alert-description"
    ref="link"
    :to="to"
    @click="routeTo"
  >
    <span v-for="({ name }, index) in facts" :key="name + index" class="names"
      >{{ getKeyNameMatch(name) }}
      <template v-if="facts.length > 1 && facts.length !== index + 1"
        >&
      </template> </span
    ><br />
    <span v-for="({ human, uuid }, index) in facts" :key="index + uuid">
      {{ human }}
    </span>
  </router-link>
</template>

<script>
import { getKeyNameMatch } from '@/util';
import { useTableCell } from '@/components/table/framework-components/cells/tableCell';

export default {
  name: 'AlertTableCellDescription',
  compatConfig: {
    MODE: 3,
  },
  setup(props) {
    const { addEventListener } = useTableCell(props);
    const routeTo = (params) => {
      if (params.type === 'cellKeyDown') {
        // ensure keyboard navigation still works
        if (params.event.key === 'Enter') {
          props.params.action(props.params);
        }
      } else {
        if (params.metaKey || params.ctrlKey) {
          // let the link work as natural
        } else {
          params.preventDefault();
          props.params?.action(props.params);
        }
      }
    };
    addEventListener('cellKeyDown', routeTo);
    return { routeTo, getKeyNameMatch };
  },
  computed: {
    facts() {
      return this.params?.valueFormatted?.match?.facts;
    },
    to() {
      return {
        name: 'alerts.item',
        params: { uuid: this.params?.data?.uuid },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-description {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-font-base);
  padding: 13px 0 !important;

  .names {
    color: var(--color-font-link-base);
    font-weight: 600;
  }
  &:hover {
    text-decoration: none !important;
    .names {
      text-decoration: underline;
    }
  }
}
</style>
