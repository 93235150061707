<template>
  <div class="cell-button-container">
    <j-button
      v-if="action"
      ref="button"
      @click="action(params)"
      :style-type="styleType"
      tabindex="0"
    >
      <template v-if="icon && leading" #leading>
        <j-icon :data="icon" />
      </template>
      <template v-if="icon && trailing" #trailing>
        <j-icon :data="icon" />
      </template>
      <template v-if="label" #default>{{ label }}</template>
    </j-button>
  </div>
</template>

<script>
import { useTableCell } from './tableCell';

export default {
  name: 'TableCellButton',
  props: ['params'],
  setup(props) {
    const { addEventListener, position, leading, trailing } =
      useTableCell(props);
    position.value = props.params.position || position.value;
    return { position, addEventListener, leading, trailing };
  },
  data() {
    return {
      action: null,
      icon: null,
      label: null,
      styleType: 'ghost-primary',
    };
  },
  mounted() {
    this.addEventListener('cellFocused', () => this.$refs.button?.$el.focus());
    this.action = this.params.action;
    this.label = this.params.label;
    this.icon = this.params.icon;
    this.styleType = this.params.styleType || this.styleType;
  },
  unmounted() {
    this.params.api.removeEventListener('cellFocused');
  },
};
</script>

<style lang="scss" scoped>
.cell-button-container {
  margin: 2px;
}
</style>
