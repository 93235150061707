<template>
  <nav>
    <div class="g-cols" aria-label="breadcrumbs">
      <ul class="nav-breadcrumbs">
        <template v-for="(breadcrumb, index) in breadcrumbs" :key="index">
          <li class="a-breadcrumb">
            <router-link
              v-if="breadcrumb.linkName && index !== breadcrumbs.length - 1"
              :to="getParentRoute(breadcrumb.linkName)"
            >
              {{ breadcrumb.linkText }}
            </router-link>
            <p v-else>{{ breadcrumb.linkText }}</p>
            <j-icon
              v-if="index !== breadcrumbs.length - 1"
              data="@jcon/chevron-small.svg"
              class="caret"
              color="var(--color-font-secondary)"
            />
          </li>
        </template>
      </ul>
      <slot></slot>
    </div>
    <div v-if="identity" class="user-info">
      <p v-if="siteEnv !== 'production'" class="site">{{ siteEnv }}</p>
      <clock />
      <div ref="userMenu">
        <a
          @click="TOGGLE_USER_INFO"
          @keydown.enter="TOGGLE_USER_INFO"
          class="user user-email"
          tabindex="0"
        >
          <span v-if="identity.email">{{ identity.email }}</span>
          <span v-else-if="identity.nickname">{{ identity.nickname }}</span>
          <span v-else>user</span>
          <j-icon data="@jcon/user.svg" />
        </a>
        <UserMenu />
      </div>
    </div>
    <div v-else-if="user" class="user-info" ref="userMenu">
      <a @click="TOGGLE_USER_INFO" class="user">
        {{ user.username }}
        <j-icon data="@jcon/user.svg" />
      </a>
      <UserMenu />
    </div>
  </nav>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import UserMenu from '@/components/UserMenu.vue';
import Clock from '@/components/Clock.vue';

export default {
  name: 'NavBar',
  components: { Clock, UserMenu },
  computed: {
    ...mapState(['siteEnv', 'showUserInfo']),
    ...mapState('route', ['name']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['identity']),
    ...mapGetters(['hasLimitedAppAccess']),
    ...mapState({
      queryState: (state) => state.app.queryState,
    }),
    breadcrumbs() {
      return this.$route.matched
        .filter(({ meta }) => {
          const checkLink =
            this.hasLimitedAppAccess && meta?.breadcrumb?.linkName
              ? this.$router.hasRoute(meta.breadcrumb.linkName)
              : true;
          return !!meta?.breadcrumb && checkLink;
        })
        .map(({ meta: { breadcrumb } }) => breadcrumb);
    },
  },
  watch: {
    showUserInfo() {
      // ensure event listener is removed when user info is hidden
      document[this.showUserInfo ? 'addEventListener' : 'removeEventListener'](
        'mousedown',
        this.closeSlideOut
      );
    },
  },
  methods: {
    ...mapMutations(['TOGGLE_USER_INFO']),
    logout() {
      this.$store.dispatch('auth/logoutUser');
      this.$router.push({ name: 'login' });
    },
    closeSlideOut(event) {
      if (this.$refs.userMenu && !this.$refs.userMenu.contains(event.target)) {
        this.TOGGLE_USER_INFO();
      }
    },
    getParentRoute(name) {
      return this.queryState[name]
        ? { name, ...this.queryState[name] }
        : { name };
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 spacing(2);
  background-color: var(--color-structure-base);
  border-bottom: 1px solid var(--color-border-secondary);
  height: var(--size-top-nav-height);

  .nav-breadcrumbs {
    @include breadcrumb-list-container;

    .a-breadcrumb {
      @include breadcrumb;
      align-items: center;
      > a {
        margin: 0;
        color: var(--color-font-link-base);
      }
    }

    .caret {
      margin: spacing(0.5);
      transform: rotate(270deg);
      color: var(--color-border-base);
    }
  }

  .user-info {
    @include grid(spacing());
    @include grid-columns;
    align-items: baseline;
    > div {
      @include grid(spacing());
      @include grid-columns;
    }
    .user {
      @include grid(spacing());
      @include grid-columns;
      align-items: center;
      &:focus {
        @include has-focus;
      }
    }
  }

  .clock,
  .site {
    font-size: 0.8rem;
    color: var(--color-font-secondary);
    white-space: nowrap;

    @include breakpoint(small down) {
      display: none;
    }
  }

  .site {
    color: var(--color-danger-base);
  }
}
</style>
