<template>
  <ListItem title="Hash">
    <div class="hash">
      <div>
        <span>sha1:</span>
        <pre>{{ sha1 }}</pre>
      </div>
      <div>
        <span>sha256:</span>
        <pre>{{ sha256 }}</pre>
      </div>
    </div>
  </ListItem>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
export default {
  name: 'HashItem',
  props: ['sha1', 'sha256'],
  components: {
    ListItem,
  },
};
</script>

<style lang="scss" scoped>
.hash {
  @include grid(spacing());
  @include grid-rows;

  > div {
    @include grid(spacing());
    @include grid-columns(max-content auto);
    align-items: center;
    pre {
      word-break: break-word;
      max-width: max-content;
    }
  }
}
</style>
