import { computed } from 'vue';
import { useStore } from 'vuex';

export function useTenants() {
  const store = useStore();
  const loginConfig = computed(() => store.state.auth.loginConfig);
  const url =
    process.env.VUE_APP_PRIMARY_DOMAIN ||
    `${loginConfig.value.branch && loginConfig.value.branch + '.'}${
      loginConfig.value.account
    }-protect.jamfcloud.com`;

  const buildVanity = (vanity) => {
    return vanity ? `${vanity}.${url}` : '';
  };

  const accountBusinessTypes = {
    Commercial: 'Commercial',
    Government: 'Government',
    Education_K12: 'Education-K12',
    Education_High_Ed: 'Education-High Ed',
    Other: 'Other',
  };

  const transformAccountBusinessType = (businessType) => {
    return accountBusinessTypes[businessType] ?? businessType;
  };

  return {
    buildVanity,
    url,
    accountBusinessTypes,
    transformAccountBusinessType,
  };
}
