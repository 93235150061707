<template>
  <div class="gp-content" v-if="insight">
    <div class="insight-details" :class="{ only: !hasComputerPermissions }">
      <div class="details">
        <j-switch v-model="isEnabled" :is-read-only="!canWrite" />
        <div class="description">
          <InsightLabel
            :label="insight.label"
            :tags="insight.tags"
            :description="insight.description"
          />
        </div>
      </div>
      <InsightGraph
        :fail="insight.totalFail"
        :pass="insight.totalPass"
        :none="insight.totalNone"
        :enabled="true"
      />
    </div>
    <JamfTable
      v-if="hasComputerPermissions"
      :get-data="getData"
      :suppress-filter-bar="true"
      :suppress-search="true"
      :columns="columns"
    >
      <template #left="{ gridApi }">
        <div class="toggle-group">
          <label class="toggle">
            <input
              v-model="fail"
              type="checkbox"
              @input="setFilters(gridApi, 'Fail', fail)"
            />
            <span class="indicator">
              <j-icon
                class="toggle-icon"
                data="@jcon/warning.svg"
                width="12"
                height="12"
              />
              <span class="toggle-text">Noncompliant</span> ({{
                insight.totalFail
              }})
            </span>
          </label>
          <label class="toggle">
            <input
              v-model="pass"
              type="checkbox"
              @input="setFilters(gridApi, 'Pass', pass)"
            />
            <span class="indicator">
              <j-icon
                class="toggle-icon"
                data="@jcon/check-circle.svg"
                width="12"
                height="12"
              />
              <span class="toggle-text">Compliant</span> ({{
                insight.totalPass
              }})
            </span>
          </label>
          <label class="toggle">
            <input
              v-model="none"
              type="checkbox"
              @input="setFilters(gridApi, 'Unknown', none)"
            />
            <span class="indicator">
              <j-icon
                class="toggle-icon"
                data="@jcon/error.svg"
                width="12"
                height="12"
              />
              <span class="toggle-text">Unknown</span> ({{ insight.totalNone }})
            </span>
          </label>
        </div>
      </template>
    </JamfTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import InsightGraph from './components/InsightGraph';
import InsightLabel from './components/InsightLabel';
import { success, danger, warning } from '@/util/constants/feedback.icons';

const parseScorecard = (params) => {
  const { insightsStatsPass, insightsStatsFail, insightsStatsUnknown } =
    params || {};
  const type = { icon: '', color: 'inherit' };
  if (insightsStatsPass) {
    type.icon = success;
    type.color = 'var(--color-success-base)';
  }
  if (insightsStatsFail) {
    type.icon = warning;
    type.color = 'var(--color-warning-base)';
  }
  if (insightsStatsUnknown) {
    type.icon = danger;
    type.color = 'inherit';
  }
  return type;
};

export default {
  name: 'InsightDetails',
  components: { InsightGraph, JamfTable, InsightLabel },
  setup() {
    const { canWrite, canAccess } = useRBAC(RBAC_RESOURCE.INSIGHT);
    return { canWrite, canAccess };
  },
  data() {
    return {
      fail: false,
      pass: false,
      none: false,
      id: this.$route.params.id,
      insight: null,
      columns: [
        {
          headerName: 'Name',
          field: 'hostName',
          sort: 'desc',
          flex: 1,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'computers.index.compliance',
              params: { id: params.data?.uuid },
            }),
          },
          valueFormatter: (params) => params?.value || params?.data?.uuid,
        },
        {
          headerName: 'Compliance',
          field: 'insightsStats',
          type: 'iconColumn',
          minWidth: 120,
          maxWidth: 120,
          width: 120,
          flex: 1,
          cellRendererParams: {
            setIcon: (_, params) => parseScorecard(params.data),
          },
        },
        {
          headerName: 'Last Insight',
          field: 'insightsUpdated',
          type: 'dateColumn',
          flex: 1,
        },
      ],
      filters: [],
      filter: {},
      listEndpoint: 'primary/listInsights',
    };
  },
  computed: {
    ...mapState('primary', {
      insights: (state) => state.insights.items,
    }),
    hasComputerPermissions() {
      return this.canAccess(RBAC_RESOURCE.COMPUTER);
    },
    isEnabled: {
      get() {
        return this.insight.enabled;
      },
      async set(value) {
        this.insight.enabled = value;
        await this.setEnabled(value);
      },
    },
  },
  async beforeMount() {
    if (!this.insights.length) {
      await this.$store.dispatch(this.listEndpoint);
    }
    await this.getInsight();
  },
  mounted() {
    // add in padding from details div
    const height = 'calc(var(--table-bar-height) * 2)';
    document.documentElement.style.setProperty('--table-calc-height', height);
  },
  unmounted() {
    document.documentElement.style.removeProperty('--table-calc-height');
  },
  methods: {
    async getData({ nextToken, pageSize }) {
      this.filter = this.getFilter();
      const response = await this.$store.dispatch(
        'primary/listInsightComputers',
        {
          uuid: this.id,
          nextToken,
          pageSize,
          filter: this.filter,
        }
      );

      await this.$store.dispatch(this.listEndpoint);
      await this.getInsight();

      return response;
    },
    setFilters(api, key, value) {
      if (!value) {
        this.filters.push(key);
      } else {
        this.filters = this.filters.filter((item) => item !== key);
      }
      api.onFilterChanged();
    },
    getFilter() {
      const result = { or: [] };
      if (this.filters.length) {
        this.filters.forEach((item) => {
          result.or.push({ [`insightsStats${item}`]: { greaterThan: 0 } });
        });
        return result;
      }
      return null;
    },
    async getInsight() {
      const [item] = this.insights.filter(({ uuid }) => this.id === uuid);
      this.insight = item;
    },
    async setEnabled(enabled) {
      const { uuid } = this.insight;
      await this.$store.dispatch('primary/updateInsightStatus', {
        uuid,
        enabled,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.insight-details {
  @include grid(spacing());
  grid-auto-rows: max-content;
  padding: spacing(2);
  border-bottom: 1px solid var(--color-border-secondary);
  .details {
    @include grid;
    @include grid-columns(min-content auto);
    align-items: flex-start;

    .description {
      @include grid(spacing());

      p {
        @include paragraph;
        margin-bottom: spacing(3);
      }
    }
  }

  &.only {
    height: 100%;
  }
}

.table-icon {
  .warning-2 {
    color: var(--color-warning-base);
  }
  .check-circle {
    color: var(--color-warning-base);
  }
}

.toggle-group {
  @include toggle-group-container;
  @include grid(0);
  grid-auto-flow: column;

  &.is-group {
    padding-right: var(--size-grid-gap-base);
    border-right: 1px solid var(--color-border-secondary);
    display: block;
  }
}

.toggle {
  @include toggle();

  margin-bottom: 0 !important;
  &-icon {
    margin-right: 4px;
  }
  &-text {
    margin-right: 4px;
    @include breakpoint(small down) {
      display: none;
    }
  }
}
</style>
