<template>
  <div :class="classes">
    <div v-if="hasLeft" class="left">
      <slot name="left"></slot>
      <j-back-button v-if="!!$attrs.onBack" @click="$attrs.onBack" />
    </div>
    <transition name="fade"
      ><div v-if="title" class="form-title">
        <h1 :class="['h4', loading ? 'load-text' : '']">{{ title }}</h1>
        <span v-if="hasDefault" class="divider"></span>
      </div>
    </transition>
    <div class="center">
      <slot></slot>
      <j-button
        v-if="showEdit && !loading"
        @click="$attrs.onEdit"
        data-feature-id="edit"
        ><template #leading><j-icon data="@jcon/edit.svg" /></template
        >Edit</j-button
      >
      <template v-else-if="showSave && !loading">
        <j-save-button v-if="!useSaveSlot" @click="submit" />
        <slot name="custom-save-button" :submit-trigger="submit"></slot>

        <transition name="fade">
          <j-button
            v-if="showCancel"
            data-feature-id="cancel"
            style-type="secondary"
            @click="$attrs.onCancel"
            >Cancel</j-button
          >
        </transition>
        <gp-loader :show-loader="submitting" />
        <j-updated
          v-model="isUpdated"
          :failed="failed"
          :message="message"
          :message-failed="messageFailed"
        />
      </template>
    </div>
    <div v-if="hasRight" class="right">
      <slot name="right"></slot>
      <j-delete-button v-if="showRemove" @click="$attrs.onRemove" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { useRouteHelpers } from '@/composables/route-helpers';

const messageFailed = 'An error occurred!';

export default {
  name: 'FormControlBar',
  props: {
    title: String,
    canWrite: {
      type: Boolean,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    updatedMessage: {
      type: String,
      default: 'Updated!',
    },
    validationMessage: {
      type: String,
      default: 'Error in form.',
    },
    loading: Boolean,
  },
  compatConfig: {
    MODE: 3,
  },
  inheritAttrs: false,
  setup() {
    const { mode } = useRouteHelpers();
    return { mode };
  },
  data() {
    return {
      isUpdated: false,
      submitted: false,
      message: this.updatedMessage,
      messageFailed,
      failed: false,
    };
  },
  watch: {
    isSubmitComplete(value) {
      this.setSubmittedState(this.submitState);
      this.isUpdated = value;
    },
  },
  computed: {
    ...mapState(['submitState', 'submitting']),
    ...mapGetters(['isFailedMutation', 'isSubmitComplete']),
    classes() {
      return {
        'form-control': true,
        'has-right': this.hasRight,
        'has-left': this.hasLeft,
        'has-title': !!this.title,
      };
    },
    hasRight() {
      return !!this.$slots.right || !!this.$attrs?.onRemove;
    },
    hasLeft() {
      return !!this.$slots.left || !!this.$attrs?.onBack;
    },
    hasDefault() {
      return this.showSave || this.showEdit || !!this.$slots.default;
    },
    hasSubmit() {
      const submits = Object.keys(this.$attrs).filter((key) =>
        key.includes('onSubmit')
      );
      return submits.length > 0;
    },
    showSave() {
      // no need to check mode if edit function is not used
      const checkEdit = this.showEdit
        ? !!(this.mode.edit || this.mode.create)
        : true;
      return this.canWrite && this.hasSubmit && checkEdit;
    },
    showEdit() {
      return (
        this.canWrite &&
        !!this.$attrs?.onEdit &&
        !this.mode.create &&
        !this.mode.edit
      );
    },
    showCancel() {
      return this.showSave && !!this.$attrs?.onCancel && !this.mode.create;
    },
    useSaveSlot() {
      return !!this.$slots?.['custom-save-button'];
    },
    showRemove() {
      return (
        this.canRemove &&
        this.canWrite &&
        !!this.$attrs?.onRemove &&
        !this.mode.create &&
        !this.loading
      );
    },
  },
  methods: {
    submit() {
      this.$store.commit('SET_SUBMIT_START');
      // only set submit watch if using the forms composable watch instead of a submit function
      if (!this.$attrs?.onSubmit) {
        this.$store.commit('SET_SUBMIT_WATCH');
      } else {
        this.$attrs.onSubmit();
      }
    },
    setSubmittedState({ success, failed }) {
      this.failed = false;
      if (success && !failed && !this.isFailedMutation) {
        if (this.mode.create) {
          this.message = 'Created!';
          this.$attrs['onSubmit:created']?.();
        } else {
          this.message = this.updatedMessage;
          this.$attrs['onSubmit:success']?.();
        }
      } else if (this.isFailedMutation || failed) {
        this.failed = true;
        this.isUpdated = true;
        this.messageFailed = failed ? this.validationMessage : messageFailed;
        this.$attrs['onSubmit:failed']?.();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  --size-action-height-base: var(--size-action-height-secondary);

  @include grid;
  @include grid-columns(max-content);
  align-items: center;
  padding: 0 spacing(2);
  border-bottom: 1px solid var(--color-border-secondary);
  min-height: var(--size-form-control-bar-height);
  overflow-x: auto;

  .right,
  .left,
  .center,
  .form-title {
    @include grid;
    @include grid-columns(max-content);
    align-items: center;
  }

  .right {
    justify-content: flex-end;
    align-items: center;
  }

  &.has-right {
    grid-auto-columns: max-content auto;
    &.has-left,
    &.has-title {
      grid-auto-columns: max-content auto auto;
    }

    &.has-left.has-title {
      grid-auto-columns: max-content max-content auto auto;
    }
  }

  .load-text {
    @include transition(background);
    height: var(--size-font-h4-height);
    background: var(--color-border-secondary);
    opacity: 0.5;
    width: max-content;
    color: transparent;
  }
}
</style>
