import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import Computers from './Computers.vue';
import Computer from './Computer.vue';
import ComputerOverview from '@/modules/computers/ComputerOverview.vue';
import ComputerDetails from './ComputerDetails.vue';
import ComputerInsights from './ComputerInsights.vue';

export default [
  {
    path: '/computers',
    component: Section,
    name: 'computers',
    props: { rbac: { permissions: [RBAC_RESOURCE.COMPUTER] } },
    meta: {
      breadcrumb: {
        linkName: 'computers.index',
        linkText: 'Computers',
      },
    },
    children: [
      {
        path: '',
        name: 'computers.index',
        component: Computers,
      },
      {
        path: ':id',
        component: Computer,
        meta: {
          breadcrumb: {
            linkText: 'Computer',
          },
        },
        children: [
          {
            path: 'overview',
            name: 'computers.index.overview',
            component: ComputerOverview,
          },
          {
            path: 'details',
            name: 'computers.index.detail',
            component: ComputerDetails,
          },
          {
            path: 'insights',
            name: 'computers.index.compliance',
            component: ComputerInsights,
            meta: {
              permissions: [RBAC_RESOURCE.INSIGHT],
            },
          },
        ],
      },
    ],
  },
];
