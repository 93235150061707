<template>
  <div class="splash-wrapper">
    <div class="splash" :class="size">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Splash',
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },
};
</script>

<style lang="scss" scoped>
.splash-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.splash {
  @include grid();
  margin: spacing(4);
  border-radius: 4px;
  box-shadow: var(--size-shadow-small) var(--color-shadow-base);
  align-self: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background: var(--color-structure-secondary);
  max-width: max-content;
  padding: spacing(2);
}
</style>
