export const USBCONTROL_TYPES = {
  Encryption: 'Encryption',
  Vendor: 'Vendor',
  Serial: 'Serial',
  Product: 'Product',
};

export const USBCONTROL_TYPES_NAMES = {
  [USBCONTROL_TYPES.Encryption]: 'Encrypted Devices',
  [USBCONTROL_TYPES.Vendor]: 'Vendor ID',
  [USBCONTROL_TYPES.Serial]: 'Serial Number',
  [USBCONTROL_TYPES.Product]: 'Product ID',
};

export const USBCONTROL_MOUNT_ACTIONS = {
  ReadOnly: 'ReadOnly',
  ReadWrite: 'ReadWrite',
  Prevented: 'Prevented',
};

export const USBCONTROL_MOUNT_ACTIONS_NAMES = {
  [USBCONTROL_MOUNT_ACTIONS.ReadOnly]: 'Read Only',
  [USBCONTROL_MOUNT_ACTIONS.ReadWrite]: 'Read and Write',
  [USBCONTROL_MOUNT_ACTIONS.Prevented]: 'Prevent',
};

export const USBCONTROL_ENCRYPTION = {
  All: 'All',
  Encrypted: 'Encrypted',
  Unencrypted: 'Unencrypted',
};

export const USBCONTROL_ACTION_MESSAGE = {
  [USBCONTROL_MOUNT_ACTIONS.Prevented]:
    'This removable storage device is not allowed.',
  [USBCONTROL_MOUNT_ACTIONS.ReadOnly]:
    'This removable storage device is limited to read-only.',
};
