<template>
  <canvas
    ref="chart"
    :aria-label="title"
    role="img"
    tabindex="0"
    aria-describedby="chartAria"
  >
    <ul
      id="chartAria"
      v-for="{ data, label, parsing } in datasets"
      tabindex="0"
      readonly
      :key="label"
      :aria-label="`${label} Content`"
      class="g-cols"
    >
      <template v-if="labels">
        <li v-for="(label, index) in labels" :key="index">
          {{ ariaLabel(label) }}{{ data[index] }}
        </li>
      </template>
      <template v-else>
        <li v-for="(item, index) in data" :key="index" scope="row">
          <template v-if="parsing">
            {{ ariaLabel(item[parsing.xAxisKey]) }}{{ item[parsing.yAxisKey] }}
            {{ label }}
          </template>
          <template v-else>
            {{ ariaLabel(item.x) }}{{ item.y }}{{ label }}
          </template>
        </li>
      </template>
    </ul>
  </canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import { timeAdapter } from './utc-time-adaptor';
import { mapState } from 'vuex';
import { ariaDate } from '@/util';
import { _adapters } from 'chart.js';
import { shallowRef } from 'vue';

Chart.defaults.font.family = '"Proxima Nova", sans-serif';

export default {
  name: 'ChartLine',
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
    },
    isTime: Boolean,
    title: String,
    timeOptions: {
      type: Object,
      validator: function (value) {
        const validUnits = [
          'millisecond',
          'second',
          'minute',
          'hour',
          'day',
          'week',
          'month',
          'quarter',
          'year',
          null,
          undefined,
        ];
        // The value must match a proper time unit
        return validUnits.includes(value?.x?.unit);
      },
      default: () => ({
        x: { unit: 'day' },
      }),
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    ...mapState('user', ['colors', 'gmt', 'analog']),
    config() {
      const border = 'rgba(146, 146, 146, 0.5)';
      const labelStyles = {
        color: this.colors['--color-font-secondary'],
      };
      const tooltipFont = this.colors['--color-font-inverse'];

      return {
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
          plugins: {
            legend: {
              align: 'start',
              position: 'bottom',
              labels: {
                color: this.colors['--color-font-base'],
                usePointStyle: true,
                textAlign: 'left',
                boxWidth: 8,
                boxHeight: 8,
                padding: 24,
                font: {
                  size: 14,
                  weight: '400',
                },
              },
            },
            tooltip: {
              cornerRadius: 4,
              displayColors: false,
              padding: 8,
              backgroundColor: this.colors['--color-indicator-tooltip'],
              bodyColor: tooltipFont,
              titleColor: tooltipFont,
            },
          },
          scales: {
            x: {
              ...(this.isTime ? { type: 'time' } : {}),
              time: {
                ...this.timeOptions.x,
              },
              grid: {
                drawTicks: false,
                color: border,
              },
              ticks: {
                ...labelStyles,
                major: {
                  enabled: true,
                },
                stepSize: this.timeOptions.x.stepSize,
              },
            },
            y: {
              min: 0,
              grid: {
                drawTicks: false,
                color: border,
              },
              ticks: {
                ...labelStyles,
                precision: 0,
                maxTicksLimit: 10,
              },
            },
          },
        },
      };
    },
  },
  watch: {
    colors: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    datasets: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    analog() {
      this.updateChart();
    },
  },
  methods: {
    ariaLabel(value) {
      return this.isTime
        ? `On ${ariaDate(
            value,
            this.gmt,
            this.timeOptions.x.unit === 'hour'
          )}, there were `
        : value;
    },
    updateChart() {
      this.chart.options = { ...this.config.options };
      this.chart.data = {
        datasets: this.setDatasetColors(this.datasets),
        labels: this.labels,
      };
      this.chart.update();
    },
    setDatasetColors(datasets) {
      datasets.forEach(({ color }, index) => {
        if (!color) {
          // ensure a color is always set for the data line
          this.$log.warn(
            'ChartLine: ',
            `A dataset does not set a color variable and will default to --color-font-base.
            If this was not intended please set 'color' on each dataset with an available variable from the user/colors store`
          );
          color = '--color-font-base';
        }
        datasets[index]['borderColor'] =
          typeof color === 'string'
            ? this.colors[color]
            : this.colors[color.border];
        datasets[index]['backgroundColor'] =
          typeof color === 'string'
            ? this.colors[color]
            : this.colors[color.background];
      });

      return datasets;
    },
  },
  beforeCreate() {
    _adapters._date.override(timeAdapter(this));
  },
  mounted() {
    // this.chart.update() throws a maximum call stack error when the dataset changes
    // https://stackoverflow.com/questions/68602389/maximum-call-stack-error-when-attempting-to-update-chart-in-vue-js
    const datasets = this.setDatasetColors(this.datasets);
    this.chart = shallowRef(
      new Chart(this.$refs.chart, {
        ...this.config,
        data: {
          labels: this.labels,
          datasets,
        },
      })
    );
  },
  beforeUnmount() {
    this.chart?.destroy();
  },
};
</script>
