<template>
  <div class="scroll-vertical">
    <div class="org-form g-rows gap-3">
      <div class="g-rows">
        <j-input-group
          v-if="!mode.create"
          label="Tenant Access"
          helper-text="Restrict this setting to prevent customer access to the tenant"
        >
          <j-toggle-group is-allow-restrict>
            <span></span>
            <j-radio
              v-model="data.active"
              :native-value="false"
              :name="toggleName"
            >
              Restrict
            </j-radio>
            <j-radio
              v-model="data.active"
              :native-value="true"
              :name="toggleName"
            >
              Allow
            </j-radio>
          </j-toggle-group>
        </j-input-group>
        <j-input
          :has-error="v$.data.name.$error"
          label="Name"
          v-model="data.name"
          error-text="Name is required"
        />
        <j-textarea
          v-model="data.description"
          label="Tenant Description"
          data-feature-id="tenant-description"
        />
        <j-input
          v-if="mode.create"
          :has-error="v$.data.hd.$error"
          :label="`Domain ( Vanity Domain: ${vanity}.${primaryDomain} )`"
          v-model="data.hd"
          :error-text="
            v$.data.hd.isUniqueVanity.$invalid
              ? 'A vanity domain with this name already exists'
              : 'Vanity domain is required'
          "
        />
      </div>
      <hr class="m-0" />
      <div class="g-rows gap-1">
        <h2>Release Group</h2>
        <j-toggle-group>
          <j-radio
            v-for="option in groupOptions"
            :key="option"
            v-model="data.releaseGroup"
            :native-value="option"
            name="release-group"
          >
            {{ capitalize(option) }}
          </j-radio>
        </j-toggle-group>
      </div>

      <hr class="m-0" />
      <div class="g-rows gap-1">
        <h2>Account Type</h2>
        <p class="helper-text" v-if="mode.create">
          Select an account category for the tenant. Other should only be used
          for internal tenants.
          <strong>This cannot be modified after creation.</strong>
        </p>
        <j-select
          v-model="accountBusinessType"
          :options="accountBusinessTypeOptions"
          :searchable="false"
          data-feature-id="accountBusinessTypeOptions"
          :is-read-only="!mode.create"
        />
      </div>
      <template
        v-if="mode.create || (tenant.complianceReporterEnabled && !mode.create)"
      >
        <hr class="m-0" />
        <div class="compliance-reporter">
          <j-switch
            v-if="mode.create"
            v-model="data.complianceReporterEnabled"
          />
          <j-icon
            v-else-if="data.complianceReporterEnabled"
            data="@jcon/check-circle.svg"
            color="var(--color-success-base)"
            :width="24"
            :height="24"
          />
          <div>
            <h2>
              {{
                isFeatureEnabled('HC_MODE')
                  ? 'High compliance deployments'
                  : 'Compliance Reporter Only'
              }}
            </h2>
            <p class="helper-text mb-2">
              Tenant machines only communicate with internal servers.
              Cloud-based functionality
              {{ mode.create ? 'will not be' : 'is not' }}
              available.
              <strong v-if="mode.create"
                >This cannot be modified after creation.</strong
              >
            </p>
            <div v-if="data.complianceReporterEnabled" class="g-rows">
              <j-input
                label="Contact"
                v-model="data.complianceReporterEmailContact"
                :has-error="v$.data.complianceReporterEmailContact.$error"
                :error-text="
                  v$.data.complianceReporterEmailContact.required.$invalid
                    ? `Contact is required`
                    : `A valid email is required`
                "
                :is-disabled="!data.complianceReporterEnabled"
                placeholder="example@email.com"
              />
              <div class="g-cols fit">
                <j-input
                  label="License Count"
                  v-model="data.complianceReporterLicenseCount"
                  type="number"
                  :has-error="v$.data.complianceReporterLicenseCount.$error"
                  error-text="Count is required"
                  :is-disabled="!data.complianceReporterEnabled"
                  placeholder="0"
                />
                <j-input
                  label="Expiration Date"
                  v-model="data.complianceReporterExpirationDate"
                  :has-error="v$.data.complianceReporterExpirationDate.$error"
                  error-text="Date is required"
                  :is-disabled="!data.complianceReporterEnabled"
                  :placeholder="userDateFormat"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <hr class="m-0" />
      <div class="connection-rows">
        <h2>Connections</h2>
        <j-create-button
          @click="addConnection"
          icon="add2"
          label="Add Connection"
        />

        <p class="error-text" v-if="!v$.data.connections.required">
          Requires at least one Connection
        </p>
        <TenantConnectionCard
          v-for="(_item, index) in data.connections"
          :key="'connection-' + index"
          @remove="removeConnection(index)"
          :connections="data.connections"
          v-model:name="data.connections[index].name"
          v-model:connection="data.connections[index]"
          v-model:users="users"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from '@vuelidate/validators';
import { emailIf, isUniqueName } from '@/util/custom-validators';
import TenantConnectionCard from './TenantConnectionCard.vue';
import { capitalize } from '@/util';
import { mapGetters, mapState } from 'vuex';
import { useForm } from '@/composables/forms';
import { useGmt } from '@/composables/gmt';
import { useTenants } from '@/modules/administrative/admin/tabs/tenants/tenants.js';

export default {
  name: 'TenantForm',
  components: {
    TenantConnectionCard,
  },
  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { buildVanity, url, accountBusinessTypes } = useTenants();
    const { v$, mode } = useForm();
    const { formatEpochDate, getDate, convertToISODate, userDateFormat } =
      useGmt();
    return {
      v$,
      formatEpochDate,
      mode,
      getDate,
      convertToISODate,
      capitalize,
      buildVanity,
      url,
      accountBusinessTypes,
      userDateFormat,
    };
  },
  validations() {
    return {
      data: {
        name: {
          required,
        },
        hd: {
          required,
          isUniqueVanity: isUniqueName(this.tenant?.hd),
        },
        connections: {
          required,
        },
        complianceReporterExpirationDate: {
          required: requiredIf(this.data.complianceReporterEnabled),
        },
        complianceReporterEmailContact: {
          required: requiredIf(this.data.complianceReporterEnabled),
          email: emailIf(this.data.complianceReporterEnabled),
        },
        complianceReporterLicenseCount: {
          required: requiredIf(this.data.complianceReporterEnabled),
        },
      },
    };
  },
  data() {
    return {
      toggleName: 'data-active',
      message: null,
      primaryDomain: this.url,
      jamfIDKey: 'JamfID',
      users: [...this.tenant.jamfIdUsers],
      complianceReporterEnabled: this.tenant.complianceReporterEnabled,
      buttonOptions: [
        'google_button',
        'default_button',
        'okta_button',
        'azure_button',
      ],
      accountBusinessTypeOptions: Object.entries(this.accountBusinessTypes).map(
        ([value, label]) => ({ value, label })
      ),
      accountBusinessType: this.tenant.accountBusinessType || 'Other',
      groupOptions: ['PROD', 'BETA', 'ALPHA', 'DEV'],
      data: {
        ...this.tenant,
        connections: this.tenant?.connections.map(
          ({ name, button, requireKnownUsers }) => ({
            name,
            button,
            requireKnownUsers,
          })
        ),
      },
    };
  },
  watch: {
    'data.connections': {
      handler(newValue) {
        // select requireKnownUsers if JamfID
        const jamfIDindex = newValue.findIndex(
          ({ name }) => name === this.jamfIDKey
        );
        if (jamfIDindex !== -1) {
          // eslint-disable-next-line no-param-reassign
          newValue[jamfIDindex].requireKnownUsers = true;
          // eslint-disable-next-line no-param-reassign
          newValue[jamfIDindex].button = 'jamf_button';
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('admin', {
      duplicateNames: (state) => state.admin.vanityNames,
    }),
    domain() {
      const { hd } = this.data;
      if (hd.indexOf('.') === -1) {
        return `${hd}.protect`;
      }
      if (hd.endsWith('.')) {
        return `${hd}protect`;
      }
      return hd;
    },
    vanity() {
      return this.data.hd.split('.')[0].toLowerCase();
    },
    payload() {
      const hasJamfID = this.data.connections.filter(
        ({ name }) => name === this.jamfIDKey
      );
      const payload = {
        ...this.data,
        complianceReporterExpirationDate: this.data
          .complianceReporterExpirationDate
          ? this.convertToISODate(this.data.complianceReporterExpirationDate)
          : null,
        jamfIdUsers: hasJamfID.length ? this.users : [],
        accountBusinessType: this.accountBusinessType,
      };

      payload.connections.forEach((connection, index) => {
        payload.connections[index].name = connection.name.trim();
      });

      if (this.mode.create) {
        payload.hd = this.domain;
        payload.vanity = this.vanity;
      }
      return payload;
    },
  },
  beforeMount() {
    if (this.mode.edit) {
      this.data.complianceReporterExpirationDate = this.getDate(
        this.data.complianceReporterExpirationDate
      );
    }
  },
  methods: {
    addConnection() {
      this.data.connections.unshift({
        name: '',
        button: 'default_button',
        requireKnownUsers: false,
      });
    },
    removeConnection(index) {
      this.data.connections.splice(index, 1);
    },
    async submit() {
      const response = await this.$store.dispatch(
        `admin/${this.mode.create ? 'create' : 'update'}Organization`,
        this.payload
      );
      if (response) {
        this.data.complianceReporterExpirationDate = this.getDate(
          response.complianceReporterExpirationDate
        );

        this.data.uuid = response.uuid;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.connection-rows {
  @include grid;
}

.compliance-reporter {
  @include grid;
  @include grid-columns(min-content auto);
  align-items: flex-start;

  .fit {
    @include grid-columns(max-content auto);
    align-items: center;
  }
}

.error-text {
  @include helper-text;
  @include has-error;
}

.radio-group {
  display: flex;
}

.org-form {
  padding: spacing(2);

  h2 {
    @include header4;
  }

  .user-input {
    @include grid(0);
    @include grid-columns(max-content);
  }
}
</style>
