<template>
  <div class="j-table-header">
    <div
      v-if="params.enableMenu"
      ref="menuButton"
      class="menu"
      :class="{ active: filterActive }"
      @click="onMenuClicked($event)"
    >
      <j-icon v-if="filterActive" data="@jcon/funnel-active.svg" />
      <j-icon v-else data="@jcon/funnel.svg" />
    </div>
    <div
      :class="{
        label: true,
        sortable: params.enableSorting,
        'ag-header-cell-text': true,
        wrap,
      }"
      @click="onSortRequested($event)"
    >
      <span>{{ params.displayName }}</span>
      <j-icon
        v-if="params.enableSorting && sort"
        data="@jcon/arrow.svg"
        :class="{ sort: true, asc: sort === 'asc' }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'JTableHeader',
  props: ['params'],
  data() {
    return {
      sort: null,
      order: false,
      filterActive: false,
      wrap: false,
      sortingOrder: [],
    };
  },
  mounted() {
    this.sortingOrder = this.params.column.colDef.sortingOrder || [
      'asc',
      'desc',
    ];
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.params.column.addEventListener('filterChanged', this.onFilterChanged);
    this.onFilterChanged();
    this.onSortChanged();
  },
  beforeUnmount() {
    this.params.column.removeEventListener('sortChanged');
    this.params.column.removeEventListener('filterChanged');
  },
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSortChanged() {
      if (this.params.column.isSortAscending()) {
        this.sort = 'asc';
      } else if (this.params.column.isSortDescending()) {
        this.sort = 'desc';
      } else {
        this.sort = null;
      }
    },
    onFilterChanged() {
      this.filterActive = this.params.column.isFilterActive();
    },
    onSortRequested(event) {
      if (this.params.enableSorting) {
        const [order] = this.sortingOrder.filter((o) => o !== this.sort);
        this.params.setSort(order, event.shiftKey);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.j-table-header {
  @include grid(spacing(0.5));
  grid-template-areas: 'label button';
  width: 100%;
  height: 100%;
  grid-template-columns: auto min-content;
  align-items: center;

  .menu {
    grid-area: button;
    cursor: pointer;
    height: 16px;
    &.active,
    &:hover {
      color: var(--color-table-primary);
    }
  }

  .label {
    @include input-group-label;
    display: flex;
    align-items: center;
    grid-area: label;
    color: var(--color-table-header-foreground);
    height: 100%;
    margin: 0;
    white-space: normal;
  }

  .sort {
    @include transition(transform);
    margin-left: spacing();
    grid-area: sort;
    color: var(--color-table-primary);
    min-width: 16px;

    &.asc {
      transform: scaleY(-1);
    }
  }
}
</style>
