<template>
  <NoResultsOverlay
    message="No overrides have been entered. Try adding some to the list."
    :title="`No ${params.controlType()} Overrides`"
  />
</template>

<script>
import NoResultsOverlay from '@/components/NoResultsOverlay.vue';

export default {
  name: 'NoRowsAddUSBControl',
  components: { NoResultsOverlay },
};
</script>
