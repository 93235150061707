import {
  GET_APPLE_ANALYTICS,
  GET_APPLE_ROWS,
  GET_OVERVIEW_COUNTS,
  GET_APPLE_CONTEXT_VALUES,
  GET_COMPUTERS_STATS,
  GET_COMPUTERS_ALERTS_COUNTS,
} from './overviews.queries';

export default {
  actions: {
    // queries
    async getOverviewCount({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_OVERVIEW_COUNTS,
          variables: payload,
        },
        { root: true }
      );
      return (
        result?.data || {
          lateCount: null,
          issuesCount: null,
          allCount: null,
          alertCounts: null,
        }
      );
    },
    async getAppleRows({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_APPLE_ROWS,
        },
        { root: true }
      );
      return result?.data;
    },

    async getAppleAnalytics({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_APPLE_ANALYTICS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        /** Split _counts and _alerts into two objects that can be utilized */
        const all = Object.entries(result.data);
        const computerCount = all
          .filter(([key]) => key.includes('_counts'))
          .reduce((map, [key, value]) => {
            map[key.split('_')[0]] = value;
            return map;
          }, {});
        const detectionCount = all
          .filter(([key]) => key.includes('_alerts'))
          .reduce((map, [key, value]) => {
            map[key.split('_')[0]] = value;
            return map;
          }, {});
        return { computerCount, detectionCount };
      }
      return result;
    },

    async getAppleContextValues({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_APPLE_CONTEXT_VALUES,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        const all = Object.entries(result.data);
        const catalinaAlertsValues = all
          .filter(([key]) => key.includes('_tags'))
          .reduce((map, [key, value]) => {
            map[key.split('_')[0]] = value;
            return map;
          }, {});
        return { catalinaAlertsValues };
      }
      return result;
    },

    async getComputersStats({ dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_COMPUTERS_STATS,
          variables: payload,
        },
        { root: true }
      );
      return result.data;
    },
    async getComputersAlertsCounts({ dispatch }) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_COMPUTERS_ALERTS_COUNTS,
        },
        { root: true }
      );
      return result.data;
    },
  },
};
