import { computed, getCurrentInstance, watch, ref } from 'vue';
import { useStore } from 'vuex';
import columnTypes from './utils/column.types';
import {
  buildQueryFilter,
  buildQuerySort,
  parseQueryFilter,
  parseQuerySort,
} from './utils/query-build-and-parse';
import { joinByOperatorChar } from './utils/operators';
import { useRoute, useRouter } from 'vue-router';
import { useRBAC } from '@/composables/rbac';

export const sharedTableProps = {
  /** Array of defined columns, minimum: { headserName: 'Name', field: 'name' }
   * See column properties: https://www.ag-grid.com/javascript-grid-column-properties/
   */
  columns: {
    type: Array,
    required: true,
  },
  /** Set any available ag-grid options (https://www.ag-grid.com/javascript-grid/grid-properties/), will override any defaults */
  options: Object,
  /** Set default column properties to be applied to all columns */
  columnDefaults: Object,
  /** Set static row height */
  rowHeight: {
    type: Number,
    default: 60,
  },
  /** Hides Search Input for table */
  suppressSearch: Boolean,
  /** Hides refresh control */
  suppressRefreshButton: Boolean,
  /** Hides control bar completely */
  suppressControlBar: Boolean,
  /** Hides the filter bar */
  suppressFilterBar: Boolean,
  /** Disables table query params from being updated in the route */
  suppressQueryParams: Boolean,
  /** @row-click */
  onRowClick: Function,
};

export function useJamfTable(props, ctx) {
  const store = useStore();
  const $router = useRouter();
  const $route = useRoute();
  const currentInstance = getCurrentInstance()?.proxy;
  const { canAccess } = useRBAC();

  const state = {
    gridOptions: {
      rowSelection: 'multiple',
      enableCellTextSelection: true,
      defaultColDef: {
        headerComponent: 'TableHeader',
        resizable: true,
        minWidth: 160,
        lockVisible: true,
        tooltipComponent: 'TableTooltip',
        flex: 1,
        ...(props.columnDefaults || {}),
      },
      suppressScrollOnNewData: true,
      loadingOverlayComponent: 'LoadingOverlay',
      noRowsOverlayComponent: 'NoRowsOverlay',
      columnTypes,
      rowHeight: props.rowHeight,
      suppressRowClickSelection: true,
      onCellKeyDown: (params) => cellKeyDown(params),
      onSortChanged: (params) =>
        !props.suppressQueryParams && sortChanged(params),
      onFilterChanged: (params) =>
        !props.suppressQueryParams && filterChanged(params),
      ...(props.options || {}),
    },
    loading: ref(false),
    filterInput: ref(''),
  };

  const refreshKey = ref(0);
  const noRowsOverlayComponentParams = ref(null);
  const hasError = ref(false);
  const gridApi = ref(null);
  const columnApi = ref(null);
  const selected = ref([]);
  const selectedNodes = ref([]);

  const internalFilters = ref([]);
  const internalColumns = ref([...props.columns]);

  const hasFilters = computed(() => {
    return !props.suppressFilterBar && internalFilters.value?.length > 0;
  });
  const triggerTableCellRefresh = computed(
    () => store.state.app.triggerTableCellRefresh
  );
  const agGridClasses = computed(() => {
    return {
      'ag-theme-alpine-dark': true,
      'has-filters': hasFilters.value,
      'row-click-enabled': !!props.onRowClick,
    };
  });

  const setInternalColumns = () => {
    props.columns.forEach(() => {
      // check for custom permissions key on column and use canAccess to determine if a column should be rendered
      removeColumnKey('permissions', canAccess);
      // check for custom flag key on column and use isFeatureEnabled to determine if a column should be rendered
      removeColumnKey('flag', store.getters.isFeatureEnabled);
      removeColumnKey('flagOff', store.getters.isFeatureEnabled, true);
    });
  };

  const removeColumnKey = (key, checkWith, result = false) => {
    const index = internalColumns.value.findIndex((item) =>
      Object.prototype.hasOwnProperty.call(item, key)
    );
    if (index !== -1) {
      const actual = checkWith(internalColumns.value[index]?.[key]);

      if (actual === result) {
        // removes entire column
        internalColumns.value.splice(index, 1);
      } else {
        // removes key from column
        delete internalColumns.value[index]?.[key];
      }
    }
  };

  watch(
    () => props.columns,
    (columns) => {
      if (columns) {
        internalColumns.value = [...columns];
        setInternalColumns();
      }
    }
  );

  watch(triggerTableCellRefresh, (value) => {
    if (value) {
      gridApi.value?.refreshCells({ force: true });
      store.commit('TRIGGER_TABLE_CELL_REFRESH', false);
    }
  });

  const defaultMountSetup = () => {
    noRowsOverlayComponentParams.value = () => ({
      hasError: hasError.value,
      ...props.options?.noRowsOverlayComponentParams,
    });
  };
  const defaultGridReadySetup = (params) => {
    gridApi.value = params.api;
    columnApi.value = params.columnApi;
    gridApi.value?.addEventListener('selectionChanged', selectedRows);
  };
  const selectedRows = () => {
    selected.value = gridApi.value.getSelectedRows();
    selectedNodes.value = gridApi.value.getSelectedNodes();
    ctx.emit('selection-changed', {
      selected: selected.value,
      selectedNodes: selectedNodes.value,
    });
  };

  const sortChanged = async (event) => {
    const sorts = event?.columnApi
      .getColumnState()
      .filter(({ sort }) => sort !== null);

    if (sorts?.length > 0) {
      sorts.sort((a, b) => a.sortIndex - b.sortIndex);
      const all = buildQuerySort(sorts);
      try {
        const query = { ...$route?.query, ...all };
        await $router.replace({ query });
      } catch {
        // do nothing
      }
    }
    currentInstance?.reset?.();
  };
  const setInitialSortFromQuery = (params) => {
    if ($route.query?.sort) {
      const state = parseQuerySort($route.query);
      params.columnApi.applyColumnState({
        state,
        defaultState: { sort: null },
      });
    }
    sortChanged(params);
  };

  const rowClick = (params) => {
    if (props.onRowClick) {
      props.onRowClick(params);
    }
  };

  const cellKeyDown = (params) => {
    const {
      colDef: { onCellClicked },
      event,
    } = params;
    // if a cell has a click action be sure to do that on enter as well for accessability
    if (onCellClicked && event.key === 'Enter') {
      params.colDef.onCellClicked(params);
    }
    // if onRowClick is set be sure to do that on enter as well for accessability
    if (event.key === 'Enter') {
      rowClick(params);
    }
  };

  const setInternalFilters = (
    column,
    { filter, type, filterType },
    operator
  ) => {
    if (operator) {
      const i = internalFilters.value.findIndex(
        (internal) => internal.column === column
      );
      internalFilters.value[i].value += joinByOperatorChar(filter, operator);
      internalFilters.value[i].type += joinByOperatorChar(type, operator);
    } else {
      internalFilters.value.push({ column, value: filter, type, filterType });
    }
  };

  const setFilters = async () => {
    await gridApi.value.setFilterModel(parseQueryFilter($route.query));
    await gridApi.value.onFilterChanged();
  };

  const filterChanged = async (params) => {
    // reset so it filters everything and not just the page
    const filterModel = params.api.getFilterModel();
    currentInstance?.getFilters?.(filterModel);
    const filters = buildQueryFilter(internalFilters.value);
    store.commit('SET_CURRENT_FILTER', filters);

    try {
      const query = { ...$route.query, ...filters };
      await $router.replace({ query });
    } catch {
      // do nothing
    }
  };

  const clearFilter = (column) => {
    gridApi.value.destroyFilter(column);
    gridApi.value.onFilterChanged();
  };

  const clearAllFilters = () => {
    gridApi.value.setFilterModel(null);
    gridApi.value.onFilterChanged();
    internalFilters.value = [];
  };

  const forceRecompute = () => {
    refreshKey.value++;
  };

  return {
    state,
    agGridClasses,
    hasFilters,
    internalColumns,
    internalFilters,
    gridApi,
    columnApi,
    noRowsOverlayComponentParams,
    hasError,
    selected,
    selectedNodes,
    refreshKey,
    // methods
    clearAllFilters,
    clearFilter,
    setFilters,
    filterChanged,
    setInternalFilters,
    setInitialSortFromQuery,
    defaultMountSetup,
    defaultGridReadySetup,
    canAccess,
    forceRecompute,
    rowClick,
    // primarily exposing for testing
    triggerTableCellRefresh,
    sortChanged,
    cellKeyDown,
    setInternalColumns,
    removeColumnKey,
    selectedRows,
  };
}
