<template>
  <div>
    <template v-if="!isFeatureEnabled('NEW_LOOK')">
      <div :class="{ hide: !showLoader, 'loader-container': true }">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p>
          <slot></slot>
        </p>
      </div>
    </template>
    <template v-else>
      <j-spinner v-if="showLoader" class="loader-container" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loader',
  props: {
    showLoader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/loader.scss';

.loader-container {
  display: flex;
  align-items: center;

  &.hide {
    display: none;
  }
}

p {
  margin-left: 8px;
}
</style>
