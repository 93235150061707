<template>
  <Tabs :tabs="tabs">
    <router-view :is-admin="true" />
  </Tabs>
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'AdminSection',
  components: { Tabs },
  computed: {
    tabs() {
      return [
        {
          label: 'Tenants',
          route: {
            name: 'admin.tenants.index',
          },
          hideOnNestedRoutes: true,
          nestedRouteNames: [
            'admin.tenants.index.detail',
            'admin.tenants.index.edit',
            'admin.tenants.index.create',
            'admin.tenants.index.delete',
            'admin.tenants.index.bailout',
          ],
        },
        {
          label: 'Audit Logs',
          route: {
            name: 'admin.auditlogs.index',
          },
        },
        {
          label: 'Packages',
          route: {
            name: 'admin.packages.protect',
          },
          nestedRouteNames: [
            'admin.packages.compliance',
            'admin.packages.protect.item',
            'admin.packages.compliance.item',
          ],
        },
        {
          label: 'Analytics',
          route: {
            name: 'admin.analytics.index',
          },
          nestedRouteNames: [
            'admin.analytics.index.detail',
            'admin.analytics.index.create',
            'admin.analytics.index.edit',
            'admin.analytics.index.delete',
          ],
        },
        {
          label: 'Utilities',
          route: {
            name: 'admin.utilities.index.token',
          },
        },
      ];
    },
  },
};
</script>
