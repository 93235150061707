<template>
  <JamfTable
    v-if="!loading"
    :get-data="getData"
    suppress-filter-bar
    suppress-refresh-button
    suppress-search
    :columns="columns"
  >
    <template v-if="canWrite" #left>
      <j-create-button
        @click="$router.push({ name: 'account.roles.create' })"
        label="Create Role"
      />
    </template>
  </JamfTable>
</template>
<script>
import JamfTable from '@/components/table/JamfTable.vue';

import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useAccount } from './composable/account';

export default {
  name: 'AccountRoles',
  components: { JamfTable },
  setup() {
    const {
      associatedUsers,
      getUsers,
      canWrite,
      getGroups,
      associatedGroups,
      associatedConnections,
    } = useAccount(RBAC_RESOURCE.ROLE);

    return {
      associatedUsers,
      associatedGroups,
      associatedConnections,
      getUsers,
      getGroups,
      canWrite,
    };
  },
  data() {
    return {
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          sort: 'asc',
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'account.roles.detail',
              params: { id: params.data?.id },
            }),
          },
        },
        {
          headerName: 'Associated Groups',
          field: 'id',
          type: 'tagsColumn',
          colId: 'associatedGroups',
          valueFormatter: ({ value }) => this.associatedGroups(value),
          cellRendererParams: {
            route: ({ route }) => route,
          },
          permissions: [RBAC_RESOURCE.GROUP, RBAC_RESOURCE.CONNECTION],
        },
        {
          headerName: 'Associated Mappings',
          type: 'tagsColumn',
          field: 'id',
          cellRendererParams: {
            route: ({ route }) => route,
          },
          colId: 'associatedMappings',
          valueFormatter: ({ value }) => this.associatedConnections(value),
          permissions: [RBAC_RESOURCE.GROUP, RBAC_RESOURCE.CONNECTION],
        },
        {
          headerName: 'Associated Users',
          type: 'tagsColumn',
          field: 'id',
          colId: 'associatedUsers',
          valueFormatter: ({ value }) => this.associatedUsers(value),
          cellRendererParams: {
            route: ({ route }) => route,
          },
          permissions: [RBAC_RESOURCE.USER],
        },
        {
          headerName: 'Modified',
          field: 'updated',
          sortable: true,
          type: 'dateColumn',
        },
      ],
      loading: true,
    };
  },
  async beforeMount() {
    await this.getGroups();
    await this.getUsers();
    this.loading = false;
  },
  methods: {
    getData(params) {
      return this.$store.dispatch('primary/listRoles', params);
    },
  },
};
</script>
