<template>
  <div class="gp-content">
    <j-form-bar
      v-if="mode.create || telemetry"
      :title="mode.create ? 'Create Telemetry Configuration' : name"
      @back="routeTo()"
      @submit:created="routeTo('detail')"
      @cancel="routeTo('detail')"
      @edit="routeTo('edit')"
      @remove="confirmRemove(telemetry)"
      :can-write="canWrite"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" v-if="!loading" :telemetry="telemetry" />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      :blockers="selectedPlans"
      @confirm="remove()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { useRemoveHelpers } from '@/composables/remove-helpers';
import { useRouteHelpers } from '@/composables/route-helpers';

export default {
  name: 'Telemetry',
  setup() {
    const { showRemoveModal, toDelete, selectedPlans, confirmRemove } =
      useRemoveHelpers();
    const { mode } = useRouteHelpers();
    const { canWrite } = useRBAC(RBAC_RESOURCE.TELEMETRY);
    return {
      showRemoveModal,
      toDelete,
      selectedPlans,
      confirmRemove,
      mode,
      canWrite,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('primary', {
      telemetry: (state) => state.telemetry.telemetry,
    }),
    name() {
      return this.telemetry?.name || '';
    },
  },
  async beforeMount() {
    if (!this.mode.create) {
      this.loading = true;

      await this.$store.dispatch('primary/getTelemetry', {
        id: this.$route.params.id,
      });
    }
    this.loading = false;
  },
  methods: {
    routeTo(name) {
      const parent = 'telemetry.index';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: name ? { id: this.telemetry?.id } : {},
      });
    },
    async remove() {
      this.loading = true;
      this.showWarning = false;
      const result = await this.$store.dispatch('primary/deleteTelemetry', {
        id: this.telemetry.id,
      });
      if (result) {
        this.$router.push({ name: 'telemetry.index' });
      }
    },
  },
};
</script>
