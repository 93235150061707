import GPFSEvent from './events/FSEvent.json';
import GPProcessEvent from './events/ProcessEvent.json';
import GPClickEvent from './events/ClickEvent.json';
import GPUSBEvent from './events/USBEvent.json';
import GPDownloadEvent from './events/DownloadEvent.json';
import GPScreenshotEvent from './events/ScreenshotEvent.json';
import GPMRTEvent from './events/MRTEvent.json';
import GPGatekeeperEvent from './events/GatekeeperEvent.json';
import GPKeylogRegisterEvent from './events/KeylogRegisterEvent.json';
import GPThreatMatchExecEvent from './events/ThreatMatchExecEvent.json';
import GPPreventedExecutionEvent from './events/PreventedExecutionEvent.json';

import appextension from './types/appextension.json';
import bundle from './types/bundle.json';
import device from './types/device.json';
import file from './types/file.json';
import group from './types/group.json';
import infoDictionary from './types/infoDictionary.json';
import process from './types/process.json';
import signing from './types/signing.json';
import string from './types/string.json';
import user from './types/user.json';
import binary from './types/binary.json';

import analytic from './configs/analytic.json';
import plan from './configs/plan.json';

import endpoint from './endpoint.json';

import analyticActions from './analytic-actions.json';

import infoSync from './schemas/InfoSync.json';
import planSchema from './schemas/Plan.json';
import DataConfigSchema from './schemas/DataConfig.json';
import HTTPEndpointSchema from './schemas/HTTPEndpoint.json';

export default {
  /* eslint-disable no-param-reassign */
  getTypeDefinitions() {
    const defs = {
      events: {
        GPFSEvent,
        GPProcessEvent,
        GPClickEvent,
        GPScreenshotEvent,
        GPUSBEvent,
        GPDownloadEvent,
        GPMRTEvent,
        GPGatekeeperEvent,
        GPKeylogRegisterEvent,
        GPThreatMatchExecEvent,
        GPPreventedExecutionEvent,
      },
      types: {
        appextension,
        bundle,
        device,
        file,
        group,
        infoDictionary,
        process,
        signing,
        string,
        user,
        binary,
      },
      configs: {
        analytic,
        plan,
      },
      analyticActions,
      // This mapping is needed to map definitions to the configs found in action
      // e.g. action.remoteAlertConfig.data
      mappings: {
        fsEvent: GPFSEvent,
        procEvent: GPProcessEvent,
        screenshotEvent: GPScreenshotEvent,
        clickEvent: GPClickEvent,
        downloadEvent: GPDownloadEvent,
        usbEvent: GPUSBEvent,
        mrtEvent: GPMRTEvent,
        gkEvent: GPGatekeeperEvent,
        keylogRegisterEvent: GPKeylogRegisterEvent,
        file,
        process,
        user,
        group,
        binary,
      },
      endpoint,
      schemas: {
        infoSync,
        plan: planSchema,
        dataConfig: DataConfigSchema,
        httpEndpoint: HTTPEndpointSchema,
      },
    };
    return defs;
  },
};
