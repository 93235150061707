<template>
  <JamfTable
    :get-data="getData"
    :view="view"
    :columns="columns"
    :suppress-control-bar="view !== 'table'"
    :search-params="{ columnOptions }"
  >
    <template #left="{ refresh }">
      <j-toggle-group v-if="view === 'table'" label="View: ">
        <j-radio
          v-model="packageType"
          :native-value="true"
          @change="switchView(refresh)"
          :name="toggleName"
          >Protect</j-radio
        >
        <j-radio
          v-model="packageType"
          :native-value="false"
          @change="switchView(refresh)"
          :name="toggleName"
          >Compliance Reporter</j-radio
        >
      </j-toggle-group>
    </template>
    <template #default="{ refresh }">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component
            :is="Component"
            :item="currentItem"
            :is-compliance="isCompliance"
            :refresh-grid="refresh"
          />
        </transition>
      </router-view>
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';

export default {
  name: 'PackagesList',
  components: {
    JamfTable,
  },
  props: {
    isCompliance: Boolean,
  },
  data() {
    return {
      toggleName: 'package-type',
      endpoint: 'admin/listPackages',
      packageType: !this.isCompliance,
      currentItem: null,
      columnOptions: {
        recipientReleaseGroup: ['DEV', 'ALPHA', 'BETA', 'PROD'],
      },
      columns: [
        {
          headerName: 'Version',
          field: 'version',
          type: 'linkColumn',
          sortable: true,
          sort: 'desc',
          cellRendererParams: {
            route: (params) => ({
              name: `admin.packages.${
                this.packageType ? 'protect' : 'compliance'
              }.item`,
              params: { id: params.data?.version },
            }),
            action: (params) => {
              this.currentItem = { ...params.data };
            },
          },
        },
        {
          headerName: 'Assigned Release Group',
          field: 'releaseGroup',
          colId: 'recipientReleaseGroup',
          type: ['tagsColumn', 'valueOptionsFilterColumn'],
          filterParams: {
            defaultValue: 'DEV',
            valueOptions: ['DEV', 'ALPHA', 'BETA', 'PROD'],
            filterType: 'enum',
            suppressTypeOptions: true,
            defaultOption: 'equals',
            suppressAndOrCondition: true,
          },
        },
        {
          headerName: 'Package',
          field: 'package',
          flex: 2,
          valueGetter: (params) =>
            params.data ? `s3://${params.data.bucket}/${params.data.key}` : '',
        },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
        },
        {
          headerName: 'Updated',
          field: 'updated',
          type: 'dateColumn',
        },
      ],
    };
  },
  computed: {
    view() {
      return this.$route.params.id ? 'item' : 'table';
    },
  },
  beforeMount() {
    this.updateEndpoint();
  },
  methods: {
    updateEndpoint() {
      this.endpoint = this.packageType
        ? 'admin/listPackages'
        : 'admin/listComplianceReporterPackages';
    },
    switchView(refresh) {
      this.$router.replace({
        path: this.packageType ? 'protect' : 'compliance',
      });
      this.updateEndpoint();
      refresh();
    },
    async getData(params) {
      return this.$store.dispatch(this.endpoint, params);
    },
  },
};
</script>
