import { uuid } from '@/util';

export function useAnalytics() {
  const copyAnalytic = (analytic) => ({
    ...analytic,
  });
  const generateInternalAnalytic = (analytic, admin) => {
    const internalAnalytic = {
      ...analytic,
      context: [],
      snapshotFiles: [],
    };

    analytic.snapshotFiles.forEach((f) => {
      if (f.key) {
        internalAnalytic.snapshotFiles.push(f);
      } else {
        internalAnalytic.snapshotFiles.push({
          key: uuid(),
          value: f,
        });
      }
    });

    analytic.context.forEach(({ name, type, exprs }) => {
      internalAnalytic.context.push({
        key: uuid(),
        name,
        type,
        exprs,
      });
    });

    if (admin) {
      internalAnalytic.released = analytic.released;
    }
    return internalAnalytic;
  };

  return { copyAnalytic, generateInternalAnalytic, uuid };
}
