<template>
  <div v-if="value" class="container">
    <pre class="secondary code" ref="code"><code>{{ value }}</code></pre>
  </div>
</template>

<script>
export default {
  name: 'TableCellCode',
  computed: {
    value() {
      return this.params.value || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  max-height: unset;
  align-items: center;
}
.code {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  white-space: nowrap;
}
</style>
