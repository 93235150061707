<template>
  <Tabs :tabs="tabs" id="detections" />
</template>

<script>
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'OverviewSection',
  components: { Tabs },
  data() {
    return {
      tabs: [
        {
          label: 'Detections',
          route: {
            name: 'overview.detections',
          },
        },
        {
          label: 'Apple Security',
          route: {
            name: 'overview.apple',
          },
        },
        {
          label: 'Computers',
          route: {
            name: 'overview.computers',
          },
        },
      ],
    };
  },
};
</script>
