import { FILTER_TYPES } from '../../utils/filter.types';

export const TEXT_TYPE_OPTIONS = [
  { label: 'Starts With', value: FILTER_TYPES.STARTS_WITH },
  { label: 'Ends With', value: FILTER_TYPES.ENDS_WITH },
  { label: 'Contains', value: FILTER_TYPES.CONTAINS },
  { label: 'Equals', value: FILTER_TYPES.EQUALS },
  { label: 'Not Equal', value: FILTER_TYPES.NOT_EQUAL },
  { label: 'Less Than Or Equal', value: FILTER_TYPES.LESS_THAN_OR_EQUAL },
  { label: 'Less Than', value: FILTER_TYPES.LESS_THAN },
  { label: 'Greater Than Or Equal', value: FILTER_TYPES.GREATER_THAN_OR_EQUAL },
  { label: 'Greater Than', value: FILTER_TYPES.GREATER_THAN },
];

export const INT_TYPE_OPTIONS = [
  { label: 'Equals', value: FILTER_TYPES.EQUALS },
  { label: 'Not Equal', value: FILTER_TYPES.NOT_EQUAL },
  { label: 'Less Than Or Equal', value: FILTER_TYPES.LESS_THAN_OR_EQUAL },
  { label: 'Less Than', value: FILTER_TYPES.LESS_THAN },
  { label: 'Greater Than Or Equal', value: FILTER_TYPES.GREATER_THAN_OR_EQUAL },
  { label: 'Greater Than', value: FILTER_TYPES.GREATER_THAN },
];

export const JSON_TYPE_OPTIONS = [
  { label: 'Contains', value: FILTER_TYPES.JSON_CONTAINS },
  { label: 'Contains Key', value: FILTER_TYPES.JSON_CONTAINS_KEY },
  { label: 'Contains All Keys', value: FILTER_TYPES.JSON_CONTAINS_ALL_KEYS },
  { label: 'Contains Any Keys', value: FILTER_TYPES.JSON_CONTAINS_ANY_KEYS },
  { label: 'Contained By', value: FILTER_TYPES.JSON_CONTAINED_BY },
];

export const ARRAY_TYPE_OPTIONS = [
  { label: 'Includes', value: FILTER_TYPES.ARRAY_INCLUDES },
  { label: 'Any Not Equal', value: FILTER_TYPES.ARRAY_ANY_NOT_EQUAL },
  { label: 'Any Equal', value: FILTER_TYPES.ARRAY_ANY_EQUAL },
];

export const DATE_TYPE_OPTIONS = [
  { label: 'Equals', value: FILTER_TYPES.EQUALS },
  { label: 'Not Equal', value: FILTER_TYPES.NOT_EQUAL },
  { label: 'Less Than Or Equal', value: FILTER_TYPES.LESS_THAN_OR_EQUAL },
  { label: 'Less Than', value: FILTER_TYPES.LESS_THAN },
  { label: 'Greater Than Or Equal', value: FILTER_TYPES.GREATER_THAN_OR_EQUAL },
  { label: 'Greater Than', value: FILTER_TYPES.GREATER_THAN },
  { label: 'Before Interval', value: FILTER_TYPES.BEFORE_INTERVAL },
  { label: 'After Interval', value: FILTER_TYPES.AFTER_INTERVAL },
];

export const TEXT_TYPE_OPTIONS_SIMPLE = [
  { label: 'Equals', value: FILTER_TYPES.EQUALS },
  { label: 'Not Equal', value: FILTER_TYPES.NOT_EQUAL },
];
