<template>
  <div class="resource-info g-rows gap-4 pt-2">
    <div class="g-rows gap-1">
      <h2>All</h2>
      <p>Selecting all permissions will also include all future permissions.</p>
    </div>
    <div
      v-for="resource in internalResources"
      :key="resource.value"
      class="g-rows gap-1"
    >
      <h2>{{ resource.label }}</h2>
      <p>{{ descriptions[resource.value] }}</p>
    </div>
  </div>
</template>

<script>
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';
import { mapGetters } from 'vuex';

export default {
  name: 'RolesInformation',
  props: {
    resources: Array,
  },
  data() {
    return {
      internalResources: [...this.resources],
      descriptions: {
        [RBAC_RESOURCE.COMPUTER]: `
          Access to the Computers page and Dashboard and Computer Info tabs for each computer.
          To access additional computer data, the Alerts, Insights, and Plans permissions may also be required.
          To delete computers, write access to Alerts are also required.
        `,
        [RBAC_RESOURCE.ACTION_CONFIGS]: 'Access to the Actions page.',
        [RBAC_RESOURCE.PLAN]:
          'Access to the Plans page. To write plans, read permissions for Actions is also required.',
        [RBAC_RESOURCE.ANALYTIC_SET]:
          'Access to the Analytic Sets page and enabling features that depend on analytic sets. Requires Read permissions for Analytics.',
        [RBAC_RESOURCE.ANALYTIC]: 'Access to the Analytics page.',
        [RBAC_RESOURCE.ALERT]: 'Access to the Alerts page.',
        [RBAC_RESOURCE.INSIGHT]:
          'Access to the Insights page. To enable insights, write permissions for Plans is also required.',
        [RBAC_RESOURCE.PREVENT_LIST]:
          'Access to the Custom Prevent Lists tab on the Threat Prevention page.',
        [RBAC_RESOURCE.UNIFIED_LOGGING_FILTER]: `
          Access to the Unified Logging page.
          To read or write configured endpoints for Unified Logging, access to the Actions page is required.
        `,
        [RBAC_RESOURCE.DATA_RETENTION]:
          'Access to the Data Retention page. Requires read access to Account Information.',
        [RBAC_RESOURCE.DATA_FORWARD]:
          'Access to the Data Forwarding page. Requires read access to Account Information.',
        [RBAC_RESOURCE.API_CLIENT]: 'Access to the API Clients page.',
        [RBAC_RESOURCE.CONNECTION]:
          'Access to the Identity Providers tab on the Accounts page.',
        [RBAC_RESOURCE.USER]: 'Access to the Users tab on the Accounts page.',
        [RBAC_RESOURCE.GROUP]: `
          Access to the Groups tab and mappings on the Accounts page.
          To read or write mappings, the Account Identity Providers permission is also required.
        `,
        [RBAC_RESOURCE.ROLE]: `
          Access to the Roles tab on the Accounts page.
          To assign roles, write access to Account Groups & Mappings or Account Users is required.
        `,
        [RBAC_RESOURCE.EXCEPTION_SET]:
          'Access to the Exception Sets tab on the Plans page. Requires read access to Analytics.',

        [RBAC_RESOURCE.ORGANIZATION]:
          'Access to the Overview tab on the Accounts page. Required for read access for the Forwarding and Retention tabs on the Data page.',
        [RBAC_RESOURCE.THREAT_PREVENTION_VERSION]:
          'Access to the Overview tab on the Threat Prevention page.',
        [RBAC_RESOURCE.AUDIT_LOG]: 'Access to the Audit Logs page.',
        [RBAC_RESOURCE.DOWNLOAD]:
          'Access to available packages on the Downloads page.',
      },
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
  },
  beforeMount() {
    if (this.isFeatureEnabled('TELEMETRY_STREAM')) {
      this.descriptions[RBAC_RESOURCE.TELEMETRY] =
        'Access to the Telemetry page.';
    }
    if (this.isFeatureEnabled('FREEZE_CONTROL')) {
      this.descriptions[
        RBAC_RESOURCE.CONFIG_FREEZE
      ] = `Access to the Change Management tab on the Accounts page. When a change freeze is enabled, changes that affect 
      the agent configurations will be prohibited. Requires read access to Account Information.`;
    }
    if (this.isFeatureEnabled('USB_CONTROLS')) {
      this.descriptions[RBAC_RESOURCE.USB_CONTROLS] =
        'Access to the Device Controls page.';
    }

    if (this.isFeatureEnabled('NTP_R1')) {
      this.descriptions[RBAC_RESOURCE.THREAT_PREVENTION_VERSION] =
        'Access to the Endpoint Threat Prevention tab on the Threat Prevention page.';
    }

    if (this.isFeatureEnabled('COMPLIANCE_DASHBOARD')) {
      const indexOfInsights = this.internalResources.findIndex(
        ({ value }) => value === RBAC_RESOURCE.INSIGHT
      );
      this.internalResources[indexOfInsights].label = 'Compliance';
      this.descriptions[
        RBAC_RESOURCE.INSIGHT
      ] = `Access to the Compliance overview page, the Compliance Settings page, and the Compliance Rule detail page.
        To access additional compliance data, Computers and Plans permissions may also be required. To enable compliance rules, 
        write permissions for Plans is required.`;
    }
  },
};
</script>

<style lang="scss" scoped>
.resource-info {
  h2 {
    @include header5;
  }

  li {
    margin-left: spacing(2);
    list-style: disc;
  }

  p,
  li {
    @include paragraph;
  }
}
</style>
