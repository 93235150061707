import { FILTER_TYPES_CHARS } from './filter.types';

export const OPERATORS = Object.freeze({
  AND: 'and',
  OR: 'or',
});

export const OPERATOR_CHARS = Object.freeze({
  [OPERATORS.AND]: '+',
  [OPERATORS.OR]: '~',
});

export const joinByOperatorChar = (value, op) =>
  `${OPERATOR_CHARS[op]}${value}`;

export const buildReadableOpString = (value, typeChars) => {
  let val =
    typeChars && Object.keys(FILTER_TYPES_CHARS).includes(typeChars)
      ? `${FILTER_TYPES_CHARS[typeChars]}${value}`
      : value;

  Object.values(OPERATORS).forEach((key) => {
    if (value.includes(OPERATOR_CHARS[key])) {
      if (typeChars) {
        const types = typeChars.split(OPERATOR_CHARS[key]);
        const vals = val.split(OPERATOR_CHARS[key]);
        val = vals
          .map((t, index) => `${FILTER_TYPES_CHARS[types[index]]} ${t}`)
          .join(` ${key} `);
      } else {
        val = val.split(OPERATOR_CHARS[key]).join(` ${key} `);
      }
    }
  });
  return val;
};

export const buildFilterModelFromQueryOps = (type, value) => {
  let model = { filter: null, operators: new Set() };
  const opValues = Object.values(OPERATOR_CHARS);

  if (opValues.some((char) => value.includes(char))) {
    let currentValue = value;
    let currentType = type;
    let currentKey = null;
    Object.entries(OPERATOR_CHARS).forEach(([key, val]) => {
      if (currentValue.includes(val)) {
        const values = currentValue.split(val);
        const types = currentType.split(val);
        model[key] = [];
        model.operators.add(key);
        types.forEach((t, i) => {
          if (!opValues.some((char) => t.includes(char))) {
            if (!model.filter) {
              // always set the base filter with first operative
              model.filter = values[i];
              model.type = t;
            } else {
              // use previous key for first case in new split operative
              model[currentKey || key].push({ type: t, filter: values[i] });
              currentKey = null;
            }
          } else {
            // only iterate over values that include an operator
            currentValue = values[i];
            currentType = t;
            currentKey = key;
          }
        });
      }
    });
  } else {
    model = { type, filter: value };
  }
  return model;
};
