<template>
  <j-button style-type="ghost-danger" data-feature-id="remove">
    <template #leading>
      <j-icon data="@jcon/trash.svg" />
    </template>
  </j-button>
</template>

<script>
export default {
  name: 'RemoveButton',
  compatConfig: {
    MODE: 3,
  },
};
</script>
