<template>
  <div id="device-info" class="scroll-vertical" v-if="tenant">
    <div class="p-2 grid">
      <CardListItems title="Tenant Information" :values="baseValues">
        <hr />

        <div class="g-col-2">
          <ListItem title="Tenant access">
            <div class="g-cols max-c ai-center gap-1">
              <j-icon v-bind="access" />{{
                tenant.active ? 'Allowed' : 'Restricted'
              }}
            </div>
          </ListItem>
          <ListItem title="Release Group">
            <j-tag>{{ tenant.releaseGroup }}</j-tag>
          </ListItem>
          <ListItem
            v-for="{ label, value } in smallValuesList"
            :key="label"
            :title="label"
          >
            {{ value }}
          </ListItem>
        </div>
      </CardListItems>
      <div class="g-rows max-c">
        <TenantBailOut :tenant="tenant" />
        <CardListItems
          v-if="tenant.complianceReporterEnabled"
          helper-text="This tenant machines only communicate with internal servers.
              Cloud-based functionality is not available."
          class="h-max-c"
          :title="
            isFeatureEnabled('HC_MODE')
              ? 'High compliance deployments'
              : 'Compliance Reporter Only'
          "
          :values="complianceReporter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import CardListItems from '@/components/CardListItems.vue';
import TenantBailOut from './TenantBailOut.vue';
import { mapGetters, mapState } from 'vuex';
import ListItem from '@/components/ListItem.vue';
import { success, disabled } from '@/util/constants/feedback.icons';
import { useTenants } from '@/modules/administrative/admin/tabs/tenants/tenants.js';

export default {
  name: 'TenantDetails',
  components: { CardListItems, ListItem, TenantBailOut },
  setup() {
    const { formatEpochDate } = useGmt();
    const { buildVanity, transformAccountBusinessType } = useTenants();

    return {
      formatEpochDate,
      buildVanity,
      transformAccountBusinessType,
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('admin', { tenant: (state) => state.admin.tenant }),
    baseValues() {
      return [
        {
          label: 'Name',
          value: this.tenant.name,
        },
        {
          label: 'Description',
          value: this.tenant.description,
        },
        {
          label: 'Account Type',
          value: this.transformAccountBusinessType(
            this.tenant.accountBusinessType
          ),
        },
        {
          label: 'Vanity',
          value: this.buildVanity(this.tenant.vanity),
          externalLink: true,
        },
        {
          label: 'Domain',
          value: this.tenant.hd,
        },
        {
          label: 'Connections',
          value: `${this.tenant.connections
            .map(({ name }) => name)
            .join(', ')}`,
        },
        {
          label: 'Jamf ID Users',
          value: this.tenant.jamfIdUsers.join(', '),
        },
        {
          label: 'Database Migration',
          value: this.tenant.migration,
        },
        {
          label: 'CAID',
          value: this.tenant.caid,
        },
        {
          label: 'CSRID',
          value: this.tenant.csrid,
        },
      ];
    },
    smallValuesList() {
      return [
        {
          label: '# Computers',
          value: this.tenant.computerCount,
        },
        {
          label: '# Jamf ID Users',
          value: this.tenant.jamfIdUsers.length,
        },
        {
          label: 'Cluster Identifier',
          value: this.tenant.cluster_id,
        },
        {
          label: 'Created',
          value: this.formatEpochDate(this.tenant.created),
        },
        {
          label: 'Updated',
          value: this.formatEpochDate(this.tenant.updated),
        },
      ];
    },
    complianceReporter() {
      return [
        {
          label: 'Contact',
          value: this.tenant.complianceReporterEmailContact,
        },
        {
          label: 'Expiration Date',
          value: this.formatEpochDate(
            this.tenant.complianceReporterExpirationDate
          ),
        },
        {
          label: 'Licenses',
          value: this.tenant.complianceReporterLicenseCount,
        },

        {
          label: 'License Key',
          value: this.tenant.complianceReporterLicenseKey,
        },
      ];
    },
    access() {
      return this.tenant.active
        ? { data: success, color: 'var(--color-success-base)' }
        : { data: disabled, color: 'var(--color-danger-base)' };
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  @include grid;
  @include grid-columns(fit-content(50%));

  @include breakpoint(small down) {
    grid-auto-flow: row;
    grid-auto-columns: auto;
  }
}
</style>
