import {
  UPDATE_ORGANIZATION_RETENTION,
  UPDATE_ORGANIZATION_FORWARD,
} from './data.mutations';
import { GET_DATA_FORWARD, GET_DATA_RETENTION } from './data.queries';

export default {
  state: {
    forward: null,
    retention: null,
  },
  mutations: {
    SET_DATA_FORWARD(state, payload) {
      state.forward = payload.forward;
      state.organizationUuid = payload.uuid ?? '';
    },
    SET_DATA_RETENTION(state, payload) {
      state.retention = payload;
    },
  },
  actions: {
    async updateOrganizationRetention({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ORGANIZATION_RETENTION,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_DATA_RETENTION', result.data.updateOrganizationRetention);
        return result.data.updateOrganizationRetention;
      }
      return result;
    },
    async updateOrganizationForward({ dispatch, commit }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_ORGANIZATION_FORWARD,
          variables: payload,
          throw: true,
        },
        { root: true }
      );
      if (result) {
        commit('SET_ORGANIZATION', result.data.updateOrganizationForward);
        commit('SET_DATA_FORWARD', result.data.updateOrganizationForward);
        return result.data.updateOrganizationForward;
      }
      return result;
    },
    async getDataForward({ commit, dispatch, state }) {
      if (!state.settings) {
        const result = await dispatch(
          'doQuery',
          {
            query: GET_DATA_FORWARD,
          },
          { root: true }
        );
        if (result) {
          commit('SET_ORGANIZATION', result.data.getOrganization);
          commit('SET_DATA_FORWARD', result.data.getOrganization);
          return result.data.getOrganization;
        }
        return result;
      }
      return state.settings;
    },
    async getDataRetention({ commit, dispatch, state }) {
      if (!state.settings) {
        const result = await dispatch(
          'doQuery',
          {
            query: GET_DATA_RETENTION,
          },
          { root: true }
        );
        if (result) {
          commit('SET_DATA_RETENTION', result.data.getOrganization);
          return result.data.getOrganization;
        }
        return result;
      }
      return state.settings;
    },
  },
};
