<template>
  <div>
    <div class="action" v-for="{ name, label, parameters } in defs" :key="name">
      <j-checkbox v-model="checkboxGroup" :native-value="name">
        {{ label }}
      </j-checkbox>
      <div v-for="(param, index) in parameters" :key="index">
        <j-input
          v-if="param.type === 'string' && internalParameters[name]"
          v-model="internalParameters[name][param.name]"
          :label="param.label"
          :class="name"
          class="field"
          :helper-text="param.description"
          :is-disabled="!parameters || !checkboxGroup.includes(name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'ActionsSelection',
  inject: ['$typeDefs'],
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defs: this.$typeDefs.analyticActions,
      checkboxGroup: [],
      internalParameters: {},
      internalActions: [],
    };
  },
  watch: {
    checkboxGroup: {
      deep: true,
      handler() {
      /** Compare internalActions to the checkboxGroup to determine if an object
       * should be removed or added from the internalActions
       */
      const both = [
        this.internalActions.map(({ name }) => name),
        this.checkboxGroup,
      ];
      both.reduce(
        (internalActions, checkboxGroup) =>
          checkboxGroup.map((name) => {
            if (!internalActions.includes(name)) {
              !!this.internalParameters[name]
                ? this.internalActions.push({
                    name,
                    parameters: this.makeString(this.internalParameters[name]),
                  })
                : this.internalActions.push({
                    name,
                    parameters: this.makeString({}),
                  });
            }
          }) &&
          internalActions.map((name) => {
            if (!checkboxGroup.includes(name)) {
              this.internalActions = this.internalActions.filter((action) =>
                checkboxGroup.includes(action.name)
              );
            }
          })
      );
      this.$emit('update:actions', this.internalActions);
      },
    },
    internalParameters: {
      deep: true,
      handler() {
        this.internalActions = this.internalActions.map((action) =>
          !!this.internalParameters[action.name]
            ? {
                ...action,
                parameters: this.makeString(
                  this.internalParameters[action.name]
                ),
              }
            : action
        );
        this.$emit('update:actions', this.internalActions);
      },
    },
  },
  methods: {
    getParams() {
      /** Get available parameters from defs */
      let defParams = {};
      this.defs.forEach(({ name, parameters }) => {
        if (parameters) {
          parameters.forEach((param) => {
            defParams = { ...defParams, [name]: { [param.name]: '' } };
          });
        }
      });
      /** Get parameters from actions */
      let actionParams = {};
      this.internalActions.forEach(({ name, parameters }) => {
        let params = JSON.parse(parameters);
        actionParams = { ...actionParams, [name]: params };
      });
      return { ...defParams, ...actionParams };
    },
    makeString(value) {
      return typeof value === 'string' ? value : JSON.stringify(value);
    },
  },
  mounted() {
    if (this.actions) {
      this.internalActions = this.actions;
      this.checkboxGroup = this.actions.map(({ name }) => name);
    }
    this.internalParameters = this.getParams();
  },
};
</script>

<style lang="scss" scoped>
.action {
  margin-bottom: spacing(2);
}
.field {
  margin-top: spacing(1);
  margin-left: spacing(4);
}
</style>
