<template>
  <ListItem v-if="signingInfo" title="Signing Info">
    <div v-if="signingInfoStatus == 0" class="vertical">
      <span class="attr"
        >Signer Type:
        {{
          getEnumString(
            $typeDefs.types.signing.fields,
            'Signer Type',
            signingInfo?.signerType
          )
        }}
      </span>
      <span v-if="signingInfo?.teamid" class="attr"
        >Team ID: {{ signingInfo.teamid }}</span
      >
      <span v-if="signingInfo?.appid" class="attr"
        >App ID: {{ signingInfo?.appid }}</span
      >
      <span v-if="signingInfo?.entitlements.length > 0" class="attr"
        >Entitlements: {{ signingInfo?.entitlements.join(', ') }}
      </span>
      <span v-if="signingInfo?.authorities.length > 0" class="attr"
        >Authorities: {{ signingInfo?.authorities.join(' → ') }}
      </span>
    </div>
    <div v-else>
      <a
        :href="`https://www.osstatus.com/search/results?platform=all&framework=all&search=${signingInfoStatus}`"
        rel="noopener"
        target="_blank"
      >
        <span v-if="signingInfoStatusMessage"
          >Error: {{ signingInfoStatusMessage }} ({{ signingInfoStatus }})
        </span>
        <span v-else> Error {{ signingInfoStatus }} </span>
      </a>
    </div>
  </ListItem>
</template>

<script>
import ListItem from '@/components/ListItem.vue';
import { useAlerts } from '@/modules/alerts/alerts.js';

export default {
  name: 'AlertSigningInfo',
  inject: ['$typeDefs'],
  compatConfig: {
    MODE: 3,
  },
  components: {
    ListItem,
  },
  setup(props) {
    const { getEnumString } = useAlerts(props);

    return { getEnumString };
  },
  props: {
    signingInfo: {
      type: Object,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
  },
  computed: {
    signingInfoStatus() {
      return this.signingInfo?.status;
    },
    signingInfoStatusMessage() {
      return this.signingInfo?.statusMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.vertical {
  display: flex;
  flex-direction: column;
}

.attr {
  width: 100%;
}
</style>
