<template>
  <div
    class="page-container"
    :class="{ 'has-banner': configFreeze }"
    tabindex="-1"
  >
    <div class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <NavBar>
      <gp-loader id="main-loader" :show-loader="showLoader" />
    </NavBar>
    <j-notification v-if="configFreeze" class="banner"
      ><h1 class="h5">Access is Restricted</h1>
      <template v-if="freezeControlAdmin"
        >A change freeze has been enabled. While the freeze is active, changes
        that affect the agent configurations will be prohibited. To disable the
        freeze and resume access, go to
        <router-link :to="{ name: 'account.changeManagement' }"
          >Change Management.</router-link
        ></template
      >
      <template v-else
        >A change freeze has been enabled by the administrator. While the freeze
        is active, changes that affect the agent configurations will be
        prohibited.</template
      >
    </j-notification>
    <SideNav
      :org-name="tenant.name"
      :is-jamf-admin="isJamfAdmin"
      :dev-info="{ ...loginConfig, releaseGroup: tenant.releaseGroup }"
      :current-route="parent || name"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SideNav from '@/components/sidenav/SideNav.vue';
import NavBar from '@/components/NavBar.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'Main',
  components: {
    SideNav,
    NavBar,
  },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.CONFIG_FREEZE);
    return { canWrite };
  },
  computed: {
    ...mapState('route', ['name']),
    ...mapState('auth', ['loginConfig']),
    ...mapState({
      tenant: (state) => state.app.info,
      configFreeze: (state) => state.app.info.configFreeze,
    }),
    ...mapGetters(['showLoader']),
    ...mapGetters('auth', ['isJamfAdmin']),
    parent() {
      const parent = this.$route?.matched?.find(
        ({ meta: { parent: p } }) => !!p
      );
      return parent?.meta?.parent;
    },
    freezeControlAdmin() {
      return this.canWrite;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  @include transition(grid-template-rows);
  display: grid;
  grid-template-areas:
    'sidenav nav'
    'sidenav banner'
    'sidenav main';
  grid-template-rows:
    var(--size-top-nav-height) var(--size-global-banner-height, 0px)
    100%;
  grid-template-columns: min-content auto;
  overflow: hidden;
  position: relative;
  height: 100vh;

  [data-has-banner='true'] & .main-content {
    border-top: 1px solid var(--color-border-secondary);
  }

  @include breakpoint(small down) {
    // move side bar to top nav area
    grid-template-areas: 'sidenav' 'nav' 'banner' 'main';
    grid-template-columns: 100%;
    grid-template-rows:
      repeat(2, var(--size-top-nav-height)) var(
        --size-global-banner-height,
        0px
      )
      100%;
  }

  .sidenav {
    grid-area: sidenav;
  }
  nav {
    grid-area: nav;
  }
  .main-content {
    grid-area: main;
    overflow: hidden;
  }

  #main-loader {
    padding-top: 5px;
  }

  .banner {
    color-scheme: dark;
    scrollbar-color: var(--color-border-secondary) var(--color-structure-base); // scrollbar color for firefox
    grid-area: banner;
    margin: spacing(2);
    overflow: auto;
    align-items: flex-start;

    a {
      color: var(--color-primary-base);
    }
  }
}
</style>
