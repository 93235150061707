import ExceptionSets from './ExceptionSets.vue';
import ExceptionSetsForm from './ExceptionSetsForm.vue';
import Section from '@/components/Section.vue';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  path: 'exceptions',
  component: Section,
  name: 'exceptions',
  meta: {
    parent: 'plans',
    breadcrumb: {
      linkText: 'Exception Sets',
      linkName: 'exceptions.index',
    },
    permissions: [RBAC_RESOURCE.EXCEPTION_SET],
  },
  children: [
    {
      path: '',
      component: ExceptionSets,
      name: 'exceptions.index',
    },
    {
      path: 'create',
      component: ExceptionSetsForm,
      name: 'exceptions.index.create',
      meta: {
        breadcrumb: {
          linkText: 'Create Exception Set',
        },
        requireWrite: true,
      },
    },
    {
      path: ':uuid',
      component: Section,
      meta: {
        breadcrumb: {
          linkName: 'exceptions.index.detail',
          linkText: 'Exception Set',
        },
        mode: { edit: true },
      },
      children: [
        {
          path: '',
          name: 'exceptions.index.detail',
          component: ExceptionSetsForm,
        },
      ],
    },
  ],
};
