<template>
  <div class="redirect">
    <Loader />
  </div>
</template>

<script>
import Loader from '@/components/ui/Loader.vue';
import { mapState } from 'vuex';

export default {
  name: 'AuthRedirect',
  components: { Loader },
  computed: {
    ...mapState('auth', ['loginConfig']),
  },
  async mounted() {
    if (this.loginConfig.connector) {
      await this.$store.dispatch('auth/loginUser', {
        connector: this.loginConfig.connector,
      });
    } else if (this.loginConfig.connections) {
      const connections = this.loginConfig.connections.filter(
        ({ name }) => name !== 'email' && name !== 'JamfID'
      );
      if (connections.length === 1) {
        await this.$store.dispatch('auth/loginUser', {
          connector: connections[0].name,
        });
      } else {
        this.$router.push({ name: 'login' });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.redirect {
  height: 100vh;
  width: 100%;
  padding: spacing();
  background: var(--color-initialize-base);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
