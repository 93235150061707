import {
  CREATE_USB_CONTROL_SET,
  DELETE_USB_CONTROL_SET,
  UPDATE_USB_CONTROL_SET,
} from './usb-controls.mutations';
import {
  GET_USB_CONTROL_SET,
  LIST_USB_CONTROL_SETS,
} from './usb-controls.queries';

export default {
  state: {
    usbcontrol: null,
    usbcontrols: [],
    usbcontrolsNames: [],
  },
  mutations: {
    SET_USB_CONTROL(state, payload) {
      state.usbcontrol = payload;
    },
    SET_USB_CONTROLS(state, payload) {
      state.usbcontrols = payload.items;
      state.usbcontrolsNames = payload.items?.map(({ name }) => name);
    },
  },
  actions: {
    // mutations
    async createUSBControlSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: CREATE_USB_CONTROL_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USB_CONTROL', result.data.createUSBControlSet);
        return result.data.createUSBControlSet;
      }
      return result;
    },
    async deleteUSBControlSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: DELETE_USB_CONTROL_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USB_CONTROL', result.data.deleteUSBControlSet);
        return result.data.deleteUSBControlSet;
      }
      return result;
    },
    async updateUSBControlSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doMutation',
        {
          query: UPDATE_USB_CONTROL_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USB_CONTROL', result.data.updateUSBControlSet);
        return result.data.updateUSBControlSet;
      }
      return result;
    },
    // queries
    async getUSBControlSet({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: GET_USB_CONTROL_SET,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USB_CONTROL', result.data.getUSBControlSet);
        return result.data.getUSBControlSet;
      }
      return result;
    },
    async listUSBControlSets({ commit, dispatch }, payload) {
      const result = await dispatch(
        'doQuery',
        {
          query: LIST_USB_CONTROL_SETS,
          variables: payload,
        },
        { root: true }
      );
      if (result) {
        commit('SET_USB_CONTROLS', result.data.listUSBControlSets);
        return result.data.listUSBControlSets;
      }
      return result;
    },
  },
};
