import VueLogger from 'vuejs3-logger';

export const options = {
  isEnabled: true,
  logLevel: process.env.VUE_APP_LOG_LEVEL,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

function createLogger(options) {
  return {
    install(app, installOptions = {}) {
      options = { ...options, ...installOptions };
      app.use(VueLogger, options);
      Object.assign(this, app.$log);
    },
  };
}

const logger = createLogger(options);

export default logger;
