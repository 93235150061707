<template>
  <JamfTable
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
  >
    <template #left>
      <h1 class="h4">Removable Storage Control</h1>
      <span class="divider is-small"></span>
      <j-create-button
        v-if="canWrite"
        @click="$router.push({ name: 'devicecontrols.index.create' })"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { associatedPlansColumn } from '@/components/table/utils/column.types';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'USBControls',
  components: { JamfTable },
  setup() {
    const { canWrite } = useRBAC([RBAC_RESOURCE.USB_CONTROLS]);
    return { canWrite };
  },
  data() {
    return {
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 200,
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'devicecontrols.index.detail',
              params: { id: params?.data?.id },
            }),
          },
        },
        {
          headerName: 'Description',
          field: 'description',
        },
        { ...associatedPlansColumn },
        {
          headerName: 'Modified',
          field: 'created',
          colId: 'updated',
          type: 'dateColumn',
          sortable: true,
        },
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sort: 'asc',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getData(params) {
      return this.$store.dispatch('primary/listUSBControlSets', params);
    },
  },
};
</script>
