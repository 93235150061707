<template>
  <div class="gp-content" v-if="!loading">
    <j-form-bar
      v-if="mode.create || role"
      :title="mode.create ? 'Create Role' : name"
      @back="routeTo()"
      @submit:created="routeTo('detail', role.id)"
      @submit="handleSubmit"
      @remove="confirmRemove(role)"
      :can-write="hasWriteAccess"
    />
    <SplitPanel>
      <template #default="{ toggle, showPanel }">
        <div class="pt-2 g-rows">
          <j-input
            label="Name"
            :has-error="v$.internalRole.name.$error"
            :error-text="nameErrorMessage"
            :is-read-only="!hasWriteAccess"
            v-model="internalRole.name"
            data-feature-id="name"
          />
          <Card class="g-rows">
            <div class="g-cols">
              <h1 class="h3">Permissions</h1>
              <HelpButton
                @click="toggle()"
                :toggle-message="showPanel"
                class="js-end"
              />
            </div>
            <ul class="resource-list g-rows">
              <li class="header">
                <p></p>
                <p>Write</p>
                <p>Read</p>
              </li>
              <li class="underline">
                <h3 class="label">All</h3>
                <j-checkbox
                  v-model="selectAll.W"
                  data-feature-id="W-all"
                  @change="selectAllResources('W')"
                  :is-read-only="!hasWriteAccess"
                />
                <j-checkbox
                  v-model="selectAll.R"
                  data-feature-id="R-all"
                  @change="selectAllResources('R')"
                  :is-read-only="!hasWriteAccess"
                />
              </li>
              <li v-for="resource in labeledResources" :key="resource.value">
                <p class="label">{{ resource.label }}</p>
                <j-checkbox
                  v-if="!noWriteAccess.includes(resource.value)"
                  v-model="resources.W"
                  @change="selectRead(resource)"
                  :data-feature-id="`W-${resource.value}`"
                  :is-read-only="!hasWriteAccess"
                  :native-value="resource.value"
                />
                <j-checkbox
                  v-model="resources.R"
                  :is-read-only="!hasWriteAccess"
                  @change="checkForRequired(resource)"
                  :data-feature-id="`R-${resource.value}`"
                  :native-value="resource.value"
                  :is-disabled="setReadDisabledState(resource)"
                />
              </li>
            </ul>
          </Card>
        </div>
      </template>
      <template #right>
        <RolesInformation :resources="labeledResources" />
      </template>
    </SplitPanel>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="name"
      :blockers="isAssigned"
      blocker-kind="items:"
      @confirm="remove"
    />
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import { isUniqueName } from '@/util/custom-validators';
import SplitPanel from '@/components/SplitPanel.vue';
import HelpButton from '@/components/HelpButton.vue';
import RolesInformation from './components/RolesInformation.vue';
import { mapGetters, mapState } from 'vuex';
import {
  RBAC_RESOURCE,
  RBAC_RESOURCE_LABELS,
} from '@/store/modules/rbac.resource';
import { required } from '@vuelidate/validators';
import { useAccount } from './composable/account';
import { useRemoveHelpers } from '@/composables/remove-helpers';

export default {
  name: 'AccountRole',
  components: { Card, SplitPanel, HelpButton, RolesInformation },
  setup() {
    const {
      mode,
      getUsers,
      getGroups,
      getConnections,
      associatedUsers,
      associatedConnections,
      associatedGroups,
      handleSubmit,
      isReadOnly,
      canWrite,
      v$,
    } = useAccount(RBAC_RESOURCE.ROLE);
    const { confirmRemove, showRemoveModal, toDelete } = useRemoveHelpers();
    return {
      mode,
      getUsers,
      getGroups,
      getConnections,
      associatedUsers,
      associatedConnections,
      associatedGroups,
      v$,
      handleSubmit,
      isReadOnly,
      canWrite,
      confirmRemove,
      showRemoveModal,
      toDelete,
    };
  },
  data() {
    const noWriteAccess = [
      RBAC_RESOURCE.THREAT_PREVENTION_VERSION,
      RBAC_RESOURCE.ORGANIZATION,
      RBAC_RESOURCE.AUDIT_LOG,
    ];

    const all = Object.entries(RBAC_RESOURCE_LABELS).map(([value, label]) => ({
      label: typeof label === 'string' ? label : label.label,
      value,
      dependencies: label?.dependencies,
      requires: label?.requires,
    }));
    const allResources = Object.keys(RBAC_RESOURCE_LABELS);
    const allWriteResources = allResources.filter(
      (value) => !noWriteAccess.includes(value)
    );
    return {
      loading: true,
      internalRole: {
        name: '',
      },
      endpointCreate: 'primary/createRole',
      endpointUpdate: 'primary/updateRole',
      deprecatedPermissions: ['Log'],
      allResources,
      allWriteResources,
      noWriteAccess,
      selectAll: {
        R: false,
        W: false,
      },
      resources: {
        R: allResources,
        W: allWriteResources,
      },
      labeledResources: all,
    };
  },
  validations() {
    return {
      internalRole: {
        name: {
          required,
          isUniqueName: isUniqueName(this.currentName),
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isFeatureEnabled']),
    ...mapState('primary', {
      role: (state) => state.account.role,
      // this is used in isUniqueName
      duplicateNames: (state) => state.account.rolesNames,
      currentName: (state) => state.account.role?.name,
    }),
    nameErrorMessage() {
      return this.v$.internalRole.name.$errors[0]?.$message;
    },
    onRemove() {
      return this.isDefaultRole
        ? {}
        : { onRemove: () => this.confirmRemove(this.role) };
    },
    name() {
      return this.role?.name || '';
    },
    hasWriteAccess() {
      return !this.isDefaultRole && this.canWrite;
    },
    isDefaultRole() {
      return ['Full Admin', 'Read Only'].includes(this.name);
    },
    isAssigned() {
      return [
        ...this.associatedConnections(this.role?.id),
        ...this.associatedGroups(this.role?.id),
        ...this.associatedUsers(this.role?.id),
      ];
    },
    payload() {
      const { R, W } = this.resources;

      const base = {
        readResources: this.selectAll.R
          ? [RBAC_RESOURCE.ALL]
          : this.addRelatedPermissions([...R]),
        writeResources: this.selectAll.W
          ? [RBAC_RESOURCE.ALL]
          : this.addRelatedPermissions([...W]),
        name: this.internalRole.name,
      };
      return this.mode.create && !this.role?.id
        ? base
        : { ...base, id: this.role.id };
    },
  },
  watch: {
    'resources.R': {
      handler(val) {
        this.checkForSelectAll(val, 'R');
      },
      deep: true,
    },
    'resources.W': {
      handler(val) {
        this.checkForSelectAll(val, 'W');
      },
      deep: true,
    },
  },
  async beforeMount() {
    await this.getUsers();
    await this.getGroups();
  },
  async mounted() {
    const types = Object.keys(this.resources);

    if (!this.mode.create) {
      this.loading = true;

      await this.$store.dispatch('primary/getRole', {
        id: this.$route.params.id,
      });
      this.internalRole.name = this.name;
      types.forEach((type) => {
        this.resources[type] =
          this.role.permissions[type]?.filter(
            (permission) => !this.deprecatedPermissions.includes(permission)
          ) || [];
        if (this.resources[type]?.includes(RBAC_RESOURCE.ALL)) {
          this.selectAll[type] = true;
          this.resources[type] =
            type === 'W' ? [...this.allWriteResources] : [...this.allResources];
        }
      });
    } else {
      types.forEach((type) => {
        this.selectAll[type] = true;
        this.resources[type] =
          type === 'W' ? [...this.allWriteResources] : [...this.allResources];
      });
    }
    this.loading = false;
  },
  methods: {
    routeTo(name, id) {
      const parent = 'account.roles';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: id ? { id } : {},
      });
    },
    checkForRequired(resource) {
      if (
        resource.requires &&
        (this.resources.R.includes(resource.value) ||
          this.resources.W.includes(resource.value)) &&
        !this.resources.R.includes(resource.requires)
      ) {
        this.resources.R.push(resource.requires);
      }
    },
    setReadDisabledState(resource) {
      if (resource.dependencies) {
        return (
          this.resources.W.includes(resource.value) ||
          this.resources.R.some((r) => resource.dependencies.includes(r))
        );
      }
      return this.resources.W.includes(resource.value);
    },
    addRelatedPermissions(resource) {
      const r = new Set([...resource]);
      if (r.has(RBAC_RESOURCE.EXCEPTION_SET)) {
        r.add(RBAC_RESOURCE.EXCEPTION);
      }
      if (
        r.has(RBAC_RESOURCE.DOWNLOAD) &&
        this.isFeatureEnabled('UNINSTALLER_TOKEN')
      ) {
        r.add(RBAC_RESOURCE.UNINSTALLER_TOKEN);
      }
      return [...r];
    },
    checkForSelectAll(val, type) {
      const resources =
        type === 'W' ? this.allWriteResources : this.allResources;
      if (val.length !== resources.length) {
        this.selectAll[type] = false;
      }
    },
    selectRead(resource) {
      if (!this.resources.R.includes(resource.value)) {
        this.resources.R.push(resource.value);
      }
      this.checkForRequired(resource);
    },
    selectAllResources(type) {
      if (type === 'R') {
        // if read only select/deselect options that are also in write
        this.resources.R = this.selectAll.R
          ? [...this.allResources]
          : this.allResources.filter((resource) =>
              this.resources.W.includes(resource)
            );
      } else if (this.selectAll[type]) {
        this.resources[type] = [...this.allWriteResources];
        // ensure all read are selected along with all write
        this.resources.R = [...this.allResources];
      } else {
        this.resources[type] = [];
      }
    },
    async remove() {
      const result = await this.$store.dispatch('primary/deleteRole', {
        id: this.role.id,
      });
      if (result) {
        this.routeTo();
      }
    },
    async submit() {
      await this.$store.dispatch(
        this.mode.create ? this.endpointCreate : this.endpointUpdate,
        this.payload
      );
      this.internalRole.id = this.role.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-list {
  @include grid(0);

  li {
    --color-input-base: var(--color-card-primary);
    @include grid();
    @include grid-columns(2fr 0.5fr 0.5fr);
    width: 100%;
    padding: spacing() spacing(2);
    align-items: center;

    > *:not(.label) {
      justify-self: flex-end;
    }

    h3 {
      @include header4;
    }

    &.underline {
      border-bottom: 1px solid var(--color-border-base);
    }

    &:nth-child(even):not(.underline) {
      --color-input-base: var(--color-structure-tertiary);
      background: var(--color-structure-tertiary);
    }
  }

  .header {
    color: var(--color-font-secondary);
  }
}
</style>
