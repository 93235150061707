// Need to disable eslint for pendo script
/* eslint-disable */

/**
 * @description builds a pendo snippet with initialized parameters.
 * @param {string} uuid pass in the uuid of the organization
 * @param {string} name pass in the name of the organization
 * @param {string} sub pass in the sub of a unique identity
 */
export const addPendoSnippet = ({ uuid, name, releaseGroup, complianceReporterEnabled, flags }, { sub: id }) => {
  (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
      v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://content.protectguides.jamfcloud.com/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
          pendo.initialize({
            visitor: {
              id,
              flags: Object.entries(flags).filter(([, value]) => typeof value === 'boolean').map(([key, value]) => `${key}:${value}`),
            },
            account: {
              id: uuid,
              name,
              releaseGroup,
              isHighCompliance: complianceReporterEnabled,
            },
        });
    })('f6ce6b1f-4f55-4b54-6782-88079f77c3ac');
};

export default addPendoSnippet;
