<template>
  <div class="gp-content">
    <slot></slot>
    <Tabs v-if="loaded && !isManaged" :tabs="tabs" @tab-changed="tabChange">
      <JamfTableClientSide
        v-if="data"
        :options="gridOptions"
        :data="analyticsData"
        :columns="columns"
        :column-defaults="columnDefaults"
        @grid-ready="onGridReady"
        @refresh="refresh"
        @selection-changed="getSelected"
        :suppress-refresh-button="includeSelection"
      >
        <template #right="{ columnApi }">
          Group by:
          <j-dropdown :options="groups">
            <template #target="{ styleIndicator }">
              <j-button
                :class="styleIndicator"
                style-type="secondary"
                data-feature-id="groups-dropdown"
              >
                <template #trailing>
                  <j-icon data="@jcon/chevron-small.svg" />
                </template>
                {{ groupsByLabel }}
              </j-button>
            </template>
            <template #item="{ item }">
              <div
                role="button"
                class="groups-list p-1"
                @mouseenter="item.active = true"
                @mouseout="item.active = false"
                @click="switchGroup(columnApi, item.field, $event)"
              >
                {{ item.label }}
                <j-icon
                  v-if="groupBy === item.field"
                  class="icon"
                  width="12"
                  data="@jcon/check-bold.svg"
                />
              </div>
            </template>
          </j-dropdown>
        </template>
      </JamfTableClientSide>
    </Tabs>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ModuleRegistry } from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import JamfTableClientSide from '@/components/table/JamfTableClientSide.vue';
import Tabs from '@/components/Tabs.vue';
import AnalyticTableGroupCell from '@/modules/analytics/components/AnalyticTableGroupCell.vue';

import { SEVERITY, SEVERITY_LEVEL } from '@/util/constants/severity.types';
import { splitCamelCase } from '@/util';

ModuleRegistry.registerModules([RowGroupingModule]);

export default {
  name: 'AnalyticsList',
  compatConfig: {
    MODE: 3,
  },
  props: {
    includeSelection: Boolean,
    isAdmin: Boolean,
    isReadOnly: Boolean,
    isManaged: Boolean,
    modelValue: Array,
  },
  components: {
    JamfTableClientSide,
    AnalyticTableGroupCell,
    Tabs,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      data: null,
      tags: [],
      categories: [],
      selected: [],
      isJamfTab: true,
      jamfAnalytics: [],
      customAnalytics: [],
      endpoint: this.isAdmin ? 'admin/listAnalytics' : 'primary/listAnalytics',
      groupBy: 'categories',
      groups: [
        { label: 'None', active: false, field: 'none' },
        { label: 'Category', active: false, field: 'categories' },
        { label: 'Smart Group', active: false, field: 'tenantActions' },
        { label: 'Severity', active: false, field: 'severity' },
        { label: 'Tags', active: false, field: 'tags' },
      ],
      columnDefaults: {},
      tabsUpdated: false,
      gridOptions: {
        groupDisplayType: 'groupRows',
        groupSelectsChildren: true,
        groupSelectsFiltered: true,
        suppressAggFuncInHeader: true,
        animateRows: true,
        getRowClass: () => this.readOnlyCellClass,
        onViewportChanged: (params) => {
          // only update selection if tabs view changed
          if (this.tabsUpdated) {
            this.setSelected(params);
            this.tabsUpdated = false;
          }
        },
        groupRowRendererParams: {
          suppressCount: true,
          checkbox: this.includeSelection,
          innerRenderer: 'AnalyticTableGroupCell',
        },
      },
      columns: null,
      loaded: false,
    };
  },
  computed: {
    readOnlyCellClass() {
      return this.isManaged || this.isReadOnly ? 'is-read-only' : '';
    },
    tabs() {
      const jamf = 'Jamf-managed';
      const custom = 'Custom';

      // if selectable, include selected count in label
      const JamfLabel = this.includeSelection
        ? `${jamf} (${this.jamfAnalytics.length})`
        : jamf;
      const CustomLabel = this.includeSelection
        ? `${custom} (${this.customAnalytics.length})`
        : custom;

      return this.isManaged || this.isAdmin
        ? [{ label: jamf, key: 'jamf' }]
        : [
            { label: JamfLabel, key: 'jamf' },
            { label: CustomLabel, key: 'custom' },
          ];
    },
    groupsByLabel() {
      const group = this.groups.find(({ field }) => field === this.groupBy);
      return group?.label || this.groupBy;
    },
    allSelectedAnalytics: {
      get() {
        return this.selected;
      },
      set(value) {
        this.selected = value;
        this.$emit('update:modelValue', value);
      },
    },
    analyticsData() {
      return this.data?.filter(({ jamf: owner }) =>
        this.isJamfTab ? owner === true : owner === false
      );
    },
  },
  async mounted() {
    await this.setupFilterOptions();

    this.columnDefaults = {
      showDisabledCheckboxes: this.includeSelection,
    };

    this.columns = [
      { type: 'groupControlColumn' },
      {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        minWidth: 200,
        valueFormatter: (params) => splitCamelCase(params.value),
        sortable: true,
        type: ['linkColumn'],
        cellRendererParams: {
          route: (params) => ({
            name: this.isAdmin
              ? 'admin.analytics.index.detail'
              : 'analytics.index.detail',
            params: { id: params.data?.uuid },
          }),
        },
      },
      {
        field: 'description',
      },
      {
        type: 'severityColumn',
        enableRowGroup: true,
        showRowGroup: true,
        sort: 'desc',
        keyCreator: this.getSeverity,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          const a = nodeA.data?.tenantSeverity || valueA;
          const b = nodeB.data?.tenantSeverity || valueB;
          return SEVERITY_LEVEL[a] - SEVERITY_LEVEL[b];
        },
        getQuickFilterText: this.getSeverity,
        valueFormatter: this.getSeverity,
        valueGetter: this.getSeverity,
      },
      {
        headerName: 'Tags',
        field: 'tags',
        enableRowGroup: true,
        hide: this.includeSelection,
        showRowGroup: true,
        valueGetter: ({ data }) => (data?.tags?.length > 0 ? data?.tags : []),
        type: ['tagsColumn', 'valueOptionsFilterColumn'],
        filterParams: {
          filterType: 'array',
          valueOptions: this.tags,
          isSearchable: true,
        },
        wrapText: true,
        minWidth: 300,
        keyCreator: (params) => params.value.join(' & ') || 'None',
        autoHeight: true,
        getQuickFilterText: this.arrayFilterText,
      },
      {
        headerName: 'Smart Group',
        field: 'tenantActions',
        wrapText: true,
        autoHeight: true,
        enableRowGroup: true,
        sortable: true,
        showRowGroup: true,
        valueGetter: (params) => {
          const action = params.data?.tenantActions?.find(({ name }) =>
            name.match('SmartGroup')
          );
          return action?.name ? `${JSON.parse(action.parameters).id}` : '';
        },
        getQuickFilterText: this.arrayFilterText,
      },
      {
        headerName: 'Category',
        field: 'categories',
        wrapText: true,
        enableRowGroup: true,
        showRowGroup: true,
        sortable: true,
        autoHeight: true,
        rowGroup: true,
        type: ['tagsColumn', 'valueOptionsFilterColumn'],
        filterParams: {
          filterType: 'array',
          valueOptions: this.categories,
          isSearchable: true,
        },
        keyCreator: (params) => params.value.join(' & ') || 'None',
        valueGetter: ({ data }) =>
          data?.categories?.length > 0 ? data?.categories : [],
        getQuickFilterText: this.arrayFilterText,
      },
      {
        headerName: 'Created',
        field: 'created',
        type: 'dateColumn',
        sortable: true,
      },
      {
        headerName: 'Modified',
        field: 'updated',
        type: 'dateColumn',
        sortable: true,
      },
      {
        field: 'severity',
        colId: 'high',
        hide: true,
        aggFunc: 'sum',
        valueGetter: (params) => this.severityNum(params, SEVERITY.High),
      },
      {
        field: 'severity',
        colId: 'medium',
        hide: true,
        aggFunc: 'sum',
        valueGetter: (params) => this.severityNum(params, SEVERITY.Medium),
      },
      {
        field: 'severity',
        colId: 'low',
        hide: true,
        aggFunc: 'sum',
        valueGetter: (params) => this.severityNum(params, SEVERITY.Low),
      },
      {
        field: 'severity',
        colId: 'informational',
        hide: true,
        aggFunc: 'sum',
        valueGetter: (params) =>
          this.severityNum(params, SEVERITY.Informational),
      },
    ];

    if (this.includeSelection) {
      this.columns.splice(1, 0, {
        type: 'selectColumn',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        showDisabledCheckboxes: true,
        headerClass: () => this.readOnlyCellClass,
        cellClass: () => this.readOnlyCellClass,
      });
      this.selected = this.modelValue || [];
      this.jamfAnalytics = [...this.selected].filter(
        ({ jamf }) => jamf === true
      );
      this.customAnalytics = [...this.selected].filter(
        ({ jamf }) => jamf === false
      );
    }

    await this.refresh();
    this.loaded = true;
  },
  methods: {
    async refresh() {
      const response = await this.$store.dispatch(this.endpoint);
      this.data = response?.items;
    },
    async setupFilterOptions() {
      const result = await this.$store.dispatch(
        'primary/listAnalyticsFilterOptions'
      );
      this.tags = result?.tags?.map(({ value }) => value).sort();
      this.categories = result?.categories?.map(({ value }) => value).sort();
    },
    severityNum(params, level) {
      const severity = this.getSeverity(params);
      return severity === level ? 1 : 0;
    },
    getSeverity(params) {
      return params.data?.tenantSeverity || params.data?.severity;
    },
    arrayFilterText(params) {
      return typeof params.value !== 'string'
        ? params?.value?.join('-')
        : params.value;
    },
    switchGroup(columnApi, colId) {
      this.groupBy = colId;
      columnApi.setRowGroupColumns([colId]);
    },
    async onGridReady(params) {
      const group = this.$route.query.groups;
      this.switchGroup(
        params.columnApi,
        group === '' ? 'none' : group || this.groupBy
      );
      this.setSelected(params);
    },
    setSelected(params) {
      if (this.includeSelection) {
        params?.api?.forEachNode((node) => {
          if (
            this.allSelectedAnalytics.findIndex(
              ({ uuid }) => node?.data?.uuid === uuid
            ) !== -1
          ) {
            node.setSelected(true);
          }
        });
      }
    },
    tabChange(value) {
      this.tabsUpdated = true;
      this.isJamfTab = value.key === 'jamf';
    },
    getSelected({ selected }) {
      if (this.isJamfTab) {
        this.jamfAnalytics = selected;
      } else {
        this.customAnalytics = selected;
      }

      this.allSelectedAnalytics = [
        ...this.jamfAnalytics,
        ...this.customAnalytics,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-list {
  @include grid;
  @include grid-columns(1fr 12px);
  align-items: center;
  user-select: none;
  margin: spacing(-1);
  .icon {
    pointer-events: none;
  }
}
</style>
