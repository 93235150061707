import Section from '@/components/Section.vue';
import UsbControls from './UsbControls.vue';
import UsbControlsForm from './UsbControlsForm.vue';

import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default [
  {
    path: '/device-controls',
    component: Section,
    name: 'devicecontrols',
    meta: {
      breadcrumb: {
        linkName: 'devicecontrols.index',
        linkText: 'Device Controls',
      },
      permissions: [RBAC_RESOURCE.USB_CONTROLS],
    },
    children: [
      {
        path: '',
        component: UsbControls,
        name: 'devicecontrols.index',
      },
      {
        path: 'create',
        component: UsbControlsForm,
        name: 'devicecontrols.index.create',
        meta: {
          breadcrumb: {
            linkText: 'Create',
          },
          requireWrite: true,
        },
      },
      {
        path: ':id',
        component: Section,
        meta: {
          breadcrumb: {
            linkName: 'devicecontrols.index.detail',
            linkText: 'Removable Storage Control Set',
          },
          mode: { edit: true },
        },
        children: [
          {
            path: '',
            name: 'devicecontrols.index.detail',
            component: UsbControlsForm,
          },
        ],
      },
    ],
  },
];
