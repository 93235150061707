<template>
  <div class="gp-content">
    <j-form-bar
      v-if="mode.create || tenant"
      :title="mode.create ? 'Create Tenant' : tenant.name"
      @back="routeTo()"
      @submit:created="routeTo('detail')"
      @cancel="routeTo('detail')"
      @edit="routeTo('edit')"
      @remove="confirmRemove(tenant)"
      :can-write="true"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component v-if="!loading" :is="Component" :tenant="tenant" />
      </transition>
    </router-view>
    <j-delete-modal
      v-model="showRemoveModal"
      :to-delete="toDelete"
      @confirm="remove()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useRemoveHelpers } from '@/composables/remove-helpers';
import { useRouteHelpers } from '@/composables/route-helpers';

export default {
  name: 'Tenant',
  setup() {
    const { showRemoveModal, toDelete, confirmRemove } = useRemoveHelpers();
    const { mode } = useRouteHelpers();
    return {
      showRemoveModal,
      toDelete,
      confirmRemove,
      mode,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('admin', { adminTenant: (state) => state.admin.tenant }),
    tenant() {
      return this.mode.create
        ? {
            name: '',
            description: '',
            hd: '',
            connections: [
              {
                name: 'JamfID',
                button: 'jamf_button',
                requireKnownUsers: true,
              },
            ],
            releaseGroup: 'PROD',
            complianceReporterEnabled: false,
            complianceReporterEmailContact: '',
            complianceReporterLicenseCount: 0,
            complianceReporterExpirationDate: '',
            jamfIdUsers: [],
            active: true,
          }
        : this.adminTenant;
    },
  },
  methods: {
    async remove() {
      this.showRemoveModal = false;
      const response = await this.$store.dispatch('admin/deleteOrganization', {
        uuid: this.adminTenant.uuid,
      });
      if (response) {
        this.$router.push({ name: 'admin.tenants.index' });
      }
    },
    routeTo(name) {
      const parent = 'admin.tenants.index';
      this.$router.push({
        name: name ? `${parent}.${name}` : parent,
        params: name ? { id: this.adminTenant?.uuid } : {},
      });
    },
  },
  async mounted() {
    if (!this.mode.create) {
      await this.$store.dispatch('admin/getOrganization', {
        uuid: this.$route.params.id,
      });
    }
    this.loading = false;
  },
};
</script>
