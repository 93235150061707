<template>
  <div class="filters">
    Filters:
    <j-tag
      class="filter"
      v-for="({ column, value, type, filterType }, index) in filters"
      :key="index"
      @click="$emit('clear', column)"
    >
      <template #icon>
        <j-icon data="@jcon/x-small.svg" />
      </template>
      <span class="filters-column">{{ column }}</span
      >:
      <span class="filters-value">{{ getValue(value, type, filterType) }}</span>
    </j-tag>
    <a v-if="filters.length > 1" class="clear-all" @click="$emit('clear:all')"
      >Clear All</a
    >
  </div>
</template>

<script>
import { useGmt } from '@/composables/gmt';
import { buildReadableOpString } from '../utils/operators';
import { FILTER_TYPES, FILTER_TYPES_CHARS } from '../utils/filter.types';

export default {
  name: 'FilterTagBar',
  setup() {
    const { formatEpochDate } = useGmt();

    return { formatEpochDate };
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  emits: ['clear', 'clear:all'],
  methods: {
    getValue(value, type, filterType) {
      if (
        filterType === 'date' &&
        ![FILTER_TYPES.BEFORE_INTERVAL, FILTER_TYPES.AFTER_INTERVAL].includes(
          type
        )
      ) {
        return this.formatEpochDate(value);
      }
      const types = Object.keys(FILTER_TYPES_CHARS).filter((item) =>
        type.match(item)
      );
      return buildReadableOpString(value, types.length ? type : null);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  @include grid;
  @include grid-columns;
  padding: 0 var(--size-grid-gap-base);
  height: var(--table-bar-height);
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-table-border);

  &-column {
    font-weight: 700;
  }
  &-type {
    font-weight: 400;
  }

  .filter {
    @include grid(spacing(0.5));
    grid-auto-rows: var(--size-input-height-secondary);
    grid-auto-flow: column;
    margin: 0;
    place-content: center;
  }
}
</style>
