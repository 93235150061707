<template>
  <div>
    <StatusIndicator v-if="value" :status="value" />
    <gp-loader v-else />
  </div>
</template>

<script>
import StatusIndicator from '@/components/StatusIndicator.vue';

export default {
  components: { StatusIndicator },
  name: 'AlertTableStatusCell',
  props: ['params'],
  data() {
    return {
      value: null,
    };
  },
  mounted() {
    const { value, valueFormatted } = this.params;
    this.value = valueFormatted || value;
  },
};
</script>
