<template>
  <div class="panel-browser">
    <div class="panel-list">
      <aside class="panel">
        <nav v-if="tabsEnabled" class="panel-tabs">
          <slot name="filter-tabs"></slot>
        </nav>
        <slot></slot>
      </aside>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  name: 'PanelBrowser',
  props: {
    title: {
      type: String,
      required: true,
    },
    tabsEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filter: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel-browser {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0; // Needed for firefox to enable scrolling
  .panel-list {
    border-right: 1px solid var(--color-border-secondary);
  }

  .panel {
    flex-grow: 1;
    width: 345px;
    border: 0;

    display: flex;
    flex-direction: column;

    font-size: 0.9rem;
    background-color: var(--color-structure-base);

    .panel-tabs {
      @include tab-group-container;
      justify-content: center;
      padding: 0;
      border-bottom: 1px solid var(--color-border-secondary);
      margin-bottom: -1px;
      align-items: flex-end;
      display: flex;
    }
  }
}
</style>
