import logger from '@/plugins/logger';
import store from '@/store/store';

const setAuthorizationHeaderLink = (isAdmin) => (request) =>
  new Promise((resolve, reject) => {
    logger.debug(request);
    store
      .dispatch('auth/getOrRefreshToken')
      .then((token) => {
        const headers = { Authorization: token };
        if (window.location.origin.includes('localhost')) {
          headers['x-jamfprotect-tenant'] =
            store.state.auth.loginConfig.tenant || 'use1-jamfadmin';
        } else if (isAdmin) {
          headers['x-jamfprotect-tenant'] = store.state.app.info.vanity;
        }
        resolve({ headers });
      })
      .catch((err) => {
        logger.error(err);
        reject(err);
      });
  });

export default setAuthorizationHeaderLink;
