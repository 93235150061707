<template>
  <JamfTable
    :get-data="getData"
    :suppress-search="true"
    :suppress-filter-bar="true"
    :columns="columns"
  >
    <template #left v-if="canWrite">
      <j-create-button
        @click="$router.push({ name: 'prevent.custom.create' })"
        label="Create Prevent List"
      />
    </template>
    <template #right="{ selected }">
      <j-delete-button
        v-if="canWrite"
        @click="confirmDelete(selected)"
        :is-disabled="selected.length === 0"
        :label="`Delete Prevent Lists (${selected.length})`"
      />
    </template>
    <template #modals="{ selected, refresh }">
      <j-delete-modal
        v-model="showWarning"
        :to-delete="toDelete"
        :title="`Delete Prevent List${selected.length > 1 ? 's' : ''}`"
        @confirm="removeSets(selected, refresh)"
      />
    </template>
  </JamfTable>
</template>

<script>
import JamfTable from '@/components/table/JamfTable.vue';
import { useRBAC } from '@/composables/rbac';
import { RBAC_RESOURCE } from '@/store/modules/rbac.resource';

export default {
  name: 'PreventLists',
  components: { JamfTable },
  setup() {
    const { canWrite } = useRBAC(RBAC_RESOURCE.PREVENT_LIST);

    return { canWrite };
  },
  data() {
    return {
      endpoints: {
        main: 'primary/listPreventLists',
        remove: 'primary/deletePreventList',
      },
      filter: this.$route.query,
      toDelete: null,
      showWarning: false,
      columns: [
        {
          headerName: 'Created',
          field: 'created',
          type: 'dateColumn',
          sort: 'asc',
          sortable: true,
        },
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 200,
          sortable: true,
          type: 'linkColumn',
          cellRendererParams: {
            route: (params) => ({
              name: 'prevent.custom.detail',
              params: { id: params?.data?.id },
            }),
          },
        },
        {
          headerName: 'Description',
          field: 'description',
        },
        {
          headerName: 'Type',
          field: 'type',
          sortable: true,
          type: 'tagsColumn',
        },
        {
          headerName: 'Count',
          field: 'count',
          sortable: true,
        },
      ],
    };
  },
  created() {
    if (this.canWrite) {
      this.columns.splice(0, 0, { type: 'selectAllColumn' });
    }
  },
  methods: {
    async getData(params) {
      return this.$store.dispatch(this.endpoints.main, params);
    },
    confirmDelete(selected) {
      this.toDelete = selected.map(({ name }) => name);
      this.showWarning = true;
    },
    async removeSets(selected, refresh) {
      this.showWarning = false;
      this.loading = true;
      await Promise.all(
        selected.map(({ id }) =>
          this.$store.dispatch(this.endpoints.remove, { id })
        )
      );
      refresh();
    },
  },
};
</script>
