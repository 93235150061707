import { sentrySetupTransactionAndSpan } from '@/util/sentry';
import logger from '@/plugins/logger';
import AuthClient from '../auth.client';

const actions = {
  logoutUser({ commit, state: { loginConfig } }) {
    AuthClient.logout(loginConfig);
    commit('LOGOUT');
  },
  loginUser({ state: { loginConfig } }, { connector }) {
    AuthClient.login({ ...loginConfig, connector });
  },
  loginConfig({ commit }, payload) {
    commit('SET_LOGIN_CONFIG', payload);
  },
  async initializeAuthClient({ commit, state: { loginConfig } }) {
    logger.debug('Initialize Auth Client');
    await AuthClient.getClient(loginConfig);
    commit('SET_AUTH_CLIENT', true);
  },
  async getOrRefreshToken({
    commit,
    state: {
      loginConfig: { audience },
    },
  }) {
    const { span } = sentrySetupTransactionAndSpan({
      name: 'auth',
      op: `auth.session`,
      description: `refresh token`,
    });
    const token = await AuthClient.getToken({ audience });
    logger.debug('Refresh Token');
    if (!token) {
      logger.debug('No Session');
      commit('SET_SESSION_NULL');
      return null;
    }
    const user = await AuthClient.getUser({ audience });
    commit('SET_SESSION', { session: { user, token } });
    span.finish();
    return token;
  },
  async handleCallbackAuth({
    commit,
    state: {
      loginConfig: { audience },
    },
  }) {
    logger.debug('Handle Callback');
    await AuthClient.client.handleRedirectCallback();
    const token = await AuthClient.getToken({ audience });
    const user = await AuthClient.getUser({ audience });
    commit('SET_SESSION', { session: { user, token } });
  },
};

export default actions;
