<template>
  <div class="table-filter-wrapper" ref="container">
    <div class="table-filter-input-wrapper">
      <input
        class="table-filter-input"
        ref="autocomplete"
        @input="onChange"
        @click="onFocus"
        @keydown.stop="onInputKeyDown"
        aria-label="Table filter input"
        v-model="computedValue"
        autocomplete="off"
        @focus="onFocus"
        :placeholder="placeholder"
      />
      <transition name="fade">
        <j-button
          v-show="finalOptionSelected"
          style-type="ghost"
          aria-label="Apply Filter"
          class="table-filter-button"
          @click="search"
          ><template #leading><j-icon data="@jcon/magnifier.svg" /></template
        ></j-button>
      </transition>
    </div>
    <div
      v-if="isOpen && currentOptions.length > 0"
      class="table-filter-options-menu"
      ref="menu"
    >
      <ul>
        <li
          :tabindex="0"
          :key="index"
          v-for="(option, index) of currentOptions"
          ref="menuItem"
          :class="option.class"
          @keydown.stop="($event) => onMenuItemKeyDown($event, option, index)"
          @click="() => onMenuItemClick(option)"
        >
          <template v-if="typeof option === 'object' && option !== null">
            <span
              v-for="(char, idx) of option.label || option.value"
              :key="idx"
              :class="characterClass(char, idx)"
              >{{ char }}</span
            >
          </template>
          <template v-else>
            <span
              v-for="(char, idx) of option"
              :key="idx"
              :class="characterClass(char, idx)"
              >{{ char }}</span
            >
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { parseQueryFilter } from '../utils/query-build-and-parse';

const KEY_EVENT = {
  SPACE: ' ',
  ENTER: 'Enter',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
};

export default {
  name: 'FilterInput',
  props: {
    modelValue: [Number, String],
    gridApi: Object,
    columnApi: Object,
    /** Column options list to display for filtering, Can be set as { key: [string] } or if the value is differnt then the human readable state { key: [{ label, value }]} */
    columnOptions: Object,
    /** Column to filter by for any string not proceeded by a filterable column  */
    defaultColumn: String,
  },
  compatConfig: { MODE: 3 },
  emits: ['update:modelValue'],
  data() {
    return {
      newValue: this.modelValue || '',
      filterModel: null,
      toFilterModel: [],
      isOpen: false,
      numMatchingChars: 0,
      currentOptions: [],
      default: {},
      options: {
        // Starting list is always columns
        columns: [],
        // set through columnOptions prop
        values: { empty: [] },
        // empty list that can always be set if no lists are available
        empty: [],
      },
      placeholder: null,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('update:modelValue', value);
      },
    },
    currentSet() {
      return this.newValue.split(':').length - 1;
    },
    finalOptionSelected() {
      return this.newValue !== '' && this.newValue.split(':')[1] !== '';
    },
  },
  mounted() {
    this.getColumnsToFilter();
  },
  methods: {
    async setFilter() {
      if (this.filterModel) {
        await this.gridApi.setFilterModel(this.filterModel);
        await this.gridApi.onFilterChanged();
        this.newValue = '';
        this.filterModel = null;
      }
    },
    getColumnsToFilter() {
      // Get columns that have a filter set
      this.columnApi
        .getAllGridColumns()
        .forEach(({ colDef: { filter, field, colId } }) => {
          if (filter) {
            // Checks if there is a custom colId for instances that need to match the front end naming
            // rather then the api naming. This needs to also be checked in the getData function
            // of your table.
            const instance = this.gridApi.getFilterInstance(colId || field);
            const filterType = instance.component
              ? instance.component.getFilterType()
              : instance.getFilterType();
            this.options.columns.push(colId || field);
            this.toFilterModel.push({ field: colId || field, filterType });
            this.default[colId || field] = instance.getDefaultOption();
          }
        });
      if (this.columnOptions) {
        this.options.values = { ...this.options.values, ...this.columnOptions };
      }
      this.currentOptions = this.options.columns;
      this.placeholder = `Filter By: ${this.options.columns.join(', ')}`;
    },
    getFilters(values) {
      let [column, value] = values.split(':');
      if (!value && this.defaultColumn) {
        value = column;
        column = this.defaultColumn;
      }
      const canFilter = this.toFilterModel.find((col) => col.field === column);
      const type = this.default[column];
      if (canFilter !== undefined && value) {
        const currentModels = this.gridApi.getFilterModel();
        const parsed = parseQueryFilter({
          column,
          type,
          value,
          filterType: canFilter.filterType,
        });
        if (
          Object.keys(currentModels).includes(column) &&
          !currentModels[column].suppressAndOrCondition
        ) {
          if (currentModels[column].and) {
            currentModels[column].operators.add('and');
            currentModels[column].and.push(parsed[column]);
            this.filterModel = { ...currentModels };
          } else {
            parsed[column] = {
              ...currentModels[column],
              and: [parsed[column]],
            };
            this.filterModel = { ...currentModels, ...parsed };
          }
        } else {
          this.filterModel = { ...currentModels, ...parsed };
        }
      }
    },
    characterClass(char, idx) {
      return {
        'bolded-char': idx < this.numMatchingChars,
      };
    },
    onFocus() {
      this.isOpen = true;
      if (this.newValue === '') {
        this.numMatchingChars = 0;
        this.currentOptions = this.options.columns;
      }
    },
    setCurrentOptions(values) {
      let list = 'columns';
      const { length } = values;

      if (length > 1) {
        list = 'empty';
      }

      if (length === 2) {
        list = Object.keys(this.options.values).includes(
          values[this.currentSet - 1]
        )
          ? values[this.currentSet - 1]
          : 'empty';
      }
      this.currentOptions =
        length === 2 ? this.options.values[list] : this.options[list];
    },
    onChange() {
      this.isOpen = true;
      const values = this.newValue.split(':');

      if (values[this.currentSet] !== '' && !this.newValue.endsWith(':')) {
        const filteredListOptions = this.currentOptions.filter((option) => {
          const value =
            typeof option === 'string' ? option : option.label || option.value;
          return (
            value.slice(0, values[this.currentSet].length).toLowerCase() ===
            values[this.currentSet].toLowerCase()
          );
        });
        this.currentOptions = filteredListOptions;
        this.numMatchingChars = values[this.currentSet].length;
      } else {
        this.setCurrentOptions(values);
        this.numMatchingChars = 0;
      }
    },
    onInputKeyDown(event) {
      if (event.key === KEY_EVENT.DOWN && this.currentOptions.length) {
        event.preventDefault();
        this.$refs.menuItem[0].focus();
        this.$refs.menu.scrollTop = 0;
      }
      if (event.key === KEY_EVENT.ENTER) {
        this.search();
      }
    },
    search() {
      this.newValue = this.newValue.trim().replace(/: +/g, ':');
      this.getFilters(this.newValue);
      this.isOpen = false;
      this.setFilter();
    },
    async onMenuItemClick(option) {
      const values = this.newValue.split(':');
      const opt = typeof option === 'string' ? option : option.value;
      if (values.length < 2) {
        values[this.currentSet] = `${opt}:`;
      } else {
        this.isOpen = false;
        values[this.currentSet] = opt;
      }
      this.numMatchingChars = 0;
      this.newValue = values.join(':');
      this.setCurrentOptions(values.join(':').split(':'));
      this.$refs.autocomplete.focus();
    },
    onMenuItemKeyDown(event, option, index) {
      const { nextElementSibling, previousElementSibling } =
        this.$refs.menuItem[index];
      if ([KEY_EVENT.SPACE, KEY_EVENT.ENTER].includes(event.key)) {
        this.onMenuItemClick(option);
        this.$refs.autocomplete.focus();
      }

      if (event.key === KEY_EVENT.UP) {
        if (previousElementSibling) {
          previousElementSibling.focus();
        } else {
          this.$refs.autocomplete.focus();
        }
      }

      if (event.key === KEY_EVENT.DOWN) {
        if (nextElementSibling) {
          nextElementSibling.focus();
        }
      }

      if (
        [KEY_EVENT.TAB, KEY_EVENT.DOWN].includes(event.key) &&
        !nextElementSibling
      ) {
        this.isOpen = false;
      }
    },
    handleClickOutsideMenu(event) {
      if (
        this.$refs.container &&
        !this.$refs.container.contains(event.target)
      ) {
        this.isOpen = false;
      }
    },
  },
  created() {
    document.addEventListener('mousedown', this.handleClickOutsideMenu);
  },

  unmounted() {
    document.removeEventListener('mousedown', this.handleClickOutsideMenu);
  },
};
</script>

<style lang="scss" scoped>
.table-filter-input {
  @include input-field-base;
  @include transition(box-shadow);
  border-radius: 0;
  border: 0;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 spacing(2);

  &:focus:not([readonly]),
  &:hover:not([readonly]) {
    box-shadow: 0px 0px 0px 1px var(--color-input-border-active) inset;
  }
}

.table-filter-wrapper {
  height: 100%;
  // bug workaround https://jamfpdd.atlassian.net/browse/UXE-1636
  @media only screen and (min-width: 812px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.table-filter-options-menu {
  @include autocomplete-list;
  border: 1px solid var(--color-border-contrast-only, transparent);
  scrollbar-color: var(--color-border-secondary)
    var(--color-structure-secondary);
  overflow-y: auto;
}

.table-filter-input-wrapper {
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;

  .table-filter-button {
    --size-action-height-base: var(--size-action-height-base);
    position: absolute;
    right: spacing();
  }
}

.table-filter-input,
.table-filter-options-menu {
  // bug workaround https://jamfpdd.atlassian.net/browse/UXE-1636
  @media only screen and (min-width: 1280px) {
    min-width: 480px;
  }
}
</style>
