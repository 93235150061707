<template>
  <div class="scroll-vertical g-rows max-c p-2 related-processes">
    <div v-if="nameTree.length > 0" class="process-tree">
      <j-icon data="@jcon/hierarchy.svg" :rotate="270" />
      <div
        v-for="(name, index) in nameTree"
        :key="name"
        class="process-tree-tag g-cols max-c ai-center gap-1"
      >
        <j-icon v-if="index > 0" data="@jcon/arrow.svg" :rotate="90" />
        <j-tag style-type="outline">
          <span class="process-tree-name">{{ name }}</span>
        </j-tag>
      </div>
    </div>
    <transition-group name="list" tag="div" class="g-rows max-c">
      <card v-for="(process, index) in sortedProcesses" :key="index">
        <label class="h4">{{ process?.name }} ({{ process?.pid }})</label>
        <AlertProcess :new="true" :process="process" :related="related" />
      </card>
      <NoRelatedInfo
        v-if="!processes.length"
        title="0 Processes"
        :key="'0-empty'"
      />
    </transition-group>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import AlertProcess from '@/modules/alerts/components/AlertProcess.vue';
import Card from '@/components/Card.vue';
import NoRelatedInfo from './NoRelatedInfo.vue';

export default {
  name: 'AlertRelatedProcesses',
  compatConfig: {
    MODE: 3,
  },
  components: {
    AlertProcess,
    Card,
    NoRelatedInfo,
  },
  props: {
    processes: {
      type: Array,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
    primary: {
      type: Number,
      default: null,
    },
  },
  computed: {
    sortedProcesses() {
      return orderBy(this.processes, 'pid', 'desc');
    },
    nameTree() {
      const procs = [];
      let startPid = this.primary;
      // If a pid is passed in, use it as the primary
      if (!startPid) {
        // else get the largest pid
        if (this.sortedProcesses?.length > 0) {
          startPid = this.sortedProcesses[0].pid;
        }
      }
      // If we have a startPid, build the tree until resolving fails
      if (startPid) {
        let currentPid = startPid;
        let index = this.processes.findIndex(({ pid }) => pid === currentPid);
        while (index >= 0) {
          procs.push(
            `${this.processes[index].name} (${this.processes[index].pid})`
          );
          currentPid = this.processes[index].ppid;
          index = this.processes.findIndex(({ pid }) => pid === currentPid);
        }
      }

      if (procs?.length > 0) {
        procs.push('launchd (1)');
        procs.push('kernel_task (0)');
      }

      return procs;
    },
  },
};
</script>

<style lang="scss" scoped>
.process-tree {
  @include flex-wrap($gap: spacing(), $scale: 0);
  @include border($color: var(--color-border-secondary), $sides: bottom);
  padding-bottom: spacing();

  &-name {
    color: var(--color-font-base);
  }

  &-tag svg {
    color: var(--color-border-base);
  }
}
</style>
