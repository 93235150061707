<template>
  <div class="gp-content">
    <j-form-bar title="Update Analytic">
      <j-save-button @click="submit()" />
      <gp-loader :show-loader="showLoader">Saving Analytic</gp-loader>
      <j-updated v-model="updated" :message="message" />
    </j-form-bar>
    <div>
      <div class="g-rows p-2">
        <ActionsSelection v-model:actions="tenantActions" />
        <div class="g-cols max-c ai-end">
          <SeverityLevelSelect v-model="tenantSeverity" />
          <transition name="fade" mode="out-in">
            <j-button
              v-if="tenantSeverity !== analytic.severity"
              @click="resetSeverity()"
              style-type="ghost-primary"
              ><template #leading
                ><j-icon data="@jcon/arrow-around-right.svg" /></template
              >Reset to original Severity</j-button
            >
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsSelection from '../components/ActionsSelection.vue';
import SeverityLevelSelect from '@/components/SeverityLevelSelect.vue';

export default {
  name: 'AnalyticInternalEdit',
  components: {
    ActionsSelection,
    SeverityLevelSelect,
  },
  props: {
    analytic: {
      type: Object,
      required: true,
    },
    actionFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      message: null,
      severityMessage: null,
      showLoader: false,
      updated: false,
      tenantActions: [],
      tenantSeverity: null,
    };
  },
  beforeMount() {
    const { severity, tenantSeverity, tenantActions, analyticActions } =
      this.analytic;
    this.tenantActions = tenantActions || analyticActions;
    this.tenantSeverity = tenantSeverity || severity;
  },
  methods: {
    resetSeverity() {
      this.tenantSeverity = this.analytic.severity;
      this.severityMessage = 'Severity Reset';
    },
    async submit() {
      this.showLoader = true;
      const savedAnalytic = await this.$store.dispatch(
        'primary/updateInternalAnalytic',
        {
          uuid: this.analytic.uuid,
          tenantActions: this.tenantActions,
          tenantSeverity: this.tenantSeverity,
        }
      );
      this.showLoader = false;
      if (savedAnalytic) {
        this.message = 'Analytic Updated';
        await this.$store.dispatch('primary/getAnalytic', {
          uuid: this.$route.params.id,
        });
        this.updated = true;
      }
    },
  },
};
</script>
